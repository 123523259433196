import { useSelector } from 'react-redux'
import { selectAll } from 'store/actions/app/rolesSlice'

export const AvailableRoles = {
  'Data.Reader': 'Data.Reader',
  Administrator: 'Administrator',
  VgtAdministrator: 'VgtAdministrator',
  Vastgoedbeheer: 'Vastgoedbeheer',
  Reparatieboom: 'Reparatieboom',
  Relatiebeheer: 'Relatiebeheer',
  Cartotheekbeheer: 'Cartotheekbeheer',
  Financieel: 'Financieel',
  Onderhoudscontract: 'Onderhoudscontract',
  Verhuur: 'Verhuur',
  Woningverbetering: 'Woningverbetering',
  Inspectie: 'Inspectie',
  Woonruimteverdeling: 'Woonruimteverdeling'
}

export const useAssignedRoles = (): (keyof typeof AvailableRoles)[] => {
  const roles = useSelector(selectAll)

  const result: (keyof typeof AvailableRoles)[] = []

  for (const role of Object.keys(AvailableRoles)) {
    if (roles.filter((s) => s.id === AvailableRoles[role]).length > 0) result.push(role as keyof typeof AvailableRoles)
  }
  return result
}

const UseRoles = () => {
  const roles = useSelector(selectAll)

  return {
    isReader: roles.filter((s) => s.id === AvailableRoles['Data.Reader']).length > 0,
    isAdmin: roles.filter((s) => s.id === AvailableRoles.Administrator).length > 0,
    isVgtAdmin: roles.filter((s) => s.id === AvailableRoles.VgtAdministrator).length > 0,
    isVastgoedbeheer: roles.filter((s) => s.id === AvailableRoles.Vastgoedbeheer).length > 0,
    isReparatieboom: roles.filter((s) => s.id === AvailableRoles.Reparatieboom).length > 0,
    isRelatiebeheer: roles.filter((s) => s.id === AvailableRoles.Relatiebeheer).length > 0,
    isCartotheekbeheer: roles.filter((s) => s.id === AvailableRoles.Cartotheekbeheer).length > 0,
    isFinancieel: roles.filter((s) => s.id === AvailableRoles.Financieel).length > 0,
    isOnderhoudscontracten: roles.filter((s) => s.id === AvailableRoles.Onderhoudscontract).length > 0,
    isVerhuur: roles.filter((s) => s.id === AvailableRoles.Verhuur).length > 0,
    isWoningverbetering: roles.filter((s) => s.id === AvailableRoles.Woningverbetering).length > 0,
    isInspectie: roles.filter((s) => s.id === AvailableRoles.Inspectie).length > 0,
    isWoonruimteverdeling: roles.filter((s) => s.id === AvailableRoles.Woonruimteverdeling).length > 0
  }
}

export default UseRoles