import React from 'react'
import useRoles from '../../../services/UseRoles'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  VastgoeddeelportefeuilleAddOrUpdateDto,
  zodiosHooks
} from '../../../api/ApiClient'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../services/HandleError'
import { IDropdownOption, Panel, PanelType } from '@fluentui/react'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import VgtTextField from '../../../components/VgtTextField'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'
import VgtDropdown from '../../../components/FluentDropdown'

export interface IEditPanelProps {
  vastgoeddeelportefeuilleId: number,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof VastgoeddeelportefeuilleAddOrUpdateDto>;

export const VastgoeddeelportefeuilleFormPanel: React.FC<IEditPanelProps> = props => {

  const { isVastgoedbeheer } = useRoles()
  const [error, setError] = React.useState<string>()

  const defaultVastgoeddeelportefeuille= {
    naam: '',
    vastgoedportefeuilleId: 0
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(VastgoeddeelportefeuilleAddOrUpdateDto), mode: 'all', defaultValues: defaultVastgoeddeelportefeuille
  })

  const onCancel = () => {
    props.invalidate()
    reset({})
    props.dismissPanel()
  }

  const onSubmit = (data: CreateUpdateType) => {
    if (props.vastgoeddeelportefeuilleId !== 0) {
      updateVastgoeddeelportefeuille(data)
    } else {
      addVastgoeddeelportefeuille(data)
    }
  }

  zodiosHooks.useGetVastgoeddeelportefeuilleId({ params: { id: props.vastgoeddeelportefeuilleId } }, {
      onSuccess: (data) => reset(data),
      enabled: props.vastgoeddeelportefeuilleId !== 0
    }
  )

  const {
    mutate: updateVastgoeddeelportefeuille
  } = zodiosHooks.usePutVastgoeddeelportefeuilleId(
    { params: { id: props.vastgoeddeelportefeuilleId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: addVastgoeddeelportefeuille } = zodiosHooks.usePostVastgoeddeelportefeuille(
    {}, { onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const { data: vastgoedportefeuillen } = zodiosHooks.useGetVastgoedportefeuille()
  const vastgoedportefeuillenOpties = vastgoedportefeuillen?.items?.map((item) => {
    return { key: item.vastgoedportefeuilleId , text: item.naam }
  })

  return (
    <div>
      <Panel type={PanelType.medium}
             headerText={`Vastgoeddeelportefeuille ${props.vastgoeddeelportefeuilleId !== 0 ? 'wijzigen' : 'toevoegen'}`}
             isOpen={props.isOpen}
             onDismiss={onCancel}
             closeButtonAriaLabel="Sluiten">
        <br />
        <ErrorMessageBar error={error} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField
            label="Naam"
            name={getPropertyName<CreateUpdateType>('naam')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isVastgoedbeheer}
          />

          <VgtTextField
            label="Code"
            name={getPropertyName<CreateUpdateType>('code')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isVastgoedbeheer}
          />

          <VgtDropdown label='Vastgoedportefeuille' name={getPropertyName<CreateUpdateType>('vastgoedportefeuilleId')}
                       control={control} options={vastgoedportefeuillenOpties as IDropdownOption[]} errors={errors}
                       required={true}
          />
          <br />

          {isVastgoedbeheer ?
            <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                                 onOkClick={() => handleSubmit(onSubmit)}
                                 onCancelClick={onCancel} /> : null}
        </form>
      </Panel>
    </div>
  )
}