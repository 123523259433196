import React, { useEffect, useState } from 'react'
import { DefaultButton, Panel, PanelType, Stack } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from 'store'
import { getPropertyName } from 'lib/interfaceUtils'
import { useSelector } from 'react-redux'
import { getSelectedEntity, IBouwlaag, bouwdeelSchema, fetchAllBouwlagen, updateBouwlagen, addBouwlagen, getLoadingState } from './bouwlaagSlice'
import VgtTextField from 'components/VgtTextField'
import useRoles from 'services/UseRoles'

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
}

const EditPanelBouwlagen: React.FC<IEditPanelProps> = props => {
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState('Bouwlaag')
  const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
  const fetchStatus = useSelector(getLoadingState)
  const selectedEntity = useSelector(getSelectedEntity)
  const { isAdmin } = useRoles()

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<IBouwlaag>({ resolver: yupResolver(bouwdeelSchema), mode: 'all' })

  useEffect(() => {
    setCloseOnFulFilled(false)
    dispatch(fetchAllBouwlagen())
  }, [])

  useEffect(() => {
    setTitle(`Bouwlaag ${selectedEntity ? isAdmin ? 'wijzigen' : '' : 'toevoegen'}`)
    if (selectedEntity) {
      reset(selectedEntity)
    } else {
      reset({})
    }
  }, [selectedEntity])

  const onCancel = () => {
    props.dismissPanel()
  }

  const onSubmit = (data: IBouwlaag) => {
    if (selectedEntity) {
      data.id = selectedEntity.id
      dispatch(updateBouwlagen(data))
      setCloseOnFulFilled(true)
    } else {
      dispatch(addBouwlagen(data))
      setCloseOnFulFilled(true)
    }
  }

  useEffect(() => {
    if (closeOnFulFilled && fetchStatus === 'succeeded') props.dismissPanel()
  }, [fetchStatus])

  return (
    <div>
      <Panel type={PanelType.large} headerText={title} isOpen={props.isOpen} onDismiss={onCancel} closeButtonAriaLabel="Sluiten">
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField label="Bouwlaag" name={getPropertyName<IBouwlaag>('naam')} register={register} control={control} errors={errors} required readOnly={!isAdmin ? true : false}/>
          <VgtTextField label="Bouwlaag extern" name={getPropertyName<IBouwlaag>('naamExtern')} register={register} control={control} errors={errors} readOnly={!isAdmin ? true : false}/>
          <br />
          { isAdmin ?
            <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
              <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} onClick={handleSubmit(onSubmit)} />
              <DefaultButton text="Annuleren" onClick={onCancel} />
            </Stack> : null
          }
        </form>
      </Panel>
    </div>
  )
}

export default EditPanelBouwlagen
