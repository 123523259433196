import React from 'react'
import { IVgtFieldPropsBase } from 'lib/IVgtFieldPropsBase'

interface IVgtFieldProps extends IVgtFieldPropsBase {
    name: string
    children: JSX.Element | JSX.Element[]
    label?: string
    labelWidth?: number
}

const VgtControlGroup: React.FC<IVgtFieldProps> = props => {
    return (
        <div id={props.name}
            //If the showIf property is false, set the display to none.
            //We have to do this, in this case, because we are applying a display styling as well
            style={props.showIf !== undefined && !props.showIf ?
                { display: 'none' } :
                {
                    display: 'grid',
                    paddingBottom: '12px',
                    rowGap: '5px'
                }}>
            {props.label ?
              <div style={{
                fontSize: '20px',
                fontWeight: '600',
                color: 'rgb(50, 49, 48)',
                marginBottom: '10px',
                marginTop: '10px'
              }}>{props.label}
                <div style={{ borderBottom: '1px solid rgb(219 219 219)' }} />
                <div style={{
                  position: 'relative',
                }}>
                  <div style={{
                    content: "",
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    width: '42%',
                    height: '1px',
                  }}>
                  </div>
                </div>
              </div> : null}
          {props.children}
        </div>
    )
}

export default VgtControlGroup
