import React from 'react'
import { Stack } from '@fluentui/react'

export type WizardStep = {
  title: string
  description?: string
  icon?: string
}

interface WizardStepperProps {
  currentStep: number
  steps: WizardStep[]
  onStepClick: (step: number) => void
}

export const WizardStepper: React.FC<WizardStepperProps> = ({ currentStep, steps, onStepClick }) => {
  const completedColor = '#107C10'
  const currentColor = '#bd1d1d'

  return (
    <Stack horizontal styles={{ root: { padding: '20px 0' } }}>
      {steps.map((step, index) => {
        const isClickable = index < currentStep;

        return (
          <Stack.Item key={index} grow={1} styles={{
            root: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }
          }}>
            {/* Connecting line */}
            {index > 0 && (
              <div style={{
                position: 'absolute',
                left: '-50%',
                right: '50%',
                height: 2,
                top: 16,
                backgroundColor: index <= currentStep ? completedColor : '#EAEAEA',
                zIndex: 0
              }} />
            )}

            <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { minWidth: 100 } }}>
              {/* Circle with number */}
              <div
                onClick={() => isClickable && onStepClick(index)}
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor:
                    index < currentStep ? completedColor : // completed - green
                      index === currentStep ? currentColor : // current - theme primary
                        '#EAEAEA', // upcoming - grey
                  color: index <= currentStep ? 'white' : '#666666',
                  position: 'relative',
                  zIndex: 1,
                  cursor: isClickable ? 'pointer' : 'default',
                }}
              >
                {index + 1}
              </div>

              {/* Step title */}
              <div
                onClick={() => isClickable && onStepClick(index)}
                style={{
                  textAlign: 'center',
                  marginTop: 8,
                  fontSize: 12,
                  color: index === currentStep ? '#bd1d1d' : '#666666',
                  width: '100%',
                  cursor: isClickable ? 'pointer' : 'default',
                }}
              >
                {step.title}
              </div>
            </Stack>
          </Stack.Item>
        )
      })}
    </Stack>
  )
}