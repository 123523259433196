
import { IColumn } from '@fluentui/react'
import Icon from 'components/Icon'

type columnSizes = 'S' | 'M' | 'L' | 'XL' | 'XXL' | 'MAX'

export const createCustomRenderedColumn = (title: string, field: string, onRender?: (item?: any, index?: number, column?: IColumn) => any, size: number | columnSizes = 'M', isSorted = true, isMultiline?: boolean, classNames?: string, headerClassNames?: string) => {
  const pixels = size === 'MAX' ? 500 : size === 'XXL' ? 350 : size === 'XL' ? 200 : size === 'L' ? 150 : size === 'M' ? 100 : size === 'S' ? 60 : size
  return {
    name: title,
    fieldName: field,
    key: field,
    minWidth: pixels,
    maxWidth: pixels,
    isResizable: true,
    onRender: onRender,
    isSorted: isSorted,
    isMultiline: isMultiline,
    className: classNames,
    headerClassName: headerClassNames
  } as IColumn
}

export const createColumn = (title: string, field: string, size: number | columnSizes = 'M', isSorted = false, onRender?: (item?: any, index?: number, column?: IColumn) => any) => {
  return createCustomRenderedColumn(title, field, onRender, size, isSorted)
}

export const createNumberColumn = (title: string, field: string, size: number | columnSizes = 'M', isSorted = false, onRender?: (item?: any, index?: number, column?: IColumn) => any) => {
  return createCustomRenderedColumn(title, field, onRender, size, isSorted, false, 'justify-content-end', 'justify-content-end')
}

export const createBooleanColumn = (title: string, field: string, size?: number | columnSizes) => {
  return createCustomRenderedColumn(
    title,
    field,
    (item) => {
      return item[field] ? <Icon name="Accept" variant="red" /> : <Icon name="Cancel" variant="light" />
    },
    size ? size : 'M',
    false,
    false,
    'justify-content-center',
    'justify-content-end'
  )
}

