import { combineReducers } from '@reduxjs/toolkit'
import authReducer from './auth'
import configurationReducer from './configuration'
import genericError from './genericError'
import userRolesSlice from "./rolesSlice";

export default combineReducers({
  auth: authReducer,
  configuration: configurationReducer,
  genericError,
  roles: userRolesSlice
})
