import { DetailsList, IColumn, Link, Panel, PanelType, SearchBox, SelectionMode, Sticky, StickyPositionType } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import { useEffect, useState } from 'react'
import { IRechtspersoon } from './rechtspersoonSlice'

export interface ISearchPanelRechtspersoonProps {
  rechtspersonen: IRechtspersoon[]
  isOpen: boolean
  headerText: string
  dismissPanel: () => void
  onSelect: (persoon: IRechtspersoon) => void
}
const SearchPanelRechtspersoon: React.FC<ISearchPanelRechtspersoonProps> = props => {
  const [rechtspersonen, setRechtspersonen] = useState<IRechtspersoon[]>([])
  const [currentSearchValue, setCurrentSearchValue] = useState<string>()
  const [items, setItems] = useState<IRechtspersoon[]>([])

  const clearSearch = () => {
    setCurrentSearchValue(undefined)
  }

  const closePanel = () => {
    clearSearch()
    props.dismissPanel()
    setItems(rechtspersonen)
  }
  const onCancel = () => {
    closePanel()
  }

  useEffect(() => {
    clearSearch()
    setRechtspersonen(props.rechtspersonen)
    setItems(props.rechtspersonen)
  }, [props.rechtspersonen])

  const onSearchBoxChanged = (newValue: string | undefined) => {
    setCurrentSearchValue(newValue)
    if (newValue && newValue.length !== 0) {
      const term = newValue.toLocaleLowerCase()
      const personen = rechtspersonen.filter(p => p.handelsnaam.toLocaleLowerCase().indexOf(term) !== -1)
      setItems(personen)
    } else {
      setItems([])
    }
  }

  const handleItemInvoked = (persoon: IRechtspersoon) => {
    props.onSelect(persoon)
    closePanel()
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<IRechtspersoon>('handelsnaam'),
      name: 'Handelsnaam',
      fieldName: getPropertyName<IRechtspersoon>('handelsnaam'),
      minWidth: 190,
      maxWidth: 250,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: IRechtspersoon) => (
        <Link key={item.handelsnaam} onClick={() => handleItemInvoked(item)}>
          {item.handelsnaam}
        </Link>
      ),
    },
    {
      key: getPropertyName<IRechtspersoon>('straat'),
      name: 'Adres',
      fieldName: getPropertyName<IRechtspersoon>('straat'),
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    },
    {
      key: getPropertyName<IRechtspersoon>('telefoonnummer'),
      name: 'Telefoonnummer',
      fieldName: getPropertyName<IRechtspersoon>('telefoonnummer'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    },
  ]

  return (
    <Panel type={PanelType.medium} headerText={props.headerText} isOpen={props.isOpen} onDismiss={onCancel} closeButtonAriaLabel="Sluiten">
      <div style={{ position: 'relative', margin: 10 }}>
        <Sticky stickyPosition={StickyPositionType.Header}>
          <SearchBox placeholder="Zoek op adres" onChanged={onSearchBoxChanged} onClear={clearSearch} value={currentSearchValue} autoComplete="off" />
        </Sticky>

        <DetailsList items={items} columns={columns} selectionMode={SelectionMode.none} />
      </div>
    </Panel>
  )
}

export default SearchPanelRechtspersoon
