import {
  VgeOppervlakteNen2580ListDto,
  zodiosHooks
} from '../../../../api/ApiClient'
import { usePagedParams } from '../../../../services/usePagedParams'
import React, { useState } from 'react'
import { VgeOppervlaktePanel } from './VgeOppervlaktePanel'
import CommandBarTemplate, { ICommandItem } from '../../../../containers/pages/PageTemplates/CommandBarTemplate'
import useRoles from '../../../../services/UseRoles'
import { useBoolean } from '@fluentui/react-hooks'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

export interface NEN2580OppervlakteProps {
  vgeId: number;

}

type VgeOppervlakteNen2580ListDtoType = z.infer<typeof VgeOppervlakteNen2580ListDto>;

export const NEN2580Oppervlakte: React.FC<NEN2580OppervlakteProps> = props => {

  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const { isVastgoedbeheer } = useRoles()
  const [inEditMode, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [itemToEdit, setItemToEdit] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = React.useState<string>()

  const { data, invalidate, isLoading } = zodiosHooks.useGetVgeOppervlakteNen2580({
    queries: {
      VgeId: props.vgeId,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting  } = zodiosHooks.useDeleteVgeOppervlakteNen2580({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const handleItemInvoked = (id: string) => {
    if (id) {
      setItemToEdit(parseInt(id))
      openPanel()
    }
  }

  const commandItems: ICommandItem[] = !isVastgoedbeheer ? [] : [
    {
      text: 'Toevoegen',
      onClick: () => {
        openPanel()
      },
      icon: 'Add',
      visible: props.vgeId !== undefined
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        deleteRows({ vgeId: props.vgeId, bouwlaagIdsToDeletes: selectedIds })
      },
      icon: 'Delete',
      disabled:  isDeleting || selectedIds.length === 0
    }
  ]

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Bouwlaag Naam', getPropertyName<VgeOppervlakteNen2580ListDtoType>('bouwlaagNaam'), 'XL', false,
        (item: VgeOppervlakteNen2580ListDtoType) => (
          <Link key={item.bouwlaagId} onClick={() => handleItemInvoked(item.bouwlaagId.toString())}>
            {item.bouwlaagNaam}
          </Link>
        )),
    ]
  }, [])

  return (
    <div>
      <ErrorMessageBar error={error} />
      <CommandBarTemplate items={commandItems}></CommandBarTemplate>
      <VgeOppervlaktePanel vgeId={props.vgeId} bouwlaagId={itemToEdit} dismissPanel={dismissPanel}
                           isOpen={inEditMode}
                           invalidate={invalidate} />
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount || 0,
          totalPages: data?.totalPages || 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.bouwlaagId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.bouwlaagId.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </div>
  )

}