import { DefaultButton, Panel, PanelType, Stack } from "@fluentui/react"
import VgtTextField from "components/VgtTextField"
import { getPropertyName } from "lib/interfaceUtils"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"
import { 
    getLoadingState, 
    IKostensoort, 
    KostensoortSchema, getSelectedEntity, updateKostensoort, addKostensoort } from "./kostensoortSlice"
import { useEffect, useState } from "react"

interface IEditPanelProps {
    isOpen: boolean
    dismissPanel: any
}

export const KostensoortEditPanel: React.FC<IEditPanelProps> = props => {
    const dispatch = useAppDispatch()
    const [title, setTitle] = useState('')
    const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
    const fetchStatus = useSelector(getLoadingState)
    const selectedEntity = useSelector(getSelectedEntity)

    useEffect(() => {
        setCloseOnFulFilled(false)
    }, []);

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting, errors },
    } = useForm<IKostensoort>({ resolver: yupResolver(KostensoortSchema), mode: 'all' })

    useEffect(() => {
        setTitle(`Kostensoort ${selectedEntity ? 'wijzigen' : 'toevoegen'}`)
        if (selectedEntity) {
            reset(selectedEntity)
        } else {
            reset({})
        }
    }, [selectedEntity]) 

    const onCancel = e => { props.dismissPanel() }

    const onSubmit = (data: IKostensoort) => {
        if (selectedEntity) {
            data.kostensoortId = selectedEntity.kostensoortId
            dispatch(updateKostensoort(data))
            setCloseOnFulFilled(true)
        } else {
            dispatch(addKostensoort(data))
            setCloseOnFulFilled(true)
        }
    }

    useEffect(() => {
        if (closeOnFulFilled && fetchStatus === 'succeeded') props.dismissPanel() 
    }, [fetchStatus])

    return (
        <div>
            <Panel type={PanelType.large} headerText={title} isOpen={props.isOpen} onDismiss={onCancel} closeButtonAriaLabel="Sluiten">
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VgtTextField label="Code" name={getPropertyName<IKostensoort>('code')} register={register} control={control} errors={errors} required={true} />
                    <VgtTextField label="Omschrijving" name={getPropertyName<IKostensoort>('omschrijving')} register={register} control={control} errors={errors} required={true} multiline autoAdjustHeight />
                    <br />
                    <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
                        <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} onClick={handleSubmit(onSubmit)} />
                        <DefaultButton text="Annuleren" onClick={onCancel} />
                    </Stack>
                </form>
            </Panel>
        </div>
    )    
}
export default KostensoortEditPanel