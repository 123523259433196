import React, { useState } from 'react'
import {
  DefaultButton,
  Icon,
  IStackStyles,
  ITextFieldStyles,
  Panel,
  Stack,
  StackItem,
  TextField
} from '@fluentui/react'
import FileInput from 'components/FileInput'
import uploadFile from 'services/uploadFile'

interface IUploadPanelProps {
  title: string
  isOpen: boolean
  dismissPanel: any
  uploadEndpoint: string
  onSuccess?: (result: any) => void
  onError?: (result: any) => void
}

const stackStyles: Partial<IStackStyles> = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#605E5C',
    borderStyle: 'solid'
  }
}

const textFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: {
    borderWidth: 0
  },
  root: {
    flexGrow: 1
  }
}

const UploadPanel: React.FC<IUploadPanelProps> = props => {
  const [fileToUpload, setFileToUpload] = useState(new File([], ''))

  const uploadTheFile = async () => {
    const formData = new FormData()

    formData.append('File', fileToUpload)
    const response = await uploadFile(props.uploadEndpoint, formData)
    if (response.success) {
      if (props.onSuccess) {
        props.onSuccess(response.result)
      }
    } else if (props.onError) {
      props.onError(response.result)
    }
  }

  const onChange = (fileList: FileList) => {
    setFileToUpload(fileList[0])
  }

  const triggerSelectFile = () => {
    const selector = document.getElementById('excelfileuploader')
    if (selector) {
      selector.click()
    }
  }

  return (
    <Panel headerText={props.title} isOpen={props.isOpen} onDismiss={props.dismissPanel} closeButtonAriaLabel='Close'>
      <Stack tokens={{ padding: '10', childrenGap: '10' }}>
        <Stack horizontalAlign='stretch'>
          <Stack styles={stackStyles}>
            <TextField
              label=''
              readOnly
              value={fileToUpload.name}
              onFocus={triggerSelectFile}
              styles={textFieldStyles}
            />
            <Icon iconName={'Folder'} onClick={triggerSelectFile}
                  style={{ alignSelf: 'center', cursor: 'pointer', paddingRight: 4, pointerEvents: 'auto' }} />
          </Stack>
          <FileInput onChange={onChange} htmlId={'excelfileuploader'}></FileInput>
        </Stack>
        <Stack horizontalAlign='end'>
          <StackItem>
            <DefaultButton text='Upload' width={50} onClick={uploadTheFile} disabled={fileToUpload.size === 0}
                           iconProps={{ iconName: 'Upload' }} />
          </StackItem>
        </Stack>
      </Stack>
    </Panel>
  )
}

export default UploadPanel
