import React from 'react'
import { useBoolean } from '@fluentui/react-hooks'
import { IStackStyles, Stack } from '@fluentui/react'

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
}

const TooltipComponent: React.FC<TooltipProps> = ({ content, children }) => {
  const [isTooltipVisible, { setTrue: showTooltip, setFalse: hideTooltip }] = useBoolean(false)

  const tooltipContainerStyles: Partial<IStackStyles> = {
    root: {
      position: 'relative',
      display: 'inline'
    }
  }

  const tooltipStyles: Partial<IStackStyles> = {
    root: {
      position: 'absolute',
      zIndex: 10,
      backgroundColor: '#fff',
      color: 'black',
      transform: 'translateX(50%)',
      padding: '8px 12px',
      border: '1px solid rgb(255 0 0 / 6%)',
      borderRadius: '5px',
      boxShadow: '0px 3px 6px rgba(0,0,0,0.2)'
    }
  }

  return (
    <Stack
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      styles={tooltipContainerStyles}
    >
      {children}
      {isTooltipVisible && (
        <Stack styles={tooltipStyles}>
          {content}
        </Stack>
      )}
    </Stack>
  )
}

export default TooltipComponent
