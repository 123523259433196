import { ICommandBarStyles } from '@fluentui/react'

const commandBarStyles = (): Partial<ICommandBarStyles> => ({
  root: {
    paddingLeft: 0,
    borderBottom: '1px solid #cfcfcf',
    width: '100%',
    marginBottom: 0,
  },
})

export default commandBarStyles
