import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IconProps } from './IconList'
import { invokeFetch } from '../../../services/apiClient'
import { RootState } from '../../../store'
import { IInstallatie } from '../installatie/installatieTypes'

const getSliceState = (state: RootState) => state.cartotheek

export enum WizardStep {
  initial,
  selectCategorie,
  selectElement,
  selectElementDetail,
  listInstallaties,
  addInstallatie,
  editInstallatie
}

export type RelatedEntityType = 'vge' | 'gebouw' | 'gebouwdeel' | 'undefined'

interface ICartotheekMachineState {
  relatedEntityType: RelatedEntityType
  entityId: number | undefined
  selectedCategorie: IconProps
  selectedElement: IconProps
  selectedDetail: IconProps
  iconsToPresent: IconProps[]
  installaties: IInstallatie[]
  selectedInstallatie: IInstallatie | undefined
  currentStep: WizardStep
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
}

const initialState: ICartotheekMachineState = {
  relatedEntityType: 'undefined',
  entityId: undefined,
  selectedCategorie: {} as IconProps,
  selectedElement: {} as IconProps,
  selectedDetail: {} as IconProps,
  iconsToPresent: [],
  installaties: [],
  selectedInstallatie: undefined,
  currentStep: WizardStep.initial,
  status: 'idle',
  error: null
}

export const initialize = createAsyncThunk('cartotheek/categorieen',
  async ({ relatedEntityType, relatedEntityId }: { relatedEntityType: RelatedEntityType, relatedEntityId: number }, thunkAPI) => {
    switch (relatedEntityType) {
      case 'vge':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/vge/${relatedEntityId}/cartotheek/categorieen`)
      case 'gebouw':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/gebouwen/${relatedEntityId}/cartotheek/categorieen`)
      case 'gebouwdeel':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/gebouwdelen/${relatedEntityId}/cartotheek/categorieen`)
      default:
        return []
    }
  }
)

export const categorieSelected = createAsyncThunk('cartotheek/elementen',
  async ({ relatedEntityType, relatedEntityId, categorie }: { relatedEntityType: RelatedEntityType, relatedEntityId: number, categorie: IconProps }, thunkAPI) => {
    switch (relatedEntityType) {
      case 'vge':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/vge/${relatedEntityId}/cartotheek/categorie/${categorie.id}/elementen`)
      case 'gebouw':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/gebouwen/${relatedEntityId}/cartotheek/categorie/${categorie.id}/elementen`)
      case 'gebouwdeel':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/gebouwdelen/${relatedEntityId}/cartotheek/categorie/${categorie.id}/elementen`)
      default:
        return []
    }
  }
)

export const categorieAdded = createAsyncThunk('cartotheek/categorieAdded',
  async ({ relatedEntityType, relatedEntityId, categorie }: { relatedEntityType: RelatedEntityType, relatedEntityId: number, categorie: IconProps }, thunkAPI) => {
    switch (relatedEntityType) {
      case 'vge':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/vge/${relatedEntityId}/cartotheek/categorie/${categorie.id}/elementen`)
      case 'gebouw':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/gebouwen/${relatedEntityId}/cartotheek/categorieen`)
      case 'gebouwdeel':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/gebouwdelen/${relatedEntityId}/cartotheek/categorieen`)
      default:
        return []
    }
  }
)

export const elementSelected = createAsyncThunk('cartotheek/elementdetails',
  async ({ relatedEntityType, relatedEntityId, element }: { relatedEntityType: RelatedEntityType, relatedEntityId: number, element: IconProps }, thunkAPI) => {
    switch (relatedEntityType) {
      case 'vge':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/vge/${relatedEntityId}/cartotheek/element/${element.id}/details`)
      case 'gebouw':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/gebouwen/${relatedEntityId}/cartotheek/element/${element.id}/details`)
      case 'gebouwdeel':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/gebouwdelen/${relatedEntityId}/cartotheek/element/${element.id}/details`)
      default:
        return []
    }
  }
)

export const elementAdded = createAsyncThunk('cartotheek/elementAdded',
  async ({ relatedEntityType, relatedEntityId, element }: { relatedEntityType: RelatedEntityType, relatedEntityId: number, element: IconProps }, thunkAPI) => {
    switch (relatedEntityType) {
      case 'vge':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/vge/${relatedEntityId}/cartotheek/element/${element.id}/details`)
      case 'gebouw':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/gebouwen/${relatedEntityId}/cartotheek/element/${element.id}/details`)
      case 'gebouwdeel':
        return await invokeFetch<IconProps[]>(thunkAPI, 'GET', `/gebouwdelen/${relatedEntityId}/cartotheek/element/${element.id}/details`)
      default:
        return []
    }
  }
)

export const getAllInstallaties = createAsyncThunk('cartotheek/listall',
  async ({ relatedEntityType, relatedEntityId }: { relatedEntityType: RelatedEntityType, relatedEntityId: number }, thunkAPI) => {
    switch (relatedEntityType) {
      case 'vge':
        return await invokeFetch<IInstallatie[]>(thunkAPI, 'GET', `/vge/${relatedEntityId}/installaties`)
      case 'gebouw':
        return await invokeFetch<IInstallatie[]>(thunkAPI, 'GET', `/gebouwen/${relatedEntityId}/installaties`)
      case 'gebouwdeel':
        return await invokeFetch<IInstallatie[]>(thunkAPI, 'GET', `/gebouwdelen/${relatedEntityId}/installaties`)
      default:
        return []
    }
  }
)

export const detailSelected = createAsyncThunk('cartotheek/list',
  async ({ relatedEntityType, relatedEntityId, elementdetail }: { relatedEntityType: RelatedEntityType, relatedEntityId: number, elementdetail: IconProps }, thunkAPI) => {
    switch (relatedEntityType) {
      case 'vge':
        return await invokeFetch<IInstallatie[]>(thunkAPI, 'GET', `/vge/${relatedEntityId}/installaties?elementdetailId=${elementdetail.id}`)
      case 'gebouw':
        return await invokeFetch<IInstallatie[]>(thunkAPI, 'GET', `/gebouwen/${relatedEntityId}/installaties?elementdetailId=${elementdetail.id}`)
      case 'gebouwdeel':
        return await invokeFetch<IInstallatie[]>(thunkAPI, 'GET', `/gebouwdelen/${relatedEntityId}/installaties?elementdetailId=${elementdetail.id}`)
      default:
        return []
    }
  }
)

export const deleteClicked = createAsyncThunk('cartotheek/delete',
  async ({ installatieId }: { installatieId: number }, thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', `/api/installatie`, { installatieIds: [installatieId] })
  }
)

export const saveInstallatie = createAsyncThunk('cartotheek/saveInstallatie',
  async ({ installatie }: { installatie: IInstallatie }, thunkAPI) => {
    if (installatie.id) {
      return await invokeFetch<IInstallatie>(thunkAPI, 'PUT', `/api/installatie/${installatie.id}`, installatie)
    } else
    {
      return await invokeFetch<IInstallatie>(thunkAPI, 'POST', `/api/installatie`, installatie)
    }
  }
)

export const cartotheek = createSlice({
  name: 'cartotheek',
  initialState: initialState,
  reducers: {
    installatieSelected: (state, action: PayloadAction<IInstallatie>) => {
      state.selectedInstallatie = action.payload
      state.currentStep = WizardStep.editInstallatie
    },
    newFromInstallatie: (state) => {
      state.selectedInstallatie = {
        categorieId: state.selectedCategorie.id,
        categorieDisplay: state.selectedCategorie.name,
        elementId: state.selectedElement.id,
        elementDisplay: state.selectedElement.name,
        elementdetailId: state.selectedDetail.id,
        elementdetailDisplay: state.selectedDetail.name,
        nlSfbOmschrijving: state.selectedInstallatie?.nlSfbOmschrijving,
        nlSfbCode: state.selectedInstallatie?.nlSfbCode,
        nlSfbElementId: state.selectedInstallatie?.nlSfbElementId,
        vgeId: state.relatedEntityType === 'vge' ? state.entityId : undefined,
        gebouwId: state.relatedEntityType === 'gebouw' ? state.entityId : undefined,
        gebouwDeelId: state.relatedEntityType === 'gebouwdeel' ? state.entityId : undefined
      } as IInstallatie

      state.currentStep = WizardStep.addInstallatie
    },
    goBack: (state) => {
      switch (state.currentStep) {
        case WizardStep.listInstallaties:
          state.currentStep = WizardStep.selectElementDetail
          break
        case WizardStep.selectElement:
          state.currentStep = WizardStep.selectCategorie
          break
        case WizardStep.selectElementDetail:
          state.currentStep = WizardStep.selectElement
          break
        case WizardStep.addInstallatie:
          state.selectedCategorie = {} as IconProps
          state.selectedElement = {} as IconProps
          state.selectedDetail = {} as IconProps
          state.currentStep = WizardStep.selectCategorie
          state.installaties = []
          state.selectedInstallatie = {} as IInstallatie
          break
        case WizardStep.editInstallatie:
          state.currentStep = WizardStep.listInstallaties
          break
        default:
          state.selectedCategorie = {} as IconProps
          state.selectedElement = {} as IconProps
          state.selectedDetail = {} as IconProps
          state.currentStep = WizardStep.selectCategorie
          state.installaties = []
          state.selectedInstallatie = {} as IInstallatie
          break
      }
    }
  },

  extraReducers: builder => {
    builder.addCase(initialize.pending, (state, action) => {
      state.status = 'pending'
      state.currentStep = WizardStep.selectCategorie
      state.entityId = action.meta.arg.relatedEntityId
      state.relatedEntityType = action.meta.arg.relatedEntityType
      state.selectedCategorie = {} as IconProps
      state.selectedElement = {} as IconProps
      state.selectedDetail = {} as IconProps
      state.installaties = []
      state.selectedInstallatie = {} as IInstallatie
    })
    builder.addCase(initialize.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.iconsToPresent = action.payload
    })
    builder.addCase(initialize.rejected, (state, action) => {
      state.currentStep = WizardStep.selectCategorie
      state.status = 'failed'
      state.error = action.error.message ?? null
    })

    builder.addCase(categorieSelected.pending, (state, action) => {
      state.currentStep = WizardStep.selectElement
      state.selectedCategorie = action.meta.arg.categorie
      state.selectedElement = {} as IconProps
      state.selectedDetail = {} as IconProps
      state.installaties = []
      state.selectedInstallatie = {} as IInstallatie
      state.status = 'pending'
    })
    builder.addCase(categorieSelected.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.iconsToPresent = action.payload
    })
    builder.addCase(categorieSelected.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message ?? null
    })

    builder.addCase(elementSelected.pending, (state, action) => {
      state.currentStep = WizardStep.selectElementDetail
      state.selectedElement = action.meta.arg.element
      state.selectedDetail = {} as IconProps
      state.status = 'pending'
    })
    builder.addCase(elementSelected.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.iconsToPresent = action.payload
    })
    builder.addCase(elementSelected.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message ?? null
    })

    builder.addCase(saveInstallatie.pending, (state) => {
      state.selectedDetail = {} as IconProps
      state.status = 'pending'
    })
    builder.addCase(saveInstallatie.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.installaties = [...state.installaties.filter(i => i.id !== action.payload.id), action.payload]
      state.currentStep = WizardStep.listInstallaties
    })
    builder.addCase(saveInstallatie.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message ?? null
    })

    builder.addCase(getAllInstallaties.pending, (state) => {
      state.status = 'pending'
    })
    builder.addCase(getAllInstallaties.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.installaties = action.payload
      state.selectedDetail = {} as IconProps
      state.selectedElement = {} as IconProps
      state.selectedCategorie = {} as IconProps
      state.selectedInstallatie = {} as IInstallatie
    })
    builder.addCase(getAllInstallaties.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message ?? null
    })

    builder.addCase(elementAdded.pending, (state, action) => {
      state.selectedElement = action.meta.arg.element
      state.status = 'pending'
    })
    builder.addCase(elementAdded.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.currentStep = WizardStep.addInstallatie
      state.iconsToPresent = action.payload
      state.selectedInstallatie = {
        // set element
        categorieId: state.selectedCategorie.id,
        categorieDisplay: state.selectedCategorie.name,
        elementId: action.meta.arg.element.id,
        elementDisplay: action.meta.arg.element.name,
        elementdetailId: undefined,
        elementdetailDisplay: undefined,
        vgeId: action.meta.arg.relatedEntityType === 'vge' ? action.meta.arg.relatedEntityId : undefined,
        gebouwId: action.meta.arg.relatedEntityType === 'gebouw' ? action.meta.arg.relatedEntityId : undefined,
        gebouwDeelId: action.meta.arg.relatedEntityType === 'gebouwdeel' ? action.meta.arg.relatedEntityId : undefined
      } as IInstallatie
    })
    builder.addCase(elementAdded.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message ?? null
    })

    builder.addCase(categorieAdded.pending, (state, action) => {
      state.selectedCategorie = action.meta.arg.categorie
      state.status = 'pending'
    })
    builder.addCase(categorieAdded.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.currentStep = WizardStep.addInstallatie
      state.iconsToPresent = action.payload
      state.selectedInstallatie = {
        categorieId: action.meta.arg.categorie.id,
        categorieDisplay: action.meta.arg.categorie.name,
        elementId: undefined,
        elementDisplay: undefined,
        elementdetailId: undefined,
        elementdetailDisplay: undefined,
        vgeId: action.meta.arg.relatedEntityType === 'vge' ? action.meta.arg.relatedEntityId : undefined,
        gebouwId: action.meta.arg.relatedEntityType === 'gebouw' ? action.meta.arg.relatedEntityId : undefined,
        gebouwDeelId: action.meta.arg.relatedEntityType === 'gebouwdeel' ? action.meta.arg.relatedEntityId : undefined
      } as IInstallatie
    })
    builder.addCase(categorieAdded.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message ?? null
    })

    builder.addCase(detailSelected.pending, (state, action) => {
      state.selectedDetail = action.meta.arg.elementdetail
      state.status = 'pending'
    })
    builder.addCase(detailSelected.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.installaties = action.payload

      const nrOfItems = action.meta.arg.elementdetail.numberOfItems
      switch (nrOfItems) {
        case 0:
          state.currentStep = WizardStep.addInstallatie
          state.selectedInstallatie = {
            categorieId: state.selectedCategorie.id,
            categorieDisplay: state.selectedCategorie.name,
            elementId: state.selectedElement.id,
            elementDisplay: state.selectedElement.name,
            elementdetailId: action.meta.arg.elementdetail.id,
            elementdetailDisplay: action.meta.arg.elementdetail.name,
            vgeId: action.meta.arg.relatedEntityType === 'vge' ? action.meta.arg.relatedEntityId : undefined,
            gebouwId: action.meta.arg.relatedEntityType === 'gebouw' ? action.meta.arg.relatedEntityId : undefined,
            gebouwDeelId: action.meta.arg.relatedEntityType === 'gebouwdeel' ? action.meta.arg.relatedEntityId : undefined
          } as IInstallatie
          break
        case 1:
          state.currentStep = WizardStep.editInstallatie
          state.selectedInstallatie = action.payload[0]
          break
        default:
          state.currentStep = WizardStep.listInstallaties
          state.selectedInstallatie = undefined
          break
      }
    })
    builder.addCase(detailSelected.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message ?? null
    })

    builder.addCase(deleteClicked.pending, (state) => {
      state.status = 'pending'
    })
    builder.addCase(deleteClicked.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.currentStep = WizardStep.listInstallaties
      state.selectedInstallatie = {} as IInstallatie
      state.installaties = state.installaties.filter(i => i.id !== action.meta.arg.installatieId)
    })
    builder.addCase(deleteClicked.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message ?? null
    })
  }
})

export const getIconsToPresent = (state: RootState) => getSliceState(state).iconsToPresent
export const getLoadingState = (state: RootState) => getSliceState(state).status
export const getError = (state: RootState) => getSliceState(state).error
export const getCurrentStep = (state: RootState) => getSliceState(state).currentStep
export const getSelectedCategorie = (state: RootState) => getSliceState(state).selectedCategorie
export const getSelectedElement = (state: RootState) => getSliceState(state).selectedElement
export const getSelectedDetail = (state: RootState) => getSliceState(state).selectedDetail
export const getSelectedInstallatie = (state: RootState) => getSliceState(state).selectedInstallatie
export const getInstallaties = (state: RootState) => getSliceState(state).installaties

export const installatieSelected = cartotheek.actions.installatieSelected
export const goBack = cartotheek.actions.goBack
export const newFromInstallatie = cartotheek.actions.newFromInstallatie

export default cartotheek.reducer