import * as yup from 'yup'

export interface IDimensieList {
  id: number;
  code: string;
  omschrijving: string;
  vgtDimensie: string;
  hasDimensieWaarden: boolean;
}

export interface IDimensieUpdateOrAdd {
  code: string;
  omschrijving: string;
  vgtDimensieId: number;
}

export const DimensieSchema = yup.object().shape({
  code: yup.string().max(20).required(),
  omschrijving: yup.string().max(500).required(),
  vgtDimensieId: yup.number().required(),
})