import React, { useMemo, useState } from 'react'
import { DefaultButton, FontIcon, IColumn, Link, mergeStyles, SelectionMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import FluentTable from 'components/FluentTable'
import { getPropertyName } from 'lib/interfaceUtils'
import EditPanelBouwlagen from './editPanel'
import { selectAll, select, clearSelection, fetchAllBouwlagen, deleteBouwlagen, getLoadingState, IBouwlaag, moveBouwlaag, removeMany } from './bouwlaagSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'

const iconButtonClass = mergeStyles({
  fontSize: 24,
  height: 24,
  width: 24,
  border: 'none',
})

interface IBouwlaagItem extends IBouwlaag {
    canMoveUp: boolean
    canMoveDown: boolean
}

const createBouwlaagItems = (items: IBouwlaag[]) => {
    return items.map((value: IBouwlaag, index: number) => {
        return {
            ...value,
            canMoveUp: index !== 0,
            canMoveDown: index !== items.length - 1
        } as IBouwlaagItem
    })
}

const BouwlagenList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const bouwlagen = useSelector(selectAll)
  const items = createBouwlaagItems(bouwlagen)
  const loadingState = useSelector(getLoadingState)
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const handleListSelectionChanged = (ids: string[]) => setSelectedItems(ids)
  const refreshItems = () => dispatch(fetchAllBouwlagen())
  const onGetKey = (item: IBouwlaag) => item.id.toString()
  const { isAdmin } = useRoles()

  const handleItemInvoked = (id: string) => {
    dispatch(select(id))
    openPanel()
  }

  const removeItems = () => {
    const ids = selectedItems.map(value => parseInt(value))
    dispatch(deleteBouwlagen(ids))
    dispatch(removeMany(ids))
    dispatch(clearSelection())
    setSelectedItems([])
  }

  const onMoveBouwlaag = (item: IBouwlaag, moveUp: boolean) => {
    dispatch(
      moveBouwlaag({
        id: item.id,
        moveUp: moveUp,
      })
    )
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<IBouwlaag>('naam'),
      name: 'Bouwlaag',
      fieldName: getPropertyName<IBouwlaag>('naam'),
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IBouwlaag) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.naam}
        </Link>
      ),
    },
    {
      key: getPropertyName<IBouwlaag>('naamExtern'),
      name: 'Bouwlaag extern',
      fieldName: getPropertyName<IBouwlaag>('naamExtern'),
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
      isRowHeader: true,
    },
    {
      key: 'moveUp',
      name: ' ',
      fieldName: 'moveUp',
      minWidth: 50,
      maxWidth: 50,
      isResizable: false,
      isRowHeader: true,
      onRender: (item: IBouwlaagItem) =>
        item.canMoveUp && (
          <DefaultButton onClick={() => onMoveBouwlaag(item, true)} className={iconButtonClass} title="Omhoog">
            <FontIcon iconName="CaretSolidUp" />
          </DefaultButton>
        ),
    },
    {
      key: 'moveDown',
      name: ' ',
      fieldName: 'moveDown',
      minWidth: 50,
      maxWidth: 50,
      isResizable: false,
      isRowHeader: true,
      onRender: (item: IBouwlaagItem) =>
        item.canMoveDown && (
          <DefaultButton onClick={() => onMoveBouwlaag(item, false)} className={iconButtonClass} title="Omlaag">
            <FontIcon iconName="CaretSolidDown" />
          </DefaultButton>
        ),
    },
  ]

  const commandItems = useMemo(() =>[
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    { visible: isAdmin, text: 'Toevoegen', onClick: () => { dispatch(clearSelection()); openPanel() }, icon: 'Add' },
    { visible: isAdmin, text: 'Verwijderen', onClick: () => removeItems(), icon: 'Delete', disabled: !selectedItems.length },
  ], [isAdmin, selectedItems.length])

  return (
    <MainTemplate title='Bouwlagen' subTitle='Overzicht' commandItems={commandItems}>
      <EditPanelBouwlagen dismissPanel={dismissPanel} isOpen={isPanelOpen} />
      <div style={{ height: '90vh'}}>
        <FluentTable
          columns={columns}
          items={items}
          loading={loadingState === 'pending'}
          selectionMode={SelectionMode.multiple}
          onSelectionChanged={handleListSelectionChanged}
          onGetKey={onGetKey}
          onItemInvoked={handleItemInvoked}
        />
      </div>
    </MainTemplate>
  )
}
export default BouwlagenList
