import { IColumn, Link, SelectionMode } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { createCustomRenderedColumn } from 'lib/gridHelper'
import { clearSelection, deleteDienstenComponent, fetchAll, select } from './dienstenComponentSlice'
import { fetchAll as fetchAllKostensoort, selectAll as selectAllKostensoorten } from '../kostensoort/kostensoortSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import FluentTable from 'components/FluentTable'
import { DienstenComponentEditPanel } from './editPanel'
import { useBoolean } from '@fluentui/react-hooks'
import { DienstenComponentDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { usePagedParams } from '../../../../services/usePagedParams'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'
import { z } from 'zod'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

type DienstenComponentDtoType = z.infer<typeof DienstenComponentDto>;

const DienstenComponentList: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const kostensoorten = useSelector(selectAllKostensoorten)
  const { validatedSearchParams, setFilter } = usePagedParams()
  const [error, setError] = React.useState<string>()

  const {
    data: dienstenComponentItems,
    invalidate: fetchDienstenComponentItems,
    isLoading: isFetchingDienstenComponentItems
  } = zodiosHooks.useGetDienstenComponent({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  useEffect(() => {
    dispatch(fetchAll({ filter: '' }))
    dispatch(fetchAllKostensoort())
  }, [])

  const handleItemInvoked = (id: string) => {
    dispatch(select(id))
    openPanel()
  }

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => fetchDienstenComponentItems(), icon: 'Refresh' },
    {
      text: 'Toevoegen', onClick: () => {
        dispatch(clearSelection())
        openPanel()
      }, icon: 'Add'
    },
    {
      text: 'Verwijderen', onClick: () =>
        dispatch(
          deleteDienstenComponent(
            selectedItems.map(value => parseInt(value))
          )
        )
      , icon: 'Delete', disabled: !selectedItems.length
    }
  ], [selectedItems.length])

  const renderCode = (x: DienstenComponentDtoType) => <Link key={x.dienstencomponentId}
                                                            onClick={() => handleItemInvoked(x.dienstencomponentId.toString())}>{x.code}</Link>

  const renderKostensoort = (kostensoortId: number) => {
    return <>{kostensoorten.find(x => x.kostensoortId === kostensoortId)?.omschrijving}</>
  }

  const columns: IColumn[] = [
    createCustomRenderedColumn('Code', getPropertyName<DienstenComponentDtoType>('code'), renderCode, 'XXL', false),
    createCustomRenderedColumn('Omschrijving', getPropertyName<DienstenComponentDtoType>('omschrijving'), (x: DienstenComponentDtoType) => <>{x.omschrijving}</>
      , 'MAX', false, true),
    createCustomRenderedColumn('Kostensoort', getPropertyName<DienstenComponentDtoType>('kostensoortId'), (x: DienstenComponentDtoType) => renderKostensoort(x.kostensoortId!), 'XXL', false)
  ]

  return (
    <MainTemplate title='Dienstencomponenten' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <DienstenComponentEditPanel
        dismissPanel={dismissPanel}
        isOpen={isPanelOpen}
      />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <div style={{ height: '77vh' }}>
        <FluentTable
          columns={columns}
          items={dienstenComponentItems?.items ?? []}
          loading={isFetchingDienstenComponentItems}
          selectionMode={SelectionMode.multiple}
          onSelectionChanged={(ids: string[]) => setSelectedItems(ids)}
          onGetKey={(item: DienstenComponentDtoType) => item.dienstencomponentId.toString()}
          onItemInvoked={(id: string) => {
            handleItemInvoked(id)
          }}
        />
      </div>
    </MainTemplate>
  )
}

export default DienstenComponentList