import React from 'react'
import { DatePicker, DayOfWeek, IDatePickerStrings, mergeStyleSets, IDatePickerProps, IDatePickerStyles } from '@fluentui/react'

const DayPickerStrings: IDatePickerStrings = {
  months: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],

  shortMonths: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],

  days: ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'],

  shortDays: ['M', 'D', 'W', 'D', 'V', 'Z', 'Z'],

  goToToday: 'Naar vandaag',
  prevMonthAriaLabel: 'Naar vorige maand',
  nextMonthAriaLabel: 'Naar volgende maand',
  prevYearAriaLabel: 'Naar vorig jaar',
  nextYearAriaLabel: 'Naar volgend jaar',
  closeButtonAriaLabel: 'Datumkiezer sluiten',
  monthPickerHeaderAriaLabel: '{0}, selecteer om de maand te wijzigen',
  yearPickerHeaderAriaLabel: '{0}, selecteer om het jaar te wijzigen',

  isRequiredErrorMessage: 'Dit veld is verplicht.',

  invalidInputErrorMessage: 'Ongeldig datumformaat.',
}

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px',
  },
  wrapper: {
    borderBottom: '1px solid rgb(219 219 219)',
  },
})

const datePickerStyles: Partial<IDatePickerStyles> = {
  root: {
    padding: '5px 0px 5px 12px',
    display: 'flex',
    borderBottom: '1px solid rgb(219 219 219)',
    width: '100%',
  },
  textField: {
    label: {
      fontWeight: 'Normal',
      width: '200px',
      flexGrow: '0',
      flexShrink: '0',
    },
  },
}

export interface IFluentDatePickerProps extends IDatePickerProps {
  className?: string
  datePickerStyles?: Partial<IDatePickerStyles>
}

const formatDate = (date?: Date) => date?.toLocaleDateString() || ''

const FluentDatePicker: React.FC<IFluentDatePickerProps> = props => {
  const styles = props.datePickerStyles ?? datePickerStyles
  const className = props.className ?? controlClass.control
  return (
    <div>
      <DatePicker
        className={className}
        firstDayOfWeek={DayOfWeek.Monday}
        strings={DayPickerStrings}
        placeholder="Kies een datum..."
        ariaLabel="Kies een datum"
        formatDate={formatDate}
        styles={styles}
        {...props}
      />
    </div>
  )
}

export default FluentDatePicker
