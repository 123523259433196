import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, EntityState } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import IEenheidDetailSoort from './eenheidDetailSoortModel'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'
import { IDropdownOption } from '@fluentui/react'

const entityAdapter = createEntityAdapter<IEenheidDetailSoort>({
  sortComparer: (a, b) => a.code?.localeCompare(b.code),
})

const baseUrl = '/EenheidDetailSoort'
const basePrefix = 'EenheidDetailSoort'

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
  return await invokeFetch<PagedEntities<IEenheidDetailSoort>>(thunkAPI, 'GET', `${baseUrl}`)
})

const setPendingState = (state: EntityState<IEenheidDetailSoort> & IEntityAdapterState) => {
  state.error = null
  state.status = 'pending'
}
const setSucceededState = (state: EntityState<IEenheidDetailSoort> & IEntityAdapterState) => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<IEenheidDetailSoort> & IEntityAdapterState, action) => {
  state.status = 'failed'
  state.error = action.error.message || null
}

export const eenheidDetailSoortSlice = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
    clearError: state => {
      state.error = null
    },
    select: (state, action: PayloadAction<string | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
  },

  extraReducers: builder => {
    builder.addCase(fetchAll.pending, state => setPendingState(state))
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IEenheidDetailSoort>>) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const getOptionsForVastgoedeenheidwijziging = (state: RootState) => {
  const options: IDropdownOption[] = []

  state.eenheidDetailSoorten.ids.forEach(id => {
    options.push({ key: state.eenheidDetailSoorten.entities[id]?.code, text: state.eenheidDetailSoorten.entities[id]?.naam } as IDropdownOption)
  })
  return options
}

export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.eenheidDetailSoorten)

export default eenheidDetailSoortSlice.reducer
