import React from 'react'
import { IDropdownOption, Panel, PanelType } from '@fluentui/react'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { z } from 'zod'
import { InspectietaakAddOrUpdateDto, zodiosHooks } from '../../../../api/ApiClient'
import VgtDropdown from '../../../../components/FluentDropdown'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import VgtTextField from '../../../../components/VgtTextField'
import VgtDecimalField from '../../../../components/VgtDecimalField'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'


export interface IEditPanelProps {
  inspectietaakId: number,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof InspectietaakAddOrUpdateDto>;

export const InspectietakenFormPanel: React.FC<IEditPanelProps> = props => {

  const [error, setError] = React.useState<string>()

  const { data: elementElementDetails } = zodiosHooks.useGetInspectieOpnamedetailCartotheekmatrixLeft({})
  const elementElementDetailOpties = elementElementDetails?.map((item) => {
    return {
      key: item.id,
      text: item.naam
    } as IDropdownOption
  })

  const { data: werkzaamheden } = zodiosHooks.useGetApiWerkzaamheden({})
  const werkzaamhedenOpties = werkzaamheden?.items?.map((item) => {
    return {
      key: item.werkzaamheidId,
      text: item.omschrijving
    } as IDropdownOption
  })

  const { data: meetkundigeEenheden } = zodiosHooks.useGetApiMeetkundigeEenhedenfetchAll({})
  const meetkundigeEenhedenOpties = meetkundigeEenheden?.items?.map((item) => {
    return {
      key: item.id,
      text: item.code + ' - ' + item.omschrijving
    } as IDropdownOption
  })

  const defaultInspectietaken = {
    elementElementId: -1,
    werkzaamheidId: -1,
    meetkundigeEenheidHlbId: -1,
    meetkundigeEenheidId: -1,
    toelichting: '',
    type: '',
    merk: '',
    hoogte: 0,
    lengte: 0,
    breedte: 0,
    bestekcode: '',
    taak: '',
    verkorteInspectietaak: ''
  } as CreateUpdateType

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(InspectietaakAddOrUpdateDto), mode: 'all', defaultValues: defaultInspectietaken
  })

  const onCancel = () => {
    props.invalidate()
    reset({})
    props.dismissPanel()
  }

  zodiosHooks.useGetApiInspectietaakId({ params: { id: props.inspectietaakId } }, {
      onSuccess: (data) => reset(data),
      enabled: props.inspectietaakId !== 0
    }
  )

  const { mutate: addInspectietaak, isLoading: isAdding } = zodiosHooks.usePostApiInspectietaak({}, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const {
    mutate: updateInspectietaak,
    isLoading: isModifying
  } = zodiosHooks.usePutApiInspectietaakId({ params: { id: props.inspectietaakId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const onSubmit = (data: CreateUpdateType) => {
    setError(undefined)
    if (props.inspectietaakId !== 0) {
      updateInspectietaak(data)
    } else {
      addInspectietaak(data)
    }
  }

  return (
    <div>
      <Panel type={PanelType.large}
             headerText={`Inspectietaken ${props.inspectietaakId !== 0 ? 'wijzigen' : 'toevoegen'}`}
             isOpen={props.isOpen}
             onDismiss={onCancel}
             closeButtonAriaLabel="Sluiten"

      >
        <ErrorMessageBar error={error} />
        <div style={{ marginBottom: 16, borderBottom: '1px solid rgb(207, 207, 207)' }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtDropdown label="Cartotheek-item" name={getPropertyName<CreateUpdateType>('elementElementId')}
                       control={control} options={elementElementDetailOpties} errors={errors}
                       required={true}
          />
          <VgtDropdown label="Werkzaamheid" name={getPropertyName<CreateUpdateType>('werkzaamheidId')}
                       control={control} options={werkzaamhedenOpties} errors={errors}
                       required={true}
          />
          <VgtTextField label="Toelichting" name={getPropertyName<CreateUpdateType>('toelichting')} control={control}
                        errors={errors} defaultValue={''} />
          <VgtTextField label="Type" name={getPropertyName<CreateUpdateType>('type')} control={control}
                        errors={errors} defaultValue={''} />
          <VgtTextField label="Merk" name={getPropertyName<CreateUpdateType>('merk')} control={control}
                        errors={errors} defaultValue={''} />
          <VgtDecimalField label="Hoogte" name={getPropertyName<CreateUpdateType>('hoogte')} control={control}
                           errors={errors} defaultValue={0} />
          <VgtDecimalField label="Lengte" name={getPropertyName<CreateUpdateType>('lengte')} control={control}
                           errors={errors} defaultValue={0} />
          <VgtDecimalField label="Breedte" name={getPropertyName<CreateUpdateType>('breedte')} control={control}
                           errors={errors} defaultValue={0} />
          <VgtDropdown label="Meetkundige eenheid (HLB)"
                       name={getPropertyName<CreateUpdateType>('meetkundigeEenheidHlbId')}
                       control={control} options={meetkundigeEenhedenOpties} errors={errors} required />

          <VgtTextField label="Bestekcode" name={getPropertyName<CreateUpdateType>('bestekcode')} control={control}
                        errors={errors} required defaultValue={''} />
          <VgtDropdown label="Meetkundige eenheid" name={getPropertyName<CreateUpdateType>('meetkundigeEenheidId')}
                       control={control} options={meetkundigeEenhedenOpties} errors={errors}
                       required={true}
          />
          <VgtTextField label="Inspectietaak" name={getPropertyName<CreateUpdateType>('taak')} control={control}
                        errors={errors} required defaultValue={''} />
          <VgtTextField label="Verkorte Inspectietaak" name={getPropertyName<CreateUpdateType>('verkorteInspectietaak')} control={control}
                        errors={errors} required defaultValue={''} />
          <OkCancelButtonStack isSubmitting={isModifying || isAdding}
                               isLoading={false}
                               onOkClick={handleSubmit(onSubmit, (errors) => {
                                 setError('Kan niet opslaan vanwege validatiefouten op de invoer.')
                               })}
                               onCancelClick={onCancel} />
        </form>
      </Panel>
    </div>
  )
}
