import {ITextFieldStyles, TextField} from '@fluentui/react'
import { useEffect, useState } from 'react'

export interface INumericVgtInputProps {
    value: number | undefined | null
    onChange: (number?: number) => void
    onEditing?: (editing: boolean) => void
    label?: string
    errors: any
    name: string
}

const NumericVgtInput: React.FC<INumericVgtInputProps> = (props: INumericVgtInputProps) => {
    const labelWidth = 215
    const textFieldStyles: Partial<ITextFieldStyles> = {
        wrapper: {
            borderRadius: '5px',
            border: '1px solid rgb(255 0 0 / 6%)'
        },
        subComponentStyles: {
            label: {
                root: { width: labelWidth, fontWeight: 'normal' },
            },
        },
        field: {
            fontWeight: 600,
        },
        suffix: {
            background: '',
        },
    }

    const regExNumber = /^(\d+(\.\d+)?).*/
    const [text, setText] = useState<string>()
    const [localNumber, setLocalNumber] = useState<number>()
    const [lastValidNumber, setLastValidNumber] = useState<number>()

    useEffect(() => {
        if (props.value) {
            setLocalNumber(props.value)
            setLastValidNumber(props.value)
        } else {
            setLocalNumber(undefined)
            setLastValidNumber(undefined)
        }

        const textAsNumber = props.value?.toLocaleString('nl-NL')
        setText(textAsNumber)
    }, [props.value])

    const fireUpdate = () => {
        if (props.onEditing) props.onEditing(false)

        let hasChanged = false
        if (lastValidNumber && localNumber) hasChanged = lastValidNumber !== localNumber
        else if ((lastValidNumber && !localNumber) || (!lastValidNumber && localNumber)) hasChanged = true
        if (hasChanged) {
            props.onChange(localNumber)
            setLastValidNumber(localNumber)
        }
    }

    const clearNumber = () => {
        setLocalNumber(undefined)
        setText(undefined)
    }

    const onChangeText = (text: string | undefined) => {
        if (props.onEditing) props.onEditing(true)

        if (text) {
            if (regExNumber.test(text)) {
                const numberText = text.replace(/\./g, '').replace(',', '.')
                let number = Number(numberText)
                if (!isNaN(number) && number <= 999999.99) {
                    setLocalNumber(number)
                    setText(text)
                }
            } else {
                clearNumber()
            }
        } else {
            clearNumber()
        }
    }

    return <TextField label={props.label}
                      value={text}
                      onChange={(_, item) => onChangeText(item)}
                      onBlur={() => fireUpdate()}
                      borderless
                      underlined
                      errorMessage={props.errors[props.name]?.message}
                      styles={textFieldStyles}
    />
}

export default NumericVgtInput
