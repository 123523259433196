import { IColumn, Stack, StackItem } from '@fluentui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getPropertyName } from 'lib/interfaceUtils'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { PageIdDefinition, PAGES } from 'pages'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { VerwerkingDto, zodiosHooks } from '../../../api/ApiClient'
import { getTitleAndMessage } from '../../../services/HandleError'
import { z } from 'zod'
import { VgtSearch } from '../../../components/VgeSearch/VgtSearch'
import { usePagedParams } from '../../../services/usePagedParams'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import ClipboardButton from '../../../components/VgtClipboardButton'

const formatToDateTimeString = (date: string | null) => {
  if (!date) return ''
  const parsedDate = new Date(date)
  return parsedDate.toLocaleDateString('nl-NL', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  })
}

type VerwerkingDtoType = z.infer<typeof VerwerkingDto>;

const Verwerkingen: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const { validatedSearchParams, setFilter, setPage, setOrder } = usePagedParams()
  const [error, setError] = useState<string>()
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const {
    data: verwerkingItems,
    invalidate: fetchVerwerkingItems,
    isLoading: isFetchingVerwerkingItems
  } = zodiosHooks.useGetVerwerking({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: "desc",
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('VerwerkingNr', getPropertyName<VerwerkingDtoType>('id'), 90),
      createColumn('Beschrijving', getPropertyName<VerwerkingDtoType>('beschrijving'), 'XL'),
      createCustomRenderedColumn('Gestart op', getPropertyName<VerwerkingDtoType>('gestartOp'), (x: VerwerkingDtoType) => <>{formatToDateTimeString(x.gestartOp)}</>, 'M', false, false),
      createCustomRenderedColumn('Gereed op', getPropertyName<VerwerkingDtoType>('gereedOp'), (x: VerwerkingDtoType) => <>{formatToDateTimeString(x.gereedOp)}</>, 'M', false, false),
      createColumn('Gestart door', getPropertyName<VerwerkingDtoType>('gestartDoor'), 'XL'),
      createColumn('Status', getPropertyName<VerwerkingDtoType>('status'), 'XL'),
      createColumn('Foutmelding', getPropertyName<VerwerkingDtoType>('foutmelding'), 'XXL'),
      {
        key: 'foutmelding-clipboard',
        name: '',
        fieldName: getPropertyName<VerwerkingDtoType>('foutmelding'),
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        onRender: (x: VerwerkingDtoType) => x.foutmelding ?
          <ClipboardButton id={x.id} message={x.foutmelding} /> : null
      }
    ]
  }, [])

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => fetchVerwerkingItems(), icon: 'Refresh' },
    {
      text: 'Naar regels',
      onClick: () => navigate(PAGES[PageIdDefinition.beheer.imports.verwerkingsRegels].route.replace(':verwerkingen', selectedIds.join(','))),
      icon: 'Forward',
      disabled: !selectedIds.length
    }
  ]

  return (
    <MainTemplate title='Verwerkingen' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <Stack horizontal verticalAlign={'center'} tokens={{ childrenGap: 10 }}>
        <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
        <StackItem><span>{verwerkingItems?.items?.length} verwerkingen gevonden.</span></StackItem>
      </Stack>
      <VgtPagedTable
        items={verwerkingItems?.items ?? []}
        isLoading={isFetchingVerwerkingItems}
        height={'203'}
        columns={columns}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: verwerkingItems?.totalCount ?? 0,
          totalPages: verwerkingItems?.totalPages ?? 0,
          hasNextPage: verwerkingItems?.hasNextPage ?? false,
          hasPreviousPage: verwerkingItems?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        onItemInvoked={() => null}
        onSelectionChanged={setSelectedIds}
        getKey={(item) => item?.id}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}

export default Verwerkingen
