import React from 'react'
import useRoles from '../../../services/UseRoles'
import { z } from 'zod'
import { DocumentClassificationAddOrUpdateDto, zodiosHooks } from '../../../api/ApiClient'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { IDropdownOption, Panel, PanelType } from '@fluentui/react'
import VgtDropdown from '../../../components/FluentDropdown'
import { getPropertyName } from '../../../lib/interfaceUtils'
import VgtCheckbox from '../../../components/FluentCheckbox'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'
import { getTitleAndMessage } from '../../../services/HandleError'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'

export interface IEditPanelProps {
  documentSoortKenmerkId: number,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof DocumentClassificationAddOrUpdateDto>;

export const DocumentClassificationFormPanel: React.FC<IEditPanelProps> = props => {

  const { isAdmin } = useRoles()
  const [error, setError] = React.useState<string>()

  const isNew = (props.documentSoortKenmerkId ?? 0) === 0

  const defaultDocumentClassification = {
    documentSoortKenmerkId: 0,
    documentSoortId: 0,
    documentKenmerkId: 0,
    isVge: false,
    isGebouw: false,
    isInstallatie: false,
    isGebouwCluster: false,
    isGebouwdeel: false,
    isOnderhoudscontract: false,
    isVhe: false,
    isVgeRuimte: false
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(DocumentClassificationAddOrUpdateDto),
    mode: 'all',
    defaultValues: defaultDocumentClassification
  })
  const onCancel = () => {
    props.invalidate()
    reset({})
    props.dismissPanel()
  }

  const { data: documentSoorten } = zodiosHooks.useGetApiDocumentssoorten()
  const { data: documentKenmerken } = zodiosHooks.useGetApiDocumentskenmerken()

  const soortOptie = documentSoorten?.items?.map(t => {
    return { key: t.documentSoortId, text: t.omschrijving } as IDropdownOption
  }) ?? []

  const kenmerkOptie = documentKenmerken?.items?.map(t => {
    return { key: t.documentKenmerkId, text: t.omschrijving } as IDropdownOption
  }) ?? []

  const { mutate: addDocumentClassification, isLoading: isAdding } = zodiosHooks.usePostApiDocumentsclassifications({}, {
    onSuccess: () => onCancel(),
    onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const { mutate: updateDocumentClassification, isLoading: isModifying } = zodiosHooks.usePutApiDocumentsclassificationsId({ params: { id: props.documentSoortKenmerkId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  zodiosHooks.useGetApiDocumentsclassificationsId({ params: { id: props.documentSoortKenmerkId } }, {
      onSuccess: (data) => reset(data),
      enabled: props.documentSoortKenmerkId !== 0
    }
  )

  const onSubmit = (data: CreateUpdateType) => {
    setError(undefined)
    if (props.documentSoortKenmerkId !== 0) {
      updateDocumentClassification(data)
    } else {
      addDocumentClassification(data)
    }
  }

  return (
    <Panel type={PanelType.medium}
           headerText={`Document Classificatie ${props.documentSoortKenmerkId !== 0 ? 'wijzigen' : 'toevoegen'}`}
           isOpen={props.isOpen}
           onDismiss={onCancel}
           closeButtonAriaLabel="Sluiten">
      <br />
      <ErrorMessageBar error={error} />
      <form onSubmit={handleSubmit(onSubmit)}>
      <VgtDropdown
        label="Soort"
        name={getPropertyName<CreateUpdateType>('documentSoortId')}
        register={register}
        control={control}
        errors={errors}
        options={soortOptie}
        disabled={!isNew}
        readOnly={!isAdmin}
        required={isNew}
        defaultSelectedKey={-1}
      />
      <VgtDropdown
        label="Kenmerk"
        name={getPropertyName<CreateUpdateType>('documentKenmerkId')}
        register={register}
        control={control}
        errors={errors}
        options={kenmerkOptie}
        disabled={!isNew}
        readOnly={!isAdmin}
        required={isNew}
        defaultSelectedKey={-1}
      />

      <VgtCheckbox label="Vastgoedeenheid" name={getPropertyName<CreateUpdateType>('isVge')} register={register} control={control} readOnly={!isAdmin} />
      <VgtCheckbox label="Gebouw" name={getPropertyName<CreateUpdateType>('isGebouw')} register={register} control={control} readOnly={!isAdmin} />
      <VgtCheckbox label="Gebouwdeel" name={getPropertyName<CreateUpdateType>('isGebouwdeel')} register={register} control={control} readOnly={!isAdmin} />
      <VgtCheckbox label="Gebouwencluster" name={getPropertyName<CreateUpdateType>('isGebouwCluster')} register={register} control={control} readOnly={!isAdmin} />
      <VgtCheckbox label="Cartotheek-Item" name={getPropertyName<CreateUpdateType>('isInstallatie')} register={register} control={control} readOnly={!isAdmin} />
      <VgtCheckbox label="Onderhoudscontract" name={getPropertyName<CreateUpdateType>('isOnderhoudscontract')} register={register} control={control} readOnly={!isAdmin} />
      <VgtCheckbox label="Ruimte" name={getPropertyName<CreateUpdateType>('isVgeRuimte')} register={register} control={control} readOnly={!isAdmin} />
      <VgtCheckbox label="Vhe" name={getPropertyName<CreateUpdateType>('isVhe')} register={register} control={control} readOnly={!isAdmin} />
      <OkCancelButtonStack isWriter={isAdmin} isSubmitting={isModifying || isAdding || isSubmitting} isLoading={false}
                           onOkClick={handleSubmit(onSubmit, (errors) => {
                             setError('Kan niet opslaan vanwege validatiefouten op de invoer.')
                             console.log(errors)
                           })} onCancelClick={onCancel} />
    </form>
    </Panel>
  )
}

export default DocumentClassificationFormPanel