import InputCard from "components/InputCard";
import { ReadOnlyField } from '../IReadonlyFieldProps';
import { IVve } from '../vveSlice';

export const RechtspersoonCard: React.FC<{ vve?: IVve }> = ({ vve }) => <InputCard title="Rechtspersoon" width="100%">
    <ReadOnlyField title="Naam volgens akte" value={vve?.naamOpAkte} />
    <ReadOnlyField title="Type splitsing" value={vve?.typeSplitsing} />
    <ReadOnlyField title="Gerechtigde Kadaster" value={vve?.gerechtigdeNummer} />
    <ReadOnlyField title="Oprichtingsdatum" value={vve?.oprichtingsdatum} />
    <ReadOnlyField title="Handelsnaam" value={vve?.handelsnaam} />
    <ReadOnlyField title="KVK nummer" value={vve?.kvkNummer} />
    <ReadOnlyField title="Adres" value={vve?.straat} />
    <ReadOnlyField title="Postcode" value={vve?.postcode} />
    <ReadOnlyField title="Woonplaats" value={vve?.woonplaats} />
</InputCard>;
