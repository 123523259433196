import React, {useState} from "react";
import {
    CommandBar,
    IColumn,
    ICommandBarItemProps,
    MessageBar,
    MessageBarType,
    SelectionMode,
    Stack,
    StackItem,
    Text,
    Toggle
} from "@fluentui/react";
import commandBarStyles from "../../../styles/commandBarStyles";
import TitleTemplate from "../../../containers/pages/PageTemplates/TitleTemplate";
import {useNavigate} from "react-router-dom";
import FluentTable from "../../../components/FluentTable";
import {InspectiecategorieActiefDto, zodiosHooks} from "../../../api/ApiClient";
import {getTitleAndMessage} from "../../../services/HandleError";
import {z} from 'zod';

type InspectiecategorieActiefType = z.infer<typeof InspectiecategorieActiefDto>;
const onGetKey = (item: InspectiecategorieActiefType) => item.inspectieCategorieActiefId.toString()

const Categorieen: React.FC<{}> = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string>()
    // data
    const {data: dataNew, invalidate, isLoading} = zodiosHooks.useGetInspectiecategorieActief();
    const {mutate: updateData} = zodiosHooks.usePutInspectiecategorieActief(
        { }, {
            onSuccess: () => {
                invalidate();
            },
            onError: (error) => setError(getTitleAndMessage(error).message)
        }
    );
    
    const handleToggleChange = (item: InspectiecategorieActiefType, propertyName: keyof InspectiecategorieActiefType, checked: boolean | undefined) => {
        updateData({...item, [propertyName]: checked});
    };

    const onHandleItemInvoked = () => {}

    const renderCategorie = (item: InspectiecategorieActiefType) => {
       return (
            <Text key={item.inspectieCategorieActiefId}>
                {item.inspectieCategorieNaam}
            </Text>
        )
    };

    const renderVooropname = (item: InspectiecategorieActiefType) => {
        return (
            <Toggle defaultChecked={item.vooropname}
                    onChange={(ev , checked) => handleToggleChange(item, 'vooropname', checked)}/>
        )
    };

    const renderEindopname = (item: InspectiecategorieActiefType) => {
        return (
            <Toggle defaultChecked={item.eindopname}
                    onChange={(ev, checked) => handleToggleChange(item, 'eindopname', checked)}
            />
        );
    };

    const renderTweedeEindopname = (item: InspectiecategorieActiefType) => {
        return (
            <Toggle defaultChecked={item.tweedeEindopname}
                    onChange={(ev, checked) => handleToggleChange(item, 'tweedeEindopname', checked)}
            />
        );
    };

    const renderAanvangsopname = (item: InspectiecategorieActiefType) => {
        return (
            <Toggle defaultChecked={item.aanvangsopname}
                    onChange={(ev, checked) => handleToggleChange(item, 'aanvangsopname', checked)}/>
        );
    };

    const renderOnderhoudsinspectie = (item: InspectiecategorieActiefType) => {
        return (
          <Toggle defaultChecked={item.onderhoudsinspectie}
                  onChange={(ev, checked) => handleToggleChange(item, 'onderhoudsinspectie', checked)}/>
        );
    };

    const renderOpleverinspectie = (item: InspectiecategorieActiefType) => {
        return (
          <Toggle defaultChecked={item.opleverinspectie}
                  onChange={(ev, checked) => handleToggleChange(item, 'opleverinspectie', checked)}/>
        );
    };

    const renderVastgoedinspectie = (item: InspectiecategorieActiefType) => {
        return (
          <Toggle defaultChecked={item.vastgoedinspectie}
                  onChange={(ev, checked) => handleToggleChange(item, 'vastgoedinspectie', checked)}/>
        );
    };

    const columns = [
        {
            key: 'Categorie',
            fieldName: 'Categorie',
            name: 'Categorie',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isRowHeader: true,
            onRender: (item) => renderCategorie(item),
        },
        {
            key: 'Vooropname',
            fieldName: 'Vooropname',
            name: 'Vooropname',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isRowHeader: true,
            onRender: (item) => renderVooropname(item),
        },
        {
            key: 'Eindopname',
            fieldName: 'Eindopname',
            name: 'Eindopname',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isRowHeader: true,
            onRender: (item) => renderEindopname(item),
        },
        {
            key: 'TweedeEindopname',
            fieldName: 'tweedeEindopname',
            name: 'Tweede eindopname',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isRowHeader: true,
            onRender: (item) => renderTweedeEindopname(item),
        },        
        {
            key: 'Aanvangsopname',
            fieldName: 'Aanvangsopname',
            name: 'Aanvangsopname',
            minWidth: 200,
            maxWidth: 200,
            isResizable: false,
            isRowHeader: true,
            onRender: (item) => renderAanvangsopname(item),
        },
        {
            key: 'Onderhoudsinspectie',
            fieldName: 'Onderhoudsinspectie',
            name: 'Onderhoudsinspectie',
            minWidth: 200,
            maxWidth: 200,
            isResizable: false,
            isRowHeader: true,
            onRender: (item) => renderOnderhoudsinspectie(item),
        },
        {
            key: 'Opleverinspectie',
            fieldName: 'Opleverinspectie',
            name: 'Opleverinspectie',
            minWidth: 200,
            maxWidth: 200,
            isResizable: false,
            isRowHeader: true,
            onRender: (item) => renderOpleverinspectie(item),
        },
        {
            key: 'Vastgoedinspectie',
            fieldName: 'Vastgoedinspectie',
            name: 'Vastgoedinspectie',
            minWidth: 200,
            maxWidth: 200,
            isResizable: false,
            isRowHeader: true,
            onRender: (item) => renderVastgoedinspectie(item),
        }

    ] as IColumn[]
    
    const commandBarItems = [
        {
            key: 'back',
            iconOnly: true,
            iconProps: {iconName: 'Back', className: 'icon'},
            split: false,
            ariaLabel: 'back',
            onClick: () => navigate(-1),
        }
    ] as ICommandBarItemProps[]


    if (dataNew?.items) {
        const order = [1, 2, 3, 6, 4, 5];

        dataNew.items.sort((a, b) => {
            return order.indexOf(a.inspectieCategorie) - order.indexOf(b.inspectieCategorie);
        });
    }
    return (
        <div className="componentWrapper">
            <Stack horizontal tokens={{childrenGap: 5}}>
                <StackItem align="baseline" grow={3}>
                    <CommandBar items={commandBarItems} styles={commandBarStyles} />
                </StackItem>
            </Stack>
            <TitleTemplate title="Categorieën" subTitle="Overzicht"/>
            {error !== undefined &&
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    dismissButtonAriaLabel='Close'
                >
                    <b>{'Fout opgetreden'}.</b>&ensp;{error}
                </MessageBar>
            }
            <div className="listContent" style={{margin: "0 10px"}}>
                <FluentTable
                    columns={columns}
                    items={dataNew?.items ?? []}
                    loading={isLoading}
                    selectionMode={SelectionMode.none}
                    onGetKey={onGetKey}
                    onItemInvoked={onHandleItemInvoked}
                />
            </div>
        </div>
    )
}

export default  Categorieen;