import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePagedParams } from '../../../services/usePagedParams'
import { useBoolean } from '@fluentui/react-hooks'
import useRoles from '../../../services/UseRoles'
import { VastgoeddeelportefeuilleListDto, zodiosHooks } from '../../../api/ApiClient'
import { getTitleAndMessage } from '../../../services/HandleError'
import { IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import { VastgoeddeelportefeuilleFormPanel } from './VastgoeddeelportefeuilleFormPanel'


type VastgoeddeelportefeuilleListDtoType = z.infer<typeof VastgoeddeelportefeuilleListDto>

export const VastgoeddeelportefeuilleList = () => {

  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [itemToEdit, setItemToEdit] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const { isVastgoedbeheer } = useRoles()
  const [error, setError] = React.useState<string>()


  const { data, invalidate, isLoading } = zodiosHooks.useGetVastgoeddeelportefeuille({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const handleItemInvoked = (id: string) => {
    if (id) {
      setItemToEdit(parseInt(id))
      openPanel()
    }
  }

  const handleDismissPanel = () => {
    setItemToEdit(0)
    dismissPanel()
  }

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteVastgoeddeelportefeuille({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Naam', getPropertyName<VastgoeddeelportefeuilleListDtoType>('naam'), 'XL', true,
        (item: VastgoeddeelportefeuilleListDtoType) => (
          <Link key={item.vastgoeddeelportefeuilleId}
                onClick={() => handleItemInvoked(item.vastgoeddeelportefeuilleId.toString())}>
            {item.naam}
          </Link>
        )),
      createColumn('Code', getPropertyName<VastgoeddeelportefeuilleListDtoType>('code'), 'S', false),
      createColumn('Vastgoedportefeuille', getPropertyName<VastgoeddeelportefeuilleListDtoType>('vastgoedportefeuilleNaam'), 'XL', false),
    ]
  }, [])

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
    {
      text: 'Toevoegen',
      onClick: () => {
        openPanel()
      },
      icon: 'Add',
      iconOnly: true,
      visible: isVastgoedbeheer
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        deleteRows({ itemsToDelete: selectedIds })
      },
      icon: 'Delete',
      iconOnly: true,
      disabled: isDeleting || selectedIds.length === 0
    }
  ], [isVastgoedbeheer, selectedIds.length])

  return (

    <MainTemplate title="Vastgoeddeelportefeuille" subTitle="overzicht" commandItems={commandItems} error={error}>
      <VastgoeddeelportefeuilleFormPanel vastgoeddeelportefeuilleId={itemToEdit} dismissPanel={handleDismissPanel}
                                         isOpen={isPanelOpen}
                                         invalidate={invalidate} />
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount || 0,
          totalPages: data?.totalPages || 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.vastgoeddeelportefeuilleId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.vastgoeddeelportefeuilleId.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />

    </MainTemplate>
  )
}