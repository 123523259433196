import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { entityAdapterInitState } from 'interfaces/entityAdapterState'
import { setPendingState, setRejectedState, setSucceededState } from 'lib/sliceSupport'
import { IInstallatie } from './installatieTypes'

const entityAdapter = createEntityAdapter<IInstallatie>()

const baseUrl = '/api/installatie'
const basePrefix = 'installatie'
const getSliceState = (state: RootState) => state.installaties

export interface IFetchInstallatieVgeArgs {
  vgeId: number
}

export interface IFetchInstallatieGebouwArgs {
  gebouwId: number
}

export interface IFetchInstallatieGebouwDeelArgs {
  gebouwDeelId: number
}

export interface IInstallatieVgeArgs {
  vgeId: number
  installatie: IInstallatie
}

export interface IInstallatieGebouwArgs {
  gebouwId: number
  installatie: IInstallatie
}

export interface IInstallatieGebouwDeelArgs {
  gebouwDeelId: number
  installatie: IInstallatie
}

export interface IInstallatieDeleteVgeArgs {
  vgeId: number
  ids: number[]
}

export interface IInstallatieDeleteGebouwArgs {
  gebouwId: number
  ids: number[]
}

export interface IInstallatieDeleteGebouwDeelArgs {
  gebouwDeelId: number
  ids: number[]
}


export const fetchInstallatie = createAsyncThunk(`${basePrefix}/fetchInstallatie`, async (id: number, thunkAPI) => {
  return await invokeFetch<IInstallatie>(thunkAPI, 'GET', `${baseUrl}/installatie/${id}`)
})

export const fetchForVge = createAsyncThunk(`${basePrefix}/fetchForVge`, async (args: IFetchInstallatieVgeArgs, thunkAPI) => {
  return await invokeFetch<IInstallatie[]>(thunkAPI, 'GET', `${baseUrl}/vge?vgeId=${args.vgeId}`)
})

export const fetchForGebouw = createAsyncThunk(`${basePrefix}/fetchForGebouw`, async (args: IFetchInstallatieGebouwArgs, thunkAPI) => {
  return await invokeFetch<IInstallatie[]>(thunkAPI, 'GET', `${baseUrl}/gebouw?gebouwId=${args.gebouwId}`)
})

export const fetchForGebouwDeel = createAsyncThunk(`${basePrefix}/fetchForGebouwDeel`, async (args: IFetchInstallatieGebouwDeelArgs, thunkAPI) => {
  return await invokeFetch<IInstallatie[]>(thunkAPI, 'GET', `${baseUrl}/gebouwDeel?gebouwDeelId=${args.gebouwDeelId}`)
})

export const updateInstallatieVge = createAsyncThunk(`${basePrefix}/updateVge`, async (args: IInstallatieVgeArgs, thunkAPI) => {
  return await invokeFetch<IInstallatie>(thunkAPI, 'PUT', `${baseUrl}/vge?vgeId=${args.vgeId}`, args.installatie)
})

export const updateInstallatieGebouw = createAsyncThunk(`${basePrefix}/updateGebouw`, async (args: IInstallatieGebouwArgs, thunkAPI) => {
  return await invokeFetch<IInstallatie>(thunkAPI, 'PUT', `${baseUrl}/gebouw?gebouwId=${args.gebouwId}`, args.installatie)
})

export const updateInstallatieGebouwDeel = createAsyncThunk(`${basePrefix}/updateGebouwDeel`, async (args: IInstallatieGebouwDeelArgs, thunkAPI) => {
  return await invokeFetch<IInstallatie>(thunkAPI, 'PUT', `${baseUrl}/gebouwDeel?gebouwDeelId=${args.gebouwDeelId}`, args.installatie)
})

export const addInstallatieForVge = createAsyncThunk(`${basePrefix}/addVge`, async (args: IInstallatieVgeArgs, thunkAPI) => {
  return await invokeFetch<IInstallatie>(thunkAPI, 'POST', `${baseUrl}/vge?vgeId=${args.vgeId}`, args.installatie)
})

export const addInstallatieForGebouw = createAsyncThunk(`${basePrefix}/addGebouw`, async (args: IInstallatieGebouwArgs, thunkAPI) => {
  return await invokeFetch<IInstallatie>(thunkAPI, 'POST', `${baseUrl}/gebouw?gebouwId=${args.gebouwId}`, args.installatie)
})

export const addInstallatieForGebouwDeel = createAsyncThunk(`${basePrefix}/addGebouwDeel`, async (args: IInstallatieGebouwDeelArgs, thunkAPI) => {
  return await invokeFetch<IInstallatie>(thunkAPI, 'POST', `${baseUrl}/gebouwDeel?gebouwDeelId=${args.gebouwDeelId}`, args.installatie)
})

export const deleteInstallatieVge = createAsyncThunk(`${basePrefix}/deleteVge`, async (args: IInstallatieDeleteVgeArgs, thunkAPI) => {
  return await invokeFetch(thunkAPI, 'DELETE', `${baseUrl}/vge?vgeId=${args.vgeId}`, args.ids)
})

export const deleteInstallatieGebouw = createAsyncThunk(`${basePrefix}/deleteGebouw`, async (args: IInstallatieDeleteGebouwArgs, thunkAPI) => {
  return await invokeFetch(thunkAPI, 'DELETE', `${baseUrl}/gebouw?gebouwId=${args.gebouwId}`, args.ids)
})

export const deleteInstallatieGebouwDeel = createAsyncThunk(`${basePrefix}/deleteGebouwDeel`, async (args: IInstallatieDeleteGebouwDeelArgs, thunkAPI) => {
  return await invokeFetch(thunkAPI, 'DELETE', `${baseUrl}/gebouwDeel?gebouwDeelId=${args.gebouwDeelId}`, args.ids)
})

export const installatieSlice = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
    clearError: state => {
      state.error = null
    },
    select: (state, action: PayloadAction<string | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
    clearAll: state => {
      entityAdapter.removeAll(state)
      state.selectedId = undefined
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchInstallatie.pending, state => setPendingState(state))
    builder.addCase(fetchInstallatie.fulfilled, (state, action: PayloadAction<IInstallatie>) => {
      setSucceededState(state)
      entityAdapter.upsertOne(state, action.payload)
      state.selectedId = action.payload.id!.toString()
    })
    builder.addCase(fetchInstallatie.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(fetchForVge.pending, state => setPendingState(state))
    builder.addCase(fetchForVge.fulfilled, (state, action: PayloadAction<IInstallatie[]>) => {
      setSucceededState(state)
      entityAdapter.upsertMany(state, action.payload)
    })
    builder.addCase(fetchForVge.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(fetchForGebouw.pending, state => setPendingState(state))
    builder.addCase(fetchForGebouw.fulfilled, (state, action: PayloadAction<IInstallatie[]>) => {
      setSucceededState(state)
      entityAdapter.upsertMany(state, action.payload)
    })
    builder.addCase(fetchForGebouw.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(fetchForGebouwDeel.pending, state => setPendingState(state))
    builder.addCase(fetchForGebouwDeel.fulfilled, (state, action: PayloadAction<IInstallatie[]>) => {
      setSucceededState(state)
      entityAdapter.upsertMany(state, action.payload)
    })
    builder.addCase(fetchForGebouwDeel.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(updateInstallatieVge.pending, state => setPendingState(state))
    builder.addCase(updateInstallatieVge.fulfilled, (state, action: PayloadAction<IInstallatie>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(updateInstallatieVge.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(updateInstallatieGebouw.pending, state => setPendingState(state))
    builder.addCase(updateInstallatieGebouw.fulfilled, (state, action: PayloadAction<IInstallatie>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(updateInstallatieGebouw.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(updateInstallatieGebouwDeel.pending, state => setPendingState(state))
    builder.addCase(updateInstallatieGebouwDeel.fulfilled, (state, action: PayloadAction<IInstallatie>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(updateInstallatieGebouwDeel.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(addInstallatieForVge.pending, state => setPendingState(state))
    builder.addCase(addInstallatieForVge.fulfilled, (state, action: PayloadAction<IInstallatie>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(addInstallatieForVge.rejected, (state, action) => {
      setRejectedState(state, action)
    })

    builder.addCase(addInstallatieForGebouw.pending, state => setPendingState(state))
    builder.addCase(addInstallatieForGebouw.fulfilled, (state, action: PayloadAction<IInstallatie>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(addInstallatieForGebouw.rejected, (state, action) => {
      setRejectedState(state, action)
    })

    builder.addCase(addInstallatieForGebouwDeel.pending, state => setPendingState(state))
    builder.addCase(addInstallatieForGebouwDeel.fulfilled, (state, action: PayloadAction<IInstallatie>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(addInstallatieForGebouwDeel.rejected, (state, action) => {
      setRejectedState(state, action)
    })

    builder.addCase(deleteInstallatieVge.pending, state => setPendingState(state))
    builder.addCase(deleteInstallatieVge.fulfilled, (state, action) => {
      entityAdapter.removeMany(state, action.meta.arg.ids)
      setSucceededState(state)
    })
    builder.addCase(deleteInstallatieVge.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(deleteInstallatieGebouw.pending, state => setPendingState(state))
    builder.addCase(deleteInstallatieGebouw.fulfilled, (state, action) => {
      entityAdapter.removeMany(state, action.meta.arg.ids)
      setSucceededState(state)
    })
    builder.addCase(deleteInstallatieGebouw.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(deleteInstallatieGebouwDeel.pending, state => setPendingState(state))
    builder.addCase(deleteInstallatieGebouwDeel.fulfilled, (state, action) => {
      entityAdapter.removeMany(state, action.meta.arg.ids)
      setSucceededState(state)
    })
    builder.addCase(deleteInstallatieGebouwDeel.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const getSelectedEntity = (state: RootState) => {
  const selectedId = getSliceState(state).selectedId
  if (selectedId) return getSliceState(state).entities[selectedId]
  else return undefined
}

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}

export const getErrorState = (state: RootState) => {
  return getSliceState(state).error
}

export const { add, modify, removeMany, select, clearSelection, clearAll } = installatieSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.installaties)

export default installatieSlice.reducer
