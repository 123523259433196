import React, { useEffect, useState } from 'react'
import { DefaultButton, Panel, PanelType, Stack } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import { getPropertyName } from 'lib/interfaceUtils'
import {
    getSelectedEntity,
    updateWoningverbeteringToestemming,
    getLoadingState, addWoningverbeteringToestemming,
    IWoningverbeteringToestemming, woningverbeteringToestemmingSchema
} from './woningverbeteringToestemmingSlice'
import VgtTextField from 'components/VgtTextField'
import useRoles from 'services/UseRoles'


interface IEditPanelProps {
    isOpen: boolean
    dismissPanel: any
}

export const WoningverbeteringToestemmingEditPanel: React.FC<IEditPanelProps> = props => {
    const dispatch = useAppDispatch()
    const [title, setTitle] = useState('')
    const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
    const fetchStatus = useSelector(getLoadingState)
    const selectedEntity = useSelector(getSelectedEntity)
    const { isAdmin } = useRoles()

    useEffect(() => {
        setCloseOnFulFilled(false)
    }, []);

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting, errors },
    } = useForm<IWoningverbeteringToestemming>({ resolver: yupResolver(woningverbeteringToestemmingSchema), mode: 'all' })

    useEffect(() => {
        setTitle(`Woningverbetering || Toestemmingcode wijzigen`)
        if (selectedEntity) {
            reset(selectedEntity)
        } else {
            reset({})
        }
    }, [selectedEntity]) 

    const onCancel = e => { props.dismissPanel() }

    const onSubmit = data => {
        if (selectedEntity) {
            data.woningverbeteringToestemmingId = selectedEntity.woningverbeteringToestemmingId
            dispatch(updateWoningverbeteringToestemming(data))
            setCloseOnFulFilled(true)
        } else {
            dispatch(addWoningverbeteringToestemming(data))
            setCloseOnFulFilled(true)
        }
    }

    useEffect(() => {
        if (closeOnFulFilled && fetchStatus === 'succeeded') props.dismissPanel() 
    }, [fetchStatus])

    return (
        <div>
            <Panel type={PanelType.large} headerText={title} isOpen={props.isOpen} onDismiss={onCancel} closeButtonAriaLabel="Sluiten">
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VgtTextField
                        label="Code"
                        name={getPropertyName<IWoningverbeteringToestemming>('code')}
                        register={register}
                        control={control}
                        errors={errors}
                        required
                        readOnly={!isAdmin ? true : false}
                    />
                    <br />
                    { isAdmin ? 
                    <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
                        <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} onClick={handleSubmit(onSubmit)} />
                        <DefaultButton text="Annuleren" onClick={onCancel} />
                    </Stack> : null}
                </form>
            </Panel>
        </div>
    )    
}
export default WoningverbeteringToestemmingEditPanel