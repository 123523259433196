import React from 'react'
import { DefaultButton, IDropdownOption, Panel, PanelType, Stack, TextField } from '@fluentui/react'
import VgtDropdown from '../../../../../components/FluentDropdown'
import { getPropertyName } from '../../../../../lib/interfaceUtils'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { GebouwDeelContactCategorieMutateDto, zodiosHooks } from '../../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../../services/HandleError'
import { z } from 'zod'
import ErrorMessageBar from '../../../../../components/ErrorMessageBar/ErrorMessageBar'

export interface IEditPanelProps {
  gebouwDeelId: number,
  contactCategorieId: number,
  contactgegevenId: number,
  contactCategorieNaam: string,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof GebouwDeelContactCategorieMutateDto>;

export const GebouwDeelContactCategorieFormPanel: React.FC<IEditPanelProps> = props => {

  const [error, setError] = React.useState<string>()

  const defaultContactCategorie = {
    contactgegevenId: props.contactgegevenId
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(GebouwDeelContactCategorieMutateDto), mode: 'all', defaultValues: defaultContactCategorie
  })

  const { data: contactgegeven } = zodiosHooks.useGetContactgegevens({})

  const {
    mutate: modifyGebouwContactgegeven
  } = zodiosHooks.usePostGebouwDeelContactCategorieGebouwDeelIdcontactCategorienContactCategorieId(
    { params: { gebouwDeelId: props.gebouwDeelId, contactCategorieId: props.contactCategorieId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const nullOptie = { key: 0, text: '---' } as IDropdownOption
  const moreContactgegevenOptie = contactgegeven?.items?.filter((item) => item.categorieId !== null && parseInt(item.categorieId) === props.contactCategorieId)
    .map((item) => {
      return { key: item.id, text: item.naam + ' - ' + item.emailadres }
    }) as IDropdownOption[]
  const contactgegevenOpties = [nullOptie, ...(moreContactgegevenOptie || [])]

  const onCancel = () => {
    props.invalidate()
    reset({})
    props.dismissPanel()
  }

  const onSubmit = (data: CreateUpdateType) => {
    if (data.contactgegevenId === 0) data.contactgegevenId = null
    modifyGebouwContactgegeven(data)
  }

  return (
    <Panel type={PanelType.medium}
           headerText={`Contactgegeven ${props.contactCategorieId !== 0 ? 'wijzigen' : 'toevoegen'}`}
           isOpen={props.isOpen}
           onDismiss={onCancel}
           closeButtonAriaLabel="Sluiten">
      <br />
      <ErrorMessageBar error={error} />
      <form onSubmit={handleSubmit(onSubmit)}>

        <TextField
          disabled={true}
          autoComplete="off"
          name={'contactCategorieNaam'}
          value={props.contactCategorieNaam}
          label={'Contact Categorie'}
          borderless
          underlined
          required={true}
          readOnly={true}
          multiline={false}
        />

        <VgtDropdown
          label="Naam"
          name={getPropertyName<CreateUpdateType>('contactgegevenId')}
          register={register}
          control={control}
          options={contactgegevenOpties}
          errors={errors}
          required={true}
          selectedKey={props.contactgegevenId}
        />

        <br />
          <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
            <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting}
                           onClick={handleSubmit(onSubmit)} />
            <DefaultButton text="Annuleren" onClick={onCancel} />
          </Stack>
      </form>
    </Panel>
  )
}