import { IDropdownOption } from "@fluentui/react"

export const convertDecimalToText = (value: number | null | undefined) => {
    if (!value) return ''
    return value.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

/**
 * Sorts and adds a option for a default option with a key value of zero.
 * @param options The original options that should be expanded
 * @returns A sorted option list with a empty option.
 */
export const addZeroDropdownOption = (options: IDropdownOption[]) => {
    const sortedOptions = [...options]
    sortedOptions.sort((a, b) => a.text.localeCompare(b.text))
    const result = [
      {
        key: 0,
        text: ' --- ',
      } as IDropdownOption,
    ]
    sortedOptions.forEach(o => result.push(o))
    return result
  }
  