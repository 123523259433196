import React, { useMemo, useState } from 'react'
import { getPropertyName } from 'lib/interfaceUtils'
import { IColumn, Link, SelectionMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import { createCustomRenderedColumn } from 'lib/gridHelper'
import { transformDateString } from '../common/support'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import FluentTable from 'components/FluentTable'
import ElementCategorieEditPanel from './editPanel'
import useRoles from 'services/UseRoles'
import ICoMoonFontIcon from '../../../../components/IcoMoon'
import { ElementCategorieDto, zodiosHooks } from '../../../../api/ApiClient'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

type ElementCategorieDtoType = z.infer<typeof ElementCategorieDto>;

const ElementCategorieList: React.FC<{}> = () => {
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const { isAdmin } = useRoles()
  const [selectedElementCategorie, setSelectedElementCategorie] = useState<ElementCategorieDtoType | undefined>()
  const [error, setError] = useState<string>()

  const {
    data: elementCategoriepageItems,
    invalidate: fetchElementCategoriepageItems,
    isLoading: isFetchingElementCategoriepageItems
  } = zodiosHooks.useGetApiElementCategoriepage({}, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: deleteApiElementCategorie } = zodiosHooks.useDeleteApiElementCategorie({}, {
    onSuccess: () => fetchElementCategoriepageItems(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const refreshItems = () => {
    fetchElementCategoriepageItems()
  }

  const handleItemInvoked = (id: string) => {
    setSelectedElementCategorie(elementCategoriepageItems?.items?.find(item => item.id === parseInt(id)))
    openPanel()
  }

  const removeItems = () => {
    deleteApiElementCategorie(selectedItems.map(value => parseInt(value)))
  }

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    {
      visible: isAdmin, text: 'Toevoegen', onClick: () => {
        setSelectedElementCategorie(undefined)
        openPanel()
      }, icon: 'Add'
    },
    {
      visible: isAdmin,
      text: 'Verwijderen',
      onClick: () => removeItems(),
      icon: 'Delete',
      disabled: !selectedItems.length
    }
  ], [isAdmin, selectedItems.length])

  const columns: IColumn[] = [
    createCustomRenderedColumn('Elementcategorie', getPropertyName<ElementCategorieDtoType>('naam'),
      (item: ElementCategorieDtoType) => <Link key={item.id}
                                         onClick={() => handleItemInvoked(item.id.toString())}>{item.naam}</Link>, 'XXL', false),
    createCustomRenderedColumn('Ingangsdatum', getPropertyName<ElementCategorieDtoType>('ingangsDatum'),
      (item: ElementCategorieDtoType) => <span>{transformDateString(item?.ingangsDatum)}</span>, 'M', false),
    createCustomRenderedColumn('Einddatum', getPropertyName<ElementCategorieDtoType>('eindDatum'),
      (item: ElementCategorieDtoType) => <span>{transformDateString(item?.eindDatum!)}</span>, 'M', false),
    {
      key: 'fonts-icon',
      name: 'Fonticoon',
      fieldName: getPropertyName<ElementCategorieDtoType>('fonticoonNaam'),
      minWidth: 70,
      maxWidth: 70,
      isResizable: false,
      isRowHeader: false,
      onRender: (item: ElementCategorieDtoType) => <ICoMoonFontIcon iconName={item?.fonticoonNaam!} />
    }
  ]

  return (
    <MainTemplate title="Elementcategorien" subTitle="Overzicht" commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <ElementCategorieEditPanel selectedElementCategorie={selectedElementCategorie} fetchElementCategoriepageItems={fetchElementCategoriepageItems} isOpen={isPanelOpen}
                                 dismissPanel={dismissPanel} />
      <div style={{ height: '77vh' }}>
        <FluentTable
          columns={columns}
          items={elementCategoriepageItems?.items ?? []}
          loading={isFetchingElementCategoriepageItems}
          selectionMode={SelectionMode.multiple}
          onSelectionChanged={(ids: string[]) => setSelectedItems(ids)}
          onGetKey={(item: ElementCategorieDtoType) => item.id.toString()}
          onItemInvoked={(id: string) => {
            handleItemInvoked(id)
          }}
        />
      </div>
    </MainTemplate>
  )
}

export default ElementCategorieList

