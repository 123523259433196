import { Checkbox, DetailsList, IColumn, ScrollablePane, SelectionMode } from '@fluentui/react'
import { ICoenCadCode, IEntityCoenCadCodes } from './coenCadCode'
import { getPropertyName } from 'lib/interfaceUtils'
import useRoles from "services/UseRoles";

export interface ICoenCadCodeItem extends ICoenCadCode {
  selected: boolean
}

export interface ICoenCadCodeSelectionProps {
  items: ICoenCadCodeItem[]
  onChanged: (items: ICoenCadCodeItem[]) => void
}

export const createCoenCadItems = (codes: ICoenCadCode[], entity?: IEntityCoenCadCodes) => {
  const items = codes?.map(v => {
    return { ...v, selected: false } as ICoenCadCodeItem
  })

  if (entity) {
    entity.coenCadCodes.forEach(c => {
      const s = items.find(item => item.id === c.id)
      if (s) s.selected = true
    })
  }

  return items
}

const CoedCadCodeSelection: React.FC<ICoenCadCodeSelectionProps> = props => {
  const onCoenCadItemChecked = (item: ICoenCadCodeItem, checked: boolean) => {
    const items = [...props.items]
    const ccItem = items.find(i => i.id === item.id)
    if (ccItem) ccItem.selected = checked
    props.onChanged(items)
  }

  const { isAdmin } = useRoles()

  const coenCadColumns: IColumn[] = [
    {
      key: getPropertyName<ICoenCadCodeItem>('selected'),
      name: 'Actief',
      minWidth: 50,
      maxWidth: 50,
      isResizable: false,
      isRowHeader: true,
      onRender: (item: ICoenCadCodeItem) => <Checkbox checked={item.selected} onChange={(_, checked) => !isAdmin ? null : onCoenCadItemChecked(item, checked ?? false)} />,
    },
    {
      key: getPropertyName<ICoenCadCode>('key'),
      name: 'CWB Key',
      fieldName: getPropertyName<ICoenCadCode>('key'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
    },
    {
      key: getPropertyName<ICoenCadCode>('groep'),
      name: 'Groep',
      fieldName: getPropertyName<ICoenCadCode>('groep'),
      minWidth: 50,
      maxWidth: 250,
      isResizable: true,
      isRowHeader: true,
    },
    {
      key: getPropertyName<ICoenCadCode>('description'),
      name: 'Omschrijving',
      fieldName: getPropertyName<ICoenCadCode>('description'),
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
    },
  ]

  return (
    <>
      <h3>Coencad CWB</h3>
      <div style={{ height: isAdmin ? '50vh' : '55vh', position: 'relative' }}>
        <ScrollablePane>
          <DetailsList items={props.items} columns={coenCadColumns} selectionMode={SelectionMode.none} />
        </ScrollablePane>
      </div>
    </>
  )
}

export default CoedCadCodeSelection
