import { combineReducers } from '@reduxjs/toolkit'

import appReducer from './actions/app'
import navbarReducer from './actions/navbar'
import defectLocatieReducer from 'features/beheer/lemdo/defectLocatie/defectLocatieSlice'
import defectSoortReducer from 'features/beheer/lemdo/defectSoort/defectSoortSlice'
import eenheidSoortReducer from 'features/beheer/lemdo/eenheidsoort/eenheidSoortSlice'
import eenheidDetailSoortReducer from 'features/beheer/lemdo/eenheidDetailSoort/eenheidDetailSoortSlice'
import elementReducer from 'features/beheer/lemdo/element/elementSlice'
import nlsfbElementReducer from 'features/beheer/lemdo/nlsfb/elementSlice'
import vgeReducer from 'features/vge/vgeSlice'
import importTaakReducer from 'features/beheer/imports/taken/slice'
import gebouwenReducer from 'features/beheer/fysiek/gebouwen/gebouwenSlice'
import rechtspersonenReducer from 'features/beheer/relaties/rechtspersoon/rechtspersoonSlice'
import gebouwenClustersReducer from 'features/beheer/fysiek/gebouwenclusters/gebouwenClustersSlice'
import perceelReducer from 'features/vge/perceelSlice'
import basisregistratiesReducer from 'features/beheer/tools/basisregistratiessearch/basisregistratiesSlice'
import eenheidDetailSoortRuimteSoortReducer from 'features/beheer/lemdo/eenheidDetailSoort/ruimteSoort/slice'
import eenheidDetailSoortDefectlocatieReducer from 'features/beheer/lemdo/eenheidDetailSoort/defectlocatie/slice'
import defectLocatieElementCategorieReducer from 'features/beheer/lemdo/defectLocatie/elementen/slice'
import elementDefectSoortReducer from 'features/beheer/lemdo/elementDefectSoort/elementDefectSoortSlice'
import elementElementDetailReducer from 'features/beheer/lemdo/elementElementDetail/elementElementDetailSlice'
import elementDefectSoortTakenReducer from 'features/beheer/lemdo/elementDefectSoort/taak/slice'
import taakReducer from 'features/beheer/lemdo/taak/slice'
import vastgoedReducer from 'features/beheer/vastgoedQueries/querySlice'
import fysiekReducer from 'features/search/fysiekSlice'
import availableQueriesReducer from 'features/beheer/vastgoedQueries/availableQueries'
import meetkundigeEenheidReducer from 'features/beheer/basis/meetkundigeEenheden/meetkundigeEenheidSlice'
import terreinClassificatieReducer from 'features/beheer/lemdo/terreinClassificatie/terreinClassificatieSlice'
import keukenReducer from 'features/beheer/stamtabellen/keuken/keukenSlice'
import sanitairReducer from 'features/beheer/stamtabellen/sanitair/sanitairSlice'
import installatieGroepIconReducer from 'features/icons/iconSlice'
import installatieGroepElementSuggestionReducer from 'features/beheer/lemdo/nlsfb/elementSuggestions'
import installatieReducer from 'features/beheer/installatie/installatieSlice'
import tenantReducer from './actions/app/tenantSlice'
import vgeRuimteSoortReducer from 'features/vge/installatie/vgeRuimteSoortSlice'
import product2BAReducer from 'features/beheer/installatie/product2BASlice'
import notificatiesReducer from 'features/vge/notificaties/notificatiesSlice'
import verwarmingReducer from 'features/beheer/lemdo/verwarming/verwarmingSlice'
import coenCadCodeReducer from 'features/beheer/coencad/coenCadCodeSlice'
import bouwlaagReducer from 'features/beheer/stamtabellen/bouwlagen/bouwlaagSlice'
import gebouwNEN2580Reducer from 'features/beheer/fysiek/nen2580/gebouwHoeveelhedenSlice'
import vgeNEN2580Reducer from 'features/beheer/fysiek/nen2580/vgeHoeveelhedenSlice'
import gebouwWWSReducer from 'features/beheer/fysiek/wws/gebouwWWSSlice'
import onderhoudsContractReducer from 'features/beheer/onderhoudscontract/onderhoudsContractSlice'
import relatieRollenReducer from 'features/beheer/relaties/relatieRollen/relatieRollenSlice'
import vgeContractReducer from 'features/beheer/onderhoudscontract/vgeContractVgeSlice'
import statisticsReducer from 'containers/pages/Home/statisticsSlice'
import vveReducer from 'features/beheer/relaties/vve/vveSlice'
import wozReducer from 'features/vge/woz/wozSlice'
import wozHistorieReducer from 'features/vge/woz/wozHistorieSlice'
import kadastraalObjectReducer from 'features/beheer/relaties/vve/kadastraalObjectSlice'
import kadastraalSubjectReducer from 'features/beheer/relaties/vve/kadastraalSubjectSlice'
import importReducer from 'features/beheer/imports/importSlice'
import gebouwOnderhoudsContractenReducer from 'features/beheer/fysiek/gebouwen/onderhoudsContract/gebouwContractSlice'
import gebouwDeelContractReducer from 'features/beheer/fysiek/gebouwdelen/onderhoudsContract/gebouwDeelContractSlice'
import vgeOnderhoudsContractenReducer from 'features/vge/onderhoudsContract/vgeContractSlice'
import begrippenLijstReducer from 'features/beheer/begrippenlijst/begrippenLijstSlice'
import dienstenComponentReducer from 'features/beheer/lemdo/dienstenComponent/dienstenComponentSlice'
import onderhoudsTaakErpReducer from 'features/beheer/lemdo/onderhoudsTaakErp/onderhoudsTaakErpSlice'
import onderhoudsspecialismeReducer from 'features/beheer/lemdo/onderhoudsspecialisme/onderhoudsspecialismeSlice'
import tenantTaakReducer from 'features/beheer/lemdo/tenantTaak/tenantTaakSlice'
import ruimtesoortErpReducer from 'features/beheer/lemdo/ruimtesoortErp/ruimtesoortErpSlice'
import defectoorzaakReducer from 'features/beheer/lemdo/defectoorzaak/defectoorzaakSlice'
import kostensoortReducer from 'features/beheer/lemdo/kostensoort/kostensoortSlice'
import garantieReducer from 'features/beheer/lemdo/garantie/garantieSlice'
import tenantKostensoortBetalerReducer
  from 'features/beheer/lemdo/tenantKostensoortBetaler/tenantKostensoortBetalerSlice'
import woningverbeteringToestemmingReducer
  from 'features/beheer/lemdo/woningverbeteringToestemming/woningverbeteringToestemmingSlice'
import cartotheekNavigatieReducer from 'features/beheer/installatie/cartotheekSlice'
import onderhoudsbedrijfTyperingReducer
  from './../features/beheer/onderhoudsbedrijfTypering/onderhoudsbedrijfTyperingSlice'
import cartotheekMachine from '../features/beheer/cartotheek/CartotheekStateMachine'

const rootReducer = combineReducers({
  app: appReducer,
  navbar: navbarReducer,
  defectLocaties: defectLocatieReducer,
  defectSoorten: defectSoortReducer,
  eenheidSoorten: eenheidSoortReducer,
  eenheidDetailSoorten: eenheidDetailSoortReducer,
  elementen: elementReducer,
  vges: vgeReducer,
  importtaken: importTaakReducer,
  gebouwen: gebouwenReducer,
  gebouwenClusters: gebouwenClustersReducer,
  percelen: perceelReducer,
  nlsfb: nlsfbElementReducer,
  basisregistraties: basisregistratiesReducer,
  eenheidDetailSoortRuimteSoorten: eenheidDetailSoortRuimteSoortReducer,
  eenheiddetailsoortDefectlocaties: eenheidDetailSoortDefectlocatieReducer,
  defectLocatieElementCategories: defectLocatieElementCategorieReducer,
  elementDefectSoorten: elementDefectSoortReducer,
  elementElementDetails: elementElementDetailReducer,
  elementDefectSoortTaken: elementDefectSoortTakenReducer,
  taken: taakReducer,
  rechtspersonen: rechtspersonenReducer,
  vastgoed: vastgoedReducer,
  fysiek: fysiekReducer,
  availableQueries: availableQueriesReducer,
  meetkundigeEenheden: meetkundigeEenheidReducer,
  terreinClassificatie: terreinClassificatieReducer,
  keukens: keukenReducer,
  sanitair: sanitairReducer,
  installatieGroepIcons: installatieGroepIconReducer,
  installatieGroepElementSuggestions: installatieGroepElementSuggestionReducer,
  installaties: installatieReducer,
  tenants: tenantReducer,
  ruimteSoortenPerVge: vgeRuimteSoortReducer,
  products2BA: product2BAReducer,
  notificaties: notificatiesReducer,
  verwarming: verwarmingReducer,
  coenCadCodes: coenCadCodeReducer,
  bouwlagen: bouwlaagReducer,
  gebouwHoeveelhedenNEN2580: gebouwNEN2580Reducer,
  vgeHoeveelhedenNEN2580: vgeNEN2580Reducer,
  gebouwWWS: gebouwWWSReducer,
  onderhoudsContracten: onderhoudsContractReducer,
  relatieRollen: relatieRollenReducer,
  onderhoudsContractVges: vgeContractReducer,
  statistics: statisticsReducer,
  vves: vveReducer,
  kadastraleObjecten: kadastraalObjectReducer,
  kadastraleSubjecten: kadastraalSubjectReducer,
  woz: wozReducer,
  wozHistorie: wozHistorieReducer,
  imports: importReducer,
  gebouwOnderhoudsContracten: gebouwOnderhoudsContractenReducer,
  gebouwDeelOnderhoudsContracten: gebouwDeelContractReducer,
  vgeOnderhoudsContracten: vgeOnderhoudsContractenReducer,
  begrippenLijst: begrippenLijstReducer,
  dienstenComponent: dienstenComponentReducer,
  onderhoudsTaakErp: onderhoudsTaakErpReducer,
  onderhoudsspecialismen: onderhoudsspecialismeReducer,
  tenantTaak: tenantTaakReducer,
  ruimtesoortErp: ruimtesoortErpReducer,
  defectoorzaken: defectoorzaakReducer,
  kostensoorten: kostensoortReducer,
  garanties: garantieReducer,
  tenantKostensoortBetaler: tenantKostensoortBetalerReducer,
  woningverbeteringToestemmingen: woningverbeteringToestemmingReducer,
  cartotheekNavigatie: cartotheekNavigatieReducer,
  cartotheek: cartotheekMachine,
  onderhoudsbedrijfTypering: onderhoudsbedrijfTyperingReducer,
})

export default rootReducer
