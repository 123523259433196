import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PageIdDefinition } from 'pages'

export const slice = createSlice({
  name: 'navbar',
  initialState: {
    activePageId: PageIdDefinition.startpagina,
  },
  reducers: {
    setActivePageId: (state, action: PayloadAction<string>) => {
      state.activePageId = action.payload
    },
  },
})

export const { setActivePageId } = slice.actions

export default slice.reducer
