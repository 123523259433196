import { Link, Text, SelectionMode, Stack, FontIcon, useTheme } from "@fluentui/react";
import FluentTable from "components/FluentTable";
import InputCard from "components/InputCard";
import CommandBarTemplate from "containers/pages/PageTemplates/CommandBarTemplate";
import { createColumn } from "lib/gridHelper";
import { getPropertyName } from "lib/interfaceUtils";
import { PageIdDefinition, PAGES } from "pages";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IVve } from "./../vveSlice";

export const RelatedVveCard: React.FC<{ vve?: IVve, onDisconnect: (vves: string[]) => void, onDisconnectBovenliggendeVve: () => void, onOpenOnderliggendPanel: () => void, onOpenBovenliggendPanel: () => void }> = ({ vve, onDisconnect, onDisconnectBovenliggendeVve, onOpenOnderliggendPanel, onOpenBovenliggendPanel }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])

    const vveCommandItems = [
        { text: 'Koppelen', onClick: () => onOpenOnderliggendPanel(), icon: 'PlugConnected', iconOnly: true },
        { text: 'Ontkoppelen', onClick: () => onDisconnect(selectedItems), icon: 'PlugDisconnected', iconOnly: true, disabled: selectedItems.length === 0 },
    ];

    return <InputCard title="Gerelateerde VvE's" height="55%" width="100%">
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text>Bovenliggende VvE</Text>
            {!vve?.bovenliggendeVve &&
                <Link
                    onClick={() => { onOpenBovenliggendPanel() }}>
                    Selecteer
                </Link>}
            {(vve?.bovenliggendeVve) &&
                <>
                    <Link
                        onClick={() => { if (vve?.bovenliggendeVve) navigate(vve?.bovenliggendeVve ? PAGES[PageIdDefinition.beheer.relatie.vves.edit].route.replace(':id', vve?.bovenliggendeVve.id.toString()) : "") }}>
                        {vve?.bovenliggendeVve?.naamOpAkte}
                    </Link>
                    <FontIcon iconName="ChromeClose" style={{ cursor: "pointer", color: theme.palette.themePrimary, fontSize: 15, height: 15, width: 15, paddingTop: 3 }} onClick={() => onDisconnectBovenliggendeVve()} />
                </>}
        </Stack>

        <div style={{ height: '20px' }}></div>
        <Text>Onderliggende VvE's</Text>
        <CommandBarTemplate items={vveCommandItems}></CommandBarTemplate>
        <div style={{ height: 200 }}><FluentTable
            columns={[createColumn('Naam', getPropertyName<IVve>('naamOpAkte'), 'L'),]}
            items={vve?.onderliggendeVves ?? [] as IVve[]}
            selectionMode={SelectionMode.multiple}
            onSelectionChanged={(ids: string[]) => setSelectedItems(ids)}
            onGetKey={(item: IVve) => item.id.toString()}
            onItemInvoked={(id: string) => { navigate(PAGES[PageIdDefinition.beheer.relatie.vves.edit].route.replace(':id', id)) }}
        /></div>
    </InputCard>
}