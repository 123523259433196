import { IColumn, Link } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { clearSelection, deleteDefectoorzaak, fetchAll, select } from './defectoorzaakSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { DefectoorzaakEditPanel } from './editPanel'
import { useBoolean } from '@fluentui/react-hooks'
import useRoles from 'services/UseRoles'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'
import { DefectoorzaakDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { usePagedParams } from '../../../../services/usePagedParams'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { z } from 'zod'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

type DefectoorzaakDtoType = z.infer<typeof DefectoorzaakDto>;

const DefectoorzaakList: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const { isAdmin } = useRoles()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const [error, setError] = React.useState<string>()
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  useEffect(() => {
    dispatch(fetchAll({ filter: '' }))
  }, [])

  const {
    data: defectoorzaakItems,
    invalidate: fetchdefectoorzaakItems,
    isLoading
  } = zodiosHooks.useGetDefectoorzaak({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const handleItemInvoked = (id) => {
    dispatch(select(id))
    openPanel()
  }

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => fetchdefectoorzaakItems(), icon: 'Refresh' },
    {
      visible: isAdmin, text: 'Toevoegen', onClick: () => {
        dispatch(clearSelection())
        openPanel()
      }, icon: 'Add'
    },
    {
      visible: isAdmin, text: 'Verwijderen', onClick: () =>
        dispatch(
          deleteDefectoorzaak(
            selectedIds
          )
        )
      , icon: 'Delete', disabled: !selectedIds.length
    }
  ], [isAdmin, selectedIds.length])

  const renderBeschrijving = (x: DefectoorzaakDtoType) => <>{x.omschrijving}</>
  const renderCode = (x: DefectoorzaakDtoType) => <Link key={x.defectoorzaakId}
                                                        onClick={() => handleItemInvoked(x.defectoorzaakId.toString())}>{x.code}</Link>

  const columns: IColumn[] = [
    createCustomRenderedColumn('Code', getPropertyName<DefectoorzaakDtoType>('code'), renderCode, 'M', false),
    createColumn('Naam', getPropertyName<DefectoorzaakDtoType>('naam'), 'XL'),
    createCustomRenderedColumn('Omschrijving', getPropertyName<DefectoorzaakDtoType>('omschrijving'), renderBeschrijving, 'XXL', false, true)
  ]

  return (
    <MainTemplate title='Defectoorzaak' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <DefectoorzaakEditPanel
        dismissPanel={dismissPanel}
        isOpen={isPanelOpen}
        fetchdefectoorzaakItems={fetchdefectoorzaakItems}
      />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <VgtPagedTable
        items={defectoorzaakItems?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: defectoorzaakItems?.totalCount ?? 0,
          totalPages: defectoorzaakItems?.totalPages ?? 0,
          hasNextPage: defectoorzaakItems?.hasNextPage || false,
          hasPreviousPage: defectoorzaakItems?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item: DefectoorzaakDtoType) => item?.defectoorzaakId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.defectoorzaakId)}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}

export default DefectoorzaakList
