import React, { FC } from 'react'
import { DefaultPalette, mergeStyles, Stack } from '@fluentui/react'
import ICoMoonFontIcon from '../../../components/IcoMoon'

const innerDiv = mergeStyles({
  textAlign: 'center',
  paddingTop: '10px',
  alignItems: 'center',

  border: '1',
  borderStyle: 'solid',
  borderColor: DefaultPalette.neutralLight,
  height: 130,
  justifyContent: 'center',
  width: 150,
  ':hover': {
    backgroundColor: DefaultPalette.themeLighterAlt,
    cursor: 'pointer',
    borderStyle: 'solid',
    borderColor: DefaultPalette.black
  }
})

const glowEffect = mergeStyles({
  boxShadow: '0 0 0px #aa3333, 0 0 0px #aa3333, 0 0 0px #aa3333, 0 0 2px #aa3333'
})

const numberOfItems = mergeStyles({
  backgroundColor: DefaultPalette.neutralLighter,
  color: DefaultPalette.black,
  marginLeft: '34px',
  fontSize: '14px',
  fontWeight: 'bold',
  border: '2px solid',
  borderStyle: 'solid',
  borderColor: DefaultPalette.blackTranslucent40,
  borderRadius: '120%',
  textAlign: 'center',
  padding: '1px 7px 2px 7px',
  position: 'relative',
  bottom: '-10px',
  right: '-10px'
})

export interface IconProps {
  id: number
  fonticoon: string
  name: string
  numberOfItems: number
}

export interface IconListProps {
  icons?: IconProps[]
  onSelected: (icon: IconProps) => void
}

export const IconList: FC<IconListProps> = ({ onSelected, icons }) => {
  return (
    <Stack tokens={{ childrenGap: 20 }} horizontal wrap enableScopedSelectors style={{ margin: 5 }}>
      {icons?.map((icon, index) => (
        <div className={`${innerDiv} ${icon.numberOfItems > 0 ? glowEffect : ''}`} key={icon.id}
             onClick={() => onSelected(icon)} style={{ backgroundColor: icon.numberOfItems > 0 ? '#F6F6F6' : 'white' }}>
          {icon.numberOfItems > 0 && <span className={numberOfItems}>
            {icon.numberOfItems < 10 ? icon.numberOfItems : <span>9+</span>}
            </span>
          }
          {icon.numberOfItems === 0 && <span>&nbsp;</span>}
          <div>
            {icon.fonticoon && <ICoMoonFontIcon iconName={icon.fonticoon} iconSize={60} />}
          </div>
          <p>{icon.name}</p>
        </div>
      ))}
    </Stack>
  )
}