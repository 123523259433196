import React, { useState } from 'react'
import { IStackStyles, IButtonStyles, PrimaryButton, Stack, Spinner, DefaultButton } from '@fluentui/react'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { z } from 'zod'
import { VgeSleutelAddOrUpdateDto, VgeSleutelDto, zodiosHooks } from '../../../../api/ApiClient'
import { useForm } from 'react-hook-form'
import VgtTextField from '../../../../components/VgtTextField'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import VgtIncrementDecrementButton from '../../../../components/VgtIncrementDecrementButton'
import ReadOnlyField from '../../../../components/ReadOnlyField'

type VgeSleutelDtoType = z.infer<typeof VgeSleutelDto>;
type VgeSleutelAddOrUpdateType = z.infer<typeof VgeSleutelAddOrUpdateDto>;

interface ISleutelsRowProps {
  item: VgeSleutelDtoType;
  key: number;
  handleSubmissionSuccess: () => void;
}

const stackStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    margin: '5px 0'
  }
}

const stackButtonsStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: 250
  }
}

const buttonStyles: IButtonStyles = {
  root: {
    marginLeft: 3,
    marginRight: 3,
    width: 80,
    height: 25,
    minWidth: 40
  }
}

const SleutelsRow: React.FC<ISleutelsRowProps> = ({
                                                    item,
                                                    handleSubmissionSuccess
                                                  }) => {
  const [error, setError] = useState<string | undefined>()

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isSubmitting, errors }
  } = useForm<VgeSleutelAddOrUpdateType>({
    mode: 'all', defaultValues: item
  })

  const { mutate: addOrUpdateVgeSleuteltype, isLoading } = zodiosHooks.usePostVgeSleutel(
    {}, {
      onError: (error) => setError(getTitleAndMessage(error).message), onSuccess: (newValue) => {
        handleSubmissionSuccess()
        reset(newValue)
        setError(undefined)
      }
    }
  )

  const revertChanges = () => {
    reset(item)
    setError(undefined)
  }

  const onSaveSleutel = (data: VgeSleutelAddOrUpdateType) => {
    const combinedData = {
      ...data,
      vgeSleutelId: item.vgeSleutelId,
      vgeId: item.vgeId,
      sleuteltypeId: item.sleuteltypeId
    }
    addOrUpdateVgeSleuteltype(combinedData)
  }

  return (
    <>
      <Stack styles={stackStyles}>
        <span style={{ width: 200 }}><ReadOnlyField title={''} value={item.sleuteltypeNaam!} labelWidth={0}
                                                    fieldWidth={200} /></span>
        <span style={{ width: 120, minWidth: 110 }}>
            <VgtIncrementDecrementButton name={getPropertyName<VgeSleutelAddOrUpdateType>('aantal')} control={control}
                                         errors={errors} defaultValue={item.aantal} />
          </span>
        <span style={{ width: '45%', minWidth: 200, margin: '0 10px' }}>
            <VgtTextField name={getPropertyName<VgeSleutelAddOrUpdateType>('certificaat')}
                          defaultValue={item.certificaat!} label='' control={control} errors={errors} />
          </span>
        <Stack styles={stackButtonsStyles}>
          <PrimaryButton styles={buttonStyles} type='submit' onClick={handleSubmit(onSaveSleutel)}
                         disabled={isSubmitting || isLoading} text='Opslaan'>
            {isSubmitting ?
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Spinner />
              </div>
              : null}
          </PrimaryButton>
          {isDirty && <DefaultButton styles={buttonStyles} onClick={revertChanges}>Annuleren</DefaultButton>}
        </Stack>
      </Stack>
      <ErrorMessageBar error={error} />
    </>
  )
}

export default SleutelsRow
