import { PagedEntities } from 'interfaces/pagedEntities';
import { createAsyncThunk, createEntityAdapter, EntityState, createSlice, PayloadAction,  EntityId } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState';
import { IDropdownOption } from '@fluentui/react'

export interface IRelatieRol {
    relatieRolId: number
    naam: string
}

const entityAdapter = createEntityAdapter<IRelatieRol>({ 
  selectId: x => x.relatieRolId,
  sortComparer: (a,b) => a.naam?.localeCompare(b.naam)
})

const baseUrl = '/rechtspersonen/relatierol'
const basePrefix = 'relatierol'
const getSliceState = (state: RootState) => state.relatieRollen

export const fetchRelatieRollen = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<PagedEntities<IRelatieRol>>(thunkAPI, 'GET', `${baseUrl}`)
})

const setPendingState = (state: EntityState<IRelatieRol> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<IRelatieRol> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<IRelatieRol> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }

  export const relatierollenSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),

    reducers: {
        clearError: state => {
            state.error = null
        },
        select: (state, action: PayloadAction<string | undefined>) => {
            state.selectedId = action.payload
        },
        clearSelection: state => {
            state.selectedId = undefined
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
    },

    extraReducers: builder => {
        builder.addCase(fetchRelatieRollen.pending, state => setPendingState(state))
        builder.addCase(fetchRelatieRollen.fulfilled, (state, action: PayloadAction<PagedEntities<IRelatieRol>>) => {
          setSucceededState(state)
          entityAdapter.setAll(state, action.payload.items)
        })
        builder.addCase(fetchRelatieRollen.rejected, (state, action) => setRejectedState(state, action))
    },
})

export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
  }
  
  export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
  }
  
  export const getErrorState = (state: RootState) => {
    return getSliceState(state).error
  }

export const getRelatieRollenAsOptions = (state: RootState) => state.relatieRollen.ids.map((id: EntityId) => {
    const entity = state.relatieRollen.entities[id]
    return {
        key: id,
        text: `${entity?.naam}`
    } as IDropdownOption
})

export const { selectAll, selectEntities } = entityAdapter.getSelectors<RootState>(state => state.relatieRollen)

export default relatierollenSlice.reducer