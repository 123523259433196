import { DefaultButton, IDropdownOption, Panel, PanelType, Stack } from "@fluentui/react"
import { getPropertyName } from "lib/interfaceUtils"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"
import { 
    getLoadingState, 
    IGarantie, 
    GarantieSchema, getSelectedEntity, updateGarantie, addGarantie, deleteGarantie } from "./garantieSlice"
import { useEffect, useState } from "react"
import VgtDropdown from "components/FluentDropdown"
import { selectAll as selectAllKostensoorten } from '../kostensoort/kostensoortSlice' 

interface IEditPanelProps {
    isOpen: boolean
    dismissPanel: any
}

export const GarantieEditPanel: React.FC<IEditPanelProps> = props => {
    const dispatch = useAppDispatch()
    const [title, setTitle] = useState('')
    const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
    const fetchStatus = useSelector(getLoadingState)
    const selectedEntity = useSelector(getSelectedEntity)
    const kostensoorten = useSelector(selectAllKostensoorten)

    useEffect(() => {
        setCloseOnFulFilled(false)
    }, []);

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting, errors },
    } = useForm<IGarantie>({ resolver: yupResolver(GarantieSchema), mode: 'all' })

    useEffect(() => {
        setTitle(`Kostensoort garantie ${selectedEntity ? 'wijzigen' : 'toevoegen'}`)
        if (selectedEntity) {
            reset(selectedEntity)
        } else {
            reset({})
        }
    }, [selectedEntity]) 

    const getKostensoortenAsOption = () => {
        const nullOption = { key: 0, text: '---' } as IDropdownOption
        const kostensoortOptions = kostensoorten.map(x => ({ key: x.kostensoortId, text: `${x.code} - ${x.omschrijving}` })) as IDropdownOption[]
        return [nullOption, ...kostensoortOptions]
    } 

    const onCancel = e => { props.dismissPanel() }

    const onSubmit = data => {
        if (selectedEntity) {
            if (data.kostensoortId !== 0){
                data.garantieId = selectedEntity.garantieId
                dispatch(updateGarantie(data))
                setCloseOnFulFilled(true)
            }
            else {
                dispatch(deleteGarantie([selectedEntity.garantieId]))
                setCloseOnFulFilled(true)
            }
        } else {
            if (data.kostensoortId !== 0) 
            dispatch(addGarantie(data))
            setCloseOnFulFilled(true)
        }
    }

    useEffect(() => {
        if (closeOnFulFilled && fetchStatus === 'succeeded') props.dismissPanel() 
    }, [fetchStatus])

    return (
        <div>
            <Panel type={PanelType.large} headerText={title} isOpen={props.isOpen} onDismiss={onCancel} closeButtonAriaLabel="Sluiten">
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VgtDropdown
                        options={getKostensoortenAsOption()}
                        label='Kostensoort'
                        name={getPropertyName<IGarantie>('kostensoortId')}
                        register={register}
                        control={control}
                        errors={errors}
                        required
                    />
                    <br />
                    <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
                        <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} onClick={handleSubmit(onSubmit)} />
                        <DefaultButton text="Annuleren" onClick={onCancel} />
                    </Stack>
                </form>
            </Panel>
        </div>
    )    
}
export default GarantieEditPanel