import React from 'react'
import InputCard from '../../../components/InputCard'
import ReadOnlyField from '../../../components/ReadOnlyField'
import { CoencadMeetinstructieListDto, zodiosHooks } from '../../../api/ApiClient'
import { z } from 'zod'
import { SectionHeader, SectionWithThreeColumnsLine } from '../../../components/Section'

interface IListProps {
  vgeId: number,
}

type recordType = z.infer<typeof CoencadMeetinstructieListDto>;

export const CoencadMeetinstructieList: React.FC<IListProps> = props => {

  const [data, setData] = React.useState<recordType[]>([] as recordType[])

  zodiosHooks.useGetApiCoencadMeetinstructieVgeId({ params: { vgeId: props.vgeId } }, {
    onSuccess: (data) => setData(data)
  })

  if (!data || data.length === 0) return <div>Geen oppervlakte data beschikbaar</div>

  return (
    <div style={{ position: 'relative', height: `800px`, overflow: 'auto' }}>

      <InputCard title="Algemeen" boldTitle={true} width={'calc(100%)'}>
        <div style={{ display: 'flex', marginRight: '20px' }}>
          <div style={{ flex: '1' }}>
            <ReadOnlyField title="Bronbestand" value={data[0]?.bronbestand} />
            <ReadOnlyField title="Berekeningswijze" value={data[0]?.berekeningswijze} />
          </div>
          <div style={{ flex: '1' }}>
            <ReadOnlyField title="Importdatum" value={data[0]?.importdatum} />
            <ReadOnlyField title="Gebruiker" value={data[0]?.gebruiker} />
          </div>
        </div>
      </InputCard>

      <InputCard width={'calc(100%)'} marginTop={"16px"}>
        <SectionHeader title="Meetintructies">
          {data.map((item, index) => (<SectionWithThreeColumnsLine key={index} title="Gebruiksoppervlakte wonen" value={item.gebruiksoppervlakteWonen} prefixValue={item.bouwlaag} />))}
          {data.map((item, index) => (<SectionWithThreeColumnsLine key={index} title="Gebruiksoppervlakte overige inpandige ruimte" value={item.gebruiksoppervlakteOverigeInpandigeRuimte} prefixValue={item.bouwlaag} />))}
          {data.map((item, index) => (<SectionWithThreeColumnsLine key={index} title="Externe bergruimte" value={item.externeBergruimte} prefixValue={item.bouwlaag} />))}
          {data.map((item, index) => (<SectionWithThreeColumnsLine key={index} title="Niet-overdekte gebouwgebonden buitenruimte" value={item.nietOverdekteGebouwgebondenBuitenruimte} prefixValue={item.bouwlaag} />))}
          {data.map((item, index) => (<SectionWithThreeColumnsLine key={index} title="Overdekte gebouwgebonden buitenruimte" value={item.overdekteGebouwgebondenBuitenruimte} prefixValue={item.bouwlaag} />))}
        </SectionHeader>
      </InputCard>


    </div>
  )
}