import React, { useState } from 'react'
import UploadPanel from 'components/UploadPanel'
import { useBoolean } from '@fluentui/react-hooks'
import { MessageBar, MessageBarType, PrimaryButton, Stack, StackItem } from '@fluentui/react'
import UploadErrorList from 'components/UploadPanel/UploadErrorList'
import TitleTemplate from 'containers/pages/PageTemplates/TitleTemplate'

const ImportElementen: React.FC<{}> = () => {
  const [hasError, setHasError] = useState(false)
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [message, setMessage] = useState('')
  const [errorMessages, setErrorMessages] = useState([] as string[])

  const onSuccess = (result: any) => {
    clearResults()
    if (result.succeeded) {
      setMessage('De gegevens zijn verwerkt.')
    } else {
      setErrorMessages(result.errors ?? [])
    }
  }

  const clearResults = () => {
    setErrorMessages([])
    dismissPanel()
    setMessage('')
    setHasError(false)
  }

  const onError = async (result: any) => {
    clearResults()
    dismissPanel()
    if (result.message !== undefined) {
      setMessage(result.message)
    } else {
      const errorMessage = await result.text()
      if (errorMessage === '') setMessage(`Er is een fout opgetreden (${result.status}).`)
      else setMessage(errorMessage)
    }
    setHasError(true)
  }

  const buttonOpenClick = () => {
    clearResults()
    openPanel()
  }

  return (
    <>
      <TitleTemplate title='Importelementen' subTitle='Overzicht'/>
      {hasError && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
          Er is een fout opgetreden tijdens het uploaden van het bestand.
        </MessageBar>
      )}
      <UploadPanel
        title="Selecteer een bestand"
        uploadEndpoint={'/gebouwen/uploadElementen'}
        isOpen={isOpen}
        dismissPanel={dismissPanel}
        onSuccess={onSuccess}
        onError={onError}
      />
      <Stack tokens={{ childrenGap: 5 }} verticalAlign={'start'} style={{ margin: 20, marginTop: 20 }}>
        <StackItem>
          <h3>Elementen en hoeveelheden Vastware</h3>
          <PrimaryButton onClick={buttonOpenClick} text="Selecteer een bestand" />
        </StackItem>
        <StackItem>
          <label>{message}</label>
        </StackItem>
      </Stack>
      <UploadErrorList messages={errorMessages} />
    </>
  )
}

export default ImportElementen
