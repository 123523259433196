import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { createInitialState, EntityObjectState, IEntityPageObjectResponse } from 'lib/sliceSupport'
import IDefectSoort from './defectSoortModel'
import { transformDateString } from '../common/support'

const initState: EntityObjectState<IDefectSoort> = createInitialState<IDefectSoort>()

const entityAdapter = createEntityAdapter<IDefectSoort>()

export const fetchAll = createAsyncThunk('/defectSoorten/fetchAll', async (_, thunkAPI) => {
  return await invokeFetch<IEntityPageObjectResponse<IDefectSoort>>(thunkAPI, 'GET', '/api/defectsoort/page?skip=0&top=50000')
})

const transformResult = (soort: IDefectSoort) => {
  const strIngangsDatum = transformDateString(soort.ingangsDatum)
  const strEindDatum = soort.eindDatum ? transformDateString(soort.eindDatum) : undefined
  return {
    ...soort,
    ingangsDatum: strIngangsDatum,
    eindDatum: strEindDatum
  } as IDefectSoort;
}

export const defectSoortSlice = createSlice({
  name: 'defectSoorten',
  initialState: entityAdapter.getInitialState(initState),

  reducers: {
    select: (state, action: PayloadAction<number | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
  },

  extraReducers: builder => {
    builder.addCase(fetchAll.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<IEntityPageObjectResponse<IDefectSoort>>) => {
      state.status = 'succeeded'
      const items = action.payload.items.map(i => transformResult(i))
      entityAdapter.setAll(state, items)
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || null
    })
  },
})

export const {   removeMany, select, clearSelection } = defectSoortSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.defectSoorten)

export default defectSoortSlice.reducer
