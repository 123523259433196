import { AccountInfo, Configuration, EventType, PublicClientApplication } from '@azure/msal-browser'
import { IConfiguration } from '../store/actions/app/configuration'

export class MsalSingleton {
  private static instance: MsalSingleton
  private readonly msalInstance: PublicClientApplication

  public msalConfig = {
    auth: {
      clientId: '',
      authority: '',
      redirectUri: ''
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  } as Configuration

  public msalScopes = { scopes: [] } as { scopes: string[] }

  private constructor() {
    const settings = JSON.parse(localStorage.getItem('settings') || '{}') as IConfiguration

    this.msalConfig.auth.clientId = settings.msalClientId
    this.msalConfig.auth.authority = 'https://login.microsoftonline.com/common'
    this.msalConfig.auth.redirectUri = settings.msalRedirectUri
    settings.apiScopes.forEach((scope: string) => {
      this.msalScopes.scopes.push(scope)
    })
    this.msalInstance = new PublicClientApplication(this.msalConfig)
    this.msalInstance.enableAccountStorageEvents()
    this.msalInstance.initialize().then(() => {
      if (!this.msalInstance.getActiveAccount() && this.msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        this.msalInstance.setActiveAccount(this.msalInstance.getAllAccounts()[0])
      }
    })

    this.msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        this.msalInstance.setActiveAccount(event.payload as AccountInfo)
      }
    })
  }

  public static getInstance(): MsalSingleton {
    if (!MsalSingleton.instance) {
      MsalSingleton.instance = new MsalSingleton()
    }
    return MsalSingleton.instance
  }

  public getMsalInstance(): PublicClientApplication {
    return this.msalInstance
  }

  public login = async () => {
    return this.msalInstance.loginRedirect(this.msalScopes)
  }

  public acquireTokenSilent = async () => {
    return this.msalInstance.acquireTokenSilent({
      ...this.msalScopes,
      account: this.msalInstance.getActiveAccount() ?? undefined
    })
  }

  public logout = async () => {
    this.msalInstance.setActiveAccount(null)
    await this.msalInstance.logoutPopup()
  }
}
