import React, { useState } from 'react'
import { IColumn, IStackStyles, Link, Stack } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { getPropertyName } from 'lib/interfaceUtils'
import { PageIdDefinition, PAGES } from 'pages'
import { usePagedParams } from '../../services/usePagedParams'
import { GebouwenClusterListDto, zodiosHooks } from '../../api/ApiClient'
import { getTitleAndMessage } from '../../services/HandleError'
import ErrorMessageBar from '../../components/ErrorMessageBar/ErrorMessageBar'
import { VgtPagedTable } from '../../components/VgtPagedTable/VgtPagedTable'
import { z } from 'zod'

type GebouwenClusterDtoType = z.infer<typeof GebouwenClusterListDto>;

const stackContainerStyles: Partial<IStackStyles> = {
  root: {
    marginLeft: 2,
    marginRight: 2
  }
}

interface GebouwenClustersTableProps {
  filter: string
}

const GebouwenClustersTable: React.FC<GebouwenClustersTableProps> = ({ filter }) => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [error, setError] = useState<string>()

  const {
    data: fysiekGebouwenCluster,
    isLoading: isFetchingFysiekGebouwenClusterItems
  } = zodiosHooks.useGetGebouwenClustersdetails({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const handleItemInvoked = (id: string) => {
    navigate(PAGES[PageIdDefinition.beheer.fysiek.gebouwenClusterEdit].route.replace(':id', id))
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<GebouwenClusterDtoType>('code'),
      name: 'Code',
      fieldName: getPropertyName<GebouwenClusterDtoType>('code'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: GebouwenClusterDtoType) => (
        <Link key={item.gebouwClusterId} onClick={() => handleItemInvoked(item.gebouwClusterId.toString())}>
          {item.code}
        </Link>
      )
    },
    {
      key: getPropertyName<GebouwenClusterDtoType>('naam'),
      name: 'Naam',
      fieldName: getPropertyName<GebouwenClusterDtoType>('naam'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    }
  ]

  return (
    <Stack styles={stackContainerStyles}>
      <ErrorMessageBar error={error} />
      <VgtPagedTable
        items={fysiekGebouwenCluster?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: fysiekGebouwenCluster?.totalCount ?? 0,
          totalPages: fysiekGebouwenCluster?.totalPages ?? 0,
          hasNextPage: fysiekGebouwenCluster?.hasNextPage ?? false,
          hasPreviousPage: fysiekGebouwenCluster?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection ?? 'asc'
        }}
        columns={columns}
        isLoading={isFetchingFysiekGebouwenClusterItems}
        getKey={(item) => item?.gebouwClusterId}
        onItemInvoked={(e) => handleItemInvoked(e.gebouwClusterId.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </Stack>
  )
}

export default GebouwenClustersTable
