import React, { useMemo, useState } from 'react'
import { IColumn, Link } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import EditPanelElementElementDetail from './editPanel'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'
import {
  ElementElementDetailListDto,
  GerelateerdInspectietakenListDto,
  GerelateerdReparatieboomTaken,
  zodiosHooks
} from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { z } from 'zod'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { usePagedParams } from '../../../../services/usePagedParams'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { createCustomRenderedColumn } from '../../../../lib/gridHelper'
import { VgtTreeViewTable } from '../../../../components/VgtTreeView'

type ElementElementDetailListDtoType = z.infer<typeof ElementElementDetailListDto>;
type GerelateerdInspectietakenListDtoType = z.infer<typeof GerelateerdInspectietakenListDto>;
type GerelateerdReparatieboomTakenType = z.infer<typeof GerelateerdReparatieboomTaken>;

const ElementElementDetailList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: closePanel }] = useBoolean(false)
  const { isAdmin } = useRoles()
  const [selectedElementElementDetailspage, setSelectedElementElementDetailspage] = useState<ElementElementDetailListDtoType | undefined>()
  const [error, setError] = useState<string>()
  const { validatedSearchParams, setFilter, setPage, setOrder } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const {
    data: elementElementDetailspage,
    invalidate: fetchElementElementDetailspageItems,
    isLoading: isFetchingElementElementDetails
  } = zodiosHooks.useGetElementElementDetailspage({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: deleteElementElementDetails } = zodiosHooks.useDeleteElementElementDetails({}, {
    onSuccess: () => fetchElementElementDetailspageItems(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const getGerelateerdInspectietakenData = (item: ElementElementDetailListDtoType) => item.gerelateerdInspectietakenListDto ?? []
  const getGerelateerdReparatieboomTakenData = (item: ElementElementDetailListDtoType) => item.gerelateerdReparatieboomTaken ?? []

  const refreshItems = () => fetchElementElementDetailspageItems()

  const removeItems = () => {
    deleteElementElementDetails(selectedIds)
  }

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
      {
        visible: isAdmin,
        text: 'Toevoegen',
        onClick: () => {
          setSelectedElementElementDetailspage(undefined)
          openPanel()
        },
        icon: 'Add'
      },
      {
        visible: isAdmin,
        text: 'Verwijderen',
        onClick: () => removeItems(),
        icon: 'Delete',
        disabled: !selectedIds.length
      }
    ],
    [isAdmin, selectedIds.length]
  )

  const handleItemInvoked = (item: ElementElementDetailListDtoType) => {
    setSelectedElementElementDetailspage(item)
    openPanel()
  }

  const columns: IColumn[] = [
    createCustomRenderedColumn('Nr.', getPropertyName<ElementElementDetailListDtoType>('id')),
    createCustomRenderedColumn('Element', getPropertyName<ElementElementDetailListDtoType>('element'), (item: ElementElementDetailListDtoType) => (
      <Link key={item.element} onClick={() => handleItemInvoked(item)}>
        {item.element}
      </Link>
    ), 'XL'),
    createCustomRenderedColumn('Elementdetail', getPropertyName<ElementElementDetailListDtoType>('elementDetail'), (item: ElementElementDetailListDtoType) => (
      <Link key={item.elementDetail} onClick={() => handleItemInvoked(item)}>
        {item.elementDetail}
      </Link>
    ), 'XL'),
    createCustomRenderedColumn('Materiaalsoort', getPropertyName<ElementElementDetailListDtoType>('materiaalSoortNaam'), undefined, 'XL'),
    createCustomRenderedColumn('NL-SfB Elementcode', getPropertyName<ElementElementDetailListDtoType>('nlSfBCode'), undefined, 'XL'),
    createCustomRenderedColumn('NL-SfB Omschrijving', getPropertyName<ElementElementDetailListDtoType>('nlSfBOmschrijving'), undefined, 'MAX')
  ]

  const gerelateerdInspectietakenColumns = useMemo(() => [
    createCustomRenderedColumn(
      'Nr.',
      getPropertyName<GerelateerdInspectietakenListDtoType>('inspectietaakId'),
      (item: GerelateerdInspectietakenListDtoType) => <span style={{ fontSize: 14 }}>{item.inspectietaakId}</span>,
      'M',
      false
    ),
    createCustomRenderedColumn(
      'Inspectietaak',
      getPropertyName<GerelateerdInspectietakenListDtoType>('taak'),
      (item: GerelateerdInspectietakenListDtoType) => <span style={{ fontSize: 14 }}>{item.taak}</span>,
      'MAX',
      false
    )
  ], [])

  const reparatieboomColumns = useMemo(() => [
    createCustomRenderedColumn('Nr.', getPropertyName<GerelateerdReparatieboomTakenType>('taakId')),
    createCustomRenderedColumn(
      'Reparatieboom-taak',
      getPropertyName<GerelateerdReparatieboomTakenType>('naam'),
      undefined,
      'MAX'
    )
  ], [])

  return (
    <MainTemplate title='Cartotheek-item' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <EditPanelElementElementDetail fetchElementElementDetailspageItems={fetchElementElementDetailspageItems}
                                     selectedElementElementDetailspage={selectedElementElementDetailspage}
                                     dismissPanel={closePanel} isOpen={isPanelOpen} />
      <VgtTreeViewTable
        items={elementElementDetailspage?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: elementElementDetailspage?.totalCount ?? 0,
          totalPages: elementElementDetailspage?.totalPages ?? 0,
          hasNextPage: elementElementDetailspage?.hasNextPage || false,
          hasPreviousPage: elementElementDetailspage?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        columns={columns}
        isLoading={isFetchingElementElementDetails}
        getKey={(item) => item?.id}
        onItemInvoked={handleItemInvoked}
        onSelectionChanged={setSelectedIds}
        onPageChanged={setPage}
        onSortChanged={setOrder}
        firstChildColumns={gerelateerdInspectietakenColumns}
        secondChildColumns={reparatieboomColumns}
        secondChildData={getGerelateerdReparatieboomTakenData}
        firstChildData={getGerelateerdInspectietakenData}
      />
    </MainTemplate>
  )
}

export default ElementElementDetailList
