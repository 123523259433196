import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState';
import { PagedEntities } from 'interfaces/pagedEntities';
import { invokeFetch } from 'services/apiClient';
import { RootState } from 'store';
import * as yup from 'yup'

export const ruimtesoortErpSchema = yup.object().shape({
    code: yup.string().required('Code is verplicht').max(20, 'Maximale lengte is 20'),
})

export interface IRuimtesoortErp {
    ruimtesoortErpId: number
    code: string
    omschrijving: string
}

const entityAdapter = createEntityAdapter<IRuimtesoortErp>({
    selectId: e => e.ruimtesoortErpId,
    sortComparer: (a, b) => a.omschrijving.localeCompare(b.omschrijving)
})

const baseUrl = '/ruimtesoortErp'
const basePrefix = 'ruimtesoortErp'
const getSliceState = (state: RootState) => state.ruimtesoortErp

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<PagedEntities<IRuimtesoortErp>>(thunkAPI, 'GET', `${baseUrl}?Filter`)
  })
  
  export const updateRuimtesoortErp = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: IRuimtesoortErp, thunkAPI) => {
    return await invokeFetch<IRuimtesoortErp>(thunkAPI, 'PUT', `${baseUrl}/${entity.ruimtesoortErpId}`, entity)
  })
  
  export const addRuimtesoortErp = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: IRuimtesoortErp, thunkAPI) => {
    return await invokeFetch<IRuimtesoortErp>(thunkAPI, 'POST', baseUrl, entity)
  })
  
  export const deleteRuimtesoortErp = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
  })
  
  const setPendingState = (state: EntityState<IRuimtesoortErp> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<IRuimtesoortErp> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<IRuimtesoortErp> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }
  
  export const RuimtesoortErpSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),
  
    reducers: {
      clearError: state => {
        state.error = null
      },
      select: (state, action: PayloadAction<string | undefined>) => {
        state.selectedId = action.payload
      },
      clearSelection: state => {
        state.selectedId = undefined
      },
      add: entityAdapter.addOne,
      modify: entityAdapter.upsertOne,
      removeMany: entityAdapter.removeMany,
      setAll: entityAdapter.setAll,
    },
  
    extraReducers: builder => {
      builder.addCase(fetchAll.pending, state => setPendingState(state))
      builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IRuimtesoortErp>>) => {
        setSucceededState(state)
        entityAdapter.setAll(state, action.payload.items)
      })
      builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(updateRuimtesoortErp.pending, state => setPendingState(state))
      builder.addCase(updateRuimtesoortErp.fulfilled, (state, action: PayloadAction<IRuimtesoortErp>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(updateRuimtesoortErp.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(addRuimtesoortErp.pending, state => setPendingState(state))
      builder.addCase(addRuimtesoortErp.fulfilled, (state, action: PayloadAction<IRuimtesoortErp>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(addRuimtesoortErp.rejected, (state, action) => {
        setRejectedState(state, action)
      })
      builder.addCase(deleteRuimtesoortErp.pending, state => setPendingState(state))
      builder.addCase(deleteRuimtesoortErp.fulfilled, (state, action) => {
        entityAdapter.removeMany(state, action.meta.arg)
        setSucceededState(state)
      })
      builder.addCase(deleteRuimtesoortErp.rejected, (state, action) => setRejectedState(state, action))
    },
  })
  
  export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
  }

  export const getLoadingState = (state: RootState) => state.ruimtesoortErp.status
  
  export const getErrorState = (state: RootState) => state.ruimtesoortErp.error
  
  export const { add, modify, removeMany, select, clearSelection } = RuimtesoortErpSlice.actions
  export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.ruimtesoortErp)
  
  export default RuimtesoortErpSlice.reducer
  