import React from 'react'
import { IStackStyles, ITextStyles, Stack, Text } from '@fluentui/react'

type EnergyLabels = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';

export type EnergyLabelType = EnergyLabels | `A${string}`;

interface EnergyLabelProps {
  label: EnergyLabelType;
  size?: number;
}

const containerStyles: Partial<IStackStyles> = {
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  }
}

const energyLabelColors: { [key in EnergyLabels]: string } = {
  A: '#399439',
  B: '#49bf49',
  C: '#9ddf6c',
  D: '#ffd818',
  E: '#fdbd57',
  F: '#fd6b39',
  G: '#fc142d'
}

const getColor = (label: EnergyLabelType) => {
  const aPlusPattern = /^A\+*$/
  if (aPlusPattern.test(label)) return energyLabelColors['A']
  return energyLabelColors[label as EnergyLabels]
}

const EnergyLabel: React.FC<EnergyLabelProps> = ({ label, size = 20 }) => {

  const color = getColor(label)

  const triangleStyles: Partial<IStackStyles> = {
    root: {
      backgroundColor: 'transparent',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderTopColor: 'transparent',
      borderBottomColor: 'transparent',
      borderLeftColor: color,
      borderRightColor: 'transparent',
      borderTopWidth: size,
      borderBottomWidth: size,
      borderLeftWidth: size,
      borderRightWidth: 0
    }
  }

  const textStyles: Partial<ITextStyles> = {
    root: {
      fontWeight: 'bold',
      color: 'white',
      textShadowColor: 'rgba(0,0,0,1)',
      fontSize: size,
      backgroundColor: color,
      lineHeight: `${size * 2}px`,
      textAlign: 'center',
      padding: '0 10px'
    }
  }

  return (
    <Stack styles={containerStyles}>
      <Text styles={textStyles}>{label}</Text>
      <Stack styles={triangleStyles} />
    </Stack>
  )
}

export default EnergyLabel
