import React from 'react'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { useNavigate, useParams } from 'react-router-dom'
import useApi from 'services/UseApi'
import { DimensiewaardeForm } from './DimensiewaardenForm'
import { IDimensiewaardeUpdateOrAdd } from './DimensiewaardeApi'

const DimensiewaardenNew = () => {
  const navigate = useNavigate()
  const { dimensieId } = useParams()
  if (!parseInt(dimensieId ?? '')) {
    throw new Error('Invalid dimensie id')
  }
  const { state: fetchState } = useApi<IDimensiewaardeUpdateOrAdd>("dimensies/" + dimensieId, 'GET')
  const { state: addState, execute } = useApi(`dimensies/${dimensieId}/waarden`, 'POST', false)

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true }
  ]

  return (
    <MainTemplate title={'Dimensiewaarde voor dimensie ' + fetchState.data?.omschrijving } subTitle='nieuw' commandItems={commandItems} error={fetchState.error || addState.error}>
      <DimensiewaardeForm onSubmit={(data) => {
        execute(data, () => navigate(-1))
      }} onCancel={() => navigate(-1)} />
    </MainTemplate>
  )
}

export default DimensiewaardenNew