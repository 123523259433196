import React, { useState } from 'react'
import { IStackStyles, Link, Stack } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { getPropertyName } from 'lib/interfaceUtils'
import { GebouwdeelListDto, zodiosHooks } from '../../api/ApiClient'
import { getTitleAndMessage } from '../../services/HandleError'
import { usePagedParams } from '../../services/usePagedParams'
import { z } from 'zod'
import { createColumn, createCustomRenderedColumn } from '../../lib/gridHelper'
import { VgtPagedTable } from '../../components/VgtPagedTable/VgtPagedTable'
import ErrorMessageBar from '../../components/ErrorMessageBar/ErrorMessageBar'
import { availableUrls } from '../../routing/AvailableUrls'

type GebouwdeelListDtoType = z.infer<typeof GebouwdeelListDto>;

const stackContainerStyles: Partial<IStackStyles> = {
  root: {
    marginLeft: 2,
    marginRight: 2
  }
}

interface GebouwdelenTableProps {
  filter: string
}

const GebouwdelenTable: React.FC<GebouwdelenTableProps> = ({ filter }) => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [error, setError] = useState<string>()

  const {
    data: gebouwdelenItems,
    isLoading: isFetchingGebouwdeelItems
  } = zodiosHooks.useGetGebouwdelen({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const handleItemInvoked = (id: string) => {
    navigate(availableUrls.Gebouwdeel.replace(':id', id))
  }

  const columns = [
    createCustomRenderedColumn(
      'Code',
      getPropertyName<GebouwdeelListDtoType>('code'),
      (item: GebouwdeelListDtoType) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.code}
        </Link>
      ),
      'S',
      false
    ),
    createColumn('Naam', getPropertyName<GebouwdeelListDtoType>('naam'), 'XL'),
    createColumn('Gebouw Naam', getPropertyName<GebouwdeelListDtoType>('gebouwNaam'), 'XL')
  ]

  return (
    <Stack styles={stackContainerStyles}>
      <ErrorMessageBar error={error} />
      <VgtPagedTable
        items={gebouwdelenItems?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: gebouwdelenItems?.totalCount ?? 0,
          totalPages: gebouwdelenItems?.totalPages ?? 0,
          hasNextPage: gebouwdelenItems?.hasNextPage ?? false,
          hasPreviousPage: gebouwdelenItems?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        columns={columns}
        isLoading={isFetchingGebouwdeelItems}
        getKey={(item) => item?.id}
        onItemInvoked={(e) => handleItemInvoked(e.id.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </Stack>
  )
}

export default GebouwdelenTable
