import React from 'react'
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  ScrollbarVisibility,
  Spinner,
  SpinnerSize,
  StickyPositionType,
  Sticky,
  ScrollablePane,
  Selection,
  IDetailsListStyles,
  mergeStyles,
  FontSizes,
  FontWeights,
  SelectionMode,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
  ISelection,
  IStyle,
  ISpinnerStyles
} from '@fluentui/react'

import './index.css'

const styles: Partial<IDetailsListStyles> = {
  root: {
    width: '100%',
    paddingTop: '0px'
  },
}

interface IFluentTableState {
  columns: Array<IColumn>
  items: Array<any>
  loading: boolean
}

interface IFluentTableProps {
  height?: string,
  cellStyles?: IStyle,
  columns: Array<IColumn>
  items: Array<any> | []
  loading?: boolean
  scrollToRowIndex?: number
  selectionMode?: SelectionMode
  className?: string  
  selection?: ISelection
  onSelectionChanged?: (ids: string[]) => void
  onItemInvoked: (id: string, index: number) => void
  onGetKey: (item: any) => string
  fontSize?: string
  spinnerStyles?: ISpinnerStyles
}

export default class FluentTable extends React.Component<IFluentTableProps, IFluentTableState> {
  private readonly _listSelection: Selection

  constructor(props) {
    super(props)

    this._listSelection = new Selection({
      onSelectionChanged: this.handleSelectionChanged,
    })

    const sortBy = this.props.columns.find(column => column.isSorted)
    const items = sortBy ? copyAndSort(this.props.items, sortBy.key, sortBy.isSortedDescending) : this.props.items    

    const headerStyles = mergeStyles({
      displayName: 'CustomColumn',
      span: {
        fontSize: this.props.fontSize ? this.props.fontSize : FontSizes.size12,
        fontWeight: FontWeights.regular,
      },
    })
    const cellStyles = mergeStyles({
      displayName: 'CustomColumn',
      span: {
        fontSize: this.props.fontSize ? this.props.fontSize : FontSizes.size12,
        fontWeight: FontWeights.regular,
      },
      backgroundColor: '#EEE'
    })

    this.state = {
      items,
      columns: props.columns.map(
        (col: IColumn) =>
        ({
          ...col,
          onColumnClick: this.handleColumnClick,
          headerClassName: headerStyles,
          className: this.props.className ?? + ' ' + cellStyles,
        } as IColumn)
      ),
      loading: props.loading,
    }
  }

  static getDerivedStateFromProps(nextProps: IFluentTableProps, prevState: IFluentTableState): IFluentTableState {
    // This logic decides whether the component should rerender when it receives new props.
    let nextState = {} as IFluentTableState
    if (nextProps.items !== prevState.items || nextProps.columns !== prevState.columns) {
      nextState.items = nextProps.items
      nextState.columns = nextProps.columns
      nextProps.selection?.setAllSelected(false)
    }

    return nextState
  }

  private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {}
    if (props) {
      if (props.itemIndex === this.props.scrollToRowIndex) {
        customStyles.root = { border: 'solid 1px' }
      }

      if (props.itemIndex % 2 === 0) {
        customStyles.root = { backgroundColor: '#f7f7f7' };
      }
      else {
        customStyles.root = { backgroundColor: '#ffffff' };
        }

        customStyles.cell = mergeStyles({ display: 'flex', alignItems: 'center' }, this.props.cellStyles);

      return <DetailsRow {...props} styles={customStyles} />
    }
    return null
  }
  render() {
    const { loading } = this.props
    const { items, columns } = this.state
    const spinnerStyles = this.props.spinnerStyles ?? {circle:{width: '3rem',height: '3rem',borderWidth: '4.5px'}};
    return (
      <div
        className="fluentTableWrapper"
        style={{ height: this.props.height ? this.props.height : '100%' }}>
        {loading && (
          <div >
            <Spinner size={SpinnerSize.large} styles={spinnerStyles}/>
          </div>
        )}
        {!loading && (
          <div style={{ display: 'flex', position: 'relative', height: "100%", width: "100%" }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} className='vgt-table-scroll-bar'>
              <DetailsList
                selection={this._listSelection}
                selectionMode={this.props.selectionMode}
                items={items!}
                initialFocusedIndex={this.props.scrollToRowIndex}
                columns={columns}
                compact={true}
                layoutMode={DetailsListLayoutMode.justified}
                selectionPreservedOnEmptyClick={true}
                onItemInvoked={this.handleItemInvoked}
                getKey={this.handleGetKey}
                setKey="none"
                className={this.props.className}
                constrainMode={ConstrainMode.unconstrained}
                styles={styles}
                onRenderRow={this._onRenderRow}
                onRenderDetailsHeader={(headerProps, defaultRender) => {
                  return (
                    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true} stickyBackgroundColor="transparent">
                      <div>{defaultRender && defaultRender(headerProps)}</div>
                    </Sticky>
                  )
                }}
              />
            </ScrollablePane>
          </div>
        )}
      </div>
    )
  }

  private handleSelectionChanged = () => {
    const { onSelectionChanged, onGetKey } = this.props
    const ids: string[] = []

    this._listSelection.getSelection().forEach(item => ids.push(onGetKey(item)))
    if (onSelectionChanged) {
      onSelectionChanged(ids)
    }
  }

  private handleItemInvoked = (item, index) => {
    const { onGetKey, onItemInvoked } = this.props
    const id = onGetKey(item)
    id && onItemInvoked(id, index)
  }

  private handleGetKey = (item: any): string => {
    const { onGetKey } = this.props
    const id = onGetKey(item)
    return id ? id.toString() : ''
  }

  private handleColumnClick = (_, column: IColumn): void => {
    const { columns, items } = this.state
    const newColumns: IColumn[] = columns.slice()
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0]
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending
        currColumn.isSorted = true
      } else {
        newCol.isSorted = false
        newCol.isSortedDescending = true
      }
    })

    const newItems = copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending)
    this.setState({
      columns: newColumns,
      items: newItems,
    })
  }
}

function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1))
}
