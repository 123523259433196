import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IGenericErrorState {
  title: string
  subText: string
  route?: string
  errors?: string[]
}

const initState: IGenericErrorState = {
  title: '',
  subText: '',
  route: '',
  errors: [],
}

export const slice = createSlice({
  name: 'genericError',
  initialState: initState,
  reducers: {
    setGenericError: (state, action: PayloadAction<IGenericErrorState>) => {
      return action.payload
    },
    resetGenericError: state => {
      return { ...initState }
    },
  },
  extraReducers: {},
})

export const { setGenericError, resetGenericError } = slice.actions

export default slice.reducer
