import { entityAdapterInitState } from './../../../../interfaces/entityAdapterState'
import { PagedEntities } from 'interfaces/pagedEntities'
import { IDropdownOption } from '@fluentui/react'
import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, EntityId, EntityState } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import * as yup from 'yup'
import { IEntityAdapterState } from 'interfaces/entityAdapterState'

export interface IBouwlaag {
  id: number
  naam: string
  order: number
  naamExtern: string
}

export interface IBouwlaagReorder {
  id: number
  moveUp: boolean
}

export const bouwdeelSchema = yup.object().shape({
  naam: yup.string().required('De naam is verplicht.').max(150, 'De maximale lengte is 150.'),
  naamExtern: yup.string().nullable().optional().max(150, 'De maximale lengte is 150.'),
})

const entityAdapter = createEntityAdapter<IBouwlaag>({
  sortComparer: (a, b) => {
    if (a.order < b.order) return -1
    if (a.order === b.order) return 0
    return 1
  },
})

const baseUrl = '/api/bouwlagen'
const basePrefix = 'bouwlagen'
const getSliceState = (state: RootState) => state.bouwlagen

export const fetchAllBouwlagen = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
  return await invokeFetch<PagedEntities<IBouwlaag>>(thunkAPI, 'GET', `${baseUrl}`)
})

export const updateBouwlagen = createAsyncThunk(`${basePrefix}/update`, async (entity: IBouwlaag, thunkAPI) => {
  return await invokeFetch<IBouwlaag>(thunkAPI, 'PUT', baseUrl, entity)
})

export const addBouwlagen = createAsyncThunk(`${basePrefix}/add`, async (entity: IBouwlaag, thunkAPI) => {
  return await invokeFetch<IBouwlaag>(thunkAPI, 'POST', baseUrl, entity)
})

export const deleteBouwlagen = createAsyncThunk(`${basePrefix}/delete`, async (entities: number[], thunkAPI) => {
  return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
})

export const moveBouwlaag = createAsyncThunk(`${basePrefix}/reorder`, async (command: IBouwlaagReorder, thunkAPI) => {
  return await invokeFetch<PagedEntities<IBouwlaag>>(thunkAPI, 'POST', `${baseUrl}/reorder`, command)
})

const setPendingState = (state: EntityState<IBouwlaag> & IEntityAdapterState) => {
  state.error = null
  state.status = 'pending'
}
const setSucceededState = (state: EntityState<IBouwlaag> & IEntityAdapterState) => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<IBouwlaag> & IEntityAdapterState, action) => {
  state.error = action.error.message || null
  state.status = 'failed'
}

export const bouwlagenslice = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
    clearError: state => {
      state.error = null
    },
    select: (state, action: PayloadAction<string | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
  },

  extraReducers: builder => {
    builder.addCase(fetchAllBouwlagen.pending, state => setPendingState(state))
    builder.addCase(fetchAllBouwlagen.fulfilled, (state, action: PayloadAction<PagedEntities<IBouwlaag>>) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchAllBouwlagen.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(updateBouwlagen.pending, state => setPendingState(state))
    builder.addCase(updateBouwlagen.fulfilled, (state, action: PayloadAction<IBouwlaag>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(updateBouwlagen.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(addBouwlagen.pending, state => setPendingState(state))
    builder.addCase(addBouwlagen.fulfilled, (state, action: PayloadAction<IBouwlaag>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(addBouwlagen.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(deleteBouwlagen.pending, state => setPendingState(state))
    builder.addCase(deleteBouwlagen.fulfilled, (state, action) => {
      entityAdapter.removeMany(state, action.meta.arg)
      setSucceededState(state)
    })
    builder.addCase(deleteBouwlagen.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(moveBouwlaag.pending, state => setPendingState(state))
    builder.addCase(moveBouwlaag.fulfilled, (state, action: PayloadAction<PagedEntities<IBouwlaag>>) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(moveBouwlaag.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const getSelectedEntity = (state: RootState) => {
  const selectedId = getSliceState(state).selectedId
  if (selectedId) return getSliceState(state).entities[selectedId]
  else return undefined
}

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}

export const getErrorState = (state: RootState) => {
  return getSliceState(state).error
}

export const { add, modify, removeMany, select, clearSelection } = bouwlagenslice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.bouwlagen)

export const getBouwlagenAsOptions = (state: RootState) => {
  return state.bouwlagen.ids.map((id: EntityId) => {
    const entity = state.bouwlagen.entities[id]
    return {
      key: id,
      text: `${entity?.naam}`,
    } as IDropdownOption
  })
}

export default bouwlagenslice.reducer
