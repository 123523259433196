import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, EntityState, EntityId } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'
import * as yup from 'yup'
import { IDropdownOption } from '@fluentui/react'


export const onderhoudsbedrijfTyperingSchema = yup.object().shape({
    naam: yup.string().required('Typering is verplicht').max(50, 'Maximale lengte is 50')
})

export interface IOnderhoudsbedrijfTypering { 
  onderhoudsbedrijfTyperingId: number,
    naam: string
}

const entityAdapter = createEntityAdapter<IOnderhoudsbedrijfTypering>({
    selectId: (entity: IOnderhoudsbedrijfTypering) => entity.onderhoudsbedrijfTyperingId,
    sortComparer: (a, b) => a.naam?.localeCompare(b.naam),
  })
  
  const baseUrl = '/onderhoudsbedrijfTypering'
  const basePrefix = 'onderhoudsbedrijfTypering'
  const getSliceState = (state: RootState) => state.onderhoudsbedrijfTypering
  
  export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<PagedEntities<IOnderhoudsbedrijfTypering>>(thunkAPI, 'GET', `${baseUrl}`)
  })
  
  export const updateOnderhoudsbedrijfTypering = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: IOnderhoudsbedrijfTypering, thunkAPI) => {
    return await invokeFetch<IOnderhoudsbedrijfTypering>(thunkAPI, 'PUT', `${baseUrl}/${entity.onderhoudsbedrijfTyperingId}`, entity)
  })
  
  export const addOnderhoudsbedrijfTypering = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: IOnderhoudsbedrijfTypering, thunkAPI) => {
    return await invokeFetch<IOnderhoudsbedrijfTypering>(thunkAPI, 'POST', baseUrl, entity)
  })
  
  export const deleteOnderhoudsbedrijfTypering = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
  })
  
  const setPendingState = (state: EntityState<IOnderhoudsbedrijfTypering> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<IOnderhoudsbedrijfTypering> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<IOnderhoudsbedrijfTypering> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }
  
  export const OnderhoudsbedrijfTyperingSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),
  
    reducers: {
      clearError: state => {
        state.error = null
      },
      select: (state, action: PayloadAction<string | undefined>) => {
        state.selectedId = action.payload
      },
      clearSelection: state => {
        state.selectedId = undefined
      },
      add: entityAdapter.addOne,
      modify: entityAdapter.upsertOne,
      removeMany: entityAdapter.removeMany,
      setAll: entityAdapter.setAll,
    },
  
    extraReducers: builder => {
      builder.addCase(fetchAll.pending, state => setPendingState(state))
      builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IOnderhoudsbedrijfTypering>>) => {
        setSucceededState(state)
        entityAdapter.setAll(state, action.payload.items)
      })
      builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(updateOnderhoudsbedrijfTypering.pending, state => setPendingState(state))
      builder.addCase(updateOnderhoudsbedrijfTypering.fulfilled, (state, action: PayloadAction<IOnderhoudsbedrijfTypering>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(updateOnderhoudsbedrijfTypering.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(addOnderhoudsbedrijfTypering.pending, state => setPendingState(state))
      builder.addCase(addOnderhoudsbedrijfTypering.fulfilled, (state, action: PayloadAction<IOnderhoudsbedrijfTypering>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(addOnderhoudsbedrijfTypering.rejected, (state, action) => {
        setRejectedState(state, action)
      })
      builder.addCase(deleteOnderhoudsbedrijfTypering.pending, state => setPendingState(state))
      builder.addCase(deleteOnderhoudsbedrijfTypering.fulfilled, (state, action) => {
        entityAdapter.removeMany(state, action.meta.arg)
        setSucceededState(state)
      })
      builder.addCase(deleteOnderhoudsbedrijfTypering.rejected, (state, action) => setRejectedState(state, action))
    },
  })
  
  export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
  }
  
  export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
  }
  
  export const getErrorState = (state: RootState) => {
    return getSliceState(state).error
  }
  
  export const { add, modify, removeMany, select, clearSelection } = OnderhoudsbedrijfTyperingSlice.actions
  export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.onderhoudsbedrijfTypering)


  export const getOnderhoudsbedrijfTyperingAsOptions = (state: RootState) => state.onderhoudsbedrijfTypering.ids.map((id: EntityId) => {
    const entity = state.onderhoudsbedrijfTypering.entities[id]
    return {
        key: id,
        text: `${entity?.naam}`
    } as IDropdownOption
})
  
  export default OnderhoudsbedrijfTyperingSlice.reducer
