import {
  HuurcontractbepalingGebouwdeelListDto, NietGekoppeldGebouwdeelListDto,
  zodiosHooks
} from '../../../api/ApiClient'
import React, { useEffect, useState } from 'react'
import commandBarStyles from '../../../styles/commandBarStyles'
import { CommandBar, IColumn, ICommandBarItemProps, Panel, PanelType, SearchBox } from '@fluentui/react'
import { createCommandButton } from '../../../lib/commandBarHelper'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { usePagedParams } from '../../../services/usePagedParams'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import { useParams } from 'react-router-dom'
import { EditParams } from '../../vhe/VheEdit'
import { IHuurcontractbepalingList, INietGekoppeldFormPanelProps } from './HuurcontractbepalingGebouwclusterList'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import { useBoolean } from '@fluentui/react-hooks'
import { getTitleAndMessage } from '../../../services/HandleError'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'


type HuurcontractbepalingGebouwdeelListDtoType = z.infer<typeof HuurcontractbepalingGebouwdeelListDto>;

export const HuurcontractbepalingGebouwdeelList: React.FC<IHuurcontractbepalingList> = props => {
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const params = useParams()
  const validatedParams = EditParams.parse(params)
  const [error, setError] = useState<string>()

  const {
    data: huurcontractbepalingGebouwdelenData,
    invalidate: invalidateHuurcontractbepalingGebouwdeelData,
    isLoading
  } = zodiosHooks.useGetApiHuurcontractbepalinglistGebouwdelen({
    queries: {
      HuurcontractbepalingId: validatedParams.id,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  useEffect(() => {
    if (huurcontractbepalingGebouwdelenData) props.setCount(huurcontractbepalingGebouwdelenData.totalCount)
  }, [huurcontractbepalingGebouwdelenData])

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Gebouwdeel', getPropertyName<HuurcontractbepalingGebouwdeelListDtoType>('gebouwdeelNaam'), 'L', false)
    ]
  }, [])

  const AddGebouwdeelToSelection = () => {
    openGebouwdeelPanel()
  }

  const RemoveGebouwdeelFromSelection = () => {
    deleteRows({ huurcontractbepalingId: validatedParams.id, itemsToDelete: selectedIds })

  }
  const { mutate: deleteRows } = zodiosHooks.useDeleteApiHuurcontractbepalingontkoppelingGebouwdelen({}, {
    onSuccess: () => invalidateHuurcontractbepalingGebouwdeelData(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const [isGebouwdeelPanelOpen, {
    setTrue: openGebouwdeelPanel,
    setFalse: dismissGebouwdeelPanel
  }] = useBoolean(false)

  const commandbarItems = [
    createCommandButton('Koppelen', 'PlugConnected', AddGebouwdeelToSelection),
    createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveGebouwdeelFromSelection, selectedIds.length !== 0)
  ] as ICommandBarItemProps[]

  return (
    <div>
      <CommandBar items={commandbarItems} styles={commandBarStyles} />
      <ErrorMessageBar error={error} />
      <NietGekoppeldGebouwdeelFormPanel huurcontractbepalingId={validatedParams.id} isOpen={isGebouwdeelPanelOpen}
                                        refresh={invalidateHuurcontractbepalingGebouwdeelData}
                                        dismissPanel={dismissGebouwdeelPanel} />
      <VgtPagedTable
        items={huurcontractbepalingGebouwdelenData?.items ?? undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: huurcontractbepalingGebouwdelenData?.totalCount ?? 0,
          totalPages: huurcontractbepalingGebouwdelenData?.totalPages ?? 0,
          hasNextPage: huurcontractbepalingGebouwdelenData?.hasNextPage ?? false,
          hasPreviousPage: huurcontractbepalingGebouwdelenData?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.gebouwdeelId}
        onSelectionChanged={setSelectedIds}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </div>
  )
}

type NietGekoppeldGebouwdeelListDtoType = z.infer<typeof NietGekoppeldGebouwdeelListDto>;

const NietGekoppeldGebouwdeelFormPanel: React.FC<INietGekoppeldFormPanelProps> = props => {

  const [error, setError] = React.useState<string>()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Naam', getPropertyName<NietGekoppeldGebouwdeelListDtoType>('gebouwdeelNaam'), 'L', false),
      createColumn('Code', getPropertyName<NietGekoppeldGebouwdeelListDtoType>('gebouwdeelCode'), 'L', false)
    ]
  }, [])

  const onCancel = () => {
    props.dismissPanel()
    props.refresh()
    invalidate()
  }

  const { data, invalidate, isLoading } = zodiosHooks.useGetApiHuurcontractbepalingnietGekoppeldGebouwdelen({
    queries: {
      HuurcontractbepalingId: props.huurcontractbepalingId,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })


  const onRenderFooterContent = () => {
    return (
      <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                           onOkClick={onSubmit}
                           onCancelClick={onCancel} />
    )
  }

  const {
    mutate: addKoppelingGebouwdeel,
    isLoading: isSubmitting
  } = zodiosHooks.usePostApiHuurcontractbepalingkoppelingGebouwdelen(
    {}, { onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const onSubmit = () => {
    addKoppelingGebouwdeel({ huurcontractbepalingId: props.huurcontractbepalingId, gebouwdeelIds: selectedIds })
  }

  return (
    <Panel
      type={PanelType.large}
      headerText={'Koppelen gebouwcluster'}
      isOpen={props.isOpen}
      onDismiss={onCancel}
      closeButtonAriaLabel="Sluiten"
      onRenderFooterContent={onRenderFooterContent}>
      <ErrorMessageBar error={error} />
      <SearchBox placeholder="Filter" styles={{ root: { width: '400px', margin: '6px' } }}
                 value={validatedSearchParams.filter}
                 onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                 onSearch={() => invalidate()} />
      <VgtPagedTable
        items={data?.items ?? undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage ?? false,
          hasPreviousPage: data?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.gebouwdeelId}
        onSelectionChanged={setSelectedIds}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </Panel>
  )
}