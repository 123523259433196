import { createSlice, createEntityAdapter, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store'

export interface INotification {
    id: number
    description: string
    status: 'succeeded' | 'failed' | 'started'
    started?: string
    finished: string
    timeStarted: string
    timeFinished: string
    initiatedBy: string
    actionUrl: string
    actionName: string
    vgeAddress: string
    isDismissed: boolean
    notificationShown: boolean
    toastShown: boolean
}

const initState: INotification[] = []
// const initState: INotification[] = [{
//     id: 1,
//     description: 'Verlopen garantie product',
//     status: 'succeeded',
//     timeStarted: '12:00',
//     timeFinished: '14:00',
//     initiatedBy: 'Vastgoedtabel',
//     actionUrl: '/vge/edit/69071',
//     actionName: 'Controle',
//     vgeAddress: 'Abeelstraat 64, Winterswijk',
//     notificationShown: false,
//     finished: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 10, 0, 0, 0).toISOString(),
//     toastShown: true,
//     isDismissed: false
// },
// {
//     id: 2,
//     description: 'Verlopen uiterste opzegdatum contract',
//     status: 'succeeded',
//     timeStarted: '12:00',
//     timeFinished: '14:00',
//     initiatedBy: 'Vastgoedtabel',
//     actionName: 'Controle',
//     actionUrl: '/vge/edit/68706',
//     vgeAddress: 'Aaldersbeeklaan 54, Enschede',
//     notificationShown: false,
//     finished: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 8, 0, 0, 0).toISOString(),
//     toastShown: true,
//     isDismissed: false
// },
// {
//     id: 3,
//     description: 'Verlopen keuringsdatum',
//     status: 'succeeded',
//     timeStarted: '12:00',
//     timeFinished: '14:00',
//     initiatedBy: 'Vastgoedtabel',
//     actionName: 'Controle',
//     actionUrl: '/vge/edit/68603',
//     vgeAddress: 'Albergenbrink 17, Enschede',
//     notificationShown: false,
//     finished: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 7, 0, 0, 0).toISOString(),
//     toastShown: true,
//     isDismissed: false

// },
// {
//     id: 4,
//     description: 'Update Notification ',
//     status: 'succeeded',
//     timeStarted: '12:00',
//     timeFinished: '14:00',
//     initiatedBy: 'Vastgoedtabel',
//     actionUrl: 'www.test4.com',
//     actionName: 'Test4',
//     vgeAddress: 'Controle',
//     notificationShown: true,
//     finished: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 6, 0, 0, 0).toISOString(),
//     toastShown: true,
//     isDismissed: true
// }
// ]

const entityAdapter = createEntityAdapter<INotification>({
    selectId: (notificaties) => notificaties.id,
    sortComparer: (a, b) => a.finished > b.finished ? -1 : 1
})

const notificatiesSlice = createSlice({
    name: 'notificaties',
    initialState: entityAdapter.getInitialState(),
    reducers: {
        initializeTestData: (state) => {
            entityAdapter.setAll(state, initState)
        },
        addNotification: (state, action: PayloadAction<INotification>) => {
            entityAdapter.addOne(state, action.payload)
        },
        dismissNotification: (state, action: PayloadAction<INotification>) => {
            entityAdapter.upsertOne(state, { ...action.payload, isDismissed: true })
        },
        updateToastHandled: (state, action: PayloadAction<INotification>) => {
            entityAdapter.upsertOne(state, { ...action.payload, toastShown: true })
        },
        updateNotificationShown: (state, action: PayloadAction<INotification[]>) => {
            entityAdapter.upsertMany(state, action.payload)
        }
    },
})

const getSliceState = (state: RootState) => state.notificaties;

export const { initializeTestData, addNotification, dismissNotification, updateToastHandled, updateNotificationShown } = notificatiesSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => getSliceState(state))

export const selectNotificationsForPanel = createSelector([selectAll], (entities) => {
    return (entities.filter(notification => !notification.isDismissed))
})

export const selectNotificationsToToast = createSelector([selectAll], (entities) => {
    return (entities.filter(notification => !notification.toastShown))
})

export const countNotShownNotifications = createSelector([selectAll], (entities) => {
    return (entities.filter(notification => !notification.notificationShown).length)
})

export default notificatiesSlice.reducer
