import { useNavigate } from 'react-router-dom'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { RechtspersoonForm } from './RechtspersoonForm'
import { zodiosHooks } from 'api/ApiClient'
import { getTitleAndMessage } from 'services/HandleError'
import React from 'react'

export const RechtspersoonNew = () => {
  const navigate = useNavigate()
  const [error, setError] = React.useState<string>()

  const { mutate, isLoading } = zodiosHooks.usePostRechtspersonen(
    {}, {onSuccess: () => navigate(-1), onError: (error) => setError(getTitleAndMessage(error).message)}
  )

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true }
  ]

  return (
    <MainTemplate title='Rechtspersoon' subTitle='nieuw' commandItems={commandItems} error={error}>
      <RechtspersoonForm onSubmit={mutate} isLoading={isLoading} onCancel={() => navigate(-1)} />
    </MainTemplate>
  )
}