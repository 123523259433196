import { useNavigate } from 'react-router-dom'
import { usePagedParams } from '../../../../services/usePagedParams'
import { useBoolean } from '@fluentui/react-hooks'
import React, { useMemo, useState } from 'react'
import useRoles from '../../../../services/UseRoles'
import { FonticoonListDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { IColumn, Link } from '@fluentui/react'
import { createColumn, createCustomRenderedColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import MainTemplate from '../../../../containers/pages/PageTemplates/MainTemplate'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { z } from 'zod'
import { FonticoonFormPanel } from './FonticoonFormPanel'
import ICoMoonFontIcon from '../../../../components/IcoMoon'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'

type FonticoonListDtoType = z.infer<typeof FonticoonListDto>;

export const FonticoonList = () => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [itemToEdit, setItemToEdit] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const { isAdmin } = useRoles()
  const [error, setError] = React.useState<string>()

  const { data, invalidate, isLoading } = zodiosHooks.useGetApiFonticoon({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteApiFonticoon({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
    {
      text: 'Toevoegen',
      onClick: () => {
        openPanel()
      },
      icon: 'Add',
      iconOnly: true,
      visible: isAdmin
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        deleteRows({ itemsToDelete: selectedIds })
      },
      icon: 'Delete',
      iconOnly: true,
      disabled: isDeleting || selectedIds.length === 0
    }
  ], [isAdmin, selectedIds.length])


  const handleItemInvoked = (id: string) => {
    if (id) {
      setItemToEdit(parseInt(id))
      openPanel()
    }
  }

  const handleDismissPanel = () => {
    setItemToEdit(0)
    dismissPanel()
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Naam', getPropertyName<FonticoonListDtoType>('fonticoonId'), 'XXL', true,
        (item: FonticoonListDtoType) => (
          <Link key={item.fonticoonId} onClick={() => handleItemInvoked(item.fonticoonId.toString())}>
            {item.naam}
          </Link>
        )),
      createColumn('Omschrijving', getPropertyName<FonticoonListDtoType>('omschrijving'), 'XXL', true),
      createCustomRenderedColumn(
        'Fonticoon',
        getPropertyName<FonticoonListDtoType>('naam'),
        (item: FonticoonListDtoType) => (
          <ICoMoonFontIcon iconName={item.naam!} />
        ),
        'L',
        false
      )
    ]
  }, [])

  return (
    <MainTemplate title='Fonticoon' subTitle='overzicht' commandItems={commandItems} error={error}>
      <FonticoonFormPanel fonticoonId={itemToEdit} dismissPanel={handleDismissPanel} isOpen={isPanelOpen}
                          invalidate={invalidate} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage ?? false,
          hasPreviousPage: data?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey ?? 'naam',
          sortDirection: validatedSearchParams.sortDirection ?? 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.fonticoonId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.fonticoonId.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}