import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { invokeFetch } from 'services/apiClient'
import { PagedEntities } from 'interfaces/pagedEntities'
import { IDropdownOption } from '@fluentui/react'

export interface IMeetkundigeEenheid {
    id: number
    code: string
    omschrijving: string
}

interface IMeetkundigeEenheidState {
    status: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: string | null
}

export const initialState: IMeetkundigeEenheidState = {
    status: 'idle',
    error: null
}

const entityAdapter = createEntityAdapter<IMeetkundigeEenheid>()

export const fetchMeetkundigeEenheden = createAsyncThunk('meetkundigeEenheden/fetchAll', async (_, thunkAPI) => {
    return await invokeFetch<PagedEntities<IMeetkundigeEenheid>>(thunkAPI, 'GET', '/api/meetkundigeEenheden/fetchAll')
})


export const meetkundigeEenheden = createSlice({
    name: 'meetkundigeEenheden',

    initialState: entityAdapter.getInitialState(initialState),

    reducers: {},

    extraReducers: builder => {
        builder.addCase(fetchMeetkundigeEenheden.pending, state => {
            state.status = 'pending'
        })
        builder.addCase(fetchMeetkundigeEenheden.fulfilled, (state, action) => {
            state.status = 'succeeded'
            entityAdapter.setAll(state, action.payload.items)
        })
        builder.addCase(fetchMeetkundigeEenheden.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message || null
        })
    }
})

export const { selectAll } = entityAdapter.getSelectors<RootState>(state => state.meetkundigeEenheden)

export const getDropDownValues = (state: RootState) => {
    const options: IDropdownOption[] = []
    const eenheden = selectAll(state)
    eenheden.forEach(i => {
        options.push({
            key: i.id,
            text: i.code
        })
    })
    return options
}

export default meetkundigeEenheden.reducer
