import React from 'react'
import useRoles from '../../../../services/UseRoles'
import { z } from 'zod'
import { CreateElementdetailsCommand, zodiosHooks } from '../../../../api/ApiClient'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Panel, PanelType } from '@fluentui/react'
import VgtTextField from '../../../../components/VgtTextField'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { getTitleAndMessage } from '../../../../services/HandleError'
import VgtDropdown from '../../../../components/FluentDropdown'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'


export interface IEditPanelProps {
  elementDetailId: number,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateOrUpdateType = z.infer<typeof CreateElementdetailsCommand>;

export const ElementDetailFormPanel: React.FC<IEditPanelProps> = props => {

  const { isAdmin } = useRoles()
  const [error, setError] = React.useState<string>()

  const defaultElementDetail = {
    code: '',
    fonticoonId: 0
  } as CreateOrUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateOrUpdateType>({
    resolver: zodResolver(CreateElementdetailsCommand), mode: 'all', defaultValues: defaultElementDetail
  })

  const {
    mutate: updateElementDetail,
    isLoading: isUpdatingElementDetail
  } = zodiosHooks.usePutElementDetailId(
    { params: { id: props.elementDetailId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: addElementDetail, isLoading: isAddingElementDetail } = zodiosHooks.usePostElementDetail(
    {}, { onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  zodiosHooks.useGetElementDetailId({ params: { id: props.elementDetailId } }, {
      onSuccess: (data) => reset(data),
      enabled: props.elementDetailId !== 0
    }
  )

  const { data: FontIcoonOptions } = zodiosHooks.useGetApiFonticoon()

  const fontIcons = FontIcoonOptions?.items?.filter(item => item.naam !== null)
    .map(item => ({ key: item.fonticoonId, text: item.omschrijving! }))

  const onCancel = () => {
    props.invalidate()
    reset({})
    props.dismissPanel()
  }

  const onSubmit = (data: CreateOrUpdateType) => {
    if (props.elementDetailId !== 0) {
      updateElementDetail(data)
    } else {
      addElementDetail(data)
    }
  }

  return (
    <Panel type={PanelType.medium}
           headerText={`Elementdetail ${props.elementDetailId !== 0 ? 'wijzigen' : 'toevoegen'}`}
           isOpen={props.isOpen}
           onDismiss={onCancel}
           closeButtonAriaLabel='Sluiten'>
      <br />
      <ErrorMessageBar error={error} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <VgtTextField
          label='Elementdetail'
          name={getPropertyName<CreateOrUpdateType>('code')}
          register={register}
          control={control}
          errors={errors}
          required
          readOnly={!isAdmin}
        />
        <VgtDropdown
          options={fontIcons}
          label='Fonticoon'
          name={getPropertyName<CreateOrUpdateType>('fonticoonId')}
          register={register}
          control={control}
          errors={errors}
          readOnly={!isAdmin}
          required
        />
        <OkCancelButtonStack
          isSubmitting={isSubmitting}
          isLoading={isAddingElementDetail || isUpdatingElementDetail}
          onOkClick={handleSubmit(onSubmit)}
          onCancelClick={onCancel}
          isWriter={isAdmin}
        />
      </form>
    </Panel>
  )
}