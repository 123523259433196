import { DetailsList, IColumn, Link, ScrollablePane, ScrollbarVisibility, SelectionMode, Sticky, StickyPositionType } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import { useDispatch, useSelector } from 'react-redux'
import { transformDateString } from '../lemdo/common/support'
import { IOnderhoudsContract } from './onderhoudsContractSlice'
import { fetchRechtspersonen, selectAll as selectSuppliers } from '../relaties/rechtspersoon/rechtspersoonSlice'
import { useEffect } from 'react'
import { PageIdDefinition, PAGES } from 'pages'
import { useNavigate } from 'react-router-dom'
import Icon from '../../../components/Icon'

export interface IListContractenProps {
  contracten: IOnderhoudsContract[]
}

const dateColumnWidth = 100

export const ListContracten: React.FC<IListContractenProps> = (props: IListContractenProps) => {
  const dispatch = useDispatch()
  const suppliers = useSelector(selectSuppliers)
  const navigate = useNavigate()

  useEffect(() => {
    if (suppliers.length === 0) dispatch(fetchRechtspersonen({ filter: '', relatieRol: 'Leverancier' }))
  }, [])

  const getRelatieHandelsNaam = (relatieId: number) => suppliers.find(i => i.id === relatieId)?.handelsnaam

  const handleItemInvoked = (id: string) => {
    navigate(PAGES[PageIdDefinition.contract.edit].route.replace(':id', id))
  }

  const columns = [
    {
      key: getPropertyName<IOnderhoudsContract>('contractNummer'),
      fieldName: getPropertyName<IOnderhoudsContract>('contractNummer'),
      name: 'Contractnummer',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IOnderhoudsContract) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.contractNummer}
        </Link>
      )      
    },
    {
      key: getPropertyName<IOnderhoudsContract>('contractOmschrijving'),
      fieldName: getPropertyName<IOnderhoudsContract>('contractOmschrijving'),
      name: 'Contractomschrijving',
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      isRowHeader: true,
    },
    {
      key: getPropertyName<IOnderhoudsContract>('contractBeheerder'),
      fieldName: getPropertyName<IOnderhoudsContract>('contractBeheerder'),
      name: 'Contractbeheerder',
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      isRowHeader: true,
    },
    {
      key: getPropertyName<IOnderhoudsContract>('leverancierId'),
      fieldName: getPropertyName<IOnderhoudsContract>('leverancierId'),
      name: 'Contractpartij',
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IOnderhoudsContract) => <label>{getRelatieHandelsNaam(item.leverancierId)}</label>,
    },
    {
      key: 'intakeDoorCorporatie',
      fieldName: getPropertyName<IOnderhoudsContract>('intakeDoorCorporatie'),
      name: 'Intake door corporatie',
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IOnderhoudsContract) => { return item.intakeDoorCorporatie ? <Icon name="Accept" variant="red" /> : <Icon name="Cancel" variant="light" />}
    },
    {
      key: getPropertyName<IOnderhoudsContract>('ingangsDatum'),
      fieldName: getPropertyName<IOnderhoudsContract>('ingangsDatum'),
      name: 'Ingangsdatum',
      minWidth: dateColumnWidth,
      maxWidth: dateColumnWidth,
      isResizable: false,
      isRowHeader: true,
      onRender: (item: IOnderhoudsContract) => <>{transformDateString(item.ingangsDatum)}</>,
    },
    {
      key: getPropertyName<IOnderhoudsContract>('eindDatum'),
      fieldName: getPropertyName<IOnderhoudsContract>('eindDatum'),
      name: 'Einddatum',
      minWidth: dateColumnWidth,
      maxWidth: dateColumnWidth,
      isResizable: false,
      isRowHeader: true,
      onRender: (item: IOnderhoudsContract) => <>{transformDateString(item.eindDatum)}</>,
    },
  ] as IColumn[]

  return (
    <div style={{ height: '78.7vh', position: 'relative' }}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DetailsList
          columns={columns}
          items={props.contracten}
          selectionMode={SelectionMode.none}
          onRenderDetailsHeader={(headerProps, defaultRender) => {
            return (
              <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true} stickyBackgroundColor="transparent">
                <div>{defaultRender && defaultRender(headerProps)}</div>
              </Sticky>
            )
          }}
        />
      </ScrollablePane>
    </div>
  )
}

export default ListContracten
