import { DefaultButton, DefaultPalette, mergeStyles } from '@fluentui/react'
import React, { FC } from 'react'
import { IconProps } from './IconList'
import ICoMoonFontIcon from '../../../components/IcoMoon'

const outer = mergeStyles({
  textAlign: 'center',
  marginBottom: 70,
  alignItems: 'center',
  height: 140,
  justifyContent: 'center',
  width: 150,
})
const inner = mergeStyles({
  border: '1',
  padding: '10px',
  height: 125,
  borderStyle: 'ridge',
  ':hover': {
    backgroundColor: DefaultPalette.themeLighterAlt,
    cursor: 'pointer',
  }
})

export const SelectedIcon: FC<{ level: string, selectedIcon: IconProps, onSelect: () => void, onAdd?: (icon: IconProps) => void }> = ({ level, selectedIcon, onSelect, onAdd }) => {
  return (
    <div onClick={() => onSelect()} className={outer}>
      <div className={inner}>
        <b style={{ display: 'block', marginBlockStart: '0.25em', marginBlockEnd: '0.5em' }}>Selectie {level}</b>
        <div>
          <ICoMoonFontIcon iconName={selectedIcon.fonticoon} iconSize={60} />
        </div>
        {selectedIcon?.name}
      </div>
      <DefaultButton style={{ marginTop: '5px' }} iconProps={{ iconName: 'Add' }} text="Toevoegen" onClick={() => {if (onAdd) onAdd(selectedIcon)}} />
    </div>
  )
}
