import useRoles from '../../../../services/UseRoles'
import React from 'react'
import { z } from 'zod'
import { GebouwdeelAddOrUpdateDto, zodiosHooks } from '../../../../api/ApiClient'
import { useForm } from 'react-hook-form'
import { PanelType } from '@fluentui/react'
import VgtTextField from '../../../../components/VgtTextField'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'
import FluentPanel from '../../../../components/FluentPanel/FluentPanel'
import { getTitleAndMessage } from '../../../../services/HandleError'
import VgtDropdown from '../../../../components/FluentDropdown'

export interface IEditPanelProps {
  gebouwDeelId: number,
  gebouwId?: number,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof GebouwdeelAddOrUpdateDto>;
export const GebouwDeelFormPanel: React.FC<IEditPanelProps> = props => {

  const { isVastgoedbeheer } = useRoles()
  const [error, setError] = React.useState<string>()

  const defaultGebouwDeel = {
    code: '',
    naam: '',
    gebouwId: props.gebouwId ?? 0,
    serviceadresVge: null,
    advertentietekst: '',
    gebouwDeelId: props.gebouwDeelId ?? 0,
    bestemmingId: 0,
    bestemmingNaam: '',
    huurcontractbepalingen: undefined,
    hoofdDocumentId: null,
    hoeveelhedenCount: undefined,
    coordinates: null
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    mode: 'all',
    defaultValues: defaultGebouwDeel
  })

  const onCancel = () => {
    props.invalidate()
    reset({})
    props.dismissPanel()
    setError(undefined)
  }

  const { mutate: addGebouwdeel, isLoading: isPostingGebouwdeel } = zodiosHooks.usePostGebouwdelen(
    {}, {
      onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: updateGebouwdeel, isLoading: isUpdatingGebouwdeel } = zodiosHooks.usePutGebouwdelenId(
    { params: { id: props.gebouwDeelId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  zodiosHooks.useGetGebouwdelenId({ params: { id: props.gebouwDeelId } }, {
      onSuccess: (data) => reset(data),
      enabled: props.gebouwDeelId !== 0
    }
  )

  const { data: gebouwenOptions } = zodiosHooks.useGetGebouwengebouwenOptions({ queries: { includeEmpty: false } }, { enabled: true })
  const { data: serviceadresOptions } = zodiosHooks.useGetGebouwdelenGebouwDeelIdserviceadresOptions({
    params: { gebouwDeelId: props.gebouwDeelId },
    queries: { includeEmpty: true }
  })

  const onSubmit = (data: CreateUpdateType) => {
    if (props.gebouwDeelId !== 0) {
      updateGebouwdeel({ ...defaultGebouwDeel, ...data })
    } else {
      addGebouwdeel({ ...defaultGebouwDeel, ...data })
    }
  }

  return (
    <FluentPanel type={PanelType.custom} customWidth='60%'
                 headerText={`Gebouwdeel ${props.gebouwDeelId !== 0 ? 'wijzigen' : 'toevoegen'}`}
                 isOpen={props.isOpen}
                 onDismiss={props.dismissPanel}
                 error={error}
                 closeButtonAriaLabel='Sluiten'>
      <VgtTextField
        label='Code'
        name={getPropertyName<CreateUpdateType>('code')}
        register={register}
        control={control}
        errors={errors}
        required
      />
      <VgtTextField
        label='Naam'
        name={getPropertyName<CreateUpdateType>('naam')}
        register={register}
        control={control}
        errors={errors}
        required
      />
      <VgtTextField
        label='Advertentietekst'
        name={getPropertyName<CreateUpdateType>('advertentietekst')}
        register={register}
        control={control}
        multiline={true}
        errors={errors}
      />
      <VgtDropdown
        label='Gebouw'
        name={getPropertyName<CreateUpdateType>('gebouwId')}
        defaultSelectedKey={props.gebouwId}
        disabled={props.gebouwId !== 0}
        register={register}
        control={control}
        errors={errors}
        required
        options={gebouwenOptions}
      />
      {props.gebouwDeelId !== 0 && serviceadresOptions && <VgtDropdown
        label='Serviceadres'
        name={getPropertyName<CreateUpdateType>('serviceadresVge')}
        register={register}
        control={control}
        errors={errors}
        options={serviceadresOptions}
      />}
      <OkCancelButtonStack
        isSubmitting={isSubmitting}
        isLoading={isPostingGebouwdeel || isUpdatingGebouwdeel}
        onOkClick={handleSubmit(onSubmit)}
        onCancelClick={onCancel}
        isWriter={isVastgoedbeheer}
      />
    </FluentPanel>
  )
}