import React from 'react'
import { IPivotStyles, Pivot, PivotItem } from '@fluentui/react'
import { Sleutels } from './sleutels/sleutels'
import './sleutelsEnMeterstanden.css'
import { Meterstanden } from './meterstanden/meterstanden'

interface IProps {
  vgeId: number;
}

export const SleutelsEnMeterstanden = ({ vgeId }: IProps) => {

  return (
    <div className='sleutels-en-meterstanden'>
      <Pivot className='sleutels-en-meterstanden__tab' styles={pivotStyles}>
        <PivotItem headerText='Sleutels' className='sleutels-en-meterstanden__pivot-item'>
          <Sleutels vgeId={vgeId} />
        </PivotItem>
        <PivotItem headerText='Meterstanden' className='sleutels-en-meterstanden__pivot-item'>
          <Meterstanden vgeId={vgeId} />
        </PivotItem>
      </Pivot>
    </div>
  )
}

const pivotStyles = (): Partial<IPivotStyles> => ({
  root: { display: 'flex', flexDirection: 'row' },
  itemContainer: { flex: '1 1 100%', display: 'flex', flexDirection: 'column' }
})
