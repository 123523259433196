import React, { useEffect, useMemo, useState } from 'react'
import { IColumn, Link } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { getPropertyName } from 'lib/interfaceUtils'
import TenantWoningverbeteringEditPanel from './editPanel'
import {
  fetchAll as fetchAllWoningverbeteringToestemming
} from '../woningverbeteringToestemming/woningverbeteringToestemmingSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'
import { createBooleanColumn, createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { TenantWoningverbeteringDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { z } from 'zod'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { usePagedParams } from '../../../../services/usePagedParams'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'

type TenantWoningverbeteringDtoType = z.infer<typeof TenantWoningverbeteringDto>;

const TenantWoningverbeteringList: React.FC = _props => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const { isReader } = useRoles()
  const [error, setError] = useState<string>()
  const [selectedTenantWoningverbeteringItems, setSelectedTenantWoningverbeteringItems] = useState<TenantWoningverbeteringDtoType | undefined>()
  const { validatedSearchParams, setFilter, setPage, setOrder } = usePagedParams()

  const {
    data: tenantWoningverbeteringItems,
    invalidate: fetchTenantWoningverbetering,
    isLoading: isFetchingTenantWoningverbeteringitems
  } = zodiosHooks.useGetTenantWoningverbetering({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { data: verbeteringTypes } = zodiosHooks.useGetWoningverbeteringwoningverbeteringTypes({})

  const refreshItems = () => fetchTenantWoningverbetering()

  const woningVerbeteringTypeName = (id: number) => {
    return verbeteringTypes?.find(x => x.woningVerbeteringTypeId === id)?.naam ?? ''
  }

  useEffect(() => {
    refreshItems()
    dispatch(fetchAllWoningverbeteringToestemming())
  }, [])

  const handleItemInvoked = (item: TenantWoningverbeteringDtoType) => {
    setSelectedTenantWoningverbeteringItems(item)
    openPanel()
  }

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' }
  ], [isReader, selectedIds.length])

  const columns: IColumn[] = [
    createCustomRenderedColumn('Woningverbetering', getPropertyName<TenantWoningverbeteringDtoType>('woningverbeteringId'),
      (x: TenantWoningverbeteringDtoType) => <Link key={x.woningverbeteringId}
                                                   onClick={() => handleItemInvoked(x)}>{x.woningverbeteringOmschrijving}</Link>, 'XXL', false),
    createColumn('Voorziening', getPropertyName<TenantWoningverbeteringDtoType>('voorziening'), 'L'),
    createBooleanColumn('Klantdialoog', getPropertyName<TenantWoningverbeteringDtoType>('actiefVoorKlantdialoog'), 'M'),
    createBooleanColumn('Overname', getPropertyName<TenantWoningverbeteringDtoType>('actiefVoorOvername'), 'M'),
    createCustomRenderedColumn('Type won.verb.', getPropertyName<TenantWoningverbeteringDtoType>('woningverbeteringType'),
      (x: TenantWoningverbeteringDtoType) =>
        <span>{woningVerbeteringTypeName(x.woningverbeteringType!)}</span>, 'M', false),
    createColumn('Toestemmingscode', getPropertyName<TenantWoningverbeteringDtoType>('woningverbeteringToestemmingCode'), 'XXL'),
    createColumn('Toelichting intern', getPropertyName<TenantWoningverbeteringDtoType>('toelichtingIntern'), 'XXL'),
    createColumn('Toelichting extern', getPropertyName<TenantWoningverbeteringDtoType>('toelichting'), 'XXL'),
    createColumn('Bedrag huuraanpassing', getPropertyName<TenantWoningverbeteringDtoType>('bedragHuurprijsaanpassing'), 'L'),
    createBooleanColumn('Vergoeding mogelijk', getPropertyName<TenantWoningverbeteringDtoType>('vergoedingMogelijk'), 'L'),
    createBooleanColumn('Geriefsverbetering mogelijk', getPropertyName<TenantWoningverbeteringDtoType>('geriefsverbeteringMogelijk'), 'L')
  ]

  return (
    <MainTemplate title='Woningverbetering' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <TenantWoningverbeteringEditPanel selectedTenantWoningverbeteringItems={selectedTenantWoningverbeteringItems}
                                        dismissPanel={dismissPanel} isOpen={isPanelOpen}
                                        fetchTenantWoningverbetering={fetchTenantWoningverbetering} />
      <VgtPagedTable
        items={tenantWoningverbeteringItems?.items ?? undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: tenantWoningverbeteringItems?.totalCount ?? 0,
          totalPages: tenantWoningverbeteringItems?.totalPages ?? 0,
          hasNextPage: tenantWoningverbeteringItems?.hasNextPage ?? false,
          hasPreviousPage: tenantWoningverbeteringItems?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        columns={columns}
        isLoading={isFetchingTenantWoningverbeteringitems}
        getKey={(item) => item?.woningverbeteringId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e)}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}
export default TenantWoningverbeteringList
