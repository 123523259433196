import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { ITextFieldStyles, Link, TextField } from '@fluentui/react'
import { SharedColors } from '@fluentui/theme'

interface ILinkReadonlyFieldProps {
  title: string
  value?: any
  url: string | null
  labelWidth?: number | string
  fieldWidth?: number | string
}

const LinkReadOnlyField: FC<ILinkReadonlyFieldProps> = (props: ILinkReadonlyFieldProps) => {
  const navigate = useNavigate()

  const labelStyles: Partial<ITextFieldStyles> = {
    wrapper: {
      borderBottom: '1px solid #efefef',
    },
    subComponentStyles: {
      label: {
        root: { width: props.labelWidth ?? 'auto', fontWeight: 'normal', cursor: 'pointer' }
      }
    },
    field: {
      fontWeight: 600,
      cursor: 'pointer',
      width: props.fieldWidth ?? 'auto',
      overflow: 'hidden'
    },
    suffix: {
      background: ''
    }
  }

  const onclick = () => {
    if (props.url) navigate(props.url)
  }

  return (
    <Link onClick={() => onclick()} disabled={!props.url} style={{ width: '100%'}}>
      <TextField
        autoComplete='off'
        styles={labelStyles}
        label={props.title}
        underlined
        readOnly
        value={props.value}
        placeholder={'---'}
        style={{ cursor: 'pointer', color: SharedColors.red10 }}
      />
    </Link>
  )
}

export default LinkReadOnlyField