import React, { useEffect, useState } from 'react'
import { Panel, PanelType } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useTypedSelector } from 'store'
import { useSelector } from 'react-redux'
import { getPropertyName } from 'lib/interfaceUtils'
import { getLoadingState } from './elementElementDetailSlice'
import VgtDropdown from 'components/FluentDropdown'
import useRoles from 'services/UseRoles'
import ElementField from 'features/beheer/lemdo/nlsfb/elementField'
import { IElementChoice } from 'features/beheer/lemdo/nlsfb/elementSuggestions'
import { IBouwkundigElement } from '../nlsfb/elementSlice'
import { fetchElementen, getElementenAsOptions } from 'features/beheer/lemdo/element/elementSlice'
import IElementElementDetail from './elementElementDetailModel'
import { z } from 'zod'
import { ElementElementDetailDto, ElementElementDetailListDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

type ElementElementDetailDtoType = z.infer<typeof ElementElementDetailDto>;
type ElementElementDetailListDtoType = z.infer<typeof ElementElementDetailListDto>;

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  selectedElementElementDetailId?: string
  selectedElementElementDetailspage: ElementElementDetailListDtoType | undefined
  fetchElementElementDetailspageItems: () => void
}

const createElementChoice = (entity: ElementElementDetailListDtoType | undefined) => {
  if (entity?.nlSfBElementId) {
    return {
      id: entity.nlSfBElementId,
      code: entity.nlSfBCode,
      description: entity.nlSfBOmschrijving
    } as IElementChoice
  }

  return undefined
}

const EditPanelElementElementDetail: React.FC<IEditPanelProps> = ({
                                                                    dismissPanel,
                                                                    selectedElementElementDetailspage,
                                                                    isOpen,
                                                                    fetchElementElementDetailspageItems
                                                                  }) => {
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState('Element || Elementdetail || Materiaalsoort || NL-SfB')
  const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
  const fetchStatus = useSelector(getLoadingState)
  const { isAdmin } = useRoles()
  const [bouwkundigElement, setBouwkundigElement] = useState<IBouwkundigElement>()
  const initialElement = createElementChoice(selectedElementElementDetailspage)
  const elementen = useTypedSelector(s => getElementenAsOptions(s))
  const isNew = (selectedElementElementDetailspage?.id ?? 0) === 0
  const [error, setError] = useState<string>()

  const onDataSubmitttedSuccesfully = () => {
    fetchElementElementDetailspageItems()
    dismissPanel()
    setError(undefined)
  }

    const { data: elementDetailsOpties } = zodiosHooks.useGetElementDetailopties({ queries: { includeEmpty: true }})

    const { data: materiaalSoortenOpties } = zodiosHooks.useGetMateriaalSoortenopties({ queries: { includeEmpty: true }})

    const {
    mutate: postElementElementDetails,
    isLoading: isPostingElementDetails
  } = zodiosHooks.usePostElementElementDetails({}, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const {
    mutate: putElementElementDetailsId,
    isLoading: isUpdatingElementDetails
  } = zodiosHooks.usePutElementElementDetailsId(
    { params: { id: selectedElementElementDetailspage?.id?.toString()! } }, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const defaultElementElementDetails = {
    id: selectedElementElementDetailspage?.id ?? 0,
    elementId: selectedElementElementDetailspage?.elementId ?? 0,
    elementDetailId: selectedElementElementDetailspage?.elementDetailId ?? null,
    element: selectedElementElementDetailspage?.element ?? '',
    elementDetail: selectedElementElementDetailspage?.elementDetail ?? '',
    ingangsDatum: new Date().toJSON(),
    eindDatum: selectedElementElementDetailspage?.eindDatum ?? '',
    materiaalSoortId: selectedElementElementDetailspage?.materiaalSoortId ?? null,
    materiaalSoortNaam: selectedElementElementDetailspage?.materiaalSoortNaam ?? '',
    nlSfBElementId: selectedElementElementDetailspage?.nlSfBElementId ?? null,
    nlSfBCode: selectedElementElementDetailspage?.nlSfBCode ?? '',
    nlSfBOmschrijving: selectedElementElementDetailspage?.nlSfBOmschrijving ?? ''
  }

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<ElementElementDetailDtoType>({ mode: 'all', defaultValues: { materiaalSoortId: 0 } })

  useEffect(() => {
    dispatch(fetchElementen())
    setCloseOnFulFilled(false)
  }, [])

  useEffect(() => {
    setTitle(`Element || Elementdetail || Materiaalsoort || NL-SfB ${selectedElementElementDetailspage ? (isAdmin ? 'wijzigen' : '') : 'toevoegen'}`)
    if (selectedElementElementDetailspage) {
      reset(selectedElementElementDetailspage)
    } else {
      reset({})
    }
  }, [selectedElementElementDetailspage])

  const onCancel = () => {
    dismissPanel()
    setError(undefined)
  }

  const onSubmit = (data: ElementElementDetailDtoType) => {
    data.nlSfBElementId = bouwkundigElement?.id!

    if (!data.nlSfBElementId) {
      data.nlSfBElementId = null
    }
    if (!data.elementDetailId) {
      data.elementDetailId = null
    }
    if (!data.materiaalSoortId) {
      data.materiaalSoortId = null
    }

    if (selectedElementElementDetailspage) {
      data.id = selectedElementElementDetailspage.id
      putElementElementDetailsId({ ...defaultElementElementDetails, ...data })
    } else {
      postElementElementDetails({ ...defaultElementElementDetails, ...data })
    }
  }

  useEffect(() => {
    if (closeOnFulFilled && fetchStatus === 'succeeded') {
      dismissPanel()
      setBouwkundigElement(undefined)
    }
  }, [fetchStatus])

  const onNlSfBElementChanged = (element: IElementChoice | undefined) => {
    if (element) setBouwkundigElement({
      id: element.id,
      code: element.code,
      omschrijving: element.description
    } as IBouwkundigElement)
    else setBouwkundigElement(undefined)
  }

  return (
    <Panel type={PanelType.medium} headerText={title} isOpen={isOpen} onDismiss={onCancel}
           closeButtonAriaLabel='Sluiten'>
      <ErrorMessageBar error={error} />
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <VgtDropdown
          label='Element'
          name={getPropertyName<IElementElementDetail>('elementId')}
          register={register}
          control={control}
          errors={errors}
          options={elementen}
          disabled={!isNew}
        />
        <VgtDropdown
          label='Elementdetail'
          name={getPropertyName<IElementElementDetail>('elementDetailId')}
          register={register}
          control={control}
          errors={errors}
          options={elementDetailsOpties}
          disabled={!isNew}
        />
        <VgtDropdown
          options={materiaalSoortenOpties}
          label='Materiaalsoort'
          name={getPropertyName<IElementElementDetail>('materiaalSoortId')}
          register={register}
          control={control}
          errors={errors}
        />
        <ElementField
          labelCode='NL-SfB code'
          labelDescription='NL-SfB omschrijving'
          toolTip='Kies NL-SfB element'
          onChanged={onNlSfBElementChanged}
          searchBoxWidth={170}
          dropDownWidth={600}
          dropDownHeight={500}
          initialElement={initialElement}
        />
        <OkCancelButtonStack
          isSubmitting={isSubmitting}
          isLoading={isPostingElementDetails || isUpdatingElementDetails}
          onOkClick={handleSubmit(onSubmit)}
          onCancelClick={onCancel}
          isWriter={isAdmin}
        />
      </form>
    </Panel>
  )
}

export default EditPanelElementElementDetail
