import React from 'react'
import ICoMoonFontIcon from '../IcoMoon'

type CompassDirections =
  | 'Onbekend'
  | 'Noord'
  | 'Noordoost'
  | 'Oost'
  | 'Zuidoost'
  | 'Zuid'
  | 'Zuidwest'
  | 'West'
  | 'Noordwest';

interface CompassDirectionProps {
  direction: CompassDirections;
  size?: number;
}

const compassDirectionIcons: { [key in CompassDirections]: string } = {
  Onbekend: '',
  Noord: 'icon-Noord-North-N',
  Noordoost: 'icon-Noordoost-Northeast-NO',
  Oost: 'icon-Oost-East-O',
  Zuidoost: 'icon-Zuidoost-Southeast-ZO',
  Zuid: 'icon-Zuid-South-Z',
  Zuidwest: 'icon-Zuidwest-Southwest-ZW',
  West: 'icon-West-West-W',
  Noordwest: 'icon-Noordwest-Northwest-NW'
}

const CompassDirection: React.FC<CompassDirectionProps> = ({ direction, size = 45 }) => {
  return <ICoMoonFontIcon iconName={compassDirectionIcons[direction]} iconSize={size} />
}

export default CompassDirection
