import { EntityId, EntityState } from '@reduxjs/toolkit'

export type loadingStatus = 'idle' | 'pending' | 'succeeded' | 'failed'

export interface EntityObjectState<TEntity> extends EntityState<TEntity> {
  selectedId: EntityId | undefined
  status: loadingStatus
  error: string | null
  searchFilter?: string
}

export interface ISearchState {
  searchFilter: string
  suggestionFilter: string
  status: loadingStatus
  error: string | null
}

export interface IEntityPageObjectResponse<TEntity> {
  hasNextPage: boolean
  hasPreviousPage: boolean
  items: Array<TEntity>
  pageIndex: number
  totalCount: number
  totalPages: number
}

export function createInitialState<TEntity>() {
  return {
    entities: {},
    error: null,
    ids: [],
    selectedId: undefined,
    status: 'idle',
  } as EntityObjectState<TEntity>
}

export const setPendingState = <TEntity>(state: EntityObjectState<TEntity>) => {
    state.error = null
    state.status = 'pending'
}
export const setSucceededState = <TEntity>(state: EntityObjectState<TEntity>) => {
    state.error = null
    state.status = 'succeeded'
}
export const setRejectedState = <TEntity>(state: EntityObjectState<TEntity>, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
}
