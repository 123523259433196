import useApi from 'services/UseApi'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { IColumn, IObjectWithKey, ISelection, Selection, SelectionMode } from '@fluentui/react'
import React from 'react'
import { createColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import { useNavigate } from 'react-router-dom'
import { AvailableRoutes } from 'AppRouter'
import { IDimensieList } from './DimensieApi'
import FluentTable from '../../../../components/FluentTable'

const DimensiesList = () => {
  const navigate = useNavigate()
  const { state, execute: fetchDimensies } = useApi<IDimensieList[]>('dimensies', 'GET')
  const { state: deleteState, execute: deleteDimensies } = useApi<IDimensieList[]>('dimensies', 'DELETE', false)

  const [selection] = React.useState<ISelection>(() => {
    return new Selection<IObjectWithKey>({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selection,
    })
  })

  const navigateToEdit = (id: string) => {
    navigate(AvailableRoutes.Dimensies_Edit.replace(":id", id))
  }

  const deleteSelected = () => {
    const selected = selection.getSelection()
    if (selected.length > 0) {
      const ids = selected.map((item) => (item as IDimensieList).id)
      deleteDimensies({itemsToDelete: ids}, fetchDimensies)
    }
  }

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Toevoegen', onClick: () => navigate(AvailableRoutes.Dimensies_New), icon: 'Add', iconOnly: true },
    { text: 'Verwijderen', onClick: () => deleteSelected(), icon: 'Delete', iconOnly: true}
  ]

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Code', getPropertyName<IDimensieList>('code'), 'S', true),
      createColumn('Omschrijving', getPropertyName<IDimensieList>('omschrijving'), 'XL'),
      createColumn('VGT dimensie', getPropertyName<IDimensieList>('vgtDimensie'), 'L')
    ]
  }, [])

  return (
  <MainTemplate title='Dimensies' subTitle='overzicht' commandItems={commandItems} error={state.error || deleteState.error}>
    <div style={{ display: 'flex', height: '80vh' }}>
      <FluentTable columns={columns} items={state.data?.items ?? []} onItemInvoked={(item) => navigateToEdit(item)}
                   onGetKey={(i) => i.id} />
    </div>
    </MainTemplate>
  )
}

export default DimensiesList