import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import { IVeraEntity } from '../common/lemdoEntity'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'
import { IDropdownOption } from '@fluentui/react'

export interface IDefectLocatie extends IVeraEntity {
  code: string
  naam: string
  iconUrl?: string
  fonticoonId?: number
  fonticoonNaam?: string
}

const entityAdapter = createEntityAdapter<IDefectLocatie>({
  sortComparer: (a, b) => a.code?.localeCompare(b.code)
})

const baseUrl = '/defectLocatie'
const basePrefix = 'defectLocatie'

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
  return await invokeFetch<PagedEntities<IDefectLocatie>>(thunkAPI, 'GET', `${baseUrl}`)
})

const setPendingState = (state: EntityState<IDefectLocatie> & IEntityAdapterState) => {
  state.error = null
  state.status = 'pending'
}
const setSucceededState = (state: EntityState<IDefectLocatie> & IEntityAdapterState) => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<IDefectLocatie> & IEntityAdapterState, action) => {
  state.status = 'failed'
  state.error = action.error.message || null
}

export const defectLocatieSlice = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
    clearError: state => {
      state.error = null
    },
    select: (state, action: PayloadAction<string | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll
  },

  extraReducers: builder => {
    builder.addCase(fetchAll.pending, state => setPendingState(state))
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IDefectLocatie>>) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
  }
})

export const {
  selectAll,
  selectEntities,
  selectById
} = entityAdapter.getSelectors<RootState>(state => state.defectLocaties)

export const getDropDownValuesForDefectlocaties = (state: RootState) => {
  const options: IDropdownOption[] = []
  const defectlocaties = selectAll(state)
  defectlocaties.forEach(i => {
    options.push({
      key: i.id,
      text: i.code + ' - ' + i.naam
    })
  })
  return options
}
export default defectLocatieSlice.reducer
