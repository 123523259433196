import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IConfiguration {
  apiEndpoint: string
  msalClientId: string
  msalRedirectUri: string
  postLogoutRedirectUri: string
  apiScopes: string[]
  graphScopes: string[]
}

export interface IConfigurationState extends IConfiguration {
  initialized: boolean
}

const initialState: IConfigurationState = {
  apiEndpoint: '',
  msalClientId: '',
  msalRedirectUri: '',
  postLogoutRedirectUri: '',
  apiScopes: [],
  graphScopes: [],
  initialized: false,
}

export const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    initializeApp: (_, action: PayloadAction<IConfiguration>) => {
      return {
        ...action.payload,
        initialized: true,
      }
    },
  },
})

export const { initializeApp } = slice.actions

export default slice.reducer
