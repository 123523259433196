import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { IImportTaak } from './model'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { transformDateString } from '../../lemdo/common/support'

interface IImportTaakObjectState extends EntityState<IImportTaak> {
  selectedId: string | undefined
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
}

interface IImportTaakObjectResponse {
  items: Array<IImportTaak>
}

export const fetchAll = createAsyncThunk('imports/importTaken', async (_, thunkAPI) => {
  return await invokeFetch<IImportTaakObjectResponse>(thunkAPI, 'GET', '/api/eponline/imports?skip=0&top=50000')
})

const entityAdapter = createEntityAdapter<IImportTaak>({
  selectId: e => e.id?.toString()
})

const transformResult = (taak: IImportTaak) => {
  return {
    ...taak,
    laatstUitgevoerd: transformDateString(taak.laatstUitgevoerd)
  }
}

const initState: IImportTaakObjectState = {
  selectedId: undefined,
  entities: {},
  error: null,
  ids: [],
  status: 'idle'
}

export const importTakenSlice = createSlice({
  name: 'importTaken',

  initialState: entityAdapter.getInitialState(initState),

  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchAll.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<IImportTaakObjectResponse>) => {
      state.status = 'succeeded'
      entityAdapter.setAll(state, action.payload.items.map(i => transformResult(i)))
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || null
    })
  }
})

export const { selectAll } = entityAdapter.getSelectors<RootState>(state => state.importtaken)

export default importTakenSlice.reducer