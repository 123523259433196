import React, { useEffect, useState } from 'react'
import {
  Text,
  getFocusStyle,
  getTheme,
  ITheme,
  List,
  mergeStyleSets,
  Panel,
  PanelType,
  SearchBox,
  Stack,
  Sticky,
  StickyPositionType,
  StackItem,
  IStackStyles,
  Image,
} from '@fluentui/react'
import { fetchByQuery, IProduct2BA, selectAll, reset as resetProducts } from './product2BASlice'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

interface ISearch2BAProps {
  query: string
  isOpen: boolean
  dismissPanel: () => void
  onSelected: (product: IProduct2BA) => void
}

export interface IThumbnailProductProps {
  gln: string
  productCode: string
  width?: string | number
}

export const createThumbnailUrl = (gln: string | undefined, productCode: string | undefined) => {
  if (gln && productCode) return `https://api.2ba.nl/1/json/Thumbnail/Product?gln=${gln}&productcode=${productCode}`
  return undefined
}

const ThumbnailProduct: React.FC<IThumbnailProductProps> = props => {
  const url = createThumbnailUrl(props.gln, props.productCode)
  return <Image src={url} width={props.width} />
}

const stackStyles: IStackStyles = {
  root: {
    width: '100%',
  },
}

const theme: ITheme = getTheme()
const { palette, semanticColors, fonts } = theme

const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 54,
      padding: 10,
      boxSizing: 'border-box',
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: 'flex',
      selectors: {
        '&:hover': { background: palette.neutralLight },
      },
      width: '100%',
      cursor: 'pointer',
    },
  ],
  itemSelected: {
    border: '1 solid black',
    background: '#ebafaf',
  },
  itemImage: {
    flexShrink: 0,
  },
  itemContent: {
    marginLeft: 10,
    overflow: 'hidden',
    flexGrow: 1,
  },
  itemName: [
    fonts.xLarge,
    {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  ],
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  chevron: {
    alignSelf: 'center',
    marginLeft: 10,
    color: palette.neutralTertiary,
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
})

const EditPanelSearch2BA: React.FC<ISearch2BAProps> = props => {
  const products = useSelector(selectAll)
  const [query, setQuery] = useState<string>('')
  const dispatch = useDispatch()

  const delayedSearch = debounce(eventData => dispatch(fetchByQuery(eventData)), 500)

  useEffect(() => {
    setQuery(props.query)
  }, [props.isOpen])

  useEffect(() => {
    if (query !== '' && props.isOpen) delayedSearch(query)
  }, [props.isOpen, query])

  const onSearchBoxChanged = (newValue?: string) => {
    setQuery(newValue ?? '')
    if (newValue && newValue.length >= 2) delayedSearch(newValue)
  }

  const onClearSearchBox = () => {
    setQuery('')
  }

  const reset = () => {
    setQuery('')
    dispatch(resetProducts())
  }

  const onSelected = (product: IProduct2BA) => {
    reset()
    props.onSelected(product)
  }

  const onDismiss = () => {
    reset()
    props.dismissPanel()
  }

  const onRenderCell = (item: IProduct2BA | undefined) => {
    return (
      item && (
        <div className={[classNames.itemCell, item?.selected ? classNames.itemSelected : ''].join(' ')} data-is-focusable={true}>
          <Stack
            id={item.id.toString()}
            horizontal
            verticalAlign={'center'}
            tokens={{ padding: '40', childrenGap: '30 30' }}
            onClick={() => onSelected(item)}
            styles={stackStyles}
          >
            <StackItem>
              <ThumbnailProduct gln={item.manufacturerGLN} productCode={item.productCode} width={100} />
            </StackItem>
            <StackItem>
              <Stack>
                <StackItem>
                  <Text variant="medium">
                    <b>
                      {item.manufacture} {item.model ? ' ' + item.model : ''} {item.version ? ' ' + item.version : ''}
                    </b>
                  </Text>
                </StackItem>
                <StackItem>{item.description}</StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </div>
      )
    )
  }

  return (
    <Panel type={PanelType.medium} headerText="Zoek product" isOpen={props.isOpen} onDismiss={onDismiss} closeButtonAriaLabel="Sluiten">
      <Sticky stickyPosition={StickyPositionType.Header}>
        <SearchBox placeholder="Zoeken" onChanged={onSearchBoxChanged} onClear={onClearSearchBox} value={query} autoComplete="off" />
      </Sticky>
      {query !== '' && <List items={products} onRenderCell={onRenderCell} style={{ border: '1px solid rgb(96, 94, 92)' }} />}
    </Panel>
  )
}

export default EditPanelSearch2BA
