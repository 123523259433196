import { IDropdownOption } from '@fluentui/react';
import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction, EntityId } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import * as yup from 'yup'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'
import { IEntityCoenCadCodes } from 'features/beheer/coencad/coenCadCode';

export interface IKeuken extends IEntityCoenCadCodes {
    id: number
    code: string
    voorziening: string
    eenheidId: number
    tooltip: string
    plusOne: boolean
    ingangsDatum: string
    eindDatum?: string
    elementElementDetailId?: number
}

export const keukenSchema = yup.object().shape({
    code: yup.string().required('Code is verplicht').max(10, 'Maximale lengte is 10'),
    voorziening: yup.string().required('Voorziening is verplicht').max(100, 'Maximale lengte is 100'),
    eenheidId: yup.number().required('Eenheid is verplicht'),
    tooltip: yup.string().nullable().max(200, 'Maximale lengte is 200')
})

const entityAdapter = createEntityAdapter<IKeuken>({
    sortComparer: (a, b) => a.code?.localeCompare(b.code),
})

const baseUrl = '/keuken'
const basePrefix = 'keuken'
const getSliceState = (state: RootState) => state.keukens


export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<PagedEntities<IKeuken>>(thunkAPI, 'GET', `${baseUrl}?top=50000`)
})

export const updateKeukenDetail = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: IKeuken, thunkAPI) => {
    return await invokeFetch<IKeuken>(thunkAPI, 'PUT', `${baseUrl}/${entity.id}`, entity)
})

export const addKeukenDetail = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: IKeuken, thunkAPI) => {
    return await invokeFetch<IKeuken>(thunkAPI, 'POST', baseUrl, entity)
})

export const deleteKeukenDetail = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
})

const setPendingState = (state: EntityState<IKeuken> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
}
const setSucceededState = (state: EntityState<IKeuken> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<IKeuken> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
}

export const keukenSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),

    reducers: {
        clearError: state => {
            state.error = null
        },
        select: (state, action: PayloadAction<string | undefined>) => {
            state.selectedId = action.payload
        },
        clearSelection: state => {
            state.selectedId = undefined
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
    },

    extraReducers: builder => {
        builder.addCase(fetchAll.pending, state => setPendingState(state))
        builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IKeuken>>) => {
            setSucceededState(state)
            entityAdapter.setAll(state, action.payload.items)
        })
        builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
        builder.addCase(updateKeukenDetail.pending, state => setPendingState(state))
        builder.addCase(updateKeukenDetail.fulfilled, (state, action: PayloadAction<IKeuken>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(updateKeukenDetail.rejected, (state, action) => setRejectedState(state, action))
        builder.addCase(addKeukenDetail.pending, state => setPendingState(state))
        builder.addCase(addKeukenDetail.fulfilled, (state, action: PayloadAction<IKeuken>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(addKeukenDetail.rejected, (state, action) => {
            setRejectedState(state, action)
        })
        builder.addCase(deleteKeukenDetail.pending, (state) => setPendingState(state))
        builder.addCase(deleteKeukenDetail.fulfilled, (state, action) => {
            entityAdapter.removeMany(state, action.meta.arg)
            setSucceededState(state)
        })
        builder.addCase(deleteKeukenDetail.rejected, (state, action) => setRejectedState(state, action))
    },
})

export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
}

export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
}

export const getErrorState = (state: RootState) => {
    return getSliceState(state).error
}

export const { add, modify, removeMany, select, clearSelection } = keukenSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.keukens)

export const getDropDownValues = (state: RootState) => state.keukens.ids.map((id: EntityId) => {
    const entity = state.keukens.entities[id]
    return {
        key: id,
        text: `${entity?.code}`
    } as IDropdownOption
})

export default keukenSlice.reducer
