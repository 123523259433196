import React, { useEffect, useState } from 'react'
import { useBoolean } from '@fluentui/react-hooks'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dropdown,
  FontIcon,
  getFocusStyle,
  getTheme,
  Icon,
  IDropdownOption,
  ISearchBox,
  IStackStyles,
  ITheme,
  List,
  mergeStyles,
  mergeStyleSets,
  PrimaryButton,
  SearchBox,
  Stack,
  StackItem,
  TeachingBubble,
  Text
} from '@fluentui/react'

import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import { fetchFysiekQuery, getFilterState, getFysiekQueryState } from './fysiekSlice'
import { ISuggestion, selectSuggestions, setSuggestionFilter } from 'features/vge/vgeSlice'
import VGEtable from './vgeTable'
import GebouwenClustersTable from './gebouwenClustersTable'
import GebouwenTable from './gebouwenTable'
import PercelenTable from './percelenTable'
import TitleTemplate from 'containers/pages/PageTemplates/TitleTemplate'
import GebouwdelenTable from './gebouwdelenTable'
import { zodiosHooks } from '../../api/ApiClient'
import { usePagedParams } from '../../services/usePagedParams'
import { VgtSearch } from '../../components/VgeSearch/VgtSearch'

const keyVastgoedeenheid = 'Vastgoedeenheid'

const iconClass = mergeStyles({
  fontSize: 24,
  height: 24,
  width: 24,
  marginTop: 4
})

const SearchForm: React.FC<{}> = () => {
  const searchRef = React.createRef<ISearchBox>()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const suggestions = useSelector(selectSuggestions)
  const filterState = useSelector(getFilterState)
  const queryState = useSelector(getFysiekQueryState)

  const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false)
  const [currentQuery, setCurrentQuery] = useState<IDropdownOption | undefined>()
  const [currentFilter, setCurrentFilter] = useState<string | undefined>('')
  const [currentSearchValue, setCurrentSearchValue] = useState<string | undefined>('')
  const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState(-1)

  const { data } = zodiosHooks.useGetFysiek()

  const menuProps: IDropdownOption[] = data
    ?.filter(item => item.title !== null)
    ?.map(value => {
      return { text: value.title ?? '', key: value.url! }
    }) || []

  const specialKeys = ['Gebouwen', 'GebouwenCluster', 'Gebouwdelen']

  const onFysiekOptionSelected = (_event, item: IDropdownOption | undefined) => {

    if (item && specialKeys.includes(item.key.toString())) {
      setCurrentQuery(item)
      navigate('/search')
      return
    } else if (item) {
      setCurrentQuery(item)
      fetchData(item.key.toString(), currentFilter)
    }
  }

  const fetchData = (query: string | undefined, filter: string | undefined) => {
    if (!query) return

    dispatch(fetchFysiekQuery({ query: query, filter: filter ?? '' }))
  }

  const delayedSearch = debounce(eventData => dispatch(setSuggestionFilter(eventData)), 500)

  const onSearchBoxChanged = (newValue: string | undefined) => {
    setCurrentFilter(newValue)
    setCurrentSearchValue(newValue)
    if (newValue && newValue.length >= 2) {
      delayedSearch(newValue)
    } else {
      dispatch(setSuggestionFilter(''))
    }
  }

  const onClearSearchBox = () => {
    setCurrentSearchValue('')
    setFocusedSuggestionIndex(-1)
    dispatch(setSuggestionFilter(''))
  }

  const toSearchWithType = (suggestion: ISuggestion) => {
    navigate(`/vge/searchresults/${encodeURI(suggestion.itemName)}/${encodeURI(suggestion.itemType.toLowerCase())}${suggestion.locatedIn ? '/' : ''}${encodeURI(suggestion.locatedIn ?? '')}`)
  }

  const onSearchClicked = (newValue: string | undefined) => {
    if (currentQuery?.key !== keyVastgoedeenheid) {
      dispatch(setSuggestionFilter(''))
      fetchData(currentQuery?.key.toString(), newValue)
    } else {
      if (currentSearchValue)
        navigate(`/vge/searchresults/${encodeURI(currentSearchValue)}`)
    }
  }

  const onItemClicked = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const suggestion: ISuggestion | undefined = suggestions.find(s => s.id === event.currentTarget.id)
    if (suggestion) {
      toSearchWithType(suggestion)
    }
    dispatch(setSuggestionFilter(''))
  }

  useEffect(() => {
    if (!currentQuery && menuProps.length !== 0) {
      const defaultOption = menuProps.find(option => option.key === keyVastgoedeenheid)
      setCurrentQuery(defaultOption)
    }
  }, [menuProps])

  useEffect(() => {
    setCurrentFilter(filterState)
    const item = menuProps.filter(option => option.key === queryState)
    if (item.length > 0) {
      setCurrentQuery(item[0])
    }
  }, [filterState, queryState])


  const theme: ITheme = getTheme()
  const { palette, semanticColors, fonts } = theme
  const classNames = mergeStyleSets({
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: 'border-box',
        borderBottom: `1px solid ${semanticColors.bodyDivider}`,
        display: 'flex',
        selectors: {
          '&:hover': { background: palette.neutralLight }
        },
        width: '100%',
        cursor: 'pointer'
      }
    ],
    itemSelected: {
      border: '1 solid black',
      background: '#ebafaf'
    },
    itemImage: {
      flexShrink: 0
    },
    itemContent: {
      marginLeft: 10,
      overflow: 'hidden',
      flexGrow: 1
    },
    itemName: [
      fonts.xLarge,
      {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    ],
    itemIndex: {
      fontSize: fonts.small.fontSize,
      color: palette.neutralTertiary,
      marginBottom: 10
    },
    chevron: {
      alignSelf: 'center',
      marginLeft: 10,
      color: palette.neutralTertiary,
      fontSize: fonts.large.fontSize,
      flexShrink: 0
    }
  })

  const onKeydown = (event: React.KeyboardEvent) => {
    if (suggestions) {
      switch (event.key) {
        case 'ArrowUp':
          if (focusedSuggestionIndex >= 1) {
            setFocusedSuggestionIndex(focusedSuggestionIndex - 1)
          }
          event.preventDefault()
          break
        case 'ArrowDown':
          if (focusedSuggestionIndex < suggestions.length - 1) {
            setFocusedSuggestionIndex(focusedSuggestionIndex + 1)
          }
          event.preventDefault()
          break
        case 'Enter':
          if (focusedSuggestionIndex >= 0) {
            event.preventDefault()
            toSearchWithType(suggestions[focusedSuggestionIndex])
            dispatch(setSuggestionFilter(''))
          }
          break
      }
    }
  }

  const stackStyles: IStackStyles = {
    root: {
      width: '100%'
    }
  }

  useEffect(() => {
    if (currentQuery) {
      sessionStorage.setItem('currentQuery', JSON.stringify(currentQuery))
    }
  }, [currentQuery])

  useEffect(() => {
    const savedQuery = sessionStorage.getItem('currentQuery')

    if (savedQuery) {
      setCurrentQuery(JSON.parse(savedQuery))
    }
  }, [])

  const onRenderCell = (item: ISuggestion | undefined) => {
    return (
      <div className={[classNames.itemCell, item?.selected ? classNames.itemSelected : ''].join(' ')}
           data-is-focusable={true}>
        <Stack id={item?.id} horizontal verticalAlign={'center'} tokens={{ padding: '40', childrenGap: '30 30' }}
               onClick={onItemClicked} styles={stackStyles}>
          <StackItem>
            <Icon iconName={'Globe'}></Icon>
          </StackItem>
          <StackItem grow={2}>
            <Stack>
              <Text variant='medium'>
                <b>{item?.itemName}</b>
                {item?.locatedIn ? ', ' : ''} {item?.locatedIn}
              </Text>
            </Stack>
            <Stack>
              <Text>{item?.itemType}</Text>
            </Stack>
          </StackItem>
          <StackItem></StackItem>
          {item?.amount}
        </Stack>
      </div>
    )
  }

  const { validatedSearchParams, setFilter } = usePagedParams()

  return (
    <div>
      <TitleTemplate title='Vastgoedeenheden' subTitle='Zoekformulier' />
      <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'start'} style={{ margin: 20, marginTop: 20 }}>
        {teachingBubbleVisible && (
          <TeachingBubble target='#targetIcon' onDismiss={toggleTeachingBubbleVisible} headline='Zoeken'>
            <Text variant={'medium'}>Hier kunt u een zoekopdracht ingeven. U kunt zoeken op:</Text>
            <ul>
              <li>(een deel van het) adres</li>
              <li>postcode + huisnummer</li>
              <li>woonplaats</li>
              <li>BAG ID (elk objecttype mogelijk)</li>
              <li>wijk</li>
              <li>buurt</li>
            </ul>
            Als u op zoeken klikt toont vastgoedtabel.nl bij één zoekresultaat direct de informatie van het gevonden
            adresseerbare object. Bij meerdere
            zoekresultaten kunt u in het zoekresultaatvenster het adresseerbare object aanklikken waarvan u de gegevens
            wilt raadplegen.
          </TeachingBubble>
        )}{' '}
        <StackItem>
          <Dropdown
            selectedKey={currentQuery?.key}
            onChange={onFysiekOptionSelected}
            options={menuProps}
            styles={{ dropdown: { width: 270 } }}
          />
        </StackItem>
        <StackItem>
          <FontIcon iconName='Info' className={iconClass} id={'targetIcon'} onClick={toggleTeachingBubbleVisible} />
        </StackItem>
        {!specialKeys.includes(currentQuery?.key?.toString()!) && <>
          <StackItem grow={2}>
            <SearchBox
              placeholder='Typ hier om te zoeken'
              onSearch={onSearchClicked}
              onKeyDownCapture={onKeydown}
              componentRef={searchRef}
              onChanged={onSearchBoxChanged}
              onClear={onClearSearchBox}
              value={currentSearchValue}
              autoComplete='off'
            />
            {currentQuery?.key === keyVastgoedeenheid ?
              suggestions.length > 0 && <List items={suggestions} onRenderCell={onRenderCell}
                                              style={{ border: '1px solid rgb(96, 94, 92)' }}></List> : null}
          </StackItem>
          <StackItem>
            <PrimaryButton text='Zoek' onClick={() => onSearchClicked(currentSearchValue)} />
          </StackItem>
        </>}
        {specialKeys.includes(currentQuery?.key?.toString()!) &&
          <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter}
                     customSearchBoxStyles={{ root: { width: 800, margin: 0 } }} />}
      </Stack>

      {
        (() => {
          switch (currentQuery?.key) {
            case 'Monumenten':
              return <VGEtable />
            case 'Gebouwen':
              return <GebouwenTable filter={validatedSearchParams.filter} />
            case 'GebouwenCluster':
              return <GebouwenClustersTable filter={validatedSearchParams.filter} />
            case 'Koopgarant':
              return <VGEtable />
            case 'PercelenZonderAdresseerbaarObject':
              return <PercelenTable />
            case 'Gebouwdelen':
              return <GebouwdelenTable filter={validatedSearchParams.filter} />
            default:
              return null
          }
        })()
      }
    </div>
  )
}

export default SearchForm
