import { store } from 'store'
import { getApiToken } from 'lib/auth'

const downloadFile = (uri: string, filename: string) => {
  const { apiEndpoint } = store.getState().app.configuration

  const endpoint = `${apiEndpoint}${uri}`

  getApiToken().then(response => {
    fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${response}`,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      })
    })
  })
}

export default downloadFile
