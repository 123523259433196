export const PrimaryTheme = {
  palette: {
    themePrimary: '#bd1d1d',
    themeLighterAlt: '#fcf4f4',
    themeLighter: '#f4d3d3',
    themeLight: '#ebafaf',
    themeTertiary: '#d76969',
    themeSecondary: '#c53232',
    themeDarkAlt: '#aa1919',
    themeDark: '#8f1616',
    themeDarker: '#6a1010',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
}