import { Link } from '@fluentui/react'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GebouwenListDto, GerelateerdGebouwDeelListDto, zodiosHooks } from '../../../../api/ApiClient'
import { usePagedParams } from '../../../../services/usePagedParams'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'
import { VgtTreeViewTable } from '../../../../components/VgtTreeView'
import { z } from 'zod'
import { createCustomRenderedColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { availableUrls } from '../../../../routing/AvailableUrls'

type GebouwenListDtoType = z.infer<typeof GebouwenListDto>;
type GerelateerdGebouwDeelListDtoType = z.infer<typeof GerelateerdGebouwDeelListDto>;

const Gebouwen: React.FC<{}> = () => {
  const navigate = useNavigate()
  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const { validatedSearchParams, setFilter, setPage, setOrder } = usePagedParams()
  const [error, setError] = useState<string>()

  const {
    data: gebouwenItems,
    invalidate: refreshGebouwenItems,
    isLoading: isFetchingGebouwenItems
  } = zodiosHooks.useGetGebouwendetails({
    queries: {
      Filter: validatedSearchParams.filter,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize
    }
  })

  const getGebouwDeelData = (item: GebouwenListDtoType) => item.gebouwDelen ?? []

  const handleItemInvoked = (id: string) => {
    let url: string
    if (id) url = availableUrls.GebouwEdit.replace(':id', id)
    else url = availableUrls.GebouwEdit
    navigate(url)
  }

  const { mutate: deleteGebouw } = zodiosHooks.useDeleteGebouwen({}, {
    onSuccess: () => refreshGebouwenItems(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const removeItems = () => {
    deleteGebouw({ itemsToDelete: selectedItems })
    setSelectedItems([])
  }

  const onGebouwdeelClick = (id: string) => {
    navigate(availableUrls.Gebouwdeel.replace(':id', id))
  }

  const columns = [
    createCustomRenderedColumn(
      'Gebouwcode',
      getPropertyName<GebouwenListDtoType>('code'),
      (item: GebouwenListDtoType) => (
        <Link key={item.gebouwId} onClick={() => handleItemInvoked(item.gebouwId.toString())}>
          {item.code}
        </Link>
      ),
      'L',
      false
    ),
    createCustomRenderedColumn('Gebouwnaam', getPropertyName<GebouwenListDtoType>('naam')
    )
  ]

  const gebouwDeelColumns = [
    createCustomRenderedColumn(
      'Gebouwdeelcode',
      getPropertyName<GerelateerdGebouwDeelListDtoType>('code'),
      (item: GerelateerdGebouwDeelListDtoType) => (
        <Link key={item.gebouwDeelId} onClick={() => onGebouwdeelClick(item.gebouwDeelId.toString())}>
          {item.code}
        </Link>
      ),
      'L',
      false
    ),
    createCustomRenderedColumn(
      'Gebouwdeelnaam',
      getPropertyName<GerelateerdGebouwDeelListDtoType>('naam')
    )
  ]

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshGebouwenItems(), icon: 'Refresh' },
    {
      text: 'Toevoegen', onClick: () => {
        handleItemInvoked('0')
      }, icon: 'Add'
    },
    { text: 'Verwijderen', onClick: () => removeItems(), icon: 'Delete', disabled: !selectedItems.length }
  ]

  return (
    <MainTemplate title='Gebouwen' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <VgtTreeViewTable
        items={gebouwenItems?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: gebouwenItems?.totalCount ?? 0,
          totalPages: gebouwenItems?.totalPages ?? 0,
          hasNextPage: gebouwenItems?.hasNextPage || false,
          hasPreviousPage: gebouwenItems?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        columns={columns}
        isLoading={isFetchingGebouwenItems}
        getKey={(item) => item?.gebouwId}
        onItemInvoked={(item) => handleItemInvoked(item?.gebouwId.toString())}
        onSelectionChanged={setSelectedItems}
        onPageChanged={setPage}
        onSortChanged={setOrder}
        firstChildColumns={gebouwDeelColumns}
        firstChildData={getGebouwDeelData}
      />
    </MainTemplate>
  )
}

export default Gebouwen