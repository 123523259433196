import { useNavigate } from 'react-router-dom'
import { CommandBar, IColumn, ICommandBarItemProps, Link, Stack, StackItem } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect, useState } from 'react'
import commandBarStyles from 'styles/commandBarStyles'
import { transformDateString } from '../lemdo/common/support'
import { PageIdDefinition, PAGES } from 'pages'
import TitleTemplate from 'containers/pages/PageTemplates/TitleTemplate'
import { usePagedParams } from '../../../services/usePagedParams'
import { OnderhoudscontractDto, zodiosHooks } from '../../../api/ApiClient'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { z } from 'zod'
import { VgtSearch } from '../../../components/VgeSearch/VgtSearch'
import DeleteConfirmationDialog from "../../../components/DeleteConfirmationDialog";
import { getTitleAndMessage } from "../../../services/HandleError";
import ErrorMessageBar from "../../../components/ErrorMessageBar/ErrorMessageBar";
import { IOnderhoudsContract } from './onderhoudsContractSlice'
import Icon from '../../../components/Icon'

type OnderhoudscontractDtoType = z.infer<typeof OnderhoudscontractDto>;

const onGetKey = (item: OnderhoudscontractDtoType) => item?.id

const defaultOnderhoudsContract = {
    id: 0,
    leverancierId: 0,
    voorVolledigBezit: false
} as OnderhoudscontractDtoType

const dateColumnWidth = 100

const OnderhoudsContractGrid: React.FC<{}> = () => {
    const navigate = useNavigate()
    const [selectedItems, setSelectedItems] = useState<number[]>([] as number[])
    const {validatedSearchParams, setPage, setOrder, setFilter} = usePagedParams()
    const [showDialog, setShowDialog] = useState(false)
    const [deleteWarningMessage, setDeleteWarningMessage] = useState('')
    const [error, setError] = useState<string>()

    const {
        data,
        invalidate: fetchOnderhoudsContract,
        isLoading: isFetchingOnderhoudsContract
    } = zodiosHooks.useGetApiOnderhoudsContract({
        queries: {
            SortKey: validatedSearchParams.sortKey,
            SortDirection: validatedSearchParams.sortDirection,
            PageIndex: validatedSearchParams.page,
            PageSize: validatedSearchParams.pageSize,
            Filter: validatedSearchParams.filter
        }
    })
    
    const {data: suppliersResponse, invalidate: fetchSuppliers} = zodiosHooks.useGetRechtspersonen({
        queries: {
            Filter: '',
            RelatieRol: 'Leverancier'
        }
    })

    const {mutate: deleteOnderhoudsContract, isLoading: isDeleting} = zodiosHooks.useDeleteApiOnderhoudsContract({},
    {
        onSuccess: () => fetchOnderhoudsContract(),
        onError: (error) => setError(getTitleAndMessage(error).message)
    })

    const handleListSelectionChanged = (ids: number[]) => {
        setSelectedItems(ids)
    }

    useEffect(() => {
        fetchOnderhoudsContract()
        fetchSuppliers()
    }, [])

    const handleItemInvoked = (item: OnderhoudscontractDtoType) => {
        navigate(PAGES[PageIdDefinition.contract.edit].route.replace(':id', item.id.toString()))
    }
    
    const removeItems = () => {
        if(isDeleting) return;
        
        const itemsToDelete = data?.items?.filter(i => selectedItems.includes(i.id));
        if (itemsToDelete) {
            let warningMessage = 'Aan deze onderhoudscontracten zijn gebouwen en/of taken gekoppeld:'
            for (const onderhoudscontract of itemsToDelete) {
                if (onderhoudscontract.taken?.length ?? -1 > 0) {
                    warningMessage += `\n- ${onderhoudscontract.contractNummer}`
                    setShowDialog(true)
                    setDeleteWarningMessage(warningMessage)
                    return
                }
            }
            removeConfirm()
        }
    }

    const removeConfirm = () => {
        deleteOnderhoudsContract(selectedItems)
        fetchOnderhoudsContract()
        setShowDialog(false)
    }

    const getRelatieHandelsNaam = (relatieId: number) => {
        return suppliersResponse?.items ? suppliersResponse.items.find(i => i.id === relatieId)?.handelsnaam ?? '' : ''
    }

    const  getIntakeDoorCorporatie =   (relatieId: number) => {
        return suppliersResponse?.items ? suppliersResponse.items.find(i => i.id === relatieId)?.intakeDoorCorporatie ?? false : false
    }

    const columns = [
        {
            key: getPropertyName<OnderhoudscontractDtoType>('contractNummer'),
            fieldName: getPropertyName<OnderhoudscontractDtoType>('contractNummer'),
            name: 'Contractnummer',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isRowHeader: true,
            onRender: (item: OnderhoudscontractDtoType) => (
                <Link key={item.id} onClick={() => handleItemInvoked(item)}>
                    {item.contractNummer}
                </Link>
            )
        },
        {
            key: getPropertyName<OnderhoudscontractDtoType>('contractOmschrijving'),
            fieldName: getPropertyName<OnderhoudscontractDtoType>('contractOmschrijving'),
            name: 'Contractomschrijving',
            minWidth: 200,
            maxWidth: 400,
            isResizable: true,
            isRowHeader: true
        },
        {
            key: getPropertyName<OnderhoudscontractDtoType>('contractBeheerder'),
            fieldName: getPropertyName<OnderhoudscontractDtoType>('contractBeheerder'),
            name: 'Contractbeheerder',
            minWidth: 200,
            maxWidth: 400,
            isResizable: true,
            isRowHeader: true
        },
        {
            key: getPropertyName<OnderhoudscontractDtoType>('leverancierId'),
            fieldName: getPropertyName<OnderhoudscontractDtoType>('leverancierId'),
            name: 'Contractpartij',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            isRowHeader: true,
            onRender: (item: OnderhoudscontractDtoType) => <label>{getRelatieHandelsNaam(item.leverancierId)}</label>
        },
        {
            key: 'intakeDoorCorporatie',
            fieldName: 'intakeDoorCorporatie',
            name: 'Intake door corporatie',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            isRowHeader: true,
            onRender: (item: IOnderhoudsContract) => { return getIntakeDoorCorporatie(item.leverancierId) ? <Icon name="Accept" variant="red" /> : <Icon name="Cancel" variant="light" />}
        },
        {
            key: getPropertyName<OnderhoudscontractDtoType>('ingangsDatum'),
            fieldName: getPropertyName<OnderhoudscontractDtoType>('ingangsDatum'),
            name: 'Ingangsdatum',
            minWidth: dateColumnWidth,
            maxWidth: dateColumnWidth,
            isResizable: false,
            isRowHeader: true,
            onRender: (item: OnderhoudscontractDtoType) => <>{transformDateString(item.ingangsDatum)}</>
        },
        {
            key: getPropertyName<OnderhoudscontractDtoType>('eindDatum'),
            fieldName: getPropertyName<OnderhoudscontractDtoType>('eindDatum'),
            name: 'Einddatum',
            minWidth: dateColumnWidth,
            maxWidth: dateColumnWidth,
            isResizable: false,
            isRowHeader: true,
            onRender: (item: OnderhoudscontractDtoType) => <>{transformDateString(item.eindDatum)}</>
        }
    ] as IColumn[]

    const commandBarItems = [
        {
            key: 'back',
            iconOnly: true,
            iconProps: {iconName: 'Back', className: 'icon'},
            split: false,
            ariaLabel: 'back',
            onClick: () => navigate(-1)
        },
        {
            key: 'refresh',
            text: 'Verversen',
            iconProps: {iconName: 'Refresh', className: 'icon'},
            split: false,
            ariaLabel: 'Verversen',
            onClick: () => fetchOnderhoudsContract()
        },
        {
            key: 'create',
            text: 'Nieuw',
            iconProps: {iconName: 'NewFolder', className: 'icon'},
            split: false,
            ariaLabel: 'Nieuw',
            onClick: () => {
                handleItemInvoked(defaultOnderhoudsContract)
            }
        },
        {
            key: 'delete',
            text: 'Verwijder',
            iconProps: {iconName: 'Delete', className: 'icon'},
            split: false,
            ariaLabel: 'Verwijderen',
            disabled: selectedItems.length === 0,
            onClick: () => removeItems()
        }
    ] as ICommandBarItemProps[]

    return (
        <div className='componentWrapper'>
            <DeleteConfirmationDialog
                onConfirm={removeConfirm}
                onCancel={() => {
                    setShowDialog(false)
                    setDeleteWarningMessage('')
                }}
                entityName={'Onderhoudscontracten'}
                showDialog={showDialog}
                customMessage={deleteWarningMessage}
            />
            <ErrorMessageBar error={error} />
            <Stack horizontal tokens={{childrenGap: 5}}>
                <StackItem align='baseline' grow={3}>
                    <CommandBar items={commandBarItems} styles={commandBarStyles}/>
                </StackItem>
            </Stack>
            <TitleTemplate title='Onderhoudscontracten' subTitle='Overzicht'/>
            <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter}/>
            <VgtPagedTable
                items={data?.items ?? undefined}
                height={'200'}
                isLoading={isFetchingOnderhoudsContract}
                columns={columns}
                pagingInfo={{
                    pageSize: validatedSearchParams.pageSize,
                    currentPage: validatedSearchParams.page,
                    totalCount: data?.totalCount ?? 0,
                    totalPages: data?.totalPages ?? 0,
                    hasNextPage: data?.hasNextPage ?? false,
                    hasPreviousPage: data?.hasPreviousPage ?? false,
                    sortKey: validatedSearchParams.sortKey,
                    sortDirection: validatedSearchParams.sortDirection
                }}
                getKey={onGetKey}
                onPageChanged={setPage}
                onSortChanged={setOrder}
                onItemInvoked={handleItemInvoked}
                onSelectionChanged={handleListSelectionChanged}
            />
        </div>
    )
}

export default OnderhoudsContractGrid
