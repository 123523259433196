import React, { useMemo, useState } from 'react'
import { z } from 'zod'
import { TaakListDto, zodiosHooks } from '../../../../api/ApiClient'
import { useNavigate } from 'react-router-dom'
import { usePagedParams } from '../../../../services/usePagedParams'
import useRoles from '../../../../services/UseRoles'
import { useBoolean } from '@fluentui/react-hooks'
import { IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import MainTemplate from '../../../../containers/pages/PageTemplates/MainTemplate'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { TakenFormPanel } from './TakenFormPanel'


type TaakListDtoType = z.infer<typeof TaakListDto>;

export const TakenList: React.FC = () => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const { isAdmin } = useRoles()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [itemToEdit, setItemToEdit] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = React.useState<string>()

  const { data, invalidate, isLoading } = zodiosHooks.useGetApiTaak({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteApiTaak({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('TaakNr', getPropertyName<TaakListDtoType>('taakId'), 'M', false),
      createColumn('Naam', getPropertyName<TaakListDtoType>('naam'), 'MAX', true,
        (item: TaakListDtoType) => (
          <Link key={item.taakId} onClick={() => handleItemInvoked(item.taakId.toString())}>
            {item.naam}
          </Link>
        )),
      createColumn('Ingangsdatum', getPropertyName<TaakListDtoType>('ingangsDatum'), 'XL', false),
      createColumn('Einddatum', getPropertyName<TaakListDtoType>('eindDatum'), 'XL', false)
    ]
  }, [])

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
      {
        visible: isAdmin,
        text: 'Toevoegen',
        onClick: () => {
          openPanel()
        },
        icon: 'Add'
      },
      {
        visible: isAdmin,
        text: 'Verwijderen',
        onClick: () => deleteRows({ itemsToDelete: selectedIds }),
        icon: 'Delete',
        disabled: isDeleting || !selectedIds.length
      }
    ],
    [isAdmin, selectedIds.length]
  )

  const handleItemInvoked = (id: string) => {
    if (id) {
      setItemToEdit(parseInt(id))
      openPanel()
    }
  }
  const handleDismissPanel = () => {
    setItemToEdit(0)
    dismissPanel()
  }

  return (
    <MainTemplate title="Reparatieboom-taken" subTitle="Overzicht" commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <TakenFormPanel taakId={itemToEdit} dismissPanel={handleDismissPanel} isOpen={isPanelOpen}
                      invalidate={invalidate} />
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.taakId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.taakId.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}
