export const PageIdDefinition = {
  startpagina: 'STARTPAGINA',
  zoekFysiek: 'ZOEK_FYSIEK',
  zoekAdministratief: 'ZOEK_ADMINISTRATIEF',
  vge: {
    searchResults: 'VGE_SEARCHRESULTS',
    edit: 'VGE_EDIT',
    fromVhe: 'FROM_VHE'
  },
  basisregistraties: {
    zoeken: 'BASISREGISTRATIES_ZOEKEN',
    details: 'BASISREGISTRATIES_DETAILS'
  },
  beheer: {
    coenCardCodes: 'COENCADCODES',
    categorieen: 'Categorieën',
    opnamedetailCartotheek: 'OPNAMEDETAIL_CARTOTHEEK',
    beheerSelector: 'BEHEERSELECTOR',
    lemdo: {
      defectLocaties: 'DEFECTLOCATIES',
      defectSoorten: 'DEFECTSOORTEN',
      eenheidSoorten: 'EENHEIDSOORTEN',
      eenheidDetailSoorten: 'EENHEIDDETAILSOORTEN',
      elementCategorieen: 'ELEMENTCATEGORIEEN',
      elementen: 'ELEMENTEN',
      elementDetails: 'ELEMENTDETAILS',
      nlsfb: 'NLSFBCODERING',
      eenheidDetailSoortRuimteSoorten: 'EENHEIDDETAILSOORTRUIMTESOORTEN',
      eenheiddetailsoortDefectlocatieBinnen: 'EENHEIDDETAILSOORTDEFECTLOCATIESBINNEN',
      eenheiddetailsoortDefectlocatieBuiten: 'EENHEIDDETAILSOORTDEFECTLOCATIESBUITEN',
      defectLocatieElementCategories: 'DEFECTLOCATIE_ELEMENTCATEGORIES',
      elementDefectSoorten: 'ELEMENTDEFECTSOORTEN',
      elementElementDetails: 'ELEMENTELEMENTDETAILS',
      elementElementDetailDefectSoortTaken: 'ELEMENTELEMENTDETAILDEFECTSOORTTAKEN',
      ruimteSoorten: 'RUIMTESOORTEN',
      taken: 'TAKEN',
      terreinClassificatie: 'TERREINCLASSIFICATIE',
      export: 'EXPORTLEMDO',
      notificaties: 'NOTIFICATIES',
      verwarming: 'VERWARMING',
      begrippenLijst: 'BEGRIPPENLIJST',
      begrippenLijstViewOnly: 'BEGRIPPENLIJST_VIEW_ONLY',
      materiaalSoorten: 'MATERIAALSOORTEN',
      dienstencomponenten: 'DIENSTENCOMPONENTEN',
      onderhoudstaakErp: 'ONDERHOUDSTAKEN',
      inrichtenTaken: 'INRICHTEN_TAKEN',
      woningverbetering: 'WONINGVERBETERING',
      tenantWoningverbetering: 'TENANTWONINGVERBETERING',
      ruimtesoortenErp: 'RUIMTESOORT_ERP',
      inrichtenRuimteSoorten: 'INRICHTEN_RUIMTESOORTEN',
      onderhoudsspecialismen: 'ONDERHOUDSSPECIALISMEN',
      defectoorzaak: 'DEFECTOORZAAK',
      woningverbeteringToestemmingcodes: 'WONINGVERBETERINGTOESTEMMINGCODES',
      kostensoorten: 'KOSTENSOORTEN',
      kostensoortGarantie: 'KOSTENSOORTGARANTIE',
      defectoorzakenKostensoort: 'DEFECTOORZAKENKOSTENSOORT',
      betalerKostensoort: 'BETALERKOSTENSOORT',
      onderhoudsbedrijfTypering: 'ONDERHOUDSBEDRIJFTYPERING',
      contactCategorie: 'CONTACTCATEGORIE',
      woningverbeteringDefectlocaties: 'WONINGVERBETERINGDEFECTLOCATIES'
    },
    vhe: {
      edit: 'VHE_EDIT'
    },
    fysiek: {
      gebouwen: 'GEBOUWEN',
      gebouwEdit: 'GEBOUWEDIT',
      gebouwenClusters: 'GEBOUWENCLUSTERS',
      gebouwenClusterEdit: 'GEBOUWENCLUSTER_EDIT',
      gebouwDelen: 'GEBOUWDELEN',
      importExport: 'EXPORTIMPORT'
    },
    relatie: {
      rechtspersonen: 'RECHTSPERSONEN',
      vves: { list: 'VVES', edit: 'VVE_EDIT' },
      contactgegevens: 'CONTACTGEGEVENS'
    },
    imports: {
      verwerkingen: 'VERWERKINGEN',
      starten: 'STARTEN',
      importeren: 'IMPORTEREN',
      exporteren: 'EXPORTEREN',
      verwerkingsRegels: 'VERWERKINGSREGELS',
      resultaten: 'IMPORT_RESULTS',
      elementenMJOB: 'IMPORT_ELEMENTEN_MJOB',
      woz: 'WOZ_IMPORT'
    },
    queries: 'VASTGOED_QUERIES',
    stamTabellen: {
      keuken: 'KEUKEN',
      sanitair: 'SANITAIR',
      documentBeheer: 'DOCUMENTCONFIG',
      bouwlagen: 'BOUWLAGEN'
    }
  },
  contract: {
    beheer: 'ONDERHOUDSCONTRACTEN_GRID',
    edit: 'ONDERHOUDSCONTRACTEN_EDIT'
  },
  sleuteltype: {
    list: 'SLEUTELTYPE_LIST',
    edit: 'SLEUTELTYPE_EDIT'
  },
  metertype: {
    list: 'METERTYPE_LIST',
    edit: 'METERTYPE_EDIT'
  }
}

export interface IPage {
  title: string
  leftIconName?: string
  rightIconName?: string
  route: string
}

interface IPages {
  [dynamic: string]: IPage
}

export const PAGES: IPages = {
  [PageIdDefinition.startpagina]: {
    title: 'Startpagina',
    leftIconName: 'Home',
    route: '/'
  },
  [PageIdDefinition.zoekFysiek]: {
    title: 'Fysiek',
    leftIconName: 'Search',
    route: '/search'
  },
  [PageIdDefinition.vge.searchResults]: {
    title: 'VGE zoekresultaten',
    leftIconName: 'Search',
    route: '/vge/searchresults/:query/type=:type'
  },
  [PageIdDefinition.vge.edit]: {
    title: 'VGE wijzigen',
    leftIconName: 'Edit',
    route: '/vge/edit/:id'
  },
  [PageIdDefinition.vge.fromVhe]: {
    title: 'VGE met VHE link',
    route: '/vge/behorendebijvhe/:id'
  },
  [PageIdDefinition.beheer.vhe.edit]: {
    title: 'VHE link',
    route: '/beheer/vhes/:id'
  },
  [PageIdDefinition.zoekAdministratief]: {
    title: 'Administratief',
    leftIconName: 'Search',
    route: '/search'
  },
  [PageIdDefinition.beheer.beheerSelector]: {
    title: 'Indelen fysiek bezit',
    leftIconName: 'BuildDefinition',
    route: '/beheer'
  },
  [PageIdDefinition.beheer.queries]: {
    title: 'Vastgoed queries',
    leftIconName: 'BuildDefinition',
    route: '/beheer/queries'
  },
  [PageIdDefinition.beheer.lemdo.eenheidSoorten]: {
    title: 'Eenheidsoorten',
    leftIconName: 'ClipboardList',
    route: '/beheer/eenheidSoorten'
  },
  [PageIdDefinition.beheer.lemdo.eenheidDetailSoorten]: {
    title: 'Eenheiddetailsoorten',
    leftIconName: 'ClipboardList',
    route: '/beheer/eenheidDetailSoorten'
  },
  [PageIdDefinition.beheer.lemdo.defectLocaties]: {
    title: 'Defectlocaties',
    leftIconName: 'ClipboardList',
    route: '/beheer/defectlocatie'
  },
  [PageIdDefinition.beheer.lemdo.defectSoorten]: {
    title: 'Defectsoorten',
    leftIconName: 'ClipboardList',
    route: '/beheer/defectSoorten'
  },
  [PageIdDefinition.beheer.lemdo.elementCategorieen]: {
    title: 'Elementcategorieën',
    route: '/beheer/elementCategorieen'
  },
  [PageIdDefinition.beheer.lemdo.materiaalSoorten]: {
    title: 'Materiaalsoorten',
    route: '/beheer/materiaalSoorten'
  },
  [PageIdDefinition.beheer.lemdo.elementen]: {
    title: 'Elementen',
    route: '/beheer/elementen'
  },
  [PageIdDefinition.beheer.lemdo.elementDetails]: {
    title: 'Elementdetails',
    route: '/beheer/elementDetails'
  },
  [PageIdDefinition.beheer.lemdo.terreinClassificatie]: {
    title: 'TerreinClassificatie',
    route: '/beheer/terreinClassificatie'
  },
  [PageIdDefinition.beheer.lemdo.onderhoudsbedrijfTypering]: {
    title: 'Typering onderhoudsbedrijven',
    route: '/beheer/typeringonderhoudsbedrijf'
  },
  [PageIdDefinition.beheer.lemdo.contactCategorie]: {
    title: 'Contact categorieën',
    route: '/beheer/contactCategorien'
  },
  [PageIdDefinition.beheer.fysiek.gebouwen]: {
    title: 'Gebouwen',
    route: '/beheer/gebouw'
  },
  [PageIdDefinition.beheer.fysiek.gebouwEdit]: {
    title: 'Gebouw',
    route: '/beheer/gebouwen/:id'
  },
  [PageIdDefinition.beheer.fysiek.gebouwDelen]: {
    title: 'Gebouwdelen',
    route: '/beheer/gebouwdelen'
  },
  [PageIdDefinition.beheer.fysiek.gebouwenClusters]: {
    title: 'Gebouwenclusters',
    route: '/beheer/gebouwenclusters'
  },
  [PageIdDefinition.beheer.fysiek.importExport]: {
    title: 'Gebouwenclusters',
    route: '/beheer/gebouwenclusters'
  },

  [PageIdDefinition.beheer.relatie.rechtspersonen]: {
    title: 'Rechtspersonen',
    route: '/beheer/relatie/rechtspersonen'
  },
  [PageIdDefinition.beheer.relatie.vves.list]: {
    title: 'VvE\'s',
    route: '/beheer/relatie/vves'
  },
  [PageIdDefinition.beheer.relatie.vves.edit]: {
    title: 'VvE',
    route: '/beheer/relatie/vves/:id'
  },
  [PageIdDefinition.beheer.relatie.contactgegevens]: {
    title: 'Contactgegevens',
    route: '/beheer/relatie/contactgegevens'
  },
  [PageIdDefinition.beheer.imports.verwerkingsRegels]: {
    title: 'Verwerkingsregels',
    route: '/beheer/verwerkingsregels/:verwerkingen'
  },
  [PageIdDefinition.beheer.imports.starten]: {
    title: 'Verwerking starten',
    route: '/beheer/verwerkingen/starten'
  },
  [PageIdDefinition.beheer.imports.verwerkingen]: {
    title: 'Verwerkingen',
    route: '/beheer/verwerkingen'
  },
  [PageIdDefinition.beheer.imports.resultaten]: {
    title: 'Importtaken',
    route: '/beheer/imports/taken'
  },
  [PageIdDefinition.beheer.imports.importeren]: {
    title: 'Importeren',
    route: '/beheer/imports/importeren'
  },
  [PageIdDefinition.beheer.imports.exporteren]: {
    title: 'Importeren',
    route: '/beheer/imports/exporteren'
  },
  [PageIdDefinition.beheer.imports.elementenMJOB]: {
    title: 'Elementen en hoeveelheden MJOB',
    route: '/beheer/imports/elementenMJOB'
  },
  [PageIdDefinition.beheer.lemdo.nlsfb]: {
    title: 'NL-Sfb Codering',
    route: '/beheer/lemdo/nlsfb'
  },
  [PageIdDefinition.basisregistraties.zoeken]: {
    title: 'Basisregistraties',
    leftIconName: 'Search',
    route: '/basisregistraties/zoeken'
  },
  [PageIdDefinition.basisregistraties.details]: {
    title: 'Basisregistraties',
    leftIconName: 'Search',
    route: '/basisregistraties/details/:id'
  },
  [PageIdDefinition.beheer.lemdo.eenheidDetailSoortRuimteSoorten]: {
    title: 'Eenheiddetailsoorten Ruimtesoorten',
    leftIconName: 'ClipboardList',
    route: '/beheer/lemdo/eenheidDetailSoorten/ruimteSoorten'
  },
  [PageIdDefinition.beheer.lemdo.eenheiddetailsoortDefectlocatieBinnen]: {
    title: 'Eenheiddetailsoorten Defectlocaties binnen',
    leftIconName: 'ClipboardList',
    route: '/beheer/lemdo/eenheidDetailSoorten/defectlocaties'
  },
  [PageIdDefinition.beheer.lemdo.eenheiddetailsoortDefectlocatieBuiten]: {
    title: 'Eenheiddetailsoorten Defectlocaties buiten',
    leftIconName: 'ClipboardList',
    route: '/beheer/lemdo/eenheidDetailSoorten/defectlocatiesbuiten'
  },
  [PageIdDefinition.beheer.lemdo.defectLocatieElementCategories]: {
    title: 'Defectlocaties || Elementen',
    leftIconName: 'ClipboardList',
    route: '/beheer/lemdo/defectLocaties/elementen'
  },
  [PageIdDefinition.beheer.lemdo.materiaalSoorten]: {
    title: 'Materiaalsoorten',
    leftIconName: 'DiffSideBySide',
    route: '/beheer/materiaalSoorten'
  },
  [PageIdDefinition.beheer.lemdo.elementDefectSoorten]: {
    title: 'Element defectsoorten',
    leftIconName: 'ClipboardList',
    route: '/beheer/lemdo/elementDefectSoorten'
  },
  [PageIdDefinition.beheer.lemdo.elementElementDetails]: {
    title: 'Elementdetails elementen',
    leftIconName: 'ClipboardList',
    route: '/beheer/lemdo/element/elementDetails'
  },
  [PageIdDefinition.beheer.lemdo.ruimteSoorten]: {
    title: 'Ruimtesoorten',
    leftIconName: 'ClipboardList',
    route: '/beheer/lemdo/ruimteSoorten'
  },
  [PageIdDefinition.beheer.lemdo.taken]: {
    title: 'Taken',
    leftIconName: 'AllApps',
    route: '/beheer/lemdo/taken'
  },
  [PageIdDefinition.beheer.lemdo.elementElementDetailDefectSoortTaken]: {
    title: 'Element',
    leftIconName: 'BulletedTreeList',
    route: '/beheer/lemdo/elementDefectSoort/taken'
  },
  [PageIdDefinition.beheer.stamTabellen.keuken]: {
    title: 'Keukens',
    leftIconName: 'Breakfast',
    route: '/beheer/stamtabellen/keuken'
  },
  [PageIdDefinition.beheer.stamTabellen.sanitair]: {
    title: 'Sanitair',
    leftIconName: 'GripperTool',
    route: '/beheer/stamtabellen/sanitair'
  },
  [PageIdDefinition.beheer.lemdo.export]: {
    title: 'Export LEmDO',
    leftIconName: 'Export',
    route: '/beheer/lemdo/export'
  },
  [PageIdDefinition.beheer.stamTabellen.documentBeheer]: {
    title: 'Classificatie Media',
    leftIconName: 'DocumentManagement',
    route: '/beheer/documentClassificatie'
  },
  [PageIdDefinition.beheer.fysiek.gebouwenClusterEdit]: {
    title: 'Gebouwencluster',
    leftIconName: 'Sections',
    route: '/beheer/fysiek/gebouwencluster/:id'
  },
  [PageIdDefinition.beheer.lemdo.verwarming]: {
    title: 'Verwarming',
    leftIconName: 'Brightness',
    route: '/beheer/verwarming'
  },
  [PageIdDefinition.beheer.coenCardCodes]: {
    title: 'CoenCad Keys',
    leftIconName: 'ButtonControl',
    route: '/beheer/stamtabellen/coencad'
  },
  [PageIdDefinition.beheer.stamTabellen.bouwlagen]: {
    title: 'Bouwlagen',
    leftIconName: 'MapLayers',
    route: '/beheer/stamtabellen/bouwlagen'
  },
  [PageIdDefinition.contract.beheer]: {
    title: 'Onderhoudscontracten',
    leftIconName: 'LinkedDatabase',
    route: '/beheer/onderhoudscontracten'
  },
  [PageIdDefinition.beheer.categorieen]: {
    title: 'Categorieën',
    leftIconName: 'LinkedDatabase',
    route: '/beheer/categorieen'
  },
  [PageIdDefinition.beheer.opnamedetailCartotheek]: {
    title: 'Opnamedetail cartotheek',
    leftIconName: 'LinkedDatabase',
    route: '/beheer/opnamedetailCartotheek'
  },
  [PageIdDefinition.contract.edit]: {
    title: 'Onderhoudscontract bewerken',
    leftIconName: 'LinkedDatabase',
    route: '/beheer/onderhoudscontracten/:id'
  },
  [PageIdDefinition.sleuteltype.list]: {
    title: 'Sleuteltypen',
    leftIconName: 'LinkedDatabase',
    route: '/beheer/sleuteltypen'
  },
  [PageIdDefinition.metertype.list]: {
    title: 'Metertypen',
    leftIconName: 'LinkedDatabase',
    route: '/beheer/metertypen'
  },
  [PageIdDefinition.beheer.lemdo.begrippenLijst]: {
    title: 'Begrippen Lijst',
    leftIconName: 'EntitlementPolicy',
    route: '/beheer/begrippenlijst'
  },
  [PageIdDefinition.beheer.lemdo.begrippenLijstViewOnly]: {
    title: 'Begrippen Lijst',
    leftIconName: 'EntitlementPolicy',
    route: '/beheer/begrippenlijst/:viewonly'
  },
  [PageIdDefinition.beheer.lemdo.dienstencomponenten]: {
    title: 'Dienstencomponenten',
    leftIconName: 'WebComponents',
    route: '/beheer/lemdo/dienstencomponenten'
  },
  [PageIdDefinition.beheer.lemdo.onderhoudstaakErp]: {
    title: 'Onderhoudstaak',
    leftIconName: 'ProcessMetaTask',
    route: '/beheer/lemdo/onderhoudstaken'
  },
  [PageIdDefinition.beheer.lemdo.inrichtenTaken]: {
    title: 'Onderhoudstaak',
    leftIconName: 'TaskGroup',
    route: '/beheer/lemdo/inrichtentaken'
  },
  [PageIdDefinition.beheer.lemdo.woningverbetering]: {
    title: 'Woningverbetering',
    leftIconName: 'BuildQueueNew',
    route: '/beheer/lemdo/woningverbetering'
  },
  [PageIdDefinition.beheer.lemdo.tenantWoningverbetering]: {
    title: 'Woningverbetering',
    leftIconName: 'BuildQueueNew',
    route: '/beheer/lemdo/tenantwoningverbetering'
  },
  [PageIdDefinition.beheer.lemdo.ruimtesoortenErp]: {
    title: 'Ruimtesoort Erp',
    leftIconName: 'CollapseContent',
    route: '/beheer/lemdo/ruimtesoorterp'
  },
  [PageIdDefinition.beheer.lemdo.inrichtenRuimteSoorten]: {
    title: 'Inrichten Ruimtesoorten',
    leftIconName: 'AddLink',
    route: '/beheer/lemdo/defectlocatiesruimtesoorten'
  },
  [PageIdDefinition.beheer.lemdo.onderhoudsspecialismen]: {
    title: 'Onderhoudsspecialismen',
    leftIconName: 'ExternalBuild',
    route: '/beheer/lemdo/onderhoudsspecialismen'
  },
  [PageIdDefinition.beheer.lemdo.defectoorzaak]: {
    title: 'Defectoorzaak',
    leftIconName: 'ClinicalImpression',
    route: '/beheer/lemdo/defectoorzaak'
  },
  [PageIdDefinition.beheer.lemdo.woningverbeteringToestemmingcodes]: {
    title: 'Woningverbetering || Toestemmingcodes',
    leftIconName: 'ClinicalImpression',
    route: '/beheer/lemdo/woningverbeteringtoestemmingcodes'
  },
  [PageIdDefinition.beheer.lemdo.woningverbeteringDefectlocaties]: {
    title: 'Defectlocaties || Woningverbetering',
    leftIconName: 'ClinicalImpression',
    route: '/beheer/lemdo/woningverbeteringdefectlocaties'
  },
  [PageIdDefinition.beheer.lemdo.kostensoorten]: {
    title: 'Kostensoorten',
    leftIconName: 'Money',
    route: '/beheer/lemdo/kostensoorten'
  },
  [PageIdDefinition.beheer.lemdo.kostensoortGarantie]: {
    title: 'Kostensoorten Garantie',
    leftIconName: 'CalculatedTable',
    route: '/beheer/lemdo/kostensoortgarantie'
  },
  [PageIdDefinition.beheer.lemdo.defectoorzakenKostensoort]: {
    title: 'Defectsoorzaken || Kostensoorten',
    leftIconName: 'CalculatorDelta',
    route: '/beheer/lemdo/defectoorzakenKostensoort'
  },
  [PageIdDefinition.beheer.lemdo.betalerKostensoort]: {
    title: 'Betaler || Kostensoorten',
    leftIconName: 'ConnectContacts',
    route: '/beheer/lemdo/betalerkostensoorten'
  }
}
