import React, { useEffect, useState } from 'react'
import { mergeStyles, Stack, StackItem } from '@fluentui/react'
import Autocomplete, { ISuggestionItem } from 'components/Autocomplete'
import {
  fetchElements,
  IElementSuggestion,
  selectEntities as selectElementSuggestions,
  clearSuggestions,
  IElementChoice,
  IElementSearchArgs,
} from './elementSuggestions'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { useAppDispatch } from 'store'
import { Dictionary } from '@reduxjs/toolkit'

export interface IElementFieldProps {
  searchBoxWidth?: number
  dropDownWidth?: number
  dropDownHeight?: number
  initialElement?: IElementChoice
  baseElementId?: number
  labelCode: string
  labelDescription: string
  toolTip: string
  elementCode?: string
  elementDescription?: string
  onChanged: (element: IElementChoice | undefined) => void
}

const searchStyle = mergeStyles({
  padding: '5px 0px 5px 12px;',
  borderBottom: '1px solid rgb(219, 219, 219)',
  '& .ms-StackItem:first-child': {
    width: '215px',
  },
  '& .ms-StackItem:last-child': {
    width: '360px',
  },
  '& .autoCompleteLabel': {
    paddingTop: '5px',
  },
})

const mapSuggestions = (dict: Dictionary<IElementSuggestion>) => {
  var arr = [] as ISuggestionItem[]
  for (var key in dict) {
    const item = dict[key]
    if (item) {
      arr.push({
        key: item.id,
        displayValue: `${item.code} - ${item.description}`,
        searchValue: item.code,
        tag: item,
      })
    }
  }
  return arr
}
export const ElementField: React.FC<IElementFieldProps> = props => {
  const [elementId, setElementId] = useState<number>()
  const [currentElementCode, setCurrentElementCode] = useState<string | undefined>(props.elementCode)
  const [currentElementDescription, setCurrentElementDescription] = useState<string | undefined>(props.elementDescription)
  const libSuggestions = useSelector(selectElementSuggestions)
  const suggestions = mapSuggestions(libSuggestions)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (props.initialElement?.id !== elementId) {
      dispatch(clearSuggestions())
      if (props.initialElement) {
        setCurrentElementCode(props.initialElement.code)
        setCurrentElementDescription(props.initialElement.description)
      }
      setElementId(props.initialElement?.id)
    }
  }, [props.initialElement])

  const onSelectElement = (item: ISuggestionItem): void => {
    var element = item.tag as IElementSuggestion
    setCurrentElementCode(element.code)
    setCurrentElementDescription(element.description)
    props.onChanged(element)
  }

  const onClear = () => {
    setCurrentElementCode(undefined)
    setCurrentElementDescription(undefined)
    props.onChanged(undefined)
  }

  const findElements = (text: string) => {
    dispatch(fetchElements({ baseElementId: props.baseElementId, text: text } as IElementSearchArgs))
  }

  const delayedSearch = debounce((text: string) => findElements(text), 500)

  const onSearchElement = (text: string) => {
    delayedSearch(text)
  }

  return (
    <>
      <Autocomplete
        label={props.labelCode}
        value={currentElementCode}
        items={suggestions}
        searchTitle={props.toolTip}
        suggestionCallback={onSelectElement}
        searchCallback={onSearchElement}
        searchBoxWidth={props.searchBoxWidth}
        dropDownWidth={props.dropDownWidth}
        dropDownHeight={props.dropDownHeight}
        clearCallback={onClear}
      />
      <Stack horizontal className={searchStyle}>
        <StackItem className="elementDescriptionLabel">
          <label>{props.labelDescription}</label>
        </StackItem>
        <StackItem className="elementDescription">
          <label style={{ fontWeight: 600 }}>{currentElementDescription}</label>
        </StackItem>
      </Stack>
    </>
  )
}

export default ElementField
