import { DefaultButton, DetailsList, Panel, PanelType, SearchBox, Selection, SelectionMode, Spinner, SpinnerSize, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import { createColumn } from "lib/gridHelper"
import { getPropertyName } from "lib/interfaceUtils"
import { debounce } from "lodash"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"
import { fetchGerechtigden, getLoadingState, selectAll, IKadastraalSubject } from "../kadastraalSubjectSlice"

export const SelectGerechtigdePanel: React.FC<{ title?: string, selectionMode?: SelectionMode, isOpen: boolean, dismissPanel: () => void, onSelectionComplete: (gerechtigden: string[]) => void }> = ({ isOpen, dismissPanel, onSelectionComplete, selectionMode = SelectionMode.single, title = "Selecteer gerechtigde" }) => {
    const [currentSearchValue, setCurrentSearchValue] = useState<string | undefined>('')
    const [koppelDisabled, setKoppelDisabled] = useState(true);
    const dispatch = useAppDispatch()

    const loadingState = useSelector(getLoadingState)
    const gerechtigden = useSelector(selectAll)

    const onSearchClicked = (newValue: string) => {
        dispatch(fetchGerechtigden({ filter: newValue }))
    }

    const [selection] = React.useState(() => {
        const s = new Selection({
            selectionMode: selectionMode,
            items: gerechtigden ?? [] as IKadastraalSubject[],
            onSelectionChanged: () => { setKoppelDisabled(selection.count === 0); return selection.count },
            getKey: item => item.id.toString()
        })
        return s
    })

    const delayedSearch = debounce(eventData => dispatch(fetchGerechtigden({ filter: eventData })), 1000)

    const onSearchBoxChanged = (newValue: string | undefined) => {
        setCurrentSearchValue(newValue)
        if (newValue && newValue.length >= 2) {
            delayedSearch(newValue)
        }
    }

    const onKoppel = () => {
        onSelectionComplete(selection?.getSelection().map(v => v.id.toString()) ?? [])
        cleanUp()
    }

    const cleanUp = () => {
        setCurrentSearchValue('')
        selection?.setAllSelected(false)
        dismissPanel()
    }
    const onRenderFooterContent = () => {
        return (
            <>
                    <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
                        <DefaultButton text='Selecteren' disabled={koppelDisabled} type='submit' primary onClick={() => onKoppel()} />
                        <DefaultButton text='Annuleren' onClick={cleanUp} />
                    </Stack>
            </>
        )
    }

    return (
        <Panel type={PanelType.large} headerText={title}
            isOpen={isOpen} onDismiss={() => cleanUp()} closeButtonAriaLabel="Sluiten"
            onRenderFooterContent={onRenderFooterContent}>

            <Sticky stickyPosition={StickyPositionType.Header}>
                <SearchBox
                    placeholder="Zoeken"
                    onSearch={onSearchClicked}
                    onChanged={onSearchBoxChanged}
                    onClear={() => setCurrentSearchValue('')}
                    value={currentSearchValue}
                    autoComplete="off" />
            </Sticky>
            {loadingState === 'pending' ?
                <Spinner size={SpinnerSize.large} style={{ marginTop: '40vh' }} /> :
                <DetailsList
                    items={gerechtigden}
                    selection={selection}
                    columns={[
                        createColumn('Nummer', getPropertyName<IKadastraalSubject>('id'), 'M'),
                        createColumn('Naam', getPropertyName<IKadastraalSubject>('naam'), 'XXL'),
                        createColumn('Adres', getPropertyName<IKadastraalSubject>('adres'), 'L')
                    ]}
                ></DetailsList>
            }
        </Panel>)
}