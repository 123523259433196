import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { EntityObjectState, createInitialState, IEntityPageObjectResponse } from 'lib/sliceSupport'
import ITaak from './model'
import {PagedEntities} from "../../../../interfaces/pagedEntities";

const initState: EntityObjectState<ITaak> = createInitialState<ITaak>()

const entityAdapter = createEntityAdapter<ITaak>()

export const fetchAll = createAsyncThunk('/taken/fetchAll', async (_, thunkAPI) => {
    return await invokeFetch<IEntityPageObjectResponse<ITaak>>(thunkAPI, 'GET', '/api/taak/page?skip=0&top=50000')
})
export const searchTaken = createAsyncThunk('/taken/search', async ({ filter }: { filter: string }, thunkAPI) => {
    return await invokeFetch<PagedEntities<ITaak>>(thunkAPI, 'GET', `/api/taak/page?top=50000&Filter=${filter}`)
})

export const taakSlice = createSlice({
    name: 'taken',
    initialState: entityAdapter.getInitialState(initState),

    reducers: {
        select: (state, action: PayloadAction<number | undefined>) => {
            state.selectedId = action.payload
        },
        clearResult: state => entityAdapter.removeAll(state),
        clearSelection: state => {
            state.selectedId = undefined
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
    },

    extraReducers: builder => {
        builder.addCase(fetchAll.pending, state => {
            state.status = 'pending'
        })
        builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<IEntityPageObjectResponse<ITaak>>) => {
            state.status = 'succeeded'
            const items = action.payload.items
            entityAdapter.setAll(state, items)
        })
        builder.addCase(fetchAll.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message || null
        })
        builder.addCase(searchTaken.pending, state => {
            state.status = 'pending'
        })
        builder.addCase(searchTaken.fulfilled, (state, action: PayloadAction<IEntityPageObjectResponse<ITaak>>) => {
            state.status = 'succeeded'
            const items = action.payload.items
            entityAdapter.setAll(state, items)
        })
        builder.addCase(searchTaken.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message || null
        })
    },
})

export const getSelectedEntity = (state: RootState) => {
    if (state.taken.selectedId) return state.taken.entities[state.taken.selectedId]
    else return undefined
}

export const { add, modify, removeMany, select, clearSelection, clearResult } = taakSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.taken)

export default taakSlice.reducer
