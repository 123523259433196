import { Stack, StackItem, TextField, DefaultButton, ITextFieldStyles } from "@fluentui/react"
import HorizonalDatePicker, { parseDate } from "components/HorizontalDatePicker"
import InputCard from "components/InputCard"
import { getPropertyName } from "lib/interfaceUtils"
import { FC, useState } from "react"
import { Controller } from "react-hook-form"
import { register } from "serviceWorker"
import { IInstallatie } from "../installatie/installatieTypes"
import { IRechtspersoon, RelatieRolIds, getRechtspersonen } from "../relaties/rechtspersoon/rechtspersoonSlice"
import { useBoolean } from "@fluentui/react-hooks"
import SearchPanelRechtspersoon from "../relaties/rechtspersoon/searchPanelRechtsPersoon"
import { useSelector } from "react-redux"
import { RootState } from "store"

interface CartotheekDetailKeuringProps {
    control: any
    errors: any
    setValue(name: string, value: any): void
}

export const CartotheekDetailKeuring: FC<CartotheekDetailKeuringProps> = ({ control, errors, setValue }: CartotheekDetailKeuringProps) => {
    const onClearField = (fieldName: string) => {
        setValue(fieldName, undefined)
    }
    const [keuringsPartij, setKeuringsPartij] = useState<IRechtspersoon>()
    const onSelectKeuringsPartij = (persoon: IRechtspersoon) => {
        setKeuringsPartij(persoon)
    }
    const [isSearchKeuringsPartijPanelOpen, {
        setTrue: openSearchKeuringsPartijPanel,
        setFalse: dismissSearchKeuringsPartijPanel
    }] = useBoolean(false)
    const beheerders = useSelector((state: RootState) => getRechtspersonen(state, RelatieRolIds.leverancier))

    const gtinTextFieldStyles: Partial<ITextFieldStyles> = {
        wrapper: {
            borderBottom: '1px solid rgb(219 219 219)',
        },
        subComponentStyles: {
            label: {
                root: { fontWeight: 'normal' },
            },
        },
        field: {
            fontWeight: 600,
        },
        suffix: {
            background: '',
        },
    }

    return (<>
        <SearchPanelRechtspersoon
            rechtspersonen={beheerders}
            isOpen={isSearchKeuringsPartijPanelOpen}
            dismissPanel={dismissSearchKeuringsPartijPanel}
            headerText='Selecteer een keuringspartij'
            onSelect={onSelectKeuringsPartij}
        />
        <InputCard width="33%" title='Keuring'>
                    <Stack horizontal style={{ width: '100%' }}>
                        <StackItem grow={1}>
                            <TextField
                                autoComplete='off'
                                name={getPropertyName<IInstallatie>('keuringPartijId')}
                                // onChange={onProductGtinChanged}
                                label='Keuringspartij'
                                value={keuringsPartij?.handelsnaam}
                                styles={gtinTextFieldStyles}
                                borderless
                                underlined
                                placeholder=' --- '
                                errorMessage={errors.keuringPartijId?.message}
                            />
                        </StackItem>
                        <StackItem grow={0}>
                            <DefaultButton text='Zoeken' onClick={openSearchKeuringsPartijPanel} style={{ marginLeft: 20 }} />
                        </StackItem>
                    </Stack>
                    <Controller
                        name={getPropertyName<IInstallatie>('keuringDatum')}
                        register={register}
                        control={control}
                        errors={errors}
                        render={({ onChange, value }) => (
                            <HorizonalDatePicker
                                label='Keuringsdatum'
                                onChange={onChange}
                                value={parseDate(value)}
                                onClear={() => onClearField(getPropertyName<IInstallatie>('keuringDatum'))}
                            />
                        )}
                    />
                    <Controller
                        name={getPropertyName<IInstallatie>('keuringGeldigTot')}
                        register={register}
                        control={control}
                        errors={errors}
                        render={({ onChange, value }) => (
                            <HorizonalDatePicker
                                label='Geldig tot'
                                onChange={onChange}
                                value={parseDate(value)}
                                onClear={() => onClearField(getPropertyName<IInstallatie>('keuringGeldigTot'))}
                            />
                        )}
                    />
                    <Controller
                        name={getPropertyName<IInstallatie>('keuringNotificatieDatum')}
                        register={register}
                        control={control}
                        errors={errors}
                        render={({ onChange, value }) => (
                            <HorizonalDatePicker
                                label='Notificatiedatum'
                                onChange={onChange}
                                value={parseDate(value)}
                                onClear={() => onClearField(getPropertyName<IInstallatie>('keuringNotificatieDatum'))}
                            />
                        )}
                    />
                </InputCard>
    </>)
}