import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { InspectieSjabloonListDto, zodiosHooks } from '../../../api/ApiClient'
import { usePagedParams } from '../../../services/usePagedParams'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import useRoles from '../../../services/UseRoles'
import { getTitleAndMessage } from '../../../services/HandleError'
import { availableUrls } from '../../../routing/AvailableUrls'

type OnderhoudssjablonListDtoType = z.infer<typeof InspectieSjabloonListDto>;

export const InspectieSjabloonList = () => {
  const navigate = useNavigate()
  const [error, setError] = React.useState<string>()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const { isInspectie } = useRoles()

  const { data, invalidate, isLoading } = zodiosHooks.useGetApiInspectieSjabloon({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteApiInspectieSjabloon({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
    {
      text: 'Toevoegen',
      onClick: () => navigate(availableUrls.InspectieSjablonen_New),
      icon: 'Add',
      iconOnly: true,
      visible: isInspectie
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        deleteRows({ itemsToDelete: selectedIds })
      },
      icon: 'Delete',
      iconOnly: true,
      disabled: isDeleting || selectedIds.length === 0
    }
  ], [isInspectie, selectedIds.length])


  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Inspectiesjablonen', getPropertyName<OnderhoudssjablonListDtoType>('naam'), 'XL', true,
        (item: OnderhoudssjablonListDtoType) => (
          <Link key={item.inspectieSjabloonId}
                onClick={() => navigate(availableUrls.InspectieSjablonen_Edit.replace(':id', item.inspectieSjabloonId.toString()))}>
            {item.naam}
          </Link>
        ))
    ]
  }, [])

  return (
    <MainTemplate title="Inspectiesjablonen" subTitle="Overzicht" commandItems={commandItems} error={error}>

      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount || 0,
          totalPages: data?.totalPages || 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.inspectieSjabloonId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => navigate(availableUrls.InspectieSjablonen_Edit.replace(':id', e.inspectieSjabloonId.toString()))}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}