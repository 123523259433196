import React, { useEffect, useState } from 'react'
import { zodiosHooks } from '../../../../api/ApiClient'
import { IconList, IconProps } from '../IconList'
import { useAppDispatch } from '../../../../store'
import {
  deleteClicked,
  detailSelected,
  elementSelected,
  getCurrentStep,
  getError,
  getLoadingState,
  getSelectedDetail,
  getSelectedElement,
  goBack,
  initialize,
  installatieSelected,
  newFromInstallatie,
  saveInstallatie,
  WizardStep
} from '../CartotheekStateMachine'
import { CartotheekDetail } from '../CartotheekDetail'
import { IInstallatie } from '../../installatie/installatieTypes'
import { useSelector } from 'react-redux'
import { CartotheekList } from '../CartotheekList'
import './cartotheekKeuken.css'
import { Spinner, SpinnerSize } from '@fluentui/react'
import { SelectedIcon } from '../SelectedIcon'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

export enum KeukenDataSoort {
  Keuken = 'Keuken',
  Sanitair = 'Sanitair'
}

interface ICartotheekKeukenProps {
  vgeId: number;
  keukenDataSoort: KeukenDataSoort;
}

export const CartotheekKeuken = ({ vgeId, keukenDataSoort }: ICartotheekKeukenProps) => {
  const dispatch = useAppDispatch()
  const { data: keukenData } = zodiosHooks.useGetVgeVgeIdcartotheekkeukenVoorzieningen({ params: { vgeId: vgeId } })
  const { data: sanitairData } = zodiosHooks.useGetVgeVgeIdcartotheeksanitairVoorzieningen({ params: { vgeId: vgeId } })
  const step = useSelector(getCurrentStep)
  const selectedDetail = useSelector(getSelectedDetail)
  const selectedElement = useSelector(getSelectedElement)
  const [icons, setIcons] = useState<IconProps[]>([])
  const loadingState = useSelector(getLoadingState)
  const error = useSelector(getError)

  useEffect(() => {
    if (vgeId === undefined) return
    dispatch(initialize({ relatedEntityType: 'vge', relatedEntityId: vgeId }))
  }, [vgeId])

  useEffect(() => {

    if (keukenDataSoort === KeukenDataSoort.Keuken) {
      if (keukenData === undefined || keukenData === null || keukenData.length === 0) {
        setIcons([])
        return
      }

      const keukenIcons = keukenData
        ?.sort((a, b) => {
          const nameA = a.name?.toLowerCase() ?? ''
          const nameB = b.name?.toLowerCase() ?? ''
          return nameA.localeCompare(nameB)
        })
        ?.map((icon) => {
            return {
              id: icon.id,
              fonticoon: icon.fonticoon ?? '',
              name: icon.name ?? '',
              numberOfItems: icon.numberOfItems ?? 0
            } as IconProps
          }
        )
      setIcons(keukenIcons)
    }

    if (keukenDataSoort === KeukenDataSoort.Sanitair) {
      if (sanitairData === undefined || sanitairData === null || sanitairData.length === 0) {
        setIcons([])
        return
      }

      const sanitairIcons = sanitairData
        ?.sort((a, b) => {
          const nameA = a.name?.toLowerCase() ?? ''
          const nameB = b.name?.toLowerCase() ?? ''
          return nameA.localeCompare(nameB)
        })
        ?.map((icon) => {
            return {
              id: icon.id,
              fonticoon: icon.fonticoon ?? '',
              name: icon.name ?? '',
              numberOfItems: icon.numberOfItems ?? 0
            } as IconProps
          }
        )
      setIcons(sanitairIcons)
    }

  }, [keukenData, sanitairData])

  const onSelectIcon = (icon: IconProps) => {
    dispatch(detailSelected({ relatedEntityType: 'vge', relatedEntityId: vgeId, elementdetail: icon }))
  }

  const onSubmit = async (data: IInstallatie) => {
    await dispatch(saveInstallatie({ installatie: data }))
  }

  const onGoBack = () => {
    dispatch(goBack())
  }

  const onNewClicked = () => {
    dispatch(newFromInstallatie())
  }

  const onDeleteClicked = (id: number) => {
    dispatch(deleteClicked({ installatieId: id }))
  }

  const onInstallatieSelected = (selectedInstallatie: IInstallatie) => {
    dispatch(installatieSelected(selectedInstallatie))
  }

  if (loadingState === 'failed')
    return <div style={{ marginTop: 20, width: 100 }}>
      <ErrorMessageBar error={error!} />
    </div>

  if ((loadingState === 'pending' && step === WizardStep.initial) || vgeId === undefined)
    return <div style={{ marginTop: 20, width: 100 }}>
      <Spinner size={SpinnerSize.large} />
    </div>

  return (
    <div className='cartotheek-keuken'>

      {step <= WizardStep.selectElementDetail && icons !== undefined &&
        <div className='cartotheek-keuken__icons'>
          <IconList icons={icons} onSelected={(icon) => onSelectIcon(icon)} />
        </div>
      }

      {step > WizardStep.selectElementDetail &&
        <SelectedIcon level={'detail'} selectedIcon={selectedDetail}
                      onSelect={() => {
                        if (selectedElement.id) {
                          dispatch(elementSelected({
                            relatedEntityType: 'vge',
                            relatedEntityId: vgeId,
                            element: selectedElement
                          }))
                        }
                      }} />
      }

      {step === WizardStep.addInstallatie &&
        <div className='cartotheek-keuken__add'>
          <CartotheekDetail onSubmit={onSubmit} onCancel={() => onGoBack()} onNewClicked={onNewClicked} />
        </div>
      }
      {step === WizardStep.editInstallatie &&
        <div className='cartotheek-keuken__edit'>
          <CartotheekDetail onSubmit={onSubmit} onCancel={() => onGoBack()} onNewClicked={onNewClicked}
                            onDeleteClicked={onDeleteClicked} />
        </div>
      }
      {step === WizardStep.listInstallaties &&
        <div className='cartotheek-keuken__list'>
          <CartotheekList onAdd={onNewClicked} onSelected={onInstallatieSelected} onClose={onGoBack} />
        </div>
      }
    </div>
  )
}
