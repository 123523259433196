import React from 'react'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { useNavigate, useParams } from 'react-router-dom'
import { DimensieForm } from './DimensieForm'
import useApi, { Status } from 'services/UseApi'
import { IDimensieUpdateOrAdd } from './DimensieApi'
import { AvailableRoutes } from 'AppRouter'

const DimensiesEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  if (!parseInt(id ?? '')) {
    throw new Error('Invalid id')
  }

  const url = `dimensies/${id}`
  const waardenUrl = AvailableRoutes.Dimensiewaarden_List.replace(':dimensieId', id ?? '')

  const { state } = useApi<IDimensieUpdateOrAdd>(url, 'GET')
  const { state: executeState, execute: update } = useApi<IDimensieUpdateOrAdd>(url, 'PUT', false)

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Waarden', onClick: () => navigate(waardenUrl), icon: 'Forward', iconOnly: true }
  ]

  return (
    <MainTemplate title='Dimensies' subTitle='bewerken' commandItems={commandItems} error={state.error || executeState.error }>
      <DimensieForm onSubmit={(data) => update(data, ()=> navigate(-1))} isExecuting={executeState.status === Status.loading} data={state.data} onCancel={() => navigate(-1)} />
    </MainTemplate>
  )
}

export default DimensiesEdit