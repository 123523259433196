import {
  DefaultButton,
  DetailsRow,
  IButtonStyles,
  IColumn,
  IconButton,
  IDetailsHeaderStyles,
  IDetailsListProps,
  IDetailsListStyles,
  IDetailsRowStyles,
  mergeStyleSets,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Text
} from '@fluentui/react'
import React, { useState } from 'react'
import { DetailsHeader, DetailsList } from '@fluentui/react/lib/DetailsList'
import { PagedSearch } from '../VgtPagedTable/VgtPagedTable'

export interface VgtTreeViewTableProps<T> {
  isLoading: boolean;
  height?: string;
  items?: T[];
  columns: IColumn[];
  firstChildColumns?: IColumn[];
  secondChildColumns?: IColumn[];
  firstChildData?: (item: T) => any[];
  secondChildData?: (item: T) => any[];
  pagingInfo: PagedSearch;
  getKey: (item: T) => number;
  onPageChanged: (page: number) => void;
  onSortChanged: (key: string, direction: 'asc' | 'desc') => void;
  onItemInvoked?: (item: T) => void;
  onSelectionChanged?: (selection: number[]) => void;
}

const classNames = mergeStyleSets({
  containerStyles: {
    height: '70vh',
    overflow: 'auto',
    '@media (min-height: 1080px)': {
      height: '80vh'
    }
  }
})

const tableStyles: Partial<IDetailsListStyles> = {
  root: {
    paddingLeft: 75
  }
}
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    width: 30,
    height: 'auto'
  },
  descriptionHovered: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}

const onRenderDetailsHeader: IDetailsListProps['onRenderDetailsHeader'] = props => {
  const detailsHeaderStyles: Partial<IDetailsHeaderStyles> = {
    root: { padding: 0 }
  }

  return props ? <DetailsHeader {...props} styles={detailsHeaderStyles} /> : null
}

export function VgtTreeViewTable<T>(props: VgtTreeViewTableProps<T>) {
  const { firstChildColumns, secondChildColumns, firstChildData, secondChildData } = props
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({})

  const toggleRow = (id: number) => {
    setExpandedRows(prev => ({ ...prev, [id]: !prev[id] }))
  }

  const onRenderRow: IDetailsListProps['onRenderRow'] = rowProps => {
    if (!rowProps) return null

    const { item } = rowProps
    const customStyles: Partial<IDetailsRowStyles> = {
      root: { backgroundColor: rowProps.itemIndex % 2 === 0 ? '#f7f7f7' : '#ffffff' }
    }

    return (
      <>
        <DetailsRow
          {...rowProps}
          styles={customStyles}
        />
        {expandedRows[props.getKey(item)] && firstChildData && firstChildData(item).length > 0 && (
          <DetailsList
            items={firstChildData(item)}
            columns={firstChildColumns}
            onRenderDetailsHeader={onRenderDetailsHeader}
            styles={tableStyles}
            compact={true}
          />
        )}
        {expandedRows[props.getKey(item)] && secondChildData && secondChildData(item).length > 0 && (
          <DetailsList
            items={secondChildData(item)}
            columns={secondChildColumns}
            onRenderDetailsHeader={onRenderDetailsHeader}
            styles={tableStyles}
            compact={true}
          />
        )}
      </>
    )
  }

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => selectionChangedHandler()
    })
  )

  const selectionChangedHandler = () => {
    if (props.onSelectionChanged) {
      const selectedItems = selection.getSelection() as T[]
      const selectedIds = selectedItems.map(item => props.getKey(item))
      props.onSelectionChanged(selectedIds)
    }
  }

  const setSortingOnColumns = (columns: IColumn[]) => {

    const { sortKey, sortDirection } = props.pagingInfo
    return columns.map(column => {
      if (column.fieldName === sortKey) {
        column.isSorted = true
        column.isSortedDescending = sortDirection === 'desc'
      } else {
        column.isSorted = false
        column.isSortedDescending = false
      }
      return column
    })
  }

  const columns = [
    {
      key: 'AddRemoveIcon',
      name: '',
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: T) => {
        const hasChildrenData = firstChildData && firstChildData(item)?.length > 0
        const hasChildren2Data = secondChildData && secondChildData(item)?.length > 0

        return hasChildrenData || hasChildren2Data ? (
          <IconButton
            iconProps={{ iconName: expandedRows[props.getKey(item)] ? 'Remove' : 'Add' }}
            onClick={() => toggleRow(props.getKey(item))}
            styles={iconButtonStyles}
          />
        ) : null
      }
    } as IColumn
  ]

  if (props.columns) {
    columns.push(...props.columns)
  }

  return (
    <div className={classNames.containerStyles}>
      <ShimmeredDetailsList
        items={props.items || []}
        columns={setSortingOnColumns(columns)}
        selectionMode={SelectionMode.multiple}
        selection={selection}
        compact={true}
        onRenderRow={onRenderRow}
        onRenderDetailsHeader={onRenderDetailsHeader}
        getKey={(item: T) => props.getKey(item)?.toString()}
        onItemInvoked={props.onItemInvoked}
        enableShimmer={props.isLoading}
        shimmerLines={props.pagingInfo.pageSize}
        onColumnHeaderClick={(e, column) => {
          if (props.onSortChanged && column?.fieldName) {
            const key = column.fieldName
            const direction = column.isSortedDescending ? 'asc' : 'desc'
            props.onSortChanged(key, direction)
          }
        }}
      />

      <div style={{ display: 'flex', justifyContent: 'center', padding: 12 }}>
        <DefaultButton
          iconProps={{ iconName: 'previous' }}
          disabled={!props.pagingInfo.hasPreviousPage}
          onClick={() => props.onPageChanged(props.pagingInfo.currentPage - 1)}
        />
        <Text style={{ paddingLeft: 12, paddingTop: 4, paddingRight: 12 }}>
          {props.pagingInfo.totalCount} resultaten.
          Pagina {props.pagingInfo.currentPage} van {props.pagingInfo.totalPages}
        </Text>
        <DefaultButton
          iconProps={{ iconName: 'next' }}
          disabled={!props.pagingInfo.hasNextPage}
          onClick={() => props.onPageChanged(props.pagingInfo.currentPage + 1)}
          style={{ paddingLeft: 12 }}
        />
      </div>
    </div>
  )
}
