import { getPropertyName } from 'lib/interfaceUtils'
import { selectAll, clearResults, fetchAll } from './vgeContractVgeSlice'
import {
  DetailsList,
  IColumn,
  mergeStyleSets,
  Panel,
  PanelType,
  SelectionMode,
  Selection,
  DefaultButton,
  SearchBox,
  Stack,
  Sticky,
  StickyPositionType,
} from '@fluentui/react'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { useForm } from 'react-hook-form'
import { debounce } from 'lodash'
import { IVge } from 'features/vge/vgeSlice'

export enum SearchType {
  metAdres,
  zonderAdres,
  terrein,
}

interface IEditPanelProps {
  gebouwIds: number[]
  isOpen: boolean
  dismissPanel: any
  onSelect: (vges: IVge[]) => void
  excludeIds: number[]
  searchType: SearchType
}

const classNames = mergeStyleSets({
  wrapper: {
    position: 'relative',
    margin: '10px',
  },
  filter: {
    backgroundColor: 'white',
    paddingBottom: 20,
    maxWidth: 300,
  },
  header: {
    margin: 0,
    backgroundColor: 'white',
  },
  row: {
    display: 'inline-block',
  },
})

const createFilteredVges = (vges: IVge[], type: SearchType, excludeIds: number[]) => {
  const filteredOnId = vges.filter(vge => excludeIds.findIndex(v => v === vge.id) === -1)
  const totalFilter = filteredOnId.filter(vge => {
    if (type === SearchType.metAdres) return vge.baGid !== undefined
    return vge.baGid === undefined
  })
  return totalFilter
}

const SelectVgesPanel: React.FC<IEditPanelProps> = props => {
  const unfilteredVges = useSelector(selectAll)
  const vges = useMemo(() => createFilteredVges(unfilteredVges, props.searchType, props.excludeIds), [unfilteredVges, props.excludeIds])
  const dispatch = useDispatch()
  const [currentSearchValue, setCurrentSearchValue] = useState<string | undefined>('')

  const loadData = (filter?: string | undefined) => dispatch(fetchAll({ filter: filter, gebouwIds: props.gebouwIds }))

  useEffect(() => {
    if (props.isOpen) loadData(undefined)
  }, [props.isOpen])

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IVge>({ mode: 'onChange' })

  const clearSearch = () => {
    setCurrentSearchValue('')
    dispatch(clearResults())
  }

  const closePanel = () => {
    clearSearch()
    props.dismissPanel()
  }

  const onCancel = () => {
    closePanel()
  }

  useEffect(() => {
    if (props.isOpen) clearSearch()
  }, [props.isOpen])

  const [selection] = React.useState<Selection>(() => {
    const s = new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selection.count,
      getKey: (item: { id: string | number }) => item.id,
    })

    return s
  })

  const onSubmit = () => {
    if (selection.count > 0) {
      const items = selection.getSelection() as IVge[]
      props.onSelect(items)
      closePanel()
    }
  }

  const columns = [
    {
      name: 'Eenheiddetailsoort',
      fieldName: getPropertyName<IVge>('srt'),
      key: getPropertyName<IVge>('srt'),
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
    } as IColumn,
    {
      name: 'Adres',
      fieldName: getPropertyName<IVge>('str'),
      key: getPropertyName<IVge>('str'),
      minWidth: 50,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: IVge) => (
        <div>
          {item.str} {item.nr} {item.ltr} {item.nrT} {item.pc} {item.wpl}
        </div>
      ),
    } as IColumn,
    {
      name: 'Adresaanduiding',
      fieldName: getPropertyName<IVge>('aa'),
      key: getPropertyName<IVge>('aa'),
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
    } as IColumn,
  ] as IColumn[]

  const onRenderFooterContent = () => {
    return (
      <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
        <DefaultButton text="Koppelen" type="submit" primary disabled={isSubmitting} onClick={onSubmit} />
        <DefaultButton text="Annuleren" onClick={onCancel} />
      </Stack>
    )
  }

  const delayedSearch = debounce(eventData => loadData(eventData), 500)

  const onSearchBoxChanged = (newValue: string | undefined) => {
    setCurrentSearchValue(newValue)
    if (newValue && newValue.length >= 2) {
      delayedSearch(newValue)
    }
  }

  return (
    <Panel
      type={PanelType.medium}
      headerText="Koppelen vastgoedeenheden"
      isOpen={props.isOpen}
      onDismiss={onCancel}
      closeButtonAriaLabel="Sluiten"
      onRenderFooterContent={onRenderFooterContent}
    >
      <div className={classNames.wrapper}>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <SearchBox placeholder="Zoek op adres" onChanged={onSearchBoxChanged} onClear={clearSearch} value={currentSearchValue} autoComplete="off" />
          </Sticky>

          <DetailsList items={vges} selection={selection} columns={columns} />
          <br />
          <br />
        </form>
      </div>
    </Panel>
  )
}

export default SelectVgesPanel
