import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState';
import { PagedEntities } from 'interfaces/pagedEntities';
import { IEntityPageObjectResponse } from 'lib/sliceSupport';
import { invokeFetch } from 'services/apiClient';
import { RootState } from 'store';
import * as yup from 'yup'
import ILEmDOEntity from '../common/lemdoEntity';

export const OnderhoudsTaakErpSchema = yup.object().shape({
  code: yup.string().required('Code is verplicht').max(50, 'Maximale lengte is 50'),
  omschrijving: yup.string().required('Omschrijving is verplicht').max(50, 'Maximale lengte is 50')
})

export interface IOnderhoudsTaak extends ILEmDOEntity{
    onderhoudstaakErpId: number
    code: string
    omschrijving: string
}

const entityAdapter = createEntityAdapter<IOnderhoudsTaak>({
    selectId: e => e.onderhoudstaakErpId,
    sortComparer: (a, b) => a.code.localeCompare(b.code)
})

const baseUrl = '/OnderhoudsTaakErp'
const basePrefix = 'OnderhoudsTaakErp'
const getSliceState = (state: RootState) => state.onderhoudsTaakErp

export interface IOnderhoudsTaakArgs {
    filter?: string
}

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (args: IOnderhoudsTaakArgs, thunkAPI) => {
    return await invokeFetch<IEntityPageObjectResponse<IOnderhoudsTaak>>(thunkAPI, 'GET', `${baseUrl}?Filter=${args.filter}`)
  })

  export const updateOnderhoudsTaakErp = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity:IOnderhoudsTaak, thunkAPI) => {
    return await invokeFetch<IOnderhoudsTaak>(thunkAPI, 'PUT', `${baseUrl}/${entity.onderhoudstaakErpId}`, entity)
  })

  export const addOnderhoudsTaakErp = createAsyncThunk(`${basePrefix}/addStatus`, async (entity:IOnderhoudsTaak, thunkAPI) => {
    return await invokeFetch<IOnderhoudsTaak>(thunkAPI, 'POST', baseUrl, entity)
  })

  export const deleteOnderhoudsTaakErp = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
  })

  const setPendingState = (state: EntityState<IOnderhoudsTaak> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<IOnderhoudsTaak> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<IOnderhoudsTaak> & IEntityAdapterState, action: any) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }
  
  export const OnderhoudsTaakErpSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),
  
    reducers: {
      clearError: state => {
        state.error = null
      },
      select: (state, action: PayloadAction<number | undefined>) => {
        state.selectedId = action.payload
      },
      clearSelection: state => {
        state.selectedId = undefined
      },
      add: entityAdapter.addOne,
      modify: entityAdapter.upsertOne,
      removeMany: entityAdapter.removeMany,
      setAll: entityAdapter.setAll,
    },
  
    extraReducers: builder => {
      builder.addCase(fetchAll.pending, state => setPendingState(state))
      builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IOnderhoudsTaak>>) => {
        setSucceededState(state)
        entityAdapter.setAll(state, action.payload.items)
      })
      builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(updateOnderhoudsTaakErp.pending, state => setPendingState(state))
      builder.addCase(updateOnderhoudsTaakErp.fulfilled, (state, action: PayloadAction<IOnderhoudsTaak>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(updateOnderhoudsTaakErp.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(addOnderhoudsTaakErp.pending, state => setPendingState(state))
      builder.addCase(addOnderhoudsTaakErp.fulfilled, (state, action: PayloadAction<IOnderhoudsTaak>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(addOnderhoudsTaakErp.rejected, (state, action) => {
        setRejectedState(state, action)
      })
      builder.addCase(deleteOnderhoudsTaakErp.pending, state => setPendingState(state))
      builder.addCase(deleteOnderhoudsTaakErp.fulfilled, (state, action) => {
        entityAdapter.removeMany(state, action.meta.arg)
        setSucceededState(state)
      })
      builder.addCase(deleteOnderhoudsTaakErp.rejected, (state, action) => setRejectedState(state, action))
    },
  })
  
  export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
  }

  export const getLoadingState = (state: RootState) => state.onderhoudsTaakErp.status
  export const { removeMany, select, clearSelection } = OnderhoudsTaakErpSlice.actions
  export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.onderhoudsTaakErp)


  export default OnderhoudsTaakErpSlice.reducer
  