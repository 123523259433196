import { PageIdDefinition, PAGES } from "pages"

export interface INavigationStreetProps {
  straat?: string
  huisnummer?: string
  huisnummerToevoeging?: string
  huisletter?: string
  postcode?: string
  woonplaats?: string
  baGid?: number
}
export interface INavigationPostcodeProps {
  postcode?: string
  huisnummer?: string
}

export interface INavigationKadasterProps {
  kadastraleAanduiding?: string
}

export interface INavigationVboProps {
  vboId?: string
}

export const NavigateToBagviewerByStreet = (props: INavigationStreetProps) => {
  let url: string
  if(props.baGid){
    url = `https://bagviewer.kadaster.nl/lvbag/bag-viewer/?objectId=${props.baGid}`
  }else {
    url = `https://bagviewer.kadaster.nl/lvbag/bag-viewer/index.html#?searchQuery=${props?.straat ?? ''}%20${props.huisnummer ?? ''}${props.huisletter ?? ''}%20${props.postcode ?? ''}%20${props.woonplaats ?? ''}`
  }
  window.open(url, '_blank')
}
export const NavigateToBagviewerByPostalCode = (props: INavigationPostcodeProps) => {
  const url: string = `https://bagviewer.kadaster.nl/lvbag/bag-viewer/index.html#?searchQuery=${props.postcode}%20${props.huisnummer}`

  window.open(url, '_blank')
}
export const NavigateToBagviewerByVbo = (props: INavigationVboProps) => {
  const url: string = `https://bagviewer.kadaster.nl/lvbag/bag-viewer/index.html#?searchQuery=${props.vboId}`

  window.open(url, '_blank')
}

const ExtractKadasterParts = (kadastraleAanduiding: string) => {
  if (kadastraleAanduiding.indexOf('-') >= 0) {
    const [gemeente, sectie, perceelNummer] = kadastraleAanduiding.split('-')
    return { gemeente, sectie, perceelNummer }
  } else {
    const gemeente = kadastraleAanduiding.substring(0, 5)
    const sectie = kadastraleAanduiding.substring(5, 7).trim()
    const perceelNummer = kadastraleAanduiding.substring(7, 12)
    return { gemeente, sectie, perceelNummer }
  }
}

export const NavigateToVge = (vgeId?: number) => {
  if (vgeId) {
    const baseAddress = window.location.href.substring(0, window.location.href.indexOf('/', 8))
    const url: string = baseAddress + PAGES[PageIdDefinition.vge.edit].route.replace(':id', vgeId.toString())
    window.open(url, '_blank')
  }
}

export const NavigateToKadastraleKaart = (props: INavigationKadasterProps) => {
  if (props.kadastraleAanduiding) {
    const url: string = createKadastraleKaartUrl(props.kadastraleAanduiding)
    window.open(url, '_blank')
  }
}

export const NavigateToWaardeLoket = (props: INavigationPostcodeProps) => {
  const url: string = `https://bagviewer.kadaster.nl/lvbag/bag-viewer/index.html#?searchQuery=${props.postcode}%20${props.huisnummer}`

  window.open(url, '_blank')
}

export const NavigateToGoogleMaps = (props: INavigationStreetProps) => {
  const url: string = `https://www.google.com/maps/search/?api=1&query=${props.woonplaats}%20${props.straat}%20${props.huisnummer}%20${props.huisnummerToevoeging}%20${props.huisletter}`

  window.open(url, '_blank')
}

export const NavigateToStreetSmartMaps = (props: INavigationStreetProps) => {
  const url: string = `https://atlas.cyclomedia.com/panoramarendering/RenderByAddress/NL/${props.straat} ${props.huisnummer}%20${props.woonplaats}%20${props.postcode}/?width=1920&height=1080&hfov=90&apiKey=GzsR6zEFO7wN-vMdePw9-XQFS_E1OL4dCzUN-jUB6rU9ir4pObEFmPs16f4Ne_wt`

  window.open(url, '_blank')
}

export const createKadastraleKaartUrl = (kadastraleAanduiding: string) => {
  const aanduiding = ExtractKadasterParts(kadastraleAanduiding)
  return `https://kadastralekaart.com/kaart/perceel/${aanduiding.gemeente}/${aanduiding.sectie}/${aanduiding.perceelNummer}`
}
