import { useNavigate, useParams } from 'react-router-dom'
import { EditParams } from '../../vhe/VheEdit'
import React from 'react'
import { zodiosHooks } from '../../../api/ApiClient'
import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import InputCard from '../../../components/InputCard'
import locatie from '../../vge/icons/Vastgoedtabel_Icons_BAG-locatie.png'
import ReadOnlyField from '../../../components/ReadOnlyField'
import { VgtTooltip } from '../../../components/VgtTooltip'
import useRoles from '../../../services/UseRoles'
import { availableUrls } from '../../../routing/AvailableUrls'

export const HuurcontractbepalingShow = () => {

  const navigate = useNavigate()
  const params = useParams()
  const validatedParams = EditParams.parse(params)
  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true }
  ]

  const { data } = zodiosHooks.useGetApiHuurcontractbepalingId({ params: { id: validatedParams.id } }, { enabled: validatedParams.id > 0 })

  return (
    <MainTemplate title="Huurcontractbepaling" subTitle="Bewerken" commandItems={commandItems}>
      <InputCard title="Huurcontractbepaling" width={'1000px'} icon={locatie}>
        <ReadOnlyField title="Naam" value={data?.naam} />
        <ReadOnlyField title="Titel" value={data?.titel} />
        <ReadOnlyField title="Content" value={data?.content} />
      </InputCard>
    </MainTemplate>
  )
}

interface Huurcontractbepaling {
  id: number;
  type: string | null;
  code: string | null;
}

interface HuurcontractbepalingenTooltipProps {
  huurcontractbepalingen: Huurcontractbepaling[] | null | undefined;
  labelWidth?: number
}

export const HuurcontractbepalingenTooltip: React.FC<HuurcontractbepalingenTooltipProps> = props => {
  const navigate = useNavigate();
  const { isVerhuur } = useRoles();
  const data = props.huurcontractbepalingen ? props.huurcontractbepalingen.map(item => ({ key: item.id.toString(), value: item.type + ": " + item.code })) : [];

  return (
    <VgtTooltip
      tooltipId={'huurcontractbepaling'}
      title={'Huurcontractbepaling(en)'}
      data={data}
      onClick={isVerhuur ? (key) => navigate(availableUrls.Huurcontractbepaling_Edit.replace(':id', key)) : (key) => navigate(availableUrls.Huurcontractbepaling_Show.replace(':id', key))}
      labelWidth={props.labelWidth ?? 165}
    />
  );
}
