import { store } from '../store'
import { getApiToken } from '../lib/auth'

const fetchUtils = (uri: string): Promise<string> => {
  const { apiEndpoint } = store.getState().app.configuration

  const endpoint = `${apiEndpoint}${uri}`

  return new Promise((resolve) => {
    getApiToken()
      .then(response => {
        fetch(endpoint, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${response}`,
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            response.blob().then(blob =>
              resolve(window.URL.createObjectURL(blob))
            )
          })
      })
  })
}

export default fetchUtils
