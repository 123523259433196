import React, { useEffect, useState } from 'react'
import { Panel, PanelType } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { getPropertyName } from 'lib/interfaceUtils'
import VgtTextField from 'components/VgtTextField'
import useRoles from 'services/UseRoles'
import { CreateDefectLocatieCommand, DefectLocatieDto, zodiosHooks } from '../../../../api/ApiClient'
import VgtDropdown from '../../../../components/FluentDropdown'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { z } from 'zod'
import VgtNumberField from '../../../../components/FluentNumberField'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

type CreateDefectLocatieCommandType = z.infer<typeof CreateDefectLocatieCommand>
type DefectLocatieDtoType = z.infer<typeof DefectLocatieDto>

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  fetchDefectLocatieItems: () => void
  selectedDefectLocatie: DefectLocatieDtoType | undefined
}

const EditPanelDefectLocatieType: React.FC<IEditPanelProps> = ({
                                                                 isOpen,
                                                                 fetchDefectLocatieItems,
                                                                 dismissPanel,
                                                                 selectedDefectLocatie
                                                               }) => {
  const [title, setTitle] = useState('Defectlocatie')
  const { isAdmin } = useRoles()
  const [error, setError] = useState<string>()

  const defaultDefectLocatie = {
    id: selectedDefectLocatie?.id ?? 0,
    code: selectedDefectLocatie?.code ?? '',
    naam: selectedDefectLocatie?.naam ?? '',
    fonticoonId: selectedDefectLocatie?.fonticoonId ?? null,
    fonticoonNaam: selectedDefectLocatie?.fonticoonNaam ?? null,
    eindDatum: selectedDefectLocatie?.eindDatum ?? null,
    veraVersie: selectedDefectLocatie?.veraVersie ?? null
  } as CreateDefectLocatieCommandType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateDefectLocatieCommandType>({ mode: 'all' })

  const onDataSubmitttedSuccesfully = () => {
    fetchDefectLocatieItems()
    dismissPanel()
  }

  const { data: FontIcoonOptions } = zodiosHooks.useGetApiFonticoon()

  const { mutate: postDefectLocatie, isLoading: isPostingDefectLocatie } = zodiosHooks.usePostDefectLocatie({}, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: putDefectLocatie, isLoading: isUpdatingDefectLocatie } = zodiosHooks.usePutDefectLocatieId(
    { params: { id: selectedDefectLocatie?.id! } }, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const fontIcons = FontIcoonOptions?.items?.filter(item => item.naam !== null)
    .map(item => ({ key: item.fonticoonId, text: item.omschrijving! }))

  useEffect(() => {
    setTitle(`Defectlocatie ${selectedDefectLocatie ? (isAdmin ? 'wijzigen' : '') : 'toevoegen'}`)
    if (selectedDefectLocatie) {
      reset(selectedDefectLocatie)
    } else {
      reset({})
    }
  }, [selectedDefectLocatie])

  const onCancel = () => {
    dismissPanel()
  }

  const onSubmit = (data: CreateDefectLocatieCommandType) => {
    if (!data.fonticoonId) {
      data.fonticoonId = null
    }

    if (selectedDefectLocatie) {
      data.id = selectedDefectLocatie.id
      putDefectLocatie({ ...defaultDefectLocatie, ...data })
    } else {
      postDefectLocatie({ ...defaultDefectLocatie, ...data })
    }
  }

  return (
    <Panel type={PanelType.medium} headerText={title} isOpen={isOpen} onDismiss={onCancel}
           closeButtonAriaLabel='Sluiten'>
      <ErrorMessageBar error={error} />
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <VgtTextField
          label='Code'
          name={getPropertyName<CreateDefectLocatieCommandType>('code')}
          register={register}
          control={control}
          errors={errors}
          required
          readOnly={!isAdmin}
        />
        <VgtTextField
          label='Naam'
          name={getPropertyName<CreateDefectLocatieCommandType>('naam')}
          register={register}
          control={control}
          errors={errors}
          required
          readOnly={!isAdmin}
        />
        <VgtNumberField
          name={getPropertyName<CreateDefectLocatieCommandType>('veraVersie')}
          label='Vera versie'
          control={control}
          readOnly={!isAdmin}
          errors={errors}
          required
        />
        <VgtDropdown
          options={fontIcons}
          label='Fonticoon'
          name={getPropertyName<CreateDefectLocatieCommandType>('fonticoonId')}
          register={register}
          control={control}
          errors={errors}
          readOnly={!isAdmin}
        />
        <OkCancelButtonStack
          isSubmitting={isSubmitting}
          isLoading={isPostingDefectLocatie || isUpdatingDefectLocatie}
          onOkClick={handleSubmit(onSubmit)}
          onCancelClick={onCancel}
          isWriter={isAdmin}
        />
      </form>
    </Panel>
  )
}

export default EditPanelDefectLocatieType
