import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, EntityState } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import { ILEmDOCodeEntity  } from '../common/lemdoEntity'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'
import * as yup from 'yup'


export const woningverbeteringToestemmingSchema = yup.object().shape({
    code: yup.string().required('Code is verplicht')
})

export interface IWoningverbeteringToestemming extends ILEmDOCodeEntity  { 
    woningverbeteringToestemmingId: number,
}

const entityAdapter = createEntityAdapter<IWoningverbeteringToestemming>({
    sortComparer: (a, b) => a.code?.localeCompare(b.code),
    selectId: x => x.woningverbeteringToestemmingId
  })
  
  const baseUrl = '/woningverbeteringToestemming'
  const basePrefix = 'woningverbeteringToestemming'
  const getSliceState = (state: RootState) => state.woningverbeteringToestemmingen
  
  export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<PagedEntities<IWoningverbeteringToestemming>>(thunkAPI, 'GET', `${baseUrl}`)
  })
  
  export const updateWoningverbeteringToestemming = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: IWoningverbeteringToestemming, thunkAPI) => {
    return await invokeFetch<IWoningverbeteringToestemming>(thunkAPI, 'PUT', `${baseUrl}/${entity.woningverbeteringToestemmingId}`, entity)
  })
  
  export const addWoningverbeteringToestemming = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: IWoningverbeteringToestemming, thunkAPI) => {
    return await invokeFetch<IWoningverbeteringToestemming>(thunkAPI, 'POST', baseUrl, entity)
  })
  
  export const deleteWoningverbeteringToestemming = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
  })
  
  const setPendingState = (state: EntityState<IWoningverbeteringToestemming> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<IWoningverbeteringToestemming> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<IWoningverbeteringToestemming> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }
  
  export const WoningverbeteringToestemmingSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),
  
    reducers: {
      clearError: state => {
        state.error = null
      },
      select: (state, action: PayloadAction<string | undefined>) => {
        state.selectedId = action.payload
      },
      clearSelection: state => {
        state.selectedId = undefined
      },
      add: entityAdapter.addOne,
      modify: entityAdapter.upsertOne,
      removeMany: entityAdapter.removeMany,
      setAll: entityAdapter.setAll,
    },
  
    extraReducers: builder => {
      builder.addCase(fetchAll.pending, state => setPendingState(state))
      builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IWoningverbeteringToestemming>>) => {
        setSucceededState(state)
        entityAdapter.setAll(state, action.payload.items)
      })
      builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(updateWoningverbeteringToestemming.pending, state => setPendingState(state))
      builder.addCase(updateWoningverbeteringToestemming.fulfilled, (state, action: PayloadAction<IWoningverbeteringToestemming>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(updateWoningverbeteringToestemming.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(addWoningverbeteringToestemming.pending, state => setPendingState(state))
      builder.addCase(addWoningverbeteringToestemming.fulfilled, (state, action: PayloadAction<IWoningverbeteringToestemming>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(addWoningverbeteringToestemming.rejected, (state, action) => {
        setRejectedState(state, action)
      })
      builder.addCase(deleteWoningverbeteringToestemming.pending, state => setPendingState(state))
      builder.addCase(deleteWoningverbeteringToestemming.fulfilled, (state, action) => {
        entityAdapter.removeMany(state, action.meta.arg)
        setSucceededState(state)
      })
      builder.addCase(deleteWoningverbeteringToestemming.rejected, (state, action) => setRejectedState(state, action))
    },
  })
  
  export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
  }
  
  export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
  }
  
  export const getErrorState = (state: RootState) => {
    return getSliceState(state).error
  }
  
  export const { add, modify, removeMany, select, clearSelection } = WoningverbeteringToestemmingSlice.actions
  export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.woningverbeteringToestemmingen)
  
  export default WoningverbeteringToestemmingSlice.reducer
