import React, { useEffect, useMemo, useState } from 'react'
import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, ScrollablePane, SelectionMode, Spinner } from '@fluentui/react'
import { Dictionary } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import downloadFile from 'services/downloadFile'
import UploadPanel from 'components/UploadPanel'
import { useBoolean } from '@fluentui/react-hooks'
import { MatrixCheckBox, MatrixCell } from '../common/matrixSelection'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { zodiosHooks } from '../../../../api/ApiClient'

const createMatrix = (locaties: any[] , locatieElementen: any[]) => {
  if (!locaties || locaties.length === 0 ) {
    return [];
  }
  const result = locaties.map<any>(l => {
    const selectionForLocationRow = locatieElementen?.filter(le => le.defectlocatieId === l.id)
    const dict = {} as Dictionary<string>

    selectionForLocationRow?.forEach(l => (dict[l.woningverbeteringId.toString()] = '1'))

    return {
      rowId: l.id,
      cells: dict,
    }
  })
  return result
}

const DefectlocatiesWoningverbetering: React.FC<{}> = () => {
  const navigate = useNavigate()
  const [items, setItems] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)

  const { data: woningverbetering, invalidate: refreshWoningverbetering, isLoading: woningverbeteringLoading  } = zodiosHooks.useGetWoningverbeteringlistItems();
  const { data: defectLocatieWoningverbeteringmatrix, invalidate: refreshDefectLocatieWoningverbeteringmatrix, isLoading: defectLocatieWoningverbeteringmatrixLoading   } = zodiosHooks.useGetDefectLocatieWoningverbeteringmatrix();
  const { data: woningverbeteringdefectlocations, invalidate: refreshWoningverbeteringdefectlocations, isLoading: woningverbeteringdefectlocationsLoading  } = zodiosHooks.useGetDefectLocatieWoningverbeteringdefectlocations();

  const refreshItems = () => {
    refreshWoningverbeteringdefectlocations();
    refreshWoningverbetering();
    refreshDefectLocatieWoningverbeteringmatrix();
  }

  useEffect(() => {
    setLoading( woningverbeteringLoading || defectLocatieWoningverbeteringmatrixLoading || woningverbeteringdefectlocationsLoading )
  }, [woningverbeteringLoading, defectLocatieWoningverbeteringmatrixLoading, woningverbeteringdefectlocationsLoading ])

  useEffect(() => {
    setItems(createMatrix(woningverbeteringdefectlocations!, defectLocatieWoningverbeteringmatrix!))
  }, [woningverbeteringdefectlocations, defectLocatieWoningverbeteringmatrix])

  const categorieColumns = woningverbetering?.map<IColumn>((s: any) => {
    return {
      key: s.woningverbeteringId.toString(),
      name: s.omschrijving,
      minWidth: 25,
      maxWidth: 25,
      isResizable: false,
      isRowHeader: true,
      headerClassName: 'headerClass',
      onRender: (item: any) => <MatrixCheckBox id={s.woningverbeteringId} titles={item.cells} /> ,
    } as IColumn
  })

  const columns = [
    {
      key: 'locatie',
      isRowHeader: true,
      minWidth: 250,
      maxWidth: 400,
      onRender: (item: any) => <MatrixCell text={createLocationText(item.rowId)} />,
    } as IColumn,
  ] as IColumn[]

  if (categorieColumns) {
    columns.push(...categorieColumns);
  }

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
      {
        text: 'Exporteer naar Excel',
        onClick: () => {
          downloadFile('/DefectLocatieWoningverbetering/export', 'Defectlocaties en woningverbeteringen.xlsx')
        },
        icon: 'Export',
      },
      {  text: 'Importeer van Excel', onClick: () => openPanel(), icon: 'Import' },
    ],
    []
  )

  const createLocationText = (id: number) => {
    const woningverbeteringdefectlocation = woningverbeteringdefectlocations?.filter(l => l.id === id);
    if (woningverbeteringdefectlocation && woningverbeteringdefectlocation.length !== 0) {
      return `${woningverbeteringdefectlocation[0].code} - ${woningverbeteringdefectlocation[0].naam}`;
    }
    return '';
  }


  const handleDismiss = () => {
    dismissPanel()
    refreshItems()
  }

  const onSuccessUploadExcel = () => {
    handleDismiss()
  };
  
  return loading ? (
    <div className="spinnerWrapper">
      <Spinner>Laden...</Spinner>
    </div>
  ) : (
    <MainTemplate title="Defectlocaties || Woningverbetering" subTitle="Overzicht" commandItems={commandItems}>
      <UploadPanel title="Importeer wijzigen" dismissPanel={handleDismiss} isOpen={isOpen} 
                   uploadEndpoint={'/DefectLocatieWoningverbetering/import'}
                    onSuccess={onSuccessUploadExcel} />
      <div style={{ height: '85vh', position: 'relative' }}>
        <ScrollablePane>
          <DetailsList
            columns={columns}
            items={items}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            constrainMode={ConstrainMode.unconstrained}
            compact={true}
            className="matrixGrid"
          />
        </ScrollablePane>
      </div>
    </MainTemplate>
  )
}

export default DefectlocatiesWoningverbetering
