import { Checkbox } from '@fluentui/react'
import React from 'react'
import { IMatrixCellProps, IMatrixCheckBoxProps } from './matrixSelectionTypes'

export const MatrixCell: React.FC<IMatrixCellProps> = props => {
  return <span title={props.text}>{props.text}</span>
}

export const MatrixCheckBox: React.FC<IMatrixCheckBoxProps> = props => {
  const strId = props.id.toString()
  const isChecked = props.titles[strId] !== undefined
  return <Checkbox checked={isChecked} disabled={true} />
}
