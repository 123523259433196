import React, { useState } from 'react'
import { IColumn, Link, SelectionMode, ShimmeredDetailsList } from '@fluentui/react'
import { createColumn } from '../../../../../lib/gridHelper'
import { getPropertyName } from '../../../../../lib/interfaceUtils'
import { useNavigate } from 'react-router-dom'
import { usePagedParams } from '../../../../../services/usePagedParams'
import { useBoolean } from '@fluentui/react-hooks'
import { GebouwDeelContactCategorieListDto, zodiosHooks } from '../../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../../services/HandleError'
import { z } from 'zod'
import { GebouwDeelContactCategorieFormPanel } from './GebouwDeelContactCategorieFormPanel'

type GebouwDeelContactCategorieListDtoType = z.infer<typeof GebouwDeelContactCategorieListDto>;

export interface IGebouwDeelContactCategorieListProps {
  gebouwDeelId: number,
}

export const GebouwDeelContactCategorieList: React.FC<IGebouwDeelContactCategorieListProps> = props => {

  useNavigate()
  const { validatedSearchParams, setOrder } = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [contactCategorieId, setContactCategorieId] = useState<number>(0)
  const [contactCategorieNaam, setContactCategorieNaam] = useState<string>('Selected Categorie Naam')
  const [contactgegevenId, setContactgegevenId] = useState<number>(0)
  const [error, setError] = React.useState<string>()

  const { data, invalidate, isLoading } = zodiosHooks.useGetGebouwDeelContactCategorieGebouwDeelIdcontactCategorien({
    params: { GebouwDeelId: props.gebouwDeelId, gebouwDeelId: props.gebouwDeelId },
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const handleItemInvoked = (contactCategorieId: string, contactCategorieNaam: string | undefined, contactgegevenId: string | undefined) => {
    if (contactCategorieId && contactCategorieNaam) {
      setContactCategorieId(parseInt(contactCategorieId))
      setContactCategorieNaam(contactCategorieNaam)
    }
    if (contactgegevenId) {
      setContactgegevenId(parseInt(contactgegevenId))
    }
    openPanel()
  }

  const handleDismissPanel = () => {
    setContactCategorieId(0)
    setContactgegevenId(0)
    dismissPanel()
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Categorie', getPropertyName<GebouwDeelContactCategorieListDtoType>('contactCategorieNaam'), 'XL', true,
        (item: GebouwDeelContactCategorieListDtoType) => (
          <Link key={item.contactCategorieId}
                onClick={() => handleItemInvoked(item.contactCategorieId.toString(), item.contactCategorieNaam?.toString(), item.contactgegevenId?.toString())}>
            {item.contactCategorieNaam}
          </Link>
        )),
      createColumn('Naam', getPropertyName<GebouwDeelContactCategorieListDtoType>('contactgegevenNaam'), 'XL', false),
      createColumn('E-mailadres', getPropertyName<GebouwDeelContactCategorieListDtoType>('emailadres'), 'XL', false),
      createColumn('Telefoonnummer', getPropertyName<GebouwDeelContactCategorieListDtoType>('telefoonnummer'), 'XL', false)
    ]
  }, [])

  return (
    <div>
      {error && <div>{error}</div>}
      <GebouwDeelContactCategorieFormPanel gebouwDeelId={props.gebouwDeelId} contactCategorieId={contactCategorieId}
                                           contactgegevenId={contactgegevenId}
                                           contactCategorieNaam={contactCategorieNaam}
                                           dismissPanel={handleDismissPanel} isOpen={isPanelOpen}
                                           invalidate={invalidate} />
      <ShimmeredDetailsList
        items={data?.items ?? []}
        columns={columns}
        selectionMode={SelectionMode.none}
        getKey={(item) => item?.contactCategorieId}
        onItemInvoked={(e) => handleItemInvoked(e.contactCategorieId.toString(), e.contactCategorieNaam?.toString(), e.contactgegevenId?.toString())}
        enableShimmer={isLoading}
        shimmerLines={validatedSearchParams.pageSize}
        onRenderMissingItem={() => null}
        onColumnHeaderClick={(e, column) => {
          const key = column?.fieldName
          if (key) {
            const direction = column.isSortedDescending ? 'asc' : 'desc'
            setOrder(key, direction)
          }
        }}
      />
    </div>
  )
}