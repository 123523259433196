import React from 'react'
import { Controller } from 'react-hook-form'
import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react'
import { IVgtFieldPropsBase } from 'lib/IVgtFieldPropsBase'

interface IVgtMultiselectDropdownProps extends IVgtFieldPropsBase {
    name: string
    label: string
    control: any
    errors?: any
    required?: boolean
    disabled?: boolean
    options: IDropdownOption[] | undefined
    styles?: object
    onChange?: (key?: number | string, selected?: boolean) => void
    dropdownWidth?: number | 'auto'
    readOnly?: boolean
    defaultSelectedKeys?: number[] | string[]
}

const textFieldStyles: Partial<IDropdownStyles> = {
    root: {
        padding: '5px 0px 5px 12px',
        display: 'flex',
        width: '98%',
    },
    label: {
        fontWeight: 'Normal',
        width: '215px',
        flexGrow: '0',
        flexShrink: '0',
    },
    title: {
        borderWidth: '0px',
        paddingLeft: '4px',
    },
    dropdown: {
        width: '70%',
        fontWeight: 600,
    },
}

const VgtMultiselectDropdown: React.FC<IVgtMultiselectDropdownProps> = props => {
    return (
        <div hidden={props.showIf !== undefined && props.showIf === false}>
            <Controller
                name={props.name}
                control={props.control}
                render={({ onChange, value }) => (
                    <Dropdown
                        onChange={props.readOnly ? () => null : (e, option) => {
                            if (option?.selected) {
                                onChange([...value, option.key])
                            } else {
                                onChange(value.filter((v: any) => v !== option?.key))
                            }
                            if (props.onChange) props.onChange(option?.key, option?.selected)
                        }}
                        // defaultSelectedKeys={props.defaultSelectedKeys ? props.defaultSelectedKeys : value}
                        selectedKeys={value ? value : props.defaultSelectedKeys}
                        styles={props.styles ? props.styles : textFieldStyles}
                        label={props.label}
                        required={!!props.required}
                        placeholder=" --- "
                        options={props.options ? props.options : []}
                        errorMessage={props.errors[props.name]?.message}
                        disabled={props.disabled}
                        multiSelect
                        dropdownWidth={props.dropdownWidth}
                    />
                )}
            />
        </div>
    )
}
export default VgtMultiselectDropdown