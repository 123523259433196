import { IDropdownOption } from '@fluentui/react';
import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction, EntityId } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import * as yup from 'yup'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'
import { IEntityCoenCadCodes } from 'features/beheer/coencad/coenCadCode';

export interface ISanitair extends IEntityCoenCadCodes {
    id: number
    code: string
    voorziening: string
    eenheidId: number
    tooltip: string
    plusOne: boolean
    ingangsDatum: string
    eindDatum?: string
    elementElementDetailId?: number
}

export const sanitairSchema = yup.object().shape({
    code: yup.string().required('Code is verplicht').max(10, 'Maximale lengte is 10'),
    voorziening: yup.string().required('Voorziening is verplicht').max(100, 'Maximale lengte is 100'),
    eenheidId: yup.number().required('Eenheid is verplicht'),
    tooltip: yup.string().nullable().max(200, 'Maximale lengte is 200')
})

const entityAdapter = createEntityAdapter<ISanitair>({
    sortComparer: (a, b) => a.code?.localeCompare(b.code),
})

const baseUrl = '/sanitair'
const basePrefix = 'sanitair'
const getSliceState = (state: RootState) => state.sanitair


export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<PagedEntities<ISanitair>>(thunkAPI, 'GET', `${baseUrl}?top=50000`)
})

export const updateSanitairDetail = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: ISanitair, thunkAPI) => {
    return await invokeFetch<ISanitair>(thunkAPI, 'PUT', `${baseUrl}/${entity.id}`, entity)
})

export const addSanitairDetail = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: ISanitair, thunkAPI) => {
    return await invokeFetch<ISanitair>(thunkAPI, 'POST', baseUrl, entity)
})

export const deleteSanitairDetail = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
})

const setPendingState = (state: EntityState<ISanitair> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
}
const setSucceededState = (state: EntityState<ISanitair> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<ISanitair> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
}

export const sanitairlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),

    reducers: {
        clearError: state => {
            state.error = null
        },
        select: (state, action: PayloadAction<string | undefined>) => {
            state.selectedId = action.payload
        },
        clearSelection: state => {
            state.selectedId = undefined
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
    },

    extraReducers: builder => {
        builder.addCase(fetchAll.pending, state => setPendingState(state))
        builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<ISanitair>>) => {
            setSucceededState(state)
            entityAdapter.setAll(state, action.payload.items)
        })
        builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
        builder.addCase(updateSanitairDetail.pending, state => setPendingState(state))
        builder.addCase(updateSanitairDetail.fulfilled, (state, action: PayloadAction<ISanitair>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(updateSanitairDetail.rejected, (state, action) => setRejectedState(state, action))
        builder.addCase(addSanitairDetail.pending, state => setPendingState(state))
        builder.addCase(addSanitairDetail.fulfilled, (state, action: PayloadAction<ISanitair>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(addSanitairDetail.rejected, (state, action) => {
            setRejectedState(state, action)
        })
        builder.addCase(deleteSanitairDetail.pending, (state) => setPendingState(state))
        builder.addCase(deleteSanitairDetail.fulfilled, (state, action) => {
            entityAdapter.removeMany(state, action.meta.arg)
            setSucceededState(state)
        })
        builder.addCase(deleteSanitairDetail.rejected, (state, action) => setRejectedState(state, action))
    },
})

export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
}

export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
}

export const getErrorState = (state: RootState) => {
    return getSliceState(state).error
}

export const { add, modify, removeMany, select, clearSelection } = sanitairlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.sanitair)

export const getDropDownValues = (state: RootState) => state.sanitair.ids.map((id: EntityId) => {
    const entity = state.sanitair.entities[id]
    return {
        key: id,
        text: `${entity?.code}`
    } as IDropdownOption
})

export default sanitairlice.reducer
