import React from 'react'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAll, selectStatus } from './store/actions/app/rolesSlice'
import { useBoolean } from '@fluentui/react-hooks'
import { DefaultButton, TeachingBubble } from '@fluentui/react'

interface IProtectedRouteProps {
  component: React.ComponentType<any>
  authorizedRole: boolean
  path: string
  exact?: boolean
  additionalProps?: any
}

export const NotAuthorized: React.FC = () => {
  const roles = useSelector(selectAll)
  const status = useSelector(selectStatus)
  const [roleInfoVisible, { toggle: toggleRoleInfoVisible }] = useBoolean(false)

  return (
    <>
      {status === 'succeeded' && (
        <div style={{ padding: 10 }}>
          <span
            style={{ verticalAlign: 'middle', height: 32 }}>U bent niet geautoriseerd om deze pagina te tonen.</span>
          <DefaultButton
            id={'roleInfoButton'}
            iconProps={{ iconName: 'Info' }}
            styles={{ root: { border: 0, width: 25, padding: 0, minWidth: 25, height: 32 } }}
            onClick={toggleRoleInfoVisible}
          />
        </div>
      )}
      {roleInfoVisible && (
        <TeachingBubble target={`#${'roleInfoButton'}`} onDismiss={toggleRoleInfoVisible} headline='Toegewezen rollen'>
          <ul>
            {roles.map(x => (
              <li>{x.id}</li>
            ))}
          </ul>
          {roles.length === 0 ? 'Er zijn geen rollen aan u toegewezen door de applicatiebeheerder.' : ''}
        </TeachingBubble>
      )}
    </>
  )
}

export const ProtectedRoute: React.FC<IProtectedRouteProps> =
  ({ component: Component, authorizedRole, path, exact, additionalProps }) => {
    return <Route
      path={path}
      element={!authorizedRole ? <NotAuthorized /> : <Component {...additionalProps} />}
      // element={props => {
      //   if (authorizedRole) {
      //     return <Component {...props} {...additionalProps} />
      //   } else return <NotAuthorized />
      // }}
    />
  }
