import { IPanelProps, Panel } from '@fluentui/react'
import ErrorMessageBar from '../ErrorMessageBar/ErrorMessageBar'
import React from 'react'

interface IFluentPanelProps extends IPanelProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
  onDismiss?: () => void;
  error?: string;
}

const FluentPanelFooter: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div>{children}</div>
}

const FluentPanel: React.FC<IFluentPanelProps> & { Footer: React.FC<{ children: React.ReactNode }> } =
  ({
     children,
     footer,
     ...props
   }) => {

    const footerContent = React.Children.toArray(children).find(
      (child: any) => child.type === FluentPanelFooter
    )

    const panelContent = React.Children.toArray(children).filter(
      (child: any) => child.type !== FluentPanelFooter
    )

    const onRenderFooterContent = () => {
      return footerContent ? (
        <div>
          {footerContent}
        </div>
      ) : null
    }

    return (
      <Panel {...props}
             closeButtonAriaLabel="Sluiten"
             onRenderFooterContent={onRenderFooterContent}
             isFooterAtBottom={true}
      >

        <div style={{ marginBottom: 16, borderBottom: '1px solid rgb(207, 207, 207)' }} />

        <ErrorMessageBar error={props.error} />

        <form>
          {panelContent}
        </form>
      </Panel>
    )
  }

FluentPanel.Footer = FluentPanelFooter

export default FluentPanel