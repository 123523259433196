import React, { useEffect, useRef, useState } from 'react'
import {
  IStackTokens, MessageBar, ScrollablePane,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem
} from '@fluentui/react'
import { zodiosHooks } from '../../../api/ApiClient'
import { SectionHeader, SectionLine } from '../../../components/Section'

export const stackTokens: IStackTokens = { childrenGap: 20 }

const dateFormat = (dateString: string | null) => {
  const date = dateString ? new Date(dateString) : null
  if (!date) {
    return null
  }

  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  let year = date.getFullYear().toString();

  if (day.length < 2) {
    day = '0' + day;
  }
  if (month.length < 2) {
    month = '0' + month;
  }

  return day + '-' + month + '-' + year;
}

const EpOnlineCard = ({ vgeId }) => {
  const { data, isLoading } = zodiosHooks.useGetVgeIdpandcertificaat(
    { params: { vgeId: vgeId } },
    {
      enabled: vgeId !== 0
    })

  const listRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(400)
  useEffect(() => {
    const handleResize = () => {
      const headersHeight = 0
      const availableHeight = window.innerHeight - headersHeight

      if (listRef.current) {
        setHeight(availableHeight - listRef.current.offsetTop)
      } else {
        setHeight(availableHeight - 300)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      {isLoading &&
        <div style={{ paddingTop: 20 }}><Spinner size={SpinnerSize.large}></Spinner></div>}

      {!isLoading && !data &&
        <div style={{ paddingTop: 20, marginRight: '24px' }}>
          <MessageBar messageBarType={5}>
          Geen gegevens beschikbaar voor deze vastgoedeenheid.
          </MessageBar>
        </div>
      }

      {data &&
        <div ref={listRef} style={{ position: 'relative', height: `${height}px`, overflow: 'auto', marginTop: '-12px' }}>
          <ScrollablePane styles={{ contentContainer: { paddingRight: '20px' } }}>
            <Stack horizontal tokens={stackTokens} style={{ display: 'flex', justifyContent: 'space-between', marginRight: '24px', marginBottom: '12px' }}>
              <StackItem style={{ width: '46%' }}>
                <SectionHeader title="Algemeen">
                  <SectionLine title="Certificaathouder" value={data.certificaathouder} />
                  <SectionLine title="Berekeningstype" value={data.berekeningstype} />
                  <SectionLine title="Opnamedatum" value={dateFormat(data.opnamedatum)} />
                  <SectionLine title="Registratiedatum" value={dateFormat(data.registratiedatum)} />
                  <SectionLine title="Meting geldig tot" value={dateFormat(data.metingGeldigTot)} />
                  <SectionLine title="Mutatiedatum" value={dateFormat(data.mutatiedatum)} />
                  <SectionLine title="Mutatievolgnummer" value={data.mutatievolgnummer} />
                </SectionHeader>
                <SectionHeader title="Gebouw">
                  <SectionLine title="Detailaanduiding" value={data.detailaanduiding} />
                  <SectionLine title="Gebouwklasse" value={data.gebouwklasseNaam} />
                  <SectionLine title="Gebouwtype" value={data.gebouwtype} />
                  <SectionLine title="Gebouwsubtype" value={data.gebouwsubtype} />
                  <SectionLine title="Pand" value={data.bagpandid} />
                  <SectionLine title="Bouwjaar" value={data.bouwjaar} />
                  <SectionLine title="Compactheid" value={data.compactheid} valueAlignment={'right'} />
                  <SectionLine title="Op basis van referentiegebouw" value={data.isOpBasisVanReferentieGebouw} />
                  <SectionLine title="SBI code" value={data.sbiCode} />
                </SectionHeader>
                <SectionHeader title="Labelinformatie">
                  <SectionLine title="Energieprestatieindex" value={data.energieprestatieindex} valueAlignment={'right'} />
                  <SectionLine title="El geldig voor WWS" value={data.energieprestatieForfaitair} valueAlignment={'right'} />
                  <SectionLine title="Labelletter" value={data.labelletter} />
                </SectionHeader>
              </StackItem>
              <StackItem style={{ width: '49%' }}>
                <SectionHeader title="NTA-8800">
                  <SectionLine title="Aanleiding registratie gebouw" value={data.status} />
                  <SectionLine title="Opnametype" value={data.opnametype} />
                  <SectionLine title="Gebruiksoppervlakte thermische zone" value={data.gebruiksoppervlakte} valueAlignment={'right'} />
                  <SectionLine title="Berekende CO2 emissie" value={data.berekendeCO2Emissie} valueAlignment={'right'} />
                  <SectionLine title="Berekend energieverbruik" value={data.berekendeEnergieverbruik} valueAlignment={'right'} />
                </SectionHeader>
                <SectionHeader title="Energiebehoefte">
                  <SectionLine title="Energiebehoefte" value={data.energiebehoefte} valueAlignment={'right'} />
                  <SectionLine title="Warmtebehoefte" value={data.warmtebehoefte} valueAlignment={'right'} />
                  <SectionLine title="Eis energiebehoefte" value={data.eisEnergiebehoefte} valueAlignment={'right'} />
                </SectionHeader>
                <SectionHeader title="Fossiele energie">
                  <SectionLine title="Primaire fossiele energie" value={data.primaireFossieleEnergie} valueAlignment={'right'} />
                  <SectionLine title="Eis primaire fossiele energie" value={data.eisPrimaireFossieleEnergie} valueAlignment={'right'} />
                  <SectionLine title="Primaire fossiele energie EMG forfaitair" value={data.primaireFossieleEnergieEmgForfaitair} valueAlignment={'right'} />
                </SectionHeader>
                <SectionHeader title="Hernieuwbare energie">
                  <SectionLine title="Aandeel hernieuwbare energie" value={data.aandeelHernieuwbareEnergie} valueAlignment={'right'} />
                  <SectionLine title="Eis aandeel hernieuwbare energie" value={data.eisAandeelHernieuwbareEnergie} valueAlignment={'right'} />
                  <SectionLine title="Aandeel hernieuwbare energie EMG forfaitair" value={data.aandeelHernieuwbareEnergieEmgForfaitair} valueAlignment={'right'} />
                </SectionHeader>
                <SectionHeader title="Temperatuuroverschrijding">
                  <SectionLine title="Temperatuur overschrijding" value={data.temperatuuroverschrijding} valueAlignment={'right'} />
                  <SectionLine title="Eis temperatuuroverschrijding" value={data.eisTemperatuuroverschrijding} valueAlignment={'right'} />
                </SectionHeader>
              </StackItem>
            </Stack>
          </ScrollablePane>
        </div>
      }
    </>
  )
}

export default EpOnlineCard