import React, { useState } from 'react'
import { IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../../../lib/gridHelper'
import { getPropertyName } from '../../../../../lib/interfaceUtils'
import { useNavigate } from 'react-router-dom'
import { usePagedParams } from '../../../../../services/usePagedParams'
import { useBoolean } from '@fluentui/react-hooks'
import { GebouwContactCategorieListDto, zodiosHooks } from '../../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../../services/HandleError'
import { z } from 'zod'
import { GebouwContactCategorieFormPanel } from './GebouwContactCategorieFormPanel'
import ErrorMessageBar from '../../../../../components/ErrorMessageBar/ErrorMessageBar'
import { VgtPagedTable } from '../../../../../components/VgtPagedTable/VgtPagedTable'


type GebouwContactCategorieListDtoType = z.infer<typeof GebouwContactCategorieListDto>;

export interface IGebouwContactCategorieListProps {
  gebouwId: number,
}

export const GebouwContactCategorieList: React.FC<IGebouwContactCategorieListProps> = props => {
  useNavigate()
  const { validatedSearchParams, setOrder, setPage } = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [contactCategorieId, setContactCategorieId] = useState<number>(0)
  const [contactCategorieNaam, setContactCategorieNaam] = useState<string>('Selected Categorie Naam')
  const [contactgegevenId, setContactgegevenId] = useState<number>(0)
  const [error, setError] = React.useState<string>()

  const { data, invalidate, isLoading } = zodiosHooks.useGetGebouwContactCategorieGebouwIdcontactCategorien({
    params: { GebouwId: props.gebouwId, gebouwId: props.gebouwId },
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const handleItemInvoked = (contactCategorieId: string, contactCategorieNaam: string | undefined, contactgegevenId: string | undefined) => {
    if (contactCategorieId && contactCategorieNaam) {
      setContactCategorieId(parseInt(contactCategorieId))
      setContactCategorieNaam(contactCategorieNaam)
    }
    if (contactgegevenId) {
      setContactgegevenId(parseInt(contactgegevenId))
    }
    openPanel()
  }

  const handleDismissPanel = () => {
    setContactCategorieId(0)
    setContactgegevenId(0)
    dismissPanel()
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Categorie', getPropertyName<GebouwContactCategorieListDtoType>('contactCategorieNaam'), 'XL', true,
        (item: GebouwContactCategorieListDtoType) => (
          <Link key={item.contactCategorieId}
                onClick={() => handleItemInvoked(item.contactCategorieId.toString(), item.contactCategorieNaam?.toString(), item.contactgegevenId?.toString())}>
            {item.contactCategorieNaam}
          </Link>
        )),
      createColumn('Naam', getPropertyName<GebouwContactCategorieListDtoType>('contactgegevenNaam'), 'XL', false),
      createColumn('E-mailadres', getPropertyName<GebouwContactCategorieListDtoType>('emailadres'), 'XL', false),
      createColumn('Telefoonnummer', getPropertyName<GebouwContactCategorieListDtoType>('telefoonnummer'), 'XL', false)
    ]
  }, [])

  return (

    <div>
      <ErrorMessageBar error={error} />
      <GebouwContactCategorieFormPanel
        gebouwId={props.gebouwId}
        contactCategorieId={contactCategorieId}
        contactgegevenId={contactgegevenId}
        contactCategorieNaam={contactCategorieNaam}
        dismissPanel={handleDismissPanel}
        isOpen={isPanelOpen} invalidate={invalidate}
      />

      <VgtPagedTable
        isLoading={isLoading}
        items={data?.items ?? []}
        columns={columns}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage ?? false,
          hasPreviousPage: data?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        getKey={(item) => item?.contactCategorieId}
        onItemInvoked={(e) => handleItemInvoked(e.contactCategorieId.toString(), e.contactCategorieNaam?.toString(), e.contactgegevenId?.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </div>
  )
}