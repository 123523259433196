import InputCard from 'components/InputCard'
import React, { FC, useEffect } from 'react'
import { createKadastraleKaartUrl } from 'services/navigationService'
import { stackTokens } from './adresseerbaarObject'
import { IGerechtigde, IPerceel } from './perceelSlice'
import { IColumn, SelectionMode, Stack, StackItem } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import FluentTable from 'components/FluentTable'
import ReadOnlyField from 'components/ReadOnlyField'

export interface IPerceelProps {
  perceel: IPerceel | undefined
}

interface IKadastraleIncludeProps {
  kadastraleAanduidingObject: string
}

interface IGerechtigdeProps {
  gerechtigden: IGerechtigde[]
}

const panelWidth = '850px'

const KadasterInclude: FC<IKadastraleIncludeProps> = (props: IKadastraleIncludeProps) => {
  const urlKadaster = createKadastraleKaartUrl(props.kadastraleAanduidingObject)

  useEffect(() => {
    const frame = window.frames['frameKadastraleKaart']
    if (frame) frame.contentWindow.location.replace(urlKadaster)
  }, [])

  return urlKadaster ?
    <iframe title='Kadastrale kaart' height={panelWidth} id='frameKadastraleKaart' style={{ border: 0 }}
            src='#'></iframe> : <></>
}

const GerechtigdenGrid: React.FC<IGerechtigdeProps> = props => {
  const handleItemInvoked = (_id: string) => {
  }
  const handleGetKey = (item: IGerechtigde) => item.nrGerechtigde.toString()

  const columns: IColumn[] = [
    {
      key: getPropertyName<IGerechtigde>('nrGerechtigde'),
      name: 'Nummer',
      fieldName: getPropertyName<IGerechtigde>('nrGerechtigde'),
      minWidth: 75,
      maxWidth: 75,
      isResizable: true,
      isRowHeader: true,
      isSorted: true,
      data: 'string'
    },
    {
      key: getPropertyName<IGerechtigde>('gerechtigde'),
      name: 'Gerechtigde',
      fieldName: getPropertyName<IGerechtigde>('gerechtigde'),
      minWidth: 100,
      maxWidth: 700,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<IGerechtigde>('rechtCode'),
      name: 'Rechtcode',
      fieldName: getPropertyName<IGerechtigde>('rechtCode'),
      minWidth: 100,
      maxWidth: 700,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<IGerechtigde>('volgNummer'),
      name: 'Rechtvlgnr.',
      fieldName: getPropertyName<IGerechtigde>('volgNummer'),
      minWidth: 75,
      maxWidth: 75,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<IGerechtigde>('aandeel'),
      name: 'Aandeel',
      fieldName: getPropertyName<IGerechtigde>('aandeel'),
      minWidth: 75,
      maxWidth: 75,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<IGerechtigde>('bronDocument'),
      name: 'Brondocument',
      fieldName: getPropertyName<IGerechtigde>('bronDocument'),
      minWidth: 120,
      maxWidth: 120,
      isResizable: false,
      isRowHeader: true,
      data: 'string'
    }
  ]
  return (
    <div style={{ height: 400, width: '100%' }}>
      <FluentTable
        cellStyles={{ cursor: 'default' }}
        selectionMode={SelectionMode.none}
        columns={columns}
        items={props.gerechtigden ?? []}
        loading={false}
        onGetKey={handleGetKey}
        onItemInvoked={handleItemInvoked}
      />
    </div>
  )
}

const PerceelGegevens: React.FC<IPerceelProps> = props => {
  const datumDoc = props.perceel == null ? '' : new Date(props.perceel?.datumBronDocument).toLocaleDateString()

  return props.perceel ? (
    <div style={{ overflow: 'auto', height: '83.5vh' }}>
      <Stack horizontal tokens={stackTokens}>
        <StackItem>
          <Stack horizontal={false} tokens={stackTokens}>
            <StackItem>
              <InputCard title='Perceel' width={panelWidth} contentPaddingLeft={10}>
                <ReadOnlyField title='Perceel' value={props.perceel.perceel} labelWidth={200} />
                <ReadOnlyField title='Kadastrale gemeente' value={props.perceel.kadastraleGemeente}
                               labelWidth={200} />
                <ReadOnlyField title='Complexindex' value={props.perceel.complexIndex} labelWidth={200} />
                <ReadOnlyField title='Omschrijving deelperceel' value='' labelWidth={200} />
                <ReadOnlyField title='Grootte' value={props.perceel.grootte} labelWidth={200} />
                <ReadOnlyField title='Nr. Gerechtigde' value={props.perceel.nrGerechtigde} labelWidth={200} />
                <ReadOnlyField title='Naam Gerechtigde' value={props.perceel.naamGerechtigde} labelWidth={200} />
                <ReadOnlyField title='Brondocument' value={props.perceel.bronDocument} labelWidth={200} />
                <ReadOnlyField title='Datum brondocument' value={datumDoc} labelWidth={200} />
                <ReadOnlyField title='Cultuurcode onbebouwd' value={props.perceel.cultuurCodeOnbebouwd}
                               labelWidth={200} />
                <ReadOnlyField title='Cultuurcode bebouwd' value={props.perceel.cultuurCodeOnbebouwd}
                               labelWidth={200} />
                <ReadOnlyField title='XY-coördinaten'
                               value={props.perceel.latitude ? `${props.perceel.latitude}, ${props.perceel.longitude}` : ''}
                               labelWidth={200} />
                <ReadOnlyField title='Kadastrale aanduiding object' value={props.perceel.kadastraleAanduidingObject}
                               labelWidth={200} />
                <ReadOnlyField title='Kadastrale aanduiding vlak' value={props.perceel.kadastraleAanduidingVlak}
                               labelWidth={200} />
              </InputCard>
            </StackItem>
            <StackItem>
              <InputCard title='Gerechtigden' width={panelWidth}>
                <GerechtigdenGrid gerechtigden={props?.perceel?.gerechtigden} />
              </InputCard>
            </StackItem>
          </Stack>
        </StackItem>
        {props?.perceel?.kadastraleAanduidingObject ? (
          <StackItem>
            <InputCard title='Kadastrale kaart' width='700px'>
              {props.perceel.kadastraleAanduidingVlak &&
                <KadasterInclude kadastraleAanduidingObject={props.perceel.kadastraleAanduidingVlak} />}
            </InputCard>
          </StackItem>
        ) : (
          <></>
        )}
      </Stack>
    </div>
  ) : (
    <></>
  )
}

export default PerceelGegevens
