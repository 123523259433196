import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { CommandBar, IColumn, ICommandBarItemProps, Panel, PanelType, SearchBox } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import {
  HuurcontractbepalingGebouwclusterListDto, NietGekoppeldGebouwclusterListDto,
  zodiosHooks
} from '../../../api/ApiClient'
import { usePagedParams } from '../../../services/usePagedParams'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import { createCommandButton } from '../../../lib/commandBarHelper'
import commandBarStyles from '../../../styles/commandBarStyles'
import { EditParams } from '../../vhe/VheEdit'
import { useParams } from 'react-router-dom'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import { useBoolean } from '@fluentui/react-hooks'
import { getTitleAndMessage } from '../../../services/HandleError'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'

type HuurcontractbepalingGebouwclusterListDtoType = z.infer<typeof HuurcontractbepalingGebouwclusterListDto>;

export interface IHuurcontractbepalingList {
  setCount: (value: number) => void
}


export const HuurcontractbepalingGebouwclusterList: React.FC<IHuurcontractbepalingList> = props => {
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const params = useParams()
  const validatedParams = EditParams.parse(params)
  const [error, setError] = useState<string>()

  const {
    data: huurcontractbepalingGebouwclusterData,
    invalidate: invalidateHuurcontractbepalingGebouwclusterData,
    isLoading
  } = zodiosHooks.useGetApiHuurcontractbepalinglistGebouwclusters({
    queries: {
      HuurcontractbepalingId: validatedParams.id,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })
  useEffect(() => {
    if (huurcontractbepalingGebouwclusterData) props.setCount(huurcontractbepalingGebouwclusterData.totalCount)
  }, [huurcontractbepalingGebouwclusterData])

  const [isGebouwclusterPanelOpen, {
    setTrue: openGebouwclusterPanel,
    setFalse: dismissGebouwclusterPanel
  }] = useBoolean(false)

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Gebouwcluster', getPropertyName<HuurcontractbepalingGebouwclusterListDtoType>('gebouwClusterNaam'), 'L', false)
    ]
  }, [])

  const AddGebouwclusterToSelection = () => {
    openGebouwclusterPanel()
  }

  const RemoveGebouwclusterFromSelection = () => {
    deleteRows({huurcontractbepalingId: validatedParams.id,  itemsToDelete: selectedIds })
  }

  const { mutate: deleteRows } = zodiosHooks.useDeleteApiHuurcontractbepalingontkoppelingGebouwcluster({}, {
    onSuccess: () => invalidateHuurcontractbepalingGebouwclusterData(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const commandbarItems = [
    createCommandButton('Koppelen', 'PlugConnected', AddGebouwclusterToSelection),
    createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveGebouwclusterFromSelection, selectedIds.length !== 0)
  ] as ICommandBarItemProps[]


  return (
   <div>
     <CommandBar items={commandbarItems} styles={commandBarStyles} />
     <ErrorMessageBar error={error} />
     <NietGekoppeldGebouwclusterFormPanel huurcontractbepalingId={validatedParams.id} isOpen={isGebouwclusterPanelOpen}
                                          refresh={invalidateHuurcontractbepalingGebouwclusterData}
                                          dismissPanel={dismissGebouwclusterPanel} />
     <VgtPagedTable
       items={huurcontractbepalingGebouwclusterData?.items ?? undefined}
       height={'203'}
       pagingInfo={{
         pageSize: validatedSearchParams.pageSize,
         currentPage: validatedSearchParams.page,
         totalCount: huurcontractbepalingGebouwclusterData?.totalCount ?? 0,
         totalPages: huurcontractbepalingGebouwclusterData?.totalPages ?? 0,
         hasNextPage: huurcontractbepalingGebouwclusterData?.hasNextPage ?? false,
         hasPreviousPage: huurcontractbepalingGebouwclusterData?.hasPreviousPage ?? false,
         sortKey: validatedSearchParams.sortKey || 'naam',
         sortDirection: validatedSearchParams.sortDirection || 'asc'
       }}
       columns={columns}
       isLoading={isLoading}
       getKey={(item) => item?.gebouwClusterId}
       onSelectionChanged={setSelectedIds}
       onPageChanged={setPage}
       onSortChanged={setOrder}
     />
   </div>
  )
}

export interface INietGekoppeldFormPanelProps {
  huurcontractbepalingId : number
  refresh: () => void;
  isOpen: boolean;
  dismissPanel: any;
}

type NietGekoppeldGebouwclusterListDtoType = z.infer<typeof NietGekoppeldGebouwclusterListDto>;

const NietGekoppeldGebouwclusterFormPanel :  React.FC<INietGekoppeldFormPanelProps> = props => {
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = React.useState<string>()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Naam', getPropertyName<NietGekoppeldGebouwclusterListDtoType>('gebouwclusterNaam'), 'L', false),
      createColumn('Code', getPropertyName<NietGekoppeldGebouwclusterListDtoType>('gebouwclusterCode'), 'L', false)
    ]
  }, [])


  const { data, invalidate, isLoading } = zodiosHooks.useGetApiHuurcontractbepalingnietGekoppeldGebouwclusters({
    queries: {
      HuurcontractbepalingId: props.huurcontractbepalingId,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const onCancel = () => {
    props.dismissPanel()
    props.refresh()
    invalidate()
  }

  const onRenderFooterContent = () => {
    return (
      <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                           onOkClick={onSubmit}
                           onCancelClick={onCancel} />
    )
  }


  const { mutate: addKoppelingGebouwcluster, isLoading: isSubmitting } = zodiosHooks.usePostApiHuurcontractbepalingkoppelingGebouwcluster(
    {}, { onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const onSubmit = () => {
    addKoppelingGebouwcluster({ huurcontractbepalingId: props.huurcontractbepalingId, gebouwClusterIds: selectedIds })
  }

  return (
    <Panel
      type={PanelType.large}
      headerText={'Koppelen gebouwcluster'}
      isOpen={props.isOpen}
      onDismiss={onCancel}
      closeButtonAriaLabel="Sluiten"
      onRenderFooterContent={onRenderFooterContent}>
      <ErrorMessageBar error={error} />
      <SearchBox placeholder="Filter" styles={{ root: { width: '400px', margin: '6px' } }}
                 value={validatedSearchParams.filter}
                 onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                 onSearch={() => invalidate()} />
      <VgtPagedTable
        items={data?.items ?? undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage ?? false,
          hasPreviousPage: data?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.gebouwClusterId}
        onSelectionChanged={setSelectedIds}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </Panel>
  )
}