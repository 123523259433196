import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import { IEntityAdapterState } from 'interfaces/entityAdapterState'

export interface IKadastraalSubject {
    id: string,
    naam: string,
    adres: string,
}

export interface IKadastraalSubjectState {
    selectedId: string | undefined
    status: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: string | null
    searchFilter: string
}

export const KadastraalSubjectInitState: IKadastraalSubjectState = {
    selectedId: undefined,
    status: 'idle',
    error: null,
    searchFilter: ''
}

const entityAdapter = createEntityAdapter<IKadastraalSubject>({
    sortComparer: (a, b) => a.naam?.localeCompare(b.naam),
    selectId: (KadastraalSubject) => KadastraalSubject.id
})

const baseUrl = '/kadastraalsubject'
const basePrefix = 'kadastraalSubject'
const getSliceState = (state: RootState) => state.kadastraleSubjecten

export interface IFetchKadastraleSubjectenArgs {
    filter?: string
}

export const fetchGerechtigden = createAsyncThunk(`${basePrefix}/fetchStatus`, async (args: IFetchKadastraleSubjectenArgs, thunkAPI) => {
    const uri = `${baseUrl}?Filter=${args.filter}`
    return await invokeFetch<PagedEntities<IKadastraalSubject>>(thunkAPI, 'GET', uri)
})

const setPendingSate = (state: EntityState<IKadastraalSubject> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
}
const setSucceededState = (state: EntityState<IKadastraalSubject> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<IKadastraalSubject> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
}

export const KadastraleObjecten = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(KadastraalSubjectInitState),


    reducers: {
        clearError: state => {
            state.error = null
        },
        select: (state, action: PayloadAction<string | undefined>) => {
            state.selectedId = action.payload
        },
        clearSelection: state => {
            state.selectedId = undefined
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.searchFilter = action.payload.toUpperCase()
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
    },

    extraReducers: builder => {
        builder.addCase(fetchGerechtigden.pending, state => setPendingSate(state))
        builder.addCase(fetchGerechtigden.fulfilled, (state, action: PayloadAction<PagedEntities<IKadastraalSubject>>) => {
            setSucceededState(state)
            entityAdapter.setAll(state, action.payload.items)
        })
        builder.addCase(fetchGerechtigden.rejected, (state, action) => setRejectedState(state, action))
    },
})

export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
}

export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
}
export const getErrorState = (state: RootState) => {
    return getSliceState(state).error
}

export const { add, modify, removeMany, select, clearSelection, clearError, setSearchFilter, setAll } = KadastraleObjecten.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => getSliceState(state))

export const getFilterState = (state: RootState) => {
    return getSliceState(state).searchFilter
}

export default KadastraleObjecten.reducer
