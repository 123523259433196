import InputCard from "components/InputCard";
import { ReadOnlyField } from "./../IReadonlyFieldProps";
import { IVve } from "./../vveSlice";

export const BeheerCard: React.FC<{ vve?: IVve }> = ({ vve }) => <InputCard title="Beheer" width="100%">
    <ReadOnlyField title="Administratief beheerder" value={vve?.administratiefBeheerder} />
    <ReadOnlyField title="Contactpersoon administratief beheerder" value={vve?.administratiefBeheerderContactpersoon} />
    <ReadOnlyField title="Registratie VvE bij administratief beheerder" value={vve?.codeInExterneVveRegistratie} />
    <ReadOnlyField title="Technisch beheerder" value={vve?.technischBeheerder} />
    <ReadOnlyField title="Contactpersoon technisch beheerder" value={vve?.technischBeheerderContactpersoon} />
    <ReadOnlyField title="Vertegenwoordiger woningcorporatie" value={vve?.vertegenwoordigerCorporatie} />
    <ReadOnlyField title="Telefoonnummer" value={vve?.beheerTelefoon} />
    <ReadOnlyField title="Emailadres" value={vve?.beheerEmail} />
    <ReadOnlyField title="Intake door corporatie" value={vve?.intakeDoorCorporatie ? "Ja": "Nee"} />
</InputCard>;
