import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'

export interface IElementChoice {
  id: number
  code: string
  description: string
}

export interface IElementSuggestion extends IElementChoice {
  selected: boolean
}

export interface IElementSearchArgs {
  text: string
  baseElementId?: number
}

interface ISuggestionState {
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
}

const initState: ISuggestionState = {
  status: 'idle',
  error: null,
}

const entityAdapter = createEntityAdapter<IElementSuggestion>({})

const baseUrl = '/nlsfb'
const basePrefix = 'nlsfb'

export const fetchElements = createAsyncThunk(`${basePrefix}/findElements`, async (args: IElementSearchArgs, thunkAPI) => {
  let uri: string
  if (args.baseElementId) uri = `${baseUrl}/findElements?text=${args.text}&baseElementId=${args.baseElementId}`
  else uri = `${baseUrl}/findElements?text=${args.text}`
  return await invokeFetch<IElementSuggestion[]>(thunkAPI, 'GET', uri)
})

export const elementSuggestionsSlice = createSlice({
  name: 'elementSuggestions',
  initialState: entityAdapter.getInitialState(initState),

  reducers: {
    setSelection: (state, action: PayloadAction<number>) => {
      state.ids.forEach(id => {
        const entity = state.entities[id]
        if (entity) entity.selected = id === action.payload
      })
    },
    clearSuggestions: entityAdapter.removeAll,
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
  },

  extraReducers: builder => {
    builder.addCase(fetchElements.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(fetchElements.fulfilled, (state, action: PayloadAction<IElementSuggestion[]>) => {
      state.status = 'succeeded'
      entityAdapter.setAll(state, action.payload)
    })
    builder.addCase(fetchElements.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || null
    })
  },
})

export const { add, modify, removeMany, setSelection, clearSuggestions } = elementSuggestionsSlice.actions
export const {
  selectAll: selectAllSuggestions,
  selectEntities,
  selectById,
} = entityAdapter.getSelectors<RootState>(state => state.installatieGroepElementSuggestions)

export default elementSuggestionsSlice.reducer
