import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState';
import { PagedEntities } from 'interfaces/pagedEntities';
import { invokeFetch } from 'services/apiClient';
import { RootState } from 'store';
import * as yup from 'yup'

export const GarantieSchema = yup.object().shape({
  kostensoortId: yup.number().required('Kostensoort is verplicht')
})

export interface IGarantie {
    garantieId: number
    kostensoortId: number
    tenant_id: number
}

const entityAdapter = createEntityAdapter<IGarantie>({
    selectId: e => e.garantieId
})

const baseUrl = '/garantie'
const basePrefix = 'garantie'
const getSliceState = (state: RootState) => state.garanties

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<PagedEntities<IGarantie>>(thunkAPI, 'GET', `${baseUrl}`)
  })
  
  export const updateGarantie = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: IGarantie, thunkAPI) => {
    return await invokeFetch<IGarantie>(thunkAPI, 'PUT', `${baseUrl}/${entity.garantieId}`, entity)
  })
  
  export const addGarantie = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: IGarantie, thunkAPI) => {
    return await invokeFetch<IGarantie>(thunkAPI, 'POST', baseUrl, entity)
  })
  
  export const deleteGarantie = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
  })
  
  const setPendingState = (state: EntityState<IGarantie> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<IGarantie> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<IGarantie> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }
  
  export const garantieSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),
  
    reducers: {
      clearError: state => {
        state.error = null
      },
      select: (state, action: PayloadAction<string | undefined>) => {
        state.selectedId = action.payload
      },
      clearSelection: state => {
        state.selectedId = undefined
      },
      add: entityAdapter.addOne,
      modify: entityAdapter.upsertOne,
      removeMany: entityAdapter.removeMany,
      setAll: entityAdapter.setAll,
    },
  
    extraReducers: builder => {
      builder.addCase(fetchAll.pending, state => setPendingState(state))
      builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IGarantie>>) => {
        setSucceededState(state)
        entityAdapter.setAll(state, action.payload.items)
      })
      builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(updateGarantie.pending, state => setPendingState(state))
      builder.addCase(updateGarantie.fulfilled, (state, action: PayloadAction<IGarantie>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(updateGarantie.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(addGarantie.pending, state => setPendingState(state))
      builder.addCase(addGarantie.fulfilled, (state, action: PayloadAction<IGarantie>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(addGarantie.rejected, (state, action) => {
        setRejectedState(state, action)
      })
      builder.addCase(deleteGarantie.pending, state => setPendingState(state))
      builder.addCase(deleteGarantie.fulfilled, (state, action) => {
        entityAdapter.removeMany(state, action.meta.arg)
        setSucceededState(state)
      })
      builder.addCase(deleteGarantie.rejected, (state, action) => setRejectedState(state, action))
    },
  })
  
  export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
  }

  export const getLoadingState = (state: RootState) => state.garanties.status
  
  export const getErrorState = (state: RootState) => state.garanties.error
  
  export const { add, modify, removeMany, select, clearSelection } = garantieSlice.actions
  export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.garanties)
  
  export default garantieSlice.reducer
  