import { DefaultButton, MessageBar, MessageBarType, Panel, PanelType, Stack } from "@fluentui/react"
import VgtDropdown from "components/FluentDropdown"
import VgtTextField from "components/VgtTextField"
import { clearErrorState } from "features/vge/vgeSlice"
import { getPropertyName } from "lib/interfaceUtils"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"
import { getErrorState, getLoadingState, IVve, vveSchema } from '../vveSlice'
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import VgtCheckbox from '../../../../../components/FluentCheckbox'

export const VveEditPanel: React.FC<{ vve?: IVve, isOpen: boolean, dismissPanel: () => void, saveEntity: (vve: IVve) => void }> = ({ isOpen, dismissPanel, saveEntity, vve }) => {
    const dispatch = useAppDispatch()
    const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
    const fetchStatus = useSelector(getLoadingState)
    const navigate = useNavigate()

    const {
        control,
        register,
        reset,
        handleSubmit,
        formState: { isSubmitting, errors },
    } = useForm<IVve>({ resolver: yupResolver(vveSchema), mode: 'all' })

    const errorStatus = useSelector(getErrorState)

    const handleConfirmClick = () => {
        dispatch(clearErrorState())
    }

    const onCancel = vve?.id === 0 ? () => navigate(-1) : dismissPanel

    useEffect(() => {
        setCloseOnFulFilled(false)
    }, [])

    const onSubmit = data => {
        data.id = vve?.id
        saveEntity(data)
        setCloseOnFulFilled(true)
    }

    useEffect(() => {
        if (closeOnFulFilled && fetchStatus === 'succeeded') dismissPanel()
    }, [fetchStatus])

    return (
        <Panel type={PanelType.large} headerText={vve?.id === 0 ? "Toevoegen VvE" : "Wijzigen VvE"} isOpen={isOpen} onDismiss={() => onCancel()} closeButtonAriaLabel="Sluiten" onOpen={() => reset(vve)}>
            {errorStatus && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={handleConfirmClick} dismissButtonAriaLabel="Close">
                    <b>{errorStatus}</b>
                </MessageBar>
            )}
            <br />
            <form onSubmit={handleSubmit(onSubmit)}>
                <VgtTextField label="Naam volgens akte" name={getPropertyName<IVve>('naamOpAkte')} register={register} control={control} errors={errors}/>
                <VgtDropdown label="Type splitsing" name={getPropertyName<IVve>('typeSplitsing')} register={register} control={control} errors={errors} options={[{ key: "1", text: "Hoofdsplitsing" }, { key: "2", text: "Ondersplitsing" }]}/>
                <VgtTextField label="Oprichtingsdatum" name={getPropertyName<IVve>('oprichtingsdatum')} register={register} control={control} errors={errors} />
                <VgtTextField label="Handelsnaam" name={getPropertyName<IVve>('handelsnaam')} register={register} control={control} errors={errors} required />
                <VgtTextField label="KVK Nummer" name={getPropertyName<IVve>('kvkNummer')} register={register} control={control} errors={errors} />
                <VgtTextField label="Adres" name={getPropertyName<IVve>('straat')} register={register} control={control} errors={errors} />
                <VgtTextField label="Postcode" name={getPropertyName<IVve>('postcode')} register={register} control={control} errors={errors} />
                <VgtTextField label="Woonplaats" name={getPropertyName<IVve>('woonplaats')} register={register} control={control} errors={errors} />
                <br />
                <VgtTextField label="Externe code VvE bij adm. beh." name={getPropertyName<IVve>('codeInExterneVveRegistratie')} register={register} control={control} errors={errors} />
                <VgtTextField label="Vertegenwoordiger corporatie" name={getPropertyName<IVve>('vertegenwoordigerCorporatie')} register={register} control={control} errors={errors} />
                <VgtCheckbox label="Intake door corporatie" name={getPropertyName<IVve>('intakeDoorCorporatie')} register={register} control={control} errors={errors} />
                <br />
                    <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
                        <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} onClick={handleSubmit(onSubmit)} />
                        <DefaultButton text="Annuleren" onClick={onCancel} />
                    </Stack>
            </form>
        </Panel>)
}