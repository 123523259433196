import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import * as yup from 'yup'
import { entityAdapterInitState } from 'interfaces/entityAdapterState'
import { setRejectedState, setSucceededState, setPendingState } from 'lib/sliceSupport'
import { ICoenCadCode } from './coenCadCode'

export const coenCadSchema = yup.object().shape({
  key: yup.string().defined().required('CWB Key is verplicht').max(20, 'Maximale lengte is 20.'),
  code: yup.string().optional().nullable().max(100, 'De maxiale lengte is 100.'),
  description: yup.string().defined().required('Omschrijving is verplicht.').max(200, 'De maximale lengte is 200.'),
  groep: yup.string().optional().nullable().max(50, 'De maximale lengte is 50'),
  elementElementDetailId: yup.number().optional().nullable()
})

const basePrefix = 'coencad'
const baseUrl = '/api/coencad'

export const fetchAll = createAsyncThunk('coencad', async (_, thunkAPI) => {
  return await invokeFetch<PagedEntities<ICoenCadCode>>(thunkAPI, 'GET', `${baseUrl}?top=50000`)
})

export const updateCoenCadCode = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: ICoenCadCode, thunkAPI) => {
  return await invokeFetch<ICoenCadCode>(thunkAPI, 'PUT', `${baseUrl}`, entity)
})

export const addCoenCadCode = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: ICoenCadCode, thunkAPI) => {
  return await invokeFetch<ICoenCadCode>(thunkAPI, 'POST', baseUrl, entity)
})

export const deleteCoenCadCode = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
  return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
})

const entityAdapter = createEntityAdapter<ICoenCadCode>()

export const coenCadCodeSlice = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
    clearError: state => {
      state.error = null
    },
    select: (state, action: PayloadAction<string | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
  },

  extraReducers: builder => {
    builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<ICoenCadCode>>) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchAll.pending, state => setPendingState(state))

    builder.addCase(updateCoenCadCode.pending, state => setPendingState(state))
    builder.addCase(updateCoenCadCode.fulfilled, (state, action: PayloadAction<ICoenCadCode>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
    })
    builder.addCase(updateCoenCadCode.rejected, (state, action) => setRejectedState(state, action))
    builder.addCase(addCoenCadCode.pending, state => setPendingState(state))
    builder.addCase(addCoenCadCode.fulfilled, (state, action: PayloadAction<ICoenCadCode>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
    })
    builder.addCase(addCoenCadCode.rejected, (state, action) => {
        setRejectedState(state, action)
    })
    builder.addCase(deleteCoenCadCode.pending, (state) => setPendingState(state))
    builder.addCase(deleteCoenCadCode.fulfilled, (state, action) => {
        entityAdapter.removeMany(state, action.meta.arg)
        setSucceededState(state)
    })
    builder.addCase(deleteCoenCadCode.rejected, (state, action) => setRejectedState(state, action))    
  },
})

const getSliceState = (state: RootState) => state.coenCadCodes

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}

export const getSelectedEntity = (state: RootState) => {
  const selectedId = getSliceState(state).selectedId
  if (selectedId) return getSliceState(state).entities[selectedId]
  else return undefined
}

export const { add, modify, removeMany, select, clearSelection } = coenCadCodeSlice.actions
export const { selectAll, selectById } = entityAdapter.getSelectors<RootState>(state => state.coenCadCodes)

export default coenCadCodeSlice.reducer
