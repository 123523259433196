import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import useRoles from '../../../services/UseRoles'
import { usePagedParams } from '../../../services/usePagedParams'
import React, { useMemo, useState } from 'react'
import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { IColumn } from '@fluentui/react'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import { VoorzieningenListDto, zodiosHooks } from '../../../api/ApiClient'
import { getTitleAndMessage } from '../../../services/HandleError'
import { VoorzieningenFormPanel } from './VoorzieningenFormPanel'

type VoorzieningenListDtoType = z.infer<typeof VoorzieningenListDto>;

export const VoorzieningenList = () => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [itemToEdit, setItemToEdit] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const { isAdmin } = useRoles()
  const [error, setError] = React.useState<string>()

  const { data, invalidate, isLoading } = zodiosHooks.useGetVoorzieningen({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteVoorzieningen({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const commandItems = useMemo(() =>  [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
    {
      text: 'Toevoegen',
      onClick: () => {
        openPanel()
      },
      icon: 'Add',
      iconOnly: true,
      visible: isAdmin,
    },
    {
      text: 'Verwijderen',
      onClick: () => {deleteRows({ itemsToDelete: selectedIds })},
      icon: 'Delete',
      iconOnly: true,
      disabled: isDeleting || selectedIds.length === 0
    }
  ], [isAdmin, selectedIds.length])

  const handleItemInvoked = (id: string) => {
    if (id) {
      setItemToEdit(parseInt(id))
      openPanel()
    }
  }

  const handleDismissPanel = () => {
    setItemToEdit(0)
    dismissPanel()
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Type', getPropertyName<VoorzieningenListDtoType>('type'), 'M', true),
      createColumn('Code', getPropertyName<VoorzieningenListDtoType>('code'), 'XL', true),
      createColumn('Naam', getPropertyName<VoorzieningenListDtoType>('naam'), 'XL', false),
      createColumn('Omschrijving', getPropertyName<VoorzieningenListDtoType>('omschrijving'), 'XL', false)
    ]
  }, [])

  return (
    <MainTemplate title="Voorzieningen" subTitle="overzicht" commandItems={commandItems} error={error}>
      <VoorzieningenFormPanel VoorzieningenId={itemToEdit} dismissPanel={handleDismissPanel} isOpen={isPanelOpen}
                              invalidate={invalidate} />
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.voorzieningId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.voorzieningId.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />

    </MainTemplate>
  )
}

