import { MessageBar, MessageBarType } from '@fluentui/react'
import React from 'react'

function ErrorMessageBar(props: Readonly<{ error: string | undefined }>) {
  return <>
    {props.error &&
      <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={true}
        dismissButtonAriaLabel='Close'
      >
        <b>{'Fout opgetreden'}.</b>&ensp;{props.error}
      </MessageBar>
    }
  </>
}

export const FieldErrorsToMessage = (errors: any) => {
  return Object.keys(errors).map((key) => {
    return key + ": " + errors[key].message
  }).join(',\n')
}

export default ErrorMessageBar