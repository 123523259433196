import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IOnderhoudsContract } from 'features/beheer/onderhoudscontract/onderhoudsContractSlice'
import { PagedEntities } from 'interfaces/pagedEntities'
import { invokeFetch } from 'services/apiClient'
import { createInitialState, setPendingState, setRejectedState, setSucceededState } from 'lib/sliceSupport'
import { RootState } from 'store'

const entityAdapter = createEntityAdapter<IOnderhoudsContract>()

const getSliceState = (state: RootState) => state.gebouwDeelOnderhoudsContracten

const baseUrl = '/api/OnderhoudsContract'
const basePrefix = 'gebouwDeelOnderhoudsContract'

export const fetchGebouwDeelContracten = createAsyncThunk(`${basePrefix}/fetchAll`, async (gebouwDeelId: number, thunkAPI) => {
  const uri = `${baseUrl}/gebouwDeel?gebouwDeelId=${gebouwDeelId}`
  return await invokeFetch<PagedEntities<IOnderhoudsContract>>(thunkAPI, 'GET', uri)
})

export const gebouwDeelOnderhoudsContractSlice = createSlice({
  name: basePrefix,
  initialState: createInitialState<IOnderhoudsContract>(),

  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchGebouwDeelContracten.pending, state => setPendingState(state))
    builder.addCase(fetchGebouwDeelContracten.fulfilled, (state, action: PayloadAction<PagedEntities<IOnderhoudsContract>>) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchGebouwDeelContracten.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}

export const getErrorState = (state: RootState) => {
  return getSliceState(state).error
}

export const { selectAll } = entityAdapter.getSelectors<RootState>(state => getSliceState(state))

export default gebouwDeelOnderhoudsContractSlice.reducer