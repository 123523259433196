export function transformDateString(strDate?: string) {
  if (strDate) {
    const time = new Date(strDate)
    const opts = {
      dateStyle: 'short'
    } as Intl.DateTimeFormatOptions
    return time.toLocaleDateString('nl-NL', opts)
  }
  return ''
}
