import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { initialState } from './LoadState'

export interface IPerceel {
    vgeId: number,
    perceel: string,
    kadastraleGemeente: string,
    complexIndex: string,
    grootte: number,
    nrGerechtigde: number,
    naamGerechtigde: string,
    bronDocument: string,
    datumBronDocument: string,
    cultuurCodeBebouwd: string,
    cultuurCodeOnbebouwd: string,
    latitude?: number,
    longitude?: number,
    kadastraleAanduidingObject: string,
    kadastraleAanduidingVlak?: string,
    gerechtigden: IGerechtigde[]
}

export interface IGerechtigde {
    nrGerechtigde: number,
    gerechtigde: string,
    rechtCode: string,
    volgNummer: number,
    aandeel: string,
    bronDocument: string
}

const entityAdapter = createEntityAdapter<IPerceel>({
    selectId: p => p.vgeId
})

export const fetchPerceel = createAsyncThunk('percelen/fetchById',
    async (vgeId: number, thunkAPI) => await invokeFetch<IPerceel>(thunkAPI, 'GET', `/api/perceel?vgeId=${vgeId}`)
)

export const perceelSlice = createSlice({
    name: 'percelen',

    initialState: entityAdapter.getInitialState(initialState),

    reducers: {},

    extraReducers: builder => {
        builder.addCase(fetchPerceel.pending, state => {
            state.status = 'pending'
        })
        builder.addCase(fetchPerceel.fulfilled, (state, action: PayloadAction<IPerceel>) => {
            state.status = 'succeeded'
            entityAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(fetchPerceel.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message || null
        })
    }
})

export const { selectById } = entityAdapter.getSelectors<RootState>(state => state.percelen)

export default perceelSlice.reducer