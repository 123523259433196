import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import IElementElementDetail from './elementElementDetailModel'
import { PagedEntities } from 'interfaces/pagedEntities'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'

const entityAdapter = createEntityAdapter<IElementElementDetail>()

const baseUrl = '/elementElementDetails'
const basePrefix = 'elementElementDetails'

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
  return await invokeFetch<PagedEntities<IElementElementDetail>>(thunkAPI, 'GET', `${baseUrl}/page?skip=0&top=50000`)
})

const setPendingState = (state: EntityState<IElementElementDetail> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<IElementElementDetail> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<IElementElementDetail> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }
  
export const elementElementDetailSlice = createSlice({
  name: 'elementElementDetails',
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
    clearError: state => {
      state.error = null
    },
    select: (state, action: PayloadAction<number | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
  },

  extraReducers: builder => {
    builder.addCase(fetchAll.pending, state => setPendingState(state))
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IElementElementDetail>>) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
  },
})

const getSliceState = (state: RootState) => state.elementElementDetails

export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
  }

export const { removeMany, select, clearSelection } = elementElementDetailSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.elementElementDetails)

export default elementElementDetailSlice.reducer
