import React, { useMemo, useState } from 'react'
import MainTemplate from '../../../../containers/pages/PageTemplates/MainTemplate'
import { IColumn, Link } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { ElementdetailsListDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { usePagedParams } from '../../../../services/usePagedParams'
import { useBoolean } from '@fluentui/react-hooks'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { createColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { z } from 'zod'
import { ElementDetailFormPanel } from './ElementDetailFormPanel'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import ICoMoonFontIcon from '../../../../components/IcoMoon'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'

type ElementdetailsDtoDtoType = z.infer<typeof ElementdetailsListDto>;

const ElementDetailList: React.FC = () => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [itemToEdit, setItemToEdit] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = React.useState<string>()


  const { data, invalidate, isLoading } = zodiosHooks.useGetElementDetail({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteElementDetail({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Code', getPropertyName<ElementdetailsDtoDtoType>('code'), 'XL', true,
        (item: ElementdetailsDtoDtoType) => (
          <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
            {item.code}
          </Link>
        )),
      createColumn('Ingangsdatum', getPropertyName<ElementdetailsDtoDtoType>('ingangsDatum'), 'L', false,
        (item: ElementdetailsDtoDtoType) => (
          <span>{item.ingangsDatum}</span>
        )),
      createColumn('Einddatum', getPropertyName<ElementdetailsDtoDtoType>('eindDatum'), 'L', false),
      createColumn('Fonticoon', getPropertyName<ElementdetailsDtoDtoType>('fonticoonNaam'), 'L', false,
        (item: ElementdetailsDtoDtoType) => <ICoMoonFontIcon iconName={item?.fonticoonNaam!} />)
    ]
  }, [])

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
    {
      text: 'Toevoegen', onClick: () => {
        openPanel()
      }, icon: 'Add'
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        deleteRows(selectedIds)
      },
      icon: 'Delete',
      disabled: isDeleting || selectedIds.length === 0
    }
  ], [selectedIds.length])

  const handleItemInvoked = (id: string) => {
    if (id) {
      setItemToEdit(parseInt(id))
      openPanel()
    }
  }
  const handleDismissPanel = () => {
    setItemToEdit(0)
    dismissPanel()
  }

  return (
    <MainTemplate title='Elementdetails' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <ElementDetailFormPanel elementDetailId={itemToEdit} dismissPanel={handleDismissPanel} isOpen={isPanelOpen}
                              invalidate={invalidate} />
      <VgtPagedTable
        items={data?.items ?? undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage ?? false,
          hasPreviousPage: data?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.id}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.id.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}

export default ElementDetailList