import CommandBarTemplate, { ICommandItem } from './CommandBarTemplate'
import ContentTemplate from './ContentTemplate'
import TitleTemplate from './TitleTemplate'
import React from 'react'
import { MessageBar, MessageBarType } from '@fluentui/react'
import { ErrorBoundary } from 'react-error-boundary'
import { ZodError } from 'zod'

const MainTemplate: React.FC<{
  title: string,
  subTitle: string,
  icon?: string,
  error?: string,
  commandItems: ICommandItem[],
  children?: React.ReactNode,
  titleIcon?: React.ReactNode
}> =
  ({ children, title, subTitle, icon, error, commandItems, titleIcon }) => {
    return (<ErrorBoundary FallbackComponent={TemplateError}>
      {commandItems.length > 0 && <CommandBarTemplate items={commandItems}></CommandBarTemplate>}
      <TitleTemplate title={title} subTitle={subTitle} icon={icon} titleIcon={titleIcon} />
      {error !== undefined &&
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          dismissButtonAriaLabel='Close'
        >
          <b>{'Fout opgetreden'}.</b>&ensp;{error}
        </MessageBar>
      }
      <ContentTemplate>{children}</ContentTemplate>
    </ErrorBoundary>)
  }

export default MainTemplate

const TemplateError = ({ error }) => {
  console.log(error)
  const zodError = error as ZodError
  const errorMessage = (error instanceof ZodError)
    ? zodError.issues?.map(issue => `${issue.path[0]}: ${issue.message}`).join(',')
    : error?.message || 'Er is die we niet hebben voorzien. De fout is gelogd en we zullen het zo snel mogelijk oplossen. U kunt terugkeren naar de home pagina.'

  return <div>{errorMessage}</div>
}