import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setPendingState, setRejectedState, setSucceededState } from 'lib/sliceSupport'
import { entityAdapterInitState } from 'interfaces/entityAdapterState'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { IInstallatieRuimteSoort } from 'features/beheer/installatie/installatieTypes'

export interface IIndelingRuimteSoortVge {
    vgeId: number
    ruimteSoorten: IInstallatieRuimteSoort[]
}

const entityAdapter = createEntityAdapter<IIndelingRuimteSoortVge>({
    selectId: e => e.vgeId
})

const baseUrl = '/api/installatie/ruimteSoorten'
const basePrefix = 'ruimteSoortenPerVge'

export const fetchById = createAsyncThunk(`${basePrefix}/ruimtesoorten`, async (vgeId: number, thunkAPI) => {
    return await invokeFetch<IIndelingRuimteSoortVge>(thunkAPI, 'GET', `${baseUrl}?vgeId=${vgeId}`)
})

export const ruimteSoortPerVgeSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),

    reducers: {},

    extraReducers: builder => {
        builder.addCase(fetchById.pending, state => setPendingState(state))
        builder.addCase(fetchById.fulfilled, (state, action: PayloadAction<IIndelingRuimteSoortVge>) => {
            setSucceededState(state)
            if (action.payload)
                entityAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(fetchById.rejected, (state, action) => setRejectedState(state, action))
    }
})

export const getRuimteSoortenPerVge = (state: RootState, vgeId: number) => {
    return state.ruimteSoortenPerVge.entities[vgeId]
}

export default ruimteSoortPerVgeSlice.reducer
