import {
  DetailsList,
  DetailsRow,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  Panel,
  PanelType,
  SelectionMode,
} from '@fluentui/react'
import {VgeMeterstandHistorieDto, zodiosHooks} from '../../../../api/ApiClient'
import {z} from "zod";
import React, {useEffect, useState} from "react";
import {getPropertyName} from '../../../../lib/interfaceUtils'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { getTitleAndMessage } from '../../../../services/HandleError'

interface IEditPanelProps {
  isOpen: boolean;
  dismissPanel: () => void;
  vgeMeterstandId: number;
}

type ShowVgeMeterstandHistorie = z.infer<typeof VgeMeterstandHistorieDto>;

export const HistoryPanel: React.FC<IEditPanelProps> = ({ vgeMeterstandId, isOpen, dismissPanel}) => {
  const [error, setError] = useState<string>()

  const { data, invalidate} = zodiosHooks.useGetVgeMeterstandhistorie(
    { queries: { VgeMeterstandId: vgeMeterstandId  } }, {
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  useEffect(() => {
    invalidate()
  }, [data, vgeMeterstandId, isOpen])

  const isHistoryPresent = data && data.length > 0;

  const formatToDateTimeString = (date: Date | undefined) => {
    if (!date) return ''
    const parsedDate = new Date(date)
    return parsedDate.toLocaleDateString('nl-NL', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    })
  }
  const onCancel = () => {
    dismissPanel();
  }
  const columns = [
    {
      key: getPropertyName<ShowVgeMeterstandHistorie>('metertypeNaam'),
      name: 'Metertype',
      fieldName: getPropertyName<ShowVgeMeterstandHistorie>('metertypeNaam'),
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<ShowVgeMeterstandHistorie>('metertypeEenheid'),
      name: 'Eenheid',
      fieldName: getPropertyName<ShowVgeMeterstandHistorie>('metertypeEenheid'),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<ShowVgeMeterstandHistorie>('aantal'),
      name: 'Aantal',
      fieldName: getPropertyName<ShowVgeMeterstandHistorie>('aantal'),
      minWidth: 100,
      maxWidth: 110,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<ShowVgeMeterstandHistorie>('inspectieOpnameTypeNaam'),
      name: 'Reden registratie',
      fieldName: getPropertyName<ShowVgeMeterstandHistorie>('inspectieOpnameTypeNaam'),
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
    },
    {
      key:  getPropertyName<ShowVgeMeterstandHistorie>('registratieGebruikerNaam'),
      name: 'Gebruiker',
      fieldName:  getPropertyName<ShowVgeMeterstandHistorie>('registratieGebruikerNaam'),
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true,
    },
    {
      key:  getPropertyName<ShowVgeMeterstandHistorie>('registratieDatum'),
      name: 'Datum',
      fieldName:  getPropertyName<ShowVgeMeterstandHistorie>('registratieDatum'),
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true,
      onRender: (item) => formatToDateTimeString(item.registratieDatum)
    }
  ] as IColumn[]

  const _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {}
    customStyles.root = { height: 20}
    return <DetailsRow  {...props!} styles={customStyles} />
  }

  return (
    <div>
      <Panel type={PanelType.extraLarge} headerText="Metertype historie" isOpen={isHistoryPresent && isOpen} onDismiss={onCancel}
             closeButtonAriaLabel="Sluiten">
        <>
          <div style={{marginBottom: 16, borderBottom: '1px solid rgb(207, 207, 207)'}}/>
          <DetailsList items={data! ?? []} onRenderRow={_onRenderRow} columns={columns}
                       selectionMode={SelectionMode.none} />
          <ErrorMessageBar error={error} />
        </>
      </Panel>
    </div>)
}