import { CommandBar, IColumn, ICommandBarItemProps, SelectionMode, Stack, StackItem } from "@fluentui/react";
import commandBarStyles from 'styles/commandBarStyles'
import { GebouwDefectLocatieDto, zodiosHooks } from "api/ApiClient";
import VgtControlGroup from "components/ControlGroup";
import { useParams } from "react-router-dom";
import { z } from 'zod';
import FluentTable from "components/FluentTable";
import { useMemo, useState } from "react";
import { DefectlocatieEditPanel } from "./collectieveDefectlocatiesEditpanel";
import { createColumn } from 'lib/gridHelper'
import { getPropertyName } from "lib/interfaceUtils";

export const EditParams = z.object({
    id: z.string().regex(/^\d+$/, 'Geen geldige id').transform(s => Number(s))
})

type GebouwDefectLocatieDtoType = z.infer<typeof GebouwDefectLocatieDto>;

const defaultGebouwDefectLocatie = {
    gebouwDefectlocatieId: 0,
    gebouwId: 0,
    defectlocatieId: 0,
    defectlocatie: '',
    beheerderId: 0,
    beheerder: '',
    externeReferentie: '',
    locatieaanduiding: ''
};

let selectedEntity: GebouwDefectLocatieDtoType = defaultGebouwDefectLocatie;

export function GetSelectedEntity() {
    return selectedEntity;
}

const CollectieveDefectlocaties: React.FC = () => {
    const params = useParams()
    const validatedParams = EditParams.parse(params)
    const [selectedIds, setSelectedIds] = useState<string[]>([])
    const [panelIsOpen, setPanelIsOpen] = useState(false)

    const { data: gebouwDefectlocatiesData, isLoading, invalidate } = zodiosHooks.useGetGebouwenIddefectlocaties({
        params: {
            id: validatedParams.id
        }
    })

    const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteGebouwenIddefectlocaties({
        params: {
            id: validatedParams.id.toString()
        }
    }, {
        onSuccess: () => invalidate(),
        onError: (error) => alert(error)
    })

    const columns: IColumn[] = useMemo(() => {
        return [
            createColumn('Defectlocatie', getPropertyName<GebouwDefectLocatieDtoType>('defectlocatie'), 'M', true),
            createColumn('Beheerder', getPropertyName<GebouwDefectLocatieDtoType>('beheerder'), 'M', false),
            createColumn('Externe referentie', getPropertyName<GebouwDefectLocatieDtoType>('externeReferentie'), 'L', false),
            createColumn('Locatieaanduiding', getPropertyName<GebouwDefectLocatieDtoType>('locatieaanduiding'), 'L', false),
        ]
    }, [])

    const selectCommandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'add',
                iconOnly: true,
                iconProps: { iconName: 'Add', className: 'icon' },
                split: false,
                ariaLabel: 'Toevoegen',
                onClick: () => {
                    selectedEntity = defaultGebouwDefectLocatie
                    selectedEntity.gebouwId = validatedParams.id
                    setPanelIsOpen(true)
                },
            },
            {
                key: 'delete',
                iconOnly: true,
                iconProps: { iconName: 'Delete', className: 'icon' },
                split: false,
                ariaLabel: 'Verwijderen',
                disabled: isDeleting,
                onClick: () => {
                    var idsToNumber = selectedIds.map(id => parseInt(id, 10))
                    deleteRows({ itemsToDelete: idsToNumber })
                },
            },
        ],
        [selectedIds]
    )

    const panelDismissed = () => {
        setPanelIsOpen(false)
        invalidate()
    }

    const handleItemInvoked = (id: string) => {
        setPanelIsOpen(true)
        selectedEntity = gebouwDefectlocatiesData?.find(gdl => gdl.gebouwDefectlocatieId === parseInt(id)) ?? defaultGebouwDefectLocatie
    }

    return (
        <Stack>
            <StackItem>
                <VgtControlGroup name="group1" label="Defectlocaties">
                    <CommandBar items={selectCommandBarItems} styles={commandBarStyles} />
                    <DefectlocatieEditPanel isOpen={panelIsOpen} onCancel={panelDismissed} />
                    <FluentTable
                        height='77vh'
                        columns={columns}
                        items={gebouwDefectlocatiesData || []}
                        loading={isLoading}
                        selectionMode={SelectionMode.multiple}
                        onSelectionChanged={(ids) => {
                            var stringIds = ids.map(id => id.toString())
                            setSelectedIds(stringIds)
                        }
                        }
                        onGetKey={(item: typeof GebouwDefectLocatieDto) => item['gebouwDefectlocatieId']}
                        onItemInvoked={(id: string) => {
                            handleItemInvoked(id)
                        }}
                    />
                </VgtControlGroup>
            </StackItem>
        </Stack>
    )
}

export default CollectieveDefectlocaties;
