import { PagedEntities } from 'interfaces/pagedEntities';
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { IEntityPageObjectResponse } from 'lib/sliceSupport'
import { ILEmDOCodeEntity } from '../common/lemdoEntity'
import * as yup from 'yup'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'


export const elementSchema = yup.object().shape({
    tekstCodeNotatie: yup.string().required('Class-tekstcodenotatie is verplicht').max(20),
    codeNotatie: yup.string().required('Class-code notatie is verplicht').max(20),
    niveau: yup.number().required('Niveau is verplicht'),
    bovenliggendNiveau: yup.string().max(20).nullable(),
    omschrijving: yup.string().required('Omschrijving is verplicht').max(100),
    classificatie: yup.string().required('Classificatie is verplicht').max(20)
})

export interface IBouwkundigElement extends ILEmDOCodeEntity {
    tekstCodeNotatie: string,
    codeNotatie: string,
    niveau: number,
    bovenliggendNiveau?: string,
    omschrijving: string,
    classificatie: string
}

const entityAdapter = createEntityAdapter<IBouwkundigElement>({
    sortComparer: (a, b) => a.code.localeCompare(b.code)
})

const baseUrl = '/nlsfb'
const basePrefix = 'nlsfb'
const getSliceState = (state: RootState) => state.nlsfb

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<IEntityPageObjectResponse<IBouwkundigElement>>(thunkAPI, 'GET', `${baseUrl}`)
  })
  
  export const updateNlsfbElement = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: IBouwkundigElement, thunkAPI) => {
    return await invokeFetch<IBouwkundigElement>(thunkAPI, 'PUT', `${baseUrl}/${entity.id}`, entity)
  })
  
  export const addNlsfbElement = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: IBouwkundigElement, thunkAPI) => {
    return await invokeFetch<IBouwkundigElement>(thunkAPI, 'POST', baseUrl, entity)
  })
  
  export const deleteNlsfbElement = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
  })
  
  const setPendingState = (state: EntityState<IBouwkundigElement> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<IBouwkundigElement> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<IBouwkundigElement> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }


export const nlsfbElementSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),

    reducers: {
        setSearchFilter: (state, action: PayloadAction<string | undefined>) => {
            state.searchFilter = action.payload
        },
        clearError: state => {
          state.error = null
        },
        select: (state, action: PayloadAction<string | undefined>) => {
          state.selectedId = action.payload
        },
        clearSelection: state => {
          state.selectedId = undefined
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
      },
    
      extraReducers: builder => {
        builder.addCase(fetchAll.pending, state => setPendingState(state))
        builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IBouwkundigElement>>) => {
          setSucceededState(state)
          entityAdapter.setAll(state, action.payload.items)
        })
        builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
        builder.addCase(updateNlsfbElement.pending, state => setPendingState(state))
        builder.addCase(updateNlsfbElement.fulfilled, (state, action: PayloadAction<IBouwkundigElement>) => {
          entityAdapter.upsertOne(state, action.payload)
          setSucceededState(state)
        })
        builder.addCase(updateNlsfbElement.rejected, (state, action) => setRejectedState(state, action))
        builder.addCase(addNlsfbElement.pending, state => setPendingState(state))
        builder.addCase(addNlsfbElement.fulfilled, (state, action: PayloadAction<IBouwkundigElement>) => {
          entityAdapter.upsertOne(state, action.payload)
          setSucceededState(state)
        })
        builder.addCase(addNlsfbElement.rejected, (state, action) => {
          setRejectedState(state, action)
        })
        builder.addCase(deleteNlsfbElement.pending, state => setPendingState(state))
        builder.addCase(deleteNlsfbElement.fulfilled, (state, action) => {
          entityAdapter.removeMany(state, action.meta.arg)
          setSucceededState(state)
        })
        builder.addCase(deleteNlsfbElement.rejected, (state, action) => setRejectedState(state, action))
    },
})

export const getSelectedEntity = (state: RootState) => {
    if (state.nlsfb.selectedId) return state.nlsfb.entities[state.nlsfb.selectedId]
    else return undefined
}

export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
  }
  
  export const getErrorState = (state: RootState) => {
    return getSliceState(state).error
  }

export const { add, modify, removeMany, select, clearSelection, setSearchFilter } = nlsfbElementSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.nlsfb)

export const getSearchFilter = (state: RootState) => state.nlsfb.searchFilter

const isMatch = (text: string | undefined | null, searchTerm: string) => {
    if (text)
        return text.toUpperCase().indexOf(searchTerm) !== -1
    return false
}

export const selectElementen = createSelector([selectAll, getSearchFilter], (entities, filter) => {
    if (filter !== undefined && filter !== '') {
        const upper = filter.toUpperCase()
        const result = entities.filter(e =>
            isMatch(e.tekstCodeNotatie, upper) ||
            isMatch(e.codeNotatie, upper) ||
            isMatch(e.bovenliggendNiveau, upper) ||
            isMatch(e.omschrijving, upper) ||
            isMatch(e.classificatie, upper))
        console.info(`Filter is ${filter} and returns ${result.length} rows.`);
        return result
    }
    return entities
})

export default nlsfbElementSlice.reducer
