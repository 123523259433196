import React, { useMemo } from 'react'

import { Dialog, DialogFooter, IDialogContentProps, IModalProps, DialogType, DefaultButton } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'

interface IDeleteConfirmationDialogProps {
    onConfirm: () => void
    onCancel: () => void
    entityName: string
    customMessage?: string
    showDialog: boolean
}

const DeleteConfirmationDialog: React.FC<IDeleteConfirmationDialogProps> = ({
    onConfirm,
    onCancel,
    entityName,
    customMessage,
    showDialog
}) => {
    const labelId: string = useId('dialogLabel')
    const subTextId: string = useId('subTextLabel')

    const modalProps = useMemo(
        (): IModalProps => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,            
        }),
        [labelId, subTextId]
    )

    const dialogContentProps = useMemo(
        (): IDialogContentProps => ({
            type: DialogType.normal,
            isMultiline: true,
            closeButtonAriaLabel: 'Sluiten',
            title: 'Verwijdering bevestigen',
            subText: `Weet u zeker dat u de geselecteerde ${entityName} wilt verwijderen?,
       ${customMessage ?? 'U kunt deze actie niet ongedaan maken.'}`,
        }),
        [entityName, customMessage]
    )    

    return (
        <div className="deleteConfirmationDialog">
            <Dialog hidden={!showDialog} onDismiss={onCancel} dialogContentProps={dialogContentProps}
                    modalProps={modalProps}>
                <DialogFooter>
                    <DefaultButton primary onClick={onConfirm} text="Verwijderen"/>
                    <DefaultButton onClick={onCancel} text="Annuleren"/>
                </DialogFooter>
            </Dialog>
        </div>
    )
}

export default DeleteConfirmationDialog
