import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { z } from 'zod'
import { getPropertyName } from '../lib/interfaceUtils'

const pagingSchema = z.object({
  page: z.coerce.number().min(1).optional().default(1),
  pageSize: z.coerce.number().min(1).optional().default(15),
  sortKey: z.string().optional().default(''),
  sortDirection: z.enum(['asc', 'desc']).optional().default('asc'),
  filter: z.string().optional().default('')
})

export const usePagedParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [validatedPagedParams, setValidatedPagedParams] = useState(pagingSchema.parse(Object.fromEntries(searchParams.entries())))
  type ExtendedParamsType = z.infer<typeof pagingSchema>

  const setFilter = (filter: string) => {
    if (filter === undefined) {
      return
    }
    searchParams.set(getPropertyName<ExtendedParamsType>('filter'), filter)
    searchParams.set(getPropertyName<ExtendedParamsType>('page'), '1')
    setSearchParams(searchParams, { replace: true })
    setValidatedPagedParams(pagingSchema.parse(Object.fromEntries(searchParams.entries())))
  }

  const setPage = (page: number) => {
    searchParams.set(getPropertyName<ExtendedParamsType>('page'), page.toString())
    setSearchParams(searchParams, { replace: true })
    setValidatedPagedParams(pagingSchema.parse(Object.fromEntries(searchParams.entries())))
  }

  const setOrder = (key: string, direction: 'asc' | 'desc' = 'asc') => {
    searchParams.set(getPropertyName<ExtendedParamsType>('sortKey'), key)
    searchParams.set(getPropertyName<ExtendedParamsType>('sortDirection'), direction)
    setSearchParams(searchParams, { replace: true })
    setValidatedPagedParams(pagingSchema.parse(Object.fromEntries(searchParams.entries())))
  }

  return {
    validatedSearchParams: validatedPagedParams,
    setPage,
    setOrder,
    setFilter
  }
}