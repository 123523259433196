import { TextField } from '@fluentui/react'
import React, { useEffect, useState } from 'react'

export interface INumericInputProps {
  value: number | undefined | null
  onChange: (number?: number) => void
  onEditing?: (editing: boolean) => void
  fractionDigits?: number
}

const NumericInput: React.FC<INumericInputProps> = (props: INumericInputProps) => {
  const regExNumber = /^(\d+(\.\d+)?).*/
  const [text, setText] = useState<string>()
  const [localNumber, setLocalNumber] = useState<number>()
  const [lastValidNumber, setLastValidNumber] = useState<number>()

  const convertDecimalToText = (value: number | null | undefined) => {
    if (!value) return ''
    return value.toLocaleString('nl-NL', { minimumFractionDigits: props.fractionDigits ?? 2, maximumFractionDigits: props.fractionDigits ?? 2 })
  }

  useEffect(() => {
    if (props.value) {
      setLocalNumber(props.value)
      setLastValidNumber(props.value)
    } else {
      setLocalNumber(undefined)
      setLastValidNumber(undefined)
    }

    const textAsNumber = convertDecimalToText(props.value)
    setText(textAsNumber)
  }, [props.value])

  const fireUpdate = () => {
    if (props.onEditing) props.onEditing(false)

    let hasChanged = false
    if (lastValidNumber && localNumber) hasChanged = lastValidNumber !== localNumber
    else if ((lastValidNumber && !localNumber) || (!lastValidNumber && localNumber)) hasChanged = true
    if (hasChanged) {
      props.onChange(localNumber)
      setLastValidNumber(localNumber)
    }
  }

  const clearNumber = () => {
    setLocalNumber(undefined)
    setText(undefined)
  }

  const onChangeText = (text: string | undefined) => {
    if (props.onEditing) props.onEditing(true)

    if (text) {
      if (regExNumber.test(text)) {
        const numberText = text.replace('.', '').replace(',', '.')
        let number = Number(numberText)
        if (!isNaN(number)) {
          setLocalNumber(number)
          setText(text)
        }
      } else {
        clearNumber()
      }
    } else {
      clearNumber()
    }
  }

  return <TextField value={text} onChange={(_, item) => onChangeText(item)} onBlur={() => fireUpdate()} type='text' style={{ textAlign: 'right' }} />
}

export default NumericInput
