import React from 'react'
import InputCard from '../../../components/InputCard'
import ReadOnlyField from '../../../components/ReadOnlyField'
import { CoencadZorggeschiktheidListDto, zodiosHooks } from '../../../api/ApiClient'
import { z } from 'zod'
import { SectionHeader, SectionLine } from '../../../components/Section'

interface IListProps {
  vgeId: number,
}

type recordType = z.infer<typeof CoencadZorggeschiktheidListDto>;

export const CoencadZorggeschiktheidList: React.FC<IListProps> = props => {

  const [data, setData] = React.useState<recordType[]>([] as recordType[])

  zodiosHooks.useGetApiCoencadZorggeschiktheidVgeId({ params: { vgeId: props.vgeId } }, {
    onSuccess: (data) => setData(data)
  })

  function formatData(data: recordType[]) {
    let result: recordType = {} as recordType

    data.forEach((item) => {
      for (let key in item) {
        if (result[key]) {
          result[key].push(item[key])
        } else {
          result[key] = [item[key]]
        }
      }
    })

    if (data.length === 1) {
      for (let key in result) {
        result[key] = result[key][0]
      }
    }

    return result
  }


  const formatedData = formatData(data)

  if (!data || data.length === 0) return <div>Geen oppervlakte data beschikbaar</div>

  return (
    <div style={{ position: 'relative', height: `800px`, overflow: 'auto' }}>

      <InputCard title="Algemeen" boldTitle={true} width={'calc(100%)'}>
        <div style={{ display: 'flex', marginRight: '20px' }}>
          <div style={{ flex: '1' }}>
            <ReadOnlyField title="Bronbestand" value={data[0]?.bronbestand} />
            <ReadOnlyField title="Berekeningswijze" value={data[0]?.berekeningswijze} />
          </div>
          <div style={{ flex: '1' }}>
            <ReadOnlyField title="Importdatum" value={data[0]?.importdatum} />
            <ReadOnlyField title="Gebruiker" value={data[0]?.gebruiker} />
          </div>
        </div>
      </InputCard>
      <div style={{ display: 'flex', flex: 1, marginTop: '20px' }}>
        <div style={{ flex: '1 1 50%', overflowY: 'auto' }}>
          <InputCard width={'calc(100%)'} marginTop={'16px'}>
            <SectionHeader title="1. Verkeersruimten">
              <SectionLine title="1.0 Entree zonder traptrede" value={formatedData.entreeZonderTraptrede} valueAlignment={'right'}  />
              <SectionLine title="1.1 Hoogte traptrede(m1)" value={formatedData.hoogteTraptrede} valueAlignment={'right'}  />
              <SectionLine title="1.2 Primaire voorzieningen gelijkvloers" value={formatedData.primaireVoorzieningenGelijkvloers} valueAlignment={'right'}  />
              <SectionLine title="1.3 Breedte toegangspad(m1)" value={formatedData.breedteToegangspad} valueAlignment={'right'}  />
              <SectionLine title="1.4 Vrije doorgang deuren woning(m1)" value={formatedData.vrijeDoorgangDeurenWoning} valueAlignment={'right'}  />
              <SectionLine title="1.5 Niveauverschil in woning(m1)" value={formatedData.niveauverschilInWoning} valueAlignment={'right'}  />
              <SectionLine title="1.6 Hellingshoek woning bij verschil >20 mm(graden)" value={formatedData.hellingshoekWoningBijVerschil20Mm} valueAlignment={'right'}  />
              <SectionLine title="1.7 Kleinste breedte gang(m1)" value={formatedData.kleinsteBreedteGang} valueAlignment={'right'}  />
            </SectionHeader>
          </InputCard>

          <InputCard width={'calc(100%)'} marginTop={'16px'}>
            <SectionHeader title="2. Badkamer & toilet">
              <SectionLine title="2.1 Badkamer dezelfde verdieping als slaapkamer" value={formatedData.badkamerDezelfdeVerdiepingAlsSlaapkamer} valueAlignment={'right'} />
              <SectionLine title="2.2 Toilet dezelfde verdieping als slaapkamer" value={formatedData.toiletDezelfdeVerdiepingAlsSlaapkamer} valueAlignment={'right'} />
              <SectionLine title="2.3 Kleinste oppervlakte badkamer en/of toilet(m2)" value={formatedData.kleinsteOppervlakteBadkamerEnOfToilet} valueAlignment={'right'} />
              <SectionLine title="2.4 Kleinste breedte badkamer en/of toilet(m1)" value={formatedData.kleinsteBreedteBadkamerEnOfToilet} valueAlignment={'right'} />
              <SectionLine title="2.5 Draaicirkel badkamer(m1)" value={formatedData.draaicirkelBadkamer} valueAlignment={'right'} />
            </SectionHeader>
          </InputCard>

          <InputCard width={'calc(100%)'} marginTop={'16px'}>
            <SectionHeader title="3. Slaapkamer">
              <SectionLine title="3.1 Breedte 2 persoons slaapkamer(m1)" value={formatedData.breedte2PersoonsSlaapkamer} valueAlignment={'right'} />
              <SectionLine title="3.2 Lengte 2 persoons slaapkamer(m1)" value={formatedData.lengte2PersoonsSlaapkamer} valueAlignment={'right'} />
              <SectionLine title="3.3 Oppervlakte 2 persoons slaapkamer(m2)" value={formatedData.oppervlakte2PersoonsSlaapkamer} valueAlignment={'right'} />
            </SectionHeader>
          </InputCard>

          <InputCard width={'calc(100%)'} marginTop={'16px'}>
            <SectionHeader title="4. Keuken">
              <SectionLine title="4.1 Kleinste vrije ruimte voor aanrecht(m1)" value={formatedData.kleinsteVrijeRuimteVoorAanrecht} valueAlignment={'right'} />
            </SectionHeader>
          </InputCard>
        </div>
        <div style={{ flex: '1 1 50%', marginLeft: '20px' }}>
          <InputCard width={'calc(100%)'} marginTop={'16px'}>
            <SectionHeader title="5. Toegangspad">
              <SectionLine title="5.1  Gebouw zonder traptrede" value={formatedData.gebouwZonderTraptrede} valueAlignment={'right'} />
              <SectionLine title="5.2  Kleinste breedte toegangspad(m1)" value={formatedData.kleinsteBreedteToegangspad} valueAlignment={'right'} />
              <SectionLine title="5.3  Niveauverschil entree(m1)" value={formatedData.niveauverschilEntree} valueAlignment={'right'} />
              <SectionLine title="5.4   Hellingshoek toegangspad bij verschil >20 mm" value={formatedData.hellingshoekToegangspadBijVerschil20} valueAlignment={'right'} />
            </SectionHeader>
            <SectionHeader title="6. Toegangsdeur">
              <SectionLine title="6.1 Dagmaat(m1)" value={formatedData.dagmaat} valueAlignment={'right'} />
              <SectionLine title="6.2 Draaicirkel(m1)" value={formatedData.draaicirkel} valueAlignment={'right'} />
            </SectionHeader>
            <SectionHeader title="7. Galerij & lift">
              <SectionLine title="7.1 Galerijbreedte(m1)" value={formatedData.galerijbreedte} valueAlignment={'right'} />
              <SectionLine title="7.2 Kleinste afmeting liftkooi(m1)" value={formatedData.kleinsteAfmetingLiftkooi} valueAlignment={'right'} />
            </SectionHeader>
          </InputCard>
        </div>
      </div>
    </div>
  )
}