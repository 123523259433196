import React from 'react'

const NotFound = () => {
  return <div><h1>Niet gevonden... Laat ons u helpen de weg te vinden</h1>
    <h3><a href="/">Naar de startpagina</a></h3>
    <h3><a target="" href="https://www.vastgoedtabel.nl/contact">Neem contact met ons op</a></h3>
  </div>
}

export default NotFound
