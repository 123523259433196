import { IStackTokens, Pivot, PivotItem, Spinner, SpinnerSize, Stack, StackItem, Text } from '@fluentui/react'
import './gebouwEdit.css'
import InputCard from 'components/InputCard'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { AdresStatus } from './gebouwVgeList'
import CollectieveDefectlocaties from './collectieveDefectlocaties'
import GebouwHoeveelheden, { EntityType } from './gebouwHoeveelheidList'
import GoogleMapReact from 'google-map-react'
import './marker.css'
import { EditMode } from './editPanelGebouwHoeveelheid'
import GebouwOnderhoudsContracten from './onderhoudsContract/gebouwOnderhoudsContracten'
import GebouwOnderhoudsBedrijven from './gebouwOnderhoudsbedrijf'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { GebouwContactCategorieList } from './gebouwContactCategorie/GebouwContactCategorieList'
import { useBoolean } from '@fluentui/react-hooks'
import { GebouwEditPanel } from './gebouwEditPanel'
import locatie from '../../../vge/icons/Vastgoedtabel_Icons_BAG-locatie.png'
import ReadOnlyField from '../../../../components/ReadOnlyField'
import DocumentList, { DocumentDoelType } from '../../documents/DocumentList'
import { GebouwAddOrUpdateDto, zodiosHooks } from '../../../../api/ApiClient'
import Oppervlakte from '../Oppervlakte/Oppervlakte'
import { CartotheekWizardPanel } from '../../cartotheek/CartotheekPanel'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { HuurcontractbepalingenTooltip } from '../../huurcontractbepaling/HuurcontractbepalingShow'
import { GebouwBestemmingPanel } from './GebouwBestemmingPanel'
import { availableUrls } from '../../../../routing/AvailableUrls'
import LinkReadOnlyField from '../../../../components/LinkReadOnlyField'
import GebouwVgeList from './gebouwVgeList'
import GebouwDelen from '../gebouwdelen/gebouwDelen'

export type CreateUpdateType = z.infer<typeof GebouwAddOrUpdateDto>;

const stackTokens: IStackTokens = { padding: '0', childrenGap: '20 20' }

const MarkerComponent = ({ text }: any) => <div className='pin'>{text}</div>

const GebouwEdit: React.FC = () => {
  const { id } = useParams()
  const gebouwId = parseInt(id ?? '0')
  const navigate = useNavigate()
  const [selectedPivot, setSelectedPivot] = useState('gebouw')
  const [documentCount, setDocumentCount] = useState(0);
  const [vgeMetAdresCount, setVgeMetAdresCount] = useState(0)
  const [vgeZonderAdresCount, setVgeZonderAdresCount] = useState(0)
  const [vgeMetTerreinCount, setVgeMetTerreinCount] = useState(0)

  const { data: gebouw, invalidate , isLoading} = zodiosHooks.useGetGebouwenId({
    params: {
      id: gebouwId
    }
  }, { enabled: gebouwId !== 0 })

  const { mutate: updateGebouwen } = zodiosHooks.usePutGebouwenId(
    { params: { id: gebouwId } }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  }
  )

  const {
    data: hoofdDocumentUrl,
    invalidate: refetchHoofdDocumentUrl
  } = zodiosHooks.useGetApiDocumentshoofdDocumentForDoelTypeDoelTypedoelIdDoelId({
    params: {
      doelType: DocumentDoelType.gebouw,
      doelId: gebouwId
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message),
    enabled: gebouwId !== 0
  }
  )

  const [inEditMode, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(gebouwId === 0)
  const [inBestemmingEditMode, {
    setTrue: openBestemmingEditPanel,
    setFalse: dismissBestemmingEditPanel
  }] = useBoolean(false)
  const [error, setError] = React.useState<string>()

  const {
    handleSubmit
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(GebouwAddOrUpdateDto),
    mode: 'onChange',
    defaultValues: {
      code: '',
      naam: '',
      type: 1,
      omschrijving: '',
      gebouwenClusterId: 0,
      registratieniveau: 'STD',
      serviceadresVge: 0
    } as CreateUpdateType
  })

  const selectedRegistratieOption = gebouw?.registratieniveau === 'STD' ? 'Standaard' : 'Werkelijk'
  const selectedGebouwType = gebouw?.type === 1 ? 'Hoogbouw' : 'Laagbouw'

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: false },
    { text: 'Wijzigen', onClick: () => openEditPanel(), icon: 'Edit' },
    { text: 'Wijzigen Bestemming', onClick: () => openBestemmingEditPanel(), icon: 'Edit' }
  ], [navigate, openEditPanel])

  const updateSelectedGebouw = data => {
    updateGebouwen(data)
  }

  const calculateCenter = () => {
    if (gebouw) {
      if (gebouw?.coordinates?.length! > 0) {
        return {
          lat: gebouw?.coordinates?.[0].lat,
          lng: gebouw?.coordinates?.[0].long
        } as GoogleMapReact.Coords
      }
      return undefined
    }
  }

  const calculateZoom = () => {
    if (!gebouw) return 0
    return gebouw?.coordinates?.length! > 0 || gebouw?.coordinates?.length! > 0 ? 18 : 6
  }

  const onSelectPivot = (item: PivotItem | undefined) => {
    const itemKey = item?.props?.itemKey
    if (itemKey === 'gebouw' && selectedPivot !== 'gebouw') {
      refetchHoofdDocumentUrl()
    }
    setSelectedPivot(itemKey ?? '')
  }

  const PhotoElement = () => {
    if (!hoofdDocumentUrl)
      return <div style={{ height: '270px', width: '397px', padding: '10px', paddingLeft: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
    if (hoofdDocumentUrl?.hoofdDocumentUrl) {
      return (
        <img
          style={{
            marginTop: '10px',
            height: '270px',
            width: '397px',
            boxShadow: 'rgb(239 239 239) 0px 0px 9px -1px',
            borderRadius: '8px'
          }}
          src={hoofdDocumentUrl?.hoofdDocumentUrl}
          alt='Hoofdfoto'
        />
      )
    } else {
      return (
        <div style={{ width: '100%', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Text>Er is geen hoofdfoto ingesteld om weer te geven</Text>
        </div>
      )
    }
  }

  useEffect(() => {
    setVgeMetAdresCount(gebouw?.aantalAdresseerbaar ?? 0)
    setVgeZonderAdresCount(gebouw?.aantalOverigGebouwd ?? 0)
    setVgeMetTerreinCount(gebouw?.aantalTerreinen ?? 0)
  }, [gebouw])

  return (
    <MainTemplate title={gebouw?.naam ? gebouw?.naam : ''} subTitle='Gebouw'
      commandItems={commandItems}>
      <GebouwEditPanel dismissPanel={dismissEditPanel} isOpen={inEditMode} gebouw={gebouw}
        invalidate={invalidate} />
      <GebouwBestemmingPanel dismissPanel={dismissBestemmingEditPanel} isOpen={inBestemmingEditMode}
        gebouw={gebouw} invalidate={invalidate} />
      <div style={{ marginLeft: 20, marginTop: 10 }}>
        <Pivot
          styles={{
            link: { padding: '0 8 0 0' },
            linkIsSelected: { padding: '0 8 0 0' },
            itemContainer: { padding: '16px 0 0 0' }
          }}
          style={{ marginBlockEnd: 10 }}
          selectedKey={selectedPivot}
          onLinkClick={onSelectPivot}
        >
          <PivotItem key='gebouw_wijzigen' headerText='Gebouw' itemKey='gebouw'>
            <Stack tokens={stackTokens}
              style={{ marginLeft: '10px', marginTop: '10px', marginRight: '10px', height: '72vh' }} horizontal>
              <StackItem grow={1}>
                <Stack>
                  <StackItem>
                    <InputCard title='Gebouw' icon={locatie} width='700'>
                      <ReadOnlyField title='Code' value={gebouw?.code} labelWidth={250} />
                      <ReadOnlyField title='Naam' value={gebouw?.naam} labelWidth={250} />
                      <ReadOnlyField title='Type bouw' value={selectedGebouwType} labelWidth={250} />
                      <ReadOnlyField title='Bestemming' value={gebouw?.bestemmingNaam} labelWidth={250} />
                      <ReadOnlyField title='Omschrijving' value={gebouw?.omschrijving} labelWidth={250} />
                      <LinkReadOnlyField
                        title='Gebouwencluster'
                        url={availableUrls.GebouwenClusterEdit.replace(':id', gebouw?.gebouwenClusterId?.toString()!)}
                        value={gebouw?.gebouwenClusterNaam}
                        labelWidth={250}
                        fieldWidth={'100%'}
                      />
                      <ReadOnlyField title='Service adres' value={gebouw?.serviceAdresVgeNaam} labelWidth={250} />
                      <ReadOnlyField title='Service e-mailadres' value={gebouw?.serviceEmailadres}
                        labelWidth={250} />
                      <ReadOnlyField title='Service telefoonnummer' value={gebouw?.serviceTelefoonnummer}
                        labelWidth={250} />
                      <ReadOnlyField title='Registratieniveau collectieve objecten'
                        value={selectedRegistratieOption} labelWidth={250} />
                      <HuurcontractbepalingenTooltip huurcontractbepalingen={gebouw?.huurcontractbepalingen}
                        labelWidth={250} />
                      <ReadOnlyField title='Vastgoedportefeuille'
                        value={gebouw?.vastgoedportefeuilleNaam} labelWidth={250} />
                      <ReadOnlyField title='Vastgoeddeelportefeuille'
                        value={gebouw?.vastgoeddeelportefeuilleNaam} labelWidth={250} />
                      <ReadOnlyField title='Werkgebied'
                        value={gebouw?.werkgebiedNaam} labelWidth={250} />
                      <ReadOnlyField title='Advertentietekst'
                        value={gebouw?.advertentietekst}
                        multiline={true}
                        autoAdjustHeight={true}
                        resizable={true}
                        labelWidth={250}
                      />
                    </InputCard>
                  </StackItem>
                  <StackItem>
                    <ErrorMessageBar error={error} />
                    <PhotoElement />
                  </StackItem>
                </Stack>
              </StackItem>
              <StackItem style={{ borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }} grow={5}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyCvaLeRfzfAtc7LJxbk5HhtcvB6WrKtX40', language: 'nl-NL' }}
                  defaultCenter={{ lat: 52.0907374, lng: 5.1214201 }}
                  defaultZoom={6}
                  options={{ mapTypeControl: true, mapTypeId: 'roadmap' }}
                  center={calculateCenter()}
                  zoom={calculateZoom()}
                >
                  {gebouw?.coordinates?.map(adres => (
                    <MarkerComponent key={adres.id} lat={adres.lat ?? 51.72835975} lng={adres.long ?? 5.01544399}
                      text={adres.nr} />
                  ))}
                </GoogleMapReact>
              </StackItem>
            </Stack>
          </PivotItem>
          {gebouw && (
            <PivotItem key='geb_dl' headerText='Gebouwdelen' itemCount={gebouw.aantalGebouwdelen}
              itemKey='geb_dl'>
              <GebouwDelen gebouwId={gebouwId} />
            </PivotItem>
          )}

          <PivotItem key='met_adres' headerText='Adresseerbaar' itemCount={vgeMetAdresCount}
            itemKey='met_adres'>
            {gebouw && (
              <GebouwVgeList
                gebouw={gebouw}
                updateSelectedGebouw={handleSubmit(updateSelectedGebouw)}
                adresStatus={AdresStatus.MetAdres}
                setItemCount={setVgeMetAdresCount}
              />
            )}
          </PivotItem>

          <PivotItem key='zonder_adres' headerText='Overig gebouwd'
            itemCount={vgeZonderAdresCount}
            itemKey='zonder_adres'>
            {gebouw && (
              <GebouwVgeList
                gebouw={gebouw}
                updateSelectedGebouw={handleSubmit(updateSelectedGebouw)}
                adresStatus={AdresStatus.ZonderAdres}
                setItemCount={setVgeZonderAdresCount}
              />
            )}
          </PivotItem>

          <PivotItem key='terreinen' headerText='Terreinen'
            itemCount={vgeMetTerreinCount}
            itemKey='terreinen'>
            {gebouw && (
              <GebouwVgeList
                gebouw={gebouw}
                updateSelectedGebouw={handleSubmit(updateSelectedGebouw)}
                adresStatus={AdresStatus.MetTerrein}
                setItemCount={setVgeMetTerreinCount}
              />
            )}
          </PivotItem>

          <PivotItem headerText='Cartotheek' itemKey='cartotheek'>
            <CartotheekWizardPanel gebouwId={gebouw?.gebouwId} />
          </PivotItem>

          <PivotItem key='col_def' headerText='Collectieve defectlocaties' itemKey='col_def'>
            <CollectieveDefectlocaties></CollectieveDefectlocaties>
          </PivotItem>

          <PivotItem key='Oppervlakte' headerText='NEN2580' itemKey='Oppervlakte'>
            <Oppervlakte gebouwId={gebouwId} />
          </PivotItem>

          {gebouw && (
            <PivotItem headerText='Onderhoudscontracten' itemKey='contracten'>
              <GebouwOnderhoudsContracten gebouwId={gebouwId} />
            </PivotItem>
          )}
          {gebouw && (
            <PivotItem headerText='Onderhoudsbedrijven' itemKey='onderhoudsbedrijven'>
              <GebouwOnderhoudsBedrijven gebouwId={gebouwId} />
            </PivotItem>
          )}
          {gebouw && (
            <PivotItem headerText='Contactgegevens' itemKey='contactgegevens'>
              <GebouwContactCategorieList gebouwId={gebouwId} />
            </PivotItem>
          )}
          {gebouw && (
          <PivotItem headerText='Conditiemeting' itemCount={gebouw.hoeveelhedenCount} itemKey='elementen'>
            <GebouwHoeveelheden entityId={gebouwId} entityType={EntityType.Gebouw}  editMode={EditMode.gebouw} />
          </PivotItem>
          )}
          {gebouw && (
            <PivotItem key='gebouw_documenten' headerText='Media' itemCount={documentCount}
                       itemKey='documents'>
              <DocumentList doelType={DocumentDoelType.gebouw} doelId={gebouwId} setDocumentCount={setDocumentCount} />
            </PivotItem>
          )}
        </Pivot>
      </div>
      {isLoading && (
        <div className='spinnerWrapper'>
          <Spinner size={SpinnerSize.large} />
        </div>
      )}

      <Stack tokens={stackTokens} style={{ marginLeft: '10px', marginTop: '10px', marginRight: '10px' }}>
        <StackItem></StackItem>
        <StackItem></StackItem>
        <StackItem></StackItem>
        <StackItem></StackItem>
      </Stack>
    </MainTemplate>
  )
}

export default GebouwEdit
