import { DefaultButton, IDropdownOption, Panel, PanelType, Stack } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
  addTenantKostensoortBetaler,
  deleteTenantKostensoortBetaler,
  getLoadingState,
  getSelectedEntity,
  ITenantKostensoortBetaler,
  TenantKostensoortBetalerSchema,
  updateTenantKostensoortBetaler
} from './tenantKostensoortBetalerSlice'
import { useEffect, useState } from 'react'
import { selectAll as selectAllKostensoorten } from '../kostensoort/kostensoortSlice'
import useRoles from 'services/UseRoles'
import VgtDropdown from 'components/FluentDropdown'

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
}

export const TenantKostensoortBetalerEditPanel: React.FC<IEditPanelProps> = props => {
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState('')
  const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
  const fetchStatus = useSelector(getLoadingState)
  const selectedEntity = useSelector(getSelectedEntity)
  const { isAdmin } = useRoles()

  const kostensoorten = useSelector(selectAllKostensoorten)

  useEffect(() => {
    setCloseOnFulFilled(false)
  }, [])

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<ITenantKostensoortBetaler>({ resolver: yupResolver(TenantKostensoortBetalerSchema), mode: 'all' })

  useEffect(() => {
    setTitle(`Betaler || Kostensoort ${selectedEntity ? isAdmin ? 'wijzen' : '' : 'toevoegen'}`)
    if (selectedEntity) {
      reset(selectedEntity)
    } else {
      reset({})
    }
  }, [selectedEntity])

  const onCancel = e => {
    props.dismissPanel()
  }

  const getKostensoortenAsOptions = () => {
    const nullOption = { key: 0, text: '---' } as IDropdownOption
    const kostensoortOptions = kostensoorten.map(x => ({
      key: x.kostensoortId,
      text: `${x.code} - ${x.omschrijving}`
    })) as IDropdownOption[]
    return [nullOption, ...kostensoortOptions]
  }

  const betalerOptions: IDropdownOption[] = [
    { key: 1, text: 'Corporatie' },
    { key: 2, text: 'Huurder' },
    { key: 3, text: 'Dienstencomponent' }
  ]

  const selectedBetaler = [{
    key: selectedEntity?.betalerOptie,
    text: selectedEntity?.betalerNaam
  }] as IDropdownOption[]

  const onSubmit = (data: ITenantKostensoortBetaler) => {
    if (selectedEntity) {
      if (data.kostensoortId === 0) {
        dispatch(deleteTenantKostensoortBetaler([selectedEntity.betalerOptie]))
        setCloseOnFulFilled(true)
      } else {
        data.betalerOptie = selectedEntity.betalerOptie
        dispatch(updateTenantKostensoortBetaler(data))
        setCloseOnFulFilled(true)
      }
    } else if (data.betalerOptie !== 0 && data.kostensoortId !== 0) {
      dispatch(addTenantKostensoortBetaler(data))
      setCloseOnFulFilled(true)
    }
  }

  useEffect(() => {
    if (closeOnFulFilled && fetchStatus === 'succeeded') props.dismissPanel()
  }, [fetchStatus])

  return (
    <div>
      <Panel type={PanelType.large} headerText={title} isOpen={props.isOpen} onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtDropdown
            options={selectedEntity?.betalerOptie ? selectedBetaler : betalerOptions}
            defaultSelectedKey={selectedEntity?.betalerOptie}
            label='Betaler'
            name={getPropertyName<ITenantKostensoortBetaler>('betalerOptie')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!!selectedEntity?.betalerOptie}
          />
          <VgtDropdown
            options={getKostensoortenAsOptions()}
            label='Kostensoort'
            name={getPropertyName<ITenantKostensoortBetaler>('kostensoortId')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isAdmin}
          />
          <br />
          {isAdmin ?
            <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
              <DefaultButton text='Opslaan' type='submit' primary disabled={isSubmitting}
                             onClick={handleSubmit(onSubmit)} />
              <DefaultButton text='Annuleren' onClick={onCancel} />
            </Stack> : null}
        </form>
      </Panel>
    </div>
  )
}
export default TenantKostensoortBetalerEditPanel