import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import { IVge } from 'features/vge/vgeSlice'
import { IEntityAdapterState } from 'interfaces/entityAdapterState'

interface IEntityAdapterStateExtended extends IEntityAdapterState {
  query?: string
  selectedRowIndex: number
  presentation: 'map' | 'list'
}

export const entityAdapterInitState: IEntityAdapterStateExtended = {
  selectedId: undefined,
  presentation: 'list',
  status: 'idle',
  error: null,
  searchFilter: '',
  selectedRowIndex: -1,
  query: 'Selecteer een optie',
}
const entityAdapter = createEntityAdapter<IVge>({
  //sortComparer: (a, b) => a.str?.localeCompare(b.str),
})

const baseUrl = '/fysiek'
const basePrefix = 'fysiek'
const getSliceState = (state: RootState) => state.fysiek

export const fetchFysiekQuery = createAsyncThunk(`${basePrefix}/fetchStatus`, async ({ query, filter }: { query: string; filter: string }, thunkAPI) => {
  return await invokeFetch<PagedEntities<IVge>>(thunkAPI, 'GET', `${baseUrl}/${query}?top=50000&Filter=${filter}`)
})
export const deleteVge = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
  return await invokeFetch(thunkAPI, 'DELETE', '/vge', entities)
})


const setPendingSate = (state: IEntityAdapterState) => {
  state.error = null
  state.status = 'pending'
}
const setSucceededSate = (state: IEntityAdapterState) => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state: IEntityAdapterState, action) => {
  state.status = 'failed'
  state.error = action.error.message || null
}

export const fysiekQuery = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
    setSelectedRowIndex: (state, action: PayloadAction<number>) => {
      state.selectedRowIndex = action.payload
    },
    setPresentationType: (state, action: PayloadAction<'map' | 'list'>) => {
      state.presentation = action.payload
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchFysiekQuery.pending, state => setPendingSate(state))
    builder.addCase(fetchFysiekQuery.fulfilled, (state, action) => {
      setSucceededSate(state)
      entityAdapter.setAll(state, action.payload.items)
      state.query = action.meta.arg.query
      state.searchFilter = action.meta.arg.filter
    })
    builder.addCase(fetchFysiekQuery.rejected, (state, action) => setRejectedState(state, action))
    builder.addCase(deleteVge.pending, state => setPendingSate(state))
    builder.addCase(deleteVge.fulfilled, (state, action) => {
      entityAdapter.removeMany(state, action.meta.arg)
      setSucceededSate(state)
        })
    builder.addCase(deleteVge.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}
export const getSelectedRowIndex = (state: RootState) => {
  return getSliceState(state).selectedRowIndex
}

export const getRecordsFound = (state: RootState) => {
  return getSliceState(state).ids.length
}

export const getErrorState = (state: RootState) => {
  return getSliceState(state).error
}
export const getFysiekQueryState = (state: RootState) => {
  return getSliceState(state).query
}
export const getFilterState = (state: RootState) => {
  return getSliceState(state).searchFilter
}
export const getPresentationType = (state: RootState) => getSliceState(state).presentation

export const { selectAll } = entityAdapter.getSelectors<RootState>(state => getSliceState(state))
export const { setSelectedRowIndex, setPresentationType } = fysiekQuery.actions

export default fysiekQuery.reducer
