import React from 'react'
import {Controller} from 'react-hook-form'
import NumericVgtInput from "./NumericVgtInput";

interface IVgtFieldProps {
    name: string
    label: string
    control: any
    errors: any
    register?: any
    required?: boolean
    readOnly?: boolean
    defaultValue?: string
    height?: string | number
    labelWidth?: number
    autoAdjustHeight?: boolean
    disabled?: boolean
}

const CurrencyField: React.FC<IVgtFieldProps> = props => {
    return (
        <Controller
            name={props.name}
            control={props.control}
            render={({onChange, value}) => (
                <NumericVgtInput value={value} onChange={onChange} label={props.label} errors={props.errors} name={props.name}/>
            )}
        />
    )
}

export default CurrencyField