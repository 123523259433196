import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePagedParams } from '../../../../services/usePagedParams'
import { useBoolean } from '@fluentui/react-hooks'
import { VerwarmingListDto, zodiosHooks } from '../../../../api/ApiClient'
import { IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { z } from 'zod'
import { VerwarmingFormPanel } from './VerwarmingFormPanel'
import { getTitleAndMessage } from '../../../../services/HandleError'

type ListDtoType = z.infer<typeof VerwarmingListDto>;

export const VerwarmingList = () => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [itemToEdit, setItemToEdit] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = React.useState<string>()

  const { data, invalidate, isLoading } = zodiosHooks.useGetVerwarmingen({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteVerwarmingen({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
    {
      text: 'Toevoegen',
      onClick: () => {
        openPanel()
      },
      icon: 'Add',
      iconOnly: true,
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        deleteRows({ itemsToDelete: selectedIds })
      },
      icon: 'Delete',
      iconOnly: true,
      disabled: isDeleting || selectedIds.length === 0
    }
  ], [selectedIds.length])

  const handleItemInvoked = (id: string) => {
    if (id) {
      setItemToEdit(parseInt(id))
      openPanel()
    }
  }

  const handleDismissPanel = () => {
    setItemToEdit(0)
    dismissPanel()
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Code', getPropertyName<ListDtoType>('code'), 'XL', true,
        (item: ListDtoType) => (
          <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
            {item.code}
          </Link>
        )),
      createColumn('Levering van warmte', getPropertyName<ListDtoType>('leveringVanWarmte'), 'XL', false),
      createColumn('Wijze van verwarming', getPropertyName<ListDtoType>('wijzeVanVerwarming'), 'XL', false),
    ]
  }, [])

  return (
    <MainTemplate title='Verwarming' subTitle='Overzicht' commandItems={commandItems} error={error}>
      <VerwarmingFormPanel id={itemToEdit} dismissPanel={handleDismissPanel} isOpen={isPanelOpen} invalidate={invalidate} />
      <VgtPagedTable
        items={data?.items ?? []}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage ?? false,
          hasPreviousPage: data?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey ?? 'naam',
          sortDirection: validatedSearchParams.sortDirection ?? 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.id}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.id.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />

    </MainTemplate>
  )
}