import React, { useEffect, useMemo, useState } from 'react'
import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, ScrollablePane, SelectionMode, Spinner } from '@fluentui/react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAll, selectAll } from './slice'
import { selectAll as selectSoorten, fetchAll as fetchEenheiddetailsoorten } from '../eenheidDetailSoortSlice'
import { IDefectLocatie, selectAll as selectAllDefectlocaties, fetchAll as fetchAllDefectlocaties } from '../../defectLocatie/defectLocatieSlice'
import IEenheidDetailSoort from '../eenheidDetailSoortModel'
import '../../common/lemdoList.css'
import '../../common/grid.css'
import { Dictionary } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import downloadFile from 'services/downloadFile'
import UploadPanel from 'components/UploadPanel'
import { useBoolean } from '@fluentui/react-hooks'
import { IMatrixRow } from '../../common/matrixSelectionTypes'
import { MatrixCheckBox, MatrixCell } from '../../common/matrixSelection'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { IEenheiddetailsoortDefectlocatie } from './model'

const createMatrix = (defectlocaties: IDefectLocatie[], details: IEenheiddetailsoortDefectlocatie[], soorten: IEenheidDetailSoort[]): IMatrixRow[] => {
  const result = [] as IMatrixRow[]
  if (defectlocaties.length === 0 && soorten.length === 0) {
    return result
  }

  for (let index = 0; index < defectlocaties.length; index++) {
    const defectlocatie = defectlocaties[index]
    const selectedCells = {} as Dictionary<string>
    const eenheiddetailsoortRow = details.filter(detail => detail.defectlocatieId === defectlocatie.id)
    eenheiddetailsoortRow.forEach(r => (selectedCells[r.eenheiddetailsoortId.toString()] = '1'))
    result.push({
      rowId: defectlocatie.id,
      cells: selectedCells,
    })
  }
  return result
}

interface IDefectlocatieMatrixProps {
  binnen: boolean
}

const DefectlocatieMatrix: React.FC<IDefectlocatieMatrixProps> = props => {
  let binnenBuitenText = props.binnen ? 'binnen' : 'buiten'
  const downloadUrl = `/eenheidDetailSoort/exportDefectlocaties?binnendewoning=${props.binnen.toString()}`
  const filenameDescription = `eenheiddetailsoort defectlocaties ${binnenBuitenText}.xlsx`
  const ComponentTitle = `Eenheiddetailsoorten || Defectlocaties ${binnenBuitenText}`
  const uploadUrl = `/eenheidDetailSoort/importDefectlocaties${binnenBuitenText}`

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const soorten = useSelector(selectSoorten)
  const defectlocaties = useSelector(selectAllDefectlocaties)
  const eenheiddetailsoortDefectlocaties = useSelector(selectAll)
  const items = useMemo(
    () => createMatrix(defectlocaties, eenheiddetailsoortDefectlocaties, soorten),
    [defectlocaties, eenheiddetailsoortDefectlocaties, soorten, props.binnen]
  )
  const [loading, setLoading] = useState(false)
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)

  const refreshItems = () => {
    dispatch(fetchEenheiddetailsoorten())
    dispatch(fetchAllDefectlocaties())
    dispatch(fetchAll(props.binnen))
    setLoading(true)
  }

  useEffect(() => {
    if (soorten.length === 0) dispatch(fetchEenheiddetailsoorten())
    if (defectlocaties.length === 0) dispatch(fetchAllDefectlocaties())
    dispatch(fetchAll(props.binnen))
    setLoading(true)
  }, [props.binnen])

  useEffect(() => {
    setLoading(defectlocaties.length + soorten.length === 0)
  }, [items])

  const soortColumns = soorten.map<IColumn>((s: IEenheidDetailSoort) => {
    return {
      key: s.id.toString(),
      name: `${s.code} - ${s.naam}`,
      minWidth: 25,
      maxWidth: 25,
      isResizable: false,
      isRowHeader: true,
      headerClassName: 'headerClass',
      onRender: (item: IMatrixRow) => <MatrixCheckBox id={s.id} titles={item.cells} />,
    } as IColumn
  })
  const columns = [
    {
      key: 'defectlocatie',
      isRowHeader: true,
      minWidth: 250,
      maxWidth: 400,
      onRender: (item: IMatrixRow) => <MatrixCell text={createDefectlocatieText(item)} />,
    } as IColumn,
  ].concat(soortColumns) as IColumn[]

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
      {
        text: 'Exporteer naar Excel',
        onClick: () => {
          downloadFile(downloadUrl, filenameDescription)
        },
        icon: 'Export',
      },
      { text: 'Importeer van Excel', onClick: () => openPanel(), icon: 'Import' },
    ],
    [downloadUrl, filenameDescription]
  )

  const createDefectlocatieText = (row: IMatrixRow) => {
    var defectlocatie = defectlocaties.filter(l => l.id === row.rowId)
    if (defectlocatie.length !== 0) return `${defectlocatie[0].code} - ${defectlocatie[0].naam}`
    return ''
  }

  const handleDismiss = () => {
    dismissPanel()
    refreshItems()
  }

  return loading ? (
    <div className="spinnerWrapper">
      <Spinner>Laden...</Spinner>
    </div>
  ) : (
    <MainTemplate title={ComponentTitle} subTitle="Overzicht" commandItems={commandItems}>
      <UploadPanel title="Importeer wijzigigingen" dismissPanel={handleDismiss} isOpen={isOpen} uploadEndpoint={uploadUrl} />
      <div style={{ height: '85vh', position: 'relative' }}>
        <ScrollablePane>
          <DetailsList
            columns={columns}
            items={items}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            constrainMode={ConstrainMode.unconstrained}
            compact={true}
            className="matrixGrid"
          />
        </ScrollablePane>
      </div>
    </MainTemplate>
  )
}

export default DefectlocatieMatrix
