import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState';
import { invokeFetch } from 'services/apiClient';
import { RootState } from 'store';
import * as yup from 'yup'

export const TenantKostensoortBetalerSchema = yup.object().shape({
})

export interface ITenantKostensoortBetaler {
    betalerOptie: number
    betalerNaam: string
    kostensoortId: number
    tenant_id: number
}

const entityAdapter = createEntityAdapter<ITenantKostensoortBetaler>({
  selectId: e => e.betalerOptie,
  sortComparer: (a, b) => a.betalerNaam.localeCompare(b.betalerNaam)
})

const baseUrl = '/tenantKostensoortBetaler'
const basePrefix = 'tenantKostensoortBetaler'
const getSliceState = (state: RootState) => state.tenantKostensoortBetaler

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<Array<ITenantKostensoortBetaler>>(thunkAPI, 'GET', `${baseUrl}`)
  })
  
  export const updateTenantKostensoortBetaler = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: ITenantKostensoortBetaler, thunkAPI) => {
    return await invokeFetch<ITenantKostensoortBetaler>(thunkAPI, 'PUT', `${baseUrl}/${entity.betalerOptie}`, entity)
  })
  
  export const addTenantKostensoortBetaler = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: ITenantKostensoortBetaler, thunkAPI) => {
    return await invokeFetch<ITenantKostensoortBetaler>(thunkAPI, 'POST', baseUrl, entity)
  })
  
  export const deleteTenantKostensoortBetaler = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
  })
  
  const setPendingState = (state: EntityState<ITenantKostensoortBetaler> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<ITenantKostensoortBetaler> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<ITenantKostensoortBetaler> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }
  
  export const tenantKostensoortBetalerSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),
  
    reducers: {
      clearError: state => {
        state.error = null
      },
      select: (state, action: PayloadAction<string | undefined>) => {
        state.selectedId = action.payload
      },
      clearSelection: state => {
        state.selectedId = undefined
      },
      add: entityAdapter.addOne,
      modify: entityAdapter.upsertOne,
      removeMany: entityAdapter.removeMany,
      setAll: entityAdapter.setAll,
    },
  
    extraReducers: builder => {
      builder.addCase(fetchAll.pending, state => setPendingState(state))
      builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<Array<ITenantKostensoortBetaler>>) => {
        setSucceededState(state)
        entityAdapter.setAll(state, action.payload)
      })
      builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(updateTenantKostensoortBetaler.pending, state => setPendingState(state))
      builder.addCase(updateTenantKostensoortBetaler.fulfilled, (state, action: PayloadAction<ITenantKostensoortBetaler>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(updateTenantKostensoortBetaler.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(addTenantKostensoortBetaler.pending, state => setPendingState(state))
      builder.addCase(addTenantKostensoortBetaler.fulfilled, (state, action: PayloadAction<ITenantKostensoortBetaler>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(addTenantKostensoortBetaler.rejected, (state, action) => {
        setRejectedState(state, action)
      })
      builder.addCase(deleteTenantKostensoortBetaler.pending, state => setPendingState(state))
      builder.addCase(deleteTenantKostensoortBetaler.fulfilled, (state, action) => {
        entityAdapter.removeMany(state, action.meta.arg)
        setSucceededState(state)
      })
      builder.addCase(deleteTenantKostensoortBetaler.rejected, (state, action) => setRejectedState(state, action))
    },
  })
  
  export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
  }

  export const getLoadingState = (state: RootState) => state.tenantKostensoortBetaler.status
  
  export const getErrorState = (state: RootState) => state.tenantKostensoortBetaler.error
  
  export const { add, modify, removeMany, select, clearSelection } = tenantKostensoortBetalerSlice.actions
  export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.tenantKostensoortBetaler)
  
  export default tenantKostensoortBetalerSlice.reducer
  