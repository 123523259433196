import { Link } from '@fluentui/react'
import React from 'react'
import ReadOnlyField from '../ReadOnlyField'
import TooltipComponent from './Tooltip'

interface ITooltipFieldProps {
  tooltipId: string
  title: string
  data: { key: string, value: string | null }[]
  onClick: (key: string) => void
  labelWidth?: number
  fieldWidth?: number
}

export const VgtTooltip: React.FC<ITooltipFieldProps> = props => {

  return (

    <TooltipComponent
      content={
        <>
          <div>{props.title}:</div>
          <ul style={{ margin: 10, padding: 0 }}>
            {(props.data || []).map((item, index) => (
              <li key={index} style={{ margin: '18px 0' }}>
                <Link style={{ cursor: 'pointer'}} onClick={() => props.onClick(item.key)}>{item.value}</Link>
                <br />
              </li>
            ))}
          </ul>
        </>
      }
    >
      <ReadOnlyField
        title={props.title}
        value={props.data.map(item => item.value).join(', ')}
        fieldWidth={props.fieldWidth ?? '100%'}
        labelWidth={props.labelWidth ?? 165}
        autoAdjustHeight
      />
    </TooltipComponent>
  )
}
