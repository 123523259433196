import {
  ActivityItem,
  Link as FluentLink,
  Panel,
  PanelType,
  Spinner,
  SpinnerSize,
  Sticky,
  StickyPositionType,
  mergeStyleSets
} from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import { KvkDetailsDto, zodiosHooks } from '../../../../api/ApiClient'
import { z } from 'zod'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'

type KvKType = z.infer<typeof KvkDetailsDto>;

interface IKVKSearchPanelProps {
  isOpen: boolean
  dismissPanel: () => void
  onOpen?: () => void
  onSelected: (item: KvKType) => void
  handelsNaam: string
  children?: React.ReactNode
}

const classNames = mergeStyleSets({
  itemsContainer: {
    marginTop: '10px',
    overflowY: 'auto',
    cursor: 'pointer'
  },
  exampleRoot: {
    marginTop: '6px',
    marginBottom: '6px'
  },
  nameText: {
    fontWeight: 'bold'
  },
  ActivityItemContainer: {
    borderTop: '1px solid #ede8e8',
    borderBottom: '1px solid #ede8e8',
    selectors: {
      ':hover': {
        background: '#ede8e8'
      }
    }
  },
  Spinner: {
    marginTop: '40vh'
  }
})


const SearchPanelKVK: React.FC<IKVKSearchPanelProps> = props => {
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [vestigingsnummer, setVestigingsnummer] = useState<string | undefined>('')

  const { data, isLoading } = zodiosHooks.useGetApiKvksearch({
    queries: {
      Filter: search
    },
    enabled: search !== undefined
  })

  const { data: details, isLoading: isLoadingDetails} = zodiosHooks.useGetApiKvkdetails({
    queries: {
      VestigingsNummer: vestigingsnummer
    },
    enabled: vestigingsnummer !== undefined
  })

  useEffect(() => {
    if (details) {
      props.onSelected(details)
      reset()
    }
  }, [details])

  const onSearchClicked = (newValue: string) => {
    setSearch(newValue)
  }

  const reset = () => {
    props.dismissPanel()
    setVestigingsnummer(undefined)
  }

  const onSelected = async (item: KvKType) => {
    setVestigingsnummer(item.vestigingsNummer ?? undefined)
    if (!item.vestigingsNummer) {
      props.onSelected(item)
      reset()
    }
  }

  return (
    <Panel type={PanelType.medium} headerText='Zoeken in het handelsregister' isOpen={props.isOpen} isBlocking={false}
           onDismiss={reset} onOpen={props.onOpen} closeButtonAriaLabel='Sluiten'>
      <Sticky stickyPosition={StickyPositionType.Header}>
        <VgtSearch filter={''} onSearch={onSearchClicked}/>
     </Sticky>
      {isLoading || isLoadingDetails ? <Spinner size={SpinnerSize.large} className={classNames.Spinner} /> :
        <div className={classNames.itemsContainer}>
          {
            data?.items?.map(item => {
              return (
                <div key={'main' + item.kvkNummer + item.vestigingsNummer} className={classNames.ActivityItemContainer}
                     onClick={() => onSelected(item)}>
                  <ActivityItem key={'act' + item.kvkNummer + item.vestigingsNummer}
                                activityDescription={[<FluentLink key={'lnk' + item.kvkNummer + item.vestigingsNummer}
                                                                  className={classNames.nameText}>Handelsnaam: {item.naam}</FluentLink>]}
                                comments={[<div key={item.kvkNummer}>KVK Nummer: {item.kvkNummer}</div>,
                                  <div
                                    key={'v' + item.vestigingsNummer}>Vestigingsnummer: {item.vestigingsNummer}</div>,
                                  <div key={'w' + item.vestigingsNummer}>Woonplaats: {item.woonplaats}</div>,
                                  <div key={'s' + item.vestigingsNummer}>Straat: {item.straat}</div>]}
                                className={classNames.exampleRoot} />
                </div>
              )
            })
          }
        </div>
      }
    </Panel>
  )
}

export default SearchPanelKVK
