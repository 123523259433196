import { DefaultButton, IDropdownOption, PanelType, Stack } from "@fluentui/react"
import { getPropertyName } from "lib/interfaceUtils"
import { Controller, useForm } from "react-hook-form"
import React, { useEffect, useState } from "react"
import VgtDropdown from "components/FluentDropdown"
import VgtTextField from "components/VgtTextField"
import { z } from "zod";
import {
    GebouwOnderhoudsbedrijfDto,
    UpdateGebouwOnderhoudsbedrijfCommand,
    zodiosHooks
} from "../../../../../api/ApiClient";
import { zodResolver } from "@hookform/resolvers/zod";
import { getTitleAndMessage } from "../../../../../services/HandleError";
import FluentPanel from "../../../../../components/FluentPanel/FluentPanel";
import { FieldErrorsToMessage } from "../../../../../components/ErrorMessageBar/ErrorMessageBar";

type IGebouwOnderhoudsbedrijf = z.infer<typeof GebouwOnderhoudsbedrijfDto>;
type CreateUpdateType = z.infer<typeof UpdateGebouwOnderhoudsbedrijfCommand>;

interface IEditPanelProps {
    isOpen: boolean
    dismissPanel: any
    gebouwId: number
    selectedEntity: IGebouwOnderhoudsbedrijf | undefined
    afterSubmit: () => void
}

export const GebouwOnderhoudsbedrijfEditPanel: React.FC<IEditPanelProps> = (props: IEditPanelProps) => {
    const [title, setTitle] = useState('')
    const [error, setError] = useState<string>()
    const {data: rechtspersonen} = zodiosHooks.useGetRechtspersonen();

    const defaultValues = {
        gebouwId: props.gebouwId,
        onderhoudsbedrijfTyperingId: props.selectedEntity?.onderhoudsbedrijfTyperingId ?? 0,
        rechtpersoonId: props.selectedEntity?.rechtspersoonId ?? 0,
        handelsNaam: '',        
    };

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: {isSubmitting, errors},
    } = useForm<CreateUpdateType>({
        resolver: zodResolver(UpdateGebouwOnderhoudsbedrijfCommand), mode: 'all', defaultValues: defaultValues
    })

    const {mutate: addGebouwOnderhoudsbedrijf} = zodiosHooks.usePostGebouwOnderhoudsbedrijf({}, {
        onSuccess: () => onCancel(),
        onError: (error) => setError(getTitleAndMessage(error).message)
    });
    const {mutate: updateGebouwOnderhoudsbedrijf} = zodiosHooks.usePutGebouwOnderhoudsbedrijfId(
        {params: {id: props.selectedEntity?.onderhoudsbedrijfTyperingId ?? 0}}, {
            onSuccess: () => onCancel(),
            onError: (error) => setError(getTitleAndMessage(error).message)
        }
    );

    useEffect(() => {
        setTitle(`Gebouw Onderhoudsbedrijf ${props.selectedEntity ? 'wijzigen' : 'toevoegen'}`)
        if (props.selectedEntity) {
            reset({
                gebouwId: props.gebouwId,
                onderhoudsbedrijfTyperingId: props.selectedEntity?.onderhoudsbedrijfTyperingId ?? 0,
                rechtspersoonId: props.selectedEntity?.rechtspersoonId ?? 0
            })
        } else {
            reset({})
        }
    }, [props.selectedEntity])

    const onCancel = () => {
        props.dismissPanel()
        props.afterSubmit()
    }

    const getOnderhoudsbedrijfAsOptions = () => {
        if (!rechtspersonen?.items) return [] as IDropdownOption[]

        const nullOption = {key: 0, text: '---'} as IDropdownOption
        const leverancierRechtspersonenOptions = rechtspersonen.items.filter(x => x.rolId === 1).map(x => ({
            key: x.id,
            text: `${x.handelsnaam}`
        })) as IDropdownOption[]
        return [nullOption, ...leverancierRechtspersonenOptions]
    }

    const onSubmit = (data: CreateUpdateType) => {
        if (props.selectedEntity) {
            data.onderhoudsbedrijfTyperingId = props.selectedEntity.onderhoudsbedrijfTyperingId
            data.gebouwId = props.gebouwId
            if (!props.selectedEntity.rechtspersoonId) {
                addGebouwOnderhoudsbedrijf(data)
            } else {
                updateGebouwOnderhoudsbedrijf(data)
            }
        }
    }

    return (
        <div>
            <FluentPanel type={PanelType.medium} headerText={title} isOpen={props.isOpen} onDismiss={onCancel}
                         closeButtonAriaLabel="Sluiten" error={error}>
                <br/>
                <VgtTextField label="Type onderhoudsbedrijf"
                              name={''} defaultValue={props.selectedEntity?.typeringNaam ?? ''} register={register}
                              control={control} errors={errors} readOnly/>
                <VgtDropdown
                    options={getOnderhoudsbedrijfAsOptions()}
                    label='Onderhoudsbedrijf'
                    name={getPropertyName<CreateUpdateType>('rechtspersoonId')}
                    register={register}
                    control={control}
                    errors={errors}
                />
                <Controller
                    name={getPropertyName<CreateUpdateType>('gebouwId')}
                    control={control}
                    defaultValue={"-1"}
                    render={()=><input type="hidden"/>}
                />
                <Controller
                    name={getPropertyName<CreateUpdateType>('onderhoudsbedrijfTyperingId')}
                    control={control}
                    defaultValue={"-1"}
                    render={()=><input type="hidden"/>}
                />
                <br/>
                <Stack horizontal horizontalAlign={'end'} tokens={{childrenGap: '12 12'}}>
                    <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting}
                                   onClick={
                                        handleSubmit(onSubmit, (errors) => {
                                       console.log(errors)
                                       setError(FieldErrorsToMessage(errors))
                                   })}/>
                    <DefaultButton text="Annuleren" onClick={onCancel}/>
                </Stack>
            </FluentPanel>
        </div>
    )
}
