import React, { useEffect, useState } from 'react'
import { DefaultButton, Panel, PanelType, Stack } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { getPropertyName } from 'lib/interfaceUtils'
import VgtTextField from 'components/VgtTextField'
import VgtCheckbox from 'components/FluentCheckbox'
import CoedCadCodeSelection, {
  createCoenCadItems,
  ICoenCadCodeItem
} from 'features/beheer/coencad/coenCadCodeSelection'
import useRoles from 'services/UseRoles'
import VgtDropdown from '../../../../components/FluentDropdown'
import { CoenCadRuimteSoortDto, CreateRuimteSoortCommand, zodiosHooks } from '../../../../api/ApiClient'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { ICoenCadCode, IEntityCoenCadCodes } from '../../coencad/coenCadCode'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

type CoenCadRuimteSoortDtoType = z.infer<typeof CoenCadRuimteSoortDto>;
type CreateRuimteSoortCommandType = z.infer<typeof CreateRuimteSoortCommand>;

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  selectedRuimteSoort: CoenCadRuimteSoortDtoType | undefined
  fetchRuimteSoort: () => void
}

const EditPanelRuimteSoorten: React.FC<IEditPanelProps> = ({
                                                             isOpen,
                                                             selectedRuimteSoort,
                                                             dismissPanel,
                                                             fetchRuimteSoort
                                                           }) => {
  const [title, setTitle] = useState('Ruimte Soort')
  const [coenCadItems, setCoenCadItems] = useState<ICoenCadCodeItem[]>([])
  const { isAdmin } = useRoles()
  const [error, setError] = React.useState<string>()

  const onDataSubmitttedSuccesfully = () => {
    fetchRuimteSoort()
    dismissPanel()
  }

  const { data: coenCad } = zodiosHooks.useGetApiCoenCad({}, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { data: defectLocatieItems } = zodiosHooks.useGetDefectLocatie({}, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: postRuimteSoort } = zodiosHooks.usePostRuimteSoort({}, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: putRuimteSoortId } = zodiosHooks.usePutRuimteSoortId(
    { params: { id: selectedRuimteSoort?.id! } }, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { data: FontIcoonOptions } = zodiosHooks.useGetApiFonticoon()

  const fontIcons = FontIcoonOptions?.items?.filter(item => item.naam !== null)
    .map(item => ({ key: item.fonticoonId, text: item.omschrijving! }))

  const defectlocatiesOptions = defectLocatieItems?.items?.filter(item => item.naam !== null || item.code !== null)
    .map(item => ({ key: item.id, text: item.code! + ' - ' + item.naam }))

  const defaultRuimteSoort = {
    id: selectedRuimteSoort?.id ?? 0,
    code: selectedRuimteSoort?.code ?? '',
    omschrijving: selectedRuimteSoort?.omschrijving ?? '',
    wwd: selectedRuimteSoort?.wwd ?? false,
    vertrek: selectedRuimteSoort?.vertrek ?? false,
    overigeRuimte: selectedRuimteSoort?.overigeRuimte ?? false,
    buitenRuimte: selectedRuimteSoort?.buitenRuimte ?? false,
    verkeersruimte: selectedRuimteSoort?.verkeersruimte ?? false,
    ingangsDatum: new Date().toJSON(),
    eindDatum: selectedRuimteSoort?.eindDatum ?? null,
    veraVersie: selectedRuimteSoort?.veraVersie ?? null,
    aantal: selectedRuimteSoort?.aantal ?? null,
    defectLocatieId: selectedRuimteSoort?.defectLocatieId ?? null,
    fonticoonId: selectedRuimteSoort?.fonticoonId ?? null,
    fonticoonNaam: selectedRuimteSoort?.fonticoonNaam ?? '',
    defectLocatieNaam: selectedRuimteSoort?.defectLocatieNaam ?? '',
    coenCadCodes: selectedRuimteSoort?.coenCadCodes ?? null
  } as CreateRuimteSoortCommandType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateRuimteSoortCommandType>({ mode: 'all' })

  useEffect(() => {
    setTitle(`Ruimtesoorten ${selectedRuimteSoort ? isAdmin ? 'wijzigen' : '' : 'toevoegen'}`)
    if (selectedRuimteSoort) {
      reset(selectedRuimteSoort)
    } else {
      reset({})
    }

    const ccItems = createCoenCadItems(coenCad?.items! as ICoenCadCode[], selectedRuimteSoort as IEntityCoenCadCodes)
    setCoenCadItems(ccItems)
  }, [selectedRuimteSoort])

  const onCancel = () => {
    setError(undefined)
    dismissPanel()
  }

  const onSubmit = (data: CreateRuimteSoortCommandType) => {
    data.coenCadCodes = coenCadItems
      .filter(i => i.selected)
      .map(i => ({
        description: i.description ?? null,
        code: i.code ?? null,
        id: i.id,
        key: i.key ?? null,
        groep: i.groep ?? null,
        elementElementDetailId: i.elementElementDetailId ?? null,
        elementElementDetailOmschrijving: i.elementElementDetailOmschrijving ?? null
      }))

    if (selectedRuimteSoort) {
      data.id = selectedRuimteSoort.id
      putRuimteSoortId({ ...defaultRuimteSoort, ...data })
    } else {
      postRuimteSoort({ ...defaultRuimteSoort, ...data })
    }
  }

  return (
    <div>
      <Panel type={PanelType.large} headerText={title} isOpen={isOpen} onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <ErrorMessageBar error={error} />
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField label='Code' name={getPropertyName<CreateRuimteSoortCommandType>('code')} register={register}
                        control={control}
                        errors={errors} required readOnly={!isAdmin} />
          <VgtTextField
            label='Omschrijving'
            name={getPropertyName<CreateRuimteSoortCommandType>('omschrijving')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          />
          <VgtCheckbox
            label='WWD'
            name={getPropertyName<CreateRuimteSoortCommandType>('wwd')}
            register={register} control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          ></VgtCheckbox>
          <VgtCheckbox
            label='Vertrek'
            name={getPropertyName<CreateRuimteSoortCommandType>('vertrek')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          ></VgtCheckbox>
          <VgtCheckbox
            label='Overige ruimte'
            name={getPropertyName<CreateRuimteSoortCommandType>('overigeRuimte')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          ></VgtCheckbox>
          <VgtCheckbox
            label='Buitenruimte'
            name={getPropertyName<CreateRuimteSoortCommandType>('buitenRuimte')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          ></VgtCheckbox>
          <VgtCheckbox
            label='Verkeersruimte'
            name={getPropertyName<CreateRuimteSoortCommandType>('verkeersruimte')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          ></VgtCheckbox>
          <VgtTextField
            label='Aantal'
            name={getPropertyName<CreateRuimteSoortCommandType>('aantal')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          ></VgtTextField>
          <VgtDropdown
            options={defectlocatiesOptions}
            name={getPropertyName<CreateRuimteSoortCommandType>('defectLocatieId')}
            label='Defectlocatie'
            control={control}
            errors={errors}
            register={register}
            readOnly={!isAdmin}
            required
          />
          <VgtDropdown
            options={fontIcons}
            label='Fonticoon'
            name={getPropertyName<CreateRuimteSoortCommandType>('fonticoonId')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isAdmin}
          />
          <br />
          <CoedCadCodeSelection items={coenCadItems} onChanged={items => setCoenCadItems(items)} />
          <br />
          {isAdmin ?
            <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
              <DefaultButton text='Opslaan' type='submit' primary disabled={isSubmitting}
                             onClick={handleSubmit(onSubmit)} />
              <DefaultButton text='Annuleren' onClick={onCancel} />
            </Stack> : null
          }
        </form>
      </Panel>
    </div>
  )
}

export default EditPanelRuimteSoorten
