export interface ISuggestResult {
  type: string
  weergavenaam: string
  id: string
  score: number
}
export interface ISuggestData {
  response: {
    numFound: number
    start: number
    maxScore: number
    docs: Array<ISuggestResult>
  }
}

export interface IGeoAdres {
  bron: string
  woonplaatscode: string
  type: string
  woonplaatsnaam: string
  gemeentenaam: string
  wijkcode: string
  wijknaam: string
  buurtcode: string
  buurtnaam: string
  huis_nlt: string
  openbareruimtetype: string
  gemeentecode: string
  gekoppeld_perceel: string
  weergavenaam: string
  waterschapsnaam: string
  openbareruimte_id: string
  provincienaam: string
  centroide_ll: string
  adresseerbaarobject_id: string
  nummeraanduiding_id: string
  straatnaam: string
  huisnummer: string
}

export interface ILookupAdres {
  response: {
    numFound: number
    start: number
    maxScore: number
    docs: Array<IGeoAdres>
  }
}

export async function geoRegisterSuggestForType(type: string, query: string, maxRows: string): Promise<ISuggestData> {
  const url = `https://geodata.nationaalgeoregister.nl/locatieserver/v3/suggest?fq=type:${type}&q=${query}&rows=${maxRows}`

  const response = await window.fetch(url)
  if (response.ok) {
    const data: ISuggestData = await response.json()
    return data
  } else {
    return Promise.reject(response.statusText)
  }
}

export async function geoRegisterLookupAny(id: string): Promise<any> {
  const url = `https://geodata.nationaalgeoregister.nl/locatieserver/v3/lookup?id=${id}`
  const response = await window.fetch(url)
  if (response.ok) {
    const data = await response.json()
    if (data.response.numFound >= 1) {
      const adres = data.response.docs[0]
      if (adres.centroide_ll) {
        adres.centroide_ll = adres.centroide_ll.replace('POINT(', '').replace(')', '').split(' ').reverse().join(', ')
      }
      return data.response.docs[0]
    }
    return Promise.reject('Not found')
  } else {
    return Promise.reject(response.statusText)
  }
}

export async function geoRegisterSuggest(straat?: string, huisnummer?: string, huisletter?: string, huisnummertoevoeging?: string, woonplaats?: string): Promise<ISuggestData> {
  if (!huisnummer) {
    return Promise.reject('Geen adresseerbaar object')
  }

  let query = ''
  if (straat) query += straat
  if (huisnummer) query += ' ' + huisnummer
  if (huisletter) query += ' ' + huisletter
  if (huisnummertoevoeging) query += ' ' + huisnummertoevoeging
  if (woonplaats) query += ' ' + woonplaats
  const url = `https://geodata.nationaalgeoregister.nl/locatieserver/v3/suggest?fq=type:adres&q=${query}&rows=10`

  const response = await window.fetch(url)
  if (response.ok) {
    const data: ISuggestData = await response.json()
    if (data.response.numFound >= 1) {
      return data
    }
    return Promise.reject('Not found')
  } else {
    return Promise.reject(response.statusText)
  }
}

export async function geoRegisterLookup(id: string): Promise<IGeoAdres> {
  const url = `https://geodata.nationaalgeoregister.nl/locatieserver/v3/lookup?id=${id}`

  const response = await window.fetch(url)
  if (response.ok) {
    const data: ILookupAdres = await response.json()
    if (data.response.numFound >= 1) {
      const adres = data.response.docs[0]
      if (adres.centroide_ll) {
        adres.centroide_ll = adres.centroide_ll.replace('POINT(', '').replace(')', '').split(' ').reverse().join(', ')
      }
      return data.response.docs[0]
    }
    return Promise.reject('Not found')
  } else {
    return Promise.reject(response.statusText)
  }
}
