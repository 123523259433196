import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { useNavigate, useParams } from 'react-router-dom'
import useApi, { Status } from 'services/UseApi'
import { DimensiewaardeForm } from './DimensiewaardenForm'
import { IDimensiewaardeUpdateOrAdd } from './DimensiewaardeApi'

const DimensiewaardenEdit = () => {
  const navigate = useNavigate()
  const { id, dimensieId } = useParams()
  if (!parseInt(id ?? '')) {
    throw new Error('Invalid id')
  }
  if (!parseInt(dimensieId ?? '')) {
    throw new Error('Invalid dimensie id')
  }

  const url = `dimensies/${dimensieId}/waarden/${id}`
  const { state } = useApi<IDimensiewaardeUpdateOrAdd>(url, 'GET')
  const { state: updateState, execute: update } = useApi<IDimensiewaardeUpdateOrAdd>(url, 'PUT', false)

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
  ]

  return (
    <MainTemplate title='Dimensiewaarde' subTitle='bewerken' commandItems={commandItems} error={state.error || updateState.error}>
      <DimensiewaardeForm onSubmit={(data) => update(data, () => navigate(-1))} isExecuting={state.status === Status.loading} data={state.data} onCancel={() => navigate(-1)} />
    </MainTemplate>
  )
}

export default DimensiewaardenEdit