import useRoles from '../../../../services/UseRoles'
import React, { useEffect, useMemo } from 'react'
import {Controller, useForm} from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  DefectoorzaakKostensoortAddOrUpdateDto,
  DefectoorzaakKostensoortListDto,
  zodiosHooks
} from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { z } from 'zod'
import { IDropdownOption, Panel, PanelType } from '@fluentui/react'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import VgtDropdown from '../../../../components/FluentDropdown'
import ReadOnlyField from '../../../../components/ReadOnlyField'
import { addZeroDropdownOption } from '../../../../lib/controlSupport'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

type DefectoorzaakKostensoortListDtoType = z.infer<typeof DefectoorzaakKostensoortListDto>;

export interface IEditPanelProps {
  defectoorzaakKostensoort : DefectoorzaakKostensoortListDtoType
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof DefectoorzaakKostensoortAddOrUpdateDto>;

export const DefectoorzaakKostensoortFormPanel: React.FC<IEditPanelProps> = ({
                                                                               defectoorzaakKostensoort,
                                                                               dismissPanel,
                                                                               isOpen,
                                                                               invalidate
                                                                             }) => {
  const { isReparatieboom } = useRoles()
  const [error, setError] = React.useState<string>()

  const defaultDefectoorzaakKostensoort = {
    defectoorzaakId: defectoorzaakKostensoort.defectoorzaakId,
    kostensoortId: defectoorzaakKostensoort.kostensoortId
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(DefectoorzaakKostensoortAddOrUpdateDto),
    mode: 'all',
    defaultValues: defaultDefectoorzaakKostensoort
  })

  const {
    mutate: setDefectoorzaakKostensoort,
    isLoading: isUpdatingDefectoorzaakKostensoort
  } = zodiosHooks.usePutDefectoorzaakKostensoortId({ params: { id: defectoorzaakKostensoort.defectoorzaakId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const onCancel = () => {
    invalidate()
    reset({})
    setError('')
    dismissPanel()
  }

  useEffect(() => {
    if (defectoorzaakKostensoort.kostensoortId) {
      reset({
        defectoorzaakId: defectoorzaakKostensoort.defectoorzaakId,
        kostensoortId: defectoorzaakKostensoort.kostensoortId
      })
    } else {
      reset({})
    }
  }, [defectoorzaakKostensoort])

  const onSubmit = (data: CreateUpdateType) => {
    if (data.kostensoortId === 0) {
      data.kostensoortId = null
    }
    data.defectoorzaakId = defectoorzaakKostensoort.defectoorzaakId
    setDefectoorzaakKostensoort(data)
  }

  const { data: kostensoort } = zodiosHooks.useGetKostensoort({})
  const kostensoortOpties = kostensoort?.items?.map((item) => {
    return {
      key: item.kostensoortId,
      text: `${item.code} - ${item.omschrijving}`
    } as IDropdownOption
  }) || []

  const kostensoortOptions = useMemo(() => addZeroDropdownOption(kostensoortOpties), [kostensoortOpties])

  return (
    <div>
      <Panel type={PanelType.large}
             headerText={'Defectoorzaak Kostensoort wijzigen'} isOpen={isOpen}
             onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <br />
        <ErrorMessageBar error={error} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ReadOnlyField title='Defectoorzaak' value={defectoorzaakKostensoort.defectoorzaak} labelWidth={200}/>
          <Controller
            name={getPropertyName<CreateUpdateType>('defectoorzaakId')}
            control={control}
            defaultValue={0}
            render={() => <input type="hidden"/>}
          />
          <VgtDropdown
            label='Kostensoort'
            name={getPropertyName<CreateUpdateType>('kostensoortId')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isReparatieboom}
            options={kostensoortOptions}
            defaultSelectedKey={defectoorzaakKostensoort.kostensoortId ?? 0}
          />
          <OkCancelButtonStack
            isSubmitting={isSubmitting}
            isLoading={isUpdatingDefectoorzaakKostensoort}
            onOkClick={handleSubmit(onSubmit)}
            onCancelClick={onCancel}
            isWriter={isReparatieboom}
          />
        </form>
      </Panel>
    </div>
  )
}