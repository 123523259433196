import { Checkbox, DetailsList, IColumn, PanelType, ScrollablePane, SelectionMode } from '@fluentui/react'
import React, { useEffect } from 'react'
import useRoles from 'services/UseRoles'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CoenCadcoderingListDto, VoorzieningenAddOrUpdateDto, zodiosHooks } from '../../../api/ApiClient'
import VgtTextField from 'components/VgtTextField'
import { getPropertyName } from 'lib/interfaceUtils'
import { getTitleAndMessage } from 'services/HandleError'
import { z } from 'zod'
import VgtDropdown from 'components/FluentDropdown'
import { OkCancelButtonStack } from 'components/OkCancelButtonStack/OkCancelButtonStack'
import { createColumn } from 'lib/gridHelper'
import FluentPanel from 'components/FluentPanel/FluentPanel'
import { FieldErrorsToMessage } from 'components/ErrorMessageBar/ErrorMessageBar'

export interface IEditPanelProps {
  VoorzieningenId: number,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof VoorzieningenAddOrUpdateDto>;
export type CoencadCoderingType = z.infer<typeof CoenCadcoderingListDto>;

export const VoorzieningenFormPanel: React.FC<IEditPanelProps> = props => {
  const { isAdmin } = useRoles()
  const [error, setError] = React.useState<string>('')
  const [coencadCodes, setCoencadCodes] = React.useState<CoencadCoderingType[]>([])

  const defaultVoorzieningen = {
    typeId: 0,
    omschrijving: '',
    code: '',
    naam: '',
    meetkundigeEenheidId: -1,
    elementElementDetailId: -1
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(VoorzieningenAddOrUpdateDto),
    mode: 'all',
    defaultValues: defaultVoorzieningen
  })

  useEffect(() => {
    if (props.VoorzieningenId === 0) {
      reset(defaultVoorzieningen)
    }
  }, [props.VoorzieningenId])

  const { isLoading } = zodiosHooks.useGetVoorzieningenId({ params: { id: props.VoorzieningenId } }, {
      onSuccess: (data) => onLoaded(data),
      enabled: props.VoorzieningenId !== 0
    }
  )
  zodiosHooks.useGetVoorzieningenIdcoencadcoderingen({ params: { id: props.VoorzieningenId } }, {
      onSuccess: (data) => onCodesLoaded(data),
      enabled: props.VoorzieningenId !== 0
    }
  )

  const { mutate: updateEntity, isLoading: isModifying } = zodiosHooks.usePutVoorzieningenId(
    { params: { id: props.VoorzieningenId } }, {
      onSuccess: () => {
        props.invalidate()
        onCancel()
      },
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: addEntity, isLoading: isAdding } = zodiosHooks.usePostVoorzieningen(
    {}, {
      onSuccess: () => {
        props.invalidate()
        onCancel()
      },
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { data: voorzieningTypeOpties } = zodiosHooks.useGetVoorzieningentypeOpties({}, { enabled: true })
  const { data: elementDetailOpties } = zodiosHooks.useGetElementElementDetailsasOptions({}, { enabled: true })

  const onLoaded = (data: CreateUpdateType) => {
    setError('')
    reset(data)
  }

  const onCodesLoaded = (data: CoencadCoderingType[]) => {
    setCoencadCodes(data)
  }

  const onCancel = () => {
    setError('')
    props.dismissPanel()
  }

  const onSubmit = (data: CreateUpdateType) => {
    if (data.elementElementDetailId === -1) {
      data.elementElementDetailId = undefined
    }

    data.selectedCoenCadCodes = coencadCodes.filter(i => i.isSelected).map(i => i.id)

    if (props.VoorzieningenId !== 0) {
      updateEntity(data)
    } else {
      addEntity(data)
    }
  }

  const onCoenCadItemChecked = (item: CoencadCoderingType, checked: boolean) => {
    const items = [...coencadCodes]
    const ccItem = items.find(i => i.id === item.id)
    if (ccItem) ccItem.isSelected = checked
    setCoencadCodes(items)
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<CoencadCoderingType>('isSelected'),
      name: 'Actief',
      minWidth: 50,
      maxWidth: 50,
      isResizable: false,
      isRowHeader: true,
      onRender: (item: CoencadCoderingType) => <Checkbox checked={item.isSelected} onChange={(_, checked) => !isAdmin ? null : onCoenCadItemChecked(item, checked ?? false)} />
    },
    createColumn('Code', getPropertyName<CoencadCoderingType>('code'), 'M'),
    createColumn('Naam', getPropertyName<CoencadCoderingType>('naam'), 'L')
  ]

  return (
    <div>
      <FluentPanel type={PanelType.medium} isOpen={props.isOpen} onDismiss={onCancel} headerText={`Voorziening ${props.VoorzieningenId !== 0 ? 'Wijzigen' : 'Toevoegen'}`} onDismissed={onCancel} error={error}>
        <VgtDropdown
          label="Type"
          name={getPropertyName<CreateUpdateType>('typeId')}
          register={register}
          control={control}
          errors={errors}
          readOnly={!isAdmin}
          options={voorzieningTypeOpties} />
        <VgtTextField
          label="Code"
          name={getPropertyName<CreateUpdateType>('code')}
          register={register}
          control={control}
          errors={errors}
          required
          readOnly={!isAdmin}
        />
        <VgtTextField
          label="Naam"
          name={getPropertyName<CreateUpdateType>('naam')}
          register={register}
          control={control}
          errors={errors}
          required
          readOnly={!isAdmin}
        />
        <VgtTextField
          label="Omschrijving"
          name={getPropertyName<CreateUpdateType>('omschrijving')}
          register={register}
          control={control}
          errors={errors}
          readOnly={!isAdmin}
        />
        <VgtDropdown
          label="Cartotheek-item"
          name={getPropertyName<CreateUpdateType>('elementElementDetailId')}
          register={register}
          control={control}
          errors={errors}
          readOnly={!isAdmin}
          options={elementDetailOpties} />

        <div style={{ marginTop: '30px', fontWeight: 'bold', fontSize: 'medium' }}>CoenCad CWB</div>
        <div style={{ height: isAdmin ? '60vh' : '65vh', position: 'relative' }}>
          <ScrollablePane>
            <DetailsList items={coencadCodes} columns={columns} selectionMode={SelectionMode.none} />
          </ScrollablePane>
        </div>

        <FluentPanel.Footer>
          <OkCancelButtonStack isSubmitting={isModifying || isAdding} isWriter={isAdmin}
                               isLoading={isLoading && props.VoorzieningenId !== 0}
                               onOkClick={handleSubmit(onSubmit, (errors) => {
                                 setError(FieldErrorsToMessage(errors))
                               })}
                               onCancelClick={onCancel} />
        </FluentPanel.Footer>
      </FluentPanel>
    </div>
  )
}