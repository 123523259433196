import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { EntityObjectState, createInitialState, IEntityPageObjectResponse } from 'lib/sliceSupport'
import { IEenheiddetailsoortDefectlocatie } from './model'

const initState: EntityObjectState<IEenheiddetailsoortDefectlocatie> = createInitialState<IEenheiddetailsoortDefectlocatie>()

export const fetchAll = createAsyncThunk('/eenheidDetailSoortRuimteSoort/fetchAll', async (binnen: boolean, thunkAPI) => {
    return await invokeFetch<IEntityPageObjectResponse<IEenheiddetailsoortDefectlocatie>>(thunkAPI, 'GET', `/eenheidDetailSoort/defectlocaties?binnendewoning=${binnen ? 'true' : 'false'}&skip=0&top=50000`)
})

const entityAdapter = createEntityAdapter<IEenheiddetailsoortDefectlocatie>({ selectId: entity => entity.eenheiddetailsoortId.toString() + '-' + entity.defectlocatieId.toString() })
export const eenheiddetailsoortDefectlocatieSlice = createSlice({
    name: 'eenheiddetailsoortDefectlocaties',
    initialState: entityAdapter.getInitialState(initState),

    reducers: {
        setAll: entityAdapter.setAll,
    },

    extraReducers: builder => {
        builder.addCase(fetchAll.pending, state => {
            state.status = 'pending'
        })
        builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<IEntityPageObjectResponse<IEenheiddetailsoortDefectlocatie>>) => {
            state.status = 'succeeded'
            const items = action.payload.items
            entityAdapter.removeAll(state)
            entityAdapter.setAll(state, items)
        })
        builder.addCase(fetchAll.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message || null
        })
    },
})

export const { selectAll } = entityAdapter.getSelectors<RootState>(state => state.eenheiddetailsoortDefectlocaties)

export default eenheiddetailsoortDefectlocatieSlice.reducer
