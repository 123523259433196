import { DefaultButton, IDropdownOption, MessageBar, MessageBarType, Panel, PanelType, Stack } from '@fluentui/react'
import { MetertypeAddOrUpdateDto, zodiosHooks } from '../../../api/ApiClient'
import { z } from 'zod'
import React, { useEffect, useState } from 'react'
import { getTitleAndMessage } from '../../../services/HandleError'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { getPropertyName } from '../../../lib/interfaceUtils'
import VgtTextField from '../../../components/VgtTextField'
import VgtNumberField from '../../../components/FluentNumberField'
import VgtDropdown from '../../../components/FluentDropdown'

interface IEditPanelProps {
  isOpen: boolean;
  dismissPanel: () => void;
  invalidateParent: () => void;
  meterTypeId: number;
}

type CreateOrUpdateType = z.infer<typeof MetertypeAddOrUpdateDto>;

const defaultValues = {
  omschrijving: '',
  eenheidId: 0,
  aantalDecimalen: 0
} as CreateOrUpdateType

export const MetertypeEdit: React.FC<IEditPanelProps> = ({ meterTypeId, isOpen, dismissPanel, invalidateParent }) => {
  const [error, setError] = useState<string>()

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateOrUpdateType>({
    resolver: zodResolver(MetertypeAddOrUpdateDto), mode: 'all', defaultValues: defaultValues
  })

  const { data } = zodiosHooks.useGetMetertypeId(
    { params: { id: meterTypeId } }
    , { onSuccess: (data) => reset(data), enabled: meterTypeId !== undefined }
  )
  const { data: meetkundigeEenheden } = zodiosHooks.useGetApiMeetkundigeEenhedenfetchAll()

  const { data: FontIcoonOptions } = zodiosHooks.useGetApiFonticoon()

  const fontIcons = FontIcoonOptions?.items?.filter(item => item.naam !== null)
    .map(item => ({ key: item.fonticoonId, text: item.omschrijving! }))

  const onMutateSuccess = () => {
    setError(undefined)
    dismissPanel()
  }

  const { mutate: update } = zodiosHooks.usePutMetertypeId(
    { params: { id: meterTypeId } }, {
      onSuccess: onMutateSuccess,
      onError: (error) => setError(getTitleAndMessage(error).message),
      onSettled: () => invalidateParent()
    }
  )
  const { mutate: add } = zodiosHooks.usePostMetertype(
    {}, {
      onSuccess: onMutateSuccess,
      onError: (error) => setError(getTitleAndMessage(error).message),
      onSettled: () => invalidateParent()
    }
  )

  useEffect(() => {
    if (data) {
      reset(data)
      setError(undefined)
    } else {
      reset(defaultValues)
      setError(undefined)
    }
  }, [data, reset])

  const onSubmit = (values: CreateOrUpdateType) => {
    if (meterTypeId !== undefined) {
      update(values)
    } else {
      add(values)
    }
  }

  const onCancel = () => {
    dismissPanel()
  }

  const eenheidOptions = () => {
    if (!meetkundigeEenheden?.items) return []

    return meetkundigeEenheden.items.map(i => {
      return { key: i.id, text: i.code }
    }) as IDropdownOption[]
  }

  return (
    <div className="metertype-edit">
      <Panel type={PanelType.medium} headerText="Metertype" isOpen={isOpen} onDismiss={onCancel}
             closeButtonAriaLabel="Sluiten">
        <>
          <div style={{ marginBottom: 16, borderBottom: '1px solid rgb(207, 207, 207)' }} />
          <form onSubmit={handleSubmit(onSubmit)}>
            {error !== undefined &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={true}
                dismissButtonAriaLabel="Close"
              >
                <b>{'Fout opgetreden'}.</b>&ensp;{error}
              </MessageBar>
            }
            <VgtTextField label="Omschrijving" name={getPropertyName<CreateOrUpdateType>('omschrijving')}
                          control={control} errors={errors} required={true}
                          defaultValue={defaultValues.omschrijving ?? ''} />
            <VgtDropdown label="Eenheid" name={getPropertyName<CreateOrUpdateType>('eenheidId')}
                         register={register} control={control} errors={errors} required options={eenheidOptions()}
                         defaultSelectedKey={defaultValues.eenheidId} />
            <VgtNumberField label="Aantal decimalen" name={getPropertyName<CreateOrUpdateType>('aantalDecimalen')}
                            control={control} errors={errors} required={true}
                            defaultValue={defaultValues.aantalDecimalen} min={-1} />
            <VgtDropdown label="Fonticoon" name={getPropertyName<CreateOrUpdateType>('fonticoonId')}
                         register={register} control={control} errors={errors} required options={fontIcons} />
            <br />
            <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
              <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} />
              <DefaultButton text="Annuleren" onClick={onCancel} />
            </Stack>
          </form>
        </>
      </Panel>
    </div>)
}