import { DefaultButton, IDropdownOption, Panel, PanelType, Stack } from "@fluentui/react"
import VgtTextField from "components/VgtTextField"
import { getPropertyName } from "lib/interfaceUtils"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"
import { 
    getLoadingState, 
    IDienstenComponent, 
    DienstenComponentSchema, getSelectedEntity, updateDienstenComponent, addDienstenComponent } from "./dienstenComponentSlice"
import { selectAll as selectAllKostensoorten } from '../kostensoort/kostensoortSlice' 
import { useEffect, useState } from "react"
import VgtDropdown from "components/FluentDropdown"

interface IEditPanelProps {
    isOpen: boolean
    dismissPanel: any
}

export const DienstenComponentEditPanel: React.FC<IEditPanelProps> = props => {
    const dispatch = useAppDispatch()
    const [title, setTitle] = useState('')
    const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
    const fetchStatus = useSelector(getLoadingState)
    const selectedEntity = useSelector(getSelectedEntity)
    const kostensoorten = useSelector(selectAllKostensoorten)

    useEffect(() => {
        setCloseOnFulFilled(false)
    }, []);

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting, errors },
    } = useForm<IDienstenComponent>({ resolver: yupResolver(DienstenComponentSchema), mode: 'all' })

    useEffect(() => {
        setTitle(`Dienstencomponent ${selectedEntity ? 'wijzigen' : 'toevoegen'}`)
        if (selectedEntity) {
            reset(selectedEntity)
        } else {
            reset({})
        }
    }, [selectedEntity]) 

    const onCancel = e => { props.dismissPanel() }

    const getKostensoortenAsOption = () => {
        const nullOption = { key: 0, text: '---' } as IDropdownOption
        const kostensoortOptions = kostensoorten.map(x => ({ key: x.kostensoortId, text: `${x.code} - ${x.omschrijving}` })) as IDropdownOption[]
        return [nullOption, ...kostensoortOptions]
    } 

    const onSubmit = data => {
        if (selectedEntity) {
            data.dienstencomponentId = selectedEntity.dienstencomponentId
            dispatch(updateDienstenComponent(data))
            setCloseOnFulFilled(true)
        } else {
            dispatch(addDienstenComponent(data))
            setCloseOnFulFilled(true)
        }
    }

    useEffect(() => {
        if (closeOnFulFilled && fetchStatus === 'succeeded') props.dismissPanel() 
    }, [fetchStatus])

    return (
        <div>
            <Panel type={PanelType.large} headerText={title} isOpen={props.isOpen} onDismiss={onCancel} closeButtonAriaLabel="Sluiten">
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VgtTextField label="Code" name={getPropertyName<IDienstenComponent>('code')} register={register} control={control} errors={errors} required={true} />
                    <VgtTextField label="Omschrijving" name={getPropertyName<IDienstenComponent>('omschrijving')} register={register} control={control} errors={errors} required={true} multiline autoAdjustHeight />
                    <VgtDropdown
                        options={getKostensoortenAsOption()}
                        label='Kostensoort'
                        name={getPropertyName<IDienstenComponent>('kostensoortId')}
                        register={register}
                        control={control}
                        errors={errors}
                    />
                    <br />
                    <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
                        <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} onClick={handleSubmit(onSubmit)} />
                        <DefaultButton text="Annuleren" onClick={onCancel} />
                    </Stack>
                </form>
            </Panel>
        </div>
    )    
}
export default DienstenComponentEditPanel