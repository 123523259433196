import { z } from 'zod'
import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import VgtTextField from 'components/VgtTextField'
import { OkCancelButtonStack } from 'components/OkCancelButtonStack/OkCancelButtonStack'
import FluentPanel from 'components/FluentPanel/FluentPanel'
import { PanelType } from '@fluentui/react/lib/Panel'
import { FieldErrorsToMessage } from 'components/ErrorMessageBar/ErrorMessageBar'
import { IrisFieldServiceConfigurationUpdateDto, zodiosHooks } from '../../api/ApiClient'
import { getPropertyName } from '../../lib/interfaceUtils'
import { getTitleAndMessage } from '../../services/HandleError'

export interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof IrisFieldServiceConfigurationUpdateDto>;

const defaultEntity = {
  baseUrl: '',
  oauthScope: '',
  clientId: '',
  clientSecret: '',
} as CreateUpdateType

export const IrisFieldServicePanel: React.FC<IEditPanelProps> = props => {

  const [error, setError] = React.useState<string>()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(IrisFieldServiceConfigurationUpdateDto), mode: 'all', defaultValues: defaultEntity
  })

  // const isActive = watch(getPropertyName<CreateUpdateType>('isActief')) as boolean ?? false
  const isActive = true

  const { mutate, isLoading: isModifying } = zodiosHooks.usePutSysteemInstellingenIrisFieldService({}, {
    onSuccess: () => onCancel(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  }
  )

  console.log(props.isOpen)
  const { isLoading } = zodiosHooks.useGetSysteemInstellingenIrisFieldService({}, {
    enabled: props.isOpen,
    onSuccess: (data) => {
      console.log(data)
      reset(data)}
  })

  const onCancel = () => {
    props.invalidate()
    reset({})
    setError(undefined)
    props.dismissPanel()
  }

  const onSubmit = (data: CreateUpdateType) => {
    setError(undefined)

    mutate(data)
  }

  return (
    <FluentPanel type={PanelType.medium} isOpen={props.isOpen} onDismiss={onCancel} headerText={'Wijzigen IrisFieldService Integratie'} onDismissed={onCancel} error={error}>
      {/* <VgtCheckbox name={getPropertyName<CreateUpdateType>('isActief')} label="Actief" control={control} errors={errors} /> */}
      <VgtTextField label="Url" name={getPropertyName<CreateUpdateType>('baseUrl')} control={control} errors={errors} required disabled={!isActive} />
      <VgtTextField label="OAuth Scope" name={getPropertyName<CreateUpdateType>('oauthScope')} control={control} errors={errors} required disabled={!isActive} />
      <VgtTextField label="Client Id" name={getPropertyName<CreateUpdateType>('clientId')} control={control} errors={errors} required disabled={!isActive} />

      <FluentPanel.Footer>
        <OkCancelButtonStack isSubmitting={isModifying} isLoading={isLoading} onOkClick={handleSubmit(onSubmit, (errors) => setError(FieldErrorsToMessage(errors)))} onCancelClick={onCancel} />
      </FluentPanel.Footer>
    </FluentPanel>
  )
}
