import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import { SleuteltypeDto, zodiosHooks } from '../../../api/ApiClient'
import React, { useState } from 'react'
import { ICommandItem } from '../../../containers/pages/PageTemplates/CommandBarTemplate'
import FluentTable from '../../../components/FluentTable'
import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import { IColumn, Link, SelectionMode } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { SleuteltypeEdit } from './SleuteltypeEdit'
import { useBoolean } from '@fluentui/react-hooks'
import ICoMoonFontIcon from '../../../components/IcoMoon'

type SleuteltypeDtoType = z.infer<typeof SleuteltypeDto>;
export const SleuteltypeList: React.FC = () => {
    const navigate = useNavigate();
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [itemToEdit, setItemToEdit] = useState<number | undefined>(undefined);
    const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

    const {data, invalidate, isLoading} = zodiosHooks.useGetSleuteltype();
    const {mutate: deleteRows, isLoading: isDeleting} = 
        zodiosHooks.useDeleteSleuteltype({}, {
        onSuccess: () => {
            setSelectedIds([])
            invalidate()
        },
        onError: (error) => alert(error)
    });
    const removeItems = () => {
        deleteRows({itemsToDelete: selectedIds.map(v => parseInt(v))})
    };

    const refreshItems = () => {
        invalidate().then();
    };
    const commandItems: ICommandItem[] = [
        { text: 'Terug', onClick: () => navigate(-1), icon: 'Back' },
        { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
        {
            text: 'Toevoegen',
            onClick: () => {openPanel()},
            icon: 'Add',
        },
        {
            text: 'Verwijderen',
            onClick: () => {removeItems()},
            icon: 'Delete',
            disabled: isDeleting || selectedIds.length === 0
        }
    ]

    const columns: IColumn[] = React.useMemo(() => {
        return [
            createColumn('Omschrijving', getPropertyName<SleuteltypeDtoType>('omschrijving'), 'L', true,
              (item: SleuteltypeDtoType) => (
                <Link key={item.sleuteltypeId} onClick={() => handleItemInvoked(item.sleuteltypeId.toString())}>
                    {item.omschrijving}
                </Link>
              )),
            createColumn('Inspectietaak', getPropertyName<SleuteltypeDtoType>('inspectietaak'), 'XXL'),
            createColumn('Fonticoon', getPropertyName<SleuteltypeDtoType>('fonticoonNaam'), 'L', false,
              (item: SleuteltypeDtoType) => <ICoMoonFontIcon iconName={item?.fonticoonNaam!} />)
        ]
    }, [])

    const handleItemInvoked = (id: string) => {
        if (id) {
            setItemToEdit(parseInt(id))
            openPanel()
        }
    }

    return (
        <MainTemplate title="Sleuteltypen " subTitle="Overzicht" commandItems={commandItems}>
            {isPanelOpen &&
              <SleuteltypeEdit sleuteltypeId={itemToEdit!} isOpen={isPanelOpen} dismissPanel={()=> {
                  dismissPanel()
                  setItemToEdit(undefined)
              }} refreshTable={invalidate} />
            }
            <div style={{marginRight: 15}}>
                <div style={{display: 'flex', height: '70vh'}}>
                    <FluentTable
                        columns={columns}
                        items={data?.items ?? [] as SleuteltypeDtoType[]}
                        loading={isLoading}
                        selectionMode={SelectionMode.multiple}
                        onSelectionChanged={setSelectedIds}
                        onItemInvoked={(id: string) => {handleItemInvoked(id)}}
                        onGetKey={item => item.sleuteltypeId.toString()}
                    />
                </div>
            </div>
        </MainTemplate>);
}