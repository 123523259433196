import React from 'react'
import NumericInput from '../NumericInput'
import { Dropdown, IDropdownOption } from '@fluentui/react'

type TextAlign = 'left' | 'right';

interface SectionTextEditProps {
  id: string;
  title: string;
  value?: number | null;
  fractionDigits?: number;
  onChange: (id: string, newValue?: number) => void;
}

export const SectionTextEdit: React.FC<SectionTextEditProps> = ({ id, title, value, onChange, fractionDigits = 0 }) => {
  const valueStyle: React.CSSProperties = {
    flex: '2',
    marginTop: -4,
    paddingBottom: 4,
    width: '200px',
    display: 'flex',
    justifyContent: 'flex-end'
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '4px',
      borderBottom: '1px solid #eee'
    }}>
      <div style={{ flex: '2', marginRight: '8px', marginTop: 6 }}>
        <span>{title}</span>
      </div>
      <div style={valueStyle}>
        <NumericInput value={value}
                      onChange={(value) => onChange(id, fractionDigits === 0 ? Math.floor(value ?? -1) : value)}
                      fractionDigits={fractionDigits} />
      </div>
    </div>
  )
}

interface SectionDropdownProps {
  title: string;
  id: string;
  value?: number | null;
  options: IDropdownOption[];
  onSelect: (id: string, item?: IDropdownOption) => void;
  valueAlignment?: TextAlign;
}

export const SectionDropDown: React.FC<SectionDropdownProps> = ({
                                                                  title,
                                                                  id,
                                                                  value,
                                                                  options,
                                                                  onSelect,
                                                                  valueAlignment = 'left'
                                                                }) => {

  const valueStyle: React.CSSProperties = {
    flex: '2',
    marginTop: -4,
    paddingBottom: 4,
    width: '200px', // Set the width of the parent div to 200px
    display: 'flex',
    textAlign: valueAlignment
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginBottom: '4px',
      paddingTop: '4px',
      borderBottom: '1px solid #eee'
    }}>
      <div style={{ flex: '2', marginRight: '8px', marginTop: 6 }}>
        <span>{title}</span>
      </div>
      <div style={valueStyle}>
        <Dropdown id={id} onChange={(_, option) => onSelect(id, option)} options={options} selectedKey={value}
                  styles={{ root: { width: '194px' } }}></Dropdown>
      </div>
    </div>
  )
}

interface SectionLineProps {
  title: string;
  value?: string | string[] | boolean | boolean[] | number | number[] | null;
  valueAlignment?: TextAlign;
}

export const SectionLine: React.FC<SectionLineProps> = ({ title, value, valueAlignment = 'left' }) => {
  const valueStyle: React.CSSProperties = {
    flex: '2',
    textAlign: valueAlignment,
    minHeight: '24px',
    fontWeight: 600
  }

  const isBoolean = (val: any) => {
    if (Array.isArray(val)) return val.every(item => typeof item === 'boolean')

    return typeof val === 'boolean'
  }

  return (
    Array.isArray(value) ? (
      <React.Fragment>
        {value.map((v: any, i: number) =>
          v !== null ? (<div key={i} style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: '12px',
            maxWidth: '1000px',
            borderBottom: '1px solid #eee'
          }}>
            <div style={{ flex: '4', marginRight: '8px' }}>
              <span>{title}</span>
            </div>
            <div style={valueStyle}>
              {isBoolean(value) ? <span>{value ? 'Ja' : 'Nee'}</span> : <span>{v ?? '------'}</span>}
            </div>
          </div>) : null
        )}
      </React.Fragment>
    ) : (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: '12px',
        borderBottom: '1px solid #eee'
      }}>
        <div style={{ flex: '2', marginRight: '8px' }}>
          <span>{title}</span>
        </div>
        <div style={valueStyle}>
          {isBoolean(value) ? <span>{value ? 'Ja' : 'Nee'}</span> : <span>{value ?? '---'}</span>}
        </div>
      </div>
    )
  )

}

interface SectionWithThreeColumnsLineProps {
  title: string;
  value?: string | boolean | number | null;
  prefixValue?: string | null
}

export const SectionWithThreeColumnsLine: React.FC<SectionWithThreeColumnsLineProps> = ({
                                                                                          title,
                                                                                          value,
                                                                                          prefixValue
                                                                                        }) => {
  const valueStyle: React.CSSProperties = {
    display: 'flex',
    flex: '2',
    minHeight: '24px',
    fontWeight: 600,
    justifyContent: 'space-between',
    minWidth: 'fit-content'
  }

  const isBoolean = (val: any) => {
    if (Array.isArray(val)) return val.every(item => typeof item === 'boolean')
    return typeof val === 'boolean'
  }

  return (
    value !== null ?
      (<div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: '12px',
        maxWidth: '1000px',
        borderBottom: '1px solid #eee'
      }}>
        <div style={{ flex: '4', marginRight: '8px' }}>
          <span>{title}</span>
        </div>
        <div style={valueStyle}>
          {prefixValue ? <span>{prefixValue}</span> : null}
          {isBoolean(value) ? <span>{value ? 'Ja' : 'Nee'}</span> : <span>{value ?? '---'}</span>}
        </div>
      </div>) : null

  )
}
export const SectionHeader = ({ title, children }) => (
  <section>
    <h3>{title}</h3>
    <div style={{ paddingLeft: 15 }}>{children}</div>
  </section>
)