import React, { useEffect, useMemo, useState } from 'react'
import {
  CommandBar,
  IColumn,
  ICommandBarItemProps,
  Link, SelectionMode
} from '@fluentui/react'
import commandBarStyles from '../../../../styles/commandBarStyles'
import { IVge } from '../../../vge/vgeSlice'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { useBoolean } from '@fluentui/react-hooks'
import { createBooleanColumn, createColumn, createCustomRenderedColumn } from '../../../../lib/gridHelper'
import { createCommandButton } from '../../../../lib/commandBarHelper'
import { PageIdDefinition, PAGES } from '../../../../pages'
import { useNavigate } from 'react-router-dom'
import { useWarningDialog } from '../../../../components/WarningDialog'
import { GebouwDeelVgeListDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { z } from 'zod'
import { CreateUpdateType } from './gebouwdeel'
import SearchVgeForLinkageToGebouwdeel from '../gebouwen/searchVgeForLinkageToGebouwdeel'
import FluentTable from '../../../../components/FluentTable'


type GebouwDeelVgeListDtoType = z.infer<typeof GebouwDeelVgeListDto>;

interface IGebouwDeelVgeListProps {
  gebouwDeel: CreateUpdateType
  adresStatus: number
  setItemCount: (count: number) => void
}

const GebouwDeelVgeList: React.FC<IGebouwDeelVgeListProps> = props => {
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const navigate = useNavigate()
  const [error, setError] = useState<string>()
  const [WarningDialog, setDialogSubtext, showDialog] = useWarningDialog()
  const [selectedIds, setSelectedIds] = useState([] as number[])


  const { data: vges, invalidate: fetchVges, isLoading } = zodiosHooks.useGetGebouwdelenGebouwDeelIdvges({
    params: { gebouwDeelId: props.gebouwDeel.gebouwDeelId as number },
    queries: {
      adresStatus: props.adresStatus
    }
  }, { enabled: props.gebouwDeel.gebouwDeelId !== 0 })

  const { mutate: deleteVgesFromGebouwDeel } = zodiosHooks.useDeleteGebouwdelenVge({}, {
    onSuccess: () => fetchVges(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  useEffect(() => {
    if (vges) {
      props.setItemCount(vges.length)
    }
  }, [vges, props.setItemCount])

  const onSelectionChanged = (ids: string[]) => {
    const numericIds = ids.map(id => parseInt(id, 10))
    setSelectedIds(numericIds)
  }

  const CheckForServiceadres = (vgeId: number, vgesSelected: number[]) => {
    if (props.gebouwDeel?.serviceadresVge && vgesSelected.find((v: number) => v === props.gebouwDeel?.serviceadresVge)) {
      setDialogSubtext('Vastgoedeenheid kan niet worden ontkoppeld aangezien deze wordt gebruikt als serviceadres')
      showDialog(true)
    } else {
      deleteVgesFromGebouwDeel({ gebouwdeelId: props.gebouwDeel.gebouwDeelId as number, vgeIds: [vgeId] })
    }
  }

  const AddToSelection = () => {
    openPanel()
  }

  const RemoveFromSelection = () => {
    if (selectedIds.length > 0) {
      selectedIds.forEach(vgeId => {
        CheckForServiceadres(vgeId, selectedIds)
      })
    }
  }

  const selectCommandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      createCommandButton('Koppelen', 'PlugConnected', AddToSelection),
      createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveFromSelection)],
    [AddToSelection, RemoveFromSelection, selectedIds]
  )

  const handleItemInvoked = (vgeId: string) => {
    navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', vgeId))
  }

  const columns: IColumn[] = [
    createColumn('VGE Code', getPropertyName<GebouwDeelVgeListDtoType>('vgeId'), 'XL'),
    createColumn('Eenheiddetailsoort', getPropertyName<GebouwDeelVgeListDtoType>('srtNm'), 'XL'),
    createCustomRenderedColumn(
      'Adres',
      getPropertyName<GebouwDeelVgeListDtoType>('adres'),
      (item: GebouwDeelVgeListDtoType) => (
        <Link key={item.adres} onClick={() => handleItemInvoked(item.vgeId.toString())}>
          {item.adres}
        </Link>
      ),
      'XL',
      false
    ),
    createColumn('Postcode', getPropertyName<GebouwDeelVgeListDtoType>('pc')),
    createColumn('Woonplaats', getPropertyName<GebouwDeelVgeListDtoType>('wpl')),
    createColumn('Locatieaanduiding', getPropertyName<GebouwDeelVgeListDtoType>('locatieaanduiding'), 'L'),
    createColumn('Beheerder', getPropertyName<GebouwDeelVgeListDtoType>('bn'), 'XL'),
    createCustomRenderedColumn('Verhuureenheid', getPropertyName<GebouwDeelVgeListDtoType>('vhes'), item => item[getPropertyName<IVge>('vhes')].join(', '), 125, false),
    createBooleanColumn('Collectief', getPropertyName<GebouwDeelVgeListDtoType>('isCollectief'), 'S'),
    createBooleanColumn('Historisch', getPropertyName<GebouwDeelVgeListDtoType>('hist'), 'S')
  ]


  return (
    <>
      <CommandBar items={selectCommandBarItems} styles={commandBarStyles} />
      {WarningDialog}
      <ErrorMessageBar error={error} />
      <SearchVgeForLinkageToGebouwdeel
        dismissPanel={dismissPanel}
        isOpen={isPanelOpen}
        gebouwDeelId={props.gebouwDeel.gebouwDeelId}
        adresStatus={props.adresStatus}
        invalidate={fetchVges}
      />
      <div style={{ height: '70vh', position: 'relative' }}>
        <FluentTable
          columns={columns}
          items={vges ?? []}
          loading={isLoading}
          selectionMode={SelectionMode.multiple}
          onSelectionChanged={(ids: string[]) => onSelectionChanged(ids)}
          onGetKey={(item) => item.vgeId.toString()}
          onItemInvoked={(item) => handleItemInvoked(item)}
        />
      </div>
    </>
  )
}

export default GebouwDeelVgeList
