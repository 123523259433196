import { MsalProvider } from '@azure/msal-react'
import AppRouter from 'AppRouter'
import { PrimaryTheme } from 'themes/PrimaryTheme'
import { ThemeProvider } from '@fluentui/react'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { MsalSingleton } from './api/msalInstance'
import { HandleError } from './services/HandleError'
import { routes } from './routing/routing'

function App() {
  if (window.location.href.indexOf('login') < 0 && window.location.href.indexOf('callback') < 0) {
    window.localStorage.setItem('firstUrl', window.location.pathname)
  }
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        HandleError(error);
      },
    }),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        keepPreviousData: false,
      }
    }
  });

  const instance = MsalSingleton.getInstance()

  return (
    <MsalProvider instance={instance.getMsalInstance()}>
      <ThemeProvider theme={PrimaryTheme}>
        <QueryClientProvider client={queryClient}>
          <AppRouter routes={routes}/>
        </QueryClientProvider>
      </ThemeProvider>
    </MsalProvider>
  )
}

export default App
