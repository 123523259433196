import FluentViewSelector from 'components/FluentViewSelector'
import { PageIdDefinition, PAGES } from 'pages'
import { IContextualMenuProps, IContextualMenuItem } from '@fluentui/react'

import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

const LENDOPagesToSelect: string[] = [
  PageIdDefinition.beheer.lemdo.defectLocaties,
  PageIdDefinition.beheer.lemdo.defectSoorten,
  PageIdDefinition.beheer.lemdo.elementCategorieen,
  PageIdDefinition.beheer.lemdo.defectLocatieElementCategories,
  PageIdDefinition.beheer.lemdo.elementen,
  PageIdDefinition.beheer.lemdo.elementElementDetailDefectSoortTaken,
  PageIdDefinition.beheer.lemdo.elementDefectSoorten,
  PageIdDefinition.beheer.lemdo.elementDetails,
  PageIdDefinition.beheer.lemdo.elementElementDetails,
  PageIdDefinition.beheer.lemdo.eenheidDetailSoorten,
  PageIdDefinition.beheer.lemdo.eenheidDetailSoortRuimteSoorten,
  PageIdDefinition.beheer.lemdo.eenheidSoorten,
  PageIdDefinition.beheer.lemdo.nlsfb,
]
const FysiekBezitPagesToSelect: string[] = [
  PageIdDefinition.beheer.fysiek.gebouwen,
  PageIdDefinition.beheer.fysiek.gebouwDelen,
  PageIdDefinition.beheer.fysiek.gebouwenClusters,
  PageIdDefinition.beheer.fysiek.importExport,
]
const ImportPagesToSelect: string[] = [PageIdDefinition.beheer.imports.resultaten, PageIdDefinition.beheer.imports.elementenMJOB]

const BeheerSelector = () => {
  const navigate = useNavigate()
  const menuPropsLendo: IContextualMenuProps = useMemo(
    () => ({
      shouldFocusOnMount: false,
      items: LENDOPagesToSelect.map(pageId => {
        const item: IContextualMenuItem = {
          key: pageId,
          text: PAGES[pageId].title,
          onClick: () => navigate(PAGES[pageId].route),
        }
        return item
      }),
    }),
    [navigate]
  )

  const menuPropsFysiekBezit: IContextualMenuProps = useMemo(
    () => ({
      shouldFocusOnMount: false,
      items: FysiekBezitPagesToSelect.map(pageId => {
        const item: IContextualMenuItem = {
          key: pageId,
          text: PAGES[pageId].title,
          onClick: () => navigate(PAGES[pageId].route),
        }
        return item
      }),
    }),
    [navigate]
  )

  const menuPropsImport: IContextualMenuProps = useMemo(
    () => ({
      shouldFocusOnMount: false,
      items: ImportPagesToSelect.map(pageId => {
        const item: IContextualMenuItem = {
          key: pageId,
          text: PAGES[pageId].title,
          onClick: () => navigate(PAGES[pageId].route),
        }
        return item
      }),
    }),
    [navigate]
  )

  return (
    <>
      <div>
        <FluentViewSelector text="Indelen fysiek bezit" menuProps={menuPropsFysiekBezit} />
      </div>
      <div>
        <FluentViewSelector text="Referentielijsten" menuProps={menuPropsLendo} />
      </div>
      <div>
        <FluentViewSelector text="Importeren" menuProps={menuPropsImport} />
      </div>
    </>
  )
}

export default BeheerSelector
