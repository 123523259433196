import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { IVeraEntity } from '../common/lemdoEntity'
import { IDropdownOption } from '@fluentui/react'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import * as yup from 'yup'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'

export interface IVerwarming extends IVeraEntity {
  code: string
  wijzeVanVerwarming: string
  leveringVanWarmte: string
  ingangsdatum: string
  einddatum: string
}

export const verwarmingSchema = yup.object().shape({
  code: yup.string().required('Code is verplicht').max(3, 'Maximale lengte is 3'),
  wijzeVanVerwarming: yup.string().required('Wijze van verwarming is verplicht').max(100, 'Maximale lengte is 11'),
  leveringVanWarmte: yup.string().required('Levering van warmte is verplicht').max(100, 'Maximale lengte is 50'),
})

const entityAdapter = createEntityAdapter<IVerwarming>({
  sortComparer: (a, b) => a.code?.localeCompare(b.code),
})

const baseUrl = '/verwarmingen'
const basePrefix = 'verwarming'
const getSliceState = (state: RootState) => state.verwarming

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
  return await invokeFetch<PagedEntities<IVerwarming>>(thunkAPI, 'GET', `${baseUrl}`)
})

export const updateVerwarming = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: IVerwarming, thunkAPI) => {
  return await invokeFetch<IVerwarming>(thunkAPI, 'PUT', `${baseUrl}/${entity.id}`, entity)
})

export const addVerwarming = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: IVerwarming, thunkAPI) => {
  return await invokeFetch<IVerwarming>(thunkAPI, 'POST', baseUrl, entity)
})

export const deleteVerwarming = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
  return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
})

const setPendingState = (state: EntityState<IVerwarming> & IEntityAdapterState) => {
  state.error = null
  state.status = 'pending'
}
const setSucceededState = (state: EntityState<IVerwarming> & IEntityAdapterState) => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<IVerwarming> & IEntityAdapterState, action) => {
  state.status = 'failed'
  state.error = action.error.message || null
}

export const verwarmingSlice = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
    clearError: state => {
      state.error = null
    },
    select: (state, action: PayloadAction<string | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
  },

  extraReducers: builder => {
    builder.addCase(fetchAll.pending, state => setPendingState(state))
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IVerwarming>>) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
    builder.addCase(updateVerwarming.pending, state => setPendingState(state))
    builder.addCase(updateVerwarming.fulfilled, (state, action: PayloadAction<IVerwarming>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(updateVerwarming.rejected, (state, action) => setRejectedState(state, action))
    builder.addCase(addVerwarming.pending, state => setPendingState(state))
    builder.addCase(addVerwarming.fulfilled, (state, action: PayloadAction<IVerwarming>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(addVerwarming.rejected, (state, action) => {
      setRejectedState(state, action)
    })
    builder.addCase(deleteVerwarming.pending, state => setPendingState(state))
    builder.addCase(deleteVerwarming.fulfilled, (state, action) => {
      entityAdapter.removeMany(state, action.meta.arg)
      setSucceededState(state)
    })
    builder.addCase(deleteVerwarming.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const getSelectedEntity = (state: RootState) => {
  const selectedId = getSliceState(state).selectedId
  if (selectedId) return getSliceState(state).entities[selectedId]
  else return undefined
}

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}

export const getErrorState = (state: RootState) => {
  return getSliceState(state).error
}

export const { add, modify, removeMany, select, clearSelection } = verwarmingSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.verwarming)

export const selectEditableItems = (state: RootState) => selectAll(state).filter(verwarming => verwarming.wijzeVanVerwarming !== 'Onbekend') as IVerwarming[]

const findVerwarmingItems = (state: RootState, wijze: string) => selectAll(state).filter(verwarming => verwarming.wijzeVanVerwarming === wijze) as IVerwarming[]

export const optionUnSelected = {
  key: -1,
  text: '---',
} as IDropdownOption

export const getVerwarmingSoortDropDownValues = (state: RootState) =>{
  const values: IDropdownOption[] = [optionUnSelected]
  const eenheden = selectAll(state)
  eenheden.forEach(i => {
    values.push({
      key: i.id,
      text: `${i.wijzeVanVerwarming} - ${i.leveringVanWarmte}`,
    })
  })
  return values
}

export const getVerwarmingSoortIndividueelDropDownValues = (state: RootState) => {
  const values: IDropdownOption[] = [optionUnSelected]
  const eenheden = findVerwarmingItems(state, 'Individueel')
  eenheden.forEach(i => {
    values.push({
      key: i.id,
      text: i.leveringVanWarmte,
    })
  })
  return values
}

export const getVerwarmingSoortCollectiefDropDownValues = (state: RootState) => {
  const values: IDropdownOption[] = [optionUnSelected]
  const eenheden = findVerwarmingItems(state, 'Collectief')
  eenheden.forEach(i => {
    values.push({
      key: i.id,
      text: i.leveringVanWarmte,
    })
  })
  return values
}

export const getUnknownVerwarmingId = (state: RootState) => {
  const eenheden = findVerwarmingItems(state, 'Onbekend')
  return eenheden[0]
}
export default verwarmingSlice.reducer
