import React, { useEffect, useState } from 'react'
import { IDropdownOption, Panel, PanelType } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { useTypedSelector } from 'store'
import { getPropertyName } from 'lib/interfaceUtils'
import {
  fetchAll as fetchAllEenheidSoorten,
  getEenheidSoortenAsOptions
} from 'features/beheer/lemdo/eenheidsoort/eenheidSoortSlice'
import VgtTextField from 'components/VgtTextField'
import VgtDropdown from 'components/FluentDropdown'
import useRoles from 'services/UseRoles'
import { z } from 'zod'
import { CreateEenheidDetailSoortCommand, EenheidDetailSoortDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { useDispatch } from 'react-redux'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

type EenheidDetailSoortDtoType = z.infer<typeof EenheidDetailSoortDto>;
type CreateEenheidDetailSoortCommandType = z.infer<typeof CreateEenheidDetailSoortCommand>;

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  fetchEenheidDetailSoort: () => void
  selectedEenheidDetailSoort: EenheidDetailSoortDtoType | undefined
}

const EditPanelEenheidDetailSoorten: React.FC<IEditPanelProps> = ({
                                                                    isOpen,
                                                                    dismissPanel,
                                                                    selectedEenheidDetailSoort,
                                                                    fetchEenheidDetailSoort
                                                                  }) => {
  const [title, setTitle] = useState('Eenheiddetailsoort')
  const { isAdmin } = useRoles()
  const [error, setError] = useState<string>()
  const dispatch = useDispatch()

  const onDataSubmitttedSuccesfully = () => {
    fetchEenheidDetailSoort()
    dismissPanel()
  }

  const {
    mutate: postEenheidDetailSoort,
    isLoading: isPostingEenheidDetailSoort
  } = zodiosHooks.usePostEenheidDetailSoort({}, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const {
    mutate: putEenheidDetailSoortId,
    isLoading: isUpdatingEenheidDetailSoort
  } = zodiosHooks.usePutEenheidDetailSoortId(
    { params: { id: selectedEenheidDetailSoort?.id! } }, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const defaultEenheidDetailSoort = {
    id: selectedEenheidDetailSoort?.id ?? 0,
    soortId: selectedEenheidDetailSoort?.soortId ?? 0,
    soortCode: selectedEenheidDetailSoort?.soortCode ?? '',
    code: selectedEenheidDetailSoort?.code ?? '',
    naam: selectedEenheidDetailSoort?.naam ?? '',
    omschrijving: selectedEenheidDetailSoort?.omschrijving ?? null,
    veraVersie: selectedEenheidDetailSoort?.veraVersie ?? 0,
    splitsNaarRuimteSoort: selectedEenheidDetailSoort?.splitsNaarRuimteSoort ?? false,
    ingangsDatum: new Date().toJSON(),
    eindDatum: selectedEenheidDetailSoort?.eindDatum ?? null,
    eengezinsMeergezinsOptie: selectedEenheidDetailSoort?.eengezinsMeergezinsOptie ?? ''
  }

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateEenheidDetailSoortCommandType>({ mode: 'all' })

  useEffect(() => {
    dispatch(fetchAllEenheidSoorten())
  }, [])

  useEffect(() => {
    setTitle(`Eenheiddetailsoort ${selectedEenheidDetailSoort ? (isAdmin ? 'wijzigen' : '') : 'toevoegen'}`)
    if (selectedEenheidDetailSoort) {
      reset(selectedEenheidDetailSoort)
    } else {
      reset({})
    }
  }, [selectedEenheidDetailSoort])

  const onCancel = () => {
    dismissPanel()
  }

  const eenheidSoorten = useTypedSelector(s => getEenheidSoortenAsOptions(s))

  const eengezinsMeergezinsoptions: IDropdownOption[] = [
    { key: 'Niet van toepassing', text: 'Niet van toepassing' },
    { key: 'Eengezinswoning', text: 'Eengezinswoning' },
    { key: 'Meergezinswoning', text: 'Meergezinswoning' }
  ]

  const onSubmit = (data: CreateEenheidDetailSoortCommandType) => {
    const {
      soortId = 0,
      eengezinsMeergezinsOptie,
      omschrijving,
      veraVersie,
      ...restData
    } = data

    const submittedData = {
      ...defaultEenheidDetailSoort,
      soortId: soortId ?? 0,
      eengezinsMeergezinsOptie: eengezinsMeergezinsOptie ?? '',
      omschrijving: omschrijving ?? '',
      veraVersie: soortId ?? 0,
      ...restData
    }

    if (selectedEenheidDetailSoort) {
      submittedData.id = selectedEenheidDetailSoort.id
      putEenheidDetailSoortId(submittedData)
    } else {
      postEenheidDetailSoort(submittedData)
    }
  }

  return (
    <div>
      <Panel type={PanelType.medium} headerText={title} isOpen={isOpen} onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <ErrorMessageBar error={error} />
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField
            label='Code'
            name={getPropertyName<CreateEenheidDetailSoortCommandType>('code')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          />
          <VgtDropdown
            options={eenheidSoorten}
            label='Eenheidsoortcode'
            name={getPropertyName<CreateEenheidDetailSoortCommandType>('soortId')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          />
          <VgtTextField
            label='Naam'
            name={getPropertyName<CreateEenheidDetailSoortCommandType>('naam')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          />
          <VgtTextField
            label='Omschrijving'
            name={getPropertyName<CreateEenheidDetailSoortCommandType>('omschrijving')}
            register={register}
            control={control}
            errors={errors}
            multiline
            readOnly={!isAdmin}
          />
          <VgtTextField
            label='Vera versie'
            name={getPropertyName<CreateEenheidDetailSoortCommandType>('veraVersie')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isAdmin}
          />
          <VgtDropdown
            label='Eengezins-meergezins'
            name={getPropertyName<CreateEenheidDetailSoortCommandType>('eengezinsMeergezinsOptie')}
            options={eengezinsMeergezinsoptions}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isAdmin}
          />
          <OkCancelButtonStack
            isSubmitting={isSubmitting}
            isLoading={isPostingEenheidDetailSoort || isUpdatingEenheidDetailSoort}
            onOkClick={handleSubmit(onSubmit)}
            onCancelClick={onCancel}
            isWriter={isAdmin}
          />
        </form>
      </Panel>
    </div>
  )
}

export default EditPanelEenheidDetailSoorten
