import React from 'react'
import { DetailsList, IColumn, SelectionMode } from '@fluentui/react'

export interface IUploadErrorListProps {
  messages: string[]
}

const UploadErrorList: React.FC<IUploadErrorListProps> = (props: IUploadErrorListProps) => {
  const columns = [
    {
      key: 'message',
      name: 'Foutmeldingen',
      minWidth: 300,
      maxWidth: 300,
      onRender: (item: string) => <label>{item}</label>,
    } as IColumn,
  ]

  return props.messages.length !== 0 ? <DetailsList items={props.messages} columns={columns} selectionMode={SelectionMode.none} /> : <></>
}

export default UploadErrorList
