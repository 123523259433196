import React from 'react'
import { z } from 'zod'
import { TaakAddOrUpdateDto, zodiosHooks } from '../../../../api/ApiClient'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { Panel, PanelType } from '@fluentui/react'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import VgtTextField from '../../../../components/VgtTextField'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import useRoles from '../../../../services/UseRoles'
import { OkCancelButtonStack } from 'components/OkCancelButtonStack/OkCancelButtonStack'

export interface IEditPanelProps {
  taakId: number,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof TaakAddOrUpdateDto>;

export const TakenFormPanel: React.FC<IEditPanelProps> = props => {

  const [error, setError] = React.useState<string>()
  const { isAdmin } = useRoles()

  const defaultTaak = {
    naam: '',
    verkorteNaam: ''
  } as CreateUpdateType


  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(TaakAddOrUpdateDto), mode: 'all', defaultValues: defaultTaak
  })

  const onCancel = () => {
    props.invalidate()
    reset({})
    props.dismissPanel()
  }

  zodiosHooks.useGetApiTaakId({ params: { id: props.taakId } }, {
      onSuccess: (data) => reset(data),
      enabled: props.taakId !== 0
    }
  )

  const { mutate: addTaak } = zodiosHooks.usePostApiTaak({}, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const {
    mutate: updateTaak
  } = zodiosHooks.usePutApiTaakId({ params: { id: props.taakId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const onSubmit = (data: CreateUpdateType) => {
    setError(undefined)
    if (props.taakId !== 0) {
      updateTaak(data)
    } else {
      addTaak(data)
    }
  }

  return (
    <div>

      <Panel type={PanelType.large}
             headerText={`Taak ${props.taakId !== 0 ? 'wijzigen' : 'toevoegen'}`}
             isOpen={props.isOpen}
             onDismiss={onCancel}
             closeButtonAriaLabel="Sluiten">
        <ErrorMessageBar error={error} />

        <form onSubmit={handleSubmit(onSubmit)}>

          <VgtTextField label="Naam" name={getPropertyName<CreateUpdateType>('naam')} control={control}
                        errors={errors} defaultValue={''} />
          <VgtTextField label="Verkorte Naam" name={getPropertyName<CreateUpdateType>('verkorteNaam')} control={control}
                        errors={errors} defaultValue={''} />

          {isAdmin ?
            <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                                 onOkClick={() => handleSubmit(onSubmit)} onCancelClick={onCancel} /> : null}
        </form>

      </Panel>
    </div>
  )
}
