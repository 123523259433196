import { useNavigate } from 'react-router-dom'
import MainTemplate from '../../containers/pages/PageTemplates/MainTemplate'
import { VheForm } from './VheForm'
import { zodiosHooks } from 'api/ApiClient'
import { getTitleAndMessage } from '../../services/HandleError'
import React from 'react'

export const VheNew = () => {
  const navigate = useNavigate()
  const [error, setError] = React.useState<string>()

  const { mutate, isLoading } = zodiosHooks.usePostVhes(
    {}, {onSuccess: () => navigate(-1), onError: (error) => setError(getTitleAndMessage(error).message)}
  )

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true }
  ]

  return (
    <MainTemplate title='Verhuureenheid' subTitle='nieuw' commandItems={commandItems} error={error}>
      <VheForm vheId={0} onSubmit={mutate} isLoading={false} isSubmitting={isLoading} onCancel={() => navigate(-1)} />
    </MainTemplate>
  )
}