import React, { useEffect, useMemo, useState } from 'react'
import IDefectLocatieElement from './model'
import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, ScrollablePane, SelectionMode, Spinner } from '@fluentui/react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAll, selectAll } from './slice'
import { selectAll as selectLocaties, fetchAll as fetchLocaties } from '../defectLocatieSlice'
import IDefectLocatie from '../defectLocatieModel'
import '../../common/lemdoList.css'
import '../../common/grid.css'
import { Dictionary } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import downloadFile from 'services/downloadFile'
import UploadPanel from 'components/UploadPanel'
import { useBoolean } from '@fluentui/react-hooks'
import { IMatrixRow } from '../../common/matrixSelectionTypes'
import { MatrixCheckBox, MatrixCell } from '../../common/matrixSelection'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { fetchElementen, selectElementen } from '../../element/elementSlice'
import IElement from '../../element/elementModel'

const createMatrix = (locaties: IDefectLocatie[], locatieElementen: IDefectLocatieElement[]) => {
  const result = locaties.map<IMatrixRow>(l => {
    const selectionForLocationRow = locatieElementen.filter(le => le.defectlocatieId === l.id)
    const dict = {} as Dictionary<string>

    selectionForLocationRow.forEach(l => (dict[l.elementId.toString()] = '1'))

    return {
      rowId: l.id,
      cells: dict,
    }
  })
  return result
}

const LocatieMatrix: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const elementen = useSelector(selectElementen)
  const locaties = useSelector(selectLocaties)
  const entities = useSelector(selectAll)
  const [items, setItems] = useState<IMatrixRow[]>([])
  const [loading, setLoading] = useState(false)
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)

  const refreshItems = () => {
    dispatch(fetchElementen())
    dispatch(fetchLocaties())
    dispatch(fetchAll())
    setLoading(true)
  }

  useEffect(() => {
    if (elementen.length === 0) dispatch(fetchElementen())
    if (locaties.length === 0) dispatch(fetchLocaties())
    dispatch(fetchAll())
  }, [])

  useEffect(() => {
    setLoading(entities.length === 0)
  }, [items])

  useEffect(() => {
    setItems(createMatrix(locaties, entities))
  }, [locaties, entities])

  const categorieColumns = elementen.map<IColumn>((s: IElement) => {
    return {
      key: s.id.toString(),
      name: s.naam,
      minWidth: 25,
      maxWidth: 25,
      isResizable: false,
      isRowHeader: true,
      headerClassName: 'headerClass',
      onRender: (item: IMatrixRow) => <MatrixCheckBox id={s.id} titles={item.cells} />,
    } as IColumn
  })
  const columns = [
    {
      key: 'locatie',
      isRowHeader: true,
      minWidth: 250,
      maxWidth: 400,
      onRender: (item: IMatrixRow) => <MatrixCell text={createLocationText(item.rowId)} />,
    } as IColumn,
  ].concat(categorieColumns) as IColumn[]

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
      {
        text: 'Exporteer naar Excel',
        onClick: () => {
          downloadFile('/defectLocatieElementCategorie/exportLocaties', 'Locaties en Elementcategorieën.xlsx')
        },
        icon: 'Export',
      },
      {  text: 'Importeer van Excel', onClick: () => openPanel(), icon: 'Import' },
    ],
    []
  )

  const createLocationText = (id: number) => {
    var locatie = locaties.filter(l => l.id === id)
    if (locatie.length !== 0) return `${locatie[0].code} - ${locatie[0].naam}`
    return ''
  }

  const handleDismiss = () => {
    dismissPanel()
    refreshItems()
  }

  return loading ? (
    <div className="spinnerWrapper">
      <Spinner>Laden...</Spinner>
    </div>
  ) : (
    <MainTemplate title="Defectlocaties || Elementen" subTitle="Overzicht" commandItems={commandItems}>
      <UploadPanel title="Importeer wijzigen" dismissPanel={handleDismiss} isOpen={isOpen} uploadEndpoint={'/defectLocatieElementCategorie/importLocaties'} />
      <div style={{ height: '85vh', position: 'relative' }}>
        <ScrollablePane>
          <DetailsList
            columns={columns}
            items={items}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            constrainMode={ConstrainMode.unconstrained}
            compact={true}
            className="matrixGrid"
          />
        </ScrollablePane>
      </div>
    </MainTemplate>
  )
}

export default LocatieMatrix
