import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {PageIdDefinition, PAGES} from "../../pages";
import useApi, {Status} from "services/UseApi";
import {Spinner, SpinnerSize, Text, useTheme} from "@fluentui/react";
import MainTemplate from "../../containers/pages/PageTemplates/MainTemplate";

const VgeFromVhe: React.FC<{}> = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const theme = useTheme()
    const { state } = useApi("/vge/fromvhe/" + id)

    useEffect(() => {
        if (state.status === Status.success && state.data) {
            navigate(PAGES[PageIdDefinition.vge.edit].route.replace(":id", state.data), { replace: true })
        }
    }, [state.status])

    return (<MainTemplate title={'Vastgoedeenheid bij verhuureenheid'} subTitle={''} commandItems={[]}>
        {(state.status === Status.loading || state.status === Status.idle) &&
            (
                <div style={{ paddingTop: 50, alignItems: 'center', display: 'flex', justifyContent: 'center', height: '100%', width: '397px' }}>
                    <Spinner size={SpinnerSize.large} />
                </div>
            )
        }
        <div style={{ paddingLeft: 20 }}><Text variant={'medium'} color={theme.palette.themePrimary}>{state.error}</Text></div>
    </MainTemplate>)
}

export default VgeFromVhe