import React from 'react'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { useNavigate } from 'react-router-dom'
import { DimensieForm } from './DimensieForm'
import useApi, { Status } from 'services/UseApi'

const DimensiesNew = () => {
  const navigate = useNavigate()
  const { state, execute } = useApi('dimensies', 'POST', false)

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true }
  ]

  return (
    <MainTemplate title='Dimensies' subTitle='nieuw' commandItems={commandItems} error={state.error}>
      <DimensieForm onSubmit={(data) => execute(data, () => navigate(-1))} isExecuting={state.status === Status.loading} onCancel={() => navigate(-1)} />
    </MainTemplate>
  )
}

export default DimensiesNew