import React, { useState } from 'react'
import { IColumn, Panel, PanelType, SearchBox } from '@fluentui/react'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { ActiefInspectietaakListDto, zodiosHooks } from '../../../api/ApiClient'
import { usePagedParams } from '../../../services/usePagedParams'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../services/HandleError'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'

interface IEditPanelProps {
  inspectieSjabloonId: number
  refresh: () => void
  isOpen: boolean
  dismissPanel: any
}

type ActiefInspectietaakListDtoType = z.infer<typeof ActiefInspectietaakListDto>;

export const InspectieSjabloonTenantInspectietaakFormPanel: React.FC<IEditPanelProps> = props => {

  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = React.useState<string>()

  const onCancel = () => {
    props.dismissPanel()
    props.refresh()
    invalidate()
  }

  const { data, invalidate, isLoading } = zodiosHooks.useGetApiInspectieSjabloonsearchInspectietaken({
    queries: {
      InspectieSjabloonId: props.inspectieSjabloonId,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Inspectietaken', getPropertyName<ActiefInspectietaakListDtoType>('taak'), 'L', false)
    ]
  }, [])

  const onRenderFooterContent = () => {
    return (
    <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                         onOkClick={onSubmit}
                         onCancelClick={onCancel} />
    )
  }

  const { mutate: addKoppelingInspectietaak, isLoading: isSubmitting } = zodiosHooks.usePostApiInspectieSjabloonkoppelingInspectietaak(
    {}, { onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const onSubmit = () => {
    addKoppelingInspectietaak({ onderhoudssjablonId: props.inspectieSjabloonId, inspectietaakIds: selectedIds })
  }


  return (
    <Panel
      type={PanelType.large}
      headerText={'Koppelen inspectietaken'}
      isOpen={props.isOpen}
      onDismiss={onCancel}
      closeButtonAriaLabel="Sluiten"
      onRenderFooterContent={onRenderFooterContent}>
      <ErrorMessageBar error={error} />
      <SearchBox placeholder="Filter" styles={{ root: { width: '400px', margin: '6px' } }}
                 value={validatedSearchParams.filter}
                 onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                 onSearch={() => invalidate()} />
      <VgtPagedTable
        items={data?.items ?? undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage ?? false,
          hasPreviousPage: data?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey || 'taak',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.inspectietaakId}
        onSelectionChanged={setSelectedIds}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </Panel>
  )
}