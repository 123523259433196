import { useNavigate } from 'react-router-dom'
import React from 'react'
import { zodiosHooks } from '../../../api/ApiClient'
import { getTitleAndMessage } from '../../../services/HandleError'
import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import { HuurcontractbepalingForm } from './HuurcontractbepalingForm'


export const HuurcontractbepalingNew = () => {
  const navigate = useNavigate()
  const [error, setError] = React.useState<string>()

  const { mutate: addHuurcontractbepaling, isLoading } = zodiosHooks.usePostApiHuurcontractbepaling(
    {}, { onSuccess: () => navigate(-1), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true }
  ]

  return (
    <MainTemplate title="Huurcontractbepaling" subTitle="Nieuw" commandItems={commandItems} error={error}>
      <HuurcontractbepalingForm huurcontractbepalingId={0} isLoading={isLoading} isSubmitting={isLoading} onSubmit={addHuurcontractbepaling} onCancel={() => navigate(-1)} />
    </MainTemplate>
  )
}