import * as React from 'react'

import { useEffect, useRef, useState } from 'react'
import {
  getTheme,
  ITheme,
  mergeStyles,
  mergeStyleSets,
  FocusZone,
  FocusZoneDirection,
  Callout,
  DirectionalHint,
  Stack,
  StackItem,
  SearchBox,
  List,
} from '@fluentui/react'

export interface IAutocompleteProps {
  label: string
  searchBoxWidth?: number
  dropDownWidth?: number
  dropDownHeight?: number
  value: string | undefined
  items: ISuggestionItem[]
  searchTitle?: string
  suggestionCallback: (item: ISuggestionItem) => void
  searchCallback: (item: string) => void
  clearCallback?: () => void
}

const theme: ITheme = getTheme()
const { palette } = theme

const AutocompleteStyles = (width: number) => {
  return {
    width: `${width}px`,
    display: 'inline-block',
  }
}

export const CalloutStyle = (width: number) => {
  return { width: `${width}px` }
}

const SuggestionListStyle = () => {
  return { padding: '4px 16px', fontSize: '14px', cursor: 'default' }
}

const stackStyle = mergeStyles({
  padding: '0px 0px 0px 12px;',
  borderBottom: '1px solid rgb(219, 219, 219)',
  '& .ms-StackItem:first-child': {
    width: '215px',
  },
  '& .ms-StackItem:last-child': {
    width: '360px',
  },
  '& .autoCompleteLabel': {
    paddingTop: '5px',
  },
})

const SuggestionListItemStyle = mergeStyleSets({
  root: {
    selectors: {
      '&:hover': {
        backgroundColor: palette.neutralLight,
      },
    },
  },
})

export interface IAutocompleteState {
  isSuggestionDisabled: boolean
  searchText: string
}

export interface ISuggestionItem {
  key: number
  selected?: boolean
  displayValue: string
  searchValue: string
  tag?: any
}

export const Autocomplete: React.FC<IAutocompleteProps> = props => {
  const menuButtonElement = useRef(null)
  const searchList = useRef<List<ISuggestionItem>>(null)
  const [isSuggestionDisabled, setIsSuggestionDisabled] = useState(false)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    setSearchText(props.value ?? '')
    hideSuggestionCallOut()
  }, [props.value])

  const showSuggestionCallOut = () => {
    setIsSuggestionDisabled(true)
  }

  const hideSuggestionCallOut = () => {
    setIsSuggestionDisabled(false)
  }

  const handleClick = (item: ISuggestionItem) => {
    hideSuggestionCallOut()
    props.suggestionCallback(item)
    setSearchText(item.displayValue)
  }

  const onRenderCell = (item: any) => {
    if (item.key !== -1) {
      return (
        <div key={item.key} className={SuggestionListItemStyle.root} data-is-focusable={true}>
          <div id={'link' + item.key} style={SuggestionListStyle()} onClick={() => handleClick(item)}>
            {item.displayValue}
          </div>
        </div>
      )
    } else {
      return (
        <div key={item.key} data-is-focusable={true}>
          {item.displayValue}
        </div>
      )
    }
  }

  const renderSuggestionList = () => {
    return (
      <FocusZone direction={FocusZoneDirection.vertical}>
        <List ref={searchList} tabIndex={0} items={props.items} onRenderCell={onRenderCell} id="SearchList" />
      </FocusZone>
    )
  }

  const boxWidth = props.searchBoxWidth ?? 300
  const dropDownWidth = props.dropDownWidth ?? boxWidth - 4

  const renderSuggestions = () => {
    return (
      <Callout
        id="SuggestionContainer"
        ariaLabelledBy={'callout-suggestions'}
        gapSpace={2}
        coverTarget={false}
        alignTargetEdge={true}
        onDismiss={() => hideSuggestionCallOut()}
        setInitialFocus={false}
        hidden={!isSuggestionDisabled}
        calloutMaxHeight={props.dropDownHeight ?? 300}
        style={CalloutStyle(dropDownWidth)}
        target={menuButtonElement.current}
        directionalHint={DirectionalHint.bottomCenter}
        isBeakVisible={false}
      >
        {renderSuggestionList()}
      </Callout>
    )
  }

  const onChange = (newValue: string | undefined) => {
    let text = newValue ?? ''
    if (text === '') {
      hideSuggestionCallOut()
    } else {
      showSuggestionCallOut()
      props.searchCallback(text)
    }
    setSearchText(text)
  }

  const onClear = () => {
    if (props.clearCallback) props.clearCallback()
  }

  return (
    <Stack horizontal className={stackStyle}>
      <StackItem className="autoCompleteLabel">
        <label>{props.label}</label>
      </StackItem>
      <StackItem className="autoCompleteCode">
        <div ref={menuButtonElement} style={AutocompleteStyles(boxWidth)}>
          <SearchBox
            id={'SuggestionSearchBox'}
            placeholder={props.searchTitle}
            autoComplete="off"
            onChange={(_, v) => onChange(v)}
            value={searchText}
            onClear={() => onClear()}
          />
          {renderSuggestions()}
        </div>
      </StackItem>
    </Stack>
  )
}

export default Autocomplete
