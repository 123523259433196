import React, { useMemo, useState } from 'react'
import { IColumn, Link } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import { getPropertyName } from 'lib/interfaceUtils'
import EditPanelElement from './editPanel'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'
import ICoMoonFontIcon from '../../../../components/IcoMoon'
import { WoningverbeteringDto, zodiosHooks } from '../../../../api/ApiClient'
import { usePagedParams } from '../../../../services/usePagedParams'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { z } from 'zod'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'

type WoningverbeteringDtoType = z.infer<typeof WoningverbeteringDto>;

const WoningverbeteringList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const { validatedSearchParams, setFilter, setPage, setOrder } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = useState<string>()
  const { isReader } = useRoles()
  const [selectedWoningverbeteringItems, setSelectedWoningverbeteringItems] = useState<WoningverbeteringDtoType | undefined>()

  const {
    data: woningverbeteringItems,
    invalidate: fetchWoningverbeteringItems,
    isLoading: isFetchingWoningverbeteringItems
  } = zodiosHooks.useGetWoningverbetering({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: deleteWoningverbetering } = zodiosHooks.useDeleteWoningverbetering({}, {
    onSuccess: () => fetchWoningverbeteringItems(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const refreshItems = () => fetchWoningverbeteringItems()

  const handleItemInvoked = (id: string) => {
    setSelectedWoningverbeteringItems(woningverbeteringItems?.items?.find(item => item.woningverbeteringId === parseInt(id)))
    openPanel()
  }

  const removeItems = () => {
    deleteWoningverbetering(selectedIds)
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<WoningverbeteringDtoType>('omschrijving'),
      name: 'Omschrijving',
      fieldName: 'Omschrijving',
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: WoningverbeteringDtoType) => (
        <Link key={item.woningverbeteringId} onClick={() => handleItemInvoked(item.woningverbeteringId.toString())}>
          {item.omschrijving}
        </Link>
      )
    },
    {
      key: getPropertyName<WoningverbeteringDtoType>('voorziening'),
      name: 'Voorziening',
      fieldName: 'Voorziening',
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: WoningverbeteringDtoType) => (
        <Link key={item.woningverbeteringId} onClick={() => handleItemInvoked(item.woningverbeteringId.toString())}>
          {item.voorziening}
        </Link>
      )
    },
    {
      key: getPropertyName<WoningverbeteringDtoType>('cartotheekItem'),
      name: 'Cartotheek-item',
      fieldName: getPropertyName<WoningverbeteringDtoType>('cartotheekItem'),
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<WoningverbeteringDtoType>('inspectietaak'),
      name: 'Inspectietaak',
      fieldName: getPropertyName<WoningverbeteringDtoType>('inspectietaak'),
      minWidth: 600,
      maxWidth: 600,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: 'fonts-icon',
      name: 'Fonticoon',
      fieldName: getPropertyName<WoningverbeteringDtoType>('fonticoonNaam'),
      minWidth: 70,
      maxWidth: 70,
      isResizable: false,
      isRowHeader: false,
      onRender: (item: WoningverbeteringDtoType) => <ICoMoonFontIcon iconName={item?.fonticoonNaam!} />
    }
  ]

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    {
      visible: isReader, text: 'Toevoegen', onClick: () => {
        setSelectedWoningverbeteringItems(undefined)
        openPanel()
      }, icon: 'Add'
    },
    {
      visible: isReader,
      text: 'Verwijderen',
      onClick: () => removeItems(),
      icon: 'Delete',
      disabled: !selectedIds.length
    }
  ], [isReader, selectedIds.length])

  return (
    <MainTemplate title='Woningverbetering' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <EditPanelElement selectedWoningverbeteringItem={selectedWoningverbeteringItems}
                        fetchWoningverbeteringItems={fetchWoningverbeteringItems} dismissPanel={dismissPanel}
                        isOpen={isPanelOpen} />
      <VgtPagedTable
        isLoading={isFetchingWoningverbeteringItems}
        height={'203'}
        items={woningverbeteringItems?.items ?? []}
        columns={columns}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: woningverbeteringItems?.totalCount ?? 0,
          totalPages: woningverbeteringItems?.totalPages ?? 0,
          hasNextPage: woningverbeteringItems?.hasNextPage ?? false,
          hasPreviousPage: woningverbeteringItems?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        getKey={(item) => item?.woningverbeteringId}
        onItemInvoked={(e) => handleItemInvoked(e.woningverbeteringId.toString())}
        onSelectionChanged={setSelectedIds}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}
export default WoningverbeteringList
