import {createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction} from '@reduxjs/toolkit';
import {entityAdapterInitState, IEntityAdapterState} from 'interfaces/entityAdapterState';
import {PagedEntities} from 'interfaces/pagedEntities';
import {invokeFetch} from 'services/apiClient';
import {RootState} from 'store';
import * as yup from 'yup'

export const TenantTaakSchema = yup.object().shape({
})

export interface IEntityAdapterStateWithUpdate extends IEntityAdapterState {
    updateStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState: IEntityAdapterStateWithUpdate = {
    ...entityAdapterInitState,
    updateStatus: 'idle'
}

interface Dienstencomponent {
    dienstencomponentId: number
    code: string
    omschrijving?: string
}

export interface ITenantTaak {
    tenantTaakId: number
    naam: string
    taakId: number
    taakNaam: string
    dienstencomponentIds?: number[]
    dienstencomponenten: Dienstencomponent[]
    onderhoudstaakErpId?: number
    onderhoudstaakErpCode: string
    onderhoudstaakErpOmschrijving: string
    onderhoudsspecialismeId?: number
    onderhoudsspecialismeCode: string
    onderhoudsspecialismeNaam: string
    meetkundigeEenheidId?: number
    meetkundigeEenheidCode: string
    meetkundigeEenheidOmschrijving: string
    bewerkingstijdMin?: number
    urgentieDgn?: number
    afspraak: boolean
    actief: boolean
    tonenAanKlant: boolean
    verkoopprijs?: number
    kostprijs?: number
    betalerOptieId?: number
    betalerNaam: string
    heeftInspectieNodig: boolean
    teBeoordelen: boolean
    metOnderhoudscontract: boolean
    portalToelichtingBijOntbrekendContract: string
    zelfOplossen: boolean
    portalToelichtingZelfOplossen: string
    isSpoed: boolean
    spoedToelichting: string
}

export interface ITenantTaakArgs {
    filter?: string
    onlyForOnderhoudscontract?: boolean
}

const entityAdapter = createEntityAdapter<ITenantTaak>({
    selectId: e => e.taakId,
    sortComparer: (a, b) => (a.taakNaam ?? "").localeCompare(b.taakNaam)
})

const baseUrl = '/tenantTaak'
const basePrefix = 'tenantTaak'
const getSliceState = (state: RootState) => state.tenantTaak

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (args: ITenantTaakArgs, thunkAPI) => {
    return await invokeFetch<PagedEntities<ITenantTaak>>(thunkAPI, 'GET', `${baseUrl}?Filter=${args.filter}&onlyForOnderhoudscontract=${args.onlyForOnderhoudscontract ?? false}`)
})


const setPendingState = (state: EntityState<ITenantTaak> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
}
const setSucceededState = (state: EntityState<ITenantTaak> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<ITenantTaak> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
}

export const tenantTaakSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(initialState),

    reducers: {
        clearError: state => {
            state.error = null
        },
        select: (state, action: PayloadAction<string | undefined>) => {
            state.selectedId = action.payload
        },
        clearResult: state => entityAdapter.removeAll(state),
        clearSelection: state => {
            state.selectedId = undefined
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
    },

    extraReducers: builder => {
        builder.addCase(fetchAll.pending, state => setPendingState(state))
        builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<ITenantTaak>>) => {
            setSucceededState(state)
            entityAdapter.setAll(state, action.payload.items)
        })
        builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))

    },
})

export const getSelectedEntity = (state: RootState) => {
    const tenantTaakState = getSliceState(state)
    const selectedId = tenantTaakState.selectedId
    if (selectedId) {
        const taak = tenantTaakState.entities[selectedId]
        if (taak) {
            return {...taak, dienstencomponentIds: taak.dienstencomponenten?.map(d => d.dienstencomponentId)}
        }
        return undefined
    } else return undefined
}

export const getLoadingState = (state: RootState) => state.tenantTaak.status
export const getUpdateState = (state: RootState) => state.tenantTaak.updateStatus

export const getErrorState = (state: RootState) => state.tenantTaak.error

export const {add, modify, removeMany, select, clearSelection, clearResult} = tenantTaakSlice.actions
export const {selectAll, selectEntities, selectById} = entityAdapter.getSelectors<RootState>(state => state.tenantTaak)

export default tenantTaakSlice.reducer
  