import React, { useMemo, useState } from 'react'
import { IColumn, Link, SelectionMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import FluentTable from 'components/FluentTable'
import { getPropertyName } from 'lib/interfaceUtils'
import EditPanelElement from './editPanel'
import { selectAll, select, clearSelection, fetchAll, deleteTerreinClassificatie, getLoadingState, ITerreinClassificatie } from './terreinClassificatieSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'

const TerreinClassificatieList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const items = useSelector(selectAll)
  const loadingState = useSelector(getLoadingState)
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const handleListSelectionChanged = (ids: string[]) => setSelectedItems(ids)
  const refreshItems = () => dispatch(fetchAll())
  const onGetKey = (item: ITerreinClassificatie) => item.id.toString()
  const { isAdmin } = useRoles()

  const handleItemInvoked = (id: string) => {
    dispatch(select(id))
    openPanel()
  }

  const removeItems = () => {
    dispatch(deleteTerreinClassificatie(selectedItems.map(value => parseInt(value))))
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<ITerreinClassificatie>('terrein'),
      name: 'Terreinclassificatie',
      fieldName: 'Terreinclassificatie',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: ITerreinClassificatie) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.terrein}
        </Link>
      ),
    }
  ]

  const commandItems = useMemo(() =>[
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    { visible: isAdmin, text: 'Toevoegen', onClick: () => { dispatch(clearSelection()); openPanel() }, icon: 'Add' },
    { visible: isAdmin, text: 'Verwijderen', onClick: () => removeItems(), icon: 'Delete', disabled: !selectedItems.length },
  ], [isAdmin, selectedItems.length])

  return (
    <MainTemplate title='Terrein classificatie' subTitle='Overzicht' commandItems={commandItems}>
      <EditPanelElement dismissPanel={dismissPanel} isOpen={isPanelOpen} />
      <div style={{ height : '90vh' }}>
        <FluentTable
          columns={columns}
          items={items}
          loading={loadingState === 'pending'}
          selectionMode={SelectionMode.multiple}
          onSelectionChanged={handleListSelectionChanged}
          onGetKey={onGetKey}
          onItemInvoked={handleItemInvoked}
        />
      </div>
    </MainTemplate>
  )
}
export default TerreinClassificatieList
