import { debounce } from 'lodash'
import { ISearchBoxStyles, SearchBox, Stack, StackItem } from '@fluentui/react'
import React from 'react'

export function VgtSearch(props: Readonly<{
  filter: string,
  onSearch: (filter: string) => void,
  customSearchBoxStyles?: Partial<ISearchBoxStyles>
}>) {

  const delayedSearch = debounce(eventData => props.onSearch(eventData), 500)

  const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
      width: '400px',
      margin: '6px'
    }
  }

  const onSearchBoxChanged = (newValue: string | undefined) => {
    if (newValue && newValue.length >= 2) {
      delayedSearch(newValue)
    } else {
      delayedSearch('')
    }
  }

  return <Stack horizontal tokens={{ childrenGap: 10 }}>
    <StackItem>
      <SearchBox styles={props.customSearchBoxStyles ?? searchBoxStyles} placeholder='Zoeken ...'
                 onChange={(_, v) => onSearchBoxChanged(v)} onSearch={props.onSearch} defaultValue={props.filter}
                 onClear={onSearchBoxChanged} />
    </StackItem>
  </Stack>
}