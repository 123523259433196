import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'

import * as yup from 'yup'
import { IPand, IVge } from 'features/vge/vgeSlice'
import { PagedEntities } from 'interfaces/pagedEntities'
import { IObjectWithKey } from '@fluentui/react'

export const gebouwSchema = yup.object().shape({
  code: yup.string().required('Code is verplicht').max(50, 'Maximale lengte is 50'),
  naam: yup.string().required('Naam is verplicht').max(200, 'Maximale lengte is 200'),
  gebouwenClusterId: yup.number().required().test('is-zero', 'Het veld gebouwencluster is niet gevuld.', (value) => value !== 0)
})

export const gebouwElementHoeveelheidSchema = yup.object().shape({
  hoofdElementGroep: yup.string().required('Hoofdelement groep is verplicht'),
  plaatsaanduiding: yup.string().optional().nullable(),
  element: yup.string().required('Element is verplicht'),
  hoeveelheid: yup.number().required('Hoeveelheid is verplicht'),
  eenheidId: yup.number().required('Eenheid is verplicht'),
  vervangingsJaar: yup.number().optional().nullable(),
  inspectieDatum: yup.date().optional(),
  conditie: yup.number().optional()
})

export interface IGebouwDeel extends IObjectWithKey {
  id: number
  code: string
  naam: string
  gebouwId?: number
  gebouwNaam?: string
  serviceadresVge?: number
  advertentietekst: string
  vgesMetAdres: IVge[]
  vgesZonderAdres: IVge[]
}

export interface IGebouwElementHoeveelheid {
  id: number
  gebouwCluster?: string
  gebouwDeel?: string
  hoofdElementGroep: string
  hoofdElementGroepOmschrijving: string
  plaatsaanduiding?: string
  element: string
  elementOmschrijving: string
  hoeveelheid: number
  eenheidId: number
  vervangingsJaar?: number
  inspectieDatum?: string
  conditie?: number
  toelichting?: string
}

export interface IGebouw {
  id: number
  code: string | null
  naam: string | null
  registratieniveau: string
  type?: 1 | 2
  omschrijving?: string | null
  vastgoedportefeuilleId: number | null
  vastgoeddeelportefeuilleId: number | null
  advertentietekst: string
  gebouwenClusterId?: number
  vgesMetAdres: IVge[]
  vgesZonderAdres: IVge[]
  gebouwDelen: IGebouwDeel[]
  getGebouwDelenFromList?: boolean
  bagPanden: IPand[]
  hoeveelheden: IGebouwElementHoeveelheid[]
  serviceadresVge: number | null
  serviceEmailadres: string
  serviceTelefoonnummer: string
}

interface IGebouwState {
  selectedGebouw: IGebouw | undefined
  selectedGebouwdeel: IGebouwDeel | undefined
  selectedId: number | undefined
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
  searchFilter: string
}

export const gebouwenInitialState: IGebouwState = {
  selectedId: undefined,
  selectedGebouw: undefined,
  selectedGebouwdeel: undefined,
  status: 'idle',
  error: null,
  searchFilter: ''
}

const entityAdapter = createEntityAdapter<IGebouw>({ selectId: gebouw => gebouw.id })

export const fetchGebouwen = createAsyncThunk('gebouwen/fetchAllStatus', async ({ filter }: {
  filter: string
}, thunkAPI) => {
  return await invokeFetch<PagedEntities<IGebouw>>(thunkAPI, 'GET', `/gebouwen?top=50000&Filter=${filter}`)
})

export const updateHoeveelheid = createAsyncThunk('gebouwen/updateHoeveelheid', async (hoeveelheid: IGebouwElementHoeveelheid, thunkAPI) => {
  return await invokeFetch<IGebouwElementHoeveelheid>(thunkAPI, 'POST', '/gebouwen/updateHoeveelheid', hoeveelheid)
})

export const gebouwen = createSlice({
  name: 'gebouwen',
  initialState: entityAdapter.getInitialState(gebouwenInitialState),

  reducers: {
    select: (state, action: PayloadAction<number | undefined>) => {
      state.selectedId = action.payload
    },
    clearResult: state => entityAdapter.removeAll(state),
    selectNewGebouw: state => {
      state.selectedGebouw = { code: '', vgesMetAdres: [] as IVge[], vgesZonderAdres: [] as IVge[] } as IGebouw
    },
    setSelectedGebouwByCode: (state, action: PayloadAction<string>) => {
      const gebouw = state.entities[action.payload]
      if (gebouw) state.selectedGebouw = gebouw
    },
    selectNewGebouwdeel: state => {
      state.selectedGebouwdeel = { code: '', naam: '' } as IGebouwDeel
    },
    setSelectedGebouwdeelByCode: (state, action: PayloadAction<string>) => {
      const gebouwdeel = state.selectedGebouw?.gebouwDelen.find(v => v.code === action.payload)
      if (gebouwdeel) state.selectedGebouwdeel = gebouwdeel
    },
    updateSelectedGebouwValues: (state, action: PayloadAction<IGebouw>) => {
      if (state.selectedGebouw) {
        state.selectedGebouw = {
          ...state.selectedGebouw,
          naam: action.payload.naam,
          code: action.payload.code,
          omschrijving: action.payload.omschrijving,
          advertentietekst: action.payload.advertentietekst,
          type: action.payload.type
        }
      }
    },
    addVgesMetAdresToSelectedGebouw: (state, action: PayloadAction<IVge[]>) => {
      if (state.selectedGebouw) {
        if (!state.selectedGebouw.vgesMetAdres) state.selectedGebouw.vgesMetAdres = []

        action.payload.forEach(item => {
          state.selectedGebouw?.vgesMetAdres.push(item)
        })
      }
    },
    removeVgesMetAdresFromSelectedGebouw: (state, action: PayloadAction<IVge[]>) => {
      if (state.selectedGebouw) {
        const gebouw: IGebouw = state.selectedGebouw
        if (!gebouw.vgesMetAdres) state.selectedGebouw.vgesMetAdres = []
        action.payload.forEach(item => {
          const index = gebouw.vgesMetAdres.findIndex(value => value.id === item.id)
          if (index >= 0) {
            gebouw.vgesMetAdres.splice(index, 1)
          }
        })
      }
    },
    addVgesZonderAdresToSelectedGebouw: (state, action: PayloadAction<IVge[]>) => {
      if (state.selectedGebouw) {
        const gebouw: IGebouw = state.selectedGebouw
        if (!gebouw.vgesZonderAdres) gebouw.vgesZonderAdres = []

        action.payload.forEach(item => {
          gebouw.vgesZonderAdres.push(item)
        })
      }
    },
    removeVgesZonderAdresFromSelectedGebouw: (state, action: PayloadAction<IVge[]>) => {
      if (state.selectedGebouw) {
        const gebouw: IGebouw = state.selectedGebouw
        if (!gebouw.vgesZonderAdres) gebouw.vgesZonderAdres = []
        action.payload.forEach(item => {
          const index = gebouw.vgesZonderAdres.findIndex(value => value.id === item.id)
          if (index >= 0) {
            gebouw.vgesZonderAdres.splice(index, 1)
          }
        })
      }
    },
    clearErrorState: state => {
      state.error = null
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll
  },

  extraReducers: builder => {
    builder.addCase(fetchGebouwen.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(fetchGebouwen.fulfilled, (state, action) => {
      state.status = 'succeeded'
      entityAdapter.setAll(state, action.payload.items)
      state.searchFilter = action.meta.arg.filter
    })
    builder.addCase(fetchGebouwen.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message ?? null
    })

    builder.addCase(updateHoeveelheid.fulfilled, (state, action: PayloadAction<IGebouwElementHoeveelheid>) => {
      const gebouw = state.selectedGebouw
      if (gebouw) {
        const hoeveelheden = gebouw.hoeveelheden
        const index = hoeveelheden.findIndex(v => v.id === action.payload.id)
        if (index >= 0) {
          hoeveelheden[index] = action.payload
        } else {
          hoeveelheden.push(action.payload)
        }
      }
      state.status = 'succeeded'
    })

    builder.addCase(updateHoeveelheid.rejected, (state, action) => {
      state.error = action.error.message ?? null
      state.status = 'failed'
    })
  }
})

export const {
  addVgesZonderAdresToSelectedGebouw,
  clearResult,
  select
} = gebouwen.actions

export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.gebouwen)
export const getLoadingStatus = (state: RootState) => state.gebouwen.status

export const getSelectedGebouw = (state: RootState) => {
  const gebouw = state.gebouwen.selectedGebouw
  if (gebouw) return gebouw
  return undefined
}

export const getSelectedGebouwdeel = (state: RootState) => {
  const gebouwdeel = state.gebouwen.selectedGebouwdeel
  if (gebouwdeel) return gebouwdeel

  return { code: '', naam: '' } as IGebouwDeel
}

export const getSelectedGebouwHoeveelheid = (state: RootState, id: number | undefined) => {
  if (id) {
    const gebouw = state.gebouwen.selectedGebouw
    return gebouw?.hoeveelheden.find(i => i.id === id)
  }
  return undefined
}

export const getSelectedGebouwdeelHoeveelheid = (state: RootState, id: number | undefined) => {
  if (id) {
    const gebouwDeel = getSelectedGebouwdeel(state)
    const gebouw = getSelectedGebouw(state)
    return gebouw?.hoeveelheden?.find(i => i.id === id && i.gebouwDeel === gebouwDeel.code)
  }
  return undefined
}

export default gebouwen.reducer
