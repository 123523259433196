import { IDropdownOption } from '@fluentui/react'
import { createAsyncThunk, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { IEntityPageObjectResponse } from 'lib/sliceSupport'
import IElement from './elementModel'
import { entityAdapterInitState } from 'interfaces/entityAdapterState'

const entityAdapter = createEntityAdapter<IElement>({
    sortComparer: (a, b) => a.naam?.localeCompare(b.naam),
})

const basePrefix = '/elementen'

export const fetchElementen = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<IEntityPageObjectResponse<IElement>>(thunkAPI, 'GET', '/api/element/page?skip=0&top=50000')
})

export const elementSlice = createSlice({
    name: 'elementen',
    initialState: entityAdapter.getInitialState(entityAdapterInitState),

    reducers: {
        select: (state, action: PayloadAction<number | undefined>) => {
            state.selectedId = action.payload
        },
        clearSelection: state => {
            state.selectedId = undefined
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
    },

    extraReducers: builder => {
        builder.addCase(fetchElementen.pending, state => {
            state.status = 'pending'
        })
        builder.addCase(fetchElementen.fulfilled, (state, action: PayloadAction<IEntityPageObjectResponse<IElement>>) => {
            state.status = 'succeeded'
            const items = action.payload.items
            entityAdapter.setAll(state, items)
        })
        builder.addCase(fetchElementen.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message || null
        })
    },
})

export const getElementenAsOptions = (state: RootState) => state.elementen.ids.map((id: EntityId) => {
    const entity = state.elementen.entities[id]
    return {
        key: entity?.id,
        text: `${entity?.naam}`
    } as IDropdownOption
})

export const { selectAll: selectElementen, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.elementen)

export default elementSlice.reducer
