import { Pivot, PivotItem, SelectionMode, Stack, StackItem } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from 'store'
import SearchPanelKVK from '../rechtspersoon/searchPanelKvK'
import { AppartementsRechtenCard } from './cards/AppartementsRechtenCard'
import { BeheerCard } from './cards/BeheerCard'
import { SelectGerechtigdePanel } from './panels/SelectGerechtigdenPanel'
import { SelectVvePanel } from './panels/SelectVvePanel'
import { KadastraleBronCard } from './cards/KadastraleBronCard'
import { RechtspersoonCard } from './cards/RechtspersoonCard'
import { RelatedVveCard } from './cards/RelatedVveCard'
import { VveAptPanel } from './panels/VveAptPanel'
import { VveBronPanel } from './panels/VveBronPanel'
import { VveEditPanel } from './panels/VveEditPanel'
import {
  addAptIndexenToVve,
  addBronnenToVve,
  fetchVve,
  getSelectedEntity,
  removeAptIndexenFromVve,
  removeBronnenFromVve,
  removeBovenliggendeVve,
  addBovenliggendeVve,
  removeVves,
  addVves,
  updateVve,
  IVve,
  addVve,
  getLoadingState,
  addKadastraalSubjectToVve
} from './vveSlice'
import { KvkDetailsDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { z } from 'zod'

type KvkDetailDto = z.infer<typeof KvkDetailsDto>;

const VveDetails: React.FC = () => {
  const { id } = useParams()
  const selectedEntity = useSelector(getSelectedEntity)
  const [title, setTitle] = useState('VvE')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { mutate } = zodiosHooks.usePutVvefromkvkId(
    { params: { id: selectedEntity?.id ?? 0 } }, {
      onError: (error) => (getTitleAndMessage(error).message)
    }
  )

  useEffect(() => {
    setTitle(`VvE ${id !== '0' ? 'wijzigen' : 'toevoegen'}`)

    if (id !== '0') {
      dispatch(fetchVve(id ?? '0'))
    } else {
      openEditPanel()
    }
  }, [id])

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Wijzigen', onClick: () => openEditPanel(), icon: 'Edit', iconOnly: true },
    { text: 'Koppelen KvK', onClick: () => openKvkPanel(), icon: 'Edit', iconOnly: true },
    {
      text: 'Koppelen gerechtigde',
      onClick: () => openGerechtigdePanel(),
      icon: 'Edit',
      iconOnly: true
    }
  ]

  const [isEditPanelOpen, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false)
  const [isBronPanelOpen, { setTrue: openBronPanel, setFalse: dismissBronPanel }] = useBoolean(false)
  const [isAptPanelOpen, { setTrue: openAptPanel, setFalse: dismissAptPanel }] = useBoolean(false)
  const [isBovenliggendeVvePanelOpen, {
    setTrue: openBovenliggendeVvePanel,
    setFalse: dismissBovenliggendeVvePanel
  }] = useBoolean(false)
  const [isOnderliggendeVvePanelOpen, {
    setTrue: openOnderliggendeVvePanel,
    setFalse: dismissOnderliggendeVvePanel
  }] = useBoolean(false)
  const [isKvkPanelOpen, { setTrue: openKvkPanel, setFalse: dismissKvkPanel }] = useBoolean(false)
  const [isGerechtigdePanelOpen, {
    setTrue: openGerechtigdePanel,
    setFalse: dismissGerechtigdePanel
  }] = useBoolean(false)
  const [navigateOnSucceeded, setNavigateOnSucceeded] = useState(false)
  const loadingState = useSelector(getLoadingState)

  const saveVve = (vve: IVve) => {
    if (vve.id !== 0) {
      dispatch(updateVve(vve))
    } else {
      dispatch(addVve(vve))
      setNavigateOnSucceeded(true)
    }
  }

  useEffect(() => {
    if (navigateOnSucceeded && loadingState === 'succeeded') {
      setNavigateOnSucceeded(false)
      navigate(`/beheer/relatie/vves/${selectedEntity?.id}`, { replace: true })
    }
  }, [navigateOnSucceeded, loadingState])

  const connectBronIndexen = (indexen: string[]) => {
    dispatch(addBronnenToVve({ id: selectedEntity?.id!, kadastraleObjecten: indexen }))
  }

  const disconnectBronnen = (bronnen: string[]) => {
    dispatch(removeBronnenFromVve({ id: selectedEntity?.id!, kadastraleObjecten: bronnen }))
  }

  const connectAptIndexen = (indexen: string[]) => {
    dispatch(addAptIndexenToVve({ id: selectedEntity?.id!, kadastraleObjecten: indexen }))
  }

  const disconnectAptIndexen = (indexen: string[]) => {
    dispatch(removeAptIndexenFromVve({ id: selectedEntity?.id!, kadastraleObjecten: indexen }))
  }

  const setSubject = (subjectId: string[]) => {
    dispatch(addKadastraalSubjectToVve({ vveId: selectedEntity?.id!, kadastraalSubjectId: parseInt(subjectId[0]) }))
  }

  const disconnectBovenliggendeVve = () => {
    dispatch(removeBovenliggendeVve(selectedEntity?.id!))
  }

  const setBovenliggendeVve = (vves: string[]) => {
    dispatch(addBovenliggendeVve({ id: selectedEntity?.id!, onderliggendeVves: vves }))
  }

  const disconnectVves = (vves: string[]) => {
    dispatch(removeVves({ id: selectedEntity?.id!, onderliggendeVves: vves }))
  }

  const connectVves = (vves: string[]) => {
    dispatch(addVves({ id: selectedEntity?.id!, onderliggendeVves: vves }))
  }

  const onKvkItemSelected = (item: KvkDetailDto) => {
    if (!selectedEntity?.id) return

    mutate(item)
    dispatch(fetchVve(id ?? '0'))
  }

  return (
    <MainTemplate title={'Vereniging van Eigenaars'} subTitle={title} commandItems={commandItems}>
      <VveEditPanel isOpen={isEditPanelOpen} saveEntity={(vve) => saveVve(vve)} vve={selectedEntity}
                    dismissPanel={dismissEditPanel} />
      <VveAptPanel isOpen={isAptPanelOpen} addAppartementsIndexen={(indexen) => connectAptIndexen(indexen)}
                   dismissPanel={dismissAptPanel} />
      <VveBronPanel isOpen={isBronPanelOpen} addAppartementsIndexen={(indexen) => connectBronIndexen(indexen)}
                    dismissPanel={dismissBronPanel} />
      <SelectVvePanel key={'bovenliggendeSelectie'} isOpen={isBovenliggendeVvePanelOpen}
                      onSelectionComplete={(vve) => setBovenliggendeVve(vve)}
                      dismissPanel={dismissBovenliggendeVvePanel} selectionMode={SelectionMode.single}
                      title='Selecteer bovenliggende VvE' />
      <SelectVvePanel key={'onderliggendeSelectie'} isOpen={isOnderliggendeVvePanelOpen}
                      onSelectionComplete={(vves) => connectVves(vves)} dismissPanel={dismissOnderliggendeVvePanel}
                      selectionMode={SelectionMode.multiple} title='Selecteer onderliggende VvE' />
      <SearchPanelKVK onSelected={onKvkItemSelected} handelsNaam={''} isOpen={isKvkPanelOpen} dismissPanel={dismissKvkPanel} onOpen={openKvkPanel} />
      <SelectGerechtigdePanel isOpen={isGerechtigdePanelOpen} onSelectionComplete={(subjectId) => setSubject(subjectId)}
                              dismissPanel={dismissGerechtigdePanel} />

      <Pivot aria-label='VvE details' styles={{
        link: { padding: '0 8 0 0' },
        linkIsSelected: { padding: '0 8 0 0' },
        itemContainer: { padding: '16px 0 0 0' }
      }}
             style={{ marginBlockEnd: 10 }}
      >
        <PivotItem headerText='Details'>

          <Stack horizontal tokens={{ padding: '15', childrenGap: '20 20', maxWidth: '99%' }}>
            <StackItem grow={1}>
              <RechtspersoonCard vve={selectedEntity} />
              <div style={{ height: '10px' }} />
              <RelatedVveCard vve={selectedEntity} onDisconnect={disconnectVves}
                              onDisconnectBovenliggendeVve={disconnectBovenliggendeVve}
                              onOpenBovenliggendPanel={openBovenliggendeVvePanel}
                              onOpenOnderliggendPanel={openOnderliggendeVvePanel} />
            </StackItem>
            <StackItem grow={1}>
              <KadastraleBronCard vve={selectedEntity} onDisconnect={disconnectBronnen} onOpenPanel={openBronPanel} />
            </StackItem>
          </Stack>
        </PivotItem>
        <PivotItem headerText='Appartementsrechten' itemCount={selectedEntity?.appartmentsrechten?.length}>

          <Stack horizontal tokens={{ padding: '0', childrenGap: '20 20', maxWidth: '99%' }}>
            <StackItem grow={1}>
              <AppartementsRechtenCard vve={selectedEntity} onDisconnect={disconnectAptIndexen} onOpenPanel={openAptPanel} />
            </StackItem>
          </Stack>
        </PivotItem>
        <PivotItem headerText='Beheer'>
          <BeheerCard vve={selectedEntity} />
        </PivotItem>
      </Pivot>
    </MainTemplate>
  )
}

export default VveDetails
