import { IDropdownOption } from '@fluentui/react';

export const landOptions: IDropdownOption[] = [
    { key: '6023', text: 'Afghanistan' },
    { key: '5034', text: 'Albanië' },
    { key: '6047', text: 'Algerije' },
    { key: '8002', text: 'Amerikaans-Samoa' },
    { key: '7088', text: 'Amerikaanse Maagdeneilanden' },
    { key: '7005', text: 'Andorra' },
    { key: '5026', text: 'Angola' },
    { key: '8036', text: 'Anguilla' },
    { key: '8045', text: 'Antigua en Barbuda' },
    { key: '7015', text: 'Argentinië' },
    { key: '5054', text: 'Armenië' },
    { key: '5095', text: 'Aruba' },
    { key: '5055', text: 'Ascension' },
    { key: '6016', text: 'Australië' },
    { key: '5097', text: 'Azerbeidzjan' },
    { key: '5056', text: 'Azoren' },
    { key: '6033', text: "Bahama's" },
    { key: '5057', text: 'Bahrein' },
    { key: '7084', text: 'Bangladesh' },
    { key: '7004', text: 'Barbados' },
    { key: '5098', text: 'Belarus' },
    { key: '5010', text: 'België' },
    { key: '8017', text: 'Belize' },
    { key: '8023', text: 'Benin' },
    { key: '9048', text: 'Bermuda' },
    { key: '5058', text: 'Bhutan' },
    { key: '6015', text: 'Bolivia' },
    { key: '5106', text: 'Bonaire' },
    { key: '9089', text: 'Bondsrepubliek Duitsland' },
    { key: '6065', text: 'Bosnië-Herzegovina' },
    { key: '5011', text: 'Botswana' },
    { key: '6008', text: 'Brazilië' },
    { key: '7095', text: 'Brits Antarctisch Territorium' },
    { key: '7096', text: 'Brits Indische Oceaanterritorium' },
    { key: '7030', text: 'Britse Maagdeneilanden' },
    { key: '5042', text: 'Brunei' },
    { key: '7024', text: 'Bulgarije' },
    { key: '5096', text: 'Burkina Faso' },
    { key: '6001', text: 'Burundi' },
    { key: '6031', text: 'Cambodja' },
    { key: '5001', text: 'Canada' },
    { key: '6053', text: 'Canarische Eilanden' },
    { key: '7092', text: 'Caymaneilanden' },
    { key: '9086', text: 'Centraal-Afrikaanse Republiek' },
    { key: '5021', text: 'Chili' },
    { key: '6022', text: 'China' },
    { key: '8012', text: 'Christmaseiland' },
    { key: '8013', text: 'Cocoseilanden' },
    { key: '5033', text: 'Colombia' },
    { key: '5060', text: 'Comoren' },
    { key: '9008', text: 'Congo' },
    { key: '7097', text: 'Cookeilanden' },
    { key: '7007', text: 'Costa Rica' },
    { key: '5006', text: 'Cuba' },
    { key: '5107', text: 'Curaçao' },
    { key: '5040', text: 'Cyprus' },
    { key: '6069', text: 'Democratische Republiek Congo' },
    { key: '5015', text: 'Denemarken' },
    { key: '9087', text: 'Djibouti' },
    { key: '8030', text: 'Dominica' },
    { key: '7027', text: 'Dominicaanse Republiek' },
    { key: '7039', text: 'Ecuador' },
    { key: '7014', text: 'Egypte' },
    { key: '7032', text: 'El Salvador' },
    { key: '9043', text: 'Equatoriaal-Guinea' },
    { key: '9003', text: 'Eritrea' },
    { key: '7065', text: 'Estland' },
    { key: '9038', text: 'Eswatini' },
    { key: '5020', text: 'Ethiopië' },
    { key: '8014', text: 'Faeröer' },
    { key: '5061', text: 'Falklandeilanden' },
    { key: '6032', text: 'Fiji' },
    { key: '5027', text: 'Filipijnen' },
    { key: '6002', text: 'Finland' },
    { key: '5002', text: 'Frankrijk' },
    { key: '5062', text: 'Frans-Guyana' },
    { key: '6054', text: 'Frans-Polynesië' },
    { key: '6048', text: 'Gabon' },
    { key: '7008', text: 'Gambia' },
    { key: '5112', text: 'Gazastrook en Westelijke Jordaanoever' },
    { key: '6064', text: 'Georgië' },
    { key: '5024', text: 'Ghana' },
    { key: '6055', text: 'Gibraltar' },
    { key: '8008', text: 'Grenada' },
    { key: '6003', text: 'Griekenland' },
    { key: '5065', text: 'Groenland' },
    { key: '6039', text: 'Groot-Brittannië' },
    { key: '5066', text: 'Guadeloupe' },
    { key: '8001', text: 'Guam' },
    { key: '6004', text: 'Guatemala' },
    { key: '7040', text: 'Guinee' },
    { key: '5072', text: 'Guinee-Bissau' },
    { key: '6025', text: 'Guyana' },
    { key: '6041', text: 'Haïti' },
    { key: '7017', text: 'Honduras' },
    { key: '5017', text: 'Hongarije' },
    { key: '6007', text: 'Ierland' },
    { key: '6011', text: 'IJsland' },
    { key: '7046', text: 'India' },
    { key: '6024', text: 'Indonesië' },
    { key: '9999', text: 'Internationaal gebied' },
    { key: '5043', text: 'Irak' },
    { key: '5012', text: 'Iran' },
    { key: '6034', text: 'Israël' },
    { key: '7044', text: 'Italië' },
    { key: '5030', text: 'Ivoorkust' },
    { key: '6017', text: 'Jamaica' },
    { key: '7035', text: 'Japan' },
    { key: '5048', text: 'Jemen' },
    { key: '5080', text: 'Johnston' },
    { key: '6042', text: 'Jordanië' },
    { key: '8025', text: 'Kaapverdië' },
    { key: '5035', text: 'Kameroen' },
    { key: '8034', text: 'Kanaaleilanden' },
    { key: '5099', text: 'Kazachstan' },
    { key: '7002', text: 'Kenya' },
    { key: '6021', text: 'Kirgizië' },
    { key: '8027', text: 'Kiribati' },
    { key: '7045', text: 'Koeweit' },
    { key: '5105', text: 'Kosovo' },
    { key: '5051', text: 'Kroatië' },
    { key: '5025', text: 'Laos' },
    { key: '7016', text: 'Lesotho' },
    { key: '7064', text: 'Letland' },
    { key: '7043', text: 'Libanon' },
    { key: '5019', text: 'Liberia' },
    { key: '6006', text: 'Libië' },
    { key: '6012', text: 'Liechtenstein' },
    { key: '7066', text: 'Litouwen' },
    { key: '6018', text: 'Luxemburg' },
    { key: '9010', text: 'Madagaskar' },
    { key: '7087', text: 'Madeira-eilanden' },
    { key: '5005', text: 'Malawi' },
    { key: '7041', text: 'Maldiven' },
    { key: '7026', text: 'Maleisië' },
    { key: '5029', text: 'Mali' },
    { key: '7003', text: 'Malta' },
    { key: '8035', text: 'Man' },
    { key: '8009', text: 'Marianen' },
    { key: '5022', text: 'Marokko' },
    { key: '9056', text: 'Marshalleilanden' },
    { key: '5069', text: 'Martinique' },
    { key: '6020', text: 'Mauritanië' },
    { key: '5044', text: 'Mauritius' },
    { key: '5084', text: 'Mayotte' },
    { key: '7006', text: 'Mexico' },
    { key: '9094', text: 'Micronesia' },
    { key: '8003', text: 'Midway' },
    { key: '6000', text: 'Moldavië' },
    { key: '5032', text: 'Monaco' },
    { key: '7052', text: 'Mongolië' },
    { key: '5104', text: 'Montenegro' },
    { key: '8015', text: 'Montserrat' },
    { key: '5070', text: 'Mozambique' },
    { key: '5047', text: 'Myanmar' },
    { key: '9023', text: 'Namibië' },
    { key: '7057', text: 'Nauru' },
    { key: '6030', text: 'Nederland' },
    { key: '6035', text: 'Nepal' },
    { key: '7018', text: 'Nicaragua' },
    { key: '7099', text: 'Nieuw-Caledonië' },
    { key: '5013', text: 'Nieuw-Zeeland' },
    { key: '6040', text: 'Niger' },
    { key: '6005', text: 'Nigeria' },
    { key: '9091', text: 'Niue' },
    { key: '6049', text: 'Noord-Korea' },
    { key: '6027', text: 'Noorwegen' },
    { key: '8016', text: 'Norfolk' },
    { key: '6038', text: 'Oekraïne' },
    { key: '6050', text: 'Oezbekistan' },
    { key: '7051', text: 'Oman' },
    { key: '0000', text: 'Onbekend' },
    { key: '5009', text: 'Oostenrijk' },
    { key: '7020', text: 'Pakistan' },
    { key: '8044', text: 'Palau' },
    { key: '7037', text: 'Panama' },
    { key: '8041', text: 'Panamakanaalzone' },
    { key: '8021', text: 'Papoea-Nieuw-Guinea' },
    { key: '5038', text: 'Paraguay' },
    { key: '7049', text: 'Peru' },
    { key: '5071', text: 'Pitcairneilanden' },
    { key: '7028', text: 'Polen' },
    { key: '7050', text: 'Portugal' },
    { key: '8020', text: 'Puerto Rico' },
    { key: '9037', text: 'Qatar' },
    { key: '5113', text: 'Republiek Noord-Macedonië' },
    { key: '5073', text: 'Réunion' },
    { key: '7047', text: 'Roemenië' },
    { key: '5053', text: 'Rusland' },
    { key: '6009', text: 'Rwanda' },
    { key: '5108', text: 'Saba' },
    { key: '8037', text: 'Saint Kitts en Nevis' },
    { key: '8029', text: 'Saint Lucia' },
    { key: '5074', text: 'Saint Pierre en Miquelon' },
    { key: '5092', text: 'Saint Vincent en de Grenadines' },
    { key: '8022', text: 'Salomonseilanden' },
    { key: '7053', text: 'Samoa' },
    { key: '6028', text: 'San Marino' },
    { key: '6059', text: 'São Tomé en Principe' },
    { key: '5018', text: 'Saoedi-Arabië' },
    { key: '7021', text: 'Senegal' },
    { key: '5103', text: 'Servië' },
    { key: '8026', text: 'Seychellen' },
    { key: '6051', text: 'Sierra Leone' },
    { key: '5037', text: 'Singapore' },
    { key: '5109', text: 'Sint Eustatius' },
    { key: '5110', text: 'Sint Maarten' },
    { key: '6060', text: 'Sint-Helena' },
    { key: '5049', text: 'Slovenië' },
    { key: '6067', text: 'Slowakije' },
    { key: '7034', text: 'Soedan' },
    { key: '6013', text: 'Somalië' },
    { key: '6037', text: 'Spanje' },
    { key: '7033', text: 'Sri Lanka' },
    { key: '5007', text: 'Suriname' },
    { key: '9095', text: 'Svalbardeilanden' },
    { key: '7009', text: 'Syrië' },
    { key: '6057', text: 'Tadzjikistan' },
    { key: '5052', text: 'Taiwan' },
    { key: '7031', text: 'Tanzania' },
    { key: '7042', text: 'Thailand' },
    { key: '7055', text: 'Tibet' },
    { key: '5101', text: 'Timor Leste' },
    { key: '5023', text: 'Togo' },
    { key: '7098', text: 'Tokelau' },
    { key: '5076', text: 'Tonga' },
    { key: '6044', text: 'Trinidad en Tobago' },
    { key: '6061', text: 'Tristan Da Cunha' },
    { key: '6019', text: 'Tsjaad' },
    { key: '6066', text: 'Tsjechië' },
    { key: '5008', text: 'Tunesië' },
    { key: '6043', text: 'Turkije' },
    { key: '6063', text: 'Turkmenistan' },
    { key: '8019', text: 'Turks- en Caicoseilanden' },
    { key: '8028', text: 'Tuvalu' },
    { key: '7001', text: 'Uganda' },
    { key: '7038', text: 'Uruguay' },
    { key: '9090', text: 'Vanuatu' },
    { key: '5045', text: 'Vaticaanstad' },
    { key: '6010', text: 'Venezuela' },
    { key: '7054', text: 'Verenigde Arabische Emiraten' },
    { key: '6014', text: 'Verenigde Staten van Amerika' },
    { key: '8024', text: 'Vietnam' },
    { key: '8005', text: 'Wake' },
    { key: '5077', text: 'Wallis en Futuna' },
    { key: '9093', text: 'Westelijke Sahara' },
    { key: '5028', text: 'Zambia' },
    { key: '8031', text: 'Zimbabwe' },
    { key: '5014', text: 'Zuid-Afrika' },
    { key: '6036', text: 'Zuid-Korea' },
    { key: '5111', text: 'Zuid-Soedan' },
    { key: '5039', text: 'Zweden' },
    { key: '5003', text: 'Zwitserland' },
];
