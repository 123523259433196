import ListContracten from "features/beheer/onderhoudscontract/listContracten"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchGebouwContracten, selectAll,  } from "./gebouwContractSlice"

export interface IGebouwOnderhoudsContractenProps{
    gebouwId: number
}

const GebouwOnderhoudsContracten : React.FC<IGebouwOnderhoudsContractenProps> = (props: IGebouwOnderhoudsContractenProps) => {
    const dispatch = useDispatch()
    const contracten = useSelector(selectAll)

    useEffect(() => {
        dispatch(fetchGebouwContracten(props.gebouwId))    

    }, [props.gebouwId])
    return <ListContracten contracten={contracten} />
}

export default GebouwOnderhoudsContracten
