import React, { useMemo } from 'react'
import { Dialog, DialogFooter, IDialogContentProps, IModalProps, DialogType, PrimaryButton } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'

interface IDeleteConfirmationDialogProps {
  onCancel: () => void
  saving?: boolean
  hideDialog: boolean
}

const DeleteWarningDialog: React.FC<IDeleteConfirmationDialogProps> = ({
  onCancel,
  hideDialog
}) => {
  const labelId: string = useId('dialogLabel')
  const subTextId: string = useId('subTextLabel')

  const modalProps = useMemo(
    (): IModalProps => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
    }),
    [labelId, subTextId]
  )

  const dialogContentProps = useMemo(
    (): IDialogContentProps => ({
      type: DialogType.normal,
      closeButtonAriaLabel: 'Sluiten',
      title: 'Waarschuwing',
      subText: `Gebouwencluster kan niet worden verwijderd, want er zijn één of meer gebouwen die hiernaar verwijzen.`,
    }),
    []
  )

  return (
    <div className="deleteConfirmationDialog">
      <Dialog hidden={hideDialog} onDismiss={onCancel} dialogContentProps={dialogContentProps} modalProps={modalProps}>
        <DialogFooter>
          <PrimaryButton onClick={onCancel} text="Annuleren" />
        </DialogFooter>
      </Dialog>
    </div>
  )
}

export default DeleteWarningDialog
