import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { IDropdownOption } from '@fluentui/react'
import VgtTextField from 'components/VgtTextField'
import { getPropertyName } from 'lib/interfaceUtils'
import VgtDropdown from 'components/FluentDropdown'
import useApi from 'services/UseApi'
import React, { useEffect } from 'react'
import { DimensieSchema, IDimensieUpdateOrAdd } from './DimensieApi'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

export const DimensieForm = ({ data, onSubmit, onCancel, isExecuting }: { data?: IDimensieUpdateOrAdd, isExecuting?: boolean, onSubmit: (values: IDimensieUpdateOrAdd) => void, onCancel: () => void }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<IDimensieUpdateOrAdd>({
    resolver: yupResolver(DimensieSchema), mode: 'all', defaultValues: {
      code: '',
      omschrijving: '',
      vgtDimensieId: 1
    }
  })

  const { state: vgtDimensies } = useApi<IDropdownOption[]>('dimensies/VgtEntiteittypes', 'GET')

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VgtTextField label='Code' name={getPropertyName<IDimensieUpdateOrAdd>('code')} required
                    control={control} errors={errors} />
      <VgtTextField label='Omschrijving' name={getPropertyName<IDimensieUpdateOrAdd>('omschrijving')} required
                    control={control} errors={errors} />
      <VgtDropdown label='VGT dimensie' name={getPropertyName<IDimensieUpdateOrAdd>('vgtDimensieId')}
                   control={control} errors={errors} required options={vgtDimensies?.data}
                   />
      <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isExecuting ?? false} onOkClick={() => handleSubmit(onSubmit)} onCancelClick={() => onCancel()} />
    </form>
  )
}