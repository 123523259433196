import FluentTable from 'components/FluentTable'
import { createColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import { Link, Spinner, SpinnerSize } from '@fluentui/react'
import ReadOnlyField, { ReadOnlyDate } from '../../components/ReadOnlyField'
import { z } from 'zod'
import { VheListDto, zodiosHooks } from 'api/ApiClient'
import { useNavigate, useParams } from 'react-router-dom'
import { PageIdDefinition, PAGES } from 'pages'

type VheListDtoType = z.infer<typeof VheListDto>

const VheList = ({ vhes, title }) => {
  const navigate = useNavigate()
  const inputLinkRender = (item: VheListDtoType) => {
    if (item?.id) {
      return (
        <Link key={item?.id} onClick={() =>
          navigate(PAGES[PageIdDefinition.beheer.vhe.edit].route.replace(':id', item.id.toString()))}>{item?.code}
        </Link>
      )
    }
    return (
      <p>{item?.code}</p>
    )
  }
  const cols = [
    createColumn('Code', getPropertyName<VheListDtoType>('code'), 'M', true, inputLinkRender),
    createColumn('Eenheiddetailsoort', getPropertyName<VheListDtoType>('detailsoort')),
    createColumn('Activatiedatum', getPropertyName<VheListDtoType>('activatieDatum')),
    createColumn('In exploitatie', getPropertyName<VheListDtoType>('inExploitatieDatum')),
    createColumn('Uit exploitatie', getPropertyName<VheListDtoType>('uitExploitatieDatum')),
    createColumn('Adres', getPropertyName<VheListDtoType>('adres')),
    createColumn('Adresaanduiding', getPropertyName<VheListDtoType>('adresaanduiding'))
  ]

  return (
    <div style={{ marginRight: 15 }}>
      <h3>{title}</h3>
      <div style={{ display: 'flex', height: '70vh' }}>
        <FluentTable columns={cols} items={vhes ?? []} onItemInvoked={r => alert(r)} onGetKey={r => r.id} />
      </div>
    </div>)
}

const VerhuureenheidTab = ({ verhuurbaar }) => {
  const { id } = useParams()
  // validate router param
  const idAsNumber = parseInt(id ?? '')
  if (isNaN(idAsNumber)) {
    throw new Error('Invalid id in url')
  }
  const { data: vgeVhesData, isLoading } = zodiosHooks.useGetVgeIdvhes({
    params: {
      id: idAsNumber
    }
  })

  if (!isLoading) {
    return (
      <>
        <VheList vhes={vgeVhesData} title={'Verhuureenheden - ' + verhuurbaar ?? ''} />
      </>
    )
  } else {
    return (
      <Spinner size={SpinnerSize.large} />
    )
  }
}

export const VheView = ({ data }: {
  data?: VheListDtoType,
}) => {
  const navigate = useNavigate()
  const inputLinkRender = () => {
    if (data?.id) {
      return (
        <Link key={data?.id} onClick={() =>
          navigate(PAGES[PageIdDefinition.beheer.vhe.edit].route.replace(':id', data.id.toString()))}>{data?.code}
        </Link>
      )
    }
    return (
      <p>{data?.code}</p>
    )
  }

  return (<>
      <ReadOnlyField title='VHE-Code' value={data?.code} labelWidth={174} onInputRender={
        inputLinkRender
      } />
      <ReadOnlyField title='Eenheiddetailsoort' value={data?.detailsoort} />
      <ReadOnlyDate title='Activatiedatum' value={data?.activatieDatum!} />
      <ReadOnlyDate title='In exploitatiedatum' value={data?.inExploitatieDatum!} />
      <ReadOnlyDate title='Uit exploitatiedatum' value={data?.uitExploitatieDatum!} />
      <ReadOnlyField title={'Adres'} value={data?.adres} />
      <ReadOnlyField title='Adresaanduiding' value={data?.adresaanduiding} />
    </>
  )
}
export default VerhuureenheidTab