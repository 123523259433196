import React, { useState } from 'react'
import { PanelType } from '@fluentui/react'
import FluentPanel from '../../../../components/FluentPanel/FluentPanel'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TenantRuimteSoortAddOrUpdateDto, zodiosHooks } from '../../../../api/ApiClient'
import { z } from 'zod'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import VgtDropdown from '../../../../components/FluentDropdown'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'
import useRoles from '../../../../services/UseRoles'
import ReadOnlyField from '../../../../components/ReadOnlyField'
import { getTitleAndMessage } from '../../../../services/HandleError'

export interface IEditPanelProps {
  tenantRuimteSoortId: number,
  defectLocatieNaam: string,
  defectLocatieId: number,
  ruimteSoortErpId: number,
  isOpen: boolean
  dismissPanel: () => void
  invalidate: () => void
}


export type CreateUpdateType = z.infer<typeof TenantRuimteSoortAddOrUpdateDto>;

export const TenantRuimteSoortErpFormPanel: React.FC<IEditPanelProps> = props => {
  const [title, setTitle] = useState('')
  const [error, setError] = React.useState<string>()
  const { isReparatieboom } = useRoles()

  const defaultRuimteSoortErp = {
    ruimtesoortErpId: props.ruimteSoortErpId,
    defectLocatieId: props.defectLocatieId
  } as CreateUpdateType

  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(TenantRuimteSoortAddOrUpdateDto), mode: 'all', defaultValues: defaultRuimteSoortErp
  })

  const { mutate: addTenantRuimteSoortErp } = zodiosHooks.usePostTenantRuimteSoort(
    {}, { onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const {
    mutate: updateTenantRuimteSoortErp
  } = zodiosHooks.usePutTenantRuimteSoortId(
    { params: { id: props.tenantRuimteSoortId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const onPanelDismiss = () => {
    props.invalidate()
    reset({})
    setTitle('Defectlocatie || Ruimtesoort')
    setError(undefined)
    props.dismissPanel()
  }

  const onCancel = () => {
    props.invalidate()
    reset({})
    setError('')
    props.dismissPanel()
  }


  const onSubmit = (data: CreateUpdateType) => {
    if (!data.defectLocatieId) {
      data.defectLocatieId = props.defectLocatieId; // Set default value
    }

    if (props.tenantRuimteSoortId === 0) {
      addTenantRuimteSoortErp(data)
    } else if (props.tenantRuimteSoortId !== 0) {
      updateTenantRuimteSoortErp(data)
    }

    console.log(data)

  }

  const { data: tenantRuimteSoortruimteSoortOptions } = zodiosHooks.useGetTenantRuimteSoortruimteSoortOptions({ queries: { includeEmpty: false }})

  return (
    <FluentPanel isOpen={props.isOpen} headerText={title} onDismiss={onPanelDismiss} error={error}
                 type={PanelType.extraLarge}>

        <ReadOnlyField title='Defectlocatie' value={props.defectLocatieNaam} labelWidth={250} />
        <VgtDropdown
          label="Ruimtesoort ERP"
          name={getPropertyName<CreateUpdateType>('ruimtesoortErpId')}
          register={register}
          control={control}
          errors={errors}
          required
          readOnly={!isReparatieboom}
          options={tenantRuimteSoortruimteSoortOptions}
          defaultSelectedKey={defaultRuimteSoortErp.ruimtesoortErpId ?? 0}
        />
        <OkCancelButtonStack
          isSubmitting={isSubmitting}
          isLoading={isSubmitting}
          onOkClick={handleSubmit(onSubmit)}
          onCancelClick={onCancel}
          isWriter={isReparatieboom}
        />
    </FluentPanel>
  )
}
