import { IDropdownOption } from '@fluentui/react';
import { createAsyncThunk, createEntityAdapter, createSlice, EntityId, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState';
import { PagedEntities } from 'interfaces/pagedEntities';
import { invokeFetch } from 'services/apiClient';
import { RootState } from 'store';
import * as yup from 'yup'

export const DienstenComponentSchema = yup.object().shape({
    code: yup.string().required('Code is verplicht').max(20, 'Maximale lengte is 20'),
    omschrijving: yup.string().required('Omschrijving is verplicht').max(50, 'Maximale lengte is 50')
})

export interface IDienstenComponent {
    dienstencomponentId: number
    code: string
    omschrijving: string
    kostensoortId: number
}

const entityAdapter = createEntityAdapter<IDienstenComponent>({
    selectId: e => e.dienstencomponentId,
    sortComparer: (a, b) => a.code.localeCompare(b.code)
})

const baseUrl = '/dienstenComponent'
const basePrefix = 'dienstenComponent'
const getSliceState = (state: RootState) => state.dienstenComponent

export interface IDienstenComponentArgs {
    filter?: string
}

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (args: IDienstenComponentArgs, thunkAPI) => {
    return await invokeFetch<PagedEntities<IDienstenComponent>>(thunkAPI, 'GET', `${baseUrl}?Filter=${args.filter}`)
  })
  
  export const updateDienstenComponent = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: IDienstenComponent, thunkAPI) => {
    return await invokeFetch<IDienstenComponent>(thunkAPI, 'PUT', `${baseUrl}/${entity.dienstencomponentId}`, entity)
  })
  
  export const addDienstenComponent = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: IDienstenComponent, thunkAPI) => {
    return await invokeFetch<IDienstenComponent>(thunkAPI, 'POST', baseUrl, entity)
  })
  
  export const deleteDienstenComponent = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
  })
  
  const setPendingState = (state: EntityState<IDienstenComponent> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<IDienstenComponent> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<IDienstenComponent> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }
  
  export const dienstenComponentSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),
  
    reducers: {
      clearError: state => {
        state.error = null
      },
      select: (state, action: PayloadAction<string | undefined>) => {
        state.selectedId = action.payload
      },
      clearSelection: state => {
        state.selectedId = undefined
      },
      add: entityAdapter.addOne,
      modify: entityAdapter.upsertOne,
      removeMany: entityAdapter.removeMany,
      setAll: entityAdapter.setAll,
    },
  
    extraReducers: builder => {
      builder.addCase(fetchAll.pending, state => setPendingState(state))
      builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IDienstenComponent>>) => {
        setSucceededState(state)
        entityAdapter.setAll(state, action.payload.items)
      })
      builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(updateDienstenComponent.pending, state => setPendingState(state))
      builder.addCase(updateDienstenComponent.fulfilled, (state, action: PayloadAction<IDienstenComponent>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(updateDienstenComponent.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(addDienstenComponent.pending, state => setPendingState(state))
      builder.addCase(addDienstenComponent.fulfilled, (state, action: PayloadAction<IDienstenComponent>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(addDienstenComponent.rejected, (state, action) => {
        setRejectedState(state, action)
      })
      builder.addCase(deleteDienstenComponent.pending, state => setPendingState(state))
      builder.addCase(deleteDienstenComponent.fulfilled, (state, action) => {
        entityAdapter.removeMany(state, action.meta.arg)
        setSucceededState(state)
      })
      builder.addCase(deleteDienstenComponent.rejected, (state, action) => setRejectedState(state, action))
    },
  })
  
  export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
  }

  export const getLoadingState = (state: RootState) => state.dienstenComponent.status
  
  export const getErrorState = (state: RootState) => state.dienstenComponent.error
  
  export const { add, modify, removeMany, select, clearSelection } = dienstenComponentSlice.actions
  export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.dienstenComponent)

  export const getDienstenComponentenAsOptions = (state: RootState) => state.dienstenComponent.ids.map((id: EntityId) => {
    const entity = state.dienstenComponent.entities[id]
    return {
        key: id,
        text: `${entity?.code} - ${entity?.omschrijving}`
    } as IDropdownOption
})
  
  export default dienstenComponentSlice.reducer
  