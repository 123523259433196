import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { EntityObjectState, createInitialState, IEntityPageObjectResponse } from 'lib/sliceSupport'
import IDefectLocatieElement from './model'

const initState: EntityObjectState<IDefectLocatieElement> = createInitialState<IDefectLocatieElement>()

const entityAdapter = createEntityAdapter<IDefectLocatieElement>({selectId: (entity) => entity.defectlocatieId.toString() + entity.elementId.toString()})

export const fetchAll = createAsyncThunk('/defectLocatieElementCategorie/fetchAll', async (_, thunkAPI) => {
    return await invokeFetch<IEntityPageObjectResponse<IDefectLocatieElement>>(thunkAPI, 'GET', '/defectLocatieElementCategorie/pageLocations?skip=0&top=50000')
})

export const defectLocatieElementCategorieslice = createSlice({
    name: 'defectLocatieElementCategories',
    initialState: entityAdapter.getInitialState(initState),

    reducers: {
        select: (state, action: PayloadAction<number | undefined>) => {
            state.selectedId = action.payload
        },
        clearSelection: state => {
            state.selectedId = undefined
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
    },

    extraReducers: builder => {
        builder.addCase(fetchAll.pending, state => {
            state.status = 'pending'
        })
        builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<IEntityPageObjectResponse<IDefectLocatieElement>>) => {
            state.status = 'succeeded'
            const items = action.payload.items
            entityAdapter.setAll(state, items)
        })
        builder.addCase(fetchAll.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message || null
        })
    },
})

export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.defectLocatieElementCategories)

export default defectLocatieElementCategorieslice.reducer
