import { IDropdownOption } from '@fluentui/react'
import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'

import { IGebouwElementHoeveelheid } from '../gebouwen/gebouwenSlice'

interface PagedEntities<TItem> {
  pageIndex: number
  totalPages: number
  totalCount: number
  hasPreviousPage: boolean
  hasNextPage: boolean
  items: TItem[]
}

export interface IGebouwenCluster {
  id: number
  code: string
  naam: string
  omschrijving: string
  advertentietekst: string
  hoeveelheden: IGebouwElementHoeveelheid[]
}

interface IGebouwenClusterState {
  selectedId: string | undefined
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
  searchFilter: string | undefined
}

const initState: IGebouwenClusterState = {
  selectedId: undefined,
  status: 'idle',
  error: null,
  searchFilter: undefined
}

const entityAdapter = createEntityAdapter<IGebouwenCluster>({
  selectId: e => e.id,
  sortComparer: (a, b) => a.code?.localeCompare(b.code)
})

export const fetchGebouwenClusters = createAsyncThunk('gebouwenClusters/fetchAllStatus', async ({ filter }: { filter: string }, thunkAPI) => {
  return await invokeFetch<PagedEntities<IGebouwenCluster>>(thunkAPI, 'GET', `/gebouwenClusters?top=50000&Filter=${filter}`)
})

export const gebouwenClusters = createSlice({
  name: 'gebouwenClusters',
  initialState: entityAdapter.getInitialState(initState),

  reducers: {
    select: (state, action: PayloadAction<string | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.searchFilter = action.payload.toUpperCase()
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll
  },

  extraReducers: builder => {
    builder.addCase(fetchGebouwenClusters.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(fetchGebouwenClusters.fulfilled, (state, action: PayloadAction<PagedEntities<IGebouwenCluster>>) => {
      state.status = 'succeeded'
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchGebouwenClusters.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message ?? null
    })
  }
})

export const getSelectedEntity = (state: RootState) => {
  if (state.gebouwenClusters.selectedId) return state.gebouwenClusters.entities[state.gebouwenClusters.selectedId]
  else return undefined
}

export const {
  selectAll,
  selectEntities,
  selectById
} = entityAdapter.getSelectors<RootState>(state => state.gebouwenClusters)


export const getGebouwenClustersAsOptions = (state: RootState) => {
  const options: IDropdownOption[] = []
  state.gebouwenClusters.ids.forEach(id => {
    options.push({
      key: id,
      text: state.gebouwenClusters.entities[id]?.code + ' - ' + state.gebouwenClusters.entities[id]?.naam
    } as IDropdownOption)
  })
  return options
}

export const getSelectedGebouwClusterHoeveelheid = (state: RootState, id: number | undefined) => {
  if (id) {
    const cluster = getSelectedEntity(state)
    if (cluster) {
      return cluster.hoeveelheden.find(i => i.id === id)
    }
  }
  return undefined
}

export default gebouwenClusters.reducer
