import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import Main from './Main'
import NotFound from './containers/pages/NotFound'
import Home from './containers/pages/Home'
import Login from './containers/Login'
import { NotAuthorized } from 'ProtectedRoute'
import React from 'react'
import { Route as RouteInterface } from './routing/routing'
import { availableUrls} from './routing/AvailableUrls'
import { useSelector } from 'react-redux'
import { selectAll } from './store/actions/app/rolesSlice'
import { SystemSettingsView } from './features/System/SystemSettingsView'

export const AvailableRoutes = {
  Dimensies_List: '/beheer/dimensies',
  Dimensies_Edit: '/beheer/dimensies/:id',
  Dimensies_New: '/beheer/dimensies/new',
  Vhes_List: '/beheer/vhes',
  Vhes_Edit: '/beheer/vhes/:id',
  Vhes_New: '/beheer/vhes/new',
  Rechtspersonen_List: '/beheer/relatie/rechtspersonen',
  Rechtspersonen_Edit: '/beheer/relatie/rechtspersonen/:id',
  Rechtspersonen_New: '/beheer/relatie/rechtspersonen/new',
  Dimensiewaarden_List: '/beheer/dimensies/:dimensieId/waarden',
  Dimensiewaarden_Edit: '/beheer/dimensies/:dimensieId/waarden/:id',
  Dimensiewaarden_New: '/beheer/dimensies/:dimensieId/waarden/new',
  ContactCategorie_List: '/beheer/contactCategorien',
  Contactgegevens_List: '/beheer/contactgegevens',
  BegrippenLijst_Update: '/BegrippenLijst/:id',
  Verwarmingen_List: '/beheer/verwarmingen',
  ElementDetailList: '/beheer/elementDetails'
}

const AppRouter   = ({ routes}) => {
  const userRoles = useSelector(selectAll)

  const checkIfUserHasAccess = (neededRoles: string[]): boolean => {
    return neededRoles.some((role) => userRoles.filter((s) => s.id === role).length > 0)
  };

  return (
    <Router>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <Main>
          <Routes>
            <Route path="/" element={checkIfUserHasAccess(["Data.Reader"]) ? <Home /> : <NotAuthorized />} />
            {routes.map((routeRouteGroup) => (
              routeRouteGroup.TopLevelGroup ? (
                routeRouteGroup.TopLevelGroup.map((routeGroup) => (
                  routeGroup.RouteGroups.map((route: RouteInterface) => (
                    <Route
                      key={`route-${route.path}`}
                      path={availableUrls[route.path]}
                      element={ checkIfUserHasAccess(route.allowedRoles) ?  route.component : <NotAuthorized />}
                    />
                  ))
                ))
              ) : (
                <Route
                  key={`route-${routeRouteGroup.path}`}
                  path={routeRouteGroup.path}
                  element={checkIfUserHasAccess(routeRouteGroup.allowedRoles) ? routeRouteGroup.component : <NotAuthorized />}
                />
              )
            ))}
            <Route path="/systeem/instellingen" element={<SystemSettingsView />}/>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Main>
      </AuthenticatedTemplate>
    </Router>
  );
};

export default AppRouter;
