import React, { useEffect, useState } from 'react'
import { IDropdownOption, Panel, PanelType } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { getPropertyName } from 'lib/interfaceUtils'
import { selectAll } from '../woningverbeteringToestemming/woningverbeteringToestemmingSlice'
import VgtTextField from 'components/VgtTextField'
import VgtDropdown from 'components/FluentDropdown'
import VgtCheckbox from 'components/FluentCheckbox'
import VgtNumberField from 'components/FluentNumberField'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import {
  CreateTenantWoningverbeteringCommand,
  TenantWoningverbeteringDto,
  zodiosHooks
} from '../../../../api/ApiClient'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

type TenantWoningverbeteringDtoType = z.infer<typeof TenantWoningverbeteringDto>;
type CreateTenantWoningverbeteringCommandType = z.infer<typeof CreateTenantWoningverbeteringCommand>;

interface IEditPanelProps {
  isOpen: boolean;
  dismissPanel: any;
  fetchTenantWoningverbetering: () => void;
  selectedTenantWoningverbeteringItems: TenantWoningverbeteringDtoType | undefined
}

export const TenantWoningverbeteringEditPanel: React.FC<IEditPanelProps> = ({
                                                                              selectedTenantWoningverbeteringItems,
                                                                              dismissPanel,
                                                                              isOpen,
                                                                              fetchTenantWoningverbetering
                                                                            }) => {
  const [error, setError] = useState<string>()
  const [title, setTitle] = useState('')
  const woningverbeteringToestemmingenOptions = useSelector(selectAll)

  const onDataSubmitttedSuccesfully = () => {
    fetchTenantWoningverbetering()
    dismissPanel()
  }

  const { data: verbeteringTypes } = zodiosHooks.useGetWoningverbeteringwoningverbeteringTypes()

  const { data: woningverbetering } = zodiosHooks.useGetWoningverbetering()

  const {
    mutate: postTenantWoningverbetering,
    isLoading: isPostingTenantWoningverbetering
  } = zodiosHooks.usePostTenantWoningverbetering({}, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const {
    mutate: putTenantWoningverbeteringId,
    isLoading: isUpdatingTenantWoningverbetering
  } = zodiosHooks.usePutTenantWoningverbeteringId(
    { params: { id: selectedTenantWoningverbeteringItems?.woningverbeteringId! } }, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: deleteTenantWoningverbetering } = zodiosHooks.useDeleteTenantWoningverbetering({}, {
    onSuccess: () => fetchTenantWoningverbetering(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const woningVerbeteringTypeDropdownOptions = !verbeteringTypes ? [] : verbeteringTypes.map(x => {
    return {
      key: x.woningVerbeteringTypeId,
      text: x.naam
    } as IDropdownOption
  })

  const woningverbeteringDropdownOptions = !woningverbetering ? [] : woningverbetering?.items?.map(x => {
    return {
      key: x.woningverbeteringId,
      text: x.omschrijving
    } as IDropdownOption
  })

  const defaultTenantWoningverbetering = {
    woningverbeteringId: selectedTenantWoningverbeteringItems?.woningverbeteringId ?? 0,
    voorziening: selectedTenantWoningverbeteringItems?.voorziening ?? null,
    tenantWoningverbeteringId: selectedTenantWoningverbeteringItems?.tenantWoningverbeteringId ?? null,
    woningverbeteringOmschrijving: selectedTenantWoningverbeteringItems?.woningverbeteringOmschrijving ?? null,
    woningverbeteringToestemmingId: selectedTenantWoningverbeteringItems?.woningverbeteringToestemmingId ?? null,
    woningverbeteringToestemmingCode: selectedTenantWoningverbeteringItems?.woningverbeteringToestemmingCode ?? null,
    bedragHuurprijsaanpassing: selectedTenantWoningverbeteringItems?.bedragHuurprijsaanpassing ?? null,
    toelichting: selectedTenantWoningverbeteringItems?.toelichting ?? null,
    toelichtingIntern: selectedTenantWoningverbeteringItems?.toelichtingIntern ?? null,
    actiefVoorKlantdialoog: selectedTenantWoningverbeteringItems?.actiefVoorKlantdialoog ?? null,
    actiefVoorOvername: selectedTenantWoningverbeteringItems?.actiefVoorOvername ?? null,
    woningverbeteringType: selectedTenantWoningverbeteringItems?.woningverbeteringType ?? null,
    geriefsverbeteringMogelijk: selectedTenantWoningverbeteringItems?.geriefsverbeteringMogelijk ?? null,
    vergoedingMogelijk: selectedTenantWoningverbeteringItems?.vergoedingMogelijk ?? null
  }

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: {
      isSubmitting,
      errors
    }
  } = useForm<CreateTenantWoningverbeteringCommandType>(
    { mode: 'all' })

  const watchActiefVoorKlantdialoog = watch(getPropertyName<CreateTenantWoningverbeteringCommandType>('actiefVoorKlantdialoog'), false)

  useEffect(() => {
    setTitle(`Woningverbetering wijzigen`)
    if (selectedTenantWoningverbeteringItems) {
      reset(selectedTenantWoningverbeteringItems)
    } else {
      reset({})
    }
  }, [selectedTenantWoningverbeteringItems])

  const getWoningverbeteringToestemmingenOptionsOptions = () => {
    const nullOption = { key: 0, text: '---' } as IDropdownOption
    const options = woningverbeteringToestemmingenOptions.map(x => ({
      key: x.woningverbeteringToestemmingId,
      text: `${x.code}`
    })) as IDropdownOption[]
    return [nullOption, ...options]
  }

  const shouldDeleteWoningverbetering = (woningverbetering: CreateTenantWoningverbeteringCommandType) => {
    const woningverbeteringClone = JSON.parse(JSON.stringify(woningverbetering))
    delete woningverbeteringClone.woningverbeteringId
    const valuesOfProperties = Object.values(woningverbeteringClone)
    return valuesOfProperties.every(x => x === undefined || x === '0' || x === '' || x === false || x === '0')
  }

  const onCancel = () => {
    setError(undefined)
    dismissPanel()
  }

  const onSubmit = (data: CreateTenantWoningverbeteringCommandType) => {
    const shouldDelete: boolean = shouldDeleteWoningverbetering(data)
    if (selectedTenantWoningverbeteringItems) {

      if (!selectedTenantWoningverbeteringItems.tenantWoningverbeteringId && data.woningverbeteringId !== undefined) {
        postTenantWoningverbetering({ ...defaultTenantWoningverbetering, ...data })
      } else if (shouldDelete) {
        deleteTenantWoningverbetering([selectedTenantWoningverbeteringItems?.tenantWoningverbeteringId!])
      } else {
        data.woningverbeteringId = selectedTenantWoningverbeteringItems.woningverbeteringId
        putTenantWoningverbeteringId({ ...defaultTenantWoningverbetering, ...data })
      }
    }
  }

  return (
    <div>
      <Panel type={PanelType.large} headerText={title} isOpen={isOpen} onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <ErrorMessageBar error={error} />
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ErrorMessageBar error={error} />
          <VgtDropdown
            options={woningverbeteringDropdownOptions?.filter(x => x.key === selectedTenantWoningverbeteringItems?.woningverbeteringId)}
            label='Woningverbetering'
            name={getPropertyName<CreateTenantWoningverbeteringCommandType>('woningverbeteringId')}
            register={register}
            control={control}
            errors={errors}
            readOnly={true}
          />
          <VgtTextField
            label='Voorziening'
            name={getPropertyName<CreateTenantWoningverbeteringCommandType>('voorziening')}
            control={control}
            errors={errors}
            multiline={true}
            readOnly={true}
          />
          <VgtCheckbox
            label='Actief voor klantdialoog'
            name={getPropertyName<CreateTenantWoningverbeteringCommandType>('actiefVoorKlantdialoog')}
            register={register}
            control={control}
            errors={errors}
          />
          <VgtCheckbox
            label='Actief voor overname'
            name={getPropertyName<CreateTenantWoningverbeteringCommandType>('actiefVoorOvername')}
            register={register}
            control={control}
            errors={errors}
          />
          <VgtDropdown
            label='Type woningverbetering'
            options={woningVerbeteringTypeDropdownOptions}
            name={getPropertyName<CreateTenantWoningverbeteringCommandType>('woningverbeteringType')}
            control={control}
            errors={errors}
          />
          <VgtDropdown
            options={getWoningverbeteringToestemmingenOptionsOptions()}
            label='Toestemmingscode'
            name={getPropertyName<CreateTenantWoningverbeteringCommandType>('woningverbeteringToestemmingId')}

            control={control}
            errors={errors}
            disabled={watchActiefVoorKlantdialoog !== true}
          />
          <VgtTextField
            label='Toelichting intern'
            name={getPropertyName<CreateTenantWoningverbeteringCommandType>('toelichtingIntern')}
            register={register}
            control={control}
            errors={errors}
            multiline={true}
            disabled={watchActiefVoorKlantdialoog !== true}
          />
          <VgtTextField
            label='Toelichting extern'
            name={getPropertyName<CreateTenantWoningverbeteringCommandType>('toelichting')}
            register={register}
            control={control}
            errors={errors}
            multiline={true}
            disabled={watchActiefVoorKlantdialoog !== true}
          />
          <VgtNumberField
            label='Bedrag huuraanpassing'
            name={getPropertyName<CreateTenantWoningverbeteringCommandType>('bedragHuurprijsaanpassing')}
            control={control}
            errors={errors}
            disabled={watchActiefVoorKlantdialoog !== true}
          />
          <VgtCheckbox
            label='Vergoeding mogelijk'
            name={getPropertyName<CreateTenantWoningverbeteringCommandType>('vergoedingMogelijk')}
            register={register}
            control={control}
            errors={errors}
          />
          <VgtCheckbox
            label='Geriefsverbetering mogelijk'
            name={getPropertyName<CreateTenantWoningverbeteringCommandType>('geriefsverbeteringMogelijk')}
            register={register}
            control={control}
            errors={errors}
            disabled={watchActiefVoorKlantdialoog !== true}
          />
          <OkCancelButtonStack
            isSubmitting={isSubmitting}
            isLoading={isPostingTenantWoningverbetering || isUpdatingTenantWoningverbetering}
            onOkClick={handleSubmit(onSubmit)}
            onCancelClick={onCancel}
          />
        </form>
      </Panel>
    </div>
  )
}
export default TenantWoningverbeteringEditPanel
