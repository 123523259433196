import React, { useMemo, useState } from 'react'
import { IColumn, Link, SelectionMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import FluentTable from 'components/FluentTable'
import { getPropertyName } from 'lib/interfaceUtils'
import EditPanelNLSFBElementen from './editPanel'
import { selectAll, select, clearSelection, fetchAll, deleteNlsfbElement, getLoadingState, IBouwkundigElement } from './elementSlice'
import downloadFile from 'services/downloadFile'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from "services/UseRoles";

const NLSFBElementenList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const items = useSelector(selectAll)
  const loadingState = useSelector(getLoadingState)
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const handleListSelectionChanged = (ids: string[]) => setSelectedItems(ids)
  const refreshItems = () => dispatch(fetchAll())
  const onGetKey = (item: IBouwkundigElement) => item.id.toString()
  const { isAdmin } = useRoles()

  const handleItemInvoked = (id: string) => {
    dispatch(select(id))
    openPanel()
  }

  const removeItems = () => {
    dispatch(deleteNlsfbElement(selectedItems.map(value => parseInt(value))))
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<IBouwkundigElement>('tekstCodeNotatie'),
      name: 'Class-tekstcodenotatie',
      fieldName: getPropertyName<IBouwkundigElement>('tekstCodeNotatie'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: IBouwkundigElement) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.tekstCodeNotatie}
        </Link>
      ),
    },
    {
      key: getPropertyName<IBouwkundigElement>('codeNotatie'),
      name: 'Class-codenotatie',
      fieldName: getPropertyName<IBouwkundigElement>('codeNotatie'),
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    },
    {
      key: getPropertyName<IBouwkundigElement>('niveau'),
      name: 'Class-niveau',
      fieldName: getPropertyName<IBouwkundigElement>('niveau'),
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      data: 'number'
    },
    {
      key: getPropertyName<IBouwkundigElement>('bovenliggendNiveau'),
      name: 'Class-bovenliggend-niveau',
      fieldName: getPropertyName<IBouwkundigElement>('bovenliggendNiveau'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<IBouwkundigElement>('code'),
      name: 'NL-SfB Elementcode',
      fieldName: getPropertyName<IBouwkundigElement>('code'),
      minWidth: 90,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<IBouwkundigElement>('omschrijving'),
      name: 'Omschrijving',
      fieldName: getPropertyName<IBouwkundigElement>('omschrijving'),
      minWidth: 230,
      maxWidth: 500,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<IBouwkundigElement>('classificatie'),
      name: 'Classificatie',
      fieldName: getPropertyName<IBouwkundigElement>('classificatie'),
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    },
    {
      key: getPropertyName<IBouwkundigElement>('ingangsDatum'),
      name: 'Ingangsdatum',
      fieldName: getPropertyName<IBouwkundigElement>('ingangsDatum'),
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      data: Date,
      onRender: (item: IBouwkundigElement) => (
        <span>{item.ingangsDatum.toString().slice(0,10)}</span>
      )
    },
    {
      key: getPropertyName<IBouwkundigElement>('eindDatum'),
      name: 'Einddatum',
      fieldName: getPropertyName<IBouwkundigElement>('eindDatum'),
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    }
  ]

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    { visible: isAdmin, text: 'Toevoegen', onClick: () => { dispatch(clearSelection()); openPanel() }, icon: 'Add' },
    { visible: isAdmin, text: 'Verwijderen', onClick: () => removeItems(), icon: 'Delete', disabled: !selectedItems.length },
    { visible: isAdmin, text: 'Exporteer naar Excel', onClick: () => downloadFile('/nlsfb/export', 'NL SfB - NEN2767.xlsx'), icon: 'Export' },
    { visible: isAdmin, text: 'Importeer van Excel', onClick: () => {}, icon: 'Import' }
  ], [isAdmin, selectedItems.length])

  return (
    <MainTemplate title='NL-SfB elementen' subTitle='Overzicht' commandItems={commandItems}>
      <EditPanelNLSFBElementen dismissPanel={dismissPanel} isOpen={isPanelOpen} />
      <div style={{ height : '90vh'}}>
        <FluentTable
          columns={columns}
          items={items}
          loading={loadingState === 'pending'}
          selectionMode={SelectionMode.multiple}
          onSelectionChanged={handleListSelectionChanged}
          onGetKey={onGetKey}
          onItemInvoked={handleItemInvoked}
        />
      </div>
    </MainTemplate>
  )
}
export default NLSFBElementenList