import { IColumn, SelectionMode, Link } from "@fluentui/react"
import { getPropertyName } from "lib/interfaceUtils"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "store"
import { createCustomRenderedColumn } from "lib/gridHelper"
import { clearSelection, deleteGarantie, fetchAll, getLoadingState, IGarantie, select, selectAll } from "./garantieSlice"
import { fetchAll as fetchAllKostensoort, selectAll as selectAllKostensoorten } from "../kostensoort/kostensoortSlice"
import MainTemplate from "containers/pages/PageTemplates/MainTemplate"
import FluentTable from "components/FluentTable"
import { GarantieEditPanel } from "./editPanel"
import { useBoolean } from "@fluentui/react-hooks"

const GarantieList: React.FC<{}> = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const items = useSelector(selectAll)
    const loadingState = useSelector(getLoadingState)
    const refreshItems = () => dispatch(fetchAll())
    const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
    const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
    const kostensoorten = useSelector(selectAllKostensoorten)

    useEffect(() => {
        refreshItems()
        dispatch(fetchAllKostensoort())
    }, []);

    const handleItemInvoked = (id: string) => {
        dispatch(select(id))
        openPanel()
    }
    
    const commandItems = useMemo(() => [
        { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
        { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
        {  text: 'Toevoegen', onClick: () => { dispatch(clearSelection()); openPanel() }, icon: 'Add'},
        {  text: 'Verwijderen', onClick: () =>
                dispatch(
                    deleteGarantie(
                        selectedItems.map(value => parseInt(value))
                    )
                )
            , icon: 'Delete', disabled: !selectedItems.length },
    ], [selectedItems.length])

    const renderKostensoortGarantie = (kostensoortId: number, garantieId: number) => {
        return <Link key={garantieId} onClick={() => handleItemInvoked(garantieId.toString())}>{kostensoorten.find(x => x.kostensoortId === kostensoortId)?.omschrijving}</Link>
    }

    const columns: IColumn[] = [
        createCustomRenderedColumn('Kostensoort garantie', getPropertyName<IGarantie>('kostensoortId'), (x: IGarantie) => renderKostensoortGarantie(x.kostensoortId, x.garantieId), 'XXL', false),
    ]

    return (<>
        <MainTemplate title="Kostensoort garantie" subTitle="Overzicht" commandItems={commandItems}>
            <GarantieEditPanel
                dismissPanel={dismissPanel}
                isOpen={isPanelOpen}
            />

             <div style={{ height: '77vh' }}>
                 <FluentTable 
                    columns={columns} 
                    items={items} 
                    loading={loadingState === 'pending'} 
                    selectionMode={SelectionMode.multiple}
                    onSelectionChanged={(ids: string[]) => setSelectedItems(ids)}
                    onGetKey={(item: IGarantie) => item.garantieId.toString()}
                    onItemInvoked={(id: string) => {handleItemInvoked(id)}}
                />
            </div>
        </MainTemplate>
    </>)
}

export default GarantieList