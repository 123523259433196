import { z } from 'zod'
import { FonticoonAddOrUpdateDto, zodiosHooks } from '../../../../api/ApiClient'
import useRoles from '../../../../services/UseRoles'
import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { Panel, PanelType } from '@fluentui/react'
import VgtTextField from '../../../../components/VgtTextField'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

export interface IEditPanelProps {
  fonticoonId: number,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof FonticoonAddOrUpdateDto>;

export const FonticoonFormPanel: React.FC<IEditPanelProps> = props => {

  const { isAdmin } = useRoles()
  const [error, setError] = React.useState<string>()

  const defaultFonticoon = {
    naam: '',
    omschrijving: ''
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(FonticoonAddOrUpdateDto), mode: 'all', defaultValues: defaultFonticoon
  })

  const {
    mutate: updateFonticoon
  } = zodiosHooks.usePutApiFonticoonId(
    { params: { id: props.fonticoonId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: addFonticoon } = zodiosHooks.usePostApiFonticoon(
    {}, { onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  zodiosHooks.useGetApiFonticoonId({ params: { id: props.fonticoonId } }, {
      onSuccess: (data) => reset(data),
      enabled: props.fonticoonId !== 0
    }
  )

  const onCancel = () => {
    props.invalidate()
    reset({})
    props.dismissPanel()
  }

  const onSubmit = (data: CreateUpdateType) => {
    if (props.fonticoonId !== 0) {
      updateFonticoon(data)
    } else {
      addFonticoon(data)
    }
  }

  return (
    <div>
      <Panel type={PanelType.medium}
             headerText={`Fonticoon ${props.fonticoonId !== 0 ? 'wijzigen' : 'toevoegen'}`} isOpen={props.isOpen}
             onDismiss={onCancel}
             closeButtonAriaLabel="Sluiten">
        <ErrorMessageBar error={error} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField
            label="Fonticoon Naam"
            name={getPropertyName<CreateUpdateType>('naam')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          />
          <VgtTextField
            label="Fonticoon Omschrijving"
            name={getPropertyName<CreateUpdateType>('omschrijving')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          />
          <br />
          {isAdmin ?
            <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                                 onOkClick={() => handleSubmit(onSubmit)}
                                 onCancelClick={onCancel} /> : null
          }
        </form>
      </Panel>
    </div>
  )


}