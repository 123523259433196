import * as yup from 'yup'

export interface IInstallatie {
  id?: number

  // Algemeen
  baseElementId?: number
  nlSfbElementId?: number
  nlSfbCode?: string | undefined
  nlSfbOmschrijving?: string | undefined
  elementdetailId?: number | undefined
  elementdetailDisplay?: string
  materiaalSoortId?: number | undefined
  materiaalSoortDisplay?: string
  elementId?: number | undefined
  elementDisplay?: string
  categorieId: number
  categorieDisplay?: string
  defectlocatieId?: number | null
  locatieaanduiding: string | null
  woningVerbeteringType: number
  zavOnderhoud: boolean
  bron?: string
  opmerking?: string
  beheerderId?: number
  beheerderDisplay?: string
  asbest: boolean
  collectief: boolean

  // Productgegevens
  productGtin: string
  productKlasse: string
  productGln: string
  productFabrikant: string
  productMerk: string
  productOpmerking: string
  productSerie: string
  productType: string
  productCode: string
  productUse2BA: boolean
  productKleur?: string
  productLengte?: number
  productBreedte?: number
  productHoogte?: number
  productAantal?: number
  wattpiek?:number
  productEenheidId?: number
  productFabricageJaar?: number

  // Plaatsing
  leverancierId?: number
  leverancierHandelsnaam?: string
  plaatsingDatum?: string
  vervangingsDatum?: string

  // Garantie product
  garantieIngangsDatum?: string
  garantieEindDatum?: string
  garantieProductNotificatieDatum?: string

  // Contract
  contractId?: number

  // Garantie werkzaamheden
  werkzaamhedenIngangsDatum?: string
  werkzaamhedenEindDatum?: string
  werkzaamhedenNotificatieDatum?: string

  // Keuring
  keuringPartijId?: number
  keuringDatum?: string
  keuringGeldigTot?: string
  keuringNotificatieDatum?: string

  // Referenties
  referentieExtern: string
  referentieKostenplaats: string
  vgeRuimteId?: number
  vgeId?: number
  gebouwId?: number
  gebouwDeelId?: number
  ruimte?: string
}

export interface IInstallatieRuimteSoort {
  id: number
  description: string
}

const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null
  }
  return value
}

const currentYear = new Date().getFullYear()

export const installatieSchema = yup.object().shape(
  {
    bron: yup.string().nullable().max(250, 'De maximale lengte van het veld bron is 250.'),
    opmerking: yup.string().nullable().max(4000, 'De maximale lengte van de opmerking is 4000.'),
    productAantal: yup.number().transform((value, originalValue) => {
      const FLOAT_REGEXP = /^-?\d*[.,]?\d*$/ // Regular expression to match a decimal number
      return FLOAT_REGEXP.test(originalValue) ? value : NaN
    }).nullable(),
    productFabricageJaar: yup
      .number()
      .max(currentYear, `Fabricagejaar kan niet groter zijn dan het huidig jaar (${currentYear}).`)
      .integer('Fabricagejaar moet een geldig nummer zijn.')
      .transform(emptyStringToNull)
      .nullable(),
    productGtin: yup
      .string()
      .nullable()
      .notRequired()
      .when('productGtin', {
        is: (value: string | any[]) => value?.length,
        then: rule => rule.min(12, 'Het veld Product GTIN heeft een minimale lengte van 12.').max(50, 'De maximale lengte van het veld Product GTIN is 50.')
      }),
    productKlasse: yup.string().nullable().max(200, 'De maximale lengte van productklasse is 200.'),
    productGln: yup.string().nullable().max(200, 'De maximale lengte van product GLN si 200'),
    garantieGlnUitvoerder: yup.string().nullable().max(200, 'De maximale lengte van garantie GLN uitvoerder is 200.'),
    garantieUitvoerder: yup.string().nullable().max(200, 'De maximale lengte van garantie uitvoerder is 200.'),
    contractNummer: yup.string().nullable().max(200, 'De maximale lengte van contractnummer is 200.'),
    contractGln: yup.string().nullable().max(200, 'De maximale lengte van contract GLN is 200.'),
    contractPartij: yup.string().nullable().max(200, 'De maximale lengte van contract partij is 200.'),
    productSerie: yup.string().nullable().max(200, 'De maximale lengte van product serie is 200.'),
    productKleur: yup.string().nullable().max(20, 'De maximale lengte van product kleur is 20.'),
    productLengte: yup.number().transform((value, originalValue) => {
      const FLOAT_REGEXP = /^-?\d*[.,]?\d*$/ // Regular expression to match a decimal number
      return FLOAT_REGEXP.test(originalValue) ? value : undefined
    }).nullable(),
    productBreedte: yup.number().transform((value, originalValue) => {
      const FLOAT_REGEXP = /^-?\d*[.,]?\d*$/ // Regular expression to match a decimal number
      return FLOAT_REGEXP.test(originalValue) ? value : undefined
    }).nullable(),
    productHoogte: yup.number().transform((value, originalValue) => {
      const FLOAT_REGEXP = /^-?\d*[.,]?\d*$/ // Regular expression to match a decimal number
      return FLOAT_REGEXP.test(originalValue) ? value : undefined
    }).nullable()
  },
  [['productGtin', 'productGtin']]
)
