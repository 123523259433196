import React, { useCallback, useEffect, useState } from 'react'
import { DefaultButton, IDropdownOption, Panel, PanelType, Stack } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from 'store'
import { getPropertyName } from 'lib/interfaceUtils'
import { useSelector } from 'react-redux'
import { getSelectedEntity, coenCadSchema, fetchAll, updateCoenCadCode, addCoenCadCode, getLoadingState } from './coenCadCodeSlice'
import VgtTextField from 'components/VgtTextField'
import { ICoenCadCode } from './coenCadCode'
import useRoles from 'services/UseRoles'
import { zodiosHooks } from '../../../api/ApiClient'
import VgtDropdown from '../../../components/FluentDropdown'

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  selectedDefectLocatieId?: string
}

const EditPanelCoenCadCode: React.FC<IEditPanelProps> = props => {
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState('Coencad')
  const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
  const fetchStatus = useSelector(getLoadingState)
  const selectedEntity = useSelector(getSelectedEntity)
  const { isAdmin } = useRoles()

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<ICoenCadCode>({ resolver: yupResolver(coenCadSchema), mode: 'all' })

  useEffect(() => {
    setCloseOnFulFilled(false)
    dispatch(fetchAll())
  }, [])

  useEffect(() => {
    setTitle(`Coencad Code Detail ${selectedEntity ? isAdmin ? 'wijzigen' : '' : 'toevoegen'}`)
    if (selectedEntity) {
      reset(selectedEntity)
    } else {
      reset({})
    }
  }, [selectedEntity])

  const onCancel = () => {
    props.dismissPanel()
  }

  const onSubmit = data => {
    if (data.elementElementDetailId === 0) data.elementElementDetailId = undefined
    if (data.code === '') data.code = undefined
    if (selectedEntity) {
      data.id = selectedEntity.id
      dispatch(updateCoenCadCode(data))
    } else {
      dispatch(addCoenCadCode(data))
    }
    setCloseOnFulFilled(true)
  }

  useEffect(() => {
    if (closeOnFulFilled && fetchStatus === 'succeeded') props.dismissPanel()
  }, [fetchStatus])

  const handleErrors = useCallback((errors: any) => {
    console.log(errors)
  }, [])

  const { data: elementElementDetail } = zodiosHooks.useGetElementElementDetailslistitems()
  const elementElementDetails = elementElementDetail?.map((item) => {
    return { key: item.elementElementDetailId, text: item.omschrijving }
  }) ?? []

  return (
    <div>
      <Panel type={PanelType.medium} headerText={title} isOpen={props.isOpen} onDismiss={onCancel} closeButtonAriaLabel="Sluiten">
        <br />
        <form onSubmit={handleSubmit(onSubmit)} onError={handleErrors}>
          <VgtTextField label="CWB Key" name={getPropertyName<ICoenCadCode>('key')} register={register} control={control} errors={errors} required readOnly={!isAdmin}/>
          <VgtTextField label="Groep" name={getPropertyName<ICoenCadCode>('groep')} register={register} control={control} errors={errors} readOnly={!isAdmin}/>
          <VgtTextField label="Code Interface" name={getPropertyName<ICoenCadCode>('code')} register={register} control={control} errors={errors} readOnly={!isAdmin}/>
          <VgtTextField
            label="Omschrijving"
            name={getPropertyName<ICoenCadCode>('description')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isAdmin}
          />
          <VgtDropdown label='Cartotheek-item' name={getPropertyName<ICoenCadCode>('elementElementDetailId')}
                       control={control} options={[{ key: 0 , text: '-----' }, ...elementElementDetails] as IDropdownOption[]} errors={errors}
                       required={true}
          />
          <br />
          { isAdmin ?
            <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
              <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} onClick={handleSubmit(onSubmit)} />
              <DefaultButton text="Annuleren" onClick={onCancel} />
            </Stack> : null
          }  
        </form>
      </Panel>
    </div>
  )
}

export default EditPanelCoenCadCode
