import { Checkbox, ICheckboxStyles, IRenderFunction, ITextFieldStyles, TextField } from '@fluentui/react'
import React, { FC } from 'react'

type width = number | string;

export interface IReadonlyFieldProps {
  title: string
  value: any
  multiline?: boolean
  labelWidth?: width
  textColor?: string | null
  fieldWidth?: width
  onInputRender?: IRenderFunction<React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement>>
  autoAdjustHeight?: boolean
  resizable?: boolean
}

interface IReadonlyCheckboxProps {
  title: string
  checked: boolean | undefined
  labelWidth?: width
}

const createReadonlyStyles = (labelWidth?: width, fieldWidth?: width, textColor?: string) => {
  return {
    wrapper: {
      borderBottom: '1px solid #efefef',
      wordWrap: 'wrap'
    },
    subComponentStyles: {
      label: {
        root: {
          width: labelWidth ?? 'auto',
          fontWeight: 'normal',
          height: 'auto'
        }
      }
    },
    field: {
      fontWeight: 600,
      width: fieldWidth ?? 'auto',
      overflow: 'hidden',
      color: textColor
    },
    suffix: {
      background: ''
    }
  } as Partial<ITextFieldStyles>
}

const createCheckBoxStyles = (labelWidth?: width) => {
  return {
    root: {
      borderBottom: '1px solid #efefef',
      padding: '10px 0'
    },
    label: {
      width: labelWidth ?? undefined
    },
    text: {
      color: 'rgb(50, 49, 48)',
      width: 315
    }
  } as Partial<ICheckboxStyles>
}

export const ReadOnlyCheckBox: FC<IReadonlyCheckboxProps> = (props: IReadonlyCheckboxProps) => {
  return <Checkbox checked={props.checked} label={props.title} boxSide='end' disabled
                   styles={createCheckBoxStyles(props.labelWidth)}></Checkbox>
}

export const ReadOnlyDate: FC<IReadonlyFieldProps> = (props: IReadonlyFieldProps) => {
  const value = props.value ? new Date(props.value).toLocaleDateString('nl-NL', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }) : ''
  return <ReadOnlyField {...props} title={props.title} value={value} />
}

export const ReadOnlyField: React.FC<IReadonlyFieldProps> = (props: IReadonlyFieldProps) => {
  return (
    <TextField
      autoComplete='off'
      styles={createReadonlyStyles(props.labelWidth ?? 165, props.fieldWidth ?? '100%', props.textColor ?? undefined)}
      label={props.title}
      underlined
      readOnly
      value={props.value}
      multiline={props.multiline}
      onRenderInput={props.onInputRender}
      resizable={props.resizable ?? false}
      placeholder={'---'}
      autoAdjustHeight={props.autoAdjustHeight}
    />
  )
}

export default ReadOnlyField
