import {
  CommandBar,
  DetailsList,
  ICommandBarItemProps,
  Link,
  SelectionMode,
  Spinner,
  SpinnerSize
} from '@fluentui/react'
import commandBarStyles from '../../../styles/commandBarStyles'
import { IInstallatie } from '../installatie/installatieTypes'
import { FC } from 'react'
import { createBooleanColumn, createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { transformDateString } from '../lemdo/common/support'
import { createCommandButton } from '../../../lib/commandBarHelper'
import { getInstallaties, getLoadingState } from './CartotheekStateMachine'
import { useSelector } from 'react-redux'

export interface IInstallatieGridProps {
  onSelected: (item: IInstallatie) => void
  onClose?: () => void
  onAdd?: () => void
}

export const CartotheekList: FC<IInstallatieGridProps> = (props: IInstallatieGridProps) => {
  const installaties = useSelector(getInstallaties)
  const loadingState = useSelector((state: any) =>  getLoadingState(state))

  const columns = [
    createColumn('Cartotheek-item', getPropertyName<IInstallatie>('elementdetailDisplay'), 'XXL', true,
      (item: IInstallatie) => <Link key={item.id} onClick={() => props.onSelected(item)}>{item.categorieDisplay} {item.elementDisplay ? ' - ' : ''}{item.elementDisplay} {item.elementdetailDisplay ? ' - ' : ''}{item.elementdetailDisplay} {item.materiaalSoortDisplay ? ' - ' : ''}{item.materiaalSoortDisplay} </Link>),
    createBooleanColumn('Collectief', getPropertyName<IInstallatie>('collectief'), 'L'),
    createColumn('Aantal', getPropertyName<IInstallatie>('productAantal'), 'L'),
    createColumn('Ruimte', getPropertyName<IInstallatie>('ruimte'), 'L'),
    createColumn('Beheerder', getPropertyName<IInstallatie>('beheerderDisplay'), 'XXL'),
    createColumn('Leverancier', getPropertyName<IInstallatie>('leverancierHandelsnaam'), 'XXL'),
    createColumn('Garantie werk t/m', getPropertyName<IInstallatie>('garantieEindDatum'), 'L', false, (item: IInstallatie) => transformDateString(item.werkzaamhedenEindDatum)),
    createColumn('Externe referentie kostenplaats', getPropertyName<IInstallatie>('referentieKostenplaats'), 'L'),
  ]
  const createCommandBarItems = () => {
    let result: ICommandBarItemProps[]  = []

    if (props.onAdd !== undefined) {
      result.push(createCommandButton('Nieuw', 'Add', () => props.onAdd!()))
    }

    return result
  }

  return (
    <>
      <CommandBar items={createCommandBarItems()} styles={commandBarStyles} />

      <div style={{ height: '78.7vh', position: 'relative' }}>
        <DetailsList items={installaties} columns={columns} onItemInvoked={props.onSelected}
                     selectionMode={SelectionMode.none} />
        {loadingState === "pending" && <Spinner size={SpinnerSize.large} />}
      </div>
      </>
      )
      }