import { mergeStyleSets, Text } from '@fluentui/react'
import { IVerwerking } from './statisticsSlice'

const classNames = mergeStyleSets({
    card: {
        border: `1px solid #efefef`,
        boxShadow: '#efefef 0px 0px 9px -1px',
        padding: '5px 14px 24px',
        width: 450
    },
    title: {
        paddingBottom: '5px',
        marginLeft: '-5px'
    },
})

const VerwerkingLine = ({ verwerkingType, verwerkingDate }) => {
    return (
        <tr>
            <td>
                <Text variant={'medium'}>
                    <b>{verwerkingType}:</b>
                </Text>
            </td>
            <td>
                <Text variant={'medium'}>
                    {verwerkingDate}
                </Text>
            </td>
        </tr>
    )
}

export type Props = { verwerkingen: IVerwerking[] }
const LaatsteVerwerkingen: React.FunctionComponent<Props> = ({ verwerkingen }) => {
    return (
        <div className={classNames.card}>
            <div className={classNames.title}><Text variant="large"><b>Laatste verwerking</b></Text></div>
            <table width={'100%'}>
                <tbody>
                    {verwerkingen?.map(
                        verwerking => <VerwerkingLine key={verwerking.type} verwerkingType={verwerking.type} verwerkingDate={verwerking.datum} />
                    )}
                </tbody>
            </table>
            <Text></Text>
        </div>
    )
}

export default LaatsteVerwerkingen