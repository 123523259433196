import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GebouwdeelListDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { usePagedParams } from '../../../../services/usePagedParams'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { createColumn, createCustomRenderedColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { Link } from '@fluentui/react'
import { availableUrls } from '../../../../routing/AvailableUrls'
import { z } from 'zod'


type GebouwdeelListDtoType = z.infer<typeof GebouwdeelListDto>;

const Gebouwdelen: React.FC = () => {
  const navigate = useNavigate()
  const [error, setError] = useState<string>()
  const { validatedSearchParams, setFilter, setOrder, setPage } = usePagedParams()

  const { data, invalidate: fetchGebouwdeelItems, isLoading } = zodiosHooks.useGetGebouwdelen({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => fetchGebouwdeelItems(), icon: 'Refresh' }
  ], [])

  const columns = [
    createCustomRenderedColumn(
      'Code',
      getPropertyName<GebouwdeelListDtoType>('code'),
      (item: GebouwdeelListDtoType) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.code}
        </Link>
      ),
      'S',
      false
    ),
    createColumn('Naam', getPropertyName<GebouwdeelListDtoType>('naam')),
    createColumn('Gebouw Naam', getPropertyName<GebouwdeelListDtoType>('gebouwNaam'))
  ]

  const handleItemInvoked = (id: string) => {
    navigate(availableUrls.Gebouwdeel.replace(':id', id))
  }

  return (
    <MainTemplate title='Gebouwdelen' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <div style={{ height: '90vh' }}>
        <VgtPagedTable
          isLoading={isLoading}
          items={data?.items ?? []}
          columns={columns}
          height={'203'}
          pagingInfo={{
            pageSize: validatedSearchParams.pageSize,
            currentPage: validatedSearchParams.page,
            totalCount: data?.totalCount ?? 0,
            totalPages: data?.totalPages ?? 0,
            hasNextPage: data?.hasNextPage ?? false,
            hasPreviousPage: data?.hasPreviousPage ?? false,
            sortKey: validatedSearchParams.sortKey || 'id',
            sortDirection: validatedSearchParams.sortDirection || 'desc'
          }}
          getKey={(item) => item?.id}
          onItemInvoked={(item) => handleItemInvoked(item.id.toString())}
          onPageChanged={setPage}
          onSortChanged={setOrder}
        />
      </div>
    </MainTemplate>
  )
}

export default Gebouwdelen