import { IColumn, Link, SelectionMode } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { createCustomRenderedColumn } from 'lib/gridHelper'
import {
  clearSelection,
  fetchAll,
  getLoadingState,
  ITenantKostensoortBetaler,
  select,
  selectAll
} from './tenantKostensoortBetalerSlice'
import { fetchAll as fetchAllKostensoorten, selectAll as selectAllKostensoorten } from '../kostensoort/kostensoortSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import FluentTable from 'components/FluentTable'
import { TenantKostensoortBetalerEditPanel } from './editPanel'
import { useBoolean } from '@fluentui/react-hooks'

const TenantKostensoortBetalerList: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const items = useSelector(selectAll)
  const loadingState = useSelector(getLoadingState)
  const kostensoorten = useSelector(selectAllKostensoorten)
  const refreshItems = () => dispatch(fetchAll())
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])

  useEffect(() => {
    refreshItems()
    dispatch(fetchAllKostensoorten())
  }, [])

  const handleItemInvoked = (id: string) => {
    dispatch(select(id))
    openPanel()
  }

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    {
      text: 'Toevoegen', onClick: () => {
        dispatch(clearSelection())
        openPanel()
      }, icon: 'Add'
    }
  ], [selectedItems.length])

  const renderBetaler = (x: ITenantKostensoortBetaler) => <Link key={x.betalerOptie}
                                                                onClick={() => handleItemInvoked(x.betalerOptie.toString())}>{x.betalerNaam}</Link>

  const renderKostensoort = (kostensoortId: number) => {
    return <>{kostensoorten.find(x => x.kostensoortId === kostensoortId)?.omschrijving}</>
  }


  const columns: IColumn[] = [
    createCustomRenderedColumn('Betaler', getPropertyName<ITenantKostensoortBetaler>('betalerNaam'), (x: ITenantKostensoortBetaler) => renderBetaler(x), 'XL', false),
    createCustomRenderedColumn('Kostensoort', getPropertyName<ITenantKostensoortBetaler>('kostensoortId'), (x: ITenantKostensoortBetaler) => renderKostensoort(x.kostensoortId), 'XXL', false)
  ]

  return (<MainTemplate title='Betaler || Kostensoort' subTitle='Overzicht' commandItems={commandItems}>
    <TenantKostensoortBetalerEditPanel
      dismissPanel={dismissPanel}
      isOpen={isPanelOpen}
    />

    <div style={{ height: '77vh' }}>
      <FluentTable
        columns={columns}
        items={items}
        loading={loadingState === 'pending'}
        selectionMode={SelectionMode.multiple}
        onSelectionChanged={(ids: string[]) => setSelectedItems(ids)}
        onGetKey={(item: ITenantKostensoortBetaler) => item.betalerOptie?.toString()}
        onItemInvoked={(id: string) => {
          handleItemInvoked(id)
        }}
      />
    </div>
  </MainTemplate>)
}

export default TenantKostensoortBetalerList