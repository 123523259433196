import React, { useMemo, useState} from 'react'
import UploadPanel from 'components/UploadPanel'
import { useBoolean } from '@fluentui/react-hooks'
import { Dropdown, IDropdownOption, MessageBar, MessageBarType, PrimaryButton, Stack, StackItem } from '@fluentui/react'
import UploadErrorList from 'components/UploadPanel/UploadErrorList'
import { useDispatch, useSelector } from 'react-redux'
import { sendSyncVhesCommand, getLoadingState } from './importSlice'
import MainTemplate from "containers/pages/PageTemplates/MainTemplate";
import { zodiosHooks } from '../../../api/ApiClient'

const Importeren: React.FC = () => {
  const dispatch = useDispatch()
  const loading = useSelector(getLoadingState)
  const [hasError, setHasError] = useState(false)
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [message, setMessage] = useState('')
  const [errorMessages, setErrorMessages] = useState([] as string[])
  const [uploadEndoint, setUploadEndoint] = useState("")
  const [currentOption, setCurrentOption] = useState<IDropdownOption | undefined>()

  const { data } = zodiosHooks.useGetImports()

  const onSuccess = (result: any) => {
    clearResults()
    if (result.succeeded) {
      setMessage('De data is opgeslagen in het systeem om te verwerken.')
    } else {
      setErrorMessages(result.errors ?? [])
    }
  }

  const clearResults = () => {
    setErrorMessages([])
    dismissPanel()
    setMessage('')
    setHasError(false)
  }

  const startSync = () => {
    dispatch(sendSyncVhesCommand())
  }
  
  const commandItems = useMemo( () => [
    {  text: 'Synchroniseren vhe', onClick: () => startSync(), icon: 'Home', iconOnly: false, enabled: loading === "pending" },
  ], [loading])

  const onError = async (result: any) => {
    clearResults()
    dismissPanel()
    if (result.message !== undefined) {
      setMessage(result.message)
    } else {
      const errorMessage = await result.text()
      if (errorMessage === '') setMessage(`Er is een fout opgetreden (${result.status}).`)
      else setMessage(errorMessage)
    }
    setHasError(true)
  }

  const buttonOpenClick = () => {
    clearResults()
    openPanel()
  }

  const onChange = (_event, item: IDropdownOption | undefined) => {
    if (item) {
      setCurrentOption(item)
      setUploadEndoint(item.key as string)
    }
  }

  const importOptions: IDropdownOption[] = data ?
    data?.map(value => ({
      text: value?.name ?? '',
      key: value?.uploadEndpoint !== null ? value.uploadEndpoint : '',
    })) : []

  return (
    <>
      <MainTemplate title='Importeren' subTitle='Overzicht' commandItems={commandItems}/>
      {hasError && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
          Er is een fout opgetreden tijdens het uploaden van het bestand.
        </MessageBar>
      )}
      <UploadPanel
        title="Selecteer een bestand"
        uploadEndpoint={uploadEndoint}
        isOpen={isOpen}
        dismissPanel={dismissPanel}
        onSuccess={onSuccess}
        onError={onError}
      />
      <Stack tokens={{ childrenGap: 5 }} verticalAlign={'start'} style={{ margin: 20, marginTop: 20 }}>
        <StackItem>
            <Dropdown
                onChange={onChange}
                placeholder="Kies een optie"
                options={importOptions}
                styles={{ dropdown: { width: 600 } }}
            />
        </StackItem>
        <StackItem>
            <PrimaryButton onClick={buttonOpenClick} text={"Selecteer een bestand"}  disabled={!currentOption?.text}/>
        </StackItem>
        <StackItem>
          <label>{message}</label>
        </StackItem>
      </Stack>
      <UploadErrorList messages={errorMessages} />
      {loading === "pending" && <div>Versturen opdracht...</div>}
      {loading === "succeeded" && <div>De sychronisatie-opdracht is verstuurd.</div>}
    </>
  )
}

export default Importeren