import { DetailsList, DetailsListLayoutMode, IColumn, IStackTokens, SelectionMode, Stack, StackItem } from '@fluentui/react'
import InputCard from 'components/InputCard'
import ReadOnlyField from 'components/ReadOnlyField'
import React, { useEffect, useState } from 'react'
import { IWoz, IWozDeelObject } from './wozSlice'
import { getPropertyName } from 'lib/interfaceUtils'
import useApi from '../../../services/UseApi'

interface IWozCardProps {
    vgeId: number | undefined
}

const WozCard: React.FC<IWozCardProps> = props => {
    const { state, execute } = useApi<IWoz>(`woz/woz-objects/${props.vgeId}`, 'GET')
    const [wozRecord, setWozRecord] = useState<IWoz>();

    let wozRecords: IWoz[] = [];

    if (wozRecord) {
        wozRecords.push(wozRecord);
    }
    if (wozRecord?.wozHistorie) {
        wozRecords.push(...wozRecord.wozHistorie);
    }
    wozRecords.sort((a, b) => a!.peildatum < b!.peildatum ? 1 : -1);

    useEffect(() => {
        execute();
    }, []);

    useEffect(() => {
        setWozRecord(state?.data);
    }, [state]);

    const stackTokens: IStackTokens = { padding: '0', childrenGap: '20 20' }

    const columns: IColumn[] = [
        {
            key: getPropertyName<IWoz>('peildatum'),
            name: 'Peildatum',
            fieldName: getPropertyName<IWoz>('peildatum'),
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isRowHeader: true,
            data: 'string',
            onRender: (item: IWoz) => (
                <div onClick={(ev)=>{
                    setWozRecord(item);
                }}>
                    <span>{item.peildatum.toString().slice(0, 10)}</span>
                </div>
            ),
        },
        {
            key: getPropertyName<IWoz>('wozWaarde'),
            name: 'WOZ-waarde',
            fieldName: getPropertyName<IWoz>('wozWaarde'),
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: getPropertyName<IWoz>('wozOzbAanslag'),
            name: 'OZB-aanslag',
            fieldName: getPropertyName<IWoz>('wozOzbAanslag'),
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isRowHeader: true,
            data: 'string',
        },
    ]

    const deelObjectColumns: IColumn[] = [
        {
            key: getPropertyName<IWozDeelObject>('wozDeelobjectNummer'),
            name: 'Nummer',
            fieldName: getPropertyName<IWozDeelObject>('wozDeelobjectNummer'),
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: getPropertyName<IWozDeelObject>('wozDeelobjectOnderdeelCode'),
            name: 'Onderdeel-code',
            fieldName: getPropertyName<IWozDeelObject>('wozDeelobjectOnderdeelCode'),
            minWidth: 110,
            maxWidth: 110,
            isResizable: true,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: getPropertyName<IWozDeelObject>('wozDeelobjectOnderdeelOmschrijving'),
            name: 'Onderdeel-omschrijving',
            fieldName: getPropertyName<IWozDeelObject>('wozDeelobjectOnderdeelOmschrijving'),
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: getPropertyName<IWozDeelObject>('wozDeelobjectGebruiksoppervlakte'),
            name: 'Gebruiksoppervlakte',
            fieldName: getPropertyName<IWozDeelObject>('wozDeelobjectGebruiksoppervlakte'),
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isRowHeader: true,
            data: 'string',
        },
        {
            key: getPropertyName<IWozDeelObject>('bagPand'),
            name: 'BAG Pand',
            fieldName: getPropertyName<IWozDeelObject>('bagPand'),
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isRowHeader: true,
            data: 'string',
        },
    ];

    return (
        <div>
            <Stack horizontal={true} tokens={stackTokens}>
                <StackItem>
                    <InputCard title='WOZ-object' width='450px'>
                        <ReadOnlyField title="Nummer" value={wozRecord?.wozObjectnummer} fieldWidth={250} />
                        <ReadOnlyField title="Locatie" value={wozRecord?.locatiebeschrijving} fieldWidth={250} />
                        <ReadOnlyField title="Vastgoedobjectsoort" value={wozRecord?.vastgoedobjectsoort} fieldWidth={250} />
                        <ReadOnlyField title="Eigendom/gebruik" value={wozRecord?.wozEigendomGebruik} fieldWidth={250} />
                    </InputCard>
                </StackItem>             
                {wozRecord?.wozDeelobjecten ?
                    <StackItem>
                        <InputCard title='WOZ-deelobjecten' width='800px'>
                            <DetailsList
                                items={wozRecord.wozDeelobjecten}
                                columns={deelObjectColumns}
                                selectionMode={SelectionMode.none}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                            />
                        </InputCard>
                    </StackItem>
                    : <></>}
                </Stack>
                <Stack horizontal={true} tokens={stackTokens}>
                    <StackItem>
                        <InputCard title='WOZ-waarde' width='450px'>
                            <DetailsList
                                items={wozRecords}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                            />
                        </InputCard>
                    </StackItem>
                </Stack>
        </div>
    )
}
export default WozCard
