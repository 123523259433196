import { IColumn, Link } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { createCustomRenderedColumn } from 'lib/gridHelper'
import { clearSelection, deleteOnderhoudsspecialisme, fetchAll, select } from './onderhoudsspecialismeSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { OnderhoudsspecialismeEditPanel } from './editPanel'
import { useBoolean } from '@fluentui/react-hooks'
import useRoles from 'services/UseRoles'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'
import { OnderhoudsspecialismeDto, zodiosHooks } from '../../../../api/ApiClient'
import { usePagedParams } from '../../../../services/usePagedParams'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'

type OnderhoudsspecialismeDtoType = z.infer<typeof OnderhoudsspecialismeDto>;


const OnderhoudsspecialismeList: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const { isAdmin } = useRoles()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const [error, setError] = React.useState<string>()
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  useEffect(() => {
    dispatch(fetchAll({ filter: '' }))
  }, [])

  const {
    data: onderhoudsspecialismeItems,
    invalidate: fetchOnderhoudsspecialismeItems,
    isLoading
  } = zodiosHooks.useGetOnderhoudsspecialisme({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const handleItemInvoked = (id) => {
    dispatch(select(id))
    openPanel()
  }

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => fetchOnderhoudsspecialismeItems(), icon: 'Refresh' },
    {
      visible: isAdmin, text: 'Toevoegen', onClick: () => {
        dispatch(clearSelection())
        openPanel()
      }, icon: 'Add'
    },
    {
      visible: isAdmin, text: 'Verwijderen', onClick: () =>
        dispatch(
          deleteOnderhoudsspecialisme(
            selectedIds
          )
        )
      , icon: 'Delete', disabled: !selectedIds.length
    }
  ], [isAdmin, selectedIds.length])

  const renderCode = (x: OnderhoudsspecialismeDtoType) => <Link key={x.code}
                                                                onClick={() => handleItemInvoked(x.onderhoudsspecialismeId.toString())}>{x.code}</Link>

  const columns: IColumn[] = [
    createCustomRenderedColumn('Code', getPropertyName<OnderhoudsspecialismeDtoType>('code'), renderCode, 'XXL', false),
    createCustomRenderedColumn('Naam', getPropertyName<OnderhoudsspecialismeDtoType>('naam'), (x: OnderhoudsspecialismeDtoType) => <>{x.naam}</>
      , 'MAX', false, true)
  ]

  return (
    <MainTemplate title='Onderhoudsspecialismen' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <OnderhoudsspecialismeEditPanel
        dismissPanel={dismissPanel}
        isOpen={isPanelOpen}
        fetchOnderhoudsspecialismeItems={fetchOnderhoudsspecialismeItems}
      />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <VgtPagedTable
        items={onderhoudsspecialismeItems?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: onderhoudsspecialismeItems?.totalCount ?? 0,
          totalPages: onderhoudsspecialismeItems?.totalPages ?? 0,
          hasNextPage: onderhoudsspecialismeItems?.hasNextPage || false,
          hasPreviousPage: onderhoudsspecialismeItems?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item: OnderhoudsspecialismeDtoType) => item?.onderhoudsspecialismeId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.onderhoudsspecialismeId)}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}

export default OnderhoudsspecialismeList
