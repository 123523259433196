import React, { useEffect, useMemo, useState } from 'react'
import {
  addOnderhoudsContract,
  contractSchema,
  fetchById,
  getSelectedEntity, getUpdateState,
  IContractGebouw,
  IOnderhoudsContract,
  setSelectedContract,
  updateOnderhoudsContract,
  clearSelection
} from './onderhoudsContractSlice'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import {
  CommandBar,
  DefaultButton,
  DetailsList,
  IColumn,
  ICommandBarItemProps, IStackTokens, mergeStyleSets,
  Pivot,
  PivotItem,
  ScrollablePane,
  SearchBox,
  Selection,
  SelectionMode,
  Stack,
  StackItem,
  Sticky,
  StickyPositionType
} from '@fluentui/react'
import VgtTextField from 'components/VgtTextField'
import { getPropertyName } from 'lib/interfaceUtils'
import {
  fetchRechtspersonen,
  IRechtspersoon,
  selectAll as selectSuppliers
} from '../relaties/rechtspersoon/rechtspersoonSlice'
import { debounce } from 'lodash'
import ReadOnlyField from 'components/ReadOnlyField'
import HorizontalDatePicker, { convertToDateString, parseDate } from 'components/HorizontalDatePicker'
import { useTypedSelector } from 'store'
import { IGebouw } from 'features/beheer/fysiek/gebouwen/gebouwenSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { useBoolean } from '@fluentui/react-hooks'
import commandBarStyles from 'styles/commandBarStyles'
import { createCommandButton } from 'lib/commandBarHelper'
import SelectGebouwenPanel from './selectGebouwenPanel'
import { IVge } from 'features/vge/vgeSlice'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import SelectVgesPanel, { SearchType } from './selectVgesPanel'
import { fetchAll as fetchVges, selectAll as selectVges } from './vgeContractVgeSlice'
import InputCard from 'components/InputCard'
import TitleTemplate from '../../../containers/pages/PageTemplates/TitleTemplate'
import { ITenantTaak } from '../lemdo/tenantTaak/tenantTaakSlice'
import SelectTaakPanel from './selectTaakPanel'
import VgtCheckbox from '../../../components/FluentCheckbox'
import DocumentList, { DocumentDoelType } from '../documents/DocumentList'

const classNames = mergeStyleSets({
  containerStyles: {
    height: '45vh',
    position: 'relative',
    '@media (max-height: 900px)': {
      height: '8vh',
    }
  }
})

const now = new Date()
const today = convertToDateString(new Date(now.getFullYear(), now.getMonth(), now.getDay()))
const nextYear = convertToDateString(new Date(now.getFullYear() + 1, now.getMonth(), now.getDay()))

const createSortText = (vge: IVge) => `${vge.str}-${('0000000000' + vge.nr).substring(6)}-${vge.nrT}`

const createSortedVges = (vges: IVge[]) => {
  const result = [...vges]
  result.sort((a, b) => {
    const textA = createSortText(a)
    const textB = createSortText(b)
    return textA.localeCompare(textB)
  })
  return result
}

const createVgesMetAdres = (vges: IVge[]) => {
  const vgesMetAdres = vges.filter(vge => vge.baGid !== undefined)
  return createSortedVges(vgesMetAdres)
}

const createVgesZonderAdres = (vges: IVge[]) => {
  const vgesZonderAdres = vges.filter(vge => vge.baGid === undefined)
  return createSortedVges(vgesZonderAdres)
}

const ContractEdit: React.FC = () => {
  const { id: idFromUrl } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
  const updateStatus = useSelector(getUpdateState)
  const selectedEntity = useTypedSelector(s => getSelectedEntity(s))
  const [searchTermContractPartij, setSearchTermContractPartij] = useState<string>()
  const suppliers = useSelector(selectSuppliers)
  const [supplier, setSupplier] = useState<IRechtspersoon>()
  const [gebouwen, setGebouwen] = useState<IContractGebouw[]>([])
  const [vges, setVges] = useState<IVge[]>([])
  const [taken, setTaken] = useState<ITenantTaak[]>([])
  const vgeIds = vges ? vges.map(v => v.id) : []
  const [isGebouwPanelOpen, { setTrue: openGebouwPanel, setFalse: dismissGebouwPanel }] = useBoolean(false)
  const [isVgePanelOpen, { setTrue: openVgePanel, setFalse: dismissVgePanel }] = useBoolean(false)
  const gebouwIds = useMemo(() => gebouwen.map(g => g.gebouwId), [gebouwen])
  const loadedVges = useSelector(selectVges)
  const sortedVgesMetAdres = useMemo(() => createVgesMetAdres(vges), [vges])
  const sortedVgesZonderAdres = useMemo(() => createVgesZonderAdres(vges), [vges])
  const [loadingVges, setLoadingVges] = useState(false)
  const [volledigBezit, setVolledigBezit] = useState<boolean | undefined>(false)
  const [searchVgeType, setSearchVgeType] = useState(SearchType.metAdres)
  const [isTaakPanelOpen, { setTrue: openTaakPanel, setFalse: dismissTaakPanel }] = useBoolean(false)
  const [documentCount, setDocumentCount] = useState(0)

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<IOnderhoudsContract>({
    resolver: yupResolver(contractSchema),
    mode: 'all',
    defaultValues: {
      ingangsDatum: today,
      eindDatum: nextYear,
      notificatieDatum: nextYear,
      uitersteOpzegDatum: nextYear,
      voorVolledigBezit: false,
      contractOmschrijving: '',
      contractBeheerder: '',
      contractNummer: ''
    }
  })

  useEffect(() => {
    if(idFromUrl === '0'){
      dispatch(clearSelection())
    }
  }, [idFromUrl])

  const AddTaakToSelection = () => {
    openTaakPanel()
  }

  const [selectionTaken] = useState<Selection>(() => {
    return new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selectionTaken.count,
      getKey: (item: { id: string | number }) => item.id
    })
  })

  const onSelectTaken = (items: ITenantTaak[]) => {
    const mergedTaken = [...taken]
    for (let i = 0; i < items.length; i++) {
      const taak = items[i]
      mergedTaken.push({...taak, taakNaam: taak.naam} as ITenantTaak)
    }
    setTaken(mergedTaken.sort((a, b) => (a.taakNaam ?? '').localeCompare(b.taakNaam ?? '')))
    dismissTaakPanel()
  }

  const RemoveTaakFromSelection = () => {
    if (selectionTaken.count > 0) {
      const items = selectionTaken.getSelection() as ITenantTaak[]
      const remainingTaken = taken.filter(g => {
        return items.findIndex(gi => gi.taakId === g.taakId) === -1
      })
      setTaken(remainingTaken)
      selectionTaken.setAllSelected(false)
    }
  }

  const taakGridColumns = [createColumn('Naam', getPropertyName<ITenantTaak>('taakNaam'))]
  const taakCommandbarItems = [
    createCommandButton('Koppelen', 'PlugConnected', AddTaakToSelection),
    createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveTaakFromSelection)
  ] as ICommandBarItemProps[]

  const loadGebouwVges = (loadGebouwIds?: number[]) => {
    setLoadingVges(true)
    dispatch(fetchVges({ gebouwIds: loadGebouwIds ?? gebouwIds }))
  }

  useEffect(() => {
    if (loadingVges) {
      setVges(loadedVges)
      setLoadingVges(false)
    }
  }, [loadedVges])

  const onSelectedSupplier = () => {
    const items = selectionSupplier.getSelection()
    if (items.length !== 0) {
      const supplier = items[0] as IRechtspersoon
      setValue('leverancierId', supplier.id)
      setSupplier(supplier)
    }
  }

  const [selectionSupplier] = useState<Selection>(() => {
    return new Selection({
      selectionMode: SelectionMode.single,
      onSelectionChanged: onSelectedSupplier,
      getKey: (item: { key: string | number }) => item.key
    })
  })

  const [selectionGebouwen] = useState<Selection>(() => {
    return new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selectionGebouwen.count,
      getKey: (item: { id: string | number }) => item.id
    })
  })

  const [selectionVgesMetAdres] = useState<Selection>(() => {
    return new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selectionVgesMetAdres.count,
      getKey: (item: { id: string | number }) => item.id
    })
  })

  const [selectionVgesZonderAdres] = useState<Selection>(() => {
    return new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selectionVgesZonderAdres.count,
      getKey: (item: { id: string | number }) => item.id
    })
  })

  const [selectionVgesTerrein] = useState<Selection>(() => {
    return new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selectionVgesTerrein.count,
      getKey: (item: { id: string | number }) => item.id
    })
  })

  useEffect(() => {
    setCloseOnFulFilled(false)
    if (idFromUrl !== '0') {
      const id = parseInt(idFromUrl ?? '0')
      dispatch(fetchById(id))
      dispatch(setSelectedContract(id))
    }
  }, [])

  useEffect(() => {
    if (!selectedEntity || idFromUrl === '0') return
      const relatie = suppliers.find(s => s.id === selectedEntity.leverancierId)
      setSupplier(relatie)
      reset(selectedEntity)
      setGebouwen(selectedEntity.gebouwen)
      setVges(selectedEntity.vges)
      setTaken(selectedEntity.taken)
      setVolledigBezit(selectedEntity.voorVolledigBezit)

  }, [selectedEntity])

  const onSubmit = (data: IOnderhoudsContract) => {
    if (selectedEntity) data.leverancierId = selectedEntity.leverancierId
    else if (supplier) data.leverancierId = supplier.id

    if (volledigBezit) {
      data.gebouwen = []
      data.vges = []
    } else {
      data.gebouwen = gebouwen
      data.vges = vges
    }
    data.taken = taken
    if (data.ingangsDatum) data.ingangsDatum = convertToDateString(data.ingangsDatum)
    if (data.eindDatum) data.eindDatum = convertToDateString(data.eindDatum)
    if (data.uitersteOpzegDatum) data.uitersteOpzegDatum = convertToDateString(data.uitersteOpzegDatum)
    if (data.notificatieDatum) data.notificatieDatum = convertToDateString(data.notificatieDatum)

    if (selectedEntity) {
      data.id = selectedEntity.id
      dispatch(updateOnderhoudsContract(data))
    } else dispatch(addOnderhoudsContract(data))
    setCloseOnFulFilled(true)
  }

  const updateSearchTermContractPartij = (newValue: string | undefined) => {
    let term = newValue
    if (term === '') term = undefined
    setSearchTermContractPartij(term)
    dispatch(fetchRechtspersonen({ filter: term ?? '', relatieRol: 'Leverancier' }))
  }

  useEffect(() => {
    if (closeOnFulFilled && updateStatus === 'succeeded') navigate(-1)
  }, [updateStatus])

  const delayedSearchContractPartij = debounce(eventData => updateSearchTermContractPartij(eventData), 500)

  const filterOnChangeContractPartij = (newValue: string | undefined) => {
    delayedSearchContractPartij(newValue)
  }

  const onClearNotificatieDatum = () => {
    setValue('notificatieDatum', undefined)
  }

  const columnsSupplier = [
    {
      name: 'Contractpartij',
      fieldName: getPropertyName<IRechtspersoon>('handelsnaam'),
      key: getPropertyName<IRechtspersoon>('handelsnaam'),
      minWidth: 50,
      maxWidth: 250,
      isResizable: true
    }
  ] as IColumn[]

  const columnGebouwen = [
    {
      name: 'Naam',
      fieldName: getPropertyName<IGebouw>('naam'),
      key: getPropertyName<IGebouw>('naam'),
      minWidth: 50,
      maxWidth: 250,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IGebouw) => <span>{item.code} - {item.naam}</span>
    }
  ] as IColumn[]

  const columnVgesMetAdres = [
    createColumn('Eenheiddetailsoort', getPropertyName<IVge>('srtNm'), 'L'),
    createColumn('Straat', getPropertyName<IVge>('str')),
    createColumn('Postcode', getPropertyName<IVge>('pc')),
    createColumn('Huisnummer', getPropertyName<IVge>('nr')),
    createColumn('Huisletter', getPropertyName<IVge>('ltr')),
    createColumn('Toev.', getPropertyName<IVge>('nrT')),
    createColumn('Woonplaats', getPropertyName<IVge>('wpl')),
    createColumn('Gebouwdeel', getPropertyName<IVge>('gdlCd')),
    createColumn('Eigenaar', getPropertyName<IVge>('en')),
    createColumn('Beheerder', getPropertyName<IVge>('bn')),
    createColumn('Kadastrale aand. Object', getPropertyName<IVge>('kad'))
  ]

  const columnVgesZonderAdres: IColumn[] = [
    createColumn('Eenheiddetailsoort', getPropertyName<IVge>('srtNm'), 'L'),
    createColumn('Straat', getPropertyName<IVge>('str')),
    createColumn('Adresaanduiding', getPropertyName<IVge>('aa')),
    createColumn('Woonplaats', getPropertyName<IVge>('wpl')),
    createCustomRenderedColumn('VHE', getPropertyName<IVge>('vhes'), item => item[getPropertyName<IVge>('vhes')].join(', ')),
    createColumn('Gerelateerd', getPropertyName<IVge>('rel')),
    createColumn('Gebouwdeel', getPropertyName<IVge>('gdl')),
    createColumn('Eigenaar', getPropertyName<IVge>('en')),
    createColumn('Beheerder', getPropertyName<IVge>('bn')),
    createColumn('Kadastrale aand. object', getPropertyName<IVge>('kad'))
  ]

  const columnVgesTerrein = [
    {
      key: getPropertyName<IVge>('srtNm'),
      name: 'Eenheiddetailsoort',
      fieldName: getPropertyName<IVge>('srtNm'),
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'vgetype',
      name: 'VGE Type',
      fieldName: 'vge type',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<IVge>('wpl'),
      name: 'Woonplaats',
      fieldName: getPropertyName<IVge>('wpl'),
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<IVge>('wk'),
      name: 'Wijk',
      fieldName: getPropertyName<IVge>('wk'),
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<IVge>('brt'),
      name: 'Buurt',
      fieldName: getPropertyName<IVge>('brt'),
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<IVge>('pc'),
      name: 'Postcode',
      fieldName: getPropertyName<IVge>('pc'),
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<IVge>('str'),
      name: 'Straat',
      fieldName: getPropertyName<IVge>('str'),
      minWidth: 110,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<IVge>('nr'),
      name: 'Huisnummer',
      fieldName: getPropertyName<IVge>('nr'),
      minWidth: 30,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<IVge>('ltr'),
      name: 'Huisletter',
      fieldName: getPropertyName<IVge>('ltr'),
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<IVge>('nrT'),
      name: 'Huisnummertoevoeging',
      fieldName: getPropertyName<IVge>('nrT'),
      minWidth: 125,
      maxWidth: 130,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<IVge>('aa'),
      name: 'Adresaanduiding',
      fieldName: getPropertyName<IVge>('aa'),
      minWidth: 80,
      maxWidth: 80,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<IVge>('trnNm'),
      name: 'Terrein classificatie',
      fieldName: getPropertyName<IVge>('trnNm'),
      minWidth: 120,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<IVge>('vhes'),
      name: 'Verhuureenheid',
      fieldName: getPropertyName<IVge>('vhes'),
      minWidth: 120,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true
    }
  ]

  const AddGebouwToSelection = () => {
    openGebouwPanel()
  }

  const AddVgeMetAdresToSelection = () => {
    setSearchVgeType(SearchType.metAdres)
    openVgePanel()
  }

  const AddVgeZonderAdresToSelection = () => {
    setSearchVgeType(SearchType.zonderAdres)
    openVgePanel()
  }

  const AddVgeTerreinToSelection = () => {
    setSearchVgeType(SearchType.terrein)
    openVgePanel()
  }

  const RemoveGebouwFromSelection = () => {
    if (selectionGebouwen.count > 0) {
      const items = selectionGebouwen.getSelection() as IContractGebouw[]
      const delIds = items.map(g => g.gebouwId)
      const remainingGebouwen = gebouwen.filter(g => {
        return items.findIndex(gi => gi.gebouwId === g.gebouwId) === -1
      })
      setGebouwen(remainingGebouwen)
      selectionGebouwen.setAllSelected(false)

      const remainingVges = vges.filter(v => {
        return delIds.findIndex(id => id === v.gebouwId) === -1
      })
      setVges(remainingVges)
      selectionVgesMetAdres.setAllSelected(false)
    }
  }

  const RemoveVgeMetAdresFromSelection = () => {
    if (selectionVgesMetAdres.count > 0) {
      const items = selectionVgesMetAdres.getSelection() as IVge[]
      const remainingVges = vges.filter(v => {
        return items.findIndex(vi => vi.id === v.id) === -1
      })
      setVges(remainingVges)
      selectionVgesMetAdres.setAllSelected(false)
    }
  }

  const RemoveVgeZonderAdresFromSelection = () => {
    if (selectionVgesZonderAdres.count > 0) {
      const items = selectionVgesZonderAdres.getSelection() as IVge[]
      const remainingVges = vges.filter(v => {
        return items.findIndex(vi => vi.id === v.id) === -1
      })
      setVges(remainingVges)
      selectionVgesZonderAdres.setAllSelected(false)
    }
  }

  const RemoveVgeTerreinFromSelection = () => {
    if (selectionVgesTerrein.count > 0) {
      const items = selectionVgesTerrein.getSelection() as IVge[]
      const remainingVges = vges.filter(v => {
        return items.findIndex(vi => vi.id === v.id) === -1
      })
      setVges(remainingVges)
      selectionVgesTerrein.setAllSelected(false)
    }
  }

  const selectGebouwCommandBarItems = [
    createCommandButton('Koppelen', 'PlugConnected', AddGebouwToSelection),
    createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveGebouwFromSelection)
  ] as ICommandBarItemProps[]

  const selectVgeMetAdresCommandBarItems = [
    createCommandButton('Koppelen', 'PlugConnected', AddVgeMetAdresToSelection),
    createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveVgeMetAdresFromSelection)
  ] as ICommandBarItemProps[]

  const selectVgeZonderAdresCommandBarItems = [
    createCommandButton('Koppelen', 'PlugConnected', AddVgeZonderAdresToSelection),
    createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveVgeZonderAdresFromSelection)
  ] as ICommandBarItemProps[]

  const selectVgeTerreinCommandBarItems = [
    createCommandButton('Koppelen', 'PlugConnected', AddVgeTerreinToSelection),
    createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveVgeTerreinFromSelection)
  ] as ICommandBarItemProps[]

  const onSelectGebouwen = (items: IGebouw[]) => {
    const mergedGebouwen = [...gebouwen]
    items.forEach(gebouw => mergedGebouwen.push({ gebouwId: gebouw.id, naam: gebouw.naam, code: gebouw.code }))
    setGebouwen(mergedGebouwen.sort((a, b) => (a.code ?? '').localeCompare(b.code ?? '')))
    dismissGebouwPanel()

    loadGebouwVges(mergedGebouwen.map(g => g.gebouwId))
  }

  const onSelectVges = (items: IVge[]) => {
    const mergedVges = [...vges]
    items.forEach(vge => mergedVges.push(vge))
    setVges(mergedVges)
    dismissVgePanel()
  }

  const stackTokens: IStackTokens = { padding: '0', childrenGap: '20 20' }

  const commandBarItems = [
    {
      key: 'back',
      iconOnly: true,
      iconProps: { iconName: 'Back', className: 'icon' },
      split: false,
      ariaLabel: 'back',
      onClick: () => navigate(-1)
    }
  ] as ICommandBarItemProps[]

  return (
    <div className="componentWrapper">
      <CommandBar items={commandBarItems} styles={commandBarStyles} />
      <TitleTemplate title=" Onderhoudscontract" subTitle={selectedEntity?.contractOmschrijving ?? ''} />
      <div style={{ marginLeft: 20, marginTop: 10, marginRight: 20 }}>
        <SelectGebouwenPanel isOpen={isGebouwPanelOpen} dismissPanel={dismissGebouwPanel}
                             onSelect={onSelectGebouwen} excludeIds={gebouwIds} />
        <SelectVgesPanel
          searchType={searchVgeType}
          isOpen={isVgePanelOpen}
          dismissPanel={dismissVgePanel}
          onSelect={onSelectVges}
          gebouwIds={gebouwIds}
          excludeIds={vgeIds}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack horizontal horizontalAlign={'space-between'} tokens={stackTokens}>
            <StackItem grow>
              <InputCard width={'auto'}>
                <div
                  style={{
                    borderBottomWidth: 1,
                    borderColor: 'rgb(219, 219, 219)',
                    borderBottomStyle: 'solid'
                  }}
                >
                  {supplier ? (
                    <ReadOnlyField labelWidth={215} title="Contractpartij"
                                   value={supplier.handelsnaam} fieldWidth={300} />
                  ) : (
                    <>
                      <h2>Zoeken contractpartij</h2>
                      <Sticky stickyPosition={StickyPositionType.Header}>
                        <SearchBox
                          placeholder="Zoek op naam"
                          styles={{ root: { maxWidth: '400px' } }}
                          onSearch={e => updateSearchTermContractPartij(e)}
                          onClear={_ => updateSearchTermContractPartij(undefined)}
                          value={searchTermContractPartij}
                          onChange={e => filterOnChangeContractPartij(e?.currentTarget.value)}
                          autoComplete="off"
                        />
                      </Sticky>
                      {searchTermContractPartij &&
                        <DetailsList items={suppliers} selection={selectionSupplier}
                                     columns={columnsSupplier} />}
                      <br />
                    </>
                  )}
                </div>

                <VgtTextField
                  label="Contractnummer"
                  name={getPropertyName<IOnderhoudsContract>('contractNummer')}
                  register={register}
                  control={control}
                  errors={errors}
                />
                <VgtTextField
                  label="Contractomschrijving"
                  name={getPropertyName<IOnderhoudsContract>('contractOmschrijving')}
                  register={register}
                  control={control}
                  errors={errors}
                />
                <VgtTextField
                  label="Contractbeheerder"
                  name={getPropertyName<IOnderhoudsContract>('contractBeheerder')}
                  register={register}
                  control={control}
                  errors={errors}
                />
                <VgtCheckbox
                  label="Voor volledige bezit"
                  name={getPropertyName<IOnderhoudsContract>('voorVolledigBezit')}
                  register={register}
                  control={control}
                  errors={errors}
                />
              </InputCard>
            </StackItem>
            <StackItem grow>
              <Stack grow verticalAlign={'end'}>
                <StackItem>
                  <InputCard width={'auto'}>
                    <Controller
                      name={getPropertyName<IOnderhoudsContract>('ingangsDatum')}
                      register={register}
                      control={control}
                      errors={errors}
                      render={({ onChange, value }) => <HorizontalDatePicker label="Ingangsdatum"
                                                                             onChange={onChange}
                                                                             value={parseDate(value)} />}
                    />
                    <Controller
                      name={getPropertyName<IOnderhoudsContract>('eindDatum')}
                      register={register}
                      control={control}
                      errors={errors}
                      render={({ onChange, value }) => <HorizontalDatePicker label="Einddatum"
                                                                             onChange={onChange}
                                                                             value={parseDate(value)} />}
                    />
                    <Controller
                      name={getPropertyName<IOnderhoudsContract>('uitersteOpzegDatum')}
                      register={register}
                      control={control}
                      errors={errors}
                      render={({ onChange, value }) => <HorizontalDatePicker
                        label="Uiterste opzegdatum"
                        onChange={onChange}
                        value={parseDate(value)} />}
                    />
                    <Controller
                      name={getPropertyName<IOnderhoudsContract>('notificatieDatum')}
                      register={register}
                      control={control}
                      errors={errors}
                      render={({ onChange, value }) => (
                        <HorizontalDatePicker label="Notificatiedatum"
                                              onChange={onChange}
                                              value={parseDate(value)}
                                              onClear={onClearNotificatieDatum} />
                      )}
                    />
                  </InputCard>
                </StackItem>
                <StackItem style={{ marginTop: 10 }} align={'end'}>
                  <DefaultButton text="Opslaan" type="submit" primary disabled={updateStatus === 'pending'}
                                 onClick={handleSubmit(onSubmit)} />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>

          <Pivot>
            {!volledigBezit && <PivotItem key="gebouwen" headerText="Gebouwen" itemCount={gebouwen.length}>
              <CommandBar items={selectGebouwCommandBarItems} styles={commandBarStyles} />
              <div className={classNames.containerStyles}>
                <ScrollablePane scrollbarVisibility="auto">
                  <DetailsList
                    items={gebouwen}
                    selection={selectionGebouwen}
                    columns={columnGebouwen}
                  />
                </ScrollablePane>
              </div>
            </PivotItem>}
            {!volledigBezit &&
              <PivotItem key="VgesMetAdres" headerText="Adresseerbaar" itemCount={sortedVgesMetAdres.length}>
                <CommandBar items={selectVgeMetAdresCommandBarItems} styles={commandBarStyles} />
                <div className={classNames.containerStyles}>
                  <ScrollablePane scrollbarVisibility="auto">
                    <DetailsList
                      items={sortedVgesMetAdres}
                      selection={selectionVgesMetAdres}
                      columns={columnVgesMetAdres}
                      selectionMode={SelectionMode.multiple}
                    />
                  </ScrollablePane>
                </div>
              </PivotItem>}
            {!volledigBezit && <PivotItem key="VgesZonderAdres" headerText="Overig Gebouwd"
                                          itemCount={sortedVgesZonderAdres.length}>
              <CommandBar items={selectVgeZonderAdresCommandBarItems} styles={commandBarStyles} />
              <div className={classNames.containerStyles}>
                <ScrollablePane scrollbarVisibility="auto">
                  <DetailsList
                    items={sortedVgesZonderAdres}
                    selection={selectionVgesZonderAdres}
                    columns={columnVgesZonderAdres}
                    selectionMode={SelectionMode.multiple}
                  />
                </ScrollablePane>
              </div>
            </PivotItem>}
            {!volledigBezit && <PivotItem key="terreinen" headerText="Terreinen" itemCount={0}>
              <CommandBar items={selectVgeTerreinCommandBarItems} styles={commandBarStyles} />
              <div className={classNames.containerStyles}>
                <ScrollablePane scrollbarVisibility="auto">
                  <DetailsList
                    items={[]}
                    selection={selectionVgesTerrein}
                    columns={columnVgesTerrein}
                    selectionMode={SelectionMode.multiple}
                  />
                </ScrollablePane>
              </div>
            </PivotItem>}
            <PivotItem key="onderhoudstaken" headerText="Onderhoudstaken" itemCount={taken.length}>
              <CommandBar items={taakCommandbarItems} styles={commandBarStyles} />
              <SelectTaakPanel isOpen={isTaakPanelOpen} dismissPanel={dismissTaakPanel}
                               onSelect={onSelectTaken} excludeIds={taken.map(t => t.tenantTaakId)} />
              <div className={classNames.containerStyles}>
                <ScrollablePane scrollbarVisibility="auto">
                  <DetailsList
                    items={taken}
                    selection={selectionTaken}
                    columns={taakGridColumns}
                    selectionMode={SelectionMode.multiple}
                  />
                </ScrollablePane>
              </div>
            </PivotItem>
            {selectedEntity && (
              <PivotItem key="onderhoudsContract_documenten" headerText="Media" itemCount={documentCount}
                         itemKey="documents">
                <DocumentList doelType={DocumentDoelType.onderhoudsContract} doelId={selectedEntity.id} setDocumentCount={setDocumentCount} />
              </PivotItem>
            )}
          </Pivot>
        </form>
      </div>
    </div>
  )
}

export default ContractEdit
