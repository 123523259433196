import useApi from 'services/UseApi'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { IColumn, IObjectWithKey, ISelection, Selection, SelectionMode } from '@fluentui/react'
import React from 'react'
import { createColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import { useNavigate, useParams } from 'react-router-dom'
import { AvailableRoutes } from 'AppRouter'
import { IDimensiewaardeList } from './DimensiewaardeApi'
import { IDimensieUpdateOrAdd } from '../dimensies/DimensieApi'
import FluentTable from 'components/FluentTable'

const DimensiewaardenList = () => {
  const navigate = useNavigate()
  const { dimensieId } = useParams()
  if (!parseInt(dimensieId ?? '')) {
    throw new Error('Invalid dimensie id')
  }
  const apiRootUrl = `dimensies/${dimensieId}/waarden`

  const { state: dimensieState } = useApi<IDimensieUpdateOrAdd>('dimensies/' + dimensieId, 'GET')
  const { state, execute: fetchWaarden } = useApi<IDimensiewaardeList[]>(apiRootUrl, 'GET')
  const { execute: deleteDimensies } = useApi<IDimensiewaardeList[]>(apiRootUrl, 'DELETE', false)

  const [selection] = React.useState<ISelection>(() => {
    return new Selection<IObjectWithKey>({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selection.count
    })
  })

  const navigateToEdit = (id: string) => {
    navigate(AvailableRoutes.Dimensiewaarden_Edit.replace(':id', id).replace(':dimensieId', dimensieId ?? ''))
  }

  const deleteSelected = () => {
    const selected = selection.getSelection()
    if (selected.length > 0) {
      const ids = selected.map((item) => (item as IDimensiewaardeList).id)
      deleteDimensies({ itemsToDelete: ids }, fetchWaarden)
    }
  }

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    {
      text: 'Toevoegen',
      onClick: () => navigate(AvailableRoutes.Dimensiewaarden_New.replace(':dimensieId', dimensieId ?? '')),
      icon: 'Add',
      iconOnly: true,
      disabled: dimensieState.data?.vgtDimensieId === 4 || dimensieState.data?.vgtDimensieId === 5
    },
    {
      text: 'Verwijderen',
      onClick: () => deleteSelected(),
      icon: 'Delete',
      iconOnly: true,
      disabled: dimensieState.data?.vgtDimensieId === 4 || dimensieState.data?.vgtDimensieId === 5
    }
  ]

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Code', getPropertyName<IDimensiewaardeList>('code'), 'L', true),
      createColumn('Omschrijving', getPropertyName<IDimensiewaardeList>('omschrijving'), 'XL')
    ]
  }, [])

  return (
    <MainTemplate title={'Dimensiewaarden voor dimensie ' + dimensieState.data?.omschrijving} subTitle='overzicht'
                  commandItems={commandItems} error={state.error}>
      <div style={{ display: 'flex', height: '70vh' }}>
        <FluentTable columns={columns} items={state.data?.items ?? []} onItemInvoked={(item) => navigateToEdit(item)} onGetKey={(i) => i.id} />
      </div>
    </MainTemplate>
  )
}

export default DimensiewaardenList