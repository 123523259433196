import VgtTextField from '../../../components/VgtTextField'
import { getPropertyName } from '../../../lib/interfaceUtils'
import React, { useEffect } from 'react'
import useRoles from '../../../services/UseRoles'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { InspectieSjabloonAddOrUpdateDto } from '../../../api/ApiClient'
import { z } from 'zod'
import InputCard from '../../../components/InputCard'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'

export type CreateUpdateType = z.infer<typeof InspectieSjabloonAddOrUpdateDto>;

export interface IEditPanelProps {
  onderhoudssjablonId: number,
  data?: CreateUpdateType,
  isLoading: boolean,
  isSubmitting: boolean,
  onSubmit: (values: CreateUpdateType) => void,
  onCancel: () => void
  onError?: (message: string) => void
}


export const InspectieSjabloonForm: React.FC<IEditPanelProps> = props => {

  const { isInspectie } = useRoles()

  const defaultOnderhoudssjablon = {
    naam: ''
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(InspectieSjabloonAddOrUpdateDto), mode: 'all', defaultValues: defaultOnderhoudssjablon
  })

  useEffect(() => {
    if (props.data) {
      reset(props.data)
    } else {
      reset(defaultOnderhoudssjablon)
    }
  }, [props.data])

  const FieldErrorsToMessage = (errors: any) => {
    return errors.naam ? errors.naam.message : undefined;
  }

  return (

    <form onSubmit={handleSubmit(props.onSubmit, (data) => {
      if (props.onError) props.onError(FieldErrorsToMessage(data))
    })}>
      <ErrorMessageBar error={FieldErrorsToMessage(errors)} />
      <InputCard title="Inspectiesjabloon" boldTitle={true} width={'calc(100%)'}>
        <VgtTextField
          label="Naam"
          name={getPropertyName<CreateUpdateType>('naam')}
          register={register}
          control={control}
          errors={errors}
          required
          readOnly={!isInspectie}
          defaultValue={defaultOnderhoudssjablon.naam}
        />
        <br />
        {isInspectie ?
          <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                               onOkClick={() => handleSubmit(props.onSubmit)}
                               onCancelClick={props.onCancel} /> : null
        }
      </InputCard>
    </form>

  )
}