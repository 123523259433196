import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState';

export interface IImportOption {
  id: number
  name: string
  uploadEndpoint: string
  isExport: boolean
}

const entityAdapter = createEntityAdapter<IImportOption>({
  selectId: i => (i.id)
})

const baseUrl = '/Import'
const basePrefix = 'Import'
const getSliceState = (state: RootState) => state.imports

export const fetchAllImportTypes = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
  return await invokeFetch<IImportOption[]>(thunkAPI, 'GET', `${baseUrl}`)
})

export const sendSyncVhesCommand = createAsyncThunk(`${basePrefix}/syncvhes`, async (_, thunkAPI) => {
  return await invokeFetch<IImportOption[]>(thunkAPI, 'POST', `${baseUrl}/syncvhes`, {syncType: "SyncVhe"})
})

const setPendingState = (state: IEntityAdapterState) => {
  state.error = null
  state.status = 'pending'
}
const setSucceededState = (state: IEntityAdapterState) => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state: IEntityAdapterState, action) => {
  state.status = 'failed'
  state.error = action.error.message || null
}


export const Import = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
    clearState: state => {
      state.error = null
      state.status = "idle"
    },
    select: (state, action: PayloadAction<string | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
  },

  extraReducers: builder => {
    builder.addCase(fetchAllImportTypes.pending, state => {})
    builder.addCase(fetchAllImportTypes.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload)
    })
    builder.addCase(fetchAllImportTypes.rejected, (state, action) => setRejectedState(state, action))
    builder.addCase(sendSyncVhesCommand.pending, state => setPendingState(state))
    builder.addCase(sendSyncVhesCommand.fulfilled, (state, action) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload)
    })
    builder.addCase(sendSyncVhesCommand.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const getSelectedEntity = (state: RootState) => {
  const selectedId = getSliceState(state).selectedId
  if (selectedId) return getSliceState(state).entities[selectedId]
  else return undefined
}

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}

export const getErrorState = (state: RootState) => {
  return getSliceState(state).error
}

export const { add, modify, removeMany, select, clearSelection, clearState } = Import.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.imports)

export default Import.reducer
