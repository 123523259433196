import { IColumn, SelectionMode } from "@fluentui/react"
import FluentTable from "components/FluentTable"
import CommandBarTemplate from "containers/pages/PageTemplates/CommandBarTemplate"
import { createColumn } from "lib/gridHelper"
import { getPropertyName } from "lib/interfaceUtils"
import { useState } from "react"
import { IKadastraalObject } from "../kadastraalObjectSlice"
import { IVve } from "../vveSlice"

const columns: IColumn[] = [
    createColumn('Kadastrale aanduiding', getPropertyName<IKadastraalObject>('kadastraleAanduiding'), 'L'),
    createColumn('Nr. Gerechtigde', getPropertyName<IKadastraalObject>('gerechtigdeNummer'), 'M'),
]

export const AppartementsRechtenCard: React.FC<{ vve?: IVve, onDisconnect: (bronnen: string[]) => void, onOpenPanel: () => void }> = ({ vve, onDisconnect, onOpenPanel }) => {
    const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
    const commandItems = [
        { text: 'Koppelen', onClick: () => onOpenPanel(), icon: 'PlugConnected', iconOnly: true },
        { text: 'Ontkoppelen', onClick: () => onDisconnect(selectedItems), icon: 'PlugDisconnected', iconOnly: true, disabled: !selectedItems.length },
    ];

    return <div style={{ height: '80vh' }}>
        <CommandBarTemplate items={commandItems}></CommandBarTemplate>
        <FluentTable
            columns={columns}
            items={vve?.appartmentsrechten ?? []}
            selectionMode={SelectionMode.multiple}
            onSelectionChanged={(ids: string[]) => setSelectedItems(ids)}
            onGetKey={(item: IKadastraalObject) => item.kadastraleAanduiding}
            onItemInvoked={(id: string) => { }}
        />
    </div>
} 