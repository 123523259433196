import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { ISuggestResult } from 'services/georegisterApi'

const entityAdapter = createEntityAdapter<ISuggestResult>({
  selectId: e => e.id,
  sortComparer: (a, b) => a.id.localeCompare(b.id),
})

export interface IBasisregistratiesSearchParameters {
  searchValue: string
  maxNumberOfRows: string
  searchType: string
}

export const basisregistratiesSlice = createSlice({
  name: 'basisregistraties',
  initialState: entityAdapter.getInitialState({ searchValue: '', maxNumberOfRows: '20', searchType: '*' } as IBasisregistratiesSearchParameters),

  reducers: {
    setSearchParameters: (state, action: PayloadAction<IBasisregistratiesSearchParameters>) => {
      state.maxNumberOfRows = action.payload.maxNumberOfRows
      state.searchValue = action.payload.searchValue
      state.searchType = action.payload.searchType
    },
    setAll: (state, action) => {
      entityAdapter.setAll(state, action.payload)
    },
  },
})

export const selectSearchParameters = (state: RootState) => {
  return {
    searchValue: state.basisregistraties.searchValue,
    maxNumberOfRows: state.basisregistraties.maxNumberOfRows,
    searchType: state.basisregistraties.searchType,
  } as IBasisregistratiesSearchParameters
}
export const { setAll, setSearchParameters } = basisregistratiesSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.basisregistraties)

export default basisregistratiesSlice.reducer
