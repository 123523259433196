import { DefaultButton, DetailsList, Panel, PanelType, SearchBox, Selection, SelectionMode, Spinner, SpinnerSize, Stack, Sticky, StickyPositionType } from "@fluentui/react"
import { createColumn } from "lib/gridHelper"
import { getPropertyName } from "lib/interfaceUtils"
import { debounce } from "lodash"
import React from "react"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"
import { setAll, fetchKadastraleObjecten, getLoadingState, selectAll, clearSelection, IKadastraalObject } from "./../kadastraalObjectSlice"
import { IVve } from "./../vveSlice"

export const VveAptPanel: React.FC<{ vve?: IVve, isOpen: boolean, dismissPanel: () => void, addAppartementsIndexen: (itemsToAdd: any) => void }> = ({ isOpen, dismissPanel, addAppartementsIndexen }) => {
    const [currentSearchValue, setCurrentSearchValue] = useState<string | undefined>('')
    const dispatch = useAppDispatch()
    const loadingState = useSelector(getLoadingState)
    const kadastraleObjecten = useSelector(selectAll)
    const [koppelDisabled, setKoppelDisabled] = useState(true);

    const onSearchClicked = (newValue: string) => {
        dispatch(fetchKadastraleObjecten({ filter: newValue }))
    }

    const [selection] = React.useState<Selection>(() => {
        const s = new Selection({
            selectionMode: SelectionMode.multiple,
            items: kadastraleObjecten,
        onSelectionChanged: () => { setKoppelDisabled(selection.count === 0); return selection.count },
            getKey: item => item.kadastraleAanduiding
        })
        return s
    })

    const delayedSearch = debounce(eventData => dispatch(fetchKadastraleObjecten({ filter: eventData })), 1000)

    const onSearchBoxChanged = (newValue: string | undefined) => {
        setCurrentSearchValue(newValue)
        if (newValue && newValue.length >= 2) {
            delayedSearch(newValue)
        }
    }

    const onKoppel = () => {
        addAppartementsIndexen(selection.getSelection().map(v => v.kadastraleAanduiding))
        cleanUp()
    }

    const cleanUp = () => {
        selection?.setAllSelected(false)
        dispatch(setAll([]))
        dispatch(clearSelection())
        setCurrentSearchValue('')
        dismissPanel()
    }

    const onRenderFooterContent = () => {
        return (
            <>
                    <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
                        <DefaultButton text='Koppelen' disabled={koppelDisabled} type='submit' primary onClick={() => onKoppel()} />
                        <DefaultButton text='Annuleren' onClick={cleanUp} />
                    </Stack>
            </>
        )
    }

    return (
        <Panel type={PanelType.large} headerText={"Koppelen appartementsindexen"}
            isOpen={isOpen} onDismiss={() => cleanUp()} closeButtonAriaLabel="Sluiten"
            onRenderFooterContent={onRenderFooterContent}>

            <Sticky stickyPosition={StickyPositionType.Header}>
                <SearchBox
                    placeholder="Zoeken"
                    onSearch={onSearchClicked}
                    onChanged={onSearchBoxChanged}
                    onClear={() => setCurrentSearchValue('')}
                    value={currentSearchValue}
                    autoComplete="off" />
            </Sticky>
            {loadingState === 'pending' ?
                <Spinner size={SpinnerSize.large} style={{ marginTop: '40vh' }} /> :
                <DetailsList
                    items={kadastraleObjecten}
                    selection={selection}
                    columns={[
                        createColumn('Kadastrale aanduiding object', getPropertyName<IKadastraalObject>('kadastraleAanduiding'), 'M'),
                        createColumn('Kadastrale aanduiding vlak', getPropertyName<IKadastraalObject>('kadastraleAanduidingVlak'), 'M'),
                        createColumn('Gerechtigde', getPropertyName<IKadastraalObject>('gerechtigdeNaam'), 'L'),
                        createColumn('Gerechtigde adres', getPropertyName<IKadastraalObject>('gerechtigdeAdres'), 'L'),
                    ]}
                ></DetailsList>
            }
        </Panel>)
}