import { EntityId } from "@reduxjs/toolkit";

export interface IEntityAdapterState {
  selectedId: EntityId | undefined
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
  searchFilter: string | undefined
}

export const entityAdapterInitState: IEntityAdapterState = {
  selectedId: undefined,
  status: 'idle',

  error: null,
  searchFilter: undefined,
}
