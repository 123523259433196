import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, createSelectorHook } from 'react-redux'
import rootReducer from './rootReducer'

const store = configureStore({
  reducer: rootReducer
})

export type RootState = ReturnType<typeof store.getState>

// Type the useDispatch hook
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

// Type the useSelector hook
export const useTypedSelector = createSelectorHook<RootState>()

export { store }
