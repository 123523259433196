import { IDropdownOption, Panel, PanelType } from '@fluentui/react'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import { getPropertyName } from '../../../lib/interfaceUtils'
import VgtDecimalField from '../../../components/VgtDecimalField'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'
import React from 'react'
import { z } from 'zod'
import { TenantInspectietaakAddOrUpdateDto, zodiosHooks } from '../../../api/ApiClient'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { getTitleAndMessage } from '../../../services/HandleError'
import VgtTextField from '../../../components/VgtTextField'
import VgtCheckbox from '../../../components/FluentCheckbox'
import useRoles from '../../../services/UseRoles'
import VgtDropdown from '../../../components/FluentDropdown'
import { TenantInspectietakenListDtoType } from './TenantInspectietakenList'

export interface IEditPanelProps {
  inspectietaak: TenantInspectietakenListDtoType | undefined,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof TenantInspectietaakAddOrUpdateDto>;

export const TenantInspectietakenFormPanel: React.FC<IEditPanelProps> = props => {

  const { isInspectie } = useRoles()
  const [error, setError] = React.useState<string>()

  const defaultTenantInspectietaken = {
    tenantInspectietaakId: props.inspectietaak?.tenantInspectietaakId ?? -1,
    inspectietaakId: -1,
    actief: props.inspectietaak?.actief ?? false,
    verkoopprijs: undefined,
    inkoopprijs: undefined,
    onderhoudsspecialismeId: -1,
    onderhoudstaakErpId: undefined,
    bewerkingstijdMin: 0,
    opmerkingHuurder: '',
    kostensoortId: -1
  } as CreateUpdateType

  const {
    control,
    handleSubmit,
    reset,
    register,
    watch,
    formState: { errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(TenantInspectietaakAddOrUpdateDto), mode: 'all', defaultValues: defaultTenantInspectietaken
  })

  const actief = watch('actief')

  const { data: inspectietaken } = zodiosHooks.useGetApiInspectietaak({})
  const taakOpties = inspectietaken?.items?.map((item) => {
    return {
      key: item.inspectietaakId,
      text: item.taak
    } as IDropdownOption
  })

  zodiosHooks.useGetApiTenantInspectietaakId({ params: { id: props.inspectietaak?.inspectietaakId ?? 0 } }, {
      onSuccess: (data) => reset(data),
      enabled: props.inspectietaak !== undefined && props.inspectietaak?.inspectietaakId !== 0
    }
  )

  const { mutate: addTenantInspectietaak, isLoading: isAdding } = zodiosHooks.usePostApiTenantInspectietaak({}, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const {
    mutate: updateTenantInspectietaak,
    isLoading: isModifying
  } = zodiosHooks.usePutApiTenantInspectietaakId({ params: { id: props.inspectietaak?.tenantInspectietaakId ?? 0 } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const onCancel = () => {
    props.invalidate()
    reset({})
    setError(undefined)
    props.dismissPanel()
  }

  const onSubmit = (data: CreateUpdateType) => {
    setError(undefined)
    if (props.inspectietaak?.tenantInspectietaakId !== 0) {
      updateTenantInspectietaak(data)
    } else {
      addTenantInspectietaak({ ...data, inspectietaakId: props.inspectietaak?.inspectietaakId ?? 0 })
    }
  }

  const { data: onderhoudsspecialisme } = zodiosHooks.useGetOnderhoudsspecialisme()

  const onderhoudsspecialismeOpties = onderhoudsspecialisme?.items?.map((item) => {
    return { key: item.onderhoudsspecialismeId, text: `${item.code} - ${item.naam}` }
  })
  const { data: onderhoudstaakErp } = zodiosHooks.useGetOnderhoudsTaakErp()

  const onderhoudstaakErpOpties = onderhoudstaakErp?.items?.map((item) => {
    return { key: item.onderhoudstaakErpId, text: `${item.code} - ${item.omschrijving}` }
  })

  const { data: kostensoort } = zodiosHooks.useGetKostensoort()

  const kostensoortOpties = kostensoort?.items?.map((item) => {
    return { key: item.kostensoortId, text: `${item.code} - ${item.omschrijving}` }
  })
  return (
    <Panel type={PanelType.large}
           headerText={`Inrichten inspectietaken -  ${props.inspectietaak?.taak} (${props.inspectietaak?.inspectietaakId})`}
           isOpen={props.isOpen} onDismiss={onCancel} closeButtonAriaLabel='Sluiten'>
      <ErrorMessageBar error={error} />
      <div style={{ marginBottom: 16, borderBottom: '1px solid rgb(207, 207, 207)' }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <VgtDropdown
          showIf={false}
          label='Taak'
          name={getPropertyName<CreateUpdateType>('inspectietaakId')}
          register={register}
          control={control}
          errors={errors}
          options={taakOpties}
          defaultSelectedKey={props.inspectietaak?.inspectietaakId}
        />
        <VgtTextField
          disabled={!actief}
          readOnly
          label='Bestekcode'
          name={'bestekcode'}
          register={register}
          control={control}
          errors={errors}
          defaultValue={props.inspectietaak?.bestekcode ?? ''}
        />
        <VgtCheckbox label='Actief' name={getPropertyName<CreateUpdateType>('actief')} register={register}
                     control={control} errors={errors} />
        <VgtDecimalField label='Verkoopprijs (incl. btw)' name={getPropertyName<CreateUpdateType>('verkoopprijs')}
                         control={control}
                         errors={errors} defaultValue={0} />
        <VgtDecimalField label='Inkoopprijs (excl. btw)' name={getPropertyName<CreateUpdateType>('inkoopprijs')}
                         control={control}
                         errors={errors} defaultValue={0} />
        <VgtDropdown
          required
          label='Onderhoudsspecialisme'
          name={getPropertyName<CreateUpdateType>('onderhoudsspecialismeId')}
          register={register}
          control={control}
          errors={errors}
          options={onderhoudsspecialismeOpties as IDropdownOption[]}
        />
        <VgtDropdown
          label='Onderhoudstaak ERP'
          name={getPropertyName<CreateUpdateType>('onderhoudstaakErpId')}
          register={register}
          control={control}
          errors={errors}
          options={onderhoudstaakErpOpties as IDropdownOption[]}
        />
        <VgtDecimalField
          required
          label='Bewerkingstijd (min)'
          name={getPropertyName<CreateUpdateType>('bewerkingstijdMin')}
          register={register}
          control={control}
          errors={errors}
          defaultValue={0}
        />
        <VgtTextField
          label='Opmerking (huurder)'
          name={getPropertyName<CreateUpdateType>('opmerkingHuurder')}
          register={register}
          control={control}
          errors={errors}
          multiline={true}
        />
        <VgtDropdown
          required
          label='Kostensoort'
          name={getPropertyName<CreateUpdateType>('kostensoortId')}
          register={register}
          control={control}
          errors={errors}
          options={kostensoortOpties as IDropdownOption[]}
        />
        <OkCancelButtonStack isSubmitting={isModifying || isAdding} isWriter={isInspectie}
                             isLoading={false}
                             onOkClick={handleSubmit(onSubmit, (errors) => {
                               setError('Kan niet opslaan vanwege validatiefouten op de invoer.')
                               console.log(errors)
                             })}
                             onCancelClick={onCancel} />
      </form>
    </Panel>
  )
}