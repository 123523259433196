import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { entityAdapterInitState } from 'interfaces/entityAdapterState'
import { setPendingState, setRejectedState, setSucceededState } from 'lib/sliceSupport'
import { RootState } from 'store'

export interface IVerwerking {
    type: string
    datum: string
}

export interface IStatistics {
    totalNumberOfVges: number
    totalNumberOfVhes: number
    totalNumberOfPercelen: number
    vgesIngedeeld: number
    vgesMetKadastraalObject: number
    vgesMetVerhuureenheid: number
    verwerkingen: IVerwerking[]
}

const entityAdapter = createEntityAdapter<IStatistics>()

export const fetchStatistics = createAsyncThunk('statistics/fetch', async (_, thunkAPI) => {
    return await invokeFetch<IStatistics>(thunkAPI, 'GET', '/statistics')
})

export const statisticsSlice = createSlice({
    name: 'statistics',
    initialState: entityAdapter.getInitialState(entityAdapterInitState),

    reducers: {
        select: (state, action: PayloadAction<string | undefined>) => {
            state.selectedId = action.payload
        },
    },

    extraReducers: builder => {
        builder.addCase(fetchStatistics.pending, state => setPendingState(state))
        builder.addCase(fetchStatistics.fulfilled, (state, action: PayloadAction<IStatistics>) => {
            setSucceededState(state)
            entityAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(fetchStatistics.rejected, (state, action) => setRejectedState(state, action))
    }
})

export const { selectAll } = entityAdapter.getSelectors<RootState>(state => state.statistics)

export default statisticsSlice.reducer
