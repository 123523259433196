import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import React, { useMemo, useState } from 'react'
import { availableUrls } from '../../../routing/AvailableUrls'
import { useNavigate } from 'react-router-dom'
import useRoles from '../../../services/UseRoles'
import { HuurcontractbepalingListDto, zodiosHooks } from '../../../api/ApiClient'
import { usePagedParams } from '../../../services/usePagedParams'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../services/HandleError'

type HuurcontractbepalingListDtoType = z.infer<typeof HuurcontractbepalingListDto>;

export const HuurcontractbepalingList = () => {
  const navigate = useNavigate()
  const { isVerhuur } = useRoles()
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [error, setError] = React.useState<string>()
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const { data, invalidate, isLoading } = zodiosHooks.useGetApiHuurcontractbepaling({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteApiHuurcontractbepaling({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
    {
      text: 'Toevoegen',
      onClick: () => navigate(availableUrls.Huurcontractbepaling_New),
      icon: 'Add',
      iconOnly: true,
      visible: isVerhuur
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        deleteRows({ itemsToDelete: selectedIds })
      },
      icon: 'Delete',
      iconOnly: true,
      disabled: isDeleting || selectedIds.length === 0
    }
  ], [isVerhuur, selectedIds.length])

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Huurcontractbepalingen', getPropertyName<HuurcontractbepalingListDtoType>('naam'), 'XL', true,
        (item: HuurcontractbepalingListDtoType) => (
          <Link key={item.huurcontractbepalingId}
                onClick={() => navigate(availableUrls.Huurcontractbepaling_Edit.replace(':id', item.huurcontractbepalingId.toString()))}>
            {item.naam}
          </Link>
        ))
    ]
  }, [])

  return (
    <MainTemplate title="Huurcontractbepalingen" subTitle="Overzicht" commandItems={commandItems} error={error}>
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount || 0,
          totalPages: data?.totalPages || 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.huurcontractbepalingId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => navigate(availableUrls.Huurcontractbepaling_Edit.replace(':id', e.huurcontractbepalingId.toString()))}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}