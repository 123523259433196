import { createColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import useRoles from 'services/UseRoles'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBoolean } from '@fluentui/react-hooks'
import FluentTable from 'components/FluentTable'
import { Link, SelectionMode } from '@fluentui/react'
import EditPanelMateriaalSoort from './editPanel'
import ICoMoonFontIcon from '../../../../components/IcoMoon'
import { MateriaalSoortDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { z } from 'zod'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

type MateriaalSoortDtoType = z.infer<typeof MateriaalSoortDto>;

const MateriaalSoortList: React.FC<{}> = () => {
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const [selectedMateriaalSoortenPageItem, setSelectedMateriaalSoortenPageItem] = useState<MateriaalSoortDtoType | undefined>()
  const [error, setError] = useState<string>()

  const {
    data: materiaalSoortenpageItems,
    invalidate: fetchmateriaalSoortenpageItems,
    isLoading: isFetchingMateriaalSoortenpageItems
  } = zodiosHooks.useGetMateriaalSoortenpage({}, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: deleteMateriaalSoorten } = zodiosHooks.useDeleteMateriaalSoorten({}, {
    onSuccess: () => fetchmateriaalSoortenpageItems(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const handleListSelectionChange = (ids: string[]) => setSelectedItems(ids)
  const refreshItems = () => fetchmateriaalSoortenpageItems()
  const onGetKey = (item: MateriaalSoortDtoType) => item.id.toString()
  const { isAdmin } = useRoles()

  const handleItemInvoked = (id: string) => {
    setSelectedMateriaalSoortenPageItem(materiaalSoortenpageItems?.items?.find(item => item.id === parseInt(id)))
    openPanel()
  }

  const removeItems = () => {
    deleteMateriaalSoorten(selectedItems.map(value => parseInt(value)))
  }

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
      {
        visible: isAdmin,
        text: 'Toevoegen',
        onClick: () => {
          setSelectedMateriaalSoortenPageItem(undefined)
          openPanel()
        },
        icon: 'Add'
      },
      {
        visible: isAdmin,
        text: 'Verwijderen',
        onClick: () => removeItems(),
        icon: 'Delete',
        disabled: !selectedItems.length
      }
    ],
    [isAdmin, selectedItems.length]
  )

  const columns = [
    createColumn('Materiaalsoort', getPropertyName<MateriaalSoortDtoType>('naam'), 'L', false, (item: MateriaalSoortDtoType) => (
      <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
        {item.naam}
      </Link>
    )),
    {
      key: 'fonts-icon',
      name: 'Fonticoon',
      fieldName: getPropertyName<MateriaalSoortDtoType>('fonticoonId'),
      minWidth: 70,
      maxWidth: 70,
      isResizable: false,
      isRowHeader: false,
      onRender: (item: MateriaalSoortDtoType) => <ICoMoonFontIcon iconName={item?.fonticoonNaam!} />
    }
  ]

  return (
    <MainTemplate title='Materiaalsoorten' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <EditPanelMateriaalSoort selectedMateriaalSoortenPageItem={selectedMateriaalSoortenPageItem}
                               fetchmateriaalSoortenpageItems={fetchmateriaalSoortenpageItems}
                               dismissPanel={dismissPanel} isOpen={isPanelOpen} />
      <div style={{ height: '90vh' }}>
        <FluentTable
          columns={columns}
          items={materiaalSoortenpageItems?.items ?? []}
          loading={isFetchingMateriaalSoortenpageItems}
          selectionMode={SelectionMode.multiple}
          onSelectionChanged={handleListSelectionChange}
          onGetKey={onGetKey}
          onItemInvoked={handleItemInvoked}
        />
      </div>
    </MainTemplate>
  )
}

export default MateriaalSoortList
