import { zodiosHooks, GebouwDefectlocatieAddorUpdateDto } from 'api/ApiClient'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { DefaultButton, Panel, PanelType, Stack } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { GetSelectedEntity } from './collectieveDefectlocaties'
import { getPropertyName } from 'lib/interfaceUtils'
import VgtDropdown from 'components/FluentDropdown'
import VgtControlGroup from 'components/ControlGroup'
import VgtTextField from 'components/VgtTextField'

type CreateUpdateType = z.infer<typeof GebouwDefectlocatieAddorUpdateDto>

export const DefectlocatieEditPanel = ({ isOpen, onCancel }: {
  isOpen: boolean,
  onCancel: () => void
}) => {

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(GebouwDefectlocatieAddorUpdateDto), mode: 'all'
  })

  const selectedEntity = GetSelectedEntity()

  const { data: defectlocatiesData } = zodiosHooks.useGetDefectLocatiedropdownoptions({})

  const { data: rechtpersoonData } = zodiosHooks.useGetRechtspersonendropdownoptions({
    queries: {
      filter: ''
    }
  })

  const { mutate } = zodiosHooks.usePostGebouwenIddefectlocaties(
    {
      params: { id: selectedEntity.gebouwId.toString() }
    }, {
      onSuccess: (response) => {
        reset(response)
        onCancel()
      },
      onError: (error) => {
        alert(error)
      }
    })

  const submit = (data: CreateUpdateType) => {
    mutate(data)
  }

  const onPanelOpen = () => {
    reset(selectedEntity)
  }

  return (
    <Panel type={PanelType.medium} headerText={'Toevoegen of wijzigen van gebouw defectlocatie'} isOpen={isOpen} onDismiss={onCancel} onOpen={onPanelOpen} closeButtonAriaLabel="Sluiten">
      <form onSubmit={handleSubmit(submit)}>
        <VgtControlGroup name="group1" label="Defectlocatie">
          <VgtTextField showIf={false} label="gebouwId" readOnly={true} errors={errors} register={register} control={control} name={getPropertyName<CreateUpdateType>('gebouwId')} />
          <VgtTextField showIf={false} label="gebouwDefectlocatieId" readOnly={true} errors={errors} register={register} control={control} name={getPropertyName<CreateUpdateType>('gebouwDefectlocatieId')} />
          <VgtDropdown label={'Defectlocatie'} name={getPropertyName<CreateUpdateType>('defectlocatieId')} errors={errors}
                       options={defectlocatiesData} register={register} control={control} required={true} />
          <VgtDropdown label={'Beheerder'} name={getPropertyName<CreateUpdateType>('beheerderId')} errors={errors}
                       options={rechtpersoonData} register={register} control={control} required={true} />
          <VgtTextField label="Externe referentie" name={getPropertyName<CreateUpdateType>('externeReferentie')}
                        register={register} control={control} errors={errors} />
          <VgtTextField label="Locatieaanduiding" name={getPropertyName<CreateUpdateType>('locatieaanduiding')}
                        register={register} control={control} errors={errors} />
        </VgtControlGroup>
        <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
          <DefaultButton
            text="Opslaan"
            type="submit"
            primary
            disabled={isSubmitting}
            onClick={
              handleSubmit(
                submit,
                (err) => {
                  alert(err)
                }
              )}
          />
          <DefaultButton text="Annuleren" onClick={onCancel} />
        </Stack>
      </form>
    </Panel>
  )
}
