import { AvailableRoles } from '../../services/UseRoles'
import { ICommandItem } from '../../containers/pages/PageTemplates/CommandBarTemplate'

type VgtCommandItem = {
  text: string
  onClick: () => void
  icon?: string
  disabled?: boolean
  visible?: boolean
  allowedRoles?: (keyof typeof AvailableRoles)[]
}

const isAllowed = (userRoles: (keyof typeof AvailableRoles)[], allowedRoles: string[] | undefined) => {
  if (!allowedRoles) return true
  for (const role of userRoles) {
    if (allowedRoles.includes(role)) return true
  }
}

export const createCommandItems = (roles: (keyof typeof AvailableRoles)[], items: VgtCommandItem[]) : ICommandItem[] => {
  const commandItems: ICommandItem[] = []

  for (const item of items) {
    if (!isAllowed(roles, item.allowedRoles) && (item.visible === undefined || item.visible)) continue

    commandItems.push({
      text: item.text,
      icon: item.icon,
      onClick: item.onClick,
      disabled: item.disabled
    })
  }
  return commandItems
}

