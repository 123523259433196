import React, { useEffect, useState } from 'react'
import { PanelType } from '@fluentui/react'
import VgtTextField from '../../../../components/VgtTextField'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { GebouwenClusterDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { FieldErrorsToMessage } from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { useParams } from 'react-router-dom'
import FluentPanel from '../../../../components/FluentPanel/FluentPanel'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

type IGebouwenCluster = z.infer<typeof GebouwenClusterDto>;

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: () => void;
  fetchData: () => void;
  selectedGebouwenCluster?: IGebouwenCluster;
}

const defaultGebouwenCluster = {
  id: 0,
  hoeveelheden: [],
  type: null,
  registratieniveau: '',
  serviceadresVge: 0,
  gebouwenClusterId: null,
  serviceEmailadres: '',
  serviceTelefoonnummer: null,
  huurcontractbepalingen: [],
  vgeCoordinates: [],
  advertentietekst: ''
}

export const GebouwenClusterEditPanel: React.FC<IEditPanelProps> = ({
                                                                      dismissPanel,
                                                                      isOpen,
                                                                      fetchData,
                                                                      selectedGebouwenCluster
                                                                    }) => {
  const [error, setError] = useState<string>()
  const { id } = useParams()

  const { mutate: addGebouwenCluster } = zodiosHooks.usePostGebouwenClusters(
    {}, {
      onSuccess: () => {
        onPanelDismiss()
      }, onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: updateGebouwenCluster } = zodiosHooks.usePutGebouwenClustersId(
    { params: { id: parseInt(id!) } }, {
      onSuccess: () => {
        onPanelDismiss()
      },
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const {
    control,
    register,
    getValues,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<IGebouwenCluster>({
    mode: 'all'
  })

  useEffect(() => {
    if (selectedGebouwenCluster) {
      reset(selectedGebouwenCluster)
    }
  }, [selectedGebouwenCluster])

  const onPanelDismiss = () => {
    fetchData()
    reset(defaultGebouwenCluster)
    setError(undefined)
    dismissPanel()
  }

  const onSubmitClick = () => {
    const data = {...defaultGebouwenCluster, ...selectedGebouwenCluster, ...getValues(), id: selectedGebouwenCluster?.id ?? 0}

    const parseResult = GebouwenClusterDto.safeParse(data)
    if (!parseResult.success) {
      const errors = Object.fromEntries(parseResult.error.issues.map(i => [i.path[0], i.message]))
      setError(FieldErrorsToMessage(errors))
      return
    }

    if (selectedGebouwenCluster) {
      updateGebouwenCluster({ ...selectedGebouwenCluster, ...parseResult.data })
    } else {
      addGebouwenCluster(parseResult.data)
    }
  }

  const headerText = 'Gebouwencluster ' + (selectedGebouwenCluster ? 'wijzigen' : 'toevoegen')

  return (
    <FluentPanel isOpen={isOpen} headerText={headerText} onDismiss={dismissPanel} error={error} type={PanelType.large}>
        <VgtTextField label='Code' name={getPropertyName<IGebouwenCluster>('code')} register={register} control={control} errors={errors} required />
        <VgtTextField label='Naam' name={getPropertyName<IGebouwenCluster>('naam')} register={register} control={control} errors={errors} required />
        <VgtTextField label='Omschrijving' name={getPropertyName<IGebouwenCluster>('omschrijving')} register={register} control={control} errors={errors} />
        <VgtTextField label='Advertentietekst' name={getPropertyName<IGebouwenCluster>('advertentietekst')} register={register} control={control} multiline={true} errors={errors} autoAdjustHeight={true} />

        <FluentPanel.Footer>
          <OkCancelButtonStack isSubmitting={isSubmitting} isWriter={true} isLoading={isSubmitting ?? false} onOkClick={() => onSubmitClick()} onCancelClick={onPanelDismiss} />
        </FluentPanel.Footer>
    </FluentPanel>
  )
}