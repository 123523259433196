import React, { useState, useRef, useEffect } from 'react'
import { Icon, IIconProps, mergeStyleSets, Text } from '@fluentui/react'

interface IInputCardProps {
  title?: string
  boldTitle?: boolean
  className?: string
  width?: string
  height?: string
  maxHeight?: number
  marginTop?: string
  titleIcons?: IIconProps[]
  icon?: string
  children?: React.ReactNode
  contentPaddingLeft?: string | number
  active?: boolean
}

const InputCard: React.FC<IInputCardProps> = ({
  title,
  boldTitle,
  className,
  children,
  width,
  height,
  maxHeight: propMaxHeight,
  marginTop,
  titleIcons,
  icon,
  contentPaddingLeft,
  active = true,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [showToggleIcon, setShowToggleIcon] = useState(false)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const maxHeight = propMaxHeight ?? 400 // default max height limit

  const toggleExpanded = () => setIsExpanded(!isExpanded)

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight
      setShowToggleIcon(contentHeight > maxHeight)
    }
  }, [children]) // Check content height after rendering children

  const classNames = mergeStyleSets({
    inputCardContent: {
      display: 'flex',
      flexDirection: 'column',
      overflow: isExpanded ? 'visible' : 'hidden', // Hide overflow content
      width: '100% - 10px',
      marginTop: icon ? '-45px' : '0px',
      paddingLeft: contentPaddingLeft,
      opacity: active ? 1 : 0.5, // Set opacity based on active prop
      pointerEvents: active ? 'auto' : 'none', // Prevent interaction when not active
      transition: 'height 0.3s', // Smooth transition for height change
    },
    inputCardTitle: {
      marginBlockEnd: '10px',
      fontWeight: boldTitle ? 'bold' : 'normal',
    },
    inputCard: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexShrink: 0,
      border: '1px solid #efefef',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Small shadow
      width: width ?? '398px',
      height: isExpanded ? 'auto' : maxHeight,
      padding: '4px 14px 24px',
      boxSizing: 'border-box',
      marginBottom: 0,
      marginTop: marginTop ?? '0px',
      backgroundColor: 'white', // White background
      borderRadius: '8px', // Rounded edges
    },
    toggleIcon: {
      cursor: 'pointer',
      alignSelf: 'center',
      marginTop: '8px',
    }
  })

  return (
    <div className={`${classNames.inputCard} ${className}`}>
      {title && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text variant="large" className={classNames.inputCardTitle}>
            {title}
          </Text>
          {titleIcons && titleIcons.map((iconProps, index) => (
            <Icon
              key={index} // Use index as key; ideally use a unique identifier if available
              style={{
                height: '20px',
                width: '20px',
                marginLeft: '10px', // Adjust spacing as needed
                cursor: 'pointer',
              }}
              {...iconProps}
            />
          ))}
        </div>
      )}
      {icon ? (
        <img
          alt=""
          style={{
            height: '40px',
            width: '40px',
            zIndex: 1,
            position: 'relative',
            left: '92%',
            top: '-34px',
          }}
          src={icon}
        />
      ) : null}
      <div ref={contentRef} className={classNames.inputCardContent}>
        {children}
      </div>
      {showToggleIcon && (
        <Icon
          className={classNames.toggleIcon}
          iconName={isExpanded ? "ChevronUp" : "ChevronDown"}
          onClick={toggleExpanded}
        />
      )}
    </div>
  )
}

export default InputCard
