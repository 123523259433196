import { useDispatch, useSelector } from 'react-redux'
import { fetchGebouwen, IGebouw, selectAll, clearResult } from 'features/beheer/fysiek/gebouwen/gebouwenSlice'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  DefaultButton,
  DetailsList,
  IColumn,
  mergeStyleSets,
  Panel,
  PanelType,
  SearchBox,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
  Selection, IObjectWithKey
} from '@fluentui/react'
import { debounce } from 'lodash'
import React from 'react'
import { getPropertyName } from 'lib/interfaceUtils'

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  onSelect: (gebouwen: IGebouw[]) => void
  excludeIds: number[]
}

const classNames = mergeStyleSets({
  wrapper: {
    position: 'relative',
    margin: '10px',
  },
  filter: {
    backgroundColor: 'white',
    paddingBottom: 20,
    maxWidth: 300,
  },
  header: {
    margin: 0,
    backgroundColor: 'white',
  },
  row: {
    display: 'inline-block',
  },
})

const createFilteredGebouwen = (gebouwen: IGebouw[], excludeIds: number[]) => {
  return gebouwen.filter(gebouw => excludeIds.findIndex(g => g === gebouw.id) === -1)
}

const SelectGebouwenPanel: React.FC<IEditPanelProps> = props => {
  const unfilteredGebouwen = useSelector(selectAll)
  const gebouwen = useMemo(() => createFilteredGebouwen(unfilteredGebouwen, props.excludeIds), [unfilteredGebouwen, props.excludeIds])
  const dispatch = useDispatch()
  const [currentSearchValue, setCurrentSearchValue] = useState<string>()

  useEffect(() => {
    dispatch(clearResult())
  }, [])

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IGebouw>({ mode: 'onChange' })

  const clearSearch = () => {
    setCurrentSearchValue('')
    dispatch(clearResult())
  }

  const closePanel = () => {
    clearSearch()
    props.dismissPanel()
  }

  const onCancel = e => {
    closePanel()
  }

  useEffect(() => {
    if (props.isOpen) clearSearch()
  }, [props.isOpen])

  const onSubmit = () => {
    if (selection.count > 0) {
      const items = selection.getSelection() as IGebouw[]
      props.onSelect(items)
    }
  }

  const [selection] = React.useState(() => {
    const s = new Selection<IGebouw>({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selection.count,
      getKey: item => item.id,
    })

    return s
  })

  const delayedSearch = debounce(eventData => dispatch(fetchGebouwen({ filter: eventData })), 500)

  const onSearchBoxChanged = (newValue: string | undefined) => {
    setCurrentSearchValue(newValue)
    if (newValue && newValue.length >= 2) {
      delayedSearch(newValue)
    }
  }

  const onClearSearchBox = () => {
    clearSearch()
  }

  const onRenderFooterContent = () => {
    return (
      <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
        <DefaultButton text="Koppelen" type="submit" primary disabled={isSubmitting} onClick={onSubmit} />
        <DefaultButton text="Annuleren" onClick={onCancel} />
      </Stack>
    )
  }

  const columns = [
    {
      name: 'Naam',
      fieldName: getPropertyName<IGebouw>('naam'),
      key: getPropertyName<IGebouw>('naam'),
      minWidth: 50,
      maxWidth: 400,
      isResizable: true,
      onRender: (item: IGebouw) => <span>{item.code} - {item.naam}</span>,
    },
  ] as IColumn[]

  return (
    <Panel
      type={PanelType.medium}
      headerText={'Koppelen gebouwen'}
      isOpen={props.isOpen}
      onDismiss={onCancel}
      closeButtonAriaLabel="Sluiten"
      onRenderFooterContent={onRenderFooterContent}
    >
      <div className={classNames.wrapper}>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <SearchBox placeholder="Zoek op adres" onChanged={onSearchBoxChanged} onClear={onClearSearchBox} value={currentSearchValue} autoComplete="off" />
          </Sticky>

          <DetailsList items={gebouwen} selection={selection as Selection<IObjectWithKey>} columns={columns} />
        </form>
      </div>
    </Panel>
  )
}

export default SelectGebouwenPanel
