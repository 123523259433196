import { PagedEntities } from 'interfaces/pagedEntities';
import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, EntityState, EntityId } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import IEenheidSoort from './eenheidSoortModel'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState';
import { IDropdownOption } from '@fluentui/react'

const entityAdapter = createEntityAdapter<IEenheidSoort>({
  sortComparer: (a, b) => a.code?.localeCompare(b.code)
})

const baseUrl = '/EenheidSoort'
const basePrefix = 'EenheidSoort'
const getSliceState = (state: RootState) => state.eenheidSoorten

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<PagedEntities<IEenheidSoort>>(thunkAPI, 'GET', `${baseUrl}?top=50000`)
})

const setPendingState = (state: EntityState<IEenheidSoort> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<IEenheidSoort> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<IEenheidSoort> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }


export const eenheidSoortSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),

    reducers: {
        clearError: state => {
            state.error = null
        },
        select: (state, action: PayloadAction<string | undefined>) => {
            state.selectedId = action.payload
        },
        clearSelection: state => {
            state.selectedId = undefined
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
    },

    extraReducers: builder => {
        builder.addCase(fetchAll.pending, state => setPendingState(state))
        builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IEenheidSoort>>) => {
          setSucceededState(state)
          entityAdapter.setAll(state, action.payload.items)
        })
        builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
    },
})

export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
}

export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
  }
  
  export const getErrorState = (state: RootState) => {
    return getSliceState(state).error
  }

export const { add, modify, removeMany, select, clearSelection } = eenheidSoortSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.eenheidSoorten)

export const getEenheidSoortenAsOptions = (state: RootState) => state.eenheidSoorten.ids.map((id: EntityId) => {
    const entity = state.eenheidSoorten.entities[id]
    return {
        key: id,
        text: `${entity?.naam}`
    } as IDropdownOption
})

export default eenheidSoortSlice.reducer
