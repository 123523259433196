import React, { useState } from 'react'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { useNavigate, useParams } from 'react-router-dom'
import { RechtspersoonForm } from './RechtspersoonForm'
import { zodiosHooks } from 'api/ApiClient'
import { z } from 'zod'
import { getTitleAndMessage } from 'services/HandleError'

export const EditParams = z.object({
  id: z.string().regex(/^\d+$/, 'Geen geldige id').transform(s => Number(s))
})

export const RechtspersoonEdit = () => {
  const navigate = useNavigate()
  const params = useParams()
  const validatedParams = EditParams.parse(params)
  const [error, setError] = useState<string>()

  const { data, isLoading, invalidate } = zodiosHooks.useGetRechtspersonenId({ params: { id: validatedParams.id }})

  const { mutate } = zodiosHooks.usePutRechtspersonenId(
    { params: { id: validatedParams.id } }, {
      onSuccess: () => invalidate().then(() => navigate(-1)),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true }
  ]

  return (
    <MainTemplate title='Rechtspersoon' subTitle='bewerken' commandItems={commandItems} error={error}>
      <RechtspersoonForm
        data={data}
        isLoading={isLoading}
        onSubmit={(data) => mutate(data)}
        onCancel={() => navigate(-1)} />
    </MainTemplate>
  )
}