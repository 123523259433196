import React from 'react'
import { Controller } from 'react-hook-form'
import {
  IButtonStyles,
  IconButton,
  IStackStyles,
  ITextFieldStyles,
  Stack,
  TextField
} from '@fluentui/react'

interface ButtonProps {
  name: string;
  control: any;
  defaultValue?: number;
  register?: any
  errors: any;
  allowDecimal?: boolean;
  buttonWidth?: number
  decimalPlaces?: number
}

const styles: Partial<ITextFieldStyles> = {
  fieldGroup: [
    {
      border: '1px solid #efefef',
      boxShadow: '#efefef 0px 0px 9px -1px',
      height: '25px',
    },
  ],
  root: { textAlign: 'center' },
};


const leftButtonStyles: IButtonStyles = {
  root: {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    height: 25,
  }
}

const rightButtonStyles: IButtonStyles = {
  root: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    height: 25,
  }
}

const VgtIncrementDecrementButton: React.FC<ButtonProps> = ({ name, control, buttonWidth = 150, defaultValue, allowDecimal, decimalPlaces = 3}) => {

  const stackStyles: IStackStyles = {
    root: {
      borderRadius: 12,
      display: 'flex',
      alignItems: 'center',
      height: 25,
      flexDirection: 'row',
      border: '1px solid #efefef',
      boxShadow: ' #efefef 0 0 9px -1px',
      width: buttonWidth,
    },
  };

  const handleChange = (onChange, newValue: string | undefined) => {
    //If you want to allow Decimal value please ensure you'll parse the number before sending the data in the Form because the returned value is a string
    if (allowDecimal) {
      const decimalValue = newValue ? newValue.replace(/[^0-9.,]+/g, '')
        .replace(/(,.*|\..*)[,.]/g, '$1')
         .replace(',', '.') : 0;
      return onChange(decimalValue)
    } else {
      const numericValue = newValue ? parseInt(newValue, 10) || 0 : 0;
      return onChange(numericValue);
    }
  };

  const decrement = (onChange, value: string | undefined) => {
    const integerValue = Math.floor(Number(value));
    const decimalValue = Number(value) - integerValue;
    let newValue;

    if (integerValue > 0) {
      newValue = Number(value) > 0 ? Math.max(0, Math.round((Number(value) - 1) * 1000) / 1000) : 0;
    } else {
      newValue = decimalValue > 0 ? parseFloat(decimalValue.toFixed(decimalPlaces)) : 0;
    }

    return onChange(parseFloat(newValue.toFixed(decimalPlaces)));
  }

  const increment = (onChange, value: string | undefined) => {
    const newValue = Math.round((Number(value) + 1) * 1000) / 1000;
    return onChange(parseFloat(newValue.toFixed(decimalPlaces)));
  }

  return (
    <Stack styles={stackStyles}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ value, onChange }) => (
            <>
              <IconButton iconProps={{ iconName: 'Remove' }} onClick={() => decrement(onChange, value)} styles={leftButtonStyles} />
              <TextField
                value={value}
                styles={styles}
                onChange={(event, newValue) => handleChange(onChange, newValue)}
              />
              <IconButton iconProps={{ iconName: 'Add' }} onClick={() => increment(onChange, value)}
                          styles={rightButtonStyles} />
            </>
        )}
      />
    </Stack>
  );
};

export default VgtIncrementDecrementButton;
