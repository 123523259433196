import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import useRoles from '../../../services/UseRoles'
import { usePagedParams } from '../../../services/usePagedParams'
import React, { useMemo, useState } from 'react'
import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import { WerkzaamhedenListDto, zodiosHooks } from '../../../api/ApiClient'
import { getTitleAndMessage } from '../../../services/HandleError'
import { WerkzaamhedenFormPanel } from './WerkzaamhedenFormPanel'
import ICoMoonFontIcon from '../../../components/IcoMoon'

type WerkzaamhedenListDtoType = z.infer<typeof WerkzaamhedenListDto>;


export const WerkzaamhedenList = () => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [itemToEdit, setItemToEdit] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const { isAdmin } = useRoles()
  const [error, setError] = React.useState<string>()

  const { data, invalidate, isLoading } = zodiosHooks.useGetApiWerkzaamheden({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteApiWerkzaamheden({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
    {
      text: 'Toevoegen',
      onClick: () => {
        openPanel()
      },
      icon: 'Add',
      iconOnly: true,
      visible: isAdmin
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        deleteRows({ itemsToDelete: selectedIds })
      },
      icon: 'Delete',
      iconOnly: true,
      disabled: isDeleting || selectedIds.length === 0
    }
  ], [isAdmin, selectedIds.length])

  const handleItemInvoked = (id: string) => {
    if (id) {
      setItemToEdit(parseInt(id))
      openPanel()
    }
  }

  const handleDismissPanel = () => {
    setItemToEdit(0)
    dismissPanel()
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Omschrijving', getPropertyName<WerkzaamhedenListDtoType>('omschrijving'), 'XL', true,
        (item: WerkzaamhedenListDtoType) => (
          <Link key={item.werkzaamheidId} onClick={() => handleItemInvoked(item.werkzaamheidId.toString())}>
            {item.omschrijving}
          </Link>
        )),
      createColumn('Fonticoon', getPropertyName<WerkzaamhedenListDtoType>('fonticoonNaam'), 'L', false,
        (item: WerkzaamhedenListDtoType) => <ICoMoonFontIcon iconName={item?.fonticoonNaam!} />)
    ]
  }, [])

  return (
    <MainTemplate title="Werkzaamheden" subTitle="overzicht" commandItems={commandItems} error={error}>
      <WerkzaamhedenFormPanel werkzaamhedenId={itemToEdit} dismissPanel={handleDismissPanel} isOpen={isPanelOpen}
                              invalidate={invalidate} />
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount || 0,
          totalPages: data?.totalPages || 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.werkzaamheidId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.werkzaamheidId.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />

    </MainTemplate>
  )
}

