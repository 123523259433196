import { FC, useEffect } from 'react'
import { DefaultButton, IDropdownOption, Panel, PanelType, Stack } from '@fluentui/react'
import {
  gebouwElementHoeveelheidSchema,
  getLoadingStatus,
  getSelectedGebouwHoeveelheid,
  IGebouwElementHoeveelheid,
  updateHoeveelheid
} from './gebouwenSlice'
import { getSelectedGebouwClusterHoeveelheid } from '../gebouwenclusters/gebouwenClustersSlice'
import { getSelectedGebouwdeelHoeveelheid } from './gebouwenSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import VgtTextField from 'components/VgtTextField'
import { getPropertyName } from 'lib/interfaceUtils'
import VgtDropdown from 'components/FluentDropdown'
import { useSelector } from 'react-redux'
import FluentDatePicker from 'components/FluentDatePicker'
import {useAppDispatch, useTypedSelector} from 'store'
import {
  fetchMeetkundigeEenheden,
  IMeetkundigeEenheid,
  selectAll as selectEenheden
} from 'features/beheer/basis/meetkundigeEenheden/meetkundigeEenheidSlice'
import { useBoolean } from '@fluentui/react-hooks'

export enum EditMode {
  gebouw,
  gebouwdeel,
  cluster,
}

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  itemId: number | undefined
  editMode: EditMode
}

const conditionOptions = [
  {
    key: 0,
    text: ''
  },
  {
    key: 1,
    text: 'Uitstekend'
  },
  {
    key: 2,
    text: 'Goed'
  },
  {
    key: 3,
    text: 'Voldoende'
  },
  {
    key: 4,
    text: 'Matig'
  },
  {
    key: 5,
    text: 'Onvoldoende'
  },
  {
    key: 6,
    text: 'Slecht'
  }
] as IDropdownOption[]

const createEenheidOptions = (eenheden: IMeetkundigeEenheid[]) => {
  return eenheden.map(e => {
    return {
      key: e.id,
      text: e.code
    } as IDropdownOption
  })
}

const EditPanelGebouwHoeveelheid: FC<IEditPanelProps> = props => {
  const dispatch = useAppDispatch()
  const selectedEntity = useTypedSelector((state) => {
    if (props.editMode === EditMode.gebouw) {
      return getSelectedGebouwHoeveelheid(state, props.itemId)
    } else if (props.editMode === EditMode.cluster) {
      return getSelectedGebouwClusterHoeveelheid(state, props.itemId)
    }
    return getSelectedGebouwdeelHoeveelheid(state, props.itemId)
  })
  const [closeOnFulFilled, { setTrue: setCloseOnFulFilledTrue, setFalse: setCloseOnFulFilledFalse }] = useBoolean(false)
  const fetchStatus = useSelector(getLoadingStatus)
  const eenheden = useSelector(selectEenheden)
  const optionsEenheden = createEenheidOptions(eenheden)

  const closePanel = () => {
    props.dismissPanel()
  }

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<IGebouwElementHoeveelheid>({ resolver: yupResolver(gebouwElementHoeveelheidSchema), mode: 'all' })

  useEffect(() => {
    if (selectedEntity) {
      reset(selectedEntity)
    } else {
      reset({} as IGebouwElementHoeveelheid)
    }
    if (closeOnFulFilled) {
      setCloseOnFulFilledFalse()
      if (selectedEntity && fetchStatus === 'succeeded') {
        props.dismissPanel()
      }
    }
  }, [selectedEntity])

  useEffect(() => {
    if (eenheden.length === 0) dispatch(fetchMeetkundigeEenheden())
  }, [])

  const onSubmit = data => {
    if (selectedEntity) {
      data.id = selectedEntity.id
      setCloseOnFulFilledTrue()
      dispatch(updateHoeveelheid(data))
    }
  }

  const onRenderFooterContent = () => {
    return (
      <>
          <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
            <DefaultButton text='Opslaan' type='submit' primary disabled={isSubmitting}
                          onClick={handleSubmit(onSubmit)} />
            <DefaultButton text='Annuleren' onClick={closePanel} />
          </Stack>
      </>
    )
  }

  const getInspectieDatum = () => {
    if (selectedEntity?.inspectieDatum) {
      return new Date(selectedEntity.inspectieDatum)
    }
    return undefined
  }

  return (
    <Panel
      type={PanelType.medium}
      headerText='Bewerkt element en hoeveelheid'
      isOpen={props.isOpen}
      onDismiss={closePanel}
      closeButtonAriaLabel='Sluiten'
      onRenderFooterContent={onRenderFooterContent}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <VgtTextField
          label='Hoofdelement groep'
          name={getPropertyName<IGebouwElementHoeveelheid>('hoofdElementGroep')}
          register={register}
          control={control}
          errors={errors}
          required
        />
        <VgtTextField
          label='Plaatsaanduiding'
          name={getPropertyName<IGebouwElementHoeveelheid>('plaatsaanduiding')}
          register={register}
          control={control}
          errors={errors}
        />
        <VgtTextField
          label='Element'
          name={getPropertyName<IGebouwElementHoeveelheid>('element')}
          register={register}
          control={control}
          errors={errors}
          required
        />
        <VgtTextField
          label='Hoeveelheid'
          name={getPropertyName<IGebouwElementHoeveelheid>('hoeveelheid')}
          register={register}
          control={control}
          errors={errors}
          required
        />
        <VgtDropdown
          label='Eenheid'
          name={getPropertyName<IGebouwElementHoeveelheid>('eenheidId')}
          options={optionsEenheden}
          register={register}
          control={control}
          errors={errors}
          required
        />
        <VgtTextField
          label='Vervangingsjaar'
          name={getPropertyName<IGebouwElementHoeveelheid>('vervangingsJaar')}
          register={register}
          control={control}
          errors={errors}
        />
        <FluentDatePicker value={getInspectieDatum()} label='Inspectiedatum' />
        <VgtDropdown
          label='Conditie'
          name={getPropertyName<IGebouwElementHoeveelheid>('conditie')}
          options={conditionOptions}
          register={register}
          control={control}
          errors={errors}
        />
      </form>
    </Panel>
  )
}

export default EditPanelGebouwHoeveelheid
