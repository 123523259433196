import React, { useMemo, useState } from 'react'
import { IColumn, Link } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import { getPropertyName } from 'lib/interfaceUtils'
import EditPanelEenheidDetailSoorten from './editPanel'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { transformDateString } from '../common/support'
import { columnsVera } from '../common/columnUtils'
import { EenheidDetailSoortDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { usePagedParams } from '../../../../services/usePagedParams'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { z } from 'zod'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'

type EenheidDetailSoortDtoType = z.infer<typeof EenheidDetailSoortDto>;

const EenheidDetailSoortList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [error, setError] = React.useState<string>()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [selectedEenheidDetailSoort, setSelectedEenheidDetailSoort] = useState<EenheidDetailSoortDtoType | undefined>()

  const {
    data: eEnheidDetailSoort,
    invalidate: fetchEenheidDetailSoort,
    isLoading: isFetchingEenheidDetailSoort
  } = zodiosHooks.useGetEenheidDetailSoort({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: deleteEenheidDetailSoort } = zodiosHooks.useDeleteEenheidDetailSoort({}, {
    onSuccess: () => fetchEenheidDetailSoort(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const refreshItems = () => fetchEenheidDetailSoort()
  const { isAdmin } = useRoles()

  const handleItemInvoked = (item: EenheidDetailSoortDtoType) => {
    setSelectedEenheidDetailSoort(item)
    openPanel()
  }

  const removeItems = () => {
    deleteEenheidDetailSoort(selectedIds)
  }

  const columns: IColumn[] = useMemo(() => {
    return [
      createCustomRenderedColumn(
        'Code',
        getPropertyName<EenheidDetailSoortDtoType>('code'),
        (item: EenheidDetailSoortDtoType) => (
          <Link key={item.id} onClick={() => handleItemInvoked(item)}>
            {item.code}
          </Link>
        ),
        'S',
        false
      ),
      createColumn('Eenheidsoortcode', getPropertyName<EenheidDetailSoortDtoType>('soortCode'), 115, false),
      createColumn('Naam', getPropertyName<EenheidDetailSoortDtoType>('naam'), 210, false),
      createColumn('Omschrijving', getPropertyName<EenheidDetailSoortDtoType>('omschrijving'), 'XXL'),
      createColumn('Eengezins-meergezins', getPropertyName<EenheidDetailSoortDtoType>('eengezinsMeergezinsOptie'), 'L', false),
      ...columnsVera,
      createCustomRenderedColumn(
        'Ingangsdatum',
        getPropertyName<EenheidDetailSoortDtoType>('ingangsDatum'),
        (item: EenheidDetailSoortDtoType) => <span>{transformDateString(item.ingangsDatum!)}</span>,
        'M',
        false
      ),
      createCustomRenderedColumn(
        'Einddatum',
        getPropertyName<EenheidDetailSoortDtoType>('eindDatum'),
        (item: EenheidDetailSoortDtoType) => <span>{transformDateString(item.eindDatum!)}</span>,
        'M',
        false
      )
    ]
  }, [])

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
      {
        visible: isAdmin,
        text: 'Toevoegen',
        onClick: () => {
          setSelectedEenheidDetailSoort(undefined)
          openPanel()
        },
        icon: 'Add'
      },
      {
        visible: isAdmin,
        text: 'Verwijderen',
        onClick: () => removeItems(),
        icon: 'Delete',
        disabled: !selectedIds.length
      }
    ],
    [isAdmin, selectedIds.length]
  )

  return (
    <MainTemplate title='Eenheiddetailsoorten' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <EditPanelEenheidDetailSoorten fetchEenheidDetailSoort={fetchEenheidDetailSoort}
                                     selectedEenheidDetailSoort={selectedEenheidDetailSoort} dismissPanel={dismissPanel}
                                     isOpen={isPanelOpen} />
      <VgtPagedTable
        isLoading={isFetchingEenheidDetailSoort}
        items={eEnheidDetailSoort?.items ?? []}
        height={'203'}
        columns={columns}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: eEnheidDetailSoort?.totalCount ?? 0,
          totalPages: eEnheidDetailSoort?.totalPages ?? 0,
          hasNextPage: eEnheidDetailSoort?.hasNextPage ?? false,
          hasPreviousPage: eEnheidDetailSoort?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        onItemInvoked={(e) => handleItemInvoked(e)}
        getKey={(item) => item?.id}
        onSelectionChanged={setSelectedIds}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}

export default EenheidDetailSoortList
