import { RootState } from 'store'
import { setGenericError } from 'store/actions/app/genericError'
import { MsalSingleton } from '../api/msalInstance'
import { IConfiguration } from '../store/actions/app/configuration'

export const invokeFetch = async <T>(thunkApi: any, method: 'GET' | 'POST' | 'PUT' | 'DELETE', uri: string, body?: any): Promise<T> => {
  const jsonBody = body && JSON.stringify(body)
  return await invokeFetchFull(thunkApi, method, uri, 'application/json', jsonBody)
}

export const invokeFetchFull = async <T>(
  thunkApi: any,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  uri: string,
  contentType?: string,
  body?: any
): Promise<T> => {
  const state = thunkApi.getState() as RootState
  const { apiEndpoint } = state.app.configuration
  const endpoint = `${apiEndpoint}${uri}`

  try {
    const response = await MsalSingleton.getInstance().acquireTokenSilent()
    let headers = { Authorization: `Bearer ${response.accessToken}` } as HeadersInit
    if (contentType) headers = { ...headers, 'Content-Type': contentType }
    const resp = await fetch(endpoint, {
      method: method,
      mode: 'cors',
      headers: headers,
      body: body,
    })

    if (resp.ok) {
      const contentType = resp.headers.get('content-type')
      if (contentType && contentType.indexOf('application/json') !== -1) {
        const json = await resp.json()
        return json as T
      } else {
        return {} as T
      }
    } else {
      const contentType = resp.headers.get('content-type')
      let message: string
      let title: string
      if (contentType && contentType.indexOf('application/problem+json') !== -1) {
        const json = await resp.json()
        message = json.detail
        title = json.title
      } else {
        // http responses, without content
        if (resp.status === 403) {
          message = 'U bent niet geautoriseerd om deze handeling uit te voeren.'
          title = 'Niet geautoriseerd'
        } else {
          message = resp.statusText
          title = 'Server fout: ' + resp.status
        }
      }

      thunkApi.dispatch(
        setGenericError({
          title: title,
          subText: message,
        })
      )
      return thunkApi.rejectWithValue({ message: message, errors: [resp.status] })
    }
  } catch (error: any) {
    thunkApi.dispatch(
      setGenericError({
        title: 'Onbekende fout',
        subText: error.message,
      })
    )
  }
  return thunkApi.rejectWithValue({ errors: ['Unknown error'] })
}

export interface IFetchImageResponse {
  image?: string
  contentType?: string
}

export const contentTypeSvg = 'image/svg+xml'

export const invokeFetchImage = async (thunkApi: any, uri: string, token: string): Promise<IFetchImageResponse> => {
  const state = thunkApi.getState() as RootState
  const { apiEndpoint } = state.app.configuration
  const endpoint = `${apiEndpoint}${uri}`

  try {
    const resp = await fetch(endpoint, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    const contentType = resp.headers.get('content-type')

    if (resp.ok) {
      if (contentType === contentTypeSvg) {
        let xml = await resp.text()
        xml = xml.replace('<?xml version="1.0" encoding="UTF-8"?>', '')
        return {
          image: xml,
          contentType: contentType,
        }
      } else {
        if (contentType === null) return thunkApi.rejectWithValue({ errors: ['Image content type cannot be null'] })
        const buffer = await resp.arrayBuffer()
        const urlBase64 = window.URL.createObjectURL(new Blob([buffer]))
        return {
          image: urlBase64,
          contentType: contentType,
        }
      }
    } else {
      let message: string
      let title: string
      if (contentType && contentType.indexOf('application/problem+json') !== -1) {
        const json = await resp.json()
        message = json.detail
        title = json.title
      } else {
        message = await resp.text()
        title = 'Internal server error'
      }

      thunkApi.dispatch(
        setGenericError({
          title: title,
          subText: message,
        })
      )
      return thunkApi.rejectWithValue({ errors: [resp.status] })
    }
  } catch (error: any) {
    thunkApi.dispatch(
      setGenericError({
        title: 'Onbekende fout',
        subText: error.message,
      })
    )
    return {}
  }
}

export const makeRequest = async <T>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  uri: string,
  contentType?: string,
  body?: any
): Promise<T> => {
  const response = await MsalSingleton.getInstance().acquireTokenSilent();
  let headers = { Authorization: `Bearer ${response.accessToken}` } as HeadersInit;
  if (contentType) headers = { ...headers, 'Content-Type': contentType };

  const settings = JSON.parse(localStorage.getItem('settings') || '{ "apiEndpoint": "https://api.vastgoedtabel.nl"}') as IConfiguration

  const endpoint = `${settings.apiEndpoint}${uri}`;

  const resp = await fetch(endpoint, {
    method: method,
    mode: 'cors',
    headers: headers,
    body: body,
  });

  if (resp.ok) {
    const contentType = resp.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const json = await resp.json();
      return json as T;
    } else {
      return {} as T;
    }
  } else {
    const contentType = resp.headers.get('content-type')
    let message: string
    let title: string
    if (contentType && contentType.indexOf('application/problem+json') !== -1) {
      const json = await resp.json()
      message = json.detail
      title = json.title
    } else {
      // http responses, without content
      if (resp.status === 403) {
        message = 'U bent niet geautoriseerd om deze handeling uit te voeren.'
        title = 'Niet geautoriseerd'
      } else {
        message = resp.statusText
        title = 'Server fout: ' + resp.status
      }
    }
    throw new Error(title + ': ' + message);
  }
};