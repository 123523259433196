import { FC } from 'react'
import { IVge } from './vgeSlice'

const googlePhotoSourceBuilder = (address: (string | undefined)[], lat?: number, long?: number) => {
  if (lat && long) return `https://maps.googleapis.com/maps/api/streetview?size=397x270&location=${lat},${long}&fov=90&pitch=10&key=AIzaSyATEnt3CXMpsDjdPvlXpUPaDapGig_qr6M`
  const filteredAddress: string = address.filter(x => x !== undefined).toString()
  return `https://maps.googleapis.com/maps/api/streetview?size=397x270&location=${filteredAddress}&fov=90&pitch=10&key=AIzaSyATEnt3CXMpsDjdPvlXpUPaDapGig_qr6M`
}

export const GooglePhoto: FC<{ vge: IVge }> = props => {
  return (
    <img
      style={{ height: '270px', width: '397px', boxShadow: 'rgb(239 239 239) 0px 0px 9px -1px', borderRadius: '8px'}}

      src={googlePhotoSourceBuilder([props.vge?.wpl ?? '', props.vge?.str ?? '', props.vge?.nr, props.vge?.nrT, props.vge?.ltr], props.vge?.lat, props.vge?.long)}
      alt="Hoofdfoto"
    />
  )
}
