import React, {useEffect, useMemo, useState} from 'react'
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  ScrollablePane,
  SelectionMode,
  Spinner
} from '@fluentui/react'
import '../../common/lemdoList.css'
import '../../common/grid.css'
import {Dictionary} from '@reduxjs/toolkit'
import {useNavigate} from 'react-router-dom'
import downloadFile from 'services/downloadFile'
import UploadPanel from 'components/UploadPanel'
import {useBoolean} from '@fluentui/react-hooks'
import {IMatrixRow} from '../../common/matrixSelectionTypes'
import {MatrixCell, MatrixCheckBox} from '../../common/matrixSelection'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import {zodiosHooks} from "../../../../../api/ApiClient";

interface ILeftItem {
  id: number;
  naam: string | null;
}

interface ITopItem {
  id: number;
  naam: string | null;
}

interface IMatrixItem {
  eenheidDetailSoortId: number
  ruimteSoortId: number
}

const createMatrix = (leftItems: ILeftItem[], details: IMatrixItem[]): IMatrixRow[] => {
  if (!leftItems || leftItems.length === 0) {
    return [];
  }

  return leftItems.map<IMatrixRow>(l => {
    const selectionForRow = details?.filter(le => le.ruimteSoortId === l.id)
    const dict = {} as Dictionary<string>

    selectionForRow?.forEach(l => (dict[l.eenheidDetailSoortId.toString()] = '1'))

    return {
      rowId: l.id,
      cells: dict,
    }
  });
}

const RuimteSoortMatrix: React.FC<{}> = () => {
  const navigate = useNavigate()
  const [items, setItems] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)

  const {data: leftItems, invalidate: refreshLeftItems, isLoading: isLoadingLeftItems} = zodiosHooks.useGetRuimteSoortlistitems({});
  const {data: topItems, invalidate: refreshTopItems, isLoading: isLoadingTopItems} = zodiosHooks.useGetEenheidDetailSoortlistitems({});
  const {data: matrixItems, invalidate: refreshMatrixItems, isLoading: isLoadingMatrixItems} = zodiosHooks.useGetEenheidDetailSoortpageRuimteSoorten({});

  const refreshItems = () => {
    refreshLeftItems();
    refreshTopItems();
    refreshMatrixItems();
  }

  useEffect(() => {
    setLoading(isLoadingLeftItems || isLoadingMatrixItems || isLoadingTopItems)
  }, [isLoadingLeftItems, isLoadingMatrixItems, isLoadingTopItems])

  useEffect(() => {
    if (!leftItems || !topItems || !matrixItems) {
      setItems([]);
      return;
    }
    setItems(createMatrix(leftItems, matrixItems));
  }, [leftItems, topItems, matrixItems])

  const soortColumns = topItems?.map<IColumn>((s: ITopItem) => {
    return {
      key: s.id.toString(),
      name: s.naam,
      minWidth: 25,
      maxWidth: 25,
      isResizable: false,
      isRowHeader: true,
      headerClassName: 'headerClass',
      onRender: (item: IMatrixRow) => <MatrixCheckBox id={s.id} titles={item.cells}/>,
    } as IColumn
  });

  const columns = [
    {
      key: 'ruimteSoort',
      isRowHeader: true,
      minWidth: 250,
      maxWidth: 400,
      onRender: (item: IMatrixRow) => <MatrixCell text={createRuimteSoortText(item)}/>,
    } as IColumn,
  ] as IColumn[];

  if (soortColumns) {
      columns.push(...soortColumns);
  }
    
  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
      {
        text: 'Exporteer naar Excel',
        onClick: () => {
          downloadFile('/eenheidDetailSoort/exportRuimteSoorten', 'Eenheiddetailsoorten Ruimtesoorten.xlsx')
        },
        icon: 'Export',
      },
      { text: 'Importeer van Excel', onClick: () => openPanel(), icon: 'Import' },
    ],
    []
  )

  const createRuimteSoortText = (row: IMatrixRow) => {
    const ruimteSoort = leftItems?.filter(l => l.id === row.rowId)
    if (ruimteSoort && ruimteSoort.length !== 0) return `${ruimteSoort[0].naam}`
    return ''
  }

  const handleDismiss = () => {
    dismissPanel()
    refreshItems()
  }

  const onSuccessUploadExcel = () => {
    handleDismiss()
  };

  return loading ? (
    <div className="spinnerWrapper">
      <Spinner>Laden...</Spinner>
    </div>
  ) : (
    <MainTemplate title="Cartotheek-item || Defectsoort || Reparatieboom-taken" subTitle="Overzicht" commandItems={commandItems}>
      <UploadPanel title="Importeer wijzigen" dismissPanel={handleDismiss} isOpen={isOpen} 
                   uploadEndpoint={'/eenheidDetailSoort/importRuimteSoorten'} 
                    onSuccess={onSuccessUploadExcel}/>
      <div style={{ height: 'calc(100vh - 180px)', position: 'relative' }}>
        <ScrollablePane>
          <DetailsList
            columns={columns}
            items={items}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            constrainMode={ConstrainMode.unconstrained}
            compact={true}
            className="matrixGrid"
          />
        </ScrollablePane>
      </div>
    </MainTemplate>
  )
}

export default RuimteSoortMatrix
