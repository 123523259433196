import React, { useEffect, useMemo, useState } from 'react'
import { IColumn, Link } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import { getPropertyName } from 'lib/interfaceUtils'
import EditPanelDefectLocatie from './editPanel'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'
import ICoMoonFontIcon from '../../../../components/IcoMoon'
import { ElementDto, zodiosHooks } from '../../../../api/ApiClient'
import { usePagedParams } from '../../../../services/usePagedParams'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'

type ElementDtoType = z.infer<typeof ElementDto>;

const ElementList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const { validatedSearchParams, setFilter, setPage, setOrder } = usePagedParams()
  const [error, setError] = useState<string>()
  const [selectedElementItem, setSelectedElementItem] = useState<ElementDtoType | undefined>()
  const { isAdmin } = useRoles()

  const {
    data: elementItems,
    invalidate: fetchElementItems,
    isLoading: isFetchingElementItems
  } = zodiosHooks.useGetApiElementpage({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: deleteApiElement } = zodiosHooks.useDeleteApiElement({}, {
    onSuccess: () => fetchElementItems(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const refreshItems = () => {
    fetchElementItems()
    setSelectedIds([])
  }

  useEffect(() => {
    refreshItems()
  }, [])

  const handleItemInvoked = (id: string) => {
    setSelectedElementItem(elementItems?.items?.find(item => item.id === parseInt(id)))
    openPanel()
  }

  const removeItems = () => {
    deleteApiElement(selectedIds)
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<ElementDtoType>('naam'),
      name: 'Code',
      fieldName: getPropertyName<ElementDtoType>('naam'),
      minWidth: 150,
      maxWidth: 400,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: ElementDtoType) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.naam}
        </Link>
      )
    },
    {
      key: getPropertyName<ElementDtoType>('categorieNaam'),
      name: 'Elementcategorie',
      fieldName: getPropertyName<ElementDtoType>('categorieNaam'),
      minWidth: 150,
      maxWidth: 400,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: 'fonts-icon',
      name: 'Fonticoon',
      fieldName: getPropertyName<ElementDtoType>('fonticoonId'),
      minWidth: 70,
      maxWidth: 70,
      isResizable: false,
      isRowHeader: false,
      onRender: (item: ElementDtoType) => <ICoMoonFontIcon iconName={item?.fonticoonNaam!} />
    }
  ]

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
      {
        visible: isAdmin,
        text: 'Toevoegen',
        onClick: () => {
          setSelectedElementItem(undefined)
          openPanel()
        },
        icon: 'Add'
      },
      {
        visible: isAdmin,
        text: 'Verwijderen',
        onClick: () => removeItems(),
        icon: 'Delete',
        disabled: !selectedIds.length
      }
    ],
    [isAdmin, selectedIds.length]
  )

  return (
    <MainTemplate title='Elementen' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <EditPanelDefectLocatie selectedElementItem={selectedElementItem} fetchElementItems={fetchElementItems}
                              dismissPanel={dismissPanel} isOpen={isPanelOpen} />
      <VgtPagedTable
        items={elementItems?.items ?? []}
        isLoading={isFetchingElementItems}
        height={'203'}
        columns={columns}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: elementItems?.totalCount ?? 0,
          totalPages: elementItems?.totalPages ?? 0,
          hasNextPage: elementItems?.hasNextPage ?? false,
          hasPreviousPage: elementItems?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        onItemInvoked={(e) => handleItemInvoked(e.id.toString())}
        onSelectionChanged={setSelectedIds}
        getKey={(item) => item?.id}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}

export default ElementList
