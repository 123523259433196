import { createAsyncThunk, createEntityAdapter, createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { entityAdapterInitState } from 'interfaces/entityAdapterState'
import { setPendingState, setSucceededState, setRejectedState } from 'lib/sliceSupport'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'

export interface IProduct2BA {
    description: string
    features: IProductFeature[]
    gtin: string
    manufacturerGLN: string
    manufacture: string
    model: string
    productClassDescription: string
    productCode: string
    id: number
    tradeItemCount: number
    version: string
    selected: boolean
}

export interface IProductFeature {
    id: string
    name: string
    value: string
}

const entityAdapter = createEntityAdapter<IProduct2BA>()

const baseUrl = '/api/product2BA'
const basePrefix = 'product2BA'

const getSliceState = (state: RootState) => state.products2BA

export const fetchByQuery = createAsyncThunk(`${basePrefix}/fetchByQuery`, async (query: string, thunkAPI) => {
    return await invokeFetch<PagedEntities<IProduct2BA>>(thunkAPI, 'GET', `${baseUrl}?query=${query}`)
})

export const product2BASlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),
    reducers: {
        clearError: state => {
            state.error = null
        },
        select: (state, action: PayloadAction<string | undefined>) => {
            state.selectedId = action.payload
        },
        reset: state => {
            state.selectedId = undefined
            state.entities = {} as Dictionary<IProduct2BA>
        }
    },

    extraReducers: builder => {
        builder.addCase(fetchByQuery.pending, state => setPendingState(state))
        builder.addCase(fetchByQuery.fulfilled, (state, action: PayloadAction<PagedEntities<IProduct2BA>>) => {
            setSucceededState(state)
            entityAdapter.setAll(state, action.payload.items)
        })
        builder.addCase(fetchByQuery.rejected, (state, action) => setRejectedState(state, action))
    }
})

export const getSelectedEntity = (state: RootState) => {
    const entities = getSliceState(state)
    const selectedId = entities.selectedId
    if (selectedId) return entities[selectedId]
    return undefined
}


export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
}

export const getErrorState = (state: RootState) => {
    return getSliceState(state).error
}

export const { select, reset } = product2BASlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.products2BA)

export default product2BASlice.reducer
