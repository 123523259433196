import React from 'react'

interface IFluentMenubarProps {
  onChange
  htmlId: string
}

const FileInput: React.FC<IFluentMenubarProps> = ({ onChange, htmlId }) => {

  return (
    <>
      <input
        style={{ display: 'none' }}
        type="file"
        id={htmlId}
        onChange={e => {
          onChange(e.target.files)
        }}
      />
    </>
  )
}

export default FileInput
