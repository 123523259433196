import { IColumn, Link, Pivot, PivotItem, SelectionMode } from '@fluentui/react'
import { PageIdDefinition, PAGES } from 'pages'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { IVge, select } from '../vge/vgeSlice'
import { fetchPerceel } from '../vge/perceelSlice'
import { selectAll, getPresentationType, getLoadingState, setPresentationType } from './fysiekSlice'
import GoogleMapReact from 'google-map-react'
import FluentTable from 'components/FluentTable'

const PercelenTable: React.FC<{}> = () => {
  const items = useSelector(selectAll)
  const presentationType = useSelector(getPresentationType)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loadingState = useSelector(getLoadingState)
  const onGetKey = (item: IVge) => item.id.toString()

  const getVgeType = (Huisnummer: string | undefined | null, Adresaanduiding: string | undefined | null) => {
    if (Huisnummer == null && Adresaanduiding == null) {
      return 'Terrein'
    }
    else if (Huisnummer == null && Adresaanduiding !== null) {
      return 'Overig Gebouwd'
    }
    else {
      return 'Adresseerbaar'
    }
  }

  const { id } = useParams()


  const onPivotItemSelect = pivotItem => {
    dispatch(setPresentationType(pivotItem.props.itemKey))
  }

  const handleItemInvoked = (id: string) => {
    navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', id))
  }

  useEffect(() => {
    dispatch(select(id))
    if (id) dispatch(fetchPerceel(parseInt(id)))
  }, [id])

  const MarkerComponent = ({ text, item }: any) => {
    return (
      <Link onClick={() => handleItemInvoked(item)}>
        <div className="pin">{text}</div>
      </Link>
    )
  }

  const calculateCenter = () => {
    if (items) {
      return items.length > 0 ? ({ lat: items[0].lat, lng: items[0].long } as GoogleMapReact.Coords) : undefined
    }
  }
  const calculateZoom = () => {
    if (items) {
      return items.length > 0 ? 15 : 6
    }
  }
  const columns: IColumn[] = [
    {
      key: getPropertyName<IVge>('srt'),
      name: 'Eenheiddetailsoort',
      fieldName: getPropertyName<IVge>('srt'),
      minWidth: 110,
      maxWidth: 120,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IVge) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.srt}
        </Link>
      ),
    },
    {
      key: 'vgetype',
      name: 'VGE Type',
      fieldName: 'vge type',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IVge) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {getVgeType(item.nr, item.aa)}
        </Link>
      ),
    },
    {
      key: getPropertyName<IVge>('wpl'),
      name: 'Woonplaats',
      fieldName: getPropertyName<IVge>('wpl'),
      minWidth: 100,
      maxWidth: 140,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IVge) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.wpl}
        </Link>
      ),
    },
    {
      key: getPropertyName<IVge>('str'),
      name: 'Straat',
      fieldName: getPropertyName<IVge>('str'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IVge) => (
        <Link key={item.str} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.str}
        </Link>
      ),
    },
    {
      key: getPropertyName<IVge>('trnNm'),
      name: 'Terreinclassificatie',
      fieldName: getPropertyName<IVge>('trnNm'),
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IVge) => (
        <Link key={item.trnNm} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.trnNm}
        </Link>
      ),
    },
    {
      key: getPropertyName<IVge>('kadV'),
      name: 'Kadastrale aand. vlak',
      fieldName: getPropertyName<IVge>('kadV'),
      minWidth: 120,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IVge) => (
        <Link key={item.kadV} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.kadV}
        </Link>
      ),
    },
    {
      key: getPropertyName<IVge>('grt'),
      name: 'Grootte',
      fieldName: getPropertyName<IVge>('grt'),
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IVge) => (
        <Link key={item.grt} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.grt}
        </Link>
      ),
    },
    {
      key: getPropertyName<IVge>('opm'),
      name: 'Opmerking',
      fieldName: getPropertyName<IVge>('opm'),
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IVge) => (
        <Link key={item.opm} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.opm}
        </Link>
      ),
    },
  ]

  return (
    <>
      <Pivot selectedKey={presentationType} onLinkClick={onPivotItemSelect}>
        <PivotItem itemKey="list" headerText="Lijst" itemIcon="List">
          <div style={{ height: '100vh', width: '100%', overflow: 'auto' }} data-is-scrollable="true">
            <FluentTable
              columns={columns}
              items={items}
              loading={loadingState === 'pending'}
              selectionMode={SelectionMode.none}
              onGetKey={onGetKey}
              onItemInvoked={handleItemInvoked} />
          </div>
        </PivotItem>
        <PivotItem itemKey="map" headerText="Kaart" itemIcon="Globe">
          <div style={{ height: '85vh', width: '98%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyATEnt3CXMpsDjdPvlXpUPaDapGig_qr6M', language: 'nl-NL' }}
              defaultCenter={{ lat: 52.0907374, lng: 5.1214201 }}
              defaultZoom={6}
              options={{ mapTypeControl: true, streetViewControl: true }}
              center={calculateCenter()}
              zoom={calculateZoom()}
            >
              {items.map(adres => (
                <MarkerComponent key={adres.id} lat={adres.lat ?? 51.72835975} lng={adres.long ?? 5.01544399} text={adres.nr} item={adres} />
              ))}
            </GoogleMapReact>
          </div>
        </PivotItem>
      </Pivot>
      <br />
      <br />
      <br />
      <br />
    </>
  )
}

export default PercelenTable
