import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { contentTypeSvg, invokeFetchImage } from 'services/apiClient'
import { entityAdapterInitState } from 'interfaces/entityAdapterState'
import { setPendingState, setRejectedState, setSucceededState } from 'lib/sliceSupport'
import { RootState } from 'store'
import { getApiToken } from 'lib/auth'

export interface IFetchIconParameters {
  id: string
  uri: string
}

export interface IIcon {
  id: string
  imageContent: string | undefined
  isSvg: boolean
}

export const fetchIcon = createAsyncThunk('icons/fetchIcon', async (fetchParams: IFetchIconParameters, thunkAPI) => {
  const token = await getApiToken()
  const iconResponse = await invokeFetchImage(thunkAPI, fetchParams.uri, token)

  if (!iconResponse?.image || !iconResponse?.contentType) return null

  let isSvg = false
  let imageText = iconResponse.image
  if (iconResponse.contentType === contentTypeSvg) isSvg = true

  return {
    id: fetchParams.id,
    imageContent: imageText,
    isSvg: isSvg,
  } as IIcon
})

const entityAdapter = createEntityAdapter<IIcon | null>({
  sortComparer: (a, b) => {
    if (!a) return -1
    if (!b) return 1
    return a.id.localeCompare(b.id)
  },
})

export const iconSlice = createSlice({
  name: 'icons',
  initialState: entityAdapter.getInitialState(entityAdapterInitState),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchIcon.pending, state => setPendingState(state))
    builder.addCase(fetchIcon.fulfilled, (state, action: PayloadAction<IIcon | null>) => {
      setSucceededState(state)
      entityAdapter.upsertOne(state, action)
    })
    builder.addCase(fetchIcon.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const { selectById } = entityAdapter.getSelectors<RootState>(state => state.installatieGroepIcons)

export default iconSlice.reducer
