import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState';
import { PagedEntities } from 'interfaces/pagedEntities';
import { IEntityPageObjectResponse } from 'lib/sliceSupport';
import { invokeFetch } from 'services/apiClient';
import { RootState } from 'store';
import * as yup from 'yup'
import ILEmDOEntity from '../common/lemdoEntity';

export const KostensoortSchema = yup.object().shape({
  code: yup.string().required('Code is verplicht').max(50, 'Maximale lengte is 50'),
  omschrijving: yup.string()
})

export interface IKostensoort extends ILEmDOEntity{
    kostensoortId: number 
    code: string
    omschrijving: string
}

const entityAdapter = createEntityAdapter<IKostensoort>({
    selectId: e => e.kostensoortId,
    sortComparer: (a, b) => a.code.localeCompare(b.code)
})

const baseUrl = '/Kostensoort'
const basePrefix = 'Kostensoort'
const getSliceState = (state: RootState) => state.kostensoorten

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<IEntityPageObjectResponse<IKostensoort>>(thunkAPI, 'GET', `${baseUrl}`)
  })

  export const updateKostensoort = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity:IKostensoort, thunkAPI) => {
    return await invokeFetch<IKostensoort>(thunkAPI, 'PUT', `${baseUrl}/${entity.kostensoortId}`, entity)
  })
  
  export const addKostensoort = createAsyncThunk(`${basePrefix}/addStatus`, async (entity:IKostensoort, thunkAPI) => {
    return await invokeFetch<IKostensoort>(thunkAPI, 'POST', baseUrl, entity)
  })
  
  export const deleteKostensoort = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
  })
  
  const setPendingState = (state: EntityState<IKostensoort> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<IKostensoort> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<IKostensoort> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }
  
  export const kostensoortSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),
  
    reducers: {
      clearError: state => {
        state.error = null
      },
      select: (state, action: PayloadAction<number | undefined>) => {
        state.selectedId = action.payload
      },
      clearSelection: state => {
        state.selectedId = undefined
      },
      add: entityAdapter.addOne,
      modify: entityAdapter.upsertOne,
      removeMany: entityAdapter.removeMany,
      setAll: entityAdapter.setAll,
    },
  
    extraReducers: builder => {
      builder.addCase(fetchAll.pending, state => setPendingState(state))
      builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IKostensoort>>) => {
        setSucceededState(state)
        entityAdapter.setAll(state, action.payload.items)
      })
      builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(updateKostensoort.pending, state => setPendingState(state))
      builder.addCase(updateKostensoort.fulfilled, (state, action: PayloadAction<IKostensoort>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(updateKostensoort.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(addKostensoort.pending, state => setPendingState(state))
      builder.addCase(addKostensoort.fulfilled, (state, action: PayloadAction<IKostensoort>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(addKostensoort.rejected, (state, action) => {
        setRejectedState(state, action)
      })
      builder.addCase(deleteKostensoort.pending, state => setPendingState(state))
      builder.addCase(deleteKostensoort.fulfilled, (state, action) => {
        entityAdapter.removeMany(state, action.meta.arg)
        setSucceededState(state)
      })
      builder.addCase(deleteKostensoort.rejected, (state, action) => setRejectedState(state, action))
    },
  })
  
  export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
  }

  export const getLoadingState = (state: RootState) => state.kostensoorten.status
  
  export const getErrorState = (state: RootState) => state.kostensoorten.error
  
  export const { add, modify, removeMany, select, clearSelection } = kostensoortSlice.actions
  export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.kostensoorten)


  export default kostensoortSlice.reducer
  