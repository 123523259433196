import React, { useState } from 'react'
import {
  DefaultButton,
  IColumn,
  mergeStyleSets,
  PanelType,
  Stack,
  Sticky,
  StickyPositionType,
} from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { gebouwSchema, IGebouw } from './gebouwenSlice'
import { getPropertyName } from 'lib/interfaceUtils'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { SearchVgesForLinkageListDto, zodiosHooks } from '../../../../api/ApiClient'
import { usePagedParams } from '../../../../services/usePagedParams'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { AdresStatus } from './gebouwVgeList'
import { z } from 'zod'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'
import FluentPanel from '../../../../components/FluentPanel/FluentPanel'
import { getTitleAndMessage } from '../../../../services/HandleError'

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  gebouwDeelId: number
  adresStatus: number
  invalidate: () => void
}

const classNames = mergeStyleSets({
  wrapper: {
    position: 'relative',
    margin: '10px',
  },
  filter: {
    backgroundColor: 'white',
    paddingBottom: 20,
    maxWidth: 300,
  },
  header: {
    margin: 0,
    backgroundColor: 'white',
  },
  row: {
    display: 'inline-block',
  },
})

type SearchVgesForLinkageListDtoType = z.infer<typeof SearchVgesForLinkageListDto>;

const SearchVgeForLinkageToGebouwdeel: React.FC<IEditPanelProps> = props => {
  const { validatedSearchParams, setOrder, setPage, setFilter } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState([] as number[])
  const [error, setError] = React.useState<string>()

  const {data , isLoading} = zodiosHooks.useGetVgesearchVgesForLinkage({
    params: {},
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter,
      adresStatus: props.adresStatus
    }
  })

  const {mutate: addVgesToGebouwDeel } = zodiosHooks.usePostGebouwdelenVge({}, {
      onSuccess: () => props.invalidate(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IGebouw>({ resolver: yupResolver(gebouwSchema), mode: 'onChange' })

  const onSelectionChanged = (ids: number[]) => {
    setSelectedIds(ids)
  }

  const closePanel = () => {
    props.dismissPanel()
  }
  const onCancel = e => {
    closePanel()
  }
  const onSubmit = () => {
    if (selectedIds.length > 0) {
      addVgesToGebouwDeel({gebouwdeelId: props.gebouwDeelId as number, vgeIds: selectedIds})
      closePanel()
    }
  }

  const columns: IColumn[] = [
    createColumn('Eenheiddetailsoort', getPropertyName<SearchVgesForLinkageListDtoType>('srt'), 120),
    createCustomRenderedColumn(
      'Adres',
      getPropertyName<SearchVgesForLinkageListDtoType>('str'),
      item => (
        <div>
          {item.str} {item.nr} {item.ltr} {item.nrT} {item.pc} {item.wpl}
        </div>
      ),
      'XL',
      false
    ),
    createCustomRenderedColumn(
      props.adresStatus === AdresStatus.MetTerrein ? 'Terreinclassificatie' : 'Adresaanduiding',
      getPropertyName<SearchVgesForLinkageListDtoType>(props.adresStatus === AdresStatus.MetTerrein ? 'trnNm' : 'aa'),
      item => (
        <div>
          {props.adresStatus === AdresStatus.MetTerrein ? item.trnNm : item.aa}
        </div>
      ),
      'XL',
      false
    ),
  ]

  return (
    <FluentPanel
      type={PanelType.medium}
      headerText={`Koppelen vastgoedeenheden ${props.adresStatus === AdresStatus.MetTerrein  ? 'met' :  'zonder'} adres`}
      isOpen={props.isOpen}
      onDismiss={closePanel}
      closeButtonAriaLabel="Sluiten"
      error={error}
    >
      <div className={classNames.wrapper}>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
          </Sticky>

          <VgtPagedTable
            isLoading={isLoading}
            items={data?.items ?? []}
            columns={columns}
            height={'203'}
            pagingInfo={{
              pageSize: validatedSearchParams.pageSize,
              currentPage: validatedSearchParams.page,
              totalCount: data?.totalCount ?? 0,
              totalPages: data?.totalPages ?? 0,
              hasNextPage: data?.hasNextPage ?? false,
              hasPreviousPage: data?.hasPreviousPage ?? false,
              sortKey: validatedSearchParams.sortKey || 'vgeId',
              sortDirection: validatedSearchParams.sortDirection || 'desc'
            }}
            onSelectionChanged={onSelectionChanged}
            getKey={(item) => item?.vgeId}
            onPageChanged={setPage}
            onSortChanged={setOrder}
          />
          <br />
          <br />
        </form>
      </div>
      <FluentPanel.Footer>
        <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
          <DefaultButton text="Koppelen" type="submit" primary disabled={isSubmitting} onClick={onSubmit} />
          <DefaultButton text="Annuleren" onClick={onCancel} />
        </Stack>
      </FluentPanel.Footer>
    </FluentPanel>
  )
}

export default SearchVgeForLinkageToGebouwdeel
