import React, { useEffect, useState } from 'react'
import { DefaultButton, Panel, PanelType, Stack } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from 'store'
import { getPropertyName } from 'lib/interfaceUtils'
import { useSelector } from 'react-redux'
import {
  getSelectedEntity,
  ITerreinClassificatie,
  terreinClassificatieSchema,
  fetchAll,
  updateTerreinClassificatie,
  addTerreinClassificatie,
  getLoadingState,
} from './terreinClassificatieSlice'
import VgtTextField from 'components/VgtTextField'
import useRoles from 'services/UseRoles'

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  selectedDefectLocatieId?: string
}

const EditPanelTerreinClassificatie: React.FC<IEditPanelProps> = props => {
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState('Terrein')
  const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
  const fetchStatus = useSelector(getLoadingState)
  const selectedEntity = useSelector(getSelectedEntity)
  const { isAdmin } = useRoles()

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<ITerreinClassificatie>({ resolver: yupResolver(terreinClassificatieSchema), mode: 'all' })

  useEffect(() => {
    setCloseOnFulFilled(false)
    dispatch(fetchAll())
  }, [])

  useEffect(() => {
    setTitle(`Terrein ${selectedEntity ? isAdmin ? 'wijzigen' : '' : 'toevoegen'}`)
    if (selectedEntity) {
      reset(selectedEntity)
    } else {
      reset({})
    }
  }, [selectedEntity])

  const onCancel = e => {
    props.dismissPanel()
  }

  const onSubmit = data => {
    data.ingangsDatum = new Date().toJSON()  
    if (selectedEntity) {
      data.id = selectedEntity.id
      dispatch(updateTerreinClassificatie(data))
      setCloseOnFulFilled(true)
    } else {
      dispatch(addTerreinClassificatie(data))
      setCloseOnFulFilled(true)
    }
  }
  
  useEffect(() => {
    if (closeOnFulFilled && fetchStatus === 'succeeded') props.dismissPanel()
  }, [fetchStatus])

  return (
    <div>
    <Panel type={PanelType.medium} headerText={title} isOpen={props.isOpen} onDismiss={onCancel} closeButtonAriaLabel="Sluiten">
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
      <VgtTextField
        label="Terreinclassicatie"
        name={getPropertyName<ITerreinClassificatie>('terrein')}
        register={register}
        control={control}
        errors={errors}
        required
        readOnly={!isAdmin ? true : false}
      />
        <br />
        { isAdmin ?
          <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
            <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} onClick={handleSubmit(onSubmit)} /> 
            <DefaultButton text="Annuleren" onClick={onCancel} />
          </Stack> : null
        }
      </form>
    </Panel>
    </div>
  )
}

export default EditPanelTerreinClassificatie