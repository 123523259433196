import { Panel, PanelType } from '@fluentui/react'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import VgtTextField from '../../../components/VgtTextField'
import { getPropertyName } from '../../../lib/interfaceUtils'
import React from 'react'
import { z } from 'zod'
import { VastgoedportefeuilleAddOrUpdateDto, zodiosHooks } from '../../../api/ApiClient'
import useRoles from '../../../services/UseRoles'
import { getTitleAndMessage } from '../../../services/HandleError'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'

export interface IEditPanelProps {
  vastgoedportefeuilleId: number,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof VastgoedportefeuilleAddOrUpdateDto>;

export const VastgoedportefeuilleFormPanel: React.FC<IEditPanelProps> = props => {

  const { isVastgoedbeheer } = useRoles()
  const [error, setError] = React.useState<string>()


  const defaultVastgoedportefeuille = {
    naam: ''
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(VastgoedportefeuilleAddOrUpdateDto), mode: 'all', defaultValues: defaultVastgoedportefeuille
  })

  zodiosHooks.useGetVastgoedportefeuilleId({ params: { id: props.vastgoedportefeuilleId } }, {
      onSuccess: (data) => reset(data),
      enabled: props.vastgoedportefeuilleId !== 0
    }
  )


  const onCancel = () => {
    props.invalidate()
    reset({})
    props.dismissPanel()
  }

  const onSubmit = (data: CreateUpdateType) => {
    if (props.vastgoedportefeuilleId !== 0) {
      updateVastgoedportefeuille(data)
    } else {
      addVastgoedportefeuille(data)
    }
  }

  const {
    mutate: updateVastgoedportefeuille
  } = zodiosHooks.usePutVastgoedportefeuilleId(
    { params: { id: props.vastgoedportefeuilleId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: addVastgoedportefeuille } = zodiosHooks.usePostVastgoedportefeuille(
    {}, { onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  return (
    <div>
      <Panel type={PanelType.medium}
             headerText={`Vastgoedportefeuille ${props.vastgoedportefeuilleId !== 0 ? 'wijzigen' : 'toevoegen'}`}
             isOpen={props.isOpen}
             onDismiss={onCancel}
             closeButtonAriaLabel="Sluiten">
        <br />
        <ErrorMessageBar error={error} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField
            label="Naam"
            name={getPropertyName<CreateUpdateType>('naam')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isVastgoedbeheer}
          />
          <VgtTextField
            label="Code"
            name={getPropertyName<CreateUpdateType>('code')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isVastgoedbeheer}
          />

          <br />

          {isVastgoedbeheer ?
            <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                                 onOkClick={() => handleSubmit(onSubmit)} onCancelClick={onCancel} /> : null}
        </form>
      </Panel>
    </div>
  )
}