import './index.css'
import React, { useEffect } from 'react'
import { IColumn, SelectionMode } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import FluentTable from 'components/FluentTable'
import { getPropertyName } from 'lib/interfaceUtils'
import { IImportTaak } from './model'
import { fetchAll, selectAll } from './slice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'

const handleGetKey = (item: IImportTaak) => {
  return item.id?.toString()
}

const handleItemInvoked = (_id: string) => { }

const ImportResults: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const refreshItems = () => {
    dispatch(fetchAll())
  }

  const items = useSelector(selectAll)

  useEffect(() => {
    refreshItems()
  }, [])

  const columns: IColumn[] = [
    {
      key: getPropertyName<IImportTaak>('omschrijving'),
      name: 'Omschrijving',
      fieldName: getPropertyName<IImportTaak>('omschrijving'),
      minWidth: 100,
      maxWidth: 400,
      className: 'overflowY',
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    },
    {
      key: getPropertyName<IImportTaak>('laatstUitgevoerd'),
      name: 'Tijdstip',
      fieldName: getPropertyName<IImportTaak>('laatstUitgevoerd'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<IImportTaak>('status'),
      name: 'Status',
      fieldName: getPropertyName<IImportTaak>('status'),
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    },
    {
      key: getPropertyName<IImportTaak>('bron'),
      name: 'Bron',
      fieldName: getPropertyName<IImportTaak>('bron'),
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      data: 'bron',
    },
  ]

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
  ]

  return (
    <MainTemplate title='Importtaken' subTitle='Overzicht' commandItems={commandItems}>
      <div style={{ height: '90vh' }}>
        <FluentTable
          columns={columns}
          items={items}
          loading={false}
          selectionMode={SelectionMode.none}
          onGetKey={handleGetKey}
          onItemInvoked={handleItemInvoked}
        />
      </div>
    </MainTemplate>
  )
}

export default ImportResults
