import { Dictionary } from '@reduxjs/toolkit'
import { IColumn, Link } from '@fluentui/react'
import ILEmDOEntity, { ILEmDOCodeEntity } from './lemdoEntity'
import { transformDateString } from './support'
import { getPropertyName } from 'lib/interfaceUtils'
import { createBooleanColumn } from 'lib/gridHelper'

export interface IColumnCreateProps<TLEmDOEntity extends ILEmDOEntity> {
  minWidth: number
  maxWidth: number
  onRender?: (item: TLEmDOEntity) => JSX.Element
}

export function createColumn<TLEmDOEntity extends ILEmDOEntity>(
  propertyName: keyof TLEmDOEntity,
  props: IColumnCreateProps<TLEmDOEntity>,
  title?: string,
  dictColumnNames?: Dictionary<string>,
  classNames?: string,
  headerClassNames?: string
): IColumn {
  const keyName = getPropertyName<TLEmDOEntity>(propertyName) as string
  let name = title
  if (dictColumnNames) {
    const dictName = dictColumnNames[keyName]
    if (dictName) name = dictName
  }
  return {
    ...props,
    key: keyName,
    name: name as string,
    fieldName: keyName,
    isResizable: true,
    isRowHeader: true,
    className: classNames,
    headerClassName: headerClassNames
  }
}

export const columnsVera = [
  createBooleanColumn('VERA', 'vera', 80),
  createBooleanColumn('VERA versie', 'veraVersie', 80)
]

export const createDatumColumns = () => {
  return [
    createColumn(
      'ingangsDatum',
      {
        minWidth: 150,
        maxWidth: 150,
        onRender: (item: ILEmDOEntity) => <>{transformDateString(item.ingangsDatum)}</>
      },
      'Ingangsdatum',
      undefined,
      'justify-content-center',
      'justify-content-center'
    ),
    createColumn(
      'eindDatum',
      {
        minWidth: 150,
        maxWidth: 150,
        onRender: (item: ILEmDOEntity) => <>{transformDateString(item.eindDatum)}</>
      },
      'Einddatum',
      undefined,
      'justify-content-center',
      'justify-content-center'
    )
  ]
}

export const createCodeColumn = (columnNames: Dictionary<string>, clickHandler: (id: number) => void): IColumn => {
  return createColumn<ILEmDOCodeEntity>(
    'code',
    {
      minWidth: 50,
      maxWidth: 250,
      onRender: (item: ILEmDOCodeEntity) => (
        <Link key={item.code} onClick={() => clickHandler(item.id)}>
          {item.code}
        </Link>
      )
    },
    'Code',
    columnNames
  )
}
