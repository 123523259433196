import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { EntityObjectState, createInitialState, IEntityPageObjectResponse } from 'lib/sliceSupport'
import IElementDefectSoortMatrixRow from './model'

const initState: EntityObjectState<IElementDefectSoortMatrixRow> = createInitialState<IElementDefectSoortMatrixRow>()

const entityAdapter = createEntityAdapter<IElementDefectSoortMatrixRow>()

export const fetchAll = createAsyncThunk('/elementDefectSoortTaken/fetchAll', async (_, thunkAPI) => {
  return await invokeFetch<IEntityPageObjectResponse<IElementDefectSoortMatrixRow>>(thunkAPI, 'GET', '/api/element/pageTaken?skip=0&top=50000')
})

export const elementDefectSoortTaakSlice = createSlice({
  name: 'elementDefectSoortTaken',
  initialState: entityAdapter.getInitialState(initState),

  reducers: {
    select: (state, action: PayloadAction<number | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
    removeAll: entityAdapter.removeAll,
  },

  extraReducers: builder => {
    builder.addCase(fetchAll.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<IEntityPageObjectResponse<IElementDefectSoortMatrixRow>>) => {
      state.status = 'succeeded'
      const items = action.payload.items
      entityAdapter.setAll(state, items)
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || null
    })
  },
})

export const getSelectedEntity = (state: RootState) => {
  if (state.elementDefectSoortTaken.selectedId) return state.elementDefectSoortTaken.entities[state.elementDefectSoortTaken.selectedId]
  else return undefined
}

export const { add, modify, removeMany, select, clearSelection, removeAll } = elementDefectSoortTaakSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.elementDefectSoortTaken)

export default elementDefectSoortTaakSlice.reducer
