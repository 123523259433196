import { Dialog, DialogContent, DialogType, Icon, IDialogContentProps, IModalProps, Stack, Text } from '@fluentui/react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'

const dropZoneStyles: React.CSSProperties = {
  border: '2px dashed #bdbdbd',
  color: '#bdbdbd',
  borderRadius: 2,
  padding: 5,
  minHeight: 125,
}

interface IFileUploadDialogProps {
  title: string
  accept?: string | string[]
  disabled?: boolean
  loading?: boolean
  multiple?: boolean;
  onCancel: () => void
  onFilesSelected: (files: File[]) => void,
  onDrop?: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
  children?: React.ReactNode
}

const FileUploadDialog: React.FC<IFileUploadDialogProps> = props => {
  const { title, accept, onCancel, onFilesSelected, loading = false, children, multiple } = props

  const modalProps = useMemo(
    (): IModalProps => ({
      isBlocking: true,
      layerProps: { eventBubblingEnabled: true },
    }),
    []
  )

  const dialogContentProps = useMemo(
    (): IDialogContentProps => ({
      type: DialogType.normal,
      title: title,
    }),
    [title]
  )

  const handleCancel = useCallback(() => {
    !loading && onCancel()
  }, [onCancel, loading])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept,
    multiple: multiple,
    onDrop: props.onDrop,
  })

  useEffect(() => {
    acceptedFiles.length > 0 && onFilesSelected(acceptedFiles)
  }, [acceptedFiles, onFilesSelected])

  return (
    <Dialog hidden={false} onDismiss={handleCancel} dialogContentProps={dialogContentProps} modalProps={modalProps} minWidth={500}>
      <DialogContent styles={{ header: { height: 0, width: 0 }, inner: { padding: 0 } }}>
        <div
          {...getRootProps({
            className: 'dropzone',
            style: dropZoneStyles,
          })}
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <input {...getInputProps()} />
            <Text styles={{ root: { textAlign: 'center' } }}>Sleep bestanden hier naartoe of klik om bestanden te selecteren.</Text>
            <Stack horizontal horizontalAlign="center">
              <Icon iconName="OpenFolderHorizontal" styles={{ root: { fontSize: 50, height: 50, width: 50 } }} />
            </Stack>
          </Stack>
        </div>
      </DialogContent>
      {children}
    </Dialog>
  )
}

export default FileUploadDialog
