import React, { useEffect, useMemo, useState } from 'react'
import { IColumn, Link, SelectionMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import FluentTable from 'components/FluentTable'
import { getPropertyName } from 'lib/interfaceUtils'
import WoningverbeteringToestemmingEditPanel from './editPanel'
import {
    selectAll,
    select,
    clearSelection,
    fetchAll,
    deleteWoningverbeteringToestemming,
    getLoadingState,
    IWoningverbeteringToestemming,
} from './woningverbeteringToestemmingSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'
import { createCustomRenderedColumn } from 'lib/gridHelper'


const WoningverbeteringToestemmingList: React.FC<{}> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const items = useSelector(selectAll)
  const loadingState = useSelector(getLoadingState)
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const handleListSelectionChanged = (ids: string[]) => setSelectedItems(ids)
  const refreshItems = () => dispatch(fetchAll())
  const onGetKey = (item: IWoningverbeteringToestemming) => item.woningverbeteringToestemmingId.toString()
  const { isReader } = useRoles()

  useEffect(() => {
    refreshItems()
  }, []);

  const handleItemInvoked = (id: string) => {
    dispatch(select(id))
    openPanel()
  }

  const removeItems = () => {
    dispatch(deleteWoningverbeteringToestemming(selectedItems.map(value => parseInt(value))))
  }

  const commandItems = useMemo(() =>[
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    { visible: isReader, text: 'Toevoegen', onClick: () => { dispatch(clearSelection()); openPanel() }, icon: 'Add' },
    { visible: isReader, text: 'Verwijderen', onClick: () => removeItems(), icon: 'Delete', disabled: !selectedItems.length },
  ], [isReader, selectedItems.length])

  const renderCode = (x: IWoningverbeteringToestemming) => <Link key={x.woningverbeteringToestemmingId} onClick={() => handleItemInvoked(x.woningverbeteringToestemmingId.toString())}>{x.code}</Link>

    const columns: IColumn[] = [
      createCustomRenderedColumn('Code', getPropertyName<IWoningverbeteringToestemming>('woningverbeteringToestemmingId'), renderCode, 'XXL', false),
    ]

  return (<>
    <MainTemplate title='Woningverbetering || Toestemmingcodes' subTitle='Overzicht' commandItems={commandItems}>
      <WoningverbeteringToestemmingEditPanel dismissPanel={dismissPanel} isOpen={isPanelOpen} />
      <div style={{ height : '90vh' }}>
        <FluentTable
          columns={columns}
          items={items}
          loading={loadingState === 'pending'}
          selectionMode={SelectionMode.multiple}
          onSelectionChanged={handleListSelectionChanged}
          onGetKey={onGetKey}
          onItemInvoked={handleItemInvoked}
        />
      </div>
    </MainTemplate>
    </>)
}

export default WoningverbeteringToestemmingList
