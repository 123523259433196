import React from 'react'
import { zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import SleutelsRow from './sleutelsRow'
import { DefaultButton, IStackStyles, Stack, Text } from '@fluentui/react'
import { usePagedParams } from '../../../../services/usePagedParams'

interface IProps {
  vgeId: number;
}

const stackStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    margin: '15px 0 10px 0',
    overflow: 'auto',
    '@media (max-height: 1080px)': {
      height: '400px'
    }
  }
}

export const Sleutels = ({ vgeId }: IProps) => {
  const [error, setError] = React.useState<string>()
  const { validatedSearchParams, setPage } = usePagedParams()

  const { data, invalidate } = zodiosHooks.useGetVgeSleutel(
    {
      queries: {
        VgeId: vgeId ?? 0,
        Filter: validatedSearchParams.filter,
        SortKey: validatedSearchParams.sortKey,
        SortDirection: validatedSearchParams.sortDirection,
        PageIndex: validatedSearchParams.page,
        PageSize: validatedSearchParams.pageSize
      }
    },
    { onError: (error) => setError(getTitleAndMessage(error).message) })

  return (
    <>
      <div style={{ overflowY: 'auto', marginBottom: 5 }}>
        <Stack styles={stackStyles}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
            <Text style={{ width: 200, fontWeight: '800', paddingLeft: 10 }}>Sleuteltype</Text>
            <Text style={{ width: 120, textAlign: 'center', minWidth: 110, fontWeight: '800' }}>Aantal</Text>
            <Text style={{ width: 400, minWidth: 200, fontWeight: '800', paddingLeft: 5 }}>Certificaatnummer</Text>
          </div>
          {data?.items?.map((item, index) => (
            <SleutelsRow key={index} item={item} handleSubmissionSuccess={invalidate} />
          ))}
        </Stack>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <DefaultButton iconProps={{ iconName: 'previous' }} disabled={!data?.hasPreviousPage}
                           onClick={() => setPage(validatedSearchParams.page - 1)} />
          </div>
          <div style={{ paddingLeft: 12, paddingTop: 4 }}>
            <Text>{data?.totalCount} resultaten.
              Pagina {(validatedSearchParams.page).toString()} van {data?.totalPages}</Text>
          </div>
          <div style={{ paddingLeft: 12 }}>
            <DefaultButton iconProps={{ iconName: 'next' }} disabled={!data?.hasNextPage}
                           onClick={() => setPage(validatedSearchParams.page + 1)} />
          </div>
        </div>
      </div>
      <ErrorMessageBar error={error} />
    </>
  )
}
