import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { entityAdapterInitState } from 'interfaces/entityAdapterState'
import { setPendingState, setRejectedState, setSucceededState } from 'lib/sliceSupport'
import { RootState } from 'store'

export interface ITenant {
    id: number
    name: string
    cyclomediaUsername: string
    cyclomediaPassword: string
    cyclomediaApiKey: string
    vheUrlToRentalApplication: string
    vgeUrlToRentalApplication: string
    isLight: boolean
}

const entityAdapter = createEntityAdapter<ITenant>()

export const fetchCurrentTenant = createAsyncThunk('tenant/fetchCurrent', async (_, thunkAPI) => {
    return await invokeFetch<ITenant>(thunkAPI, 'GET', '/api/tenant')
})

const getSliceState = (state: RootState) => state.tenants

export const tenantSlice = createSlice({
    name: 'tenants',
    initialState: entityAdapter.getInitialState(entityAdapterInitState),

    reducers: {
        select: (state, action: PayloadAction<string | undefined>) => {
            state.selectedId = action.payload
        },
    },

    extraReducers: builder => {
        builder.addCase(fetchCurrentTenant.pending, state => setPendingState(state))
        builder.addCase(fetchCurrentTenant.fulfilled, (state, action: PayloadAction<ITenant>) => {
            setSucceededState(state)
            entityAdapter.upsertOne(state, action.payload)
            state.selectedId = action.payload.id.toLocaleString()
        })
        builder.addCase(fetchCurrentTenant.rejected, (state, action) => setRejectedState(state, action))
    }
})

export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
}

export default tenantSlice.reducer
