import * as yup from 'yup'

export interface IDimensiewaardeList {
  id: number;
  code: string;
  omschrijving: string;
}

export interface IDimensiewaardeUpdateOrAdd {
  code: string;
  omschrijving: string;
}

export const DimensiewaardeSchema = yup.object().shape({
  code: yup.string().max(100).required(),
  omschrijving: yup.string().max(500).required(),
})
