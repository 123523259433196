import React from 'react'
import {Controller} from 'react-hook-form'
import {Toggle, IToggleProps, IIconProps} from '@fluentui/react'
import {TOGGLE_ON, TOGGLE_OFF} from 'lib/constants/labels'

interface IVgtFieldProps extends IToggleProps {
    name: string
    label: string
    control: any
    errors: any
    register?: any
    required?: boolean
    readOnly?: boolean
    iconProps?: IIconProps
    pattern?: string
    defaultValue?: string
    height?: string | number
    labelWidth?: number
    autoAdjustHeight?: boolean
    disabled?: boolean
}


const FluentToggle: React.FC<IVgtFieldProps> = props => {
    return (
        <div>
            <Controller
                name={props.name}
                control={props.control}
                render={({onChange, value}) => (
                    <Toggle label={props.label} inlineLabel onText={TOGGLE_ON} offText={TOGGLE_OFF}
                            onChange={(e, checked) => {
                                onChange(checked)
                            }}
                            checked={value ? true : false}
                            styles={{
                                root: {padding: '0 0 0 12px'},
                                label: {width: '200px', fontWeight: 'normal'},
                            }}
                    />
                )}
            />
        </div>
    )
}

export default FluentToggle
