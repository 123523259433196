import React from 'react'
import {
  DatePicker,
  DayOfWeek,
  IDatePickerStrings,
  mergeStyleSets,
  IDatePickerProps,
  IDatePickerStyles
} from '@fluentui/react'
import { IVgtFieldPropsBase } from '../../lib/IVgtFieldPropsBase'
import { Controller } from 'react-hook-form'

const DayPickerStrings: IDatePickerStrings = {
  months: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],

  shortMonths: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],

  days: ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'],

  shortDays: ['M', 'D', 'W', 'D', 'V', 'Z', 'Z'],

  goToToday: 'Naar vandaag',
  prevMonthAriaLabel: 'Naar vorige maand',
  nextMonthAriaLabel: 'Naar volgende maand',
  prevYearAriaLabel: 'Naar vorig jaar',
  nextYearAriaLabel: 'Naar volgend jaar',
  closeButtonAriaLabel: 'Datumkiezer sluiten',
  monthPickerHeaderAriaLabel: '{0}, selecteer om de maand te wijzigen',
  yearPickerHeaderAriaLabel: '{0}, selecteer om het jaar te wijzigen',

  isRequiredErrorMessage: 'Dit veld is verplicht.',

  invalidInputErrorMessage: 'Ongeldig datumformaat.'
}

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px'
  },
  wrapper: {
    borderBottom: '1px solid rgb(219 219 219)'
  }
})

const datePickerStyles: Partial<IDatePickerStyles> = {
  root: {
    borderBottom: '1px solid rgb(219 219 219)',
  },
  textField: {
    width: '190px',
    paddingLeft: '10px',
    label: {
      fontWeight: 'Normal',
    }
  }
}

export interface IVgtDatePickerProps extends IDatePickerProps, IVgtFieldPropsBase {
  name: string
  label: string
  control: any
  register?: any
  errors?: any
  required?: boolean
  readOnly?: boolean
}

export const formatNLDate = (date?: Date | null) => {
  if (!date)
    return ''
  const dateStr = date.getDate().toString().padStart(2, '0') + '-' + (date.getMonth()+1).toString().padStart(2, '0') + '-' + date.getFullYear()
  return dateStr
}
export const formatIsoDate = (date?: Date | null) => {
  if (!date)
    return ''
  const dateStr =  date.getFullYear()+ '-' + (date.getMonth()+1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0')
  return dateStr
}
const toDate = (dateStr?: string | null) => {
  if (!dateStr)
    return undefined
  const [year, month, day] = dateStr.split("-");
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  return date
}

const VgtDatePicker: React.FC<IVgtDatePickerProps> = props => {
  const styles = datePickerStyles
  const className = controlClass.control
  if (props.showIf === false)
    return <></>
  else return (
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.defaultValue ?? props.control.defaultValue}
        render={({ onBlur, onChange, ref, value }) => (
          <DatePicker
            ref={ref}
            disabled={props.readOnly}
            className={className}
            label={props.label}
            firstDayOfWeek={DayOfWeek.Monday}
            strings={DayPickerStrings}
            placeholder='Kies een datum...'
            ariaLabel='Kies een datum'
            formatDate={formatNLDate}
            onSelectDate={(date) => onChange(formatIsoDate(date))}
            onBlur={onBlur}
            value={toDate(value)}
            styles={styles}
          />
        )}
      />
  )
}

export default VgtDatePicker
