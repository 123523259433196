import {
  ActionButton,
  Callout,
  ICalloutContentStyles,
  Link,
  Stack,
  Text
} from '@fluentui/react'
import React, { useState } from 'react'
import IGraphUserProfile from 'interfaces/graphUserProfile'
import { useMsal } from '@azure/msal-react'
import { useSelector } from 'react-redux'
import { selectAll } from '../../store/actions/app/rolesSlice'

const calloutStyles: Partial<ICalloutContentStyles> = {
  root: {
    width: '250px',
    maxWidth: '400px',
    backgroundColor: '#fff',
    top: '48px !important',
    right: '0px !important'
  }
}

interface IProfileCalloutProps {
  visible: boolean
  onDismiss: () => void
  targetRef: React.RefObject<Element>
  profile?: IGraphUserProfile
}

const ProfileCallout: React.FC<IProfileCalloutProps> = ({ visible, onDismiss, targetRef, profile }) => {
  const { instance } = useMsal()
  const userRoles = useSelector(selectAll)
  const [rolesVisible, setRolesVisible] = useState(false)

  const logout = () => {
    instance.logout()
  }

  return (
    <>
      {visible && (
        <Callout onDismiss={onDismiss} setInitialFocus styles={calloutStyles} target={targetRef}>
          <Stack styles={{ root: { margin: 10, textAlign: 'right' } }} tokens={{ childrenGap: 5 }}>
            <Text
              variant="medium"
              styles={{ root: { fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden' } }}
            >
              {profile?.displayName}
            </Text>
            <Text variant="medium" styles={{ root: { textOverflow: 'ellipsis', overflow: 'hidden' } }}>
              {profile?.mail}
            </Text>
            <Text variant="medium" styles={{ root: { textOverflow: 'ellipsis', overflow: 'hidden' } }}>
              {profile?.jobTitle}
            </Text>
            <ActionButton
              style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', marginTop:'8px', marginBottom: '-8px', padding: 0 }}
              styles={{ label: { marginRight: 0} }}
              iconProps={{ iconName: rolesVisible ? 'ChevronUp' : 'ChevronDown', style: { paddingTop: '2px', fontSize: 14 } }}
              onClick={() => setRolesVisible(!rolesVisible)}
              ariaLabel={'Toegewezen rollen'}
            >
              Toegewezen rollen
            </ActionButton>
              {rolesVisible && (
                userRoles.map((role) => (
                  <Text key={role.id} variant="small" styles={{ root: { textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: '6px' } }}>
                    {role.id}
                  </Text>
                ))
                // <DetailsList isHeaderVisible={false} compact={true}  onRenderDetailsHeader={() => null} onRenderRow={_onRenderRow} columns={columns} items={userRoles} selectionMode={SelectionMode.none} />
              )}
            <Link styles={{ root: { textAlign: 'right', paddingTop: '10px' } }} onClick={logout}>
              Uitloggen
            </Link>
          </Stack>
        </Callout>
      )}
    </>
  )
}

export default ProfileCallout
