import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { INavLink, INavStyles, Nav, useTheme } from '@fluentui/react'
import { selectAll } from 'store/actions/app/rolesSlice'
import { Route, RouteGroup, routes } from './routing'
import { availableUrls } from './AvailableUrls'
import { useNavigate } from 'react-router-dom'
import { AvailableRoles } from '../services/UseRoles'

const NavigationBar: React.FC = () => {
  const roles = useSelector(selectAll)

  const navigate = useNavigate()
  const theme = useTheme()
  const [selectedKey, setSelectedKey] = useState('')

  const expandedState = {}
  const [isExpandedState, setIsExpandedState] = useState(expandedState)

  const navStyles: Partial<INavStyles> = {
    root: {
      boxSizing: 'border-box',
      padding: 8,
      paddingBottom: 24,
      overflowY: 'auto',
      fontWeight: 'bold',
      backgroundColor: '#f3f3f3',
      height: '100vh',
      boxShadow: '1px 1px 3px 0px rgba(0,0,0,.132), 2px 0px 6px 0 rgba(0,0,0,.108)'
    },
    chevronIcon: {
      color: theme.palette.themePrimary
    },
    linkText: {
      color: 'black',
      fontSize: '14.5px',
      fontWeight: 400
    },
    link: {
      whiteSpace: 'normal',
      lineHeight: 'inherit'
    },
    groupContent: {
      marginBottom: 0
    },
    chevronButton: {
      color: 'black',
      fontSize: '14.5px',
      fontWeight: 400,
      borderBottom: 0
    },
    navItem: {
      paddingLeft:16
    }
  }

  const checkIfUserHasAccess = (allowedRoles: (keyof typeof AvailableRoles)[]) => {
    return (
      allowedRoles?.some((allowedRole) =>
        roles?.some((userRole) => userRole.id === allowedRole)
      )
    )
  }

  const onLinkExpandClick = (_, item) => {
    const updatedExpandedState = { ...isExpandedState }
    if (updatedExpandedState[item.key] === undefined) {
      updatedExpandedState[item.key] = false
    }
    updatedExpandedState[item.key] = !updatedExpandedState[item.key]
    setIsExpandedState(updatedExpandedState)
  }

  const onClick = (path: string) => {
    if (!path) return
    navigate(availableUrls[path])
    setSelectedKey(`route-${path}`)
  }

  const getTopLevelRoutes = (item: Route) => {
    if (checkIfUserHasAccess(item.allowedRoles) && item.showInNav) {
      return [
        {
          key: item.path,
          name: item.displayName,
          target: '_self',
          onClick: () => onClick(item.path),
          icon: item.icon,
          url: ''
        }
      ]
    } else {
      return []
    }
  }

  const filteredRoutesGroupsWithShowInNavSetToFalse = routes.filter(item => {
    if ('TopLevelGroup' in item && item.TopLevelGroup) {
      return item.TopLevelGroup.some(
        (routeGroup: RouteGroup) => routeGroup.RouteGroups?.some(innerRoute => recursiveCheckNestedRoutes(innerRoute))
      )
    } else {
      return ('showInNav' in item && item.showInNav && checkIfUserHasAccess(item.allowedRoles));
    }
  })

  function recursiveCheckNestedRoutes(route: (Route | RouteGroup)) {
    if ('RouteGroups' in route && route.RouteGroups) {
      return route.RouteGroups.some(recursiveCheckNestedRoutes)
    } else {
      return ('showInNav' in route && route.showInNav && checkIfUserHasAccess(route.allowedRoles));
    }
  }

  const filtered = (data) =>(
    data.filter(item => {
      if (item.RouteGroups) {
        return item.RouteGroups.some(innerRoute => recursiveCheck(innerRoute)) || checkIfUserHasAccess(item.allowedRoles);
      } else {
        return checkIfUserHasAccess(item.allowedRoles);
      }
    })
  );

  function recursiveCheck(route) {
    if (route.RouteGroups) {
      return route.RouteGroups.some(recursiveCheck);
    } else {
      return checkIfUserHasAccess(route.allowedRoles);
    }
  }

  return (
    <Nav
      ariaLabel="VGT NavBar"
      styles={navStyles}
      selectedKey={selectedKey}
      groups={filteredRoutesGroupsWithShowInNavSetToFalse
        .map((routeRouteGroup) => ({
          name: 'TopLevelGroup' in routeRouteGroup && routeRouteGroup.TopLevelGroup ? routeRouteGroup.displayName : undefined,
          key: 'key' in routeRouteGroup ? routeRouteGroup.key : undefined,
          expandAriaLabel: 'Expanded section',
          collapseByDefault: 'TopLevelGroup' in routeRouteGroup ? routeRouteGroup.TopLevelGroup?.length! > 0 : false,
          links:
            'TopLevelGroup' in routeRouteGroup && routeRouteGroup.TopLevelGroup
              ? filtered(routeRouteGroup.TopLevelGroup).map((routeGroup: RouteGroup) => ({
                name: routeGroup.displayName,
                key: routeGroup.key,
                expandAriaLabel: 'Expand section',
                collapseAriaLabel: 'Collapse section',
                isExpanded: isExpandedState[routeGroup.key],
                url: '',
                links: routeGroup.RouteGroups
                  ? (routeGroup.RouteGroups
                    .filter((route: Route) => route.showInNav && checkIfUserHasAccess(route.allowedRoles))
                    .map((route: Route) => ({
                      key: `route-${route.path}`,
                      name: route.displayName,
                      onClick: () => onClick(route.path),
                      icon: route.icon ?? undefined,
                      target: '_self',
                      url: ''
                    })) as INavLink[])
                  : undefined

              })) as INavLink[]
              : getTopLevelRoutes(routeRouteGroup as Route)
        }))}
      onLinkExpandClick={onLinkExpandClick}
    />
  )

}

export default NavigationBar
