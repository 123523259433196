import React, { FC } from 'react'
import { IDropdownOption } from '@fluentui/react'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import VgtControlGroup from '../../../../components/ControlGroup'
import VgtCheckbox from '../../../../components/FluentCheckbox'
import VgtDropdown from '../../../../components/FluentDropdown'
import VgtMultiselectDropdown from '../../../../components/FluentMultiselectDropdown'
import VgtFormattedText from '../../../../components/VgtFormattedText'
import { Betaler, CreateUpdateType, TaakType, taakTypeOptions } from './TenantTaakFormPanel'
import VgtNumberField from '../../../../components/FluentNumberField'

export const InnerColumn: FC<{ visible: boolean, meetkundigeEenheden?: IDropdownOption[], onderhoudsspecialisme?: IDropdownOption[], onderhoudstaken?: IDropdownOption[], setValue: any, inspectieSpecialismeId: number, betalers?: IDropdownOption[], dienstencomponenten?: IDropdownOption[], isTaskActive: boolean, suffix: string, label: string, watch: any, control: any, errors: any, required?: boolean }>
  = ({ visible,meetkundigeEenheden, onderhoudsspecialisme, onderhoudstaken, setValue, inspectieSpecialismeId, betalers, dienstencomponenten, isTaskActive, suffix, label, watch, control, errors }) => {

  const isSpoed = watch(getPropertyName<CreateUpdateType>('isSpoed') + suffix) === true
  const taakType = watch(getPropertyName<CreateUpdateType>('taakTypeOptieId') + suffix)
  const isDienstenComponent = watch(getPropertyName<CreateUpdateType>('betalerOptieId') + suffix) === Betaler.Dienstencomponent
  const isBetalerHuurder = watch(getPropertyName<CreateUpdateType>('betalerOptieId') + suffix) === Betaler.Huurder

  const resetSpoedAndSpecialisme = (checked: boolean) => {
    if (checked) {
      setValue(getPropertyName<CreateUpdateType>('isSpoed') + suffix, false)
      setValue(getPropertyName<CreateUpdateType>('onderhoudsspecialismeId') + suffix, inspectieSpecialismeId)
    } else {
      setValue(getPropertyName<CreateUpdateType>('onderhoudsspecialismeId') + suffix, 0)
    }
  }
  const vereistInspectieChanged = (checked: boolean | undefined) => {
    if (checked === undefined) return

    resetSpoedAndSpecialisme(checked)
    if (checked) {
      setValue(getPropertyName<CreateUpdateType>('teBeoordelen') + suffix, false)
    }
  }
  const teBeoordelenChanged = (checked: boolean | undefined) => {
    if (checked === undefined) return

    resetSpoedAndSpecialisme(checked)
    if (checked) {
      setValue(getPropertyName<CreateUpdateType>('inspectieVereist') + suffix, false)
    }
  }

  const isSpoedChanged = (checked: boolean | undefined) => {
    if (checked === undefined) return

    setValue(getPropertyName<CreateUpdateType>('inspectieVereist') + suffix, false)
  }

  return (
    <>
      <VgtControlGroup name={label} label={label} showIf={visible}>
        <VgtCheckbox disabled={!isTaskActive} label="Tonen aan klant" name={getPropertyName<CreateUpdateType>('tonenAanKlant') + suffix} control={control} />
        <VgtCheckbox disabled={!isTaskActive} label="Afspraak" name={getPropertyName<CreateUpdateType>('afspraak') + suffix} control={control} />
        <VgtDropdown disabled={!isTaskActive} options={betalers} label="Betaler" name={getPropertyName<CreateUpdateType>('betalerOptieId') + suffix} control={control} errors={errors} />
        <VgtMultiselectDropdown disabled={!isTaskActive} showIf={isDienstenComponent} required={isDienstenComponent} options={dienstencomponenten} label="Dienstencomponenten" name={getPropertyName<CreateUpdateType>('dienstencomponentIds') + suffix} control={control} errors={errors} />
        <VgtCheckbox showIf={!isBetalerHuurder} disabled={!isTaskActive} afterChange={vereistInspectieChanged} label={'Vereist inspectie'} name={getPropertyName<CreateUpdateType>('inspectieVereist') + suffix} control={control} />
        <VgtCheckbox disabled={!isTaskActive} afterChange={teBeoordelenChanged} label={'Te beoordelen'} name={getPropertyName<CreateUpdateType>('teBeoordelen') + suffix} control={control} />

        <VgtDropdown required={false} disabled={!isTaskActive} options={taakTypeOptions} label="Taaktype" name={getPropertyName<CreateUpdateType>('taakTypeOptieId') + suffix} control={control} errors={errors} />
        <VgtFormattedText readOnly={true} showIf={taakType === TaakType.Onderhoudscontract && isTaskActive} required={isSpoed} label="Geen onderhoudscontract toelichting" name={getPropertyName<CreateUpdateType>('portalToelichtingBijOntbrekendContract') + suffix} control={control} errors={errors} height={'200px'} />
        <VgtFormattedText readOnly={true} showIf={taakType === TaakType.GeenService && isTaskActive} label="Geen service toelichting" name={getPropertyName<CreateUpdateType>('portalToelichtingZelfOplossen') + suffix} control={control} errors={errors} height={'200px'} />
        <VgtCheckbox disabled={!isTaskActive} label={'Is spoed'} afterChange={isSpoedChanged} name={getPropertyName<CreateUpdateType>('isSpoed') + suffix} control={control} />
        <VgtFormattedText readOnly={true} showIf={isSpoed && isTaskActive} required={isSpoed} label="Spoed toelichting" name={getPropertyName<CreateUpdateType>('spoedToelichting') + suffix} control={control} errors={errors} height={'200px'} />
      </VgtControlGroup>

      <VgtControlGroup name="erp-group" label="ERP" showIf={visible}>
        <VgtDropdown disabled={!isTaskActive} required={true} options={onderhoudstaken} label="Onderhoudstaak ERP" name={getPropertyName<CreateUpdateType>('onderhoudstaakErpId') + suffix} control={control} errors={errors} />
      </VgtControlGroup>

      <VgtControlGroup name="field-service-group" label="Field Service" showIf={visible}>
        <VgtDropdown disabled={!isTaskActive} options={onderhoudsspecialisme} label="Onderhoudsspecialisme" name={getPropertyName<CreateUpdateType>('onderhoudsspecialismeId') + suffix} control={control} errors={errors} required={false} />
        <VgtDropdown disabled={!isTaskActive} options={meetkundigeEenheden} label="Meetkundige eenheid" name={getPropertyName<CreateUpdateType>('meetkundigeEenheidId') + suffix} control={control} errors={errors} required={false} />
        <VgtNumberField disabled={!isTaskActive} label="Bewerkingstijd (min)" name={getPropertyName<CreateUpdateType>('bewerkingstijdMin') + suffix} control={control} errors={errors} />
        <VgtNumberField disabled={!isTaskActive} label="Urgentie (wrkdgn)" name={getPropertyName<CreateUpdateType>('urgentieDgn') + suffix} control={control} errors={errors} />
        <VgtNumberField disabled={!isTaskActive} label="Verkoopprijs (incl. btw)" name={getPropertyName<CreateUpdateType>('verkoopprijs') + suffix} control={control} errors={errors} />
        <VgtNumberField disabled={!isTaskActive} label="Kostprijs (incl. btw)" name={getPropertyName<CreateUpdateType>('kostprijs') + suffix} control={control} errors={errors} />
      </VgtControlGroup>
    </>
  )
}