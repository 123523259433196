import React, { useMemo, useState } from 'react'
import {
  DetailsList,
  IColumn,
  IStackTokens,
  Link,
  Pivot,
  PivotItem,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  StackItem,
  Text
} from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import { EntityType } from '../gebouwen/gebouwHoeveelheidList'
import { EditMode } from '../gebouwen/editPanelGebouwHoeveelheid'
import { useNavigate, useParams } from 'react-router-dom'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import DocumentList, { DocumentDoelType } from '../../documents/DocumentList'
import { GebouwListDto, zodiosHooks } from '../../../../api/ApiClient'
import { z } from 'zod'
import { PageIdDefinition, PAGES } from '../../../../pages'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import GoogleMapReact from 'google-map-react'
import InputCard from '../../../../components/InputCard'
import locatie from '../../../vge/icons/Vastgoedtabel_Icons_BAG-locatie.png'
import { useBoolean } from '@fluentui/react-hooks'
import { GebouwenClusterEditPanel } from './editGebouwenClusterPanel'
import ReadOnlyField from '../../../../components/ReadOnlyField'
import { HuurcontractbepalingenTooltip } from '../../huurcontractbepaling/HuurcontractbepalingShow'
import GebouwHoeveelheden from '../gebouwen/gebouwHoeveelheidList'

type IGebouwenclusterGebouwen = z.infer<typeof GebouwListDto>;

const stackTokens: IStackTokens = { padding: '0', childrenGap: '20 20' }

const EditGebouwenCluster: React.FC<{}> = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [documentCount, setDocumentCount] = useState(0)
  const [error, setError] = useState<string>()
  const [inEditMode, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false)
  const [selectedPivot, setSelectedPivot] = useState<string>('cluster_Gebouwen')

  const {
    data: selectedGebouwenCluster,
    invalidate: fetchSelectedGebouwenCluster
  } = zodiosHooks.useGetGebouwenClustersId({
    queries: { Id: parseInt(id!) },
    params: { id: id! }
  }, { enabled: id !== undefined })

  const { data: gebouwenClusters } = zodiosHooks.useGetGebouwenbyClusterIdClusterId(
    { params: { clusterId: parseInt(id!) } },
    { enabled: id !== undefined }
  )

  const {
    data: hoofdDocumentUrl,
    invalidate: refetchHoofdDocumentUrl
  } = zodiosHooks.useGetApiDocumentshoofdDocumentForDoelTypeDoelTypedoelIdDoelId({
      params: {
        doelType: DocumentDoelType.gebouwenCluster,
        doelId: parseInt(id!)
      }
    }, {
      onError: (error) => setError(getTitleAndMessage(error).message),
      enabled: id !== undefined
    }
  )

  const MarkerComponent = ({ text }: any) => <div className='pin'>{text}</div>

  const calculateCenter = () => {
    if (selectedGebouwenCluster) {
      if (selectedGebouwenCluster?.vgeCoordinates?.length! > 0) {
        return {
          lat: selectedGebouwenCluster?.vgeCoordinates?.[0].lat,
          lng: selectedGebouwenCluster?.vgeCoordinates?.[0].long
        } as GoogleMapReact.Coords
      }

      return undefined
    }
  }

  const calculateZoom = () => {
    if (!selectedGebouwenCluster) return 0
    return selectedGebouwenCluster?.vgeCoordinates?.length! > 0 ? 18 : 6
  }

  const handleLinkClick = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
    const clickedPivotKey = item?.props.itemKey
    if (clickedPivotKey === 'cluster_Gebouwen' && selectedPivot !== 'cluster_Gebouwen') {
      refetchHoofdDocumentUrl()
    }
    setSelectedPivot(clickedPivotKey ?? '')
  }

  const photoElement = () => {
    if (hoofdDocumentUrl?.hoofdDocumentUrl) {
      return (
        <img
          style={{
            marginTop: '10px',
            height: '270px',
            width: '397px',
            boxShadow: 'rgb(239 239 239) 0px 0px 9px -1px'
          }}
          src={hoofdDocumentUrl?.hoofdDocumentUrl}
          alt='Geen foto van de locatie gevonden'
        />
      )
    } else {
      return <Text>Er is geen hoofdfoto ingesteld om weer te geven</Text>
    }
  }

  const handleItemInvoked = (id: string) => {
    let url: string
    if (id) url = PAGES[PageIdDefinition.beheer.fysiek.gebouwEdit].route.replace(':id', id)
    else url = PAGES[PageIdDefinition.beheer.fysiek.gebouwEdit].route
    navigate(url)
  }

  const commandItems = useMemo(() => [{
    text: 'Terug',
    onClick: () => navigate(-1),
    icon: 'Back',
    iconOnly: false
  },
    { text: 'Wijzigen', onClick: () => openEditPanel(), icon: 'Edit' }
  ], [])

  const columns: IColumn[] = [
    {
      key: getPropertyName<IGebouwenclusterGebouwen>('code'),
      name: 'Code',
      fieldName: getPropertyName<IGebouwenclusterGebouwen>('code'),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: IGebouwenclusterGebouwen) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.code}
        </Link>
      )
    },
    {
      key: getPropertyName<IGebouwenclusterGebouwen>('naam'),
      name: 'Naam',
      fieldName: getPropertyName<IGebouwenclusterGebouwen>('naam'),
      minWidth: 300,
      maxWidth: 350,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<IGebouwenclusterGebouwen>('gebouwClusterNaam'),
      name: 'Gebouwencluster',
      fieldName: getPropertyName<IGebouwenclusterGebouwen>('gebouwClusterNaam'),
      minWidth: 300,
      maxWidth: 350,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<IGebouwenclusterGebouwen>('vgesMetAdres'),
      name: 'Aantal adresseerbaar',
      fieldName: getPropertyName<IGebouwenclusterGebouwen>('vgesMetAdres'),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: IGebouwenclusterGebouwen) => <div>{item.vgesMetAdres?.length ?? 0}</div>
    },
    {
      key: `${getPropertyName<IGebouwenclusterGebouwen>('vgesZonderAdres')}-Aantal-overig-gebouwd`,
      name: 'Aantal overig gebouwd',
      fieldName: getPropertyName<IGebouwenclusterGebouwen>('vgesZonderAdres'),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: IGebouwenclusterGebouwen) =>
        <div>{item.vgesZonderAdres?.filter(x => x.srt !== 'TER').length ?? 0}</div>
    },
    {
      key: `${getPropertyName<IGebouwenclusterGebouwen>('vgesZonderAdres')}-Aantal-terreinen`,
      name: 'Aantal terreinen',
      fieldName: getPropertyName<IGebouwenclusterGebouwen>('vgesZonderAdres'),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: IGebouwenclusterGebouwen) =>
        <div>{item.vgesZonderAdres?.filter(x => x.srt === 'TER').length ?? 0}</div>
    }
  ]

  return (
    <MainTemplate title={selectedGebouwenCluster?.naam ? selectedGebouwenCluster.naam : ''} subTitle='Gebouwencluster'
                  commandItems={commandItems}>
      <GebouwenClusterEditPanel selectedGebouwenCluster={selectedGebouwenCluster}
                                fetchData={fetchSelectedGebouwenCluster} isOpen={inEditMode}
                                dismissPanel={dismissEditPanel} />
      <div style={{ marginLeft: 20, marginTop: 10 }}>
        <ErrorMessageBar error={error} />
        <Pivot
          styles={{
            link: { padding: '0 8 0 0' },
            linkIsSelected: { padding: '0 8 0 0' },
            itemContainer: { padding: '16px 0 0 0' }
          }}
          style={{ marginBlockEnd: 10 }}
          onLinkClick={handleLinkClick}
        >
          <PivotItem key='cluster_Gebouwen' itemKey='cluster_Gebouwen' headerText='Gebouwencluster'>
            <Stack
              tokens={stackTokens}
              style={{ marginLeft: '10px', marginTop: '10px', marginRight: '10px', height: '72vh' }}
              horizontal
            >
              <StackItem grow={1}>
                <Stack>
                  <StackItem>
                    <InputCard title='Gebouwencluster' icon={locatie} width='700'>
                      <ReadOnlyField title={'Code'} value={selectedGebouwenCluster?.code} labelWidth={171} />
                      <ReadOnlyField title={'Naam'} value={selectedGebouwenCluster?.naam} labelWidth={171} />
                      <ReadOnlyField title={'Omschrijving'} value={selectedGebouwenCluster?.omschrijving}
                                     labelWidth={171} />
                      <HuurcontractbepalingenTooltip
                        huurcontractbepalingen={selectedGebouwenCluster?.huurcontractbepalingen} labelWidth={171} />
                      <ReadOnlyField title={'Advertentietekst'} value={selectedGebouwenCluster?.advertentietekst}
                                     autoAdjustHeight resizable multiline labelWidth={171} />
                    </InputCard>
                  </StackItem>
                  <StackItem>
                    <ErrorMessageBar error={error} />
                    {photoElement()}
                  </StackItem>
                </Stack>
              </StackItem>
              <StackItem grow={5}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyCvaLeRfzfAtc7LJxbk5HhtcvB6WrKtX40', language: 'nl-NL' }}
                  defaultCenter={{ lat: 52.0907374, lng: 5.1214201 }}
                  defaultZoom={6}
                  options={{ mapTypeControl: true, mapTypeId: 'roadmap' }}
                  center={calculateCenter()}
                  zoom={calculateZoom()}
                >
                  {selectedGebouwenCluster?.vgeCoordinates?.map(item => (
                    <MarkerComponent key={item.id} lat={item.lat ?? 51.72835975} lng={item.long ?? 5.01544399}
                                     text={item.nr} />
                  ))}
                </GoogleMapReact>
              </StackItem>
            </Stack>
          </PivotItem>
          {id !== undefined && id !== '0' &&
            <PivotItem key='selected_gebouwencluster' headerText='Gebouwen' itemKey='selected_GebouwenCluster'>
              <div style={{ height: '78.7vh', position: 'relative' }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                  <DetailsList
                    columns={columns}
                    styles={{ root: { fontSize: '14px' } }}
                    items={gebouwenClusters?.items ?? []}
                  />
                </ScrollablePane>
              </div>
            </PivotItem>}
          {selectedGebouwenCluster &&
            <PivotItem key='cluster_hoeveelheden' itemKey='cluster_hoeveelheden' headerText='Conditiemeting'
                       itemCount={selectedGebouwenCluster?.hoeveelhedenCount ?? 0}>
              <GebouwHoeveelheden entityId={parseInt(id! ?? 0)} entityType={EntityType.GebouwCluster}  editMode={EditMode.gebouw} />
            </PivotItem>}
          {selectedGebouwenCluster && (
            <PivotItem key='cluster_documenten' headerText='Media' itemCount={documentCount} itemKey='documents'>
              <DocumentList doelType={DocumentDoelType.gebouwenCluster} doelId={selectedGebouwenCluster.id} setDocumentCount={setDocumentCount}/>
            </PivotItem>
          )}
        </Pivot>
      </div>
    </MainTemplate>
  )
}

export default EditGebouwenCluster
