import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { CommandBar, IColumn, ICommandBarItemProps, Panel, PanelType, SearchBox } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import { VgeDto, zodiosHooks } from '../../../api/ApiClient'
import { usePagedParams } from '../../../services/usePagedParams'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import { createCommandButton } from '../../../lib/commandBarHelper'
import commandBarStyles from '../../../styles/commandBarStyles'
import { EditParams } from '../../vhe/VheEdit'
import { useParams } from 'react-router-dom'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import { useBoolean } from '@fluentui/react-hooks'
import { getTitleAndMessage } from '../../../services/HandleError'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'
import { IVge } from '../../vge/vgeSlice'
import { IHuurcontractbepalingList, INietGekoppeldFormPanelProps } from './HuurcontractbepalingGebouwclusterList'

type VgeDtoType = z.infer<typeof VgeDto>;


export const HuurcontractbepalingVgeList: React.FC<IHuurcontractbepalingList> = props => {
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const params = useParams()
  const validatedParams = EditParams.parse(params)
  const [error, setError] = useState<string>()

  const {
    data: huurcontractbepalingVgeData,
    invalidate: invalidateHuurcontractbepalingVgeData,
    isLoading
  } = zodiosHooks.useGetApiHuurcontractbepalinglistVges({
    queries: {
      HuurcontractbepalingId: validatedParams.id,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  useEffect(() => {
    if (huurcontractbepalingVgeData) props.setCount(huurcontractbepalingVgeData.totalCount)
  }, [huurcontractbepalingVgeData])

  const [isVgePanelOpen, {
    setTrue: openVgePanel,
    setFalse: dismissVgePanel
  }] = useBoolean(false)

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Soort', getPropertyName<VgeDtoType>('srt'), 'M', false),
      createColumn('Straat', getPropertyName<VgeDtoType>('str'), 'M', false),
      createColumn('Postcode', getPropertyName<VgeDtoType>('pc'), 'M', false),
      createColumn('Huisnummer', getPropertyName<VgeDtoType>('nr'), 'M', false),
      createColumn('Huisletter', getPropertyName<VgeDtoType>('ltr'), 'M', false),
      createColumn('Toev.', getPropertyName<VgeDtoType>('nrT'), 'M', false),
      createColumn('Woonplaats', getPropertyName<VgeDtoType>('wpl'), 'M', false),
      createColumn('Gebouwdeel', getPropertyName<VgeDtoType>('gdlCd'), 'M', false),
      createColumn('Eigenaar', getPropertyName<VgeDtoType>('en'), 'M', false),
      createColumn('Beheerder', getPropertyName<VgeDtoType>('bn'), 'M', false),
      createColumn('Kadastrale aand. Object', getPropertyName<VgeDtoType>('kad'), 'M', false)
    ]
  }, [])

  const AddVgeToSelection = () => {
    openVgePanel()
  }

  const RemoveVgeFromSelection = () => {
    deleteRows({ huurcontractbepalingId: validatedParams.id, itemsToDelete: selectedIds })
  }

  const { mutate: deleteRows } = zodiosHooks.useDeleteApiHuurcontractbepalingontkoppelingVges({}, {
    onSuccess: () => invalidateHuurcontractbepalingVgeData(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const commandbarItems = [
    createCommandButton('Koppelen', 'PlugConnected', AddVgeToSelection),
    createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveVgeFromSelection, selectedIds.length !== 0)
  ] as ICommandBarItemProps[]


  return (
    <div>
      <CommandBar items={commandbarItems} styles={commandBarStyles} />
      <ErrorMessageBar error={error} />
      <NietGekoppeldVgeFormPanel huurcontractbepalingId={validatedParams.id} isOpen={isVgePanelOpen}
                                           refresh={invalidateHuurcontractbepalingVgeData}
                                           dismissPanel={dismissVgePanel} />
      <VgtPagedTable
        items={huurcontractbepalingVgeData?.items ?? undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: huurcontractbepalingVgeData?.totalCount ?? 0,
          totalPages: huurcontractbepalingVgeData?.totalPages ?? 0,
          hasNextPage: huurcontractbepalingVgeData?.hasNextPage ?? false,
          hasPreviousPage: huurcontractbepalingVgeData?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.id}
        onSelectionChanged={setSelectedIds}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </div>
  )
}


const NietGekoppeldVgeFormPanel: React.FC<INietGekoppeldFormPanelProps> = props => {
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = React.useState<string>()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()

  const columns = [
    {
      name: 'Adres',
      fieldName: getPropertyName<IVge>('str'),
      key: getPropertyName<IVge>('str'),
      minWidth: 50,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: IVge) => (
        <div>
          {item.str} {item.nr} {item.ltr} {item.nrT} {item.pc} {item.wpl}
        </div>
      )
    } as IColumn,
    {
      name: 'Eenheiddetailsoort',
      fieldName: getPropertyName<IVge>('srt'),
      key: getPropertyName<IVge>('srt'),
      minWidth: 50,
      maxWidth: 50,
      isResizable: true
    } as IColumn,
    {
      name: 'Adresaanduiding',
      fieldName: getPropertyName<IVge>('aa'),
      key: getPropertyName<IVge>('aa'),
      minWidth: 50,
      maxWidth: 50,
      isResizable: true
    } as IColumn
  ] as IColumn[]


  const { data, invalidate, isLoading } = zodiosHooks.useGetApiHuurcontractbepalingnietGekoppeldVges({
    queries: {
      HuurcontractbepalingId: props.huurcontractbepalingId,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const onCancel = () => {
    props.dismissPanel()
    props.refresh()
    invalidate()
  }

  const onRenderFooterContent = () => {
    return (
      <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                           onOkClick={onSubmit}
                           onCancelClick={onCancel} />
    )
  }


  const {
    mutate: addKoppelingVge,
    isLoading: isSubmitting
  } = zodiosHooks.usePostApiHuurcontractbepalingkoppelingVges(
    {}, { onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const onSubmit = () => {
    addKoppelingVge({ huurcontractbepalingId: props.huurcontractbepalingId, vgeIds: selectedIds })
  }

  return (
    <Panel
      type={PanelType.large}
      headerText={'Koppelen vastgoedeenheden'}
      isOpen={props.isOpen}
      onDismiss={onCancel}
      closeButtonAriaLabel="Sluiten"
      onRenderFooterContent={onRenderFooterContent}>
      <ErrorMessageBar error={error} />
      <SearchBox placeholder="Filter" styles={{ root: { width: '400px', margin: '6px' } }}
                 value={validatedSearchParams.filter}
                 onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                 onSearch={() => invalidate()} />
      <VgtPagedTable
        items={data?.items ?? undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage ?? false,
          hasPreviousPage: data?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.id}
        onSelectionChanged={setSelectedIds}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </Panel>
  )
}