import {VgeDto} from "../api/ApiClient";
import {z} from "zod";
import {IVge} from "../features/vge/vgeSlice";

//IVge added as backward compatibility until other areas have stopped using it

type vgeDto = z.infer<typeof VgeDto>
export const createAddressTextFromVge = (vge: vgeDto | IVge | undefined, include_PC_WPL: boolean = false) => {
    let text: string = ''
    if (vge) {
        text = `${vge.str} ${vge.nr ?? ''}${vge.ltr ?? ''}${vge.nrT ? '-' + vge.nrT : ''} ${vge.aa ?? ''}`
    }
    if (include_PC_WPL) {
        text += `, ${vge?.pc ? vge?.pc : ''} ${vge?.wpl ? vge?.wpl : ''}`
    }
    return text.replace('undefined', '').trim()
}
