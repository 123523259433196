import axios from "axios";
import { MsalSingleton } from '../api/msalInstance'

export function getTitleAndMessage(error: any) {
  let title = "Er is een fout opgetreden";
  let message = "";
  if (error instanceof Error) {
    message = error.message;
    title = error.name;
  }
  if (axios.isAxiosError(error)) {
    title = error.response?.data?.title ?? "Er is een serverfout opgetreden"
    message = error.response?.data?.detail ?? error.response?.data?.title ?? error.message;
  }

  return {title: title, message: message}
}

export function HandleError(error: any) {
  const {title, message} = getTitleAndMessage(error);

  alert(title + ": " + message);

  if (error.response?.status === 401) {
    MsalSingleton.getInstance().logout().then(() => {
      window.location.href = "/UnAuthorized";
    });
  }
}
