import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useRoles from '../../../../services/UseRoles'
import MainTemplate from '../../../../containers/pages/PageTemplates/MainTemplate'
import { InspectietaakListDto, zodiosHooks } from '../../../../api/ApiClient'
import { usePagedParams } from '../../../../services/usePagedParams'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { z } from 'zod'
import { useBoolean } from '@fluentui/react-hooks'
import { InspectietakenFormPanel } from './InspectietakenFormPanel'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'

type InspectietaakListDtoType = z.infer<typeof InspectietaakListDto>;

export const InspectietakenList: React.FC = () => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const { isAdmin } = useRoles()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [itemToEdit, setItemToEdit] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = React.useState<string>()

  const { data, invalidate, isLoading } = zodiosHooks.useGetApiInspectietaak({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteApiInspectietaak({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })
  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Inspectietaak', getPropertyName<InspectietaakListDtoType>('taak'), 'XL', true,
        (item: InspectietaakListDtoType) => (
          <Link key={item.inspectietaakId} onClick={() => handleItemInvoked(item.inspectietaakId.toString())}>
            {item.taak}
          </Link>
        )),
      createColumn('Elementcategorie', getPropertyName<InspectietaakListDtoType>('elementcategorie'), 'XL', false),
      createColumn('Cartotheek-item', getPropertyName<InspectietaakListDtoType>('cartotheekItem'), 'L', false),
      createColumn('Werkzaamheid', getPropertyName<InspectietaakListDtoType>('werkzaamheid'), 'L', false),
      createColumn('Toelichting', getPropertyName<InspectietaakListDtoType>('toelichting'), 'L', false),
      createColumn('Type', getPropertyName<InspectietaakListDtoType>('type'), 'L', false),
      createColumn('Merk', getPropertyName<InspectietaakListDtoType>('merk'), 'L', false),
      createColumn('Hoogte', getPropertyName<InspectietaakListDtoType>('hoogte'), 'L', false),
      createColumn('Lengte', getPropertyName<InspectietaakListDtoType>('lengte'), 'L', false),
      createColumn('Breedte', getPropertyName<InspectietaakListDtoType>('breedte'), 'L', false),
      createColumn('Meetkundige eenheid (HLB)', getPropertyName<InspectietaakListDtoType>('meetkundigeEenheidHlb'), 'L', false),
      createColumn('Bestekcode', getPropertyName<InspectietaakListDtoType>('bestekcode'), 'L', false),
      createColumn('Meetkundige eenheid', getPropertyName<InspectietaakListDtoType>('meetkundigeEenheid'), 'L', false)
    ]
  }, [])

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
      {
        visible: isAdmin,
        text: 'Toevoegen',
        onClick: () => {
          openPanel()
        },
        icon: 'Add'
      },
      {
        visible: isAdmin,
        text: 'Verwijderen',
        onClick: () => deleteRows({ itemsToDelete: selectedIds }),
        icon: 'Delete',
        disabled: isDeleting || !selectedIds.length
      }
    ],
    [isAdmin, selectedIds.length]
  )

  const handleItemInvoked = (id: string) => {
    if (id) {
      setItemToEdit(parseInt(id))
      openPanel()
    }
  }
  const handleDismissPanel = () => {
    setItemToEdit(0)
    dismissPanel()
  }

  return (
    <MainTemplate title='Inspectietaken' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <InspectietakenFormPanel inspectietaakId={itemToEdit} dismissPanel={handleDismissPanel} isOpen={isPanelOpen}
                               invalidate={invalidate} />
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.inspectietaakId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.inspectietaakId.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}