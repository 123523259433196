import React, { useState, useEffect, useMemo } from 'react'
import { IColumn, Link, SelectionMode } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import FluentTable from 'components/FluentTable'
import { getPropertyName } from 'lib/interfaceUtils'
import { selectAll, select, clearSelection, fetchAll, getLoadingState, deleteCoenCadCode } from './coenCadCodeSlice'
import EditPanelCoenCadCode from './editPanel'
import { useBoolean } from '@fluentui/react-hooks'
import { ICoenCadCode } from './coenCadCode'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'

const CoenCadCodeList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const items = useSelector(selectAll)
  const loadingState = useSelector(getLoadingState)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)

  const handleListSelectionChange = (keys: string[]) => setSelectedItems(keys)
  const refreshItems = () => dispatch(fetchAll())
  const onGetKey = (item: ICoenCadCode) => item.id.toString()
  const { isAdmin } = useRoles()

  const handleItemInvoked = (id: string) => {
    dispatch(select(id))
    openPanel()
  }

  useEffect(() => {
    dispatch(fetchAll())
  }, [])

  const removeItems = () => {
    dispatch(deleteCoenCadCode(selectedItems.map(value => parseInt(value))))
  }

  useEffect(() => {
    dispatch(fetchAll())
  }, [])

  const columns: IColumn[] = [
    {
      key: getPropertyName<ICoenCadCode>('key'),
      name: 'CWB Key',
      fieldName: getPropertyName<ICoenCadCode>('key'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: ICoenCadCode) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.key}
        </Link>
      ),
    },
    {
      key: getPropertyName<ICoenCadCode>('groep'),
      name: 'Groep',
      fieldName: getPropertyName<ICoenCadCode>('groep'),
      minWidth: 50,
      maxWidth: 250,
      isResizable: true,
      isRowHeader: true,
    },
    {
      key: getPropertyName<ICoenCadCode>('code'),
      name: 'Code Interface',
      fieldName: getPropertyName<ICoenCadCode>('code'),
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    },
    {
      key: getPropertyName<ICoenCadCode>('elementElementDetailOmschrijving'),
      name: 'Cartotheek-item',
      fieldName: getPropertyName<ICoenCadCode>('elementElementDetailOmschrijving'),
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    },
    {
      key: getPropertyName<ICoenCadCode>('description'),
      name: 'Omschrijving',
      fieldName: getPropertyName<ICoenCadCode>('description'),
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    },
  ]

  const commandItems = useMemo(() =>[
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    { visible: isAdmin, text: 'Toevoegen', onClick: () => { dispatch(clearSelection()); openPanel() }, icon: 'Add' },
    { visible: isAdmin, text: 'Verwijderen', onClick: () => removeItems(), icon: 'Delete', disabled: !selectedItems.length },
  ], [isAdmin, selectedItems.length])

  return (
    <MainTemplate title='Coencad' subTitle='Overzicht' commandItems={commandItems}>
      <EditPanelCoenCadCode dismissPanel={dismissPanel} isOpen={isPanelOpen} />
      <div style={{ height : '90vh'}}>
        <FluentTable
          columns={columns}
          items={items}
          loading={loadingState === 'pending'}
          selectionMode={SelectionMode.multiple}
          onSelectionChanged={handleListSelectionChange}
          onGetKey={onGetKey}
          onItemInvoked={handleItemInvoked}
        />
      </div>
    </MainTemplate>
  )
}

export default CoenCadCodeList
