import { store } from 'store'
import { getApiToken } from 'lib/auth'

export interface IUploadResult {
  success: boolean
  result: any
}

const uploadFile = async (uri: string, formData: FormData) => {
  const { apiEndpoint } = store.getState().app.configuration
  const endpoint = `${apiEndpoint}${uri}`

  try {
    const token = await getApiToken()
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })

    if (response.ok) {
      const result = await response.json()
      return {
        success: true,
        result: result
      }
    }

    return {
      success: false,
      result: response
    }
  }
  catch (ex) {
    console.error('Error:', ex)
    return {
      success: false,
      result: ex
    }
  }
}

export default uploadFile
