import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { entityAdapterInitState } from 'interfaces/entityAdapterState'
import { setPendingState, setSucceededState, setRejectedState } from 'lib/sliceSupport'
import { RootState } from 'store'
import IElementElementDetail from '../lemdo/elementElementDetail/elementElementDetailModel'
import { IBouwkundigElement } from 'features/beheer/lemdo/nlsfb/elementSlice'

export interface INavigation {
  id: string
  items: IMatrixCell[]
}

export interface IMatrixCell {
  id: number
  name: string
  iconUrl?: string
  elementElementDetailId?: number
  elementElementDetail: IElementElementDetail
  nlSfBElementId?: number
  nlSfbElement?: IBouwkundigElement
  children: IMatrixCell[]
  installaties: IInstallatieChoice[]
  enabled: boolean
}

export interface IInstallatieChoice {
  id: number
  bouwkundigElement?: string
  beheerder?: string
  plaatsingsDatum?: string
  garantieDatum?: string
  collectief: boolean
  groep?: string
}

const entityAdapter = createEntityAdapter<INavigation>()

const baseUrl = '/api/cartotheek'
const basePrefix = 'cartotheek'

const getSliceState = (state: RootState) => state.cartotheekNavigatie

export const fetchForVge = createAsyncThunk(`${basePrefix}/vge`, async (vgeId: number, thunkAPI) => {
  return await invokeFetch<INavigation>(thunkAPI, 'GET', `${baseUrl}/vge?vgeId=${vgeId}`)
})

export const fetchForGebouw = createAsyncThunk(`${basePrefix}/gebouw`, async (gebouwId: number, thunkAPI) => {
  return await invokeFetch<INavigation>(thunkAPI, 'GET', `${baseUrl}/gebouw?gebouwId=${gebouwId}`)
})

export const fetchForGebouwdeel = createAsyncThunk(`${basePrefix}/gebouwdeel`, async (gebouwId: number, thunkAPI) => {
  return await invokeFetch<INavigation>(thunkAPI, 'GET', `${baseUrl}/gebouwdeel?gebouwdeelId=${gebouwId}`)
})

export const cartotheekSlice = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),
  reducers: {
    clearSelection: state => {
      state.selectedId = undefined
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchForVge.pending, state => setPendingState(state))
    builder.addCase(fetchForVge.fulfilled, (state, action: PayloadAction<INavigation>) => {
      setSucceededState(state)
      entityAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(fetchForVge.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(fetchForGebouw.pending, state => setPendingState(state))
    builder.addCase(fetchForGebouw.fulfilled, (state, action: PayloadAction<INavigation>) => {
      setSucceededState(state)
      entityAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(fetchForGebouw.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(fetchForGebouwdeel.pending, state => setPendingState(state))
    builder.addCase(fetchForGebouwdeel.fulfilled, (state, action: PayloadAction<INavigation>) => {
      setSucceededState(state)
      entityAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(fetchForGebouwdeel.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const getSelectedEntity = (state: RootState) => {
  const entities = getSliceState(state)
  const selectedId = entities.selectedId
  if (selectedId) return entities[selectedId]
  return undefined
}

export const getLoadingState = (state: RootState) => getSliceState(state).status
export const getErrorState = (state: RootState) => getSliceState(state).error
export const { clearSelection } = cartotheekSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.cartotheekNavigatie)

export default cartotheekSlice.reducer
