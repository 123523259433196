import { IStackTokens, Stack, StackItem } from "@fluentui/react"
import defaultIcon from "./Icons/Breadcrumb_icon.png"

const containerStackTokens: IStackTokens = { childrenGap: 7 };

const TitleTemplate: React.FC<{ title: string, subTitle: string, icon?: string, titleIcon?: React.ReactNode }> = ({ title, subTitle, icon, titleIcon }) => {
    return (
        <div style={{
            padding: '10px', marginBlockEnd: '15px',
            boxShadow: '1px 1px 3px 0px rgba(0,0,0,.132), 2px 0px 6px 0 rgba(0,0,0,.108)'
        }}>
            <Stack horizontal tokens={containerStackTokens}>
                <StackItem>
                    <img alt='' style={{
                        height: '43px',
                        width: '43px'
                    }}
                        src={icon ?? defaultIcon} />
                </StackItem>
                <StackItem>
                    <Stack>
                        <StackItem style={{ display: 'flex'}}>
                            <div style={{ fontSize: '20px', fontWeight: 'normal' }}>
                                <div>{title}</div>
                                <div style={{ fontSize: '14px' }}>{subTitle}</div>
                            </div>
                            <div style={{ marginLeft: 30}}>{titleIcon}</div>
                        </StackItem>
                    </Stack>
                </StackItem>
            </Stack>
        </div>
    )
}

export default TitleTemplate
