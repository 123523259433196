import React from 'react'
import { DetailsList, IColumn, Link, PanelType, SelectionMode } from '@fluentui/react'
import FluentPanel from '../../../../components/FluentPanel/FluentPanel'
import { TenantTaakOnderhoudscontractenListDto, zodiosHooks } from '../../../../api/ApiClient'
import { createColumn, createCustomRenderedColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { z } from 'zod'
import { PageIdDefinition, PAGES } from '../../../../pages'

import { useNavigate } from 'react-router-dom'

interface IShowPanelProps {
  tenantTaakId: number
  isOpen: boolean
  dismissPanel: () => void
}

type TenantTaakOnderhoudscontractenListDtoType = z.infer<typeof TenantTaakOnderhoudscontractenListDto>;
export const TenantTaakOnderhoudscontractShowPanel: React.FC<IShowPanelProps> = props => {

  const navigate = useNavigate()

  const { data } = zodiosHooks.useGetTenantTaakTenantTaakIdonderhoudscontracten({
    params: { tenantTaakId: props.tenantTaakId as number }
  }, { enabled: props.tenantTaakId !== 0 })

  const onPanelDismiss = () => {
    props.dismissPanel()
  }


  const handleItemInvoked = (onderhoudscontractId: string) => {
    navigate(PAGES[PageIdDefinition.contract.edit].route.replace(':id', onderhoudscontractId))
  }

  const columns: IColumn[] = [
    createCustomRenderedColumn(
      'Contractnummer',
      getPropertyName<TenantTaakOnderhoudscontractenListDtoType>('contractNummer'),
      (item: TenantTaakOnderhoudscontractenListDtoType) => (
        <Link key={item.onderhoudscontractId} onClick={() => handleItemInvoked(item.onderhoudscontractId.toString())}>
          {item.contractNummer}
        </Link>
      ),
      'XL',
      false
    ),
    createColumn('Contractomschrijving', getPropertyName<TenantTaakOnderhoudscontractenListDtoType>('contractOmschrijving'), 'XL'),
    createColumn('ContractBeheerder', getPropertyName<TenantTaakOnderhoudscontractenListDtoType>('contractBeheerder')),
    createColumn('ContractPartij', getPropertyName<TenantTaakOnderhoudscontractenListDtoType>('contractPartij'))
  ]

  return (
    <div>
      <FluentPanel isOpen={props.isOpen} headerText={`Onderhoudscontracten gerelateerd`}
                   onDismiss={onPanelDismiss}
                   type={PanelType.extraLarge}>
        <DetailsList
          items={data ?? []}
          columns={columns}
          selectionMode={SelectionMode.multiple}
        />
      </FluentPanel>
    </div>
  )
}