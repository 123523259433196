import {
    IColumn,
    Link,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
} from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createCustomRenderedColumn } from 'lib/gridHelper'
import { selectAll as selectAllRechtspersonen } from 'features/beheer/relaties/rechtspersoon/rechtspersoonSlice'
import { useBoolean } from '@fluentui/react-hooks'
import { GebouwOnderhoudsbedrijfEditPanel } from './editPanel'
import { GebouwOnderhoudsbedrijfDto, zodiosHooks } from "../../../../../api/ApiClient";
import FluentTable from "../../../../../components/FluentTable";
import { z } from "zod";

export interface IGebouwOnderhoudsbedrijfProps {
    gebouwId: number
}

type IGebouwOnderhoudsbedrijf = z.infer<typeof GebouwOnderhoudsbedrijfDto>;

const GebouwOnderhoudsbedrijfList: React.FC<IGebouwOnderhoudsbedrijfProps> = (props: IGebouwOnderhoudsbedrijfProps) => {
    const rechtspersonen = useSelector(selectAllRechtspersonen)
    const [isPanelOpen, {setTrue: openPanel, setFalse: dismissPanel}] = useBoolean(false)
    const [selectedEntity, setSelectedEntity] = useState<IGebouwOnderhoudsbedrijf>();

    useEffect(() => {
        refreshItems()
    }, [])

    const {
        data,
        invalidate: refreshItems
    } = zodiosHooks.useGetGebouwOnderhoudsbedrijf({
        queries: {GebouwId: props.gebouwId, SortDirection: 'asc', SortKey: 'typeringNaam'}
    }, {enabled: props.gebouwId !== 0})

    const handleItemInvoked = (id: string) => {
        if(!data?.items) return
        setSelectedEntity(data.items.find(i => i.onderhoudsbedrijfTyperingId === parseInt(id)));
        openPanel()
    }

    const renderOnderhoudsbedrijf = (rechtpersoonId: number) => {
        return <>{rechtspersonen.find(x => x.id === rechtpersoonId)?.handelsnaam}</>
    }

    const columns: IColumn[] = [
        createCustomRenderedColumn('Type onderhoudsbedrijf', getPropertyName<IGebouwOnderhoudsbedrijf>('typeringNaam'), (x: IGebouwOnderhoudsbedrijf) =>
            <Link key={x.onderhoudsbedrijfTyperingId}
                  onClick={() => handleItemInvoked(x.onderhoudsbedrijfTyperingId.toString())}>{x.typeringNaam}</Link>, 'XXL', false),
        createCustomRenderedColumn('Onderhoudsbedrijf', getPropertyName<IGebouwOnderhoudsbedrijf>('handelsNaam'), (x: IGebouwOnderhoudsbedrijf) => renderOnderhoudsbedrijf(x.rechtspersoonId ?? 0), 'XXL', false),
        createCustomRenderedColumn('Email-adres', getPropertyName<IGebouwOnderhoudsbedrijf>('emailadres'), (x: IGebouwOnderhoudsbedrijf) =>
            <span>{x.emailadres}</span>, 'XXL', false),
        createCustomRenderedColumn('Telefoonnummer', getPropertyName<IGebouwOnderhoudsbedrijf>('telefoonnummer'), (x: IGebouwOnderhoudsbedrijf) =>
            <span>{x.telefoonnummer}</span>, 'XXL', false),
        createCustomRenderedColumn('Contactpersoon', getPropertyName<IGebouwOnderhoudsbedrijf>('contactpersoon'), (x: IGebouwOnderhoudsbedrijf) =>
            <span>{x.contactpersoon}</span>, 'XXL', false)

    ]

    return (
        <div style={{height: '78.7vh', position: 'relative'}}>
            <GebouwOnderhoudsbedrijfEditPanel
                dismissPanel={dismissPanel}
                isOpen={isPanelOpen}
                gebouwId={props.gebouwId}
                selectedEntity={selectedEntity}
                afterSubmit={refreshItems}
            />
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <FluentTable
                    columns={columns}
                    items={data?.items ?? []}
                    onGetKey={(item) => item.id}
                    selectionMode={SelectionMode.none}
                    onItemInvoked={handleItemInvoked}
                ></FluentTable>
            </ScrollablePane>
        </div>
    )
}

export default GebouwOnderhoudsbedrijfList