import { RechtspersoonListDto, zodiosHooks } from 'api/ApiClient'
import { AvailableRoutes } from 'AppRouter'
import { IColumn } from '@fluentui/react'
import React, { useState } from 'react'
import { createColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import { useNavigate } from 'react-router-dom'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { z } from 'zod'
import { VgtPagedTable } from 'components/VgtPagedTable/VgtPagedTable'
import { usePagedParams } from 'services/usePagedParams'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'
import { getTitleAndMessage } from "../../../../services/HandleError";

type RechtspersoonListDtoType = z.infer<typeof RechtspersoonListDto>;

export const RechtspersoonList = () => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setFilter, setOrder } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = useState<string>()

  const { data, invalidate, isLoading } = zodiosHooks.useGetRechtspersonenlist({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteRechtspersonen({}, {
    onSuccess: () => invalidate(),
    onError: (error) => {
      setError(getTitleAndMessage(error).message)
    }
  })

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    {
      text: 'Toevoegen',
      onClick: () => navigate(AvailableRoutes.Rechtspersonen_New),
      icon: 'Add',
      iconOnly: true
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        deleteRows({ itemsToDelete: selectedIds })
      },
      icon: 'Delete',
      iconOnly: true,
      disabled: isDeleting || selectedIds.length === 0
    }
  ]

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Id', getPropertyName<RechtspersoonListDtoType>('id'), 'S', true),
      createColumn('Rol', getPropertyName<RechtspersoonListDtoType>('rolNaam'), 'M', true),
      createColumn('Externe referentie', getPropertyName<RechtspersoonListDtoType>('externeReferentie'), 'M', false),
      createColumn('Handelsnaam', getPropertyName<RechtspersoonListDtoType>('handelsnaam'), 'MAX', false),
      createColumn('Adres', getPropertyName<RechtspersoonListDtoType>('adres'), 'XL', false),
      createColumn('Woonplaats', getPropertyName<RechtspersoonListDtoType>('woonplaats'), 'L', false),
      createColumn('Telefoonnummer', getPropertyName<RechtspersoonListDtoType>('telefoonnummer'), 'S', false)
    ]
  }, [])

  return (
    <MainTemplate title='Rechtspersonen' subTitle='overzicht' commandItems={commandItems} error={error}>
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <VgtPagedTable
        items={data?.items ?? undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage ?? false,
          hasPreviousPage: data?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey || 'code',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.id}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => navigate(AvailableRoutes.Rechtspersonen_Edit.replace(':id', e.id.toString()))}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}