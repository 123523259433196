import React, { useEffect, useState } from 'react'
import { Panel, PanelType } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { getPropertyName } from 'lib/interfaceUtils'
import VgtTextField from 'components/VgtTextField'
import useRoles from 'services/UseRoles'
import VgtDropdown from '../../../../components/FluentDropdown'
import { CreateElementCategorieCommand, ElementCategorieDto, zodiosHooks } from '../../../../api/ApiClient'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

type ElementCategorieDtoType = z.infer<typeof ElementCategorieDto>;
type CreateElementCategorieCommandType = z.infer<typeof CreateElementCategorieCommand>;

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  fetchElementCategoriepageItems: () => void
  selectedElementCategorie: ElementCategorieDtoType | undefined
}

export const ElementCategorieEditPanel: React.FC<IEditPanelProps> = ({
                                                                       isOpen,
                                                                       selectedElementCategorie,
                                                                       dismissPanel,
                                                                       fetchElementCategoriepageItems
                                                                     }) => {
  const [title, setTitle] = useState('')
  const { isAdmin } = useRoles()
  const [error, setError] = useState<string>()

  const onDataSubmitttedSuccesfully = () => {
    fetchElementCategoriepageItems()
    dismissPanel()
  }

  const { data: FontIcoonOptions } = zodiosHooks.useGetApiFonticoon()

  const { mutate: postApiElementCategorie, isLoading: isPostingElementCategorie } = zodiosHooks.usePostApiElementCategorie({}, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: putApiElementCategorieId, isLoading: isUpdatingElementCategorie } = zodiosHooks.usePutApiElementCategorieId(
    { params: { id: selectedElementCategorie?.id! } }, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const fontIcons = FontIcoonOptions?.items?.filter(item => item.naam !== null)
    .map(item => ({ key: item.fonticoonId, text: item.omschrijving! }))

  const defaultElementCategorie = {
    id: selectedElementCategorie?.id ?? 0,
    code: selectedElementCategorie?.code ?? '',
    naam: selectedElementCategorie?.naam ?? '',
    ingangsDatum: new Date().toJSON(),
    fonticoonId: selectedElementCategorie?.fonticoonId ?? null,
    fonticoonNaam: selectedElementCategorie?.fonticoonNaam ?? null,
    eindDatum: selectedElementCategorie?.eindDatum ?? null
  }

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<ElementCategorieDtoType>({ mode: 'all' })

  useEffect(() => {
    setTitle(`Elementcategorie wijzigen`)
    if (selectedElementCategorie) {
      reset(selectedElementCategorie)
    } else {
      reset({})
    }
  }, [selectedElementCategorie])

  const onCancel = () => {
    dismissPanel()
  }

  const onSubmit = (data: CreateElementCategorieCommandType) => {
    const {
      fonticoonId = null,
      ...restData
    } = data

    const submittedData = {
      ...defaultElementCategorie,
      fonticoonId: fonticoonId ?? null,
      ...restData
    }

    if (selectedElementCategorie) {
      submittedData.id = selectedElementCategorie.id
      putApiElementCategorieId(submittedData)
    } else {
      postApiElementCategorie(submittedData)
    }
  }

  return (
    <div>
      <Panel type={PanelType.large} headerText={title} isOpen={isOpen} onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <ErrorMessageBar error={error} />
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField
            label='Elementcategorie'
            name={getPropertyName<CreateElementCategorieCommandType>('naam')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          />
          <VgtDropdown
            options={fontIcons}
            label='Fonticoon'
            name={getPropertyName<CreateElementCategorieCommandType>('fonticoonId')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isAdmin}
          />
          <OkCancelButtonStack
            isSubmitting={isSubmitting}
            isLoading={isPostingElementCategorie || isUpdatingElementCategorie}
            onOkClick={handleSubmit(onSubmit)}
            onCancelClick={onCancel}
            isWriter={isAdmin}
          />
        </form>
      </Panel>
    </div>
  )
}
export default ElementCategorieEditPanel