import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import { store } from './store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'

import { initializeIcons } from '@fluentui/react/lib/Icons'
import { IConfiguration, initializeApp } from './store/actions/app/configuration'
import { ErrorBoundary } from 'react-error-boundary'
import { ZodError } from 'zod'

const startup = async () => {
  initializeIcons()

  let fetchSettings: Promise<Response>
  if (process.env.NODE_ENV === 'development') {
    fetchSettings = fetch(process.env.PUBLIC_URL + '/settings.dev.json')
  } else {
    fetchSettings = fetch(process.env.PUBLIC_URL + '/settings.json')
  }

  const resp = await fetchSettings
  const settings: IConfiguration = await resp.json()
  localStorage.setItem('settings', JSON.stringify(settings))

  store.dispatch(initializeApp(settings))

  const render = Component => {
    ReactDOM.render(
      <React.StrictMode>
        <ErrorBoundary FallbackComponent={IndexError}>
          <Provider store={store}>
            <Component />
          </Provider>
        </ErrorBoundary>
      </React.StrictMode>,
      document.getElementById('root')
    )
  }

  render(App)

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', () => {
      const nextApp = require('./App').default
      render(nextApp)
    })
  }
}

startup().catch(error => console.log('Error during startup: ', error))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// serviceWorker.register({
//   onUpdate: reg => {
//     if (reg && reg.waiting) {
//       reg.waiting.postMessage({ type: 'SKIP_WAITING' })
//     }
//     window.location.reload()
//   },
// })
const IndexError = ({ error, resetErrorBoundary }) => {
  console.log(error)
  const zodError = error as ZodError;
  const errorMessage = (error instanceof ZodError)
    ? zodError.issues?.map(issue => `${issue.path[0]}: ${issue.message}`).join(",")
    : error?.message || "Er is die we niet hebben voorzien. De fout is gelogd en we zullen het zo snel mogelijk oplossen. U kunt terugkeren naar de home pagina.";

  return <div>{errorMessage}</div>
}
