import { CommandBar, ICommandBarItemProps } from "@fluentui/react/lib/CommandBar"
import React from "react"
import commandBarStyles from 'styles/commandBarStyles'

export interface ICommandItem {
    text: string,
    icon?: string,
    onClick: () => void,
    disabled?: boolean,
    visible?: boolean
}

const CommandBarTemplate: React.FC<{ items: ICommandItem[] }> = ({ items }) => {
    const commandBarItems: ICommandBarItemProps[] =
            items.filter(v => v.visible || v.visible === undefined).map((value, number) => {
                return {
                    key: number.toString(),
                    text: value.text,
                    iconProps: { iconName: value.icon, className: 'icon' },
                    iconOnly: !value.text,
                    onClick: value.onClick,
                    split: false,
                    ariaLabel: value.text,
                    disabled: value.disabled,
                }
            }
    )

    return (<CommandBar items={commandBarItems} styles={commandBarStyles} />)
}

export default CommandBarTemplate