import {useCallback, useEffect, useReducer, useState} from "react";
import {getApiToken} from "../lib/auth";
import {useTypedSelector} from "../store";

export enum Status { "idle", "loading", "success", "error"}

interface IApiState {
    status: Status
    data: any
    error: string | undefined
}

const reducer = (state: IApiState, action: { type: Status; payload?: any; }): IApiState => {
    switch (action.type) {
        case Status.idle:
            return { status: Status.idle, data: undefined, error: undefined } as IApiState;
        case Status.loading:
            return { status: Status.loading, data: undefined, error: undefined } as IApiState;
        case Status.success:
            return { status: Status.success, data: action.payload, error: undefined } as IApiState;
        case Status.error:
            return { status: Status.error, data: undefined, error: action.payload } as IApiState;
    }
};

const initialState : IApiState = {
    status: Status.idle,
    data: undefined,
    error: undefined,
};

const UseApi = <T>(relativeUrl: string, method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' = 'GET', fetchImmediate = true) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [url, setUrl] = useState(relativeUrl)
    const apiEndpoint = useTypedSelector(state => state.app.configuration.apiEndpoint)

    const executeFetch = useCallback((bodyValue?: any, onSuccess?: () => void) => {
        if (url === '') return

        getApiToken().then(token => {

            const absoluteUrl = new URL(url, apiEndpoint).toString()

            dispatch({ type: Status.loading })
            window.fetch(absoluteUrl, {
                method: method,
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors',
                body: method === 'GET' ? undefined : JSON.stringify(bodyValue)
            }).then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText)
                    response.json().then(
                      error => {
                          dispatch({ type: Status.error, payload: error.detail })
                      }).catch(_ => dispatch({ type: Status.error, payload: error.message }))
                } else {
                    response.json().then(value => {
                        dispatch({ type: Status.success, payload: value as T })
                        if (onSuccess) onSuccess()
                    }).catch(error => {
                        dispatch({ type: Status.error, payload: 'Parsing json: ' + error })
                    })
                }

                return state
            }).catch(reason => {
                dispatch({ type: Status.error, payload: 'Executing request: ' + reason })
            })
        }).catch(reason => {
            dispatch({ type: Status.error, payload: 'Fetching api token: ' + reason })
        })

    }, [url, method])

    useEffect(() => {
        if (url === '') return

        if (!executeFetch) return

        if (fetchImmediate) {
            executeFetch()
        }
    }, [fetchImmediate, executeFetch, url])

    return { state: state, execute: executeFetch, setUrl: setUrl }
}

export default UseApi