import React, { useMemo, useState} from 'react'
import { Dropdown, IDropdownOption, PrimaryButton, Stack, StackItem } from '@fluentui/react'
import { useDispatch, useSelector } from 'react-redux'
import { sendSyncVhesCommand, getLoadingState } from '../importSlice'
import MainTemplate from "containers/pages/PageTemplates/MainTemplate";
import downloadFile from 'services/downloadFile'
import { zodiosHooks } from '../../../../api/ApiClient'

const Exporteren: React.FC = () => {
  const dispatch = useDispatch()
  const loading = useSelector(getLoadingState)
  const [uploadEndoint, setUploadEndoint] = useState("")
  const [currentOption, setCurrentOption] = useState<IDropdownOption | undefined>()

  const { data } = zodiosHooks.useGetExports()

  const startSync = () => {
    dispatch(sendSyncVhesCommand())
  }

  const commandItems = useMemo( () => [
    { text: 'Synchroniseren vhe', onClick: () => startSync(), icon: 'Home', iconOnly: false, enabled: loading === "pending" },
  ], [loading])

  const onChange = (_event, item: IDropdownOption | undefined) => {
    if (item) {
      setCurrentOption(item)
      setUploadEndoint(item.key as string)
    }
  }

  const exportToExcel = () => {
    downloadFile(uploadEndoint, `${currentOption?.text}.xlsx`)
  }

  const exportOptions: IDropdownOption[] = data ?
    data?.map(value => ({
      text: value?.name ?? '',
      key: value?.endpoint !== null ? value.endpoint : '',
    })) : []

  return (
    <>
      <MainTemplate title='Exporteren' subTitle='Overzicht' commandItems={commandItems}/>
      <Stack tokens={{ childrenGap: 5 }} verticalAlign={'start'} style={{ margin: 20, marginTop: 20 }}>
        <StackItem>
          <Dropdown
            onChange={onChange}
            placeholder="Kies een optie"
            options={exportOptions}
            styles={{ dropdown: { width: 600 } }}
          />
        </StackItem>
        <StackItem>
          <PrimaryButton onClick={exportToExcel} text={"Bestand exporteren"} disabled={!currentOption?.text}/>
        </StackItem>
      </Stack>
      {loading === "pending" && <div>Versturen opdracht...</div>}
      {loading === "succeeded" && <div>De sychronisatie-opdracht is verstuurd.</div>}
    </>
  )
}

export default Exporteren