import {
    Stack,
    StackItem,
    TextField,
    DefaultButton,
    ITextFieldStyles,
    Link,
    mergeStyleSets,
    IDropdownStyles
} from '@fluentui/react'
import VgtDropdown from "components/FluentDropdown"
import VgtTextField from "components/VgtTextField"
import HorizonalDatePicker, { parseDate } from "components/HorizontalDatePicker"
import InputCard from "components/InputCard"
import { getPropertyName } from "lib/interfaceUtils"
import { Controller } from "react-hook-form"
import { register } from "serviceWorker"
import { IInstallatie } from "../installatie/installatieTypes"

import { getDropDownValues as getEenheden } from 'features/beheer/basis/meetkundigeEenheden/meetkundigeEenheidSlice'
import React, { FC, useState } from 'react';
import { useBoolean } from "@fluentui/react-hooks"
import { useSelector } from "react-redux"
import { IRechtspersoon, RelatieRolIds, getRechtspersonen } from "../relaties/rechtspersoon/rechtspersoonSlice"
import SearchPanelRechtspersoon from "../relaties/rechtspersoon/searchPanelRechtsPersoon"
import { RootState } from "store"
import EditPanelSearch2BA from "../installatie/searchPanel2BA"
import { IProduct2BA } from "../installatie/product2BASlice"
import { CartotheekDetailKeuring } from './CartotheekDetailKeuring'

interface CartotheekDetailProductProps {
    installatie: IInstallatie
    control: any
    errors: any
    watch: (field: string) => any
    setValue(name: string, value: any): void
}


export const CartotheekDetailProduct: FC<CartotheekDetailProductProps> = ({ watch, control, errors, setValue, installatie }) => {
    const use2BA = watch(getPropertyName<IInstallatie>('productUse2BA')) === true
    const onClearField = (fieldName: string) => {
        setValue(fieldName, undefined)
    }
    const [productDetailsUrl, setProductDetailsUrl] = useState<string>()

    const leveranciers = useSelector((state: RootState) => getRechtspersonen(state, RelatieRolIds.leverancier))
    const [isSearch2BAOpen, { setTrue: openSearch2BA, setFalse: dismissSearch2BA }] = useBoolean(false)
    const [leverancier, setLeverancier] = useState<IRechtspersoon>()
    const [isSearchLeverancierPanelOpen, {
        setTrue: openSearchLeverancierPanel,
        setFalse: dismissSearchLeverancierPanel
    }] = useBoolean(false)
    const onSelectLeverancier = (persoon: IRechtspersoon) => {
        setLeverancier(persoon)
        setValue(getPropertyName<IInstallatie>('leverancierId'), persoon.id)
    }
    const getSelectedLeverancier = (leverancierId: number | undefined) => {
        return leverancier ?? leveranciers.find((rechtspersoon) => rechtspersoon.id === leverancierId)
    }
    const createProductDetailsUrl = (gln: string | undefined, productCode: string | undefined) => {
        if (gln && productCode) return `https://unifeed.2ba.nl/?gln=${gln}&productcode=${productCode}`
        return undefined
    }
    const onProduct2BASelected = (product: IProduct2BA) => {
        dismissSearch2BA()
        setValue(getPropertyName<IInstallatie>('productGtin'), product.gtin)
        setValue(getPropertyName<IInstallatie>('productKlasse'), product.productClassDescription)
        setValue(getPropertyName<IInstallatie>('productGln'), product.manufacturerGLN)
        setValue(getPropertyName<IInstallatie>('productFabrikant'), product.manufacture)
        setValue(getPropertyName<IInstallatie>('productMerk'), product.manufacture)
        setValue(getPropertyName<IInstallatie>('productSerie'), product.version)
        setValue(getPropertyName<IInstallatie>('productType'), product.model)
        setValue(getPropertyName<IInstallatie>('productCode'), product.productCode)
        setValue(getPropertyName<IInstallatie>('productUse2BA'), true)
        // setProductThumbnailUrl(createThumbnailUrl(product.manufacturerGLN, product.productCode))
        setProductDetailsUrl(createProductDetailsUrl(product.manufacturerGLN, product.productCode))
    }

    const productGtin = watch(getPropertyName<IInstallatie>('productGtin'))

    const eenheidOptions = useSelector(getEenheden)
    const gtinTextFieldStyles: Partial<ITextFieldStyles> = {
        wrapper: {
            borderBottom: '1px solid rgb(219 219 219)',
        },
        subComponentStyles: {
            label: {
                root: { fontWeight: 'normal' },
            },
        },
        field: {
            fontWeight: 600,
        },
        suffix: {
            background: '',
        },
    }

    const dropDownFieldStyles: Partial<IDropdownStyles> = {
        root: {
            padding: '0px 0px 0px 12px',
            display: 'flex',
            width: '98%',
        },
        label: {
            fontWeight: 'Normal',
            width: 153,
            flexGrow: '0',
            flexShrink: '0',
        },
        title: {
            borderWidth: '0px',
            paddingLeft: '4px',
            width: '98%'
        },
        dropdown: {
            width: '100%',
            fontWeight: 600,
        },
    }

    const classNames = mergeStyleSets({
        stackItem: {
            border: `1px solid #efefef`,
            boxShadow: '#efefef 0px 0px 9px -1px',
            margin: '10px 0 20px'
        },
    })

    return (<>
        <SearchPanelRechtspersoon
            rechtspersonen={leveranciers}
            isOpen={isSearchLeverancierPanelOpen}
            dismissPanel={dismissSearchLeverancierPanel}
            headerText='Selecteer een leverancier'
            onSelect={onSelectLeverancier}
        />
        <EditPanelSearch2BA
            isOpen={isSearch2BAOpen}
            dismissPanel={dismissSearch2BA}
            onSelected={product => onProduct2BASelected(product)}
            query={productGtin?.toString() ?? ''}
        />
        <Stack style={{ width: '99%' }}>
            <StackItem>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <InputCard  width='33%' title="Product" >
                        <Stack horizontal style={{ width: '100%' }} >
                            <StackItem grow={1}>
                                <VgtTextField
                                  label='Fabrikant'
                                  name={getPropertyName<IInstallatie>('productFabrikant')}
                                  register={register}
                                  control={control}
                                  errors={errors}
                                  readOnly={use2BA}
                                  labelWidth={215}
                                />
                            </StackItem>
                            <StackItem grow={0}>
                                <DefaultButton text='Zoeken' onClick={() => openSearch2BA()}  />
                            </StackItem>
                        </Stack>
                        <VgtTextField
                            label='Merk'
                            name={getPropertyName<IInstallatie>('productMerk')}
                            register={register}
                            control={control}
                            errors={errors}
                            readOnly={use2BA}
                        />
                        <VgtTextField
                            label='Serie'
                            name={getPropertyName<IInstallatie>('productSerie')}
                            register={register}
                            control={control}
                            errors={errors}
                            readOnly={use2BA}
                        />
                        <VgtTextField
                            label='Type'
                            name={getPropertyName<IInstallatie>('productType')}
                            register={register}
                            control={control}
                            errors={errors}
                            readOnly={use2BA}
                        />
                        <VgtTextField
                            label='Productcode'
                            name={getPropertyName<IInstallatie>('productCode')}
                            register={register}
                            control={control}
                            errors={errors}
                            readOnly={use2BA}
                        />
                            <Stack horizontal style={{ paddingTop: 5, borderBottom: '1px solid rgb(219, 219, 219)' }}>
                                <StackItem style={{ width: 215 }}>
                                    <label style={{ paddingLeft: 12 }}>Productdetails</label>
                                </StackItem>
                                <StackItem>
                                    <Link
                                        style={{ paddingLeft: 18, overflow: 'clip', color: 'black', fontWeight: 600 }}
                                        href={productDetailsUrl}
                                        title={productDetailsUrl}
                                        target='_blank'
                                    >
                                        https://unifeed.2ba.nl/...
                                    </Link>
                                </StackItem>
                            </Stack>
                    </InputCard>
                    <InputCard width='33%' title="">
                            <VgtTextField
                                label='Kleur'
                                name={getPropertyName<IInstallatie>('productKleur')}
                                register={register}
                                control={control}
                                errors={errors}
                                labelWidth={150}
                            />
                            <VgtTextField
                                label='Lengte'
                                name={getPropertyName<IInstallatie>('productLengte')}
                                register={register}
                                control={control}
                                errors={errors}
                                labelWidth={150}
                            />
                            <VgtTextField
                                label='Breedte'
                                name={getPropertyName<IInstallatie>('productBreedte')}
                                register={register}
                                control={control}
                                errors={errors}
                                labelWidth={150}
                            />
                            <VgtTextField
                                label='Hoogte'
                                name={getPropertyName<IInstallatie>('productHoogte')}
                                register={register}
                                control={control}
                                errors={errors}
                                labelWidth={150}
                            />
                            <VgtDropdown
                                label='Eenheid'
                                name={getPropertyName<IInstallatie>('productEenheidId')}
                                register={register}
                                control={control}
                                errors={errors}
                                options={eenheidOptions}
                                dropdownWidth={200}
                                styles={dropDownFieldStyles}
                            />
                            <VgtTextField
                              label='Aantal'
                              name={getPropertyName<IInstallatie>('productAantal')}
                              register={register}
                              control={control}
                              errors={errors}
                              labelWidth={150}
                            />
                          {installatie.elementdetailDisplay === 'Zonnepaneel' &&
                              <VgtTextField
                                label="Wattpiek"
                                name={getPropertyName<IInstallatie>('wattpiek')}
                                register={register}
                                control={control}
                                errors={errors}
                                labelWidth={150}
                            /> }
                    </InputCard>
                            <InputCard width='33%' title="Garantie product">
                                <Controller
                                  name={getPropertyName<IInstallatie>('garantieIngangsDatum')}
                                  register={register}
                                  control={control}
                                  errors={errors}
                                  render={({ onChange, value }) => (
                                    <HorizonalDatePicker
                                      label='Ingangsdatum'
                                      onChange={onChange}
                                      value={parseDate(value)}
                                      onClear={() => onClearField(getPropertyName<IInstallatie>('garantieIngangsDatum'))}
                                    />
                                  )}
                                />
                                <Controller
                                  name={getPropertyName<IInstallatie>('garantieEindDatum')}
                                  register={register}
                                  control={control}
                                  errors={errors}
                                  render={({ onChange, value }) => (
                                    <HorizonalDatePicker
                                      label='Einddatum'
                                      onChange={onChange}
                                      value={parseDate(value)}
                                      onClear={() => onClearField(getPropertyName<IInstallatie>('garantieEindDatum'))}
                                    />
                                  )}
                                />
                                <Controller
                                  name={getPropertyName<IInstallatie>('garantieProductNotificatieDatum')}
                                  register={register}
                                  control={control}
                                  errors={errors}
                                  render={({ onChange, value }) => (
                                    <HorizonalDatePicker
                                      label='Notificatiedatum'
                                      onChange={onChange}
                                      value={parseDate(value)}
                                      onClear={() => onClearField(getPropertyName<IInstallatie>('garantieProductNotificatieDatum'))}
                                    />
                                  )}
                                />
                            </InputCard>
                </Stack>
            </StackItem>
            <StackItem className={classNames.stackItem}>
                <VgtTextField
                  label='Opmerking'
                  name={getPropertyName<IInstallatie>('productOpmerking')}
                  register={register}
                  control={control}
                  errors={errors}
                  multiline={true}
                />
            </StackItem>
            <StackItem>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <InputCard width="33%" title="Plaatsing">
                        <Stack horizontal>
                            <StackItem grow={1}>
                                <Controller
                                  name={getPropertyName<IInstallatie>('leverancierId')}
                                  register={register}
                                  control={control}
                                  errors={errors}
                                  render={({ onChange }) => (
                                    <TextField
                                      autoComplete='off'
                                      name={getPropertyName<IInstallatie>('leverancierId')}
                                      onChange={onChange}
                                      label='Leverancier'
                                      value={getSelectedLeverancier(installatie.leverancierId)?.handelsnaam}
                                      styles={gtinTextFieldStyles}
                                      borderless
                                      underlined
                                      placeholder=' --- '
                                      errorMessage={errors.leverancierId?.message}
                                    />
                                  )}
                                />
                            </StackItem>
                            <StackItem grow={0}>
                                <DefaultButton text='Zoeken' onClick={openSearchLeverancierPanel} />
                            </StackItem>
                        </Stack>

                        <Stack style={{ width: '100%'}}  >
                            <Controller
                              name={getPropertyName<IInstallatie>('plaatsingDatum')}
                              register={register}
                              control={control}
                              errors={errors}
                              render={({ onChange, value }) => (
                                <HorizonalDatePicker
                                  label='Plaatsingsdatum'
                                  onChange={onChange}
                                  value={parseDate(value)}
                                  onClear={() => onClearField(getPropertyName<IInstallatie>('plaatsingDatum'))}
                                />
                              )}
                            />
                        </Stack>

                        <Controller
                          name={getPropertyName<IInstallatie>('vervangingsDatum')}
                          register={register}
                          control={control}
                          errors={errors}
                          render={({ onChange, value }) => (
                            <HorizonalDatePicker
                              label='Vervangingsdatum'
                              onChange={onChange}
                              value={parseDate(value)}
                              onClear={() => onClearField(getPropertyName<IInstallatie>('plaatsingDatum'))}
                            />
                          )}
                        />
                    </InputCard>
                    <InputCard width="33%" title="Garantie werkzaamheden">
                        <Controller
                            name={getPropertyName<IInstallatie>('werkzaamhedenIngangsDatum')}
                            register={register}
                            control={control}
                            errors={errors}
                            render={({ onChange, value }) => (
                                <HorizonalDatePicker
                                    label='Ingangsdatum'
                                    onChange={onChange}
                                    value={parseDate(value)}
                                    onClear={() => onClearField(getPropertyName<IInstallatie>('werkzaamhedenIngangsDatum'))}
                                />
                            )}
                        />
                        <Controller
                            name={getPropertyName<IInstallatie>('werkzaamhedenEindDatum')}
                            register={register}
                            control={control}
                            errors={errors}
                            render={({ onChange, value }) => (
                                <HorizonalDatePicker
                                    label='Einddatum'
                                    onChange={onChange}
                                    value={parseDate(value)}
                                    onClear={() => onClearField(getPropertyName<IInstallatie>('werkzaamhedenEindDatum'))}
                                />
                            )}
                        />

                        <Controller
                            name={getPropertyName<IInstallatie>('werkzaamhedenNotificatieDatum')}
                            register={register}
                            control={control}
                            errors={errors}
                            render={({ onChange, value }) => (
                                <HorizonalDatePicker
                                    label='Notificatiedatum'
                                    onChange={onChange}
                                    value={parseDate(value)}
                                    onClear={() => onClearField(getPropertyName<IInstallatie>('werkzaamhedenNotificatieDatum'))}
                                />
                            )}
                        />
                    </InputCard>
                    <CartotheekDetailKeuring setValue={setValue}
                                             errors={errors}
                                             control={control} />
                </Stack>
            </StackItem>
        </Stack>
    </>
    )
}