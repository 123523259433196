import { IColumn, Link } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { createCustomRenderedColumn } from 'lib/gridHelper'
import { clearSelection, deleteOnderhoudsTaakErp, fetchAll, select } from './onderhoudsTaakErpSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { OnderhoudsTaakEditPanel } from './editPanel'
import { useBoolean } from '@fluentui/react-hooks'
import { OnderhoudsTaakErpDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { usePagedParams } from '../../../../services/usePagedParams'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { z } from 'zod'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

type OnderhoudsTaakErpDtoType = z.infer<typeof OnderhoudsTaakErpDto>;

const OnderhoudsTaakList: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = React.useState<string>()

  const {
    data: onderhoudsTaakErpItems,
    invalidate: fetchOnderhoudsTaakErpItems,
    isLoading
  } = zodiosHooks.useGetOnderhoudsTaakErp({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  useEffect(() => {
    dispatch(fetchAll({ filter: '' }))
  }, [])

  const handleItemInvoked = (id) => {
    dispatch(select(id))
    openPanel()
  }

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => fetchOnderhoudsTaakErpItems(), icon: 'Refresh' },
    {
      text: 'Toevoegen', onClick: () => {
        dispatch(clearSelection())
        openPanel()
      }, icon: 'Add'
    },
    {
      text: 'Verwijderen', onClick: () =>
        dispatch(
          deleteOnderhoudsTaakErp(
            selectedIds
          )
        )
      , icon: 'Delete', disabled: !selectedIds.length
    }
  ], [selectedIds.length])

  const renderCode = (x: OnderhoudsTaakErpDtoType) => <Link key={x.code}
                                                            onClick={() => handleItemInvoked(x.onderhoudstaakErpId.toString())}>{x.code}</Link>

  const columns: IColumn[] = [
    createCustomRenderedColumn('Code', getPropertyName<OnderhoudsTaakErpDtoType>('code'), renderCode, 'XXL', false),
    createCustomRenderedColumn('Omschrijving', getPropertyName<OnderhoudsTaakErpDtoType>('omschrijving'), (x: OnderhoudsTaakErpDtoType) => <>{x.omschrijving}</>
      , 'MAX', false, true)
  ]

  return (
    <MainTemplate title='Onderhoudstaken ERP' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <OnderhoudsTaakEditPanel
        fetchOnderhoudsTaakErpItems={fetchOnderhoudsTaakErpItems}
        dismissPanel={dismissPanel}
        isOpen={isPanelOpen}
      />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <VgtPagedTable
        items={onderhoudsTaakErpItems?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: onderhoudsTaakErpItems?.totalCount ?? 0,
          totalPages: onderhoudsTaakErpItems?.totalPages ?? 0,
          hasNextPage: onderhoudsTaakErpItems?.hasNextPage || false,
          hasPreviousPage: onderhoudsTaakErpItems?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item: OnderhoudsTaakErpDtoType) => item?.onderhoudstaakErpId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.onderhoudstaakErpId.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>)
}

export default OnderhoudsTaakList