import { DefaultButton, Panel, PanelType, Stack } from '@fluentui/react'
import React from 'react'
import useRoles from '../../../services/UseRoles'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ContactCategorieAddOrUpdateDto, zodiosHooks } from '../../../api/ApiClient'
import VgtTextField from '../../../components/VgtTextField'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { getTitleAndMessage } from '../../../services/HandleError'
import { z } from 'zod'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'

export interface IEditPanelProps {
  contactCategorieId: number,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof ContactCategorieAddOrUpdateDto>;

export const ContactCategorieFormPanel: React.FC<IEditPanelProps> = props => {

  const { isAdmin } = useRoles()
  const [error, setError] = React.useState<string>()

  const defaultContactCategorie = {
    naam: ''
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(ContactCategorieAddOrUpdateDto), mode: 'all', defaultValues: defaultContactCategorie
  })


  const {
    mutate: updateContactCategorie
  } = zodiosHooks.usePutContactCategorieenId(
    { params: { id: props.contactCategorieId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: addContactCategorie } = zodiosHooks.usePostContactCategorieen(
    {}, { onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  zodiosHooks.useGetContactCategorieenId({ params: { id: props.contactCategorieId } }, {
      onSuccess: (data) => reset(data),
      enabled: props.contactCategorieId !== 0
    }
  )

  const onCancel = () => {
    props.invalidate()
    reset({})
    props.dismissPanel()
  }

  const onSubmit = (data: CreateUpdateType) => {
    if (props.contactCategorieId !== 0) {
      updateContactCategorie(data)
    } else {
      addContactCategorie(data)
    }
  }

  return (
    <div>
      <Panel type={PanelType.medium}
             headerText={`Categorie ${props.contactCategorieId !== 0 ? 'wijzigen' : 'toevoegen'}`} isOpen={props.isOpen}
             onDismiss={onCancel}
             closeButtonAriaLabel="Sluiten">
        <br />
        <ErrorMessageBar error={error} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField
            label="Categorie Naam"
            name={getPropertyName<CreateUpdateType>('naam')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          />
          <br />
          {isAdmin ?
            <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
              <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting}
                             onClick={handleSubmit(onSubmit)} />
              <DefaultButton text="Annuleren" onClick={onCancel} />
            </Stack> : null
          }
        </form>
      </Panel>
    </div>
  )
}