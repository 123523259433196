import React, { useState } from 'react'
import { IButtonStyles, IconButton, ITooltipHostProps, TooltipHost } from '@fluentui/react'

interface ClipboardButtonProps {
  id: number
  message: string
}

const ClipboardButton: React.FC<ClipboardButtonProps> = ({ id, message }) => {
  const [tooltipContent, setTooltipContent] = useState<string>('Kopiëren naar Klembord')
  const [tooltipId] = useState(`tooltip-${id}`)

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(message).then(() => {
      setTooltipContent('Gekopieerd')
    })
  }

  const handleMouseLeave = () => {
    setTooltipContent('Kopiëren naar klembord')
  }

  const calloutProps: ITooltipHostProps['calloutProps'] = {
    gapSpace: 0
  }

  const hostStyles: ITooltipHostProps['styles'] = {
    root: { display: 'inline-block' }
  }

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: { height: 'unset' }
  }

  return (
    <TooltipHost content={tooltipContent} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
      <IconButton iconProps={{ iconName: 'Copy' }} onClick={copyToClipBoard} onMouseLeave={handleMouseLeave}
                  style={{ backgroundColor: 'transparent' }} styles={iconButtonStyles} />
    </TooltipHost>
  )
}

export default ClipboardButton
