import { ITextFieldStyles, TextField } from '@fluentui/react'
import { FC } from 'react'

const labelStyles: Partial<ITextFieldStyles> = {
  wrapper: {
    borderBottom: '1px solid #efefef'
  },
  subComponentStyles: {
    label: {
      root: { width: 350, fontWeight: 'normal' }
    }
  },
  field: {
    fontWeight: 600
  },
  suffix: {
    background: ''
  }
}

interface IReadonlyFieldProps {
  title: string;
  value: any;
}

export const ReadOnlyField: FC<IReadonlyFieldProps> = (props: IReadonlyFieldProps) => {
  return <TextField autoComplete='off' styles={labelStyles} label={props.title} underlined readOnly value={props.value}
                    placeholder={'---'} />
}
