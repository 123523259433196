import MainTemplate from '../../../../containers/pages/PageTemplates/MainTemplate'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import React, { useMemo, useState } from 'react'
import { TenantRuimteSoortListDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { usePagedParams } from '../../../../services/usePagedParams'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import useRoles from '../../../../services/UseRoles'
import { IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { z } from 'zod'
import { TenantRuimteSoortErpFormPanel } from './TenantRuimteSoortErpFormPanel'


type TenantRuimteSoortErpListDtoType = z.infer<typeof TenantRuimteSoortListDto>;

export const TenantRuimteSoortErpList = () => {

  const { validatedSearchParams, setPage, setOrder} = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [error, setError] = React.useState<string>()
  const navigate = useNavigate()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const { isReparatieboom } = useRoles()
  const [itemToEdit, setItemToEdit] = useState<TenantRuimteSoortErpListDtoType|undefined>(undefined)

  const { data, invalidate, isLoading } = zodiosHooks.useGetTenantRuimteSoort({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: 100,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' }
  ], [isReparatieboom, selectedIds.length])

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Defectlocatie', getPropertyName<TenantRuimteSoortErpListDtoType>('defectLocatieNaam'), 'XL', true,
        (item: TenantRuimteSoortErpListDtoType) => (
          <Link key={item.defectLocatieId} onClick={() => handleItemInvoked(item)}>
            {item.defectLocatieNaam}
          </Link>
        )),
      createColumn('Ruimtesoort ERP', getPropertyName<TenantRuimteSoortErpListDtoType>('ruimtesoortErpOmschrijving'), 'XL', true)
    ]
  }, [])

  const handleItemInvoked = (item: TenantRuimteSoortErpListDtoType) => {
    if (item) {
      setItemToEdit(item)
      openPanel()
    }
  }
  const handleDismissPanel = () => {
    setItemToEdit(undefined)
    dismissPanel()
  }

  return (
    <MainTemplate title="Defectoorzaken || Kostensoorten" subTitle="overzicht" commandItems={commandItems}
                  error={error}>
      <TenantRuimteSoortErpFormPanel tenantRuimteSoortId={itemToEdit?.tenantRuimteSoortId ?? 0}
                                     defectLocatieNaam={itemToEdit?.defectLocatieNaam ?? ''}
                                     defectLocatieId={itemToEdit?.defectLocatieId ?? 0}
                                     ruimteSoortErpId={itemToEdit?.ruimtesoortErpId ?? 0} isOpen={isPanelOpen}
                                     dismissPanel={handleDismissPanel} invalidate={invalidate} />
      <div style={{ height: '70vh', position: 'relative' }}>
        <VgtPagedTable
          items={data?.items || undefined}
          height={'203'}
          pagingInfo={{
            pageSize: validatedSearchParams.pageSize,
            currentPage: validatedSearchParams.page,
            totalCount: data?.totalCount ?? 0,
            totalPages: data?.totalPages ?? 0,
            hasNextPage: data?.hasNextPage || false,
            hasPreviousPage: data?.hasPreviousPage || false,
            sortKey: validatedSearchParams.sortKey || 'naam',
            sortDirection: validatedSearchParams.sortDirection || 'asc'
          }}
          columns={columns}
          isLoading={isLoading}
          getKey={(item) => item?.tenantRuimteSoortId}
          onItemInvoked={(e) => handleItemInvoked(e)}
          onPageChanged={setPage}
          onSortChanged={setOrder}
          onSelectionChanged={setSelectedIds}
        />
      </div>
    </MainTemplate>
)
}