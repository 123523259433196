import React, { useMemo, useState } from 'react'
import { Dialog, DialogFooter, IDialogContentProps, IModalProps, DialogType, PrimaryButton } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'
import { IVgtFieldPropsBase } from 'lib/IVgtFieldPropsBase'

interface IDeleteConfirmationDialogProps extends IVgtFieldPropsBase {
  onCancel?: () => void
  showDialog: (show: boolean) => void
  subText?: string
}

export function useWarningDialog(onCancel?: () => void) {
  const [show, setShow] = useState(false)
  const [subText, setSubText] = useState('This warning dialog has no text set')

  const showDialog = (show: boolean) => {
    setShow(show)
  }

  const setDialogSubText = (text: string) => {
    setSubText(text)
  }

  return [(<WarningDialog showIf={show} showDialog={showDialog} subText={subText}
                          onCancel={onCancel} />), setDialogSubText, showDialog] as const
}

const WarningDialog: React.FC<IDeleteConfirmationDialogProps> = ({ subText, showIf, showDialog, onCancel }) => {

  const labelId: string = useId('dialogLabel')
  const subTextId: string = useId('subTextLabel')

  const modalProps = useMemo(
    (): IModalProps => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true
    }),
    [labelId, subTextId]
  )

  const dialogContentProps = useMemo(
    (): IDialogContentProps => ({
      type: DialogType.normal,
      closeButtonAriaLabel: 'Sluiten',
      title: 'Waarschuwing',
      subText: subText
    }),
    [subText]
  )

  //We first hide the component, and then call the onCancel callback if one was provided
  const hideHandler = () => {showDialog(false)
    if (onCancel) onCancel()
  }

  return (
    <div className='deleteConfirmationDialog'>
      <Dialog hidden={!showIf} onDismiss={hideHandler} dialogContentProps={dialogContentProps} modalProps={modalProps}>
        <DialogFooter>
          <PrimaryButton onClick={hideHandler} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </div>
  )
}
export default useWarningDialog
