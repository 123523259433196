import React, { useCallback } from 'react'

import { MessageBar, MessageBarType } from '@fluentui/react';
import { useAppDispatch, useTypedSelector } from 'store'
import { getGenericError } from 'store/selectors/app'
import { useNavigate } from 'react-router-dom'
import { resetGenericError } from 'store/actions/app/genericError'

const GenericError: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const genericError = useTypedSelector(getGenericError)

  const ToastError = () => (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={false}
      onDismiss={handleConfirmClick}
      dismissButtonAriaLabel="Close"
    >
      <b>{genericError.title}.</b>&ensp;{genericError.subText}
    </MessageBar>
  );

  const handleConfirmClick = useCallback(() => {
    dispatch(resetGenericError())
    if (genericError.route) {
      navigate(genericError.route)
    }
  }, [genericError])

  if (!genericError.title || !genericError.subText) return null

  return (
    <div>
      <ToastError />
    </div>
  )
}

export default GenericError
