import { z } from 'zod'
import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import VgtTextField from 'components/VgtTextField'
import { OkCancelButtonStack } from 'components/OkCancelButtonStack/OkCancelButtonStack'
import FluentPanel from 'components/FluentPanel/FluentPanel'
import { PanelType } from '@fluentui/react/lib/Panel'
import { FieldErrorsToMessage } from 'components/ErrorMessageBar/ErrorMessageBar'
import { VheNummeringUpdateDto, zodiosHooks } from '../../api/ApiClient'
import VgtCheckbox from '../../components/FluentCheckbox'
import { getPropertyName } from '../../lib/interfaceUtils'
import { getTitleAndMessage } from '../../services/HandleError'
import VgtNumberField from '../../components/FluentNumberField'

export interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof VheNummeringUpdateDto>;

const defaultNummeringValues = {
  isActief: false,
  startVanaf: 0,
  totaleLengteCode: 0,
  voorloopKarakter: '0',
  prefix: ''
} as CreateUpdateType

export const VheNummeringPanel: React.FC<IEditPanelProps> = props => {

  const [error, setError] = React.useState<string>()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(VheNummeringUpdateDto), mode: 'all', defaultValues: defaultNummeringValues
  })

  const isActive = watch(getPropertyName<CreateUpdateType>('isActief')) as boolean ?? false

  const { mutate, isLoading: isModifying } = zodiosHooks.usePutSysteemInstellingenVheNummering({}, {
    onSuccess: () => onCancel(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  }
  )

  const { isLoading } = zodiosHooks.useGetSysteemInstellingenVheNummering({}, {
    enabled: props.isOpen,
    onSuccess: (data) => reset(data)
  })

  const onCancel = () => {
    props.invalidate()
    reset({})
    setError(undefined)
    props.dismissPanel()
  }

  const onSubmit = (data: CreateUpdateType) => {
    setError(undefined)

    mutate(data)
  }

  return (
    <FluentPanel type={PanelType.medium} isOpen={props.isOpen} onDismiss={onCancel} headerText={'Wijzigen VHE nummering'} onDismissed={onCancel} error={error}>
      <VgtCheckbox name={getPropertyName<CreateUpdateType>('isActief')} label="Actief" control={control} errors={errors} />
      <VgtTextField label="Prefix" name={getPropertyName<CreateUpdateType>('prefix')} control={control} errors={errors} required disabled={!isActive} />
      <VgtNumberField label="Totale lengte" name={getPropertyName<CreateUpdateType>('totaleLengteCode')} control={control} errors={errors} required disabled={!isActive} />
      <VgtNumberField label="Voorloop karakter" name={getPropertyName<CreateUpdateType>('voorloopKarakter')} control={control} errors={errors} required disabled={!isActive} />
      <VgtNumberField label="Start vanaf" name={getPropertyName<CreateUpdateType>('startVanaf')} control={control} errors={errors} required disabled={!isActive} />

      <FluentPanel.Footer>
        <OkCancelButtonStack isSubmitting={isModifying} isLoading={isLoading} onOkClick={handleSubmit(onSubmit, (errors) => setError(FieldErrorsToMessage(errors)))} onCancelClick={onCancel} />
      </FluentPanel.Footer>
    </FluentPanel>
  )
}
