import { z } from 'zod'
import { usePagedParams } from '../../../services/usePagedParams'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { EditParams } from '../../vhe/VheEdit'
import {
  HuurcontractbepalingGebouwListDto,
  NietGekoppeldGebouwListDto,
  zodiosHooks
} from '../../../api/ApiClient'
import { CommandBar, IColumn, ICommandBarItemProps, Panel, PanelType, SearchBox } from '@fluentui/react'
import commandBarStyles from '../../../styles/commandBarStyles'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { createCommandButton } from '../../../lib/commandBarHelper'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import { IHuurcontractbepalingList, INietGekoppeldFormPanelProps } from './HuurcontractbepalingGebouwclusterList'
import { useBoolean } from '@fluentui/react-hooks'
import { getTitleAndMessage } from '../../../services/HandleError'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'


type HuurcontractbepalingGebouwListDtoType = z.infer<typeof HuurcontractbepalingGebouwListDto>;

export const HuurcontractbepalingGebouwList: React.FC<IHuurcontractbepalingList> = props => {
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const params = useParams()
  const validatedParams = EditParams.parse(params)
  const [error, setError] = useState<string>()

  const AddGebouwToSelection = () => {
    openGebouwPanel()
  }

  const RemoveGebouwFromSelection = () => {
    deleteRows({ huurcontractbepalingId: validatedParams.id, itemsToDelete: selectedIds })
  }
  const [isGebouwPanelOpen, {
    setTrue: openGebouwPanel,
    setFalse: dismissGebouwPanel
  }] = useBoolean(false)
  const commandbarItems = [
    createCommandButton('Koppelen', 'PlugConnected', AddGebouwToSelection),
    createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveGebouwFromSelection, selectedIds.length !== 0)
  ] as ICommandBarItemProps[]

  const { mutate: deleteRows } = zodiosHooks.useDeleteApiHuurcontractbepalingontkoppelingGebouw({}, {
    onSuccess: () => invalidateHuurcontractbepalingGebouwenData(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const {
    data: huurcontractbepalingGebouwenData,
    invalidate: invalidateHuurcontractbepalingGebouwenData,
    isLoading
  } = zodiosHooks.useGetApiHuurcontractbepalinglistGebouwen({
    queries: {
      HuurcontractbepalingId: validatedParams.id,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  useEffect(() => {
    if (huurcontractbepalingGebouwenData) props.setCount(huurcontractbepalingGebouwenData.totalCount)
  }, [huurcontractbepalingGebouwenData])

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Gebouw', getPropertyName<HuurcontractbepalingGebouwListDtoType>('gebouwNaam'), 'L', false)
    ]
  }, [])

  return (
    <div>
      <CommandBar items={commandbarItems} styles={commandBarStyles} />
      <ErrorMessageBar error={error} />
      <NietGekoppeldGebouwFormPanel huurcontractbepalingId={validatedParams.id} isOpen={isGebouwPanelOpen}
                                    refresh={invalidateHuurcontractbepalingGebouwenData}
                                    dismissPanel={dismissGebouwPanel} />
      <VgtPagedTable
        items={huurcontractbepalingGebouwenData?.items ?? undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: huurcontractbepalingGebouwenData?.totalCount ?? 0,
          totalPages: huurcontractbepalingGebouwenData?.totalPages ?? 0,
          hasNextPage: huurcontractbepalingGebouwenData?.hasNextPage ?? false,
          hasPreviousPage: huurcontractbepalingGebouwenData?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.gebouwId}
        onSelectionChanged={setSelectedIds}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </div>
  )

}

type NietGekoppeldGebouwListDtoType = z.infer<typeof NietGekoppeldGebouwListDto>;

const NietGekoppeldGebouwFormPanel: React.FC<INietGekoppeldFormPanelProps> = props => {

  const [error, setError] = React.useState<string>()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Naam', getPropertyName<NietGekoppeldGebouwListDtoType>('gebouwNaam'), 'L', false),
      createColumn('Code', getPropertyName<NietGekoppeldGebouwListDtoType>('gebouwCode'), 'L', false)
    ]
  }, [])

  const onCancel = () => {
    props.dismissPanel()
    props.refresh()
    invalidate()
  }

  const { data, invalidate, isLoading } = zodiosHooks.useGetApiHuurcontractbepalingnietGekoppeldGebouwen({
    queries: {
      HuurcontractbepalingId: props.huurcontractbepalingId,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })


  const onRenderFooterContent = () => {
    return (
      <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                           onOkClick={onSubmit}
                           onCancelClick={onCancel} />
    )
  }

  const {
    mutate: addKoppelingGebouw,
    isLoading: isSubmitting
  } = zodiosHooks.usePostApiHuurcontractbepalingkoppelingGebouw(
    {}, { onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const onSubmit = () => {
    addKoppelingGebouw({ huurcontractbepalingId: props.huurcontractbepalingId, gebouwIds: selectedIds })
  }

  return (
    <Panel
      type={PanelType.large}
      headerText={'Koppelen gebouwcluster'}
      isOpen={props.isOpen}
      onDismiss={onCancel}
      closeButtonAriaLabel="Sluiten"
      onRenderFooterContent={onRenderFooterContent}>
      <ErrorMessageBar error={error} />
      <SearchBox placeholder="Filter" styles={{ root: { width: '400px', margin: '6px' } }}
                 value={validatedSearchParams.filter}
                 onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                 onSearch={() => invalidate()} />
      <VgtPagedTable
        items={data?.items ?? undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage ?? false,
          hasPreviousPage: data?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.gebouwId}
        onSelectionChanged={setSelectedIds}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </Panel>
  )
}