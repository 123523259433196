import { ICommandBarItemProps } from '@fluentui/react'

export const createCommandButton = (title: string, iconName: string, onClick: any, isActive: boolean = true) => {
  return {
    key: title,
    text: title,
    iconOnly: false,
    iconProps: { iconName: iconName },
    split: false,
    ariaLabel: title,
    onClick: onClick,
    disabled: !isActive
  } as ICommandBarItemProps
}
