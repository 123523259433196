import { useNavigate } from 'react-router-dom'
import { usePagedParams } from '../../../services/usePagedParams'
import { useBoolean } from '@fluentui/react-hooks'
import React, { useMemo, useState } from 'react'
import useRoles from '../../../services/UseRoles'
import { WerkgebiedListDto, zodiosHooks } from '../../../api/ApiClient'
import { getTitleAndMessage } from '../../../services/HandleError'
import { IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { WerkgebiedFormPanel } from './WerkgebiedFormPanel'


type WerkgebiedListDtoType = z.infer<typeof WerkgebiedListDto>

export const WerkgebiedList = () => {

  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [itemToEdit, setItemToEdit] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const { isReparatieboom } = useRoles()
  const [error, setError] = React.useState<string>()


  const { data, invalidate, isLoading } = zodiosHooks.useGetWerkgebied({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteWerkgebied({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })


  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
    {
      text: 'Toevoegen',
      onClick: () => {
        openPanel()
      },
      icon: 'Add',
      iconOnly: true,
      visible: isReparatieboom
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        deleteRows({ itemsToDelete: selectedIds })
      },
      icon: 'Delete',
      iconOnly: true,
      disabled: isDeleting || selectedIds.length === 0
    }
  ], [isReparatieboom, selectedIds.length])


  const handleItemInvoked = (id: string) => {
    if (id) {
      setItemToEdit(parseInt(id))
      openPanel()
    }
  }

  const handleDismissPanel = () => {
    setItemToEdit(0)
    dismissPanel()
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Naam', getPropertyName<WerkgebiedListDtoType>('naam'), 'XL', true,
        (item: WerkgebiedListDtoType) => (
          <Link key={item.werkgebiedId} onClick={() => handleItemInvoked(item.werkgebiedId.toString())}>
            {item.naam}
          </Link>
        ))
    ]
  }, [])


  return (
    <MainTemplate title="Werkgebieden" subTitle="overzicht" commandItems={commandItems} error={error}>
      <WerkgebiedFormPanel werkgebiedId={itemToEdit} dismissPanel={handleDismissPanel} isOpen={isPanelOpen}
                           invalidate={invalidate} />
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount || 0,
          totalPages: data?.totalPages || 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.werkgebiedId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.werkgebiedId.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />

    </MainTemplate>
  )


}