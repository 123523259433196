import { IColumn, Link, SelectionMode } from '@fluentui/react'
import FluentTable from 'components/FluentTable'
import InputCard from 'components/InputCard'
import CommandBarTemplate from 'containers/pages/PageTemplates/CommandBarTemplate'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import { useState } from 'react'
import { IKadastraalObject } from './../kadastraalObjectSlice'
import { IVve } from './../vveSlice'
import { createKadastraleKaartUrl } from 'services/navigationService'

const NavigateToKadastraleKaart = (kadastraleAanduiding: string) => {
  if (kadastraleAanduiding) {
    const url: string = createKadastraleKaartUrl(kadastraleAanduiding)
    window.open(url, '_blank')
  }
}

const renderKadastraleAanduiding = (x: IKadastraalObject) => (
  <Link key={x.kadastraleAanduiding} onClick={() => NavigateToKadastraleKaart(x.kadastraleAanduiding)}>
    {x.kadastraleAanduiding}
  </Link>
)

const bronColumns: IColumn[] = [
  createCustomRenderedColumn('Kadastrale aanduiding', getPropertyName<IKadastraalObject>('kadastraleAanduiding'), renderKadastraleAanduiding, 'L', false),
  createColumn('Adres gerechtigde', getPropertyName<IKadastraalObject>('gerechtigdeAdres'), 'M'),
]

export const KadastraleBronCard: React.FC<{ vve?: IVve; onDisconnect: (bronnen: string[]) => void; onOpenPanel: () => void }> = ({
  vve,
  onDisconnect,
  onOpenPanel,
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const bronCommandItems = [
    { text: 'Koppelen', onClick: () => onOpenPanel(), icon: 'PlugConnected', iconOnly: true },
    { text: 'Ontkoppelen', onClick: () => onDisconnect(selectedItems), icon: 'PlugDisconnected', iconOnly: true, disabled: !selectedItems.length },
  ]

  return (
    <InputCard title="Kadastrale bron(nen) splitsing" height="65%" width="100%">
      <CommandBarTemplate items={bronCommandItems}></CommandBarTemplate>
      <FluentTable
        columns={bronColumns}
        items={vve?.bronnenVoorSplitsing ?? []}
        selectionMode={SelectionMode.multiple}
        onSelectionChanged={(ids: string[]) => setSelectedItems(ids)}
        onGetKey={(item: IKadastraalObject) => item.kadastraleAanduiding}
        onItemInvoked={(id: string) => {}}
      />
    </InputCard>
  )
}
