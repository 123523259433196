import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useRoles from '../../../services/UseRoles'
import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import {
  DocumentClassificationListDto,
  zodiosHooks
} from '../../../api/ApiClient'
import { usePagedParams } from '../../../services/usePagedParams'
import { Checkbox, IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { z } from 'zod'
import { useBoolean } from '@fluentui/react-hooks'
import DocumentClassificationFormPanel from './DocumentClassificationFormPanel'
import { getTitleAndMessage } from '../../../services/HandleError'

type DocumentClassificationListDtoType = z.infer<typeof DocumentClassificationListDto>;
export const DocumentClassificationList = () => {
  const navigate = useNavigate()
  const { isAdmin } = useRoles()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [itemToEdit, setItemToEdit] = useState<number>(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = React.useState<string>()

  validatedSearchParams.pageSize = 30
  validatedSearchParams.sortKey = 'soort'

  const { data, invalidate, isLoading } = zodiosHooks.useGetApiDocumentsclassifications({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteApiDocumentsclassifications({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Soort', getPropertyName<DocumentClassificationListDtoType>('soort'), 'XL', true,
        (item: DocumentClassificationListDtoType) => (
          <Link key={item.documentSoortKenmerkId} onClick={() => handleItemInvoked(item.documentSoortKenmerkId.toString())}>
            {item.soort}
          </Link>
        )),
      createColumn('Kenmerk', getPropertyName<DocumentClassificationListDtoType>('kenmerk'), 'XL', true,
        (item: DocumentClassificationListDtoType) => (
          <Link key={item.documentSoortKenmerkId} onClick={() => handleItemInvoked(item.documentSoortKenmerkId.toString())}>
            {item.kenmerk}
          </Link>
        )),
      createColumn('Vastgoedeenheid', getPropertyName<DocumentClassificationListDtoType>('isVge'), 'L', true, (item: DocumentClassificationListDtoType) => <Checkbox checked={item.isVge} />),
      createColumn('Gebouw', getPropertyName<DocumentClassificationListDtoType>('isGebouw'), 'L', true, (item: DocumentClassificationListDtoType) => <Checkbox checked={item.isGebouw} />),
      createColumn('Gebouwdeel', getPropertyName<DocumentClassificationListDtoType>('isGebouwdeel'), 'L', true, (item: DocumentClassificationListDtoType) => <Checkbox checked={item.isGebouwdeel} />),
      createColumn('Gebouwencluster', getPropertyName<DocumentClassificationListDtoType>('isGebouwCluster'), 'L', true, (item: DocumentClassificationListDtoType) => <Checkbox checked={item.isGebouwCluster} />),
      createColumn('Cartotheek-Item', getPropertyName<DocumentClassificationListDtoType>('isInstallatie'), 'L', true, (item: DocumentClassificationListDtoType) => <Checkbox checked={item.isInstallatie} />),
      createColumn('Onderhoudscontract', getPropertyName<DocumentClassificationListDtoType>('isOnderhoudscontract'), 'L', true, (item: DocumentClassificationListDtoType) => <Checkbox checked={item.isOnderhoudscontract} />),
      createColumn('Ruimte', getPropertyName<DocumentClassificationListDtoType>('isVgeRuimte'), 'L', true, (item: DocumentClassificationListDtoType) => <Checkbox checked={item.isVgeRuimte} />),
      createColumn('Vhe', getPropertyName<DocumentClassificationListDtoType>('isVhe'), 'L', true, (item: DocumentClassificationListDtoType) => <Checkbox checked={item.isVhe} />),
    ]
  }, [])


  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
    {
      text: 'Toevoegen',
      onClick: () => {
        openPanel()
      },
      icon: 'Add',
      iconOnly: true,
      disabled: !isAdmin,
      visible: isAdmin,
    },
    {
      text: 'Verwijderen',
      onClick: () => {deleteRows({ itemsToDelete: selectedIds })},
      icon: 'Delete',
      iconOnly: true,
      disabled: isDeleting || selectedIds.length === 0 || !isAdmin
    }
  ], [isAdmin, selectedIds.length])

  const handleItemInvoked = (id: string) => {
    if (id) {
      setItemToEdit(parseInt(id))
      openPanel()
    }
  }
  const handleDismissPanel = () => {
    setItemToEdit(0)
    dismissPanel()
  }

  return (
    <MainTemplate title="Classificatie Document" subTitle="overzicht" commandItems={commandItems} error={error}>
      <DocumentClassificationFormPanel documentSoortKenmerkId={itemToEdit} dismissPanel={handleDismissPanel} isOpen={isPanelOpen}
                                       invalidate={invalidate}  />
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.documentSoortKenmerkId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={(e) => handleItemInvoked(e.documentSoortKenmerkId.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}

export default DocumentClassificationList
