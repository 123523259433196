import { IColumn, IStackStyles, Link, Stack } from '@fluentui/react'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import { PageIdDefinition, PAGES } from 'pages'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePagedParams } from '../../services/usePagedParams'
import { GebouwenListDto, zodiosHooks } from '../../api/ApiClient'
import { getTitleAndMessage } from '../../services/HandleError'
import ErrorMessageBar from '../../components/ErrorMessageBar/ErrorMessageBar'
import { VgtPagedTable } from '../../components/VgtPagedTable/VgtPagedTable'
import { z } from 'zod'

type GebouwDtoType = z.infer<typeof GebouwenListDto>;

const stackContainerStyles: Partial<IStackStyles> = {
  root: {
    marginLeft: 2,
    marginRight: 2
  }
}

interface GebouwenTableProps {
  filter: string
}

const GebouwenTable: React.FC<GebouwenTableProps> = ({ filter }) => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [error, setError] = useState<string>()

  const {
    data: fysiekGebouwen,
    isLoading: isFetchingFysiekGebouwenItems
  } = zodiosHooks.useGetGebouwendetails({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const getGebouwType = (type: number | undefined) => {
    switch (type) {
      case 1:
        return <span>Hoogbouw</span>
      case 2:
        return <span>Laagbouw</span>
      default:
        return ''
    }
  }

  const handleItemInvoked = (id: string) => {
    let url: string
    if (id) url = PAGES[PageIdDefinition.beheer.fysiek.gebouwEdit].route.replace(':id', id)
    else url = PAGES[PageIdDefinition.beheer.fysiek.gebouwEdit].route
    navigate(url)
  }

  const columns: IColumn[] = [
    createCustomRenderedColumn('Code', getPropertyName<GebouwDtoType>('code'),
      (item: GebouwDtoType) => <Link key={item.gebouwId}
                                     onClick={() => handleItemInvoked(item.gebouwId.toString())}>{item.code}</Link>, 65, false),
    createColumn('Naam', getPropertyName<GebouwDtoType>('naam'), 275),
    createCustomRenderedColumn('Type bouw', getPropertyName<GebouwDtoType>('type'), (item: GebouwDtoType) => getGebouwType(item?.type!), 75, false),
    createColumn('Omschrijving', getPropertyName<GebouwDtoType>('omschrijving'), 275)
  ]

  return (
    <Stack styles={stackContainerStyles}>
      <ErrorMessageBar error={error} />
      <VgtPagedTable
        items={fysiekGebouwen?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: fysiekGebouwen?.totalCount ?? 0,
          totalPages: fysiekGebouwen?.totalPages ?? 0,
          hasNextPage: fysiekGebouwen?.hasNextPage ?? false,
          hasPreviousPage: fysiekGebouwen?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        columns={columns}
        isLoading={isFetchingFysiekGebouwenItems}
        getKey={(item) => item?.gebouwId}
        onItemInvoked={(e) => handleItemInvoked(e.gebouwId.toString())}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </Stack>
  )
}

export default GebouwenTable
