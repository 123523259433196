import React, { FC, useEffect, useState } from 'react'
import { IInstallatie, installatieSchema } from '../installatie/installatieTypes'
import { CommandBar, MessageBar, MessageBarType, Pivot, PivotItem } from '@fluentui/react'
import { convertToDateString } from 'components/HorizontalDatePicker'
import { getPropertyName } from 'lib/interfaceUtils'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import commandBarStyles from 'styles/commandBarStyles'
import { yupResolver } from '@hookform/resolvers/yup'
import { CartotheekDetailAlgemeen } from './CartotheekDetailAlgemeen'
import { createCommandButton } from 'lib/commandBarHelper'
import { CartotheekDetailProduct } from './CartotheekDetailProduct'
import { useSelector } from 'react-redux'
import { getSelectedInstallatie } from './CartotheekStateMachine'
import DocumentList, { DocumentDoelType } from '../documents/DocumentList'

interface CartotheekDetailProps {
  onNewClicked: () => void
  onSubmit: (data: IInstallatie) => Promise<void>;
  onCancel: () => void

  onDeleteClicked?(id: number): void
}

const formDefaults: IInstallatie = {
  id: 0,

  categorieId : -1,
  categorieDisplay: '',
  elementId: -1,
  elementDisplay: '',
  elementdetailId: -1,
  elementdetailDisplay: '',
  nlSfbElementId: -1,
  nlSfbOmschrijving: '',
  nlSfbCode: '',
  vgeId: -1,
  gebouwDeelId: -1,
  gebouwId: -1,
  asbest: false,
  zavOnderhoud: false,
  woningVerbeteringType: 0,
  collectief: false,
  defectlocatieId: -1,
  productUse2BA: false,
  vgeRuimteId: -1,
  leverancierId: -1,
  locatieaanduiding: '',
  productGtin: '',
  productHoogte: 0,
  productMerk: '',
  productType: '',
  productSerie: '',
  referentieExtern: '',
  referentieKostenplaats: '',
  beheerderId: -1,
  bron: '',
  opmerking: '',
  contractId: -1,
  productKlasse: '',
  productGln: '',
  productFabrikant: '',
  productCode: '',
  productFabricageJaar: 1900,
  productKleur: '',
  productLengte: 0,
  productBreedte: 0,
  productAantal: 0,
  productEenheidId: -1,
  garantieIngangsDatum: '',
  garantieEindDatum: '',
  garantieProductNotificatieDatum: '',
  productOpmerking: '',
  plaatsingDatum: '',
  vervangingsDatum: '',
  werkzaamhedenIngangsDatum: '',
  werkzaamhedenEindDatum: '',
  werkzaamhedenNotificatieDatum: '',
  keuringDatum: '',
  keuringGeldigTot: '',
  keuringNotificatieDatum: ''
}

export const CartotheekDetail: FC<CartotheekDetailProps> = (props) => {
  const [errorMessages, setErrorMessages] = useState<string[]>()
  const [documentCount, setDocumentCount] = useState(0)
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<IInstallatie>({
    resolver: yupResolver(installatieSchema),
    mode: 'all',
    defaultValues: formDefaults
  })

  const selectedInstallatie = useSelector(getSelectedInstallatie)

  useEffect(() => {
    if (selectedInstallatie == null) return

    reset({ ...formDefaults, ...selectedInstallatie })
  }, [selectedInstallatie])

  const createCommandButtonItems = () => {
    const result = [
      createCommandButton('Opslaan', 'Save', handleSubmit(onSubmit, onError), !isSubmitting),
      createCommandButton('Annuleren', 'Cancel', () => props.onCancel())]
    if ((selectedInstallatie?.id ?? 0) !== 0) result.push(createCommandButton('Verwijder', 'Delete', () => onDelete(selectedInstallatie!.id!)))
    if ((selectedInstallatie?.id ?? 0) !== 0) result.push(createCommandButton('Nieuw', 'Add', () => onNewClicked()))

    return result
  }

  const onDelete = (id: number) => {
    if (props.onDeleteClicked)
      props.onDeleteClicked(id)
  }

  const onNewClicked = () => {
    props.onNewClicked()
  }

  const onSubmit = async (data: IInstallatie) => {

    data.vgeId = selectedInstallatie?.vgeId
    data.gebouwId = selectedInstallatie?.gebouwId
    data.gebouwDeelId = selectedInstallatie?.gebouwDeelId
    data.id = selectedInstallatie?.id

    if (data.woningVerbeteringType === 0) data.woningVerbeteringType = 0
    if (data.beheerderId === -1) data.beheerderId = undefined
    if (data.vgeRuimteId === -1) data.vgeRuimteId = undefined
    if (data.contractId === -1) data.contractId = undefined
    if (data.nlSfbElementId === -1) data.nlSfbElementId = undefined

    if (data.elementdetailId === -1) data.elementdetailId = undefined
    if (data.elementId === -1) data.elementId = undefined

    if (data.leverancierId === -1) data.leverancierId = undefined
    if (data.keuringPartijId === -1) data.keuringPartijId = undefined
    if (data.defectlocatieId === -1) data.defectlocatieId = undefined
    if (data.gebouwId === -1) data.gebouwId = undefined
    if (data.gebouwDeelId === -1) data.gebouwDeelId = undefined
    if (data.productEenheidId === -1) data.productEenheidId = undefined
    data.defectlocatieId = data.defectlocatieId === 0 ? null : data.defectlocatieId

    if (data.nlSfbElementId === undefined) data.nlSfbElementId = undefined

    data.keuringDatum = data.keuringDatum ? convertToDateString(data.keuringDatum) : undefined
    data.keuringGeldigTot = data.keuringGeldigTot ? convertToDateString(data.keuringGeldigTot) : undefined
    data.keuringNotificatieDatum = data.keuringNotificatieDatum ? convertToDateString(data.keuringNotificatieDatum) : undefined
    data.vervangingsDatum = data.vervangingsDatum ? convertToDateString(data.vervangingsDatum) : undefined
    data.werkzaamhedenIngangsDatum = data.werkzaamhedenIngangsDatum ? convertToDateString(data.werkzaamhedenIngangsDatum) : undefined
    data.werkzaamhedenEindDatum = data.werkzaamhedenEindDatum ? convertToDateString(data.werkzaamhedenEindDatum) : undefined
    data.werkzaamhedenNotificatieDatum = data.werkzaamhedenNotificatieDatum ? convertToDateString(data.werkzaamhedenNotificatieDatum) : undefined
    data.garantieProductNotificatieDatum = data.garantieProductNotificatieDatum ? convertToDateString(data.garantieProductNotificatieDatum) : undefined
    data.garantieIngangsDatum = data.garantieIngangsDatum ? convertToDateString(data.garantieIngangsDatum) : undefined
    data.garantieEindDatum = data.garantieEindDatum ? convertToDateString(data.garantieEindDatum) : undefined
    data.plaatsingDatum = data.plaatsingDatum ? convertToDateString(data.plaatsingDatum) : undefined

    if (data.keuringDatum === '') data.keuringDatum = undefined
    if (data.keuringGeldigTot === '') data.keuringGeldigTot = undefined
    if (data.keuringNotificatieDatum === '') data.keuringNotificatieDatum = undefined
    if (data.vervangingsDatum === '') data.vervangingsDatum = undefined
    if (data.werkzaamhedenIngangsDatum === '') data.werkzaamhedenIngangsDatum = undefined
    if (data.werkzaamhedenEindDatum === '') data.werkzaamhedenEindDatum = undefined
    if (data.werkzaamhedenNotificatieDatum === '') data.werkzaamhedenNotificatieDatum = undefined
    if (data.garantieProductNotificatieDatum === '') data.garantieProductNotificatieDatum = undefined
    if (data.garantieIngangsDatum === '') data.garantieIngangsDatum = undefined
    if (data.garantieEindDatum === '') data.garantieEindDatum = undefined
    if (data.plaatsingDatum === '') data.plaatsingDatum = undefined

    await props.onSubmit(data);
  };
  

  const onError = (errors: FieldErrors<IInstallatie>) => {
    const errorArray = Object.values(errors)
    const messages = errorArray.map(e => e.message ?? '')
    setErrorMessages(messages)
  }

  return (
    <>
      {errorMessages && (
        <MessageBar messageBarType={MessageBarType.warning} dismissButtonAriaLabel="Close"
                    onDismiss={() => setErrorMessages(undefined)}>
          <ul>{errorMessages.map(e => (<li key={e}>{e}</li>))} </ul>
        </MessageBar>
      )}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Controller name={getPropertyName<IInstallatie>('productUse2BA')} control={control} render={() => <></>} />
        <Controller name={getPropertyName<IInstallatie>('elementdetailId')} control={control} render={() => <></>} />
        <Controller name={getPropertyName<IInstallatie>('categorieId')} control={control} render={() => <></>} />
        <Controller name={getPropertyName<IInstallatie>('elementId')} control={control} render={() => <></>} />
        <Pivot style={{}}>
          <PivotItem headerText="Algemeen" itemKey="general" alwaysRender>
            <CommandBar items={createCommandButtonItems()} styles={commandBarStyles} style={{ width: '98%' }} />
            <CartotheekDetailAlgemeen installatie={selectedInstallatie ?? {} as IInstallatie} errors={errors}
                                      setValue={setValue} control={control} />
            <CartotheekDetailProduct installatie={selectedInstallatie ?? {} as IInstallatie} watch={watch}
                                     setValue={setValue}
                                     errors={errors} control={control} />
          </PivotItem>
          {selectedInstallatie?.id !== undefined && (
            <PivotItem key="element_documenten" headerText="Media" itemCount={documentCount} itemKey="documents">
              <DocumentList doelType={DocumentDoelType.installatie} doelId={selectedInstallatie.id ?? 0} setDocumentCount={setDocumentCount} />
            </PivotItem>
          )}
        </Pivot>
      </form>
    </>
  )
}