import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect, useState } from 'react'
import {
  Link,
  ScrollablePane,
  ScrollbarVisibility
} from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import EditPanelGebouwHoeveelheid, { EditMode } from './editPanelGebouwHoeveelheid'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchMeetkundigeEenheden,
  selectAll as selectEenheden
} from 'features/beheer/basis/meetkundigeEenheden/meetkundigeEenheidSlice'
import { transformDateString } from 'features/beheer/lemdo/common/support'
import { GebouwHoeveelheidListDto, zodiosHooks } from '../../../../api/ApiClient'
import { usePagedParams } from '../../../../services/usePagedParams'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { z } from 'zod'

interface IHoeveelhedenProps {
  entityId: number
  entityType: number
  editMode: EditMode
}

export enum EntityType {
  Gebouw = 1,
  GebouwDeel = 2,
  GebouwCluster = 3
}

interface IConditieProps {
  conditie?: number
}

interface IEenheidProps {
  eenheidId?: number
}

type GebouwHoeveelheidListDtoType = z.infer<typeof GebouwHoeveelheidListDto>;

const Eenheid: React.FC<IEenheidProps> = props => {
  const eenheden = useSelector(selectEenheden)
  const dispatch = useDispatch()

  useEffect(() => {
    if (eenheden.length === 0) dispatch(fetchMeetkundigeEenheden())
  }, [])

  const eenheid = eenheden.find(e => e.id === props.eenheidId)
  return eenheid ? <label>{eenheid.code}</label> : <></>
}

const Conditie: React.FC<IConditieProps> = props => {
  let conditie = ''
  switch (props.conditie) {
    case 1:
      conditie = 'Uitstekend'
      break
    case 2:
      conditie = 'Goed'
      break
    case 3:
      conditie = 'Voldoende'
      break
    case 4:
      conditie = 'Matig'
      break
    case 5:
      conditie = 'Onvoldoende'
      break
    case 6:
      conditie = 'Slecht'
      break
    default:
      return <></>
  }
  const text = `${props.conditie} - ${conditie}`
  return <label>{text}</label>
}

const GebouwHoeveelhedenList: React.FC<IHoeveelhedenProps> = props => {
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItemId, setSelectedItemId] = useState<number>()
  const { validatedSearchParams, setOrder, setPage } = usePagedParams()

  const showItem = (id: number) => {
    setSelectedItemId(id)
    openPanel()
  }


  const { data, isLoading } = zodiosHooks.useGetGebouwenEntityIdhoeveelheden({
    params: { entityId: props.entityId as number },
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter,
      entityType: props.entityType
    }
  }, { enabled: props.entityId !== 0 })

  const columns = [
    createColumn(
      'Hoofdelementgroep',
      getPropertyName<GebouwHoeveelheidListDtoType>('hoofdElementGroep'),
      130,
      undefined,
      (item: GebouwHoeveelheidListDtoType) => <Link
        onClick={e => showItem(item.id)}>{item.hoofdElementGroep} - {item.hoofdElementGroepOmschrijving}</Link>
    ),
    createColumn('Plaatsaanduiding', getPropertyName<GebouwHoeveelheidListDtoType>('plaatsaanduiding'), 115),
    createColumn('Element', getPropertyName<GebouwHoeveelheidListDtoType>('element'), 55),
    createColumn('Element-omschrijving', getPropertyName<GebouwHoeveelheidListDtoType>('elementOmschrijving'), 'XXL'),
    createColumn('Hoeveelheid', getPropertyName<GebouwHoeveelheidListDtoType>('hoeveelheid'), 'M'),
    createColumn('Eenheid', getPropertyName<GebouwHoeveelheidListDtoType>('eenheidId'), 55, undefined, (item: GebouwHoeveelheidListDtoType) => (
      <Eenheid eenheidId={item.eenheidId} />
    )),
    createColumn('Vervangingsjaar', getPropertyName<GebouwHoeveelheidListDtoType>('vervangingsJaar'), 105),
    createCustomRenderedColumn('Inspectiedatum', getPropertyName<GebouwHoeveelheidListDtoType>('inspectieDatum'), (item: GebouwHoeveelheidListDtoType) =>
      <label>{transformDateString(item.inspectieDatum ?? '')}</label>, 105, false),
    createCustomRenderedColumn('Conditie', getPropertyName<GebouwHoeveelheidListDtoType>('conditie'), (item: GebouwHoeveelheidListDtoType) =>
      <Conditie
        conditie={item.conditie ?? undefined} />, undefined, false), createColumn('Element-toelichting', getPropertyName<GebouwHoeveelheidListDtoType>('toelichting'), 'L')
  ]

  return (
    <>
      <EditPanelGebouwHoeveelheid isOpen={isPanelOpen} dismissPanel={dismissPanel} itemId={selectedItemId}
                                  editMode={props.editMode} />
      <div style={{ height: '78.7vh', position: 'relative' }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <div style={{ height: '70vh', position: 'relative' }}>
            <VgtPagedTable
              isLoading={isLoading}
              items={data?.items ?? []}
              columns={columns}
              height={'203'}
              pagingInfo={{
                pageSize: validatedSearchParams.pageSize,
                currentPage: validatedSearchParams.page,
                totalCount: data?.totalCount ?? 0,
                totalPages: data?.totalPages ?? 0,
                hasNextPage: data?.hasNextPage ?? false,
                hasPreviousPage: data?.hasPreviousPage ?? false,
                sortKey: validatedSearchParams.sortKey || 'id',
                sortDirection: validatedSearchParams.sortDirection || 'desc'
              }}
              getKey={(item) => item?.id}
              onItemInvoked={(item) => showItem(item.id)}
              onPageChanged={setPage}
              onSortChanged={setOrder}
            />
          </div>
        </ScrollablePane>
      </div>
    </>
  )
}

export default GebouwHoeveelhedenList
