import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { entityAdapterInitState } from 'interfaces/entityAdapterState'
import { setRejectedState, setSucceededState, setPendingState } from 'lib/sliceSupport'
import { IBronBestandNEN2580 } from './bronBestand'

export interface IGebouwHoeveelhedenNEN2580 extends IBronBestandNEN2580 {}

const basePrefix = 'gebouwNEN2580'
const baseUrl = '/gebouwen/NEN2580'

export const fetchHoeveelheidGebouwNEN2580 = createAsyncThunk(`${basePrefix}/fetchById`, async (gebouwId: number, thunkAPI) => {
  return await invokeFetch<IGebouwHoeveelhedenNEN2580>(thunkAPI, 'GET', `${baseUrl}?id=${gebouwId}`)
})

const entityAdapter = createEntityAdapter<IGebouwHoeveelhedenNEN2580>()

export const gebouwHoeveelhedenNEN2580Slice = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchHoeveelheidGebouwNEN2580.pending, state => setPendingState(state))
    builder.addCase(fetchHoeveelheidGebouwNEN2580.fulfilled, (state, action: PayloadAction<IGebouwHoeveelhedenNEN2580>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(fetchHoeveelheidGebouwNEN2580.rejected, (state, action) => setRejectedState(state, action))
  },
})

const getSliceState = (state: RootState) => state.gebouwHoeveelhedenNEN2580

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}

export const { selectById } = entityAdapter.getSelectors<RootState>(state => state.gebouwHoeveelhedenNEN2580)

export default gebouwHoeveelhedenNEN2580Slice.reducer
