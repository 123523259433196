import React, { useEffect, useMemo, useState } from 'react'
import IElementDefectSoort from './elementDefectSoortenModel'
import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, ScrollablePane, SelectionMode, Spinner } from '@fluentui/react'
import { useDispatch, useSelector } from 'react-redux'
import IDefectSoort from '../defectSoort/defectSoortModel'
import '../common/lemdoList.css'
import '../common/grid.css'
import { Dictionary } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import downloadFile from 'services/downloadFile'
import UploadPanel from 'components/UploadPanel'
import { useBoolean } from '@fluentui/react-hooks'
import { IMatrixRow } from '../common/matrixSelectionTypes'
import { MatrixCell, MatrixCheckBox } from '../common/matrixSelection'
import { selectAll as selectDefectSoorten, fetchAll as fetchDefectSoorten } from '../defectSoort/defectSoortSlice'
import { selectAll as selectElementDefectSoorten, fetchAll as fetchElementDefectSoorten } from './elementDefectSoortSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'

interface IElementMatrixRow extends IMatrixRow {
  element: string
  elementDetail?: string
  materiaalSoort?: string
}

const createMatrix = (elementDefectSoorten: IElementDefectSoort[]): IElementMatrixRow[] => {
  return elementDefectSoorten.map(eds => {
    var dict = {} as Dictionary<string>
    eds.defectSoortIds.forEach(d => (dict[d.toString()] = '1'))
    return {
      rowId: eds.id,
      element: eds.element,
      elementDetail: eds.elementDetail,
      materiaalSoort: eds.materiaalSoort,
      cells: dict,
    }
  })
}

const ElementDefectSoortMatrix: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const defectSoorten = useSelector(selectDefectSoorten)
  const elementDefectSoorten = useSelector(selectElementDefectSoorten)
  const items = createMatrix(elementDefectSoorten)
  const [loading, setLoading] = useState(false)
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)

  const refreshItems = () => {
    dispatch(fetchElementDefectSoorten())
    dispatch(fetchDefectSoorten())
    setLoading(true)
  }

  useEffect(() => {
    if (defectSoorten.length === 0) dispatch(fetchDefectSoorten())
    if (elementDefectSoorten.length === 0) dispatch(fetchElementDefectSoorten())
  }, [])

  useEffect(() => {
    setLoading(elementDefectSoorten.length === 0)
  }, [items])

  const defectSoortColumns = defectSoorten.map<IColumn>((s: IDefectSoort) => {
    return {
      key: s.id.toString(),
      name: `${s.code} - ${s.naam}`,
      minWidth: 25,
      maxWidth: 25,
      isResizable: false,
      isRowHeader: true,
      headerClassName: 'headerClass',
      onRender: (item: IMatrixRow) => <MatrixCheckBox id={s.id} titles={item.cells} />,
    } as IColumn
  })

  const columns = [
    {
      key: 'element',
      name: ' ',
      isRowHeader: true,
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: IElementMatrixRow) => <MatrixCell text={item.element} />,
    } as IColumn,
    {
      key: 'elementDetail',
      name: ' ',
      isRowHeader: true,
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: IElementMatrixRow) => <MatrixCell text={item.elementDetail ?? ''} />,
    } as IColumn,
    {
      key: 'materiaalSoort',
      name: ' ',
      isRowHeader: true,
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: IElementMatrixRow) => <MatrixCell text={item.materiaalSoort ?? ''} />,
    } as IColumn,
  ].concat(defectSoortColumns) as IColumn[]

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
      {
        text: 'Exporteer naar Excel',
        onClick: () => {
          downloadFile('/api/element/exportDefectSoorten', 'Element defectsoorten.xlsx')
        },
        icon: 'Export',
      },
      { text: 'Importeer van Excel', onClick: () => openPanel(), icon: 'Import' },
    ],
    []
  )

  const handleDismiss = () => {
    dismissPanel()
    refreshItems()
  }

  return loading ? (
    <div className="spinnerWrapper">
      <Spinner>Laden...</Spinner>
    </div>
  ) : (
    <MainTemplate title="Cartotheek-item || Defectsoort" subTitle="Overzicht" commandItems={commandItems}>
      <UploadPanel title="Importeer wijzigen" dismissPanel={handleDismiss} isOpen={isOpen} uploadEndpoint={'/api/element/importDefectSoorten'} />
      <div style={{ height: '85vh', position: 'relative' }}>
        <ScrollablePane>
          <DetailsList
            columns={columns}
            items={items}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            constrainMode={ConstrainMode.unconstrained}
            compact={true}
            className="matrixGrid"
          />
        </ScrollablePane>
      </div>
    </MainTemplate>
  )
}

export default ElementDefectSoortMatrix
