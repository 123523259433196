import React, {useEffect, useMemo, useState} from 'react';
import {IMatrixRow} from "../lemdo/common/matrixSelectionTypes";
import {Dictionary} from "@reduxjs/toolkit";
import {useNavigate} from "react-router-dom";
import {useBoolean} from "@fluentui/react-hooks";
import {zodiosHooks} from "../../../api/ApiClient";
import {
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    ScrollablePane,
    SelectionMode,
    Spinner
} from "@fluentui/react";
import {MatrixCell, MatrixCheckBox} from "../lemdo/common/matrixSelection";
import downloadFile from "../../../services/downloadFile";
import MainTemplate from "../../../containers/pages/PageTemplates/MainTemplate";
import UploadPanel from "../../../components/UploadPanel";

interface ILeftItem {
    id: number;
    naam: string | null;
}

interface ITopItem {
    id: number;
    naam: string | null;
}

interface IMatrixItem {
    inspectieSectieTypeId: number
    elementElementDetailId: number
}

const createMatrix = (leftItems: ILeftItem[], matrixItems: IMatrixItem[]): IMatrixRow[] => {
    if (!leftItems || leftItems.length === 0) {
        return [];
    }

    return leftItems.map<IMatrixRow>(leftItem => {
        const selectionForRow = matrixItems?.filter(le => le.elementElementDetailId === leftItem.id)
        const dict = {} as Dictionary<string>

        selectionForRow?.forEach(l => (dict[l.inspectieSectieTypeId.toString()] = '1'))

        return {
            rowId: leftItem.id,
            cells: dict,
        }
    });
}

export const OpnamedetailCartotheek = () => {
    const navigate = useNavigate()
    const [items, setItems] = useState<any[]>([])
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)

    const {data: leftItems, invalidate: refreshLeftItems, isLoading: isLoadingLeftItems} = zodiosHooks.useGetInspectieOpnamedetailCartotheekmatrixLeft({});
    const {data: topItems, invalidate: refreshTopItems, isLoading: isLoadingTopItems} = zodiosHooks.useGetInspectieOpnamedetailCartotheekmatrixTop({});
    const {data: matrixItems, invalidate: refreshMatrixItems, isLoading: isLoadingMatrixItems} = zodiosHooks.useGetInspectieOpnamedetailCartotheekmatrix({});

    const refreshItems = () => {
        refreshLeftItems();
        refreshTopItems();
        refreshMatrixItems();
    }

    useEffect(() => {
        if (!leftItems || !topItems || !matrixItems) {
            setItems([]);
            return;
        }
        setItems(createMatrix(leftItems, matrixItems));
    }, [leftItems, topItems, matrixItems])

    const topItemsColumns = topItems?.map<IColumn>((s: ITopItem) => {
        return {
            key: s.id.toString(),
            name: s.naam,
            minWidth: 25,
            maxWidth: 25,
            isResizable: false,
            isRowHeader: false,
            headerClassName: 'headerClass',
            onRender: (item: IMatrixRow) => <MatrixCheckBox id={s.id} titles={item.cells}/>,
        } as IColumn
    });

    const columns = [
        {
            key: 'rowHeader',
            isRowHeader: true,
            minWidth: 250,
            maxWidth: 500,
            onRender: (item: IMatrixRow) => <MatrixCell text={getLeftRowName(item)}/>,
        } as IColumn,
    ] as IColumn[];

    if (topItemsColumns) {
        columns.push(...topItemsColumns);
    }

    const getLeftRowName = (row: IMatrixRow) => {
        const leftItem = leftItems?.filter(l => l.id === row.rowId)
        if (leftItem && leftItem.length !== 0) return leftItem[0].naam ?? ''
        return ''
    }

    const commandItems = useMemo(
        () => [
            { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
            { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
            {
                text: 'Exporteer naar Excel',
                onClick: () => {
                    downloadFile('/InspectieOpnamedetailCartotheek/export', 'InspectieOpnamedetailCartotheek.xlsx')
                },
                icon: 'Export',
            },
            { text: 'Importeer van Excel', onClick: () => openPanel(), icon: 'Import' },
        ],
        []
    )

    const handleDismiss = () => {
        dismissPanel()
        refreshItems()
    }

    const onSuccessUploadExcel = () => {
        handleDismiss()
    };
    
    return (isLoadingLeftItems || isLoadingMatrixItems || isLoadingTopItems) ? (
        <div className="spinnerWrapper">
            <Spinner>Laden...</Spinner>
        </div>
    ) : (
        <MainTemplate title="Opnamedetail cartotheek" subTitle="Overzicht" 
                      commandItems={commandItems}>
            <UploadPanel title="Importeer wijzigen" dismissPanel={handleDismiss} isOpen={isOpen}
                         uploadEndpoint={'/InspectieOpnamedetailCartotheek/import'}
                         onSuccess={onSuccessUploadExcel}/>
            <div style={{ height: 'calc(100vh - 180px)', position: 'relative' }}>
                <ScrollablePane>
                    <DetailsList
                        columns={columns}
                        items={items}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        constrainMode={ConstrainMode.unconstrained}
                        compact={true}
                        className="matrixGrid"
                    />
                </ScrollablePane>
            </div>
        </MainTemplate>
    )
};
