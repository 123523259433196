import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { store } from 'store'

const getToken = async (graphToken: boolean = false): Promise<string> => {
  const appCfg = store.getState().app.configuration
  const cfg: Configuration = {
    auth: {
      clientId: appCfg.msalClientId,
      redirectUri: appCfg.msalRedirectUri,
    },
  }

  const pca = new PublicClientApplication(cfg)
  const accounts = pca.getAllAccounts()
  const account = accounts[0]
  const resp = await pca.acquireTokenSilent({
    scopes: graphToken ? appCfg.graphScopes : appCfg.apiScopes,
    account,
  })

  return resp.accessToken
}

export const getApiToken = async (): Promise<string> => {
  return await getToken()
}

export const getGraphToken = async (): Promise<string> => {
  return await getToken(true)
}
