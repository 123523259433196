import React from 'react'
import './styles.css'
import { SharedColors } from '@fluentui/theme'

interface ICoMoonProps {
  color?: string,
  iconName: string,
  iconSize?: string | number
}

const ICoMoonFontIcon: React.FC<ICoMoonProps> = ({ color= SharedColors.red20, iconSize = 30, iconName }) => {

  const style: React.CSSProperties = {
    color: color,
    fontSize: iconSize
  }

  return (
    <span className={iconName} style={style}></span>
  )
}

export default ICoMoonFontIcon
