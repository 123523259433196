import React, { useEffect, useState } from 'react'
import { Panel, PanelType } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { getPropertyName } from 'lib/interfaceUtils'
import VgtTextField from 'components/VgtTextField'
import useRoles from 'services/UseRoles'
import { DefectSoortAddOrUpdateDto, DefectSoortDto, DefectSoortListDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { z } from 'zod'
import VgtNumberField from '../../../../components/FluentNumberField'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

type DefectSoortAddOrUpdateDtoType = z.infer<typeof DefectSoortAddOrUpdateDto>
type DefectSoortDtoType = z.infer<typeof DefectSoortDto>
type DefectSoortListDtoType = z.infer<typeof DefectSoortListDto>

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  fetchDefectSoortItems: () => void
  selectedDefectSoort: DefectSoortListDtoType | undefined
}

const EditPanelDefectSoort: React.FC<IEditPanelProps> = ({
                                                           isOpen,
                                                           fetchDefectSoortItems,
                                                           dismissPanel,
                                                           selectedDefectSoort
                                                         }) => {
  const [title, setTitle] = useState('Defectlocatie')
  const { isAdmin } = useRoles()
  const [error, setError] = useState<string>()

  const defaultDefectSoort = {
    id: selectedDefectSoort?.id ?? 0,
    code: selectedDefectSoort?.code ?? '',
    naam: selectedDefectSoort?.naam ?? '',
    omschrijving: selectedDefectSoort?.omschrijving ?? '',
    ingangsDatum: new Date().toJSON(),
    eindDatum: selectedDefectSoort?.eindDatum ?? null,
    veraVersie: selectedDefectSoort?.veraVersie ?? null
  }

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<DefectSoortDtoType>({ mode: 'all' })

  const onDataSubmitttedSuccesfully = () => {
    fetchDefectSoortItems()
    dismissPanel()
  }


  const { mutate: postApiDefectSoort, isLoading: isPostingDefectSoort } = zodiosHooks.usePostApiDefectSoort({}, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: putApiDefectSoortId, isLoading: isUpdatingDefectSoort } = zodiosHooks.usePutApiDefectSoortId(
    { params: { id: selectedDefectSoort?.id?.toString() ?? '' } }, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  useEffect(() => {
    setTitle(`Defectlocatie ${selectedDefectSoort ? (isAdmin ? 'wijzigen' : '') : 'toevoegen'}`)
    if (selectedDefectSoort) {
      reset(selectedDefectSoort)
    } else {
      reset({})
    }
  }, [selectedDefectSoort])

  const onCancel = () => {
    dismissPanel()
  }

  const onSubmit = (data: DefectSoortDtoType | DefectSoortAddOrUpdateDtoType) => {

    if (!data.omschrijving) {
      data.omschrijving = null
    }
    if (!data.veraVersie) {
      data.veraVersie = null
    }

    if (selectedDefectSoort) {
      putApiDefectSoortId({ ...defaultDefectSoort, ...data } as DefectSoortDtoType)
    } else {
      postApiDefectSoort({ ...defaultDefectSoort, ...data } as DefectSoortAddOrUpdateDtoType)
    }
  }

  return (
    <Panel type={PanelType.medium} headerText={title} isOpen={isOpen} onDismiss={onCancel}
           closeButtonAriaLabel='Sluiten'>
      <ErrorMessageBar error={error} />
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <VgtTextField label='Code' name={getPropertyName<DefectSoortAddOrUpdateDtoType>('code')} register={register}
                      control={control}
                      errors={errors} required readOnly={!isAdmin} />
        <VgtTextField label='Naam' name={getPropertyName<DefectSoortAddOrUpdateDtoType>('naam')} register={register}
                      control={control}
                      errors={errors} required multiline readOnly={!isAdmin} />
        <VgtTextField label='Omschrijving' name={getPropertyName<DefectSoortAddOrUpdateDtoType>('omschrijving')}
                      register={register}
                      control={control} errors={errors} readOnly={!isAdmin} />
        <VgtNumberField label='VERA versie' name={getPropertyName<DefectSoortAddOrUpdateDtoType>('veraVersie')}
                        control={control} errors={errors} readOnly={!isAdmin} />
        <OkCancelButtonStack
          isSubmitting={isSubmitting}
          isLoading={isPostingDefectSoort || isUpdatingDefectSoort}
          onOkClick={handleSubmit(onSubmit)}
          onCancelClick={onCancel}
          isWriter={isAdmin}
        />
      </form>
    </Panel>
  )
}

export default EditPanelDefectSoort
