import { z } from 'zod'
import {
  InspectieSjabloonAddOrUpdateDto,
  InspectieSjabloonTenantInspectietaakListDto,
  zodiosHooks
} from '../../../api/ApiClient'
import React, { useState } from 'react'
import { getTitleAndMessage } from '../../../services/HandleError'
import { useNavigate, useParams } from 'react-router-dom'
import { EditParams } from '../../vhe/VheEdit'
import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import { InspectieSjabloonForm } from './InspectieSjabloonForm'
import { CommandBar, IColumn, ICommandBarItemProps, Pivot, PivotItem } from '@fluentui/react'
import commandBarStyles from '../../../styles/commandBarStyles'
import { createCommandButton } from '../../../lib/commandBarHelper'
import { InspectieSjabloonTenantInspectietaakFormPanel } from './InspectieSjabloonTenantInspectietaakFormPanel'
import { useBoolean } from '@fluentui/react-hooks'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { usePagedParams } from '../../../services/usePagedParams'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'


export type CreateUpdateType = z.infer<typeof InspectieSjabloonAddOrUpdateDto>;
type InspectieSjabloonTenantInspectietaakListDtoType = z.infer<typeof InspectieSjabloonTenantInspectietaakListDto>;

export const InspectieSjabloonEdit = () => {
  const navigate = useNavigate()
  const params = useParams()
  const validatedParams = EditParams.parse(params)
  const [error, setError] = useState<string>()
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const [isInspectietaakPanelOpen, {
    setTrue: openInspectietaakPanel,
    setFalse: dismissInspectietaakPanel
  }] = useBoolean(false)
  const {
    data,
    isLoading,
    invalidate
  } = zodiosHooks.useGetApiInspectieSjabloonId({ params: { id: validatedParams.id } }, { enabled: validatedParams.id > 0 })

  const { mutate: addInspectieSjabloonen } = zodiosHooks.usePutApiInspectieSjabloonId(
    { params: { id: validatedParams.id } }, {
      onSuccess: () => invalidate().then(() => navigate(-1)),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true }
  ]

  const setComponentError = (error: string) => setError(error)

  const AddInspectieToSelection = () => {
    openInspectietaakPanel()
  }

  const RemoveInspectieFromSelection = () => {
    deleteRows({ itemsToDelete: selectedIds })
    invalidateInspectietakenData()
  }

  const taakCommandbarItems = [
    createCommandButton('Koppelen', 'PlugConnected', AddInspectieToSelection),
    createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveInspectieFromSelection)
  ] as ICommandBarItemProps[]

  const { data: inspectietakenData, invalidate : invalidateInspectietakenData } = zodiosHooks.useGetApiInspectieSjabloonlistOfInspectietaken({
    queries: {
      InspectieSjabloonId: validatedParams.id,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows } = zodiosHooks.useDeleteApiInspectieSjabloonontkoppelingInspectietaak({}, {
    onSuccess: () => invalidateInspectietakenData(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })


  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Inspectietaken', getPropertyName<InspectieSjabloonTenantInspectietaakListDtoType>('taak'), 'L', false)
    ]
  }, [])

  return (
    <MainTemplate title="Inspectiesjabloon" subTitle="Bewerken" commandItems={commandItems} error={error}>
      <InspectieSjabloonForm onderhoudssjablonId={validatedParams.id} data={data} isLoading={isLoading} isSubmitting={isLoading}
                             onSubmit={addInspectieSjabloonen} onCancel={() => navigate(-1)}
                             onError={setComponentError} />

      <Pivot>
        <PivotItem key="inspectietaken" headerText="Inspectietaken" itemCount={inspectietakenData?.totalCount}>
          <CommandBar items={taakCommandbarItems} styles={commandBarStyles} />
          <InspectieSjabloonTenantInspectietaakFormPanel inspectieSjabloonId={validatedParams.id} isOpen={isInspectietaakPanelOpen}
                                                         refresh={invalidateInspectietakenData}
                                                         dismissPanel={dismissInspectietaakPanel}  />
          <VgtPagedTable
            items={inspectietakenData?.items ?? undefined}
            height={'203'}
            pagingInfo={{
              pageSize: validatedSearchParams.pageSize,
              currentPage: validatedSearchParams.page,
              totalCount: inspectietakenData?.totalCount ?? 0,
              totalPages: inspectietakenData?.totalPages ?? 0,
              hasNextPage: inspectietakenData?.hasNextPage ?? false,
              hasPreviousPage: inspectietakenData?.hasPreviousPage ?? false,
              sortKey: validatedSearchParams.sortKey || 'taak',
              sortDirection: validatedSearchParams.sortDirection || 'asc'
            }}
            columns={columns}
            isLoading={isLoading}
            getKey={(item) => item?.inspectieSjabloonTenantInspectietaakId}
            onSelectionChanged={setSelectedIds}
            onPageChanged={setPage}
            onSortChanged={setOrder}
          />
        </PivotItem>
      </Pivot>
    </MainTemplate>
  )
}