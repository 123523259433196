import { z } from 'zod'
import { InspectieSjabloonAddOrUpdateDto, zodiosHooks } from '../../../api/ApiClient'
import React from 'react'
import { getTitleAndMessage } from '../../../services/HandleError'
import { useNavigate } from 'react-router-dom'
import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import { InspectieSjabloonForm } from './InspectieSjabloonForm'

export type CreateUpdateType = z.infer<typeof InspectieSjabloonAddOrUpdateDto>;

export const InspectieSjabloonNew = () => {
  const navigate = useNavigate()
  const [error, setError] = React.useState<string>()

  const { mutate: addOnderhoudssjablon, isLoading } = zodiosHooks.usePostApiInspectieSjabloon(
    {}, { onSuccess: () => navigate(-1), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true }
  ]

  return (
    <MainTemplate title="Inspectiesjabloon" subTitle="Nieuw" commandItems={commandItems} error={error}>
      <InspectieSjabloonForm onderhoudssjablonId={0} isLoading={isLoading} isSubmitting={isLoading} onSubmit={addOnderhoudssjablon} onCancel={() => navigate(-1)} />
    </MainTemplate>
  )
}