import React, { useState } from 'react'
import { IDropdownOption, PanelType, Stack, StackItem } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import { z } from 'zod'
import { TenantTaakAddOrUpdateDto, zodiosHooks } from 'api/ApiClient'
import useRoles from 'services/UseRoles'
import { getTitleAndMessage } from 'services/HandleError'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { OkCancelButtonStack } from 'components/OkCancelButtonStack/OkCancelButtonStack'
import VgtCheckbox from '../../../../components/FluentCheckbox'
import FluentPanel from '../../../../components/FluentPanel/FluentPanel'
import { FieldErrorsToMessage } from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { InnerColumn } from './InnerColumn'

export interface IEditPanelProps {
  taakId: number,
  tenantTaakId: number,
  isOpen: boolean
  dismissPanel: () => void
  invalidate: () => void
}

const TenantTaakAddOrUpdateFormSchema = TenantTaakAddOrUpdateDto.omit({ taakNaam: true });
export type CreateUpdateType = z.infer<typeof TenantTaakAddOrUpdateFormSchema>;

export enum TaakType {
  Onbekend = 0,
  Onderhoudscontract = 2,
  GeenService = 1
}

export enum Betaler {
  Corporatie = 1,
  Huurder = 2,
  Dienstencomponent = 3
}

export const defaultTenantTaak = {
  tenantTaakId: 0,
  taakId: 0,
  taakNaam: '',
  isGecombineerd: true,
  taakTypeOptieId: TaakType.Onbekend,

  dienstencomponentIds: [],
  meetkundigeEenheidId: 0,
  onderhoudstaakErpId: 0,
  onderhoudsspecialismeId: 0,
  bewerkingstijdMin: 0,
  urgentieDgn: 0,
  afspraak: false,
  actief: false,
  tonenAanKlant: false,
  verkoopprijs: 0,
  kostprijs: 0,
  betalerOptieId: 0,
  inspectieVereist: false,
  teBeoordelen: false,
  portalToelichtingBijOntbrekendContract: '',
  portalToelichtingZelfOplossen: '',
  isSpoed: false,
  spoedToelichting: '',

  taakTypeOptieIdBuiten: TaakType.Onbekend,
  dienstencomponentIdsBuiten: [],
  meetkundigeEenheidIdBuiten: 0,
  onderhoudstaakErpIdBuiten: 0,
  onderhoudsspecialismeIdBuiten: 0,
  bewerkingstijdMinBuiten: 0,
  urgentieDgnBuiten: 0,
  afspraakBuiten: false,
  actiefBuiten: false,
  tonenAanKlantBuiten: false,
  verkoopprijsBuiten: 0,
  kostprijsBuiten: 0,
  betalerOptieIdBuiten: 0,
  inspectieVereistBuiten: false,
  teBeoordelenBuiten: false,
  portalToelichtingBijOntbrekendContractBuiten: '',
  portalToelichtingZelfOplossenBuiten: '',
  isSpoedBuiten: false,
  spoedToelichtingBuiten: ''
} as CreateUpdateType

export const taakTypeOptions = [
  { key: TaakType.Onbekend, text: '---' },
  { key: TaakType.Onderhoudscontract, text: 'Onderhoudscontract' },
  { key: TaakType.GeenService, text: 'Geen service' }
] as IDropdownOption[]

export const TenantTaakFormPanel: React.FC<IEditPanelProps> = props => {
  const { isReparatieboom } = useRoles()
  const [error, setError] = React.useState<string>()
  const [title, setTitle] = useState('Inrichten taken')
  const [inspectieSpecialismeId, setInspectieSpecialismeId] = useState<number>(0)

  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(TenantTaakAddOrUpdateFormSchema), mode: 'all', defaultValues: defaultTenantTaak
  })

  zodiosHooks.useGetTenantTaakId({ params: { id: props.tenantTaakId } }, {
      onSuccess: (data) => {
        reset(data)
        setTitle(`Inrichten taken - ${data.taakNaam}`)
      },
      enabled: props.tenantTaakId !== 0
    }
  )

  const { mutate: updateTenantTaak } = zodiosHooks.usePutTenantTaakId(
    { params: { id: props.tenantTaakId } }, {
      onSuccess: () => onPanelDismiss(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: addTenantTaak } = zodiosHooks.usePostTenantTaak(
    {},
    {
      onSuccess: () => onPanelDismiss(),
      onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const { data: dienstencomponenten } = zodiosHooks.useGetDienstenComponentasOpties()
  const { data: meetkundigeEenheden } = zodiosHooks.useGetApiMeetkundigeEenhedenasOptions()
  const { data: onderhoudstaken } = zodiosHooks.useGetOnderhoudsTaakErpopties()
  const { data: onderhoudsspecialisme } = zodiosHooks.useGetOnderhoudsspecialismeOpties(
    {}, {
      onSuccess: (data) => {
        setInspectieSpecialismeId(data.find(s => s.text.startsWith('INS -'))?.key ?? 0)
      }
    }
  )
  const { data: betalers } = zodiosHooks.useGetTenantKostensoortBetaleropties()

  const onPanelDismiss = () => {
    props.invalidate()
    reset(defaultTenantTaak)
    setTitle('Inrichten taken')
    setError(undefined)
    props.dismissPanel()
  }

  const onSubmitClick = () => {
    const data = {...getValues(), tenantTaakId: props.tenantTaakId, taakId: props.taakId}

    const parseResult = TenantTaakAddOrUpdateFormSchema.safeParse(data)
    if (!parseResult.success) {

      // create a dictionary with the path as key and the error message as value
      const errors = Object.fromEntries(parseResult.error.issues.map(i => [i.path[0], i.message]))
      setError(FieldErrorsToMessage(errors))
      return
    }

    if (props.tenantTaakId !== 0) {
      updateTenantTaak({...parseResult.data, taakNaam: ''})
    } else {
      addTenantTaak({...parseResult.data, taakNaam: ''})
    }
  }

  const onIsGecombineerdChanged = (checked: boolean | undefined) => {
    if (checked === undefined) return

    if (!checked) {
      const data = getValues()
      // copy all fields that contain Buiten in the name to the Buiten fields from the original binnen data
      for (const key in data) {
        if (key.endsWith('Buiten')) {
          setValue(key, data[key.replace('Buiten', '')])
        }
      }
    }
  }

  const isTaskActive = watch(getPropertyName<CreateUpdateType>('actief')) === true
  const isGecombineerd = watch(getPropertyName<CreateUpdateType>('isGecombineerd')) === true

  return (
    <div>
      <FluentPanel isOpen={props.isOpen} headerText={title} onDismiss={onPanelDismiss} error={error} type={PanelType.extraLarge}>

        <Stack horizontal tokens={{ childrenGap: '24px' }}>
          <StackItem styles={{ root: { width: '50%' } }}>
            <VgtCheckbox label="Actief" name={getPropertyName<CreateUpdateType>('actief')} control={control} />
            <InnerColumn visible={true} suffix="" onderhoudsspecialisme={onderhoudsspecialisme} onderhoudstaken={onderhoudstaken} meetkundigeEenheden={meetkundigeEenheden} watch={watch} setValue={setValue} inspectieSpecialismeId={inspectieSpecialismeId} betalers={betalers} dienstencomponenten={dienstencomponenten}
                         isTaskActive={isTaskActive} label="Binnen" control={control} errors={errors} />

          </StackItem>
          <StackItem styles={{ root: { width: '50%' } }}>
            <VgtCheckbox disabled={!isTaskActive} name={getPropertyName<CreateUpdateType>('isGecombineerd')} label="Buiten is gelijk aan binnen" control={control} afterChange={onIsGecombineerdChanged} />
              <InnerColumn visible={!isGecombineerd} suffix="Buiten"
                           isTaskActive={isTaskActive} label="Buiten"
                           onderhoudsspecialisme={onderhoudsspecialisme} onderhoudstaken={onderhoudstaken} meetkundigeEenheden={meetkundigeEenheden}
                           inspectieSpecialismeId={inspectieSpecialismeId} betalers={betalers} dienstencomponenten={dienstencomponenten}
                           watch={watch} setValue={setValue}
                           control={control} errors={errors} />
          </StackItem>
        </Stack>

        <FluentPanel.Footer>
          <OkCancelButtonStack isSubmitting={isSubmitting} isWriter={isReparatieboom} isLoading={isSubmitting ?? false}
             onOkClick={() => onSubmitClick()}
             onCancelClick={onPanelDismiss} />
        </FluentPanel.Footer>
      </FluentPanel>
    </div>
  )
}