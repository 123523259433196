import React from 'react'
import { DefaultButton, IButtonStyles, IContextualMenuProps } from '@fluentui/react'
import './index.css'

const buttonStyles: IButtonStyles = {
  root: {
    border: 0,
    marginLeft: '15px',
    marginTop: '10px',
    padding: '5px',
  },
  textContainer: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
}

interface IFluentViewSelectorProps {
  text: string
  menuProps: IContextualMenuProps
}

const FluentViewSelector: React.FC<IFluentViewSelectorProps> = ({ text, menuProps }) => {
  return <DefaultButton text={text} menuProps={menuProps} styles={buttonStyles} />
}

export default FluentViewSelector
