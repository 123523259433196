import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import { MetertypeDto, zodiosHooks } from '../../../api/ApiClient'
import React, { useState } from 'react'
import { ICommandItem } from '../../../containers/pages/PageTemplates/CommandBarTemplate'
import FluentTable from '../../../components/FluentTable'
import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import { SelectionMode } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { useBoolean } from '@fluentui/react-hooks'
import { MetertypeEdit } from './MetertypeEdit'
import ICoMoonFontIcon from '../../../components/IcoMoon'

type MetertypeDtoType = z.infer<typeof MetertypeDto>;
export const MetertypeList: React.FC = () => {
  const navigate = useNavigate()
  const [metertypeId, setMetertypeId] = useState<number | undefined>(undefined)
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const { data, invalidate, isLoading } = zodiosHooks.useGetMetertype()
  const { data: meetkundigeEenheden } = zodiosHooks.useGetApiMeetkundigeEenhedenfetchAll()

  const { mutate: deleteRows, isLoading: isDeleting } =
    zodiosHooks.useDeleteMetertype({}, {
      onSuccess: () => {
        setSelectedIds([])
        invalidate()
      },
      onError: (error) => alert(error)
    })
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const removeItems = () => {
    deleteRows({ itemsToDelete: selectedIds.map(v => parseInt(v)) })
  }

  const getEenheidText = (eenheidId: number | undefined) => {
    return meetkundigeEenheden?.items!! ? meetkundigeEenheden.items.find(i => i.id === eenheidId)?.code : ''
  }

  const refreshItems = () => {
    invalidate().then()
  }

  const commandItems: ICommandItem[] = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back' },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    {
      text: 'Toevoegen',
      onClick: () => {
        openPanel()
      },
      icon: 'Add'
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        removeItems()
      },
      icon: 'Delete',
      disabled: isDeleting || selectedIds.length === 0
    }
  ]

  const columns = [
    createColumn('Omschrijving', getPropertyName<MetertypeDtoType>('omschrijving'), 'L', true),
    {
      key: getPropertyName<MetertypeDtoType>('eenheidId'),
      name: 'Eenheid',
      fieldName: 'eenheidId',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      data: 'number',
      onRender: (item: MetertypeDtoType) => getEenheidText(item.eenheidId)
    },
    createColumn('Aantal decimalen', getPropertyName<MetertypeDtoType>('aantalDecimalen'), 'L'),
    createColumn('Fonticoon', getPropertyName<MetertypeDtoType>('fonticoonNaam'), 'L', false,
      (item: MetertypeDtoType) => <ICoMoonFontIcon iconName={item?.fonticoonNaam!} />)
  ]

  const handleItemInvoked = (id: string) => {
    setMetertypeId(parseInt(id))
    openPanel()
  }
  return (
    <MainTemplate title="Metertypen " subTitle="Overzicht" commandItems={commandItems}>
      <MetertypeEdit isOpen={isPanelOpen}
                     dismissPanel={() => {
                       dismissPanel()
                       setMetertypeId(undefined)
                     }}
                     invalidateParent={invalidate}
                     meterTypeId={metertypeId!}></MetertypeEdit>

      <div style={{ marginRight: 15 }}>
        <div style={{ display: 'flex', height: '70vh' }}>
          <FluentTable
            columns={columns}
            items={data?.items ?? [] as MetertypeDtoType[]}
            loading={isLoading}
            selectionMode={SelectionMode.multiple}
            onSelectionChanged={setSelectedIds}
            onItemInvoked={handleItemInvoked}
            onGetKey={item => item.metertypeId.toString()}
          />
        </div>
      </div>
    </MainTemplate>)
}