import React, { useEffect, useState } from 'react'
import { IDropdownOption, Panel, PanelType } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { getPropertyName } from 'lib/interfaceUtils'
import VgtTextField from 'components/VgtTextField'
import useRoles from 'services/UseRoles'
import VgtDropdown from '../../../../components/FluentDropdown'
import { CreateWoningverbeteringCommand, WoningverbeteringDto, zodiosHooks } from '../../../../api/ApiClient'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

type WoningverbeteringDtoType = z.infer<typeof WoningverbeteringDto>;
type CreateWoningverbeteringCommandType = z.infer<typeof CreateWoningverbeteringCommand>;

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  fetchWoningverbeteringItems: () => void;
  selectedWoningverbeteringItem: WoningverbeteringDtoType | undefined
}

export const WoningverbeteringEditPanel: React.FC<IEditPanelProps> = ({
                                                                        isOpen,
                                                                        dismissPanel,
                                                                        selectedWoningverbeteringItem,
                                                                        fetchWoningverbeteringItems
                                                                      }) => {
  const [title, setTitle] = useState('')
  const { isAdmin } = useRoles()
  const [selectedElementElementDetailId, setSelectedElementElementDetailId] = useState<number | undefined>(selectedWoningverbeteringItem?.elementElementDetailId!)
  const [error, setError] = useState<string>()

  const onDataSubmitttedSuccesfully = () => {
    fetchWoningverbeteringItems()
    dismissPanel()
  }

  const { data } = zodiosHooks.useGetElementElementDetailslistitems()

  const {
    mutate: postWoningverbetering,
    isLoading: isPostingWoningverbetering
  } = zodiosHooks.usePostWoningverbetering({}, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const {
    mutate: putWoningverbeteringId,
    isLoading: isUpdatingWoningverbetering
  } = zodiosHooks.usePutWoningverbeteringId(
    { params: { id: selectedWoningverbeteringItem?.woningverbeteringId! } }, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const elementDetailOptions = data?.map((elementDetail) => {
    return {
      key: elementDetail.elementElementDetailId,
      text: elementDetail.omschrijving ?? ''
    }
  }) ?? []

  const defaultSelectedKey = selectedWoningverbeteringItem ?
    elementDetailOptions?.find(option => option.text === selectedWoningverbeteringItem.omschrijving)?.key :
    null

  const { data: inspectietaken } = zodiosHooks.useGetApiInspectietaak({})
  const inspectietaakOpties = inspectietaken?.items?.filter(item => item.elementElementId === selectedElementElementDetailId).map((item) => {
    return {
      key: item.inspectietaakId,
      text: item.taak
    } as IDropdownOption
  })

  const { data: FontIcoonOptions } = zodiosHooks.useGetApiFonticoon()

  const fontIcons = FontIcoonOptions?.items?.filter(item => item.naam !== null)
    .map(item => ({ key: item.fonticoonId, text: item.omschrijving! }))

  const defaultDefectLocatie = {
    woningverbeteringId: selectedWoningverbeteringItem?.woningverbeteringId ?? 0,
    omschrijving: selectedWoningverbeteringItem?.omschrijving ?? '',
    voorziening: selectedWoningverbeteringItem?.voorziening ?? '',
    elementElementDetailId: selectedWoningverbeteringItem?.elementElementDetailId ?? null,
    cartotheekItem: selectedWoningverbeteringItem?.cartotheekItem ?? '',
    inspectietaakId: selectedWoningverbeteringItem?.inspectietaakId ?? null,
    fonticoonId: selectedWoningverbeteringItem?.fonticoonId ?? null,
    fonticoonNaam: selectedWoningverbeteringItem?.fonticoonNaam ?? '',
    inspectietaak: selectedWoningverbeteringItem?.inspectietaak ?? ''
  }

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateWoningverbeteringCommandType>({ mode: 'all' })

  useEffect(() => {
    setTitle(`Woningverbetering wijzigen`)
    if (selectedWoningverbeteringItem) {
      reset(selectedWoningverbeteringItem)
    } else {
      reset({})
    }
    setSelectedElementElementDetailId(selectedWoningverbeteringItem?.elementElementDetailId!)
  }, [selectedWoningverbeteringItem])

  const onCancel = () => {
    dismissPanel()
  }

  const onSubmit = (data: CreateWoningverbeteringCommandType) => {
    if (!data.fonticoonId) {
      data.fonticoonId = null
    }
    if (selectedWoningverbeteringItem) {
      data.woningverbeteringId = selectedWoningverbeteringItem.woningverbeteringId
      putWoningverbeteringId({ ...defaultDefectLocatie, ...data })
    } else {
      postWoningverbetering({ ...defaultDefectLocatie, ...data })
    }
  }

  return (
    <div>
      <Panel type={PanelType.large} headerText={title} isOpen={isOpen} onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <ErrorMessageBar error={error} />
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField
            label='Omschrijving'
            name={getPropertyName<CreateWoningverbeteringCommandType>('omschrijving')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          />
          <VgtTextField
            label='Voorziening'
            name={getPropertyName<CreateWoningverbeteringCommandType>('voorziening')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          />
          <VgtDropdown
            label='Cartotheek-item'
            name={'elementElementDetailId'}
            register={register}
            control={control}
            errors={errors}
            options={elementDetailOptions}
            defaultSelectedKey={defaultSelectedKey!}
            readOnly={!isAdmin}
            onChange={e => setSelectedElementElementDetailId(e as number)}
          />
          <VgtDropdown
            label='Inspectietaak'
            name={'inspectietaakId'}
            register={register}
            control={control}
            errors={errors}
            options={inspectietaakOpties}
            defaultSelectedKey={defaultSelectedKey!}
            readOnly={!isAdmin}
            disabled={selectedElementElementDetailId === undefined}
          />
          <VgtDropdown
            options={fontIcons}
            label='Fonticoon'
            name={'fonticoonId'}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isAdmin}
          />
          <OkCancelButtonStack
            isSubmitting={isSubmitting}
            isLoading={isPostingWoningverbetering || isUpdatingWoningverbetering}
            onOkClick={handleSubmit(onSubmit)}
            onCancelClick={onCancel}
            isWriter={isAdmin}
          />
        </form>
      </Panel>
    </div>
  )
}
export default WoningverbeteringEditPanel