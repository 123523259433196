import { IconButton, IDatePickerStyles, IStyle, mergeStyles, Stack, StackItem } from '@fluentui/react'
import React, { FC } from 'react'
import FluentDatePicker from '../FluentDatePicker'

export interface IHorizontalDatePickerProps {
  label: string
  onChange: (...event: any[]) => void
  value: Date | undefined
  onClear?: () => void
  height?: number
}

const dateToISOButLocal = (date: Date) => {
  return date.toLocaleString('sv').replace(' ', 'T')
}

export const convertToDateString = (date: any) => {
  const castDate = date as Date
  return dateToISOButLocal(castDate)
}

export const parseDate = (strDate: string | undefined) => {
  if (!strDate)
    return undefined
  return new Date(strDate)
}

const createStackClassName = (height?: number) => {
  const stackStyle = {
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: 'rgb(219, 219, 219)',
    paddingLeft: 12,
    '& .datepicker-label': {
      paddingTop: 7,
      borderBottomStyle: 'initial',
      width: 208
    },
    ':hover': {
      borderBottomColor: 'black'
    },
    '& .ms-DatePicker': {
      borderBottomStyle: 'initial'
    },
    height: height
  } as IStyle
  return mergeStyles(stackStyle)
}

const datePickerStyles: Partial<IDatePickerStyles> = {
  root: {
    padding: '0px 0px 0px 12px',
    display: 'flex'
  },
  textField: {
    label: {
      fontWeight: 'Normal',
      flexGrow: '0',
      flexShrink: '0'
    },
    height: 32,
    width: 150
  }
}

const HorizonalDatePicker: FC<IHorizontalDatePickerProps> = (props: IHorizontalDatePickerProps) => {
  const classStack = createStackClassName(props.height)
  return (
    <Stack horizontal className={classStack}>
      <StackItem className='datepicker-label'>
        <label>{props.label}</label>
      </StackItem>
      <StackItem className='datepicker-control'>
        <FluentDatePicker value={props.value} onSelectDate={props.onChange} datePickerStyles={datePickerStyles}
                          className='' />
      </StackItem>
      {props.onClear && (
        <StackItem>
          <IconButton aria-label='Wis de einddatum' iconProps={{ iconName: 'Cancel' }} onClick={props.onClear} />
        </StackItem>
      )}
    </Stack>
  )
}

export default HorizonalDatePicker
