import React, { FunctionComponent } from 'react'
import Toolbar from './containers/Toolbar'
import GenericError from 'containers/GenericError'
import { ErrorBoundary } from 'react-error-boundary'
import { ZodError } from 'zod'
import NavigationBar from './routing/NavigationBar'

type MainProps = {
  children: JSX.Element | JSX.Element[]
}

const Main: FunctionComponent<MainProps> = props => {
  return (
    <ErrorBoundary FallbackComponent={MainError}>
    <div id="grid" className="ms-Grid" style={{ flexDirection: 'column', padding: 0 }}>

      <div className='ms-Grid-row' style={{ height: '48px', overflow: 'hidden', width: '100%', margin: 0 }}>
        <div className="ms-Grid-col ms-sm12" style={{ padding: 0 }}>
          <Toolbar />
        </div>
      </div>

      <div className='ms-Grid-row' style={{ overflow: 'hidden', margin: 0 }}>
        <div className="ms-Grid-col ms-sm12" style={{ padding: 0 }}>
          <GenericError />
        </div>
      </div>

      <div className='ms-Grid-row' style={{ display: 'flex', flex: 1, overflow: 'hidden', maxWidth: '100%', margin: 0, maxHeight: 'calc(100vh - 50px)' }}>
        <div style={{ padding: 0 }}>
          <NavigationBar />
        </div>
        <div id="content" style={{ flex: 1, padding: 0, overflow: 'hidden' }} >{props.children}</div>
      </div>

    </div>
    </ErrorBoundary>
  )
}

export default Main

const MainError = ({ error }) => {
  console.log(error)
  const zodError = error as ZodError;
  const errorMessage = (error instanceof ZodError)
    ? zodError.issues?.map(issue => `${issue.path[0]}: ${issue.message}`).join(",")
    : error?.message || "Er is die we niet hebben voorzien. De fout is gelogd en we zullen het zo snel mogelijk oplossen. U kunt terugkeren naar de home pagina.";

  return <div>{errorMessage}</div>
}
