import React, { useEffect, useState } from 'react'
import useRoles from 'services/UseRoles'
import { useForm } from 'react-hook-form'
import { Panel, PanelType } from '@fluentui/react'
import VgtTextField from 'components/VgtTextField'
import { getPropertyName } from 'lib/interfaceUtils'
import { MateriaalSoortDto, zodiosHooks } from '../../../../api/ApiClient'
import VgtDropdown from '../../../../components/FluentDropdown'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

type MateriaalSoortDtoType = z.infer<typeof MateriaalSoortDto>;

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  fetchmateriaalSoortenpageItems: () => void
  selectedMateriaalSoortenPageItem: MateriaalSoortDtoType | undefined
}

const EditPanelMateriaalSoort: React.FC<IEditPanelProps> = ({
                                                              isOpen,
                                                              selectedMateriaalSoortenPageItem,
                                                              fetchmateriaalSoortenpageItems,
                                                              dismissPanel
                                                            }) => {
  const [title, setTitle] = useState('Materiaalsoort')
  const { isAdmin } = useRoles()
  const [error, setError] = React.useState<string>()

  const onDataSubmitttedSuccesfully = () => {
    fetchmateriaalSoortenpageItems()
    dismissPanel()
  }

  const {
    mutate: postMateriaalSoorten,
    isLoading: isPostingMateriaalSoorten
  } = zodiosHooks.usePostMateriaalSoorten({}, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: putMateriaalSoortenId, isLoading: isUpdatingMateriaalSoorten } = zodiosHooks.usePutMateriaalSoortenId(
    { params: { id: selectedMateriaalSoortenPageItem?.id?.toString()! } }, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { data: FontIcoonOptions } = zodiosHooks.useGetApiFonticoon()

  const fontIcons = FontIcoonOptions?.items?.filter(item => item.naam !== null)
    .map(item => ({ key: item.fonticoonId, text: item.omschrijving! }))

  const defaultMateriaalSoorten = {
    id: selectedMateriaalSoortenPageItem?.id ?? 0,
    naam: selectedMateriaalSoortenPageItem?.naam ?? '',
    eindDatum: selectedMateriaalSoortenPageItem?.eindDatum ?? null,
    fonticoonId: selectedMateriaalSoortenPageItem?.fonticoonId ?? null,
    fonticoonNaam: selectedMateriaalSoortenPageItem?.fonticoonNaam ?? null
  }

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<MateriaalSoortDtoType>({ mode: 'all' })

  useEffect(() => {
    setTitle(`Materiaalsoort ${selectedMateriaalSoortenPageItem ? (isAdmin ? 'wijzigen' : '') : 'toevoegen'}`)
    if (selectedMateriaalSoortenPageItem) {
      reset(selectedMateriaalSoortenPageItem)
    } else {
      reset({})
    }
  }, [selectedMateriaalSoortenPageItem])

  const onCancel = () => {
    dismissPanel()
  }

  const onSubmit = (data: MateriaalSoortDtoType) => {

    if (!data.fonticoonId) {
      data.fonticoonId = null
    }

    if (selectedMateriaalSoortenPageItem) {
      data.id = selectedMateriaalSoortenPageItem.id
      putMateriaalSoortenId({ ...defaultMateriaalSoorten, ...data })
    } else {
      postMateriaalSoorten({ ...defaultMateriaalSoorten, ...data })
    }
  }

  return (
    <div>
      <Panel type={PanelType.medium} headerText={title} isOpen={isOpen} onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <ErrorMessageBar error={error} />
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField
            label='Materiaalsoort'
            name={getPropertyName<MateriaalSoortDtoType>('naam')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isAdmin}
          />
          <VgtDropdown
            options={fontIcons}
            label='Fonticoon'
            name={getPropertyName<MateriaalSoortDtoType>('fonticoonId')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isAdmin}
          />
          <OkCancelButtonStack
            isSubmitting={isSubmitting}
            isLoading={isPostingMateriaalSoorten || isUpdatingMateriaalSoorten}
            onOkClick={handleSubmit(onSubmit)}
            onCancelClick={onCancel}
            isWriter={isAdmin}
          />
        </form>
      </Panel>
    </div>
  )
}

export default EditPanelMateriaalSoort
