import React, { useMemo, useState } from 'react'
import { IColumn, Link, SelectionMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import FluentTable from 'components/FluentTable'
import { getPropertyName } from 'lib/interfaceUtils'
import EditPanelDefectLocatie from './editPanel'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'
import ICoMoonFontIcon from '../../../../components/IcoMoon'
import { DefectLocatieDto, zodiosHooks } from '../../../../api/ApiClient'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

type DefectLocatieDtoType = z.infer<typeof DefectLocatieDto>;

const DefectLocatieList: React.FC = _props => {
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const [error, setError] = useState<string>()
  const [selectedDefectLocatie, setSelectedDefectLocatie] = useState<DefectLocatieDtoType | undefined>()
  const { isAdmin } = useRoles()

  const { data: defectLocatieItems, invalidate: fetchDefectLocatieItems, isLoading: isFetchingDefectLocatieItems } = zodiosHooks.useGetDefectLocatie({}, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: deleteDefectLocatie } = zodiosHooks.useDeleteDefectLocatie({}, {
    onSuccess: () => fetchDefectLocatieItems(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const handleListSelectionChange = (ids: string[]) => setSelectedItems(ids)
  const refreshItems = () => fetchDefectLocatieItems()
  const onGetKey = (item: DefectLocatieDtoType) => item.id.toString()

  const handleItemInvoked = (id: string) => {
    setSelectedDefectLocatie(defectLocatieItems?.items?.find(item => item.id === parseInt(id)))
    openPanel()
  }

  const removeItems = () => {
    deleteDefectLocatie(selectedItems.map(value => parseInt(value)))
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<DefectLocatieDtoType>('code'),
      name: 'Code',
      fieldName: getPropertyName<DefectLocatieDtoType>('code'),
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      isRowHeader: true,
      data: 'number',
      onRender: (item: DefectLocatieDtoType) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.code}
        </Link>
      )
    },
    {
      key: getPropertyName<DefectLocatieDtoType>('naam'),
      name: 'Naam',
      fieldName: getPropertyName<DefectLocatieDtoType>('naam'),
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<DefectLocatieDtoType>('veraVersie'),
      name: 'VERA Versie',
      fieldName: getPropertyName<DefectLocatieDtoType>('veraVersie'),
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      data: 'number'
    },
    {
      key: 'fonts-icon',
      name: 'Fonticoon',
      fieldName: getPropertyName<DefectLocatieDtoType>('fonticoonId'),
      minWidth: 70,
      maxWidth: 70,
      isResizable: false,
      isRowHeader: false,
      onRender: (item: DefectLocatieDtoType) => <ICoMoonFontIcon iconName={item?.fonticoonNaam!} />
    }
  ]

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
      {
        visible: isAdmin,
        text: 'Toevoegen',
        onClick: () => {
          setSelectedDefectLocatie(undefined)
          openPanel()
        },
        icon: 'Add'
      },
      {
        visible: isAdmin,
        text: 'Verwijderen',
        onClick: () => removeItems(),
        icon: 'Delete',
        disabled: !selectedItems.length
      }
    ],
    [isAdmin, selectedItems.length]
  )

  return (
    <MainTemplate title='Defectlocaties' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <EditPanelDefectLocatie selectedDefectLocatie={selectedDefectLocatie}
                              fetchDefectLocatieItems={fetchDefectLocatieItems} dismissPanel={dismissPanel}
                              isOpen={isPanelOpen} />
      <div style={{ height: '90vh' }}>
        <FluentTable
          columns={columns}
          items={defectLocatieItems?.items ?? []}
          loading={isFetchingDefectLocatieItems}
          selectionMode={SelectionMode.multiple}
          onSelectionChanged={handleListSelectionChange}
          onGetKey={onGetKey}
          onItemInvoked={handleItemInvoked}
        />
      </div>
    </MainTemplate>
  )
}
export default DefectLocatieList
