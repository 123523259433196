import React, { useEffect } from 'react'
import {
  DetailsList,
  Dropdown,
  IColumn,
  IDetailsColumnStyles,
  IDropdownOption,
  ISearchBox,
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SearchBox,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react'
import { debounce } from 'lodash'
import { geoRegisterSuggestForType, ISuggestResult } from 'services/georegisterApi'
import { useNavigate } from 'react-router'
import { PageIdDefinition, PAGES } from 'pages'
import { getPropertyName } from 'lib/interfaceUtils'
import { useDispatch } from 'react-redux'
import { selectAll, setAll, setSearchParameters, selectSearchParameters, IBasisregistratiesSearchParameters } from './basisregistratiesSlice'
import { useTypedSelector } from 'store'
import TitleTemplate from 'containers/pages/PageTemplates/TitleTemplate'

const cellStyles: Partial<IDetailsColumnStyles> = {
  root: {
    fontSize: '14px',
    fontWeight: 600,
  },
}
const options: IDropdownOption[] = [
  { key: 'adres', text: 'Adres' },
  { key: 'appartementsrecht', text: 'Appartementsrecht' },
  { key: 'woonplaats', text: 'Woonplaats' },
  { key: 'buurt', text: 'Buurt' },
  { key: 'wijk', text: 'Wijk' },
  { key: 'weg', text: 'Web' },
  { key: 'postcode', text: 'Postcode' },
  { key: 'perceel', text: 'Perceel' },
  { key: 'gemeente', text: 'Gemeente' },
  { key: 'provincie', text: 'Provincie' },
  { key: '*', text: 'Alles' },
]

const BasisRegistratiesSearch: React.FC<{}> = () => {
  const searchRef = React.createRef<ISearchBox>()
  const dispatch = useDispatch()
  const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>()
  const [queryState, setQueryState] = React.useState<'loading' | 'idle' | 'error'>('idle')
  const [maxNumberOfResults, setMaxNumberOfResults] = React.useState('20')
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [currentSearchValue, setCurrentSearchValue] = React.useState<string | undefined>('')
  const searchResults = useTypedSelector(state => selectAll(state))
  const { maxNumberOfRows, searchValue, searchType } = useTypedSelector(state => selectSearchParameters(state))
  const navigate = useNavigate()

  const onChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    setSelectedItem(item)
    searchRef.current?.focus()
  }

  const delayedSearch = debounce(() => onSearchClicked, 500)

  useEffect(() => {
    setCurrentSearchValue(searchValue)
    setSelectedItem(options[searchType])
    setMaxNumberOfResults(maxNumberOfRows)
  }, [])

  const onSearchBoxChanged = (newValue: string | undefined) => {
    setCurrentSearchValue(newValue)
    if (newValue && newValue.length >= 2) {
      delayedSearch()
    }
  }

  const onClearSearchBox = () => {
    setCurrentSearchValue('')
  }

  const onSearchClicked = () => {
    if (currentSearchValue) {
      setQueryState('loading')
      dispatch(
        setSearchParameters({
          searchType: selectedItem?.key.toString() ?? '*',
          searchValue: currentSearchValue,
          maxNumberOfRows: maxNumberOfResults,
        } as IBasisregistratiesSearchParameters)
      )
      geoRegisterSuggestForType(selectedItem?.key.toString() ?? '*', currentSearchValue, maxNumberOfResults)
        .then(suggestData => {
          setQueryState('idle')
          dispatch(setAll(suggestData.response.docs))
        })
        .catch(reason => {
          setQueryState('error')
          setErrorMessage(reason)
        })
    }
  }

  const onItemInvoked = (item: ISuggestResult) => {
    navigate(PAGES[PageIdDefinition.basisregistraties.details].route.replace(':id', item.id))
  }

  const createColumn = (title: string, field: string) => {
    return { name: title, fieldName: field, key: field, minWidth: 50, maxWidth: 250, isResizable: true, styles: { cellStyles } } as IColumn
  }

  return (
    <>
      <TitleTemplate title='Basisregistraties' subTitle='Overzicht'/>
      <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'start'} style={{ margin: 20, marginTop: 20 }}>
        <StackItem>
          <Dropdown
            selectedKey={selectedItem ? selectedItem.key : undefined}
            onChange={onChange}
            placeholder="zoeken op"
            defaultSelectedKey="*"
            options={options}
            styles={{ dropdown: { width: 175 } }}
          />{' '}
        </StackItem>
        <StackItem grow={2}>
          <SearchBox
            placeholder="Voer een zoekterm in"
            onSearch={onSearchClicked}
            componentRef={searchRef}
            onChanged={onSearchBoxChanged}
            onClear={onClearSearchBox}
            value={currentSearchValue}
            autoComplete="off"
          />
        </StackItem>
        <StackItem>
          <TextField value={maxNumberOfResults} onChange={(event, newValue) => setMaxNumberOfResults(newValue ?? '20')}></TextField>
        </StackItem>
        <StackItem>
          <PrimaryButton text="Zoeken" onClick={onSearchClicked} />
        </StackItem>
      </Stack>
      {queryState === 'error' && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={() => setQueryState('idle')} dismissButtonAriaLabel="Close">
          Er is een fout opgetreden tijdens de zoekactie. Reden: {errorMessage}
        </MessageBar>
      )}
      <div style={{ height: '83vh', overflow: 'auto', width: '99%' }} data-is-scrollable="true">
        <DetailsList
          items={searchResults}
          selectionMode={0}
          onItemInvoked={onItemInvoked}
          columns={[
            {
              key: getPropertyName<ISuggestResult>('weergavenaam'),
              name: 'Naam',
              fieldName: getPropertyName<ISuggestResult>('weergavenaam'),
              minWidth: 200,
              maxWidth: 300,
              isResizable: true,
              isRowHeader: true,
              data: 'string',
              onRender: (item: ISuggestResult) => (
                <Link key={item.id} onClick={() => onItemInvoked(item)}>
                  {item.weergavenaam}
                </Link>
              ),
            },
            createColumn('Type', 'type'),
            createColumn('Score', 'score'),
          ]}
        ></DetailsList>
      </div>
    </>
  )
}

export default BasisRegistratiesSearch
