import { PagedEntities } from 'interfaces/pagedEntities';
import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, EntityState } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState';

export interface IWoz {
  wozObjectnummer: string,
  peildatum: Date,
  wozWaarde: number,
  wozOzbAanslag: number,
  vastgoedobjectsoortID: string,
  vastgoedobjectsoort: string,
  wozEigendomGebruik: string,
  locatiebeschrijving: string,
  verblijfsobject: string,
  vgeId: number,
  wozDeelobjecten: IWozDeelObject[] | undefined
  wozHistorie: IWoz[] | undefined
}

export interface IWozDeelObject{
  bagPand: string,
  wozDeelobjectNummer: string,
  wozObjectnummer: string,
  wozDeelobjectOnderdeelCode: string,
  wozDeelobjectOnderdeelOmschrijving: string,
  wozDeelobjectGebruiksoppervlakte: string,
  wozDeelobjectHistorie: IWozDeelObject[] | undefined
}

const entityAdapter = createEntityAdapter<IWoz>({
  selectId: w => (w.wozObjectnummer),
  sortComparer: (a, b) => a.wozObjectnummer?.localeCompare(b.wozObjectnummer)
})

const baseUrl = '/Woz'
const basePrefix = 'Woz'
const getSliceState = (state: RootState) => state.woz

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
  const woz = await invokeFetch<PagedEntities<IWoz>>(thunkAPI, 'GET', `${baseUrl}?top=50000`);
  return woz;
})

const setPendingState = (state: EntityState<IWoz> & IEntityAdapterState) => {
  state.error = null
  state.status = 'pending'
}
const setSucceededState = (state: EntityState<IWoz> & IEntityAdapterState) => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<IWoz> & IEntityAdapterState, action) => {
  state.status = 'failed'
  state.error = action.error.message || null
}


export const woz = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
    clearError: state => {
      state.error = null
    },
    select: (state, action: PayloadAction<string | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
  },

  extraReducers: builder => {
    builder.addCase(fetchAll.pending, state => setPendingState(state))
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const getSelectedEntity = (state: RootState) => {
  const selectedId = getSliceState(state).selectedId
  if (selectedId) return getSliceState(state).entities[selectedId]
  else return undefined
}

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}

export const getErrorState = (state: RootState) => {
  return getSliceState(state).error
}

export const { add, modify, removeMany, select, clearSelection } = woz.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.woz)

export default woz.reducer
