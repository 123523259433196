import { Panel, PanelType } from '@fluentui/react'
import VgtTextField from 'components/VgtTextField'
import { getPropertyName } from 'lib/interfaceUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
  addDefectoorzaak,
  defectoorzaakSchema,
  getLoadingState,
  getSelectedEntity,
  IDefectoorzaak,
  updateDefectoorzaak
} from './defectoorzaakSlice'
import React, { useEffect, useState } from 'react'
import useRoles from 'services/UseRoles'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  fetchdefectoorzaakItems: () => void;
}

export const DefectoorzaakEditPanel: React.FC<IEditPanelProps> = props => {
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState('')
  const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
  const fetchStatus = useSelector(getLoadingState)
  const selectedEntity = useSelector(getSelectedEntity)
  const { isAdmin } = useRoles()

  useEffect(() => {
    setCloseOnFulFilled(false)
  }, [])

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<IDefectoorzaak>({ resolver: yupResolver(defectoorzaakSchema), mode: 'all' })

  useEffect(() => {
    setTitle(`Defectoorzak ${selectedEntity ? isAdmin ? 'wijzigen' : '' : 'toevoegen'}`)
    if (selectedEntity) {
      reset(selectedEntity)
    } else {
      reset({})
    }
  }, [selectedEntity])

  const onCancel = () => {
    props.dismissPanel()
  }

  const onSubmit = (data: IDefectoorzaak) => {
    if (selectedEntity) {
      data.defectoorzaakId = selectedEntity.defectoorzaakId
      dispatch(updateDefectoorzaak(data))
      setCloseOnFulFilled(true)
    } else {
      dispatch(addDefectoorzaak(data))
      setCloseOnFulFilled(true)
    }
  }

  useEffect(() => {
    if (closeOnFulFilled && fetchStatus === 'succeeded') {
      props.fetchdefectoorzaakItems()
      props.dismissPanel()
    }
  }, [fetchStatus])

  return (
    <div>
      <Panel type={PanelType.large} headerText={title} isOpen={props.isOpen} onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField label='Code' name={getPropertyName<IDefectoorzaak>('code')} register={register}
                        control={control} errors={errors} required={isAdmin}
                        readOnly={!isAdmin} />
          <VgtTextField label='Naam' name={getPropertyName<IDefectoorzaak>('naam')} register={register}
                        control={control} errors={errors} required={isAdmin} multiline autoAdjustHeight
                        readOnly={!isAdmin} />
          <VgtTextField label='Omschrijving' name={getPropertyName<IDefectoorzaak>('omschrijving')} register={register}
                        control={control} errors={errors} required={isAdmin} autoAdjustHeight
                        readOnly={!isAdmin} />
          <OkCancelButtonStack
            isSubmitting={isSubmitting}
            isLoading={isSubmitting}
            onOkClick={handleSubmit(onSubmit)}
            onCancelClick={onCancel}
            isWriter={isAdmin}
          />
        </form>
      </Panel>
    </div>
  )
}