import { getPropertyName } from 'lib/interfaceUtils'
import React, { useMemo, useState } from 'react'
import useRoles from 'services/UseRoles'
import { DefectSoortListDto, zodiosHooks } from '../../../../api/ApiClient'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import MainTemplate from '../../../../containers/pages/PageTemplates/MainTemplate'
import { usePagedParams } from '../../../../services/usePagedParams'
import { z } from 'zod'
import { useNavigate } from 'react-router-dom'
import EditPanelDefectSoort from './editPanel'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { useBoolean } from '@fluentui/react-hooks'
import { IColumn, Link } from '@fluentui/react'
import { transformDateString } from '../common/support'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'

type DefectSoortListDtoType = z.infer<typeof DefectSoortListDto>

const DefectSoortList: React.FC<{}> = () => {
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const { isAdmin } = useRoles()
  const { validatedSearchParams, setFilter, setOrder, setPage } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [selectedDefectSoort, setSelectedDefectSoort] = useState<DefectSoortListDtoType | undefined>()
  const [error, setError] = useState<string>()

  const {
    data: defectSoortItems,
    invalidate: fetchDefectSoortItems,
    isLoading: isFetchingDefectSoort
  } = zodiosHooks.useGetApiDefectSoort({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: deleteApiDefectSoort } = zodiosHooks.useDeleteApiDefectSoort({}, {
    onSuccess: () => fetchDefectSoortItems(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const handleItemInvoked = (item: DefectSoortListDtoType) => {
    setSelectedDefectSoort(item)
    openPanel()
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<DefectSoortListDtoType>('code'),
      name: 'Code',
      fieldName: getPropertyName<DefectSoortListDtoType>('code'),
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      data: 'number',
      onRender: (item: DefectSoortListDtoType) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item)}>
          {item.code}
        </Link>
      )
    },
    {
      key: getPropertyName<DefectSoortListDtoType>('naam'),
      name: 'Naam',
      fieldName: getPropertyName<DefectSoortListDtoType>('naam'),
      minWidth: 150,
      maxWidth: 400,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: 'omschrijving',
      name: 'Omschrijving',
      fieldName: getPropertyName<DefectSoortListDtoType>('omschrijving'),
      minWidth: 150,
      maxWidth: 400,
      isResizable: false,
      isRowHeader: false
    },
    {
      key: getPropertyName<DefectSoortListDtoType>('veraVersie'),
      name: 'VERA Versie',
      fieldName: getPropertyName<DefectSoortListDtoType>('veraVersie'),
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      data: 'boolean'
    },
    {
      key: getPropertyName<DefectSoortListDtoType>('ingangsDatum'),
      name: 'Ingangsdatum',
      fieldName: getPropertyName<DefectSoortListDtoType>('ingangsDatum'),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: DefectSoortListDtoType) => <>{transformDateString(item.ingangsDatum!)}</>
    },
    {
      key: getPropertyName<DefectSoortListDtoType>('eindDatum'),
      name: 'Einddatum',
      fieldName: getPropertyName<DefectSoortListDtoType>('eindDatum'),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: DefectSoortListDtoType) => <>{transformDateString(item.eindDatum!)}</>
    }
  ]

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => fetchDefectSoortItems(), icon: 'Refresh' },
    {
      visible: isAdmin, text: 'Toevoegen', onClick: () => {
        setSelectedDefectSoort(undefined)
        openPanel()
      }, icon: 'Add'
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        deleteApiDefectSoort({ itemsToDelete: selectedIds })
      },
      icon: 'Delete',
      iconOnly: true,
      disabled: selectedIds.length === 0
    }
  ], [isAdmin, selectedIds.length])


  return (
    <MainTemplate title='Defectsoorten' subTitle='Overzicht' commandItems={commandItems}
                  error={error}>
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <EditPanelDefectSoort selectedDefectSoort={selectedDefectSoort}
                            fetchDefectSoortItems={fetchDefectSoortItems} dismissPanel={dismissPanel}
                            isOpen={isPanelOpen} />
      <VgtPagedTable
        items={defectSoortItems?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: defectSoortItems?.totalCount ?? 0,
          totalPages: defectSoortItems?.totalPages ?? 0,
          hasNextPage: defectSoortItems?.hasNextPage || false,
          hasPreviousPage: defectSoortItems?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        columns={columns}
        isLoading={isFetchingDefectSoort}
        getKey={(item) => item?.id}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={handleItemInvoked}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}

export default DefectSoortList

