import { useNavigate, useParams } from 'react-router-dom'
import { EditParams } from '../../vhe/VheEdit'
import React, { useState } from 'react'
import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import { zodiosHooks } from '../../../api/ApiClient'
import { getTitleAndMessage } from '../../../services/HandleError'
import { HuurcontractbepalingForm } from './HuurcontractbepalingForm'
import { Pivot, PivotItem } from '@fluentui/react'
import { HuurcontractbepalingGebouwclusterList } from './HuurcontractbepalingGebouwclusterList'
import { HuurcontractbepalingGebouwdeelList } from './HuurcontractbepalingGebouwdeelList'
import { HuurcontractbepalingGebouwList } from './HuurcontractbepalingGebouwList'
import { HuurcontractbepalingVgeList } from './HuurcontractbepalingVgeList'


export const HuurcontractbepalingEdit = () => {
  const navigate = useNavigate()
  const params = useParams()
  const validatedParams = EditParams.parse(params)
  const [error, setError] = useState<string>()
  const [gebouwClusterCount, setGebouwClusterCount] = useState(0);
  const [gebouwCount, setGebouwCount] = useState(0);
  const [gebouwDeelCount, setGebouwDeelCount] = useState(0);
  const [vgeCount, setVgeCount] = useState(0);
  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true }
  ]

  const {
    data,
    isLoading,
    invalidate
  } = zodiosHooks.useGetApiHuurcontractbepalingId({ params: { id: validatedParams.id } }, { enabled: validatedParams.id > 0 })

  const { mutate: updateHuurcontractbepaling } = zodiosHooks.usePutApiHuurcontractbepalingId(
    { params: { id: validatedParams.id } }, {
      onSuccess: () => invalidate().then(() => navigate(-1)),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const setComponentError = (error: string) => setError(error)

  return (
    <MainTemplate title="Huurcontractbepaling" subTitle="Bewerken" commandItems={commandItems} error={error}>
      <HuurcontractbepalingForm huurcontractbepalingId={validatedParams.id} data={data} isLoading={isLoading}
                                isSubmitting={isLoading}
                                onSubmit={updateHuurcontractbepaling} onCancel={() => navigate(-1)}
                                onError={setComponentError} />
      <Pivot style={{ paddingTop: '16px' }}>
        <PivotItem key="huurcontractbepalingGebouwcluster" headerText="Gebouwencluster" itemCount={gebouwClusterCount}>
          <HuurcontractbepalingGebouwclusterList setCount={setGebouwClusterCount} />
        </PivotItem>
        <PivotItem key="huurcontractbepalingGebouw" headerText="Gebouwen" itemCount={gebouwCount}>
          <HuurcontractbepalingGebouwList  setCount={setGebouwCount}/>
        </PivotItem>
        <PivotItem key="huurcontractbepalingGebouwdelen" headerText="Gebouwdelen" itemCount={gebouwDeelCount}>
          <HuurcontractbepalingGebouwdeelList  setCount={setGebouwDeelCount}/>
        </PivotItem>
        <PivotItem key="huurcontractbepalingVge" headerText="Vastgoedeenheden" itemCount={vgeCount}>
          <HuurcontractbepalingVgeList  setCount={setVgeCount}/>
        </PivotItem>
      </Pivot>
    </MainTemplate>
  )
}