import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { setRejectedState, setSucceededState, setPendingState } from 'lib/sliceSupport'
import { entityAdapterInitState } from 'interfaces/entityAdapterState'
import { IWWS } from './entities'

const entityAdapter = createEntityAdapter<IWWS>()

const basePrefix = 'gebouwWws'

export const fetchVoorzieningen = createAsyncThunk(`${basePrefix}/fetchById`, async (gebouwId: number, thunkAPI) => {
  return await invokeFetch<IWWS>(thunkAPI, 'GET', `/gebouwen/${gebouwId}/wws`)
})

export const voorzieningenSlice = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),
  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchVoorzieningen.pending, state => setPendingState(state))
    builder.addCase(fetchVoorzieningen.fulfilled, (state, action: PayloadAction<IWWS>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(fetchVoorzieningen.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const { selectById } = entityAdapter.getSelectors<RootState>(state => state.gebouwWWS)

export default voorzieningenSlice.reducer
