import React from "react";
import {Icon, Stack, StackItem, Text} from "@fluentui/react";

interface IExplainCardProps {
  type: 'Info' | 'Warning' | 'Error'
  children: React.ReactNode
  headerText: string
}

export const ExplainCard = (props: IExplainCardProps) => {
  return (
    <>
      <Stack horizontal style={{
        width: 'calc(100% - 24px)',
        padding: '6px',                
        marginBottom: '10px',
        marginLeft: '12px'
      }}>
        <StackItem>
          {
            props.type === 'Info' ? <Icon iconName="Info" style={{
              color: '#42E242',
              fontSize: '30px'
            }}/> : props.type === 'Warning' ?
              <Icon iconName="Warning" style={{color: 'orange', fontSize: '30px'}}/> :
              <Icon iconName="Error" style={{color: 'red', fontSize: '30px'}}/>
          }
        </StackItem>
        <StackItem style={{paddingLeft: '10px', paddingTop: '6px'}}>
          <Text variant="mediumPlus">{props.headerText}</Text>
        </StackItem>
      </Stack>
      <Stack style={{
        width: 'calc(100% - 24px)',
        padding: '6px',                
        marginBottom: '10px',
        marginLeft: '12px'        
      }} tokens={{childrenGap: 10}}>
        {props.children}
      </Stack>
    </>
  )
}