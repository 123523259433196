import ListContracten from "features/beheer/onderhoudscontract/listContracten"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchVgeContracten, selectAll,  } from "./vgeContractSlice"

export interface IVgeOnderhoudsContractenProps{
    vgeId: number
}

const VgeOnderhoudsContracten : React.FC<IVgeOnderhoudsContractenProps> = (props: IVgeOnderhoudsContractenProps) => {
    const dispatch = useDispatch()
    const contracten = useSelector(selectAll)

    useEffect(() => {
        dispatch(fetchVgeContracten(props.vgeId))    

    }, [props.vgeId])
    return <ListContracten contracten={contracten} />
}

export default VgeOnderhoudsContracten
