import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, EntityState, EntityId } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import { IVeraEntity  } from '../common/lemdoEntity'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'
import * as yup from 'yup'
import { IDropdownOption } from '@fluentui/react'


export const terreinClassificatieSchema = yup.object().shape({
    terrein: yup.string().required('Terrein is verplicht').max(150, 'Maximale lengte is 150')
})

export interface ITerreinClassificatie extends IVeraEntity  { 
    id: number,
    terrein: string
}

const entityAdapter = createEntityAdapter<ITerreinClassificatie>({
    sortComparer: (a, b) => a.terrein?.localeCompare(b.terrein),
  })
  
  const baseUrl = '/terreinclassificatie'
  const basePrefix = 'terreinclassificatie'
  const getSliceState = (state: RootState) => state.terreinClassificatie
  
  export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<PagedEntities<ITerreinClassificatie>>(thunkAPI, 'GET', `${baseUrl}`)
  })
  
  export const updateTerreinClassificatie = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: ITerreinClassificatie, thunkAPI) => {
    return await invokeFetch<ITerreinClassificatie>(thunkAPI, 'PUT', `${baseUrl}/${entity.id}`, entity)
  })
  
  export const addTerreinClassificatie = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: ITerreinClassificatie, thunkAPI) => {
    return await invokeFetch<ITerreinClassificatie>(thunkAPI, 'POST', baseUrl, entity)
  })
  
  export const deleteTerreinClassificatie = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
  })
  
  const setPendingState = (state: EntityState<ITerreinClassificatie> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<ITerreinClassificatie> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<ITerreinClassificatie> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }
  
  export const TerreinClassificatieSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),
  
    reducers: {
      clearError: state => {
        state.error = null
      },
      select: (state, action: PayloadAction<string | undefined>) => {
        state.selectedId = action.payload
      },
      clearSelection: state => {
        state.selectedId = undefined
      },
      add: entityAdapter.addOne,
      modify: entityAdapter.upsertOne,
      removeMany: entityAdapter.removeMany,
      setAll: entityAdapter.setAll,
    },
  
    extraReducers: builder => {
      builder.addCase(fetchAll.pending, state => setPendingState(state))
      builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<ITerreinClassificatie>>) => {
        setSucceededState(state)
        entityAdapter.setAll(state, action.payload.items)
      })
      builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(updateTerreinClassificatie.pending, state => setPendingState(state))
      builder.addCase(updateTerreinClassificatie.fulfilled, (state, action: PayloadAction<ITerreinClassificatie>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(updateTerreinClassificatie.rejected, (state, action) => setRejectedState(state, action))
      builder.addCase(addTerreinClassificatie.pending, state => setPendingState(state))
      builder.addCase(addTerreinClassificatie.fulfilled, (state, action: PayloadAction<ITerreinClassificatie>) => {
        entityAdapter.upsertOne(state, action.payload)
        setSucceededState(state)
      })
      builder.addCase(addTerreinClassificatie.rejected, (state, action) => {
        setRejectedState(state, action)
      })
      builder.addCase(deleteTerreinClassificatie.pending, state => setPendingState(state))
      builder.addCase(deleteTerreinClassificatie.fulfilled, (state, action) => {
        entityAdapter.removeMany(state, action.meta.arg)
        setSucceededState(state)
      })
      builder.addCase(deleteTerreinClassificatie.rejected, (state, action) => setRejectedState(state, action))
    },
  })
  
  export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
  }
  
  export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
  }
  
  export const getErrorState = (state: RootState) => {
    return getSliceState(state).error
  }
  
  export const { add, modify, removeMany, select, clearSelection } = TerreinClassificatieSlice.actions
  export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.terreinClassificatie)


  export const getTerreinClassificatieAsOptions = (state: RootState) => state.terreinClassificatie.ids.map((id: EntityId) => {
    const entity = state.terreinClassificatie.entities[id]
    return {
        key: id,
        text: `${entity?.terrein}`
    } as IDropdownOption
})
  
  export default TerreinClassificatieSlice.reducer
