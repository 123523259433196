import { FunctionComponent, useEffect, useState } from 'react'
import Vastgoedtabel from './Vastgoedtabel_Logo_fin_small.jpg'
import { Text, Image, IStackStyles, mergeStyleSets, Pivot, PivotItem, Stack, StackItem } from '@fluentui/react'

import ReactSpeedometer from 'react-d3-speedometer'
import LaatsteVerwerkingen from './laatsteVerwerkingen'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStatistics, IStatistics, selectAll } from './statisticsSlice'

type HomeProps = {}

const classNames = mergeStyleSets({
  homeContent: {
    marginLeft: '25px',
    marginRight: '50px',
    marginTop: '20px',
    height: '100vh',
  },
  verwerkingen: {
    marginTop: 'calc(100vh - 720px)',
    '@media (max-height: 720px)': {
      marginTop: '50px',
    },
  },
  amountCard: {
    border: `1px solid #efefef`,
    boxShadow: '#efefef 0px 0px 9px -1px',
    padding: '5px 14px 24px',
    width: 250,
  }
})

const stackStyles: IStackStyles = {
  root: {
    height: "75vh",
    verticalAlign: "end",
    '@media (max-height: 720px)': {
      overflowY: 'auto'
    },
  },
};

const Home: FunctionComponent<HomeProps> = () => {

  const dispatch = useDispatch()
  const statistics = useSelector(selectAll)
  const [statisticsLine, setStatisticsLine] = useState<IStatistics>({
    totalNumberOfVges: 0, vgesIngedeeld: 0, vgesMetVerhuureenheid: 0, vgesMetKadastraalObject: 0,
  } as IStatistics)

  useEffect(() => {
    dispatch(fetchStatistics())
  }, [])

  useEffect(() => {
    if (statistics?.length > 0) {
      setStatisticsLine(statistics[0])
    }
  }, [statistics])

  return (
    <div>
      <Image maximizeFrame={false} src={Vastgoedtabel} width="400px" />
      <div className={classNames.homeContent}>
        <Stack styles={stackStyles}>
          <StackItem grow={false}>
            <Pivot>
              <PivotItem headerText="Aansluitcontroles">
                <Stack wrap horizontalAlign={'space-evenly'} horizontal tokens={{ padding: 10 }} >
                  <StackItem>
                    <ReactSpeedometer
                      currentValueText={statisticsLine.vgesIngedeeld + " vge's ingedeeld"}
                      maxValue={100}
                      value={statisticsLine.vgesIngedeeld * 100 / statisticsLine.totalNumberOfVges}
                      height={200}
                      needleColor="#A60808"
                      segments={1000}
                      maxSegmentLabels={2}
                      startColor="#CC1919"
                      endColor="#FFCBCB"
                    />
                  </StackItem>
                  <StackItem>
                    <ReactSpeedometer
                      currentValueText={statisticsLine.vgesMetVerhuureenheid + " vge's met verhuureenheden​"}
                      maxValue={100}
                      value={statisticsLine.vgesMetVerhuureenheid * 100 / statisticsLine.totalNumberOfVges}
                      height={200}
                      needleColor="#A60808"
                      segments={1000}
                      maxSegmentLabels={2}
                      startColor="#CC1919"
                      endColor="#FFCBCB"
                    />
                  </StackItem>
                  <StackItem>
                    <ReactSpeedometer
                      currentValueText={statisticsLine.vgesMetKadastraalObject + " vge's met kadastraal object"}
                      maxValue={100}
                      value={statisticsLine.vgesMetKadastraalObject * 100 / statisticsLine.totalNumberOfVges}
                      needleColor="#A60808"
                      height={200}
                      segments={1000}
                      maxSegmentLabels={2}
                      startColor="#CC1919"
                      endColor="#FFCBCB"
                    />
                  </StackItem>
                </Stack>
                <Stack horizontal wrap horizontalAlign={'space-evenly'} >
                  <StackItem>
                    <div className={classNames.amountCard}>
                      <div style={{ textAlign: "center" }}>
                        <Text variant='medium'>
                          <b>Aantal vastgoedeenheden</b>
                        </Text>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Text variant='xLarge'>
                          <b>{statisticsLine.totalNumberOfVges}</b>
                        </Text>
                      </div>
                    </div>
                  </StackItem>
                  <StackItem>
                    <div className={classNames.amountCard}>
                      <div style={{ textAlign: "center" }}>
                        <Text variant='medium'>
                          <b>Aantal verhuureenheden </b>
                        </Text>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Text variant='xLarge'>
                          <b>{statisticsLine.totalNumberOfVhes}</b>
                        </Text>
                      </div>
                    </div>
                  </StackItem>
                  <StackItem>
                    <div className={classNames.amountCard}>
                      <div style={{ textAlign: "center" }}>
                        <Text variant='medium'>
                          <b>Aantal kadastrale objecten</b>
                        </Text>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Text variant='xLarge'>
                          <b>{statisticsLine.totalNumberOfPercelen}</b>
                        </Text>
                      </div>
                    </div>
                  </StackItem>
                </Stack>
              </PivotItem>
            </Pivot>
          </StackItem>
          <StackItem shrink={true} grow={true} style={{ marginBottom: 20}} >
            <div className={classNames.verwerkingen}>
              <LaatsteVerwerkingen verwerkingen={statisticsLine.verwerkingen} />
            </div>
          </StackItem>
        </Stack>
      </div>
    </div >
  )
}

export default Home
