import { useForm } from 'react-hook-form'
import VgtTextField from 'components/VgtTextField'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect } from 'react'
import { DimensiewaardeSchema, IDimensiewaardeUpdateOrAdd } from './DimensiewaardeApi'
import { yupResolver } from '@hookform/resolvers/yup'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

export const DimensiewaardeForm = ({ data, isExecuting, onSubmit, onCancel }: { data?: IDimensiewaardeUpdateOrAdd, isExecuting?: boolean, onSubmit: (values: IDimensiewaardeUpdateOrAdd) => void, onCancel: () => void }) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<IDimensiewaardeUpdateOrAdd>({
    resolver: yupResolver(DimensiewaardeSchema),
    mode: 'all', defaultValues: {
      code: '',
      omschrijving: ''
    }
  })

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VgtTextField label='Code' name={getPropertyName<IDimensiewaardeUpdateOrAdd>('code')} required register={register} control={control} errors={errors} />
      <VgtTextField label='Omschrijving' name={getPropertyName<IDimensiewaardeUpdateOrAdd>('omschrijving')} required register={register} control={control} errors={errors} />
      <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isExecuting ?? false} onOkClick={() => handleSubmit(onSubmit)} onCancelClick={() => onCancel()} />
    </form>
  )
}