import React, { useMemo, useState, useEffect } from 'react'
import { Link, SelectionMode, IColumn } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import FluentTable from 'components/FluentTable'
import { getPropertyName } from 'lib/interfaceUtils'
import { selectAll, select, clearSelection, fetchAll, getLoadingState } from './eenheidSoortSlice'
import IEenheidSoort from './eenheidSoortModel'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from "services/UseRoles";
import { transformDateString } from '../common/support'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { columnsVera } from '../common/columnUtils'

const EenheidSoortList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const items = useSelector(selectAll)
  const loadingState = useSelector(getLoadingState)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const handleListSelectionChange = (ids: string[]) => setSelectedItems(ids)
  const refreshItems = () => dispatch(fetchAll())
  const onGetKey = (item: IEenheidSoort) => item.id.toString()
  const { isAdmin } = useRoles()

  const handleItemInvoked = (id: string) => {
    dispatch(select(id))
  }

  useEffect(() => {
    dispatch(fetchAll())
  }, [])

  const columns: IColumn[] = [
    createCustomRenderedColumn('Code', getPropertyName<IEenheidSoort>('code'),
      (item: IEenheidSoort) => <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>{item.code}</Link>, 'S', false),
    createColumn('Naam', getPropertyName<IEenheidSoort>('naam'), 'L', false),
    createColumn('Omschrijving', getPropertyName<IEenheidSoort>('omschrijving'), 545),
    ...columnsVera,   
    createCustomRenderedColumn('Ingangsdatum', getPropertyName<IEenheidSoort>('ingangsDatum'),
      (item: IEenheidSoort) => <span>{transformDateString(item.ingangsDatum)}</span>, 'M', false),
    createCustomRenderedColumn('Einddatum', getPropertyName<IEenheidSoort>('eindDatum'),
      (item: IEenheidSoort) => <span>{item.eindDatum}</span>, 'M', false)    
  ];

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    { visible: isAdmin, text: 'Toevoegen', onClick: () => { dispatch(clearSelection()); }, icon: 'Add' },
    { visible: isAdmin, text: 'Verwijderen', onClick: () => { }, icon: 'Delete', disabled: !selectedItems.length },
  ], [isAdmin, selectedItems.length])

  return (
    <MainTemplate title="Eenheidsoorten" subTitle="Overzicht" commandItems={commandItems}>      
      <FluentTable
        height='90vh'
        columns={columns}
        items={items}
        loading={loadingState === 'pending'}
        selectionMode={SelectionMode.multiple}
        onSelectionChanged={handleListSelectionChange}
        onGetKey={onGetKey}
        onItemInvoked={handleItemInvoked}
      />
    </MainTemplate>
  )
}
export default EenheidSoortList
