import { z } from 'zod'
import { VerwarmingAddOrUpdateDto, zodiosHooks } from '../../../../api/ApiClient'
import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import VgtTextField from 'components/VgtTextField'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { getTitleAndMessage } from '../../../../services/HandleError'
import VgtDropdown from 'components/FluentDropdown'
import { OkCancelButtonStack } from 'components/OkCancelButtonStack/OkCancelButtonStack'
import FluentPanel from 'components/FluentPanel/FluentPanel'
import { PanelType } from '@fluentui/react/lib/Panel'
import { FieldErrorsToMessage } from 'components/ErrorMessageBar/ErrorMessageBar'

export interface IEditPanelProps {
  id: number,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof VerwarmingAddOrUpdateDto>;

const defaultVerwarmingValues = {
  code: '',
  wijzeVanVerwarmingId: 0,
  leveringVanWarmteId: 0
} as CreateUpdateType

export const VerwarmingFormPanel: React.FC<IEditPanelProps> = props => {

  const [error, setError] = React.useState<string>()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(VerwarmingAddOrUpdateDto), mode: 'all', defaultValues: defaultVerwarmingValues
  })

  const { data: leveringOpties } = zodiosHooks.useGetVerwarmingenleveringOpties()
  const { data: wijzeOpties } = zodiosHooks.useGetVerwarmingenwijzeOpties()

  const { mutate: updateVerwarming, isLoading: isModifying } = zodiosHooks.usePutVerwarmingenId({ params: { id: props.id } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  zodiosHooks.useGetVerwarmingenId({ params: { id: props.id } }, {
      onSuccess: (data) => reset(data),
      enabled: props.id !== 0
    }
  )

  const { mutate: addVerwarming, isLoading: isAdding } = zodiosHooks.usePostVerwarmingen({}, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const onCancel = () => {
    props.invalidate()
    reset({})
    setError(undefined)
    props.dismissPanel()
  }


  const onSubmit = (data: CreateUpdateType) => {
    setError(undefined)
    if (props.id !== 0) {
      updateVerwarming(data)
    } else {
      addVerwarming(data)
    }
  }

  return (
    <FluentPanel type={PanelType.medium} isOpen={props.isOpen} onDismiss={onCancel} headerText={props.id !== 0 ? 'wijzigen' : 'toevoegen'} onDismissed={onCancel} error={error}>
      <VgtTextField label="Code" name={getPropertyName<CreateUpdateType>('code')} control={control}
                    errors={errors} required />
      <VgtDropdown label="Levering van warmte" name={getPropertyName<CreateUpdateType>('leveringVanWarmteId')}
                   control={control} options={leveringOpties} errors={errors}
                   required={true}
      />
      <VgtDropdown label="Wijze van verwarming" name={getPropertyName<CreateUpdateType>('wijzeVanVerwarmingId')}
                   control={control} options={wijzeOpties} errors={errors}
                   required={true}
      />

      <FluentPanel.Footer>
        <OkCancelButtonStack isSubmitting={isModifying || isAdding} isLoading={false} onOkClick={handleSubmit(onSubmit, (errors) => setError(FieldErrorsToMessage(errors)))} onCancelClick={onCancel} />
      </FluentPanel.Footer>
    </FluentPanel>
  )
}