import React from 'react'
import { InteractionType } from '@azure/msal-browser'
import { useMsalAuthentication } from '@azure/msal-react'

// Trigger login
const Login: React.FC = () => {
  useMsalAuthentication(InteractionType.Redirect);

  return <></>
}

export default Login
