import React from 'react'
import { Controller } from 'react-hook-form'
import { IIconProps, ISpinButtonStyles, SpinButton } from '@fluentui/react'

interface IVgtFieldProps {
  max?: number
  min?: number
  name: string
  label: string
  control: any
  errors?: any
  required?: boolean
  readOnly?: boolean
  iconProps?: IIconProps
  defaultValue?: number
  height?: string | number
  labelWidth?: number
  multiline?: boolean
  autoAdjustHeight?: boolean
  disabled?: boolean
}

const VgtNumberField: React.FC<IVgtFieldProps> = props => {
  const labelWidth = props.labelWidth ? props.labelWidth : 215

  const spinButtonStyles: Partial<ISpinButtonStyles> = {
    labelWrapper: {
      width: labelWidth,
      // borderBottom: '1px solid rgb(219 219 219)',
      height: props.height
    },
    input: {
      fontWeight: 600,
      border: '0px solid rgb(219 219 219)',
    },
    label: {
      fontWeight: 400,
      marginLeft: 12,
    },
    spinButtonWrapper: {
      border: 'none',
      '::after': {
        border: 'none'
      },
    },
  }

  const getNumericPart = (value: string | undefined | null): number | undefined => {
    if (value === undefined || value === null || value === '') return undefined

    const valueRegex = /^(\d+(\.\d+)?).*/
    if (valueRegex.test(value)) {
      const numericValue = Number(value.replace(valueRegex, '$1'))
      return isNaN(numericValue) ? undefined : numericValue
    }
    return undefined
  }

  const onValidate = (value: string, event?: React.SyntheticEvent<HTMLElement>) => {
    let numericValue = getNumericPart(value)
    if (numericValue !== undefined) {
      if (props.max !== undefined && numericValue > props.max) {
        numericValue = props.max
      }
      if (props.min !== undefined && numericValue < props.min) {
        numericValue = props.min
      }
      return numericValue.toString()
    }
    return ''
  }

  function myOnchange(onChange, event: React.SyntheticEvent<HTMLElement>, newValue?: string) {
    let numericValue = getNumericPart(newValue)
    return onChange(numericValue)
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ onChange, value }) => (
        <SpinButton
          onChange={(event, newValue) => myOnchange(onChange, event, newValue)}
          label={props.label}
          onValidate={onValidate}
          value={value? value.toString() : ''}
          max={props.max}
          min={props.min}
          step={1}
          onIncrement={(v: string) => {
            let numericValue = getNumericPart(v)
            if (numericValue !== undefined) {
              return (numericValue + 1).toString()
            } else return '1'
          }}
          incrementButtonAriaLabel='Increase value by 1'
          decrementButtonAriaLabel='Decrease value by 1'
          styles={spinButtonStyles}
          disabled={props.disabled}
        />
      )}
    />
  )
}

export default VgtNumberField
