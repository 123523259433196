import { z } from 'zod'
import { ContactgegevenAddOrUpdateDto, zodiosHooks } from '../../../../api/ApiClient'
import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { IDropdownOption, Panel, PanelType} from '@fluentui/react'
import VgtTextField from '../../../../components/VgtTextField'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { getTitleAndMessage } from '../../../../services/HandleError'
import VgtDropdown from '../../../../components/FluentDropdown'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

export interface IEditPanelProps {
  contactgegevenId: number,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof ContactgegevenAddOrUpdateDto>;

export const ContactgegevenFormPanel: React.FC<IEditPanelProps> = props => {

  const [error, setError] = React.useState<string>()

  const defaultContactgegeven = {
    contactCategorieId: -1,
    naam: '',
    emailadres: '',
    telefoonnummer: ''
  } as CreateUpdateType

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(ContactgegevenAddOrUpdateDto), mode: 'all', defaultValues: defaultContactgegeven
  })

  const { data: contactCategorien } = zodiosHooks.useGetContactCategorieen()

  const { mutate: updateContactgegeven, isLoading: isModifying } = zodiosHooks.usePutContactgegevensId({ params: { id: props.contactgegevenId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  zodiosHooks.useGetContactgegevensId({ params: { id: props.contactgegevenId } }, {
      onSuccess: (data) => reset(data),
      enabled: props.contactgegevenId !== 0
    }
  )

  const { mutate: addContactgegeven, isLoading: isAdding } = zodiosHooks.usePostContactgegevens({}, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const contactCategorieOpties = contactCategorien?.items?.map((item) => {
    return { key: item.id, text: item.naam }
  })

  const onCancel = () => {
    props.invalidate()
    reset({})
    setError(undefined)
    props.dismissPanel()
  }


  const onSubmit = (data: CreateUpdateType) => {
    setError(undefined)
    if (props.contactgegevenId !== 0) {
      updateContactgegeven(data)
    } else {
      addContactgegeven(data)
    }
  }

  return (
    <Panel type={PanelType.medium}
           headerText={`Contactgegeven ${props.contactgegevenId !== 0 ? 'wijzigen' : 'toevoegen'}`}
           isOpen={props.isOpen}
           onDismiss={onCancel}
           closeButtonAriaLabel='Sluiten'>
      <div style={{ marginBottom: 16, borderBottom: '1px solid rgb(207, 207, 207)' }} />
      <ErrorMessageBar error={error} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <VgtTextField label='Contact Naam' name={getPropertyName<CreateUpdateType>('naam')} control={control}
                      errors={errors} required />
        <VgtTextField label='Emailadres' name={getPropertyName<CreateUpdateType>('emailadres')} control={control}
                      errors={errors} required />
        <VgtTextField label='Telefoonnummer' name={getPropertyName<CreateUpdateType>('telefoonnummer')}
                      control={control} errors={errors} required />
        <VgtDropdown label='Contact Categorie' name={getPropertyName<CreateUpdateType>('contactCategorieId')}
                     control={control} options={contactCategorieOpties as IDropdownOption[]} errors={errors}
                     required={true}
                     />

        <OkCancelButtonStack isSubmitting={isModifying || isAdding}
                             isLoading={false}
                             onOkClick={handleSubmit(onSubmit, (errors) => {
                               setError('Kan niet opslaan vanwege validatiefouten op de invoer.')
                               console.log(errors)
                             })}
                             onCancelClick={onCancel} />
      </form>
    </Panel>
  )
}