import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { EntityObjectState, createInitialState, IEntityPageObjectResponse } from 'lib/sliceSupport'
import IElementDefectSoorten from './elementDefectSoortenModel'

const initState: EntityObjectState<IElementDefectSoorten> = createInitialState<IElementDefectSoorten>()

const entityAdapter = createEntityAdapter<IElementDefectSoorten>()

export const fetchAll = createAsyncThunk('/elementDefectSoorten/fetchAll', async (_, thunkAPI) => {
    return await invokeFetch<IEntityPageObjectResponse<IElementDefectSoorten>>(thunkAPI, 'GET', '/api/element/pageDefectSoorten?skip=0&top=50000')
})

export const elementDefectSoortSlice = createSlice({
    name: 'elementDefectSoorten',
    initialState: entityAdapter.getInitialState(initState),

    reducers: {
        select: (state, action: PayloadAction<number | undefined>) => {
            state.selectedId = action.payload
        },
        clearSelection: state => {
            state.selectedId = undefined
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
        removeAll: state => entityAdapter.removeAll(state),
    },

    extraReducers: builder => {
        builder.addCase(fetchAll.pending, state => {
            state.status = 'pending'
        })
        builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<IEntityPageObjectResponse<IElementDefectSoorten>>) => {
            state.status = 'succeeded'
            const items = action.payload.items
            entityAdapter.setAll(state, items)
        })
        builder.addCase(fetchAll.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message || null
        })
    },
})

export const getSelectedEntity = (state: RootState) => {
    if (state.elementDefectSoorten.selectedId) return state.elementDefectSoorten.entities[state.elementDefectSoorten.selectedId]
    else return undefined
}

export const { add, modify, removeMany, select, clearSelection, removeAll } = elementDefectSoortSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.elementDefectSoorten)

export default elementDefectSoortSlice.reducer
